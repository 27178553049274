import React from 'react';
import contentMap from './contentMap';
import { getAccordionTitle, getAccordionContent } from '../../components/Accordion/helpers';
import browseCleverQuotesConstants from './browseCleverQuotesConstants';
import RefineSupplier from '../../features/RefineSupplier/index';
import { removePill } from '../../features/RefineSupplier/helpers';
import userAndDepartmentsHelper from '../../features/AddUsersAndDepartmentsToDirectAwardModal/usersAndDepartmentsHelper';
import Button from '../../components/Button';

// TODO REMOVE WITH NEW STYLE
const generateAccordionData = ({
    suppliers, setSuppliers, history, projectID, opportunityID, eventID,
}) => {
    const elements = [];
    if (suppliers) {
        suppliers.forEach((data, idx) => {
            elements.push({
                sectionId: `bdAccordionSection-id-${idx}`,
                header: <p className='title'>{data.accountName}</p>,
                content: <>
                    <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentColumn'>
                            <div className='bdAccordionSectionContentData'>
                                <p className='label body'>{browseCleverQuotesConstants.noOfEmployees}</p>
                                <p className='value caption'>{data?.noOfEmployees || '-'}</p>
                            </div>
                            <div className='bdAccordionSectionContentData'>
                                {data?.usersAndDepartments
                                && userAndDepartmentsHelper
                                    .generateUsersAndDepartmentsTag(data?.supplierID,
                                        data?.usersAndDepartments, true,
                                        (cardID, pillID) => removePill(cardID,
                                            pillID, suppliers, setSuppliers))}
                            </div>
                        </div>
                        <div className='bdAccordionSectionContentColumn'>
                            <div className='bdAccordionSectionContentData'>
                                <p className='label body'>{browseCleverQuotesConstants.postcode}</p>
                                <p className='value caption'>{data?.postcode || '-'}</p>
                            </div>
                            <div className='bdAccordionSectionContentData'>
                                <p className='label body'>{browseCleverQuotesConstants.localityName}</p>
                                <p className='value caption'>{data?.localityName || '-'}</p>
                            </div>
                        </div>
                    </div>
                    <div className='bdAccordianSectionButton'>
                        <Button
                            id={`SupplierBtn-${data.id}`}
                            variant='tertiary'
                            label={browseCleverQuotesConstants.btnViewSupplier}
                            handleClick={() => history.push(`/supplier/overview/${data.supplierID}`)}
                        />
                        <RefineSupplier
                            supplier={data}
                            withTags={false}
                            filteredData = {suppliers}
                            context='clever-quotes'
                            projectID={projectID}
                            opportunityID={opportunityID}
                            eventID={eventID}
                            handleFilteredData = {setSuppliers}
                            fromCleverQuotes={true}
                        />
                    </div>
                </>,
            });
        });
    }
    return elements;
};

const generateAccordionSection = ({
    data,
    idx,
    context,
    authContext,
    ...rest
}) => {
    const sectionId = `bdAccordionSection-id-${idx}`;
    return ({
        sectionId,
        header:
           getAccordionTitle({
               data, context, authContext, contentMap, ...rest,
           }),

        content: getAccordionContent({
            data, context, authContext, sectionId, contentMap, ...rest,
        }),
    });
};

const generateNewAccordionData = ({
    suppliers = [],
    context = browseCleverQuotesConstants.CLEVER_QUOTES,
    authContext,
    ...rest
}) => {
    if (!suppliers?.length) return [];

    return suppliers.map((data, idx) => generateAccordionSection({
        data,
        idx,
        context,
        suppliers,
        ...rest,
    }));
};

export { generateNewAccordionData, generateAccordionData };
