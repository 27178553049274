/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import HeaderContent from './HeaderContent';
import SectionContent from './SectionContent';
import Accordion from '../../Accordion';
import StatusLabel from '../../../components/StatusLabel';
import Upload from '../../Upload';
import Button from '../../../components/Button';
import rbac from '../../../rbac';
import {
    intentStatuses,
    intentLetterStatuses,
    approvalStatuses,
} from '../../../config/constants';
import { projectSubmissionIsIncomplete } from '../../../services/award.service';
import projectService from '../../../services/project.service';
import userIcon from '../../../styles/icons/white/user-2.svg';

const SupplierItem = ({
    accountId,
    item,
    publishLevel,
    awardInfo,
    addIntentProcedure,
    handleUpdateIntentStatus,
    handleStatusUpdate,
    uploadIntentLetter,
    deleteIntentLetter,
    isLotLevel = false,
    isDPS,
    userSteeringRole,
    handleAwardModal,
    readOnly,
    openNodes,
    handleSaveOpenNodesInLocalStorage,
    isAwardLetterSent,
    projectRoute,
}) => {
    const accordionId = item.lotID ? `${accountId}-${item.lotID}` : accountId;
    const generateTotalScore = () => {
        let score = 0;
        if (publishLevel === 'lot') {
            item?.questionnaires?.map((question) => {
                const quesitonScore = question.score * 1;
                if (typeof Number(quesitonScore) === 'number') {
                    score += quesitonScore;
                }
                return null;
            });
        } else {
            item?.responses?.questionnaires?.map((question) => {
                const quesitonScore = question.score * 1;
                if (typeof Number(quesitonScore) === 'number') {
                    score += quesitonScore;
                }
                return null;
            });
        }
        return score;
    };

    const generateStatusPills = (status) => {
        switch (status) {
            case intentLetterStatuses.notStarted:
                return <></>;
            case intentLetterStatuses.stage1ApprovalPending:
                return <>
                    <StatusLabel
                        id={'intentDocsApproval'}
                        color={'amber'}
                        labelTxt={'Stage 1 Approval Pending'}
                    />
                </>;
            case intentLetterStatuses.stage2ApprovalPending:
                return <>
                    <StatusLabel
                        id={'intentDocsApproval'}
                        color={'green'}
                        labelTxt={'Stage 1 Approved'}
                    />
                    <StatusLabel
                        id={'intentDocsApproval'}
                        color={'amber'}
                        labelTxt={'Stage 2 Approval Pending'}
                    />
                </>;
            case intentLetterStatuses.approved:
                return <>
                    <StatusLabel
                        id={'intentDocsApproval'}
                        color={'green'}
                        labelTxt={'Approved'}
                    />
                </>;
            default:
                return <></>;
        }
    };

    const generateDPSButtons = (approvalStatus, awardLetterSent) => {
        switch (approvalStatus) {
            case approvalStatuses.notStarted:
                return <div className='sbAccordionSectionContentDataUpload'>
                    <div className='sbAccordionSectionContentDataAwardRow'>
                        {rbac.can(rbac.action.submitAwardLetterForApproval, userSteeringRole) && <Button
                            id={'submitForApproval'}
                            label='Submit for Approval'
                            size={'small'}
                            handleClick={() => handleStatusUpdate('submit', null, accountId)}
                        />}
                    </div>
                </div>;
            case approvalStatuses.stage1ApprovalPending:
                return <div className='sbAccordionSectionContentDataUpload'>
                    <div className='sbAccordionSectionContentDataAwardRow'>
                        <div className='sbAccordionSectionContentDataAwardRowDPS'>
                            {rbac.can(rbac.action.approveStage1, userSteeringRole) && <>
                                <Button
                                    id={'rejectAll'}
                                    label='Reject'
                                    size={'small'}
                                    variant={'primary'}
                                    additionalVariant={'red'}
                                    handleClick={() => handleStatusUpdate('rejected', 'awardletter', accountId)}
                                />
                                <Button
                                    id={'approveAll'}
                                    label='Approve'
                                    size={'small'}
                                    handleClick={() => handleStatusUpdate('s1approved', 'awardletter', accountId)}
                                />
                            </>}
                        </div>
                    </div>
                </div>;
            case approvalStatuses.stage2ApprovalPending:
                return <div className='sbAccordionSectionContentDataUpload'>
                    <div className='sbAccordionSectionContentDataAwardRow'>
                        <div className='sbAccordionSectionContentDataAwardRowDPS'>
                            {rbac.can(rbac.action.approveStage2, userSteeringRole) && <>
                                <Button
                                    id={'rejectAll'}
                                    label='Reject'
                                    size={'small'}
                                    variant={'primary'}
                                    additionalVariant={'red'}
                                    handleClick={() => handleStatusUpdate('rejected', 'awardletter', accountId)}
                                />
                                <Button
                                    id={'approveAll'}
                                    label='Approve'
                                    size={'small'}
                                    handleClick={() => handleStatusUpdate('s2approved', 'awardletter', accountId)}
                                />
                            </>}
                        </div>
                    </div>
                </div>;
            case approvalStatuses.approved:
                return !awardLetterSent
                    ? <div className='sbAccordionSectionContentDataUpload'>
                        <div className='sbAccordionSectionContentDataAwardRow'>
                            {rbac.can(rbac.action.submitAwardForApproval, userSteeringRole) && <Button
                                id={'sendAwardLetters'}
                                label='Send Award Letters'
                                size={'small'}
                                handleClick={() => handleAwardModal(accountId)}
                            />
                            }
                        </div>
                    </div>
                    : <div className='sbAccordionSectionContentDataUpload'>
                        <div className='sbAccordionSectionContentDataAwardRow'>
                            <StatusLabel
                                id='awardsent'
                                color='green'
                                labelTxt='Award Letters Sent'
                            />
                        </div>
                    </div>;
            default:
                return <></>;
        }
    };

    const generateActionsLabel = () => {
        let incompleteIntent = false;
        if (isLotLevel === true) {
            if (item?.intentStatus === intentStatuses.pending || !item?.intentStatus) {
                incompleteIntent = true;
            }
        } else if (item?.responses?.intentStatus === intentStatuses.pending || !item?.responses?.intentStatus) {
            incompleteIntent = true;
        }

        if (isDPS === true && isLotLevel === false && item?.awardApproval === approvalStatuses.stage1ApprovalPending) {
            if (rbac.can(rbac.action.approveStage1, userSteeringRole)) {
                return 'Action Needed';
            }
            return 'Stage 1 Approval Pending';
        }

        if (isDPS === true && isLotLevel === false && item?.awardApproval === approvalStatuses.stage2ApprovalPending) {
            if (rbac.can(rbac.action.approveStage2, userSteeringRole)) {
                return 'Action Needed';
            }
            return 'Stage 2 Approval Pending';
        }

        switch (true) {
            case (isDPS === true && isLotLevel === true && incompleteIntent === false): return undefined;
            case (addIntentProcedure === true && (incompleteIntent === true || item?.docs?.length < 1)):
            case (addIntentProcedure === false && (incompleteIntent === true || item?.docs?.length < 1)):
            case (isDPS === true && (item?.awardApproval === approvalStatuses.notStarted || !item.awardApproval)):
            case (isDPS === true && isLotLevel === false && item?.awardApproval === approvalStatuses.approved && !item.awardLetterSent):
            case (awardInfo?.intentLetterSent === true && item?.docs?.length < 2): return 'Action Needed';
            default: return undefined;
        }
    };

    const uploadOptionsIsShown = () => {
        if (!isDPS) {
            return true;
        }
        return !projectSubmissionIsIncomplete(item?.responses, publishLevel);
    };

    const getAccountName = (account) => <><img src={userIcon} id='userIcon' alt='user'/> {account?.companyName}</>;

    const generateAccordionData = () => {
        const elements = [];
        elements.push({
            sectionId: accordionId,
            sectionClass: 'supplierLevel',
            headerClass: `${!isLotLevel ? '' : 'headerLevel'}`,
            header: <HeaderContent
                id={accordionId}
                itemName={item.lotTitle || getAccountName(item?.account)}
                showStatusLabel={!!item.intentStatus}
                statusLabel={item.intentStatus || ''}
                actionLabel={generateActionsLabel()}
                statusColor={item.intentStatus === intentStatuses.successful ? 'green' : 'red'}
                score={generateTotalScore(item.score) || item.responses?.score}
                weightage={item.weightage || item.responses?.weightage || 100}
                readOnly={readOnly}
            />,
            content: <>
                <SectionContent
                    data={item}
                    accountID={accountId}
                    showButton={true}
                    handleUpdateIntentStatus={handleUpdateIntentStatus}
                    isLotLevel={isLotLevel}
                    readOnly={readOnly}
                    isDPS={isDPS}
                />
                {publishLevel === 'project'
                && <div className='award-info-data-upload award-info-data-upload-projects Mui-extra'>
                    {addIntentProcedure
                    && !isDPS
                    && <>
                        {generateStatusPills(item?.intentApproval)}
                        {item.intentStatus && <Upload
                            title={item?.intentApproval && item?.intentApproval !== 'notStarted' ? 'Intent letter' : 'Upload Intent Letter'}
                            allowMultiple={false}
                            uploadIdentifier={'intent letter'}
                            uploadCallback={(docs) => uploadIntentLetter(docs, 'intentletter', accountId)}
                            deleteCallback={(docs) => deleteIntentLetter(docs, 'intentletter', accountId)}
                            uploadedDocuments={item?.docs?.filter((doc) => doc.documentType === 'intent') || []}
                            readOnly={(item?.intentApproval && item?.intentApproval !== 'notStarted') || readOnly}
                            showExpiryDate={false}
                        />}
                    </>}
                    {((awardInfo?.standstillEndDate || (awardInfo?.standstillApplicable !== undefined && awardInfo?.standstillApplicable === false)) || addIntentProcedure === false || isDPS)
                    && <>
                        <>
                            {generateStatusPills(item?.awardApproval)}
                            {((awardInfo?.standstillEndDate || (awardInfo?.standstillApplicable !== undefined && awardInfo?.standstillApplicable === false)) || (addIntentProcedure === false && item.intentApproval) || isDPS)
                             && uploadOptionsIsShown()
                            && <Upload
                                title={awardInfo && awardInfo?.standstillEndDate && item.awardApproval ? 'Award letter' : 'Upload Award Letter'}
                                allowMultiple={false}
                                uploadIdentifier={'award letter'}
                                uploadCallback={(docs) => uploadIntentLetter(docs, 'awardletter', accountId)}
                                deleteCallback={(docs) => deleteIntentLetter(docs, 'awardletter', accountId)}
                                uploadedDocuments={item?.docs?.filter((doc) => doc.documentType === 'award') || []}
                                readOnly={(item?.awardApproval && item?.awardApproval !== 'notStarted') || readOnly}
                                showExpiryDate={false}
                            />}
                        </>
                    </>}
                    {((isDPS && !readOnly) || (!item?.awardLetterSent && isAwardLetterSent && projectService.isRTMDirectAward(projectRoute) && !readOnly))
                        && generateDPSButtons(item.awardApproval, item.awardLetterSent)}
                </div>}

            </>,
        });
        return elements;
    };

    return <section className='sbContentSection' key={`section-${accordionId}`}>
        <div className='sbContentBody'>
            <Accordion id='sbAccordion_Supplier'
                key={`section-accordion-${accordionId}`}
                data={generateAccordionData()}
                handleToogleAccordion={handleSaveOpenNodesInLocalStorage}
                clickOnExpandIcon = {false}
                open={openNodes?.includes(`${accordionId}`)}
            />
        </div>
    </section >;
};

SupplierItem.propTypes = {
    id: PropTypes.number.isRequired,
    accountId: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    publishLevel: PropTypes.string.isRequired,
    awardInfo: PropTypes.object,
    addIntentProcedure: PropTypes.bool,
    handleUpdateIntentStatus: PropTypes.func.isRequired,
    handleStatusUpdate: PropTypes.func.isRequired,
    uploadIntentLetter: PropTypes.func,
    deleteIntentLetter: PropTypes.func,
    isLotLevel: PropTypes.bool,
    isDPS: PropTypes.bool,
    userSteeringRole: PropTypes.array,
    handleAwardModal: PropTypes.func,
    readOnly: PropTypes.bool,
    openNodes: PropTypes.array,
    handleSaveOpenNodesInLocalStorage: PropTypes.func,
    isAwardLetterSent: PropTypes.bool,
    projectRoute: PropTypes.string,
};

export default SupplierItem;
