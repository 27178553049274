import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import AuthContext from '../../context/AuthContext';
import authenticationAPIs from '../../services/user-management.service';
import gatewayAuthAPIs from '../../services/gateway.service';

import actions from './slice/auth.actions';

const Auth = ({ children }) => {
    const [authState, setAuthState] = useState({
        authenticated: false,
        user: {},
        tokens: {},
    });
    const dispatch = useDispatch();

    const setContextSession = (authResult) => {
        const userData = {
            id: authResult.userId,
            firstName: authResult.firstName,
            lastName: authResult.lastName,
            email: authResult.email,
            role: authResult.role,
            accountName: authResult.accountName,
            accountType: authResult.accountType,
            accountId: authResult.accountId,
            username: authResult.username,
            details: authResult.details,
            privilege: authResult.privilege,
            defaultWorkflow: authResult.defaultWorkflow,
            accountVerified: authResult.accountVerified,
            organisationDetails: authResult.organisationDetails,
            additionalDocuments: authResult.additionalDocuments || [],
            userDetails: authResult.userDetails,
        };

        let isUserAuthenticated = false;
        isUserAuthenticated = !!authResult.tokens.accessToken;
        const userTokens = authResult.tokens;

        setAuthState({
            ...authState,
            authenticated: isUserAuthenticated,
            user: userData,
            tokens: userTokens,
        });
    };

    const updateAuthContext = (authResult) => {
        const {
            userDetails,
            organisationDetails,
            ...rest
        } = authResult;

        const userData = {
            id: userDetails.userID,
            firstName: userDetails.contactDetails.firstname,
            lastName: userDetails.contactDetails.surname,
            organisationDetails,
            userDetails,
            ...rest,
        };

        setAuthState({
            ...authState,
            user: userData,
        });
    };

    const updateToken = (accessToken) => {
        setAuthState({
            ...authState,
            tokens: {
                ...authState.tokens,
                accessToken,
            },
        });
    };

    const login = async (email, password) => {
        const auth = await authenticationAPIs.login(email, password);

        if (!auth.data) {
            return { networkError: 'network error' };
        }

        if (auth.data.accessToken || auth.data.Session) {
            // const details = await authenticationAPIs.getUserDetails(auth.data.userID);
            const tokens = auth.data.accessToken ? { accessToken: auth.data.accessToken }
                : { session: auth.data.Session };
            tokens.refreshToken = auth.data.refreshToken ? auth.data.refreshToken : '';

            setContextSession({
                userId: auth.data.userID,
                firstName: auth.data.userDetails?.contactDetails?.firstname,
                lastName: auth.data.userDetails?.contactDetails?.surname,
                email,
                role: auth.data.userDetails?.userRole,
                accountName: auth.data.organisationDetails?.accountName,
                accountType: auth.data.organisationDetails?.accountType,
                accountId: auth.data.organisationDetails?.accountID,
                username: auth.data.userDetails?.contactDetails?.firstname,
                tokens,
                details: auth.data.userDetails,
                privilege: auth.data.privilege,
                defaultWorkflow: {
                    dAWorkflow: auth.data.organisationDetails?.dAWorkflow,
                    fCWorkflow: auth.data.organisationDetails?.fCWorkflow,
                },
                accountVerified: auth.data.organisationDetails?.verified,
                organisationDetails: auth.data.organisationDetails,
                additionalDetails: auth.data.additionalDetails,
                userDetails: auth.data.userDetails,
                additionalDocuments: auth.data.additionalDocuments || [],
            });

            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const refresh = async () => {
        const auth = await gatewayAuthAPIs.refresh(authState.tokens.refreshToken);

        if (!auth.data) {
            return { networkError: 'network error' };
        }

        if (auth.data?.AuthenticationResult) {
            updateToken(auth.data.AuthenticationResult.AccessToken);
        }

        return auth;
    };

    const createPassword = async (password) => {
        const username = authState.user.email;
        const sessionToken = authState.tokens.session;
        const auth = await authenticationAPIs.createPassword(username, password, sessionToken);
        const tokens = { accessToken: auth.data.accessToken };

        tokens.refreshToken = auth.data.refreshToken ? auth.data.refreshToken : '';

        if (auth.data.accessToken) {
            setContextSession({
                userId: auth.data.userDetails?.userID,
                firstName: auth.data.userDetails?.contactDetails?.firstname,
                lastName: auth.data.userDetails?.contactDetails?.surname,
                email: auth.data.userDetails.email,
                role: auth.data.userDetails?.userRole,
                accountName: auth.data.organisationDetails?.accountName,
                accountType: auth.data.organisationDetails?.accountType,
                accountId: auth.data.organisationDetails?.accountID,
                username: auth.data.userDetails?.contactDetails?.firstname,
                tokens,
                details: auth.data.userDetails,
                privilege: auth.data.privilege,
                defaultWorkflow: {
                    dAWorkflow: auth.data.organisationDetails?.dAWorkflow,
                    fCWorkflow: auth.data.organisationDetails?.fCWorkflow,
                },
                accountVerified: auth.data.organisationDetails?.verified,
                organisationDetails: auth.data.organisationDetails,
                additionalDocuments: auth.data.additionalDocuments || [],
                userDetails: auth.data.userDetails,
            });

            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const getVerificationCode = async (username) => {
        const auth = await authenticationAPIs.getVerificationCode(username);

        if (auth.status === 200) {
            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const resetPassword = async (username, password, confirmationCode) => {
        const auth = await authenticationAPIs.resetPassword(username, password, confirmationCode);

        if (auth.status === 200) {
            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const silentLogin = async (loginObj) => {
        setAuthState(loginObj);
        const {
            accessToken,
            organisationDetails,
            privilege,
            refreshToken,
            userDetails,
            Session,
            additionalDocuments,
        } = loginObj;

        if (accessToken || Session) {
            const tokens = accessToken ? { accessToken }
                : { session: Session };
            tokens.refreshToken = refreshToken || '';

            setContextSession({
                userId: userDetails?.userID,
                firstName: userDetails?.contactDetails?.firstname,
                lastName: userDetails?.contactDetails?.surname,
                email: userDetails.email,
                role: userDetails?.userRole,
                accountName: organisationDetails?.accountName,
                accountType: organisationDetails?.accountType,
                accountId: organisationDetails?.accountID,
                username: userDetails?.contactDetails?.firstname,
                tokens,
                details: userDetails,
                privilege,
                defaultWorkflow: {
                    dAWorkflow: organisationDetails?.dAWorkflow,
                    fCWorkflow: organisationDetails?.fCWorkflow,
                },
                accountVerified: organisationDetails?.verified,
                organisationDetails,
                additionalDocuments: additionalDocuments || [],
                userDetails,
            });

            return { actionSuccess: true, ...loginObj };
        }

        return { actionSuccess: false, ...loginObj };
    };

    const setRole = (role) => {
        setAuthState({
            ...authState,
            user: {
                ...authState.user,
                role,
            },
        });
    };

    const logout = async (logoutCb) => {
        const auth = await authenticationAPIs.logout(authState.tokens.accessToken);
        if (auth.status) {
            setContextSession({
                authenticated: false,
                userId: '',
                email: '',
                role: '',
                accountName: '',
                accountId: '',
                accountType: '',
                username: '',
                tokens: {},
                details: {},
                privilege: [],
                organisationDetails: {},
                additionalDocuments: [],
                userDetails: {},
            });

            dispatch(actions.logoutUser());

            if (logoutCb) logoutCb();

            return { actionSuccess: true, ...auth };
        }

        return { actionSuccess: false, ...auth };
    };

    const authActions = {
        login,
        refresh,
        createPassword,
        getVerificationCode,
        resetPassword,
        logout,
        silentLogin,
        setRole,
        updateAuthContext,
    };

    return (
        <AuthContext.Provider value={{
            ...authState,
            ...authActions,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

Auth.propTypes = {
    children: PropTypes.object,
};

export default Auth;
