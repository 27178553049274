import React from 'react';
import { Link } from 'react-router-dom';
import helperFunctions from '../../utils/helperFunctions';
import {
    allSteeringRoles,
    questionnaireTypes,
    workflowEventTypes,
    evaluationPanel,
    RICH_TEXT,
    routeToMarket,
} from '../../config/constants';
import documentManagementAPIs from '../../services/document-management.service';
import projectManagementAPIs from '../../services/project-management.service';
import { BUYER_STATUS } from '../Correspondence/ViewCorrespondence/constants';
import shareIcon from '../../styles/images/share-icon.png';
import BrowsePageHelper from '../../utils/browsePageHelper';
import isSupplierRole from '../../utils/isSupplierRole';
import Button from '../../components/Button';
import { Constants } from './constants';
import userAndDepartmentsHelper from '../AddUsersAndDepartmentsToDirectAwardModal/usersAndDepartmentsHelper';
import RefineSupplier from '../RefineSupplier/index';
import ShowMoreShowLess from '../../components/SeeMoreSeeLess';
import contentMap from './contentMap';
import { getAccordionTitle, getAccordionContent } from '../../components/Accordion/helpers';

const getPrettyRTM = (rtm) => routeToMarket
    .find((route) => route.id === rtm)?.name || 'Not Selected';


const getPrettyEventType = (eType) => workflowEventTypes.filter(
    (eventType) => eventType.value === eType,
)[0]?.label;

const getPrettyQuestionnaireType = (qType) => questionnaireTypes.filter(
    (eventType) => eventType.value === qType,
)[0]?.label;


const getPrettySteeringGroupRole = (roleId) => allSteeringRoles
    .filter((steeringRole) => (steeringRole.role === roleId))[0]?.name;

const viewSection = ({
    history, context, responseID, sectionID, templateType, questionnaireID, projectID, eventID,
}) => {
    if (responseID) {
        history.push(`/${context.toLowerCase()}/view/${sectionID}/import/${templateType || '_'}/${questionnaireID}/${projectID}/${eventID}/${responseID}`);
    } else {
        history.push(`/${context.toLowerCase()}/view/${sectionID}/import/${templateType || '_'}/${questionnaireID || '_'}`);
    }
};

const handleScroll = () => {
    const header = document.getElementById('bdContentHeader');
    const selectQuestionnaires = document.getElementById('selectQuestionnaires');
    const sticky = header?.offsetTop;
    if (window.scrollY > sticky) {
        header?.classList?.add('sticky');
        selectQuestionnaires?.classList?.add('sticky');
    } else {
        header?.classList?.remove('sticky');
        selectQuestionnaires?.classList?.remove('sticky');
    }
};

/**
     * TODO: This will have to be caliberated for all event types
     * @param {} event
     * @returns
     */
const prepareDocumentBuilderState = (event) => ({
    ...event,
    eventID: undefined,
    createdOn: undefined,
    systemTimestamp: undefined,
    accountID: undefined,
    departmentID: undefined,
    userID: undefined,
});

const prepareDocumentBuilderStateForStage = (stage) => ({
    name: stage.stageName,
    category: stage.category,
    sectionItems: stage.events.map((event) => prepareDocumentBuilderState(event)),
});

const prepareDocumentBuilderStateForSection = (section) => ({
    // This will need to handle questions too.
    ...section,
    title: section.title,
    sectionItems: section.questions.map((question) => ({
        ...question,
    })),
});

const fetchSection = async (sectionID) => {
    const response = await documentManagementAPIs.getSection(sectionID);
    return prepareDocumentBuilderStateForSection(response.data);
};

const fetchStage = async (stageID) => {
    const data = await projectManagementAPIs.getStage(stageID);
    return prepareDocumentBuilderStateForStage(data.data);
};

const fetchEvent = async (event) => {
    const data = await projectManagementAPIs.getEvent(event);
    return prepareDocumentBuilderState(data.data);
};

const formattedContext = (cntxt) => {
    switch (cntxt) {
        case 'contractsRegister':
            return 'awarded contracts';
        case 'calloff':
            return 'Call Off';
        case 'rebates':
            return 'rebate';
        default:
            return cntxt.toLowerCase();
    }
};

const getRecipientsName = (recipients) => {
    const recipientsNames = recipients
        .map((recipient) => recipient.account?.companyName);
    const recipientsNamesJoined = recipientsNames.join(', ');
    return recipientsNamesJoined;
};

const getCorrespondenceLinkURL = (originalTopicID, topicList, resourceType, resourceId) => {
    if (isSupplierRole(topicList[originalTopicID]?.senderAccount?.accountType)) {
        return `/${resourceType}/correspondence/overview/${resourceId}/${originalTopicID}/single`;
    }
    return `/${resourceType}/correspondence/overview/${resourceId}/${originalTopicID}`;
};

const getBuyerStatus = (authContext, topic) => {
    if (!isSupplierRole(authContext?.user?.accountType)) {
        if (topic?.buyerStatus) {
            return topic.buyerStatus;
        }

        if (!topic.hasThreads && isSupplierRole(topic.senderAccount?.accountType)) {
            if (topic.newOrUnread) {
                return BUYER_STATUS.UNREAD.value;
            }
            return BUYER_STATUS.ACTION_NEEDED.value;
        }
    }
    return undefined;
};

const getHeaderForSelectPage = ({
    context, action, eventsSelected,
    contractID, projectID, workflowID, history, resourceName,
    routeToMarketParam, responseID, questionnaireID, type, eventID, importEvents,
}) => {
    switch (context.toLowerCase()) {
        case 'events': {
            return <>
                {action.type === 'view' ? <></>
                    : <>

                        {
                            <div className={'dashboardCard large'} id={'projectActions'}>
                                <div className={'projectActionContainer'}>
                                    <div className={'cancelButtonContainer'}>
                                        <p className='title import-title'>{`Import events to ${action.stageName}`}</p>
                                        <div>
                                            <p className='caption'>{`${eventsSelected.length} events selected`}</p>
                                        </div>
                                    </div>
                                    <Button
                                        id={`${context.toLowerCase()}Btn-back`}
                                        variant= 'secondary'
                                        label={'Back'}
                                        handleClick={() => {
                                            if (contractID === '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                                                history.push(`/workflows/project/edit/${projectID}/${workflowID}/imported`);
                                            } else if (contractID && contractID !== '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                                                history.push(`/workflows/contract/edit/${projectID}/${workflowID}/${contractID}/imported`);
                                            } else if (resourceName && resourceName !== '_' && projectID && projectID !== '_') {
                                                history.push(`/workflows/${resourceName}/${projectID}/${routeToMarketParam}/create/imported`);
                                            } else if (workflowID && workflowID !== '_') {
                                                history.push(`/workflows/edit/${workflowID}/imported`);
                                            } else {
                                                history.push(`/workflows/_/_/${routeToMarketParam}/create/imported`);
                                            }
                                        } } />
                                    <Button
                                        id={`${context.toLowerCase()}Btn`}
                                        additionalVariant = {'primaryToolbarBtn'}
                                        variant= 'primary'
                                        label={'Import Event(s)'}
                                        handleClick={() => importEvents() } />
                                </div>
                            </div>
                        }
                    </>
                }
            </>;
        }
        case 'stages': {
            return <>
                {action.type === 'view' ? <></>
                    : <>

                        {
                            <div className={'dashboardCard large'} id={'projectActions'}>
                                <div className={'projectActionContainer'}>
                                    <div className={'cancelButtonContainer'}>
                                        <p className='title import-title'>{`Import stage to ${action.workflowName}`}</p>
                                    </div>
                                    <Button
                                        id={`${context.toLowerCase()}Btn-back`}
                                        variant= 'secondary'
                                        label={'Back'}
                                        handleClick={() => {
                                            if (contractID === '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                                                history.push(`/workflows/project/edit/${projectID}/${workflowID}/imported`);
                                            } else if (contractID && contractID !== '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                                                history.push(`/workflows/contract/edit/${projectID}/${workflowID}/${contractID}/imported`);
                                            } else if (resourceName && resourceName !== '_' && projectID && projectID !== '_') {
                                                history.push(`/workflows/${resourceName}/${projectID}/${routeToMarketParam}/create/imported`);
                                            } else if (workflowID && workflowID !== '_') {
                                                history.push(`/workflows/edit/${workflowID}/imported`);
                                            } else {
                                                history.push(`/workflows/_/_/${routeToMarketParam}/create/imported`);
                                            }
                                        } } />
                                </div>
                            </div>
                        }
                    </>
                }
            </>;
        }
        case 'sections': {
            return <>
                {action.type === 'view' ? <></>
                    : <>
                        {
                            <div className={'dashboardCard large'} id={'projectActions'}>
                                <div className={'projectActionContainer'}>
                                    <div className={'cancelButtonContainer'}>
                                        {/* Do we have a questionnaire id for a new one? */}
                                        <p className='title import-title'>{'Import section to questionnaire'}</p>
                                    </div>
                                    <Button
                                        id={`${context.toLowerCase()}Btn-back`}
                                        variant= 'secondary'
                                        label={'Back'}
                                        handleClick={() => {
                                            if (responseID) {
                                                history.push(`/questionnaires/edit/${questionnaireID}/${projectID}/${eventID}/imported/${responseID}`);
                                            } else if (questionnaireID) {
                                                history.push(`/questionnaires/edit/${questionnaireID}/_/_/imported`);
                                            } else {
                                                history.push(`/questionnaires/draft/${type}/_/_/imported`);
                                            }
                                        }} />
                                </div>
                            </div>
                        }
                    </>
                }
            </>;
        }
        default:
            return <></>;
    }
};


// TODO: DELETE WITH NEW STYLE
const generateAccordionSectionHeader = (data, context) => {
    switch (context.toLowerCase()) {
        case 'opportunities':
            return <p className='headerTitle title'>{data.opportunityName}</p>;
        case 'contractsregister':
            return <>
                <p className='headerTitle title'>{data.title}</p>
                <div className='bdAccordionSectionHeaderAddon'>
                    <p className='label body title'>Award date:</p>
                    <p className='value caption'>{helperFunctions.generatePrettyDate(data.awardDate)}</p>
                </div>
            </>;
        case 'suppliers':
            return <p className='headerTitle  title'>{data.accountName}</p>;
        case 'projects':
            return <>
                <p className='headerTitle title'>{data.title}</p>
                {(data.stages && data.stages.length > 0) && <div className='bdAccordionSectionHeaderAddon projects'>
                    <p className='label projects body'>Stage {data.currentStage} of {Object.keys(data.stages).length}</p>
                </div>}
                {data.projectStatus === 'cancelled' && <div className='bdAccordionSectionHeaderAddon cancelled'>
                    <label className={'label projects body'}>Cancelled</label>
                </div>}
            </>;
        case 'requests':
            return <>
                <p className='title'>{data.title}</p>
                {data.projectStatus === 'cancelled' && <div className='bdAccordionSectionHeaderAddon cancelled'>
                    <label className={'label projects body'}>Cancelled</label>
                </div>}
            </>;
        case 'workflows':
            return <>
                {/* <p className='title bdWorkflowName'>{data.workflowName}</p>
                {data.decision === 'disabled'
                && <div className='bdAccordionSectionHeaderAddon cancelled'>
                    <label className={'label projects body'}>Disabled</label>
                </div>} */}
                <div className='headerTitleContent'>
                    {data.accessGroups?.length > 0 || data.isPublic ? <img src={shareIcon} alt={'shared'} className='share-icon'></img> : <></>}
                    <p className='title'>{data.workflowName}</p>
                </div>
                <div className='bdAccordionSectionHeaderAddon'>
                    {data.status === 'disabled' && <label className='status-label red title'>Disabled</label>}
                    <label className='status-label title primary'>{getPrettyRTM(data.route)}</label>
                </div>
                {/* <div className='bdAccordionSectionHeaderAddon'>
                    <label className='title headerTag active'>{data.workflowType}</label>
                </div> */}
            </>;
        case 'solutions':
            return <>
                <p className='title'>{data.contractTitle}</p>
                {data.solutionStatus === 'cancelled' && <div className='bdAccordionSectionHeaderAddon cancelled'>
                    <label className={'label projects body'}>Cancelled</label>
                </div>}
            </>;
        case 'spends':
            return <>
                <p className='title'>{data.filename.split('_')[0]}</p>
            </>;
        case 'rebates':
            return <>
                <p className='title'>{data.filename.split('_')[0]}</p>
            </>;
        case 'questionnairesselect':
            return <>
                <p className='title'>{data.templateName}</p>
                <div className='bdAccordionSectionHeaderAddon'>
                    <label className='title headerTag active'>{getPrettyQuestionnaireType(data.templateType)}</label>
                </div>
            </>;
        case 'questionnaires':
            return <>
                <div className='headerTitleContent'>
                    {data.accessGroups?.length > 0 || data.isPublic ? <img src={shareIcon} alt={'shared'} className='share-icon'></img> : <></>}
                    <p className='title'>{data.templateName}</p>
                </div>
                <div className='bdAccordionSectionHeaderAddon'>
                    {data?.templateStatus === 'draft' ? <label className='status-label red title'>Draft</label>
                        : data?.isDisabled && <label className='status-label red title'>Disabled</label>}
                    <label className='status-label title primary'>{getPrettyQuestionnaireType(data.templateType)}</label>
                </div>
            </>;
        case 'sections':
            return <>
                <p className='title'>{data.title}</p>
            </>;
        case 'contracts':
            return <>
                <p className='headerTitle title'>{data.title}</p>
                {data.contractStatus && data.contractStatus === 'cancelled'
                    ? <div className='bdAccordionSectionHeaderAddon cancelled'>
                        <p className='label projects body'>Cancelled</p>
                    </div>
                    : <></>}
            </>;
        case 'events':
            return <>
                <p className='title bdWorkflowName'>{data.eventName}</p>
            </>;
        case 'stages':
            return <>
                <p className='title bdWorkflowName'>{data.stageName}</p>
            </>;
        case 'correspondence':
            return <>
                <div className='neworUnread_accordion_header'>
                    {data?.referenceValue
                        && <span className='headerReferenceValue'>{data.referenceValue}</span>}
                    {data.lastUnreadMessageSystemTimestamp && <p className='newOrUnreadData tag' />}
                    <p className='title'>{data.subject}</p>
                </div>
            </>;
        case 'calloff': return <div className='bdAccordionSectionTitleRow'>
            <div className='bdAccordionSectionTitleColumn'>
                <p className='headerTitle title'>{data.title}</p>
            </div>
            <div className='bdAccordionSectionTitleColumn'>
                {BrowsePageHelper.getStatusPill(data.projectStatus, data.isAwarded)}
            </div>
        </div>;
        case 'activitylogs':
            return <p className='title'>{data.message}</p>;
        default:
            return <></>;
    }
};
// TODO: DELETE WITH NEW STYLE
const generateAccordionSectionContent = ({
    data, action, accountID, context,
    authContext, contextSingular,
    history, removePill, filteredData, setFilteredData, opportunityID, newDataSet, projectID,
    eventID, routeToMarketParam, setNewDataSet, lotID, selectedTab, handleDownload,
    selectSection, handleView, topicList, resourceType, resourceId, selectStage, selectEvents,
    allowEdit, handleEdit, eventsSelected, callOffMechanism, questionnaireID, templateType,
    responseID,
}) => {
    switch (context.toLowerCase()) {
        case 'opportunities':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Buyer: </p><p className='value caption'>
                        {data?.account?.companyName || '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Expression of interest start date: </p><p className='value caption'>
                        {data?.expressionWindowStartDate ? helperFunctions.generatePrettyDate(data.expressionWindowStartDate) : '-'}</p></div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Estimated value: </p><p className='value caption'>
                        {helperFunctions.formatCurrency(data.estimatedValue) !== '' ? helperFunctions.formatCurrency(data.estimatedValue) : '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Expression of interest end date: </p><p className='value caption'>
                        {data?.expressionWindowEndDate ? helperFunctions.generatePrettyDate(data.expressionWindowEndDate) : '-'}</p></div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Event: </p><p className='value caption'>
                        {data?.eventName || '-' }</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Submission end date: </p><p className='value caption'>
                        {data?.submissionEndDate ? helperFunctions.generatePrettyDate(data.submissionEndDate) : '-'}</p></div>
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.id}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={
                            authContext.authenticated === true ? () => history.push(`/${context.toLowerCase()}/overview/${data.opportunityID}`)
                                : () => history.push(`/${context.toLowerCase()}/overview/public/${data.opportunityID}`)
                        } />
                </div>
            </>;
        case 'contractsregister':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Buyer: </p><p className='value caption'>
                        {data?.account?.companyName || '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Start date: </p><p className='value caption'>
                        {helperFunctions.generatePrettyDate(data?.contractStart) || '-'}</p></div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>End date: </p><p className='value caption'>
                        {helperFunctions.generatePrettyDate(data?.contractEnd) || '-'}</p></div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Estimated value: </p><p className='value caption'>
                        {helperFunctions.formatCurrency(data.awardValue) !== '' ? helperFunctions.formatCurrency(data.awardValue) : '-'}</p></div>
                    {/* <div className='bdAccordionSectionContentData'>
                    <p className='label body'>Extension date: </p>
                    <p className='value caption'>{data.extensionDate}</p>
                    </div> */}
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`contractBtn-${data.contractID}`}
                        variant='tertiary'
                        label='View Awarded Contract'
                        handleClick={() => history.push(`/contracts-register/overview/${data.contractID}`)} />
                </div>
            </>;
        case 'suppliers': {
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentColumn'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>{Constants.companySize}</p>
                            <p className='value caption'>{data?.noOfEmployees || '-'}</p>
                        </div>
                        <div className='bdAccordionSectionContentData'>
                            {data.usersAndDepartments && userAndDepartmentsHelper
                                .generateUsersAndDepartmentsTag(
                                    data.supplierID, data.usersAndDepartments, true,
                                    (cardID, pillID) => removePill(cardID,
                                        pillID, filteredData, setFilteredData),
                                )
                            }
                        </div>
                    </div>
                    <div className='bdAccordionSectionContentColumn'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>{Constants.postcode}</p>
                            <p className='value caption'>{data?.postcode || '-'}</p>
                        </div>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>{Constants.locality}</p>
                            <p className='value caption'>{data?.localityName || '-'}</p>
                        </div>
                    </div>
                </div>
                <div className='bdAccordianSectionButton'>

                    <Button
                        id={`${context.toLowerCase()}Btn-${data.supplierID}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/${contextSingular.toLowerCase()}/overview/${data.supplierID}`)} />
                    {opportunityID
                    && <RefineSupplier
                        supplier={data}
                        withTags={false}
                        newDataSet = {newDataSet}
                        filteredData = {filteredData}
                        context={context}
                        projectID={projectID}
                        opportunityID={opportunityID}
                        routeToMarketParam={routeToMarketParam}
                        eventID={eventID}
                        lotID={lotID}
                        handleFilteredData = {setFilteredData}
                        handleNewDataSet={setNewDataSet}
                    />
                    }
                </div>
            </>;
        }
        case 'projects':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Description: </p><p className='value caption'>
                        {data?.description || '-'}</p></div>
                    {selectedTab === 'nepo' && <div className='bdAccordionSectionContentData'><p className='label body'>Buyer: </p><p className='value caption'>
                        {data?.account?.companyName || '-'}</p></div>}
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Contract Start Date: </p><p className='value caption'>
                        {data?.contractStart || '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Contract End Date: </p><p className='value caption'>
                        {data?.contractEnd || '-'}</p></div>
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.projectID}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/${context.toLowerCase()}/overview/${data.projectID}`)} />
                </div>
            </>;
        case 'requests':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Description: </p>
                        <p className='value caption'>{data?.description || '-'}</p>
                    </div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Request start date: </p><p className='value caption'>
                        {data?.contractStart || '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Request duration: </p><p className='value caption'>
                        {data?.contractDuration || '-'}</p></div>
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.projectID}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/procurement-${context.toLowerCase()}/overview/${data.projectID}`)} />
                </div>
            </>;
        case 'workflows': {
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Description: </p>
                        <p className='value caption'>{data?.workflowDescription || '-'}</p>
                    </div>
                </div>
                {action.type === 'view' ? <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.workflowID}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.workflowID}`)} />
                </div>
                    : <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.id}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={accountID
                                ? () => history.push(`/${context.toLowerCase()}/view/${data.workflowID}/${projectID}/import/${accountID}/${callOffMechanism}`)
                                : () => history.push(`/${context.toLowerCase()}/view/${data.workflowID}/${projectID}/import`)
                            } />
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.workflowID}`}
                            variant='tertiary'
                            label={`Select ${contextSingular}`}
                            handleClick={() => action.cb(data.workflowID) } />
                    </div>
                }
            </>;
        }
        case 'solutions':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Solution by: </p><p className='value caption'>
                        {data?.account?.companyName || '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Start date: </p><p className='value caption'>
                        {data?.contractStart || '-'}</p></div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Project Type: </p><p className='value caption'>{
                        data?.projectDescribes?.length > 0
                            ? data?.projectDescribes.map((role, i) => {
                                if (i + 1 === data?.projectDescribes.length) {
                                    return role ? `${helperFunctions.camel2title(role)}` : '-';
                                }
                                return role ? `${helperFunctions.camel2title(role)}, ` : '-';
                            })
                            : '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Current end date: </p><p className='value caption'>
                        {data?.contractEnd || '-'}</p></div>
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.id}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/${context.toLowerCase()}/overview/${data.solutionID}`)} />
                </div>
            </>;
        case 'spends':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Upload date: </p><p className='value caption'>
                        {data?.lastModified || '-'}</p></div>
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.documentID}`}
                        variant='tertiary'
                        label={'Download'}
                        handleClick={
                            () => handleDownload(data)
                        } />
                </div>
            </>;
        case 'rebates':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Upload date: </p><p className='value caption'>
                        {data?.lastModified || '-'}</p></div>
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.documentID}`}
                        variant='tertiary'
                        label={'Download'}
                        handleClick={
                            () => handleDownload(data)
                        } />
                </div>
            </>;
        case 'questionnairesselect':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Description: </p>
                        { helperFunctions.getHtmlOrPlainTextBlock(data.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION) }
                    </div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Date created: </p><p className='value caption'>
                        {data?.createdOn ? helperFunctions.generatePrettyDate(data.createdOn) : '-'}</p></div>
                </div>
                {data.evaluationPanel
                    && <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>{evaluationPanel}:</p>
                            <p className='value caption'>
                                {helperFunctions
                                    .getEvaluationPanelLabel(data.evaluationPanel)}</p>
                        </div>
                    </div>
                }
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.templateID}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/questionnaires/view/${data.templateID}`)}
                    />
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.templateID}`}
                        variant='tertiary'
                        label={`Select ${contextSingular}`}
                        handleClick={() => action.cb(data) } />
                </div>
            </>;
        case 'questionnaires':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Description: </p>
                        { helperFunctions.getHtmlOrPlainTextBlock(data.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION) }
                    </div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Type: </p><p className='value caption'>
                        {data?.templateType ? getPrettyQuestionnaireType(data.templateType) : '-'}</p>
                    </div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Date created: </p><p className='value caption'>
                        {data?.createdOn ? helperFunctions.generatePrettyDate(data.createdOn) : '-'}</p></div>
                </div>
                {data.evaluationPanel
                    && <div className='bdAccordionSectionContentRow'>
                        <div className='bdAccordionSectionContentData'>
                            <p className='label body'>{evaluationPanel}:</p>
                            <p className='value caption'>
                                {helperFunctions
                                    .getEvaluationPanelLabel(data.evaluationPanel)}</p>
                        </div>
                    </div>
                }
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.id}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.templateID}`)} />
                </div>
            </>;
        case 'sections':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Description: </p><p className='value caption'>
                        { helperFunctions.getHtmlOrPlainTextBlock(data?.description || '-', 'section-description') }</p></div>
                </div>
                {action.type === 'view' ? <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.id}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.sectionID}`)}
                    />
                </div>
                    : <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.id}`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => viewSection(history, context, responseID,
                                data.sectionID, templateType, questionnaireID,
                                projectID, eventID)} />
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.sectionID}`}
                            variant= {'tertiary'}
                            label={'Import Section'}
                            handleClick={() => selectSection(data.sectionID) } />
                    </div>
                }
            </>;
        case 'contracts':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Description: </p><p className='value caption'>
                        {data?.description || '-'}</p></div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Contract start date: </p><p className='value caption'>
                        {data?.contractStart || '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>Contract end date: </p><p className='value caption'>
                        {data?.contractEnd || '-'}</p></div>
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.contractID}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={
                            () => (isSupplierRole(authContext?.user?.accountType)
                                ? history.push(`/${context.toLowerCase()}/overview/${data.contractID}`)
                                : history.push(`/${context.toLowerCase()}/management/${data.contractID}`)
                            )
                        }/>
                </div>
            </>;
        case 'events': {
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Description: </p>
                        <p className='value caption'>{data?.description || '-'}</p>
                    </div>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Date created: </p>
                        <p className='value caption'>{data?.createdOn ? helperFunctions.generatePrettyDate(data.createdOn) : '-'}</p>
                    </div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Type: </p>
                        <p className='value caption'>{data?.eventType ? getPrettyEventType(data.eventType) : '-'}</p>
                    </div>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Role responsible: </p>
                        <p className='value caption'>{data?.rolesResponsible ? getPrettySteeringGroupRole(data.rolesResponsible) : '-'}</p>
                    </div>
                </div>
                {action.type === 'view'
                    ? <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.eventID}-view`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => handleView(data.eventID)}
                        />
                        {allowEdit && <Button
                            id={`${context.toLowerCase()}Btn-${data.eventID}-edit`}
                            variant='tertiary'
                            label={`Edit ${contextSingular}`}
                            handleClick={() => handleEdit(data.eventID)}
                        />}
                    </div>
                    : <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.eventID}`}
                            variant= {eventsSelected.indexOf(data.eventID) > -1 ? 'primary' : 'tertiary'}
                            additionalVariant = {'selected-event'}
                            label={eventsSelected.indexOf(data.eventID) > -1 ? 'Selected' : 'Select Event'}
                            handleClick={() => selectEvents(data.eventID) } />
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.eventID}-view`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => handleView(data.eventID)}
                        />
                    </div>
                }
            </>;
        }
        case 'stages': {
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Route to market: </p>
                        <p className='value caption'>{data?.routeToMarket ? getPrettyRTM(data.routeToMarket) : '-'}</p>
                    </div>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Date created: </p>
                        <p className='value caption'>{data.createdOn ? helperFunctions.generatePrettyDate(data.createdOn) : '-'}</p>
                    </div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        <p className='label body'>Number of events: </p>
                        <p className='value caption'>{data?.events?.length || '-'}</p>
                    </div>
                </div>
                {action.type === 'view'
                    ? <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.stageID}-view`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.stageID}`)}
                        />
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.stageID}-edit`}
                            variant='tertiary'
                            label={`Edit ${contextSingular}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/edit/${data.stageID}`)}
                        />
                    </div>
                    : <div className='bdAccordianSectionButton'>
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.stageID}`}
                            variant= {'tertiary'}
                            label={'Select Stage'}
                            handleClick={() => selectStage(data.stageID) }
                        />
                        <Button
                            id={`${context.toLowerCase()}Btn-${data.stageID}-view`}
                            variant='tertiary'
                            label={`View ${contextSingular}`}
                            handleClick={() => history.push(`/${context.toLowerCase()}/view/${data.stageID}`)}
                        />
                    </div>
                }
            </>;
        }
        case 'correspondence':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'>
                        {
                            <p className={`value caption line-break ${helperFunctions.isLongMessage((data?.message)) ? 'ellipse' : ''}`}>
                                {data?.message ? helperFunctions.getHtmlOrPlainTextBlock(data.message) : '-'}
                            </p>
                        }
                    </div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.createdBy}</p><p className='value caption'>
                        {data?.senderAccount?.companyName || '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.createdOn}</p><p className='value caption'>
                        {data?.createdOn ? helperFunctions.formatPrettyDateTime(data.createdOn) : '-'}</p></div>
                </div>
                {!isSupplierRole(authContext?.user?.accountType)
                    && <>
                        <div className='bdAccordionSectionContentRow'>
                            <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.sentTo}</p><p className='value caption'>
                                {data.recipientAccountIDs?.length > 0 ? <ShowMoreShowLess
                                    inputText={getRecipientsName(data.recipients)}
                                    inputTextCharacterLimit={30} /> : Constants.all}</p>
                            </div>
                        </div>
                        {data.originalTopicID
                            && <div className='bdAccordionSectionContentRow'>
                                <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.originalTopic}</p>
                                    <Link className='value caption' key={data.id} to={
                                        getCorrespondenceLinkURL(data.originalTopicID,
                                            topicList, resourceType, resourceId)
                                    }>
                                        {Constants.originalTopicLinkTitle}
                                    </Link></div>
                            </div>}
                    </>
                }
                {!isSupplierRole(authContext?.user?.accountType)
                    && data?.senderAccountID === authContext.user.accountId
                    ? <Button
                        id={`${context.toLowerCase()}Btn-${data.correspondenceID}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/${resourceType}/correspondence/overview/${resourceId}/${data.topicID}`)} />
                    : <Button
                        id={`${context.toLowerCase()}Btn-${data.correspondenceID}`}
                        variant='tertiary'
                        label={`View ${contextSingular}`}
                        handleClick={() => history.push(`/${resourceType}/correspondence/overview/${resourceId}/${data.topicID}/single`)} />
                }

            </>;
        case 'calloff':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.descriptionLabel} </p><p className='value caption'>
                        {data?.description || '-'}</p></div>
                </div>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentColumn'>
                        <div className='bdAccordionSectionContentRow'>
                            <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.submissionEndDateLabel} </p><p className='value caption'>
                                {data?.submissionEndDate ? helperFunctions.formatPrettyDate(data.submissionEndDate) : '-'}</p></div>
                        </div>
                    </div>
                    <div className='bdAccordionSectionContentColumn'>
                        <div className='bdAccordionSectionContentRow'>
                            <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.contractStartDateLabel} </p><p className='value caption'>
                                {data?.contractStart || '-'}</p></div>
                        </div>
                        <div className='bdAccordionSectionContentRow'>
                            <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.contractEndDateLabel} </p><p className='value caption'>
                                {data?.contractEnd || '-'}</p></div>
                        </div>
                    </div>
                </div>
                <div className='bdAccordianSectionButton'>
                    <Button
                        id={`${context.toLowerCase()}Btn-${data.projectID}`}
                        variant='tertiary'
                        label={`View ${formattedContext(contextSingular)}`}
                        handleClick={() => history.push(`/${context.toLowerCase()}/overview/${data.projectID}`)} />
                </div>
            </>;
        case 'activitylogs':
            return <>
                <div className='bdAccordionSectionContentRow'>
                    <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.createdBy}</p><p className='value caption'>
                        {`${data?.firstname} ${data?.surname}, ${data.departmentName}, ${data?.accountName}` || '-'}</p></div>
                    <div className='bdAccordionSectionContentData'><p className='label body'>{Constants.createdOn}</p><p className='value caption'>
                        {data?.systemTimestamp ? helperFunctions.formatPrettyDateTime(data.systemTimestamp) : '-'}</p></div>
                </div>
            </>;
        default:
            return <></>;
    }
};


const generateAccordionSection = ({
    data,
    idx,
    toggle,
    context,
    authContext,
    ...rest
}) => {
    const sectionId = `bdAccordionSection-id-${idx}`;
    return ({
        sectionId,
        header: toggle
            ? getAccordionTitle({
                data, context, authContext, contentMap, ...rest,
            })
            : generateAccordionSectionHeader(data, context),
        content: toggle
            ? getAccordionContent({
                data, context, authContext, sectionId, contentMap, ...rest,
            })
            : generateAccordionSectionContent({
                data, context, authContext, toggle, ...rest,
            }),
        buyerStatus: getBuyerStatus(authContext, data),
    });
};

const generateAccordionData = ({
    filteredData = [],
    context,
    toggle,
    authContext,
    ...rest
}) => {
    if (!filteredData?.length) return [];

    return filteredData.map((data, idx) => generateAccordionSection({
        data,
        idx,
        toggle,
        context,
        authContext,
        filteredData,
        ...rest,
    }));
};


export {
    getPrettyEventType,
    getPrettyQuestionnaireType,
    getPrettySteeringGroupRole,
    getPrettyRTM,
    handleScroll,
    prepareDocumentBuilderState,
    fetchSection,
    prepareDocumentBuilderStateForStage,
    fetchStage,
    fetchEvent,
    getRecipientsName,
    formattedContext,
    getBuyerStatus,
    generateAccordionSectionHeader,
    generateAccordionSectionContent,
    generateAccordionData,
    getHeaderForSelectPage,

};
