import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import LabelledInput, { LabelledInputTypeText } from '../../components/LabelledInput';
import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import actionsRegion from '../RegionsOfSupply/slice/supplyRegions.action';
import actions from '../CodesModal/slice/codes.action';
import Button from '../../components/Button';
import Tag from '../../components/Tag';
import LabelledSelect from '../../components/LabelledSelect';
import Form from '../../components/Form';
import GeneralConstants from '../../utils/generalConstants';
import userManagementAPIs from '../../services/user-management.service';
import rbac from '../../rbac';
import Toast from '../../components/Alerts/Toast/Toast';
import Modal from '../../components/Modal';
import constants from '../MyProfileForm/constants';
import { ThemeContext } from '../../context/ThemeContext';

const SelectCodesModal = React.lazy(() => import('../CodesModal/SelectCodesModal'));
const SupplyRegionsModal = React.lazy(() => import('../RegionsOfSupply/SupplyRegionsModal'));

const MyProfileSupplierForm = ({
    values,
    setValues,
    handleCancel,
    handleChange,
}) => {
    const [showCodesModal, setShowCodesModal] = useState(false);
    const [showRegionsModal, setShowRegionsModal] = useState(false);
    const [initialCodesData, setInitialCodesData] = useState();
    const [initialRegionsData, setInitialRegionsData] = useState();
    const [initialDepartmentData, setInitialDepartmentData] = useState();
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
        setShowCodesModal(!showCodesModal);
    };
    const dispatch = useDispatch();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;

    const {
        initCodesData, onlySelectedCodes, initCodesRegions, onlySelectedRegions,
    } = useSelector((state) => ({
        initCodesData: state.CodesReducer.selectedCodes,
        onlySelectedCodes: state.CodesReducer.onlySelectedCodes,
        initCodesRegions: state.SupplyRegionsReducer.selectedRegions,
        onlySelectedRegions: state.SupplyRegionsReducer.onlySelectedRegions,
    }));
    const [codeData, setCodeData] = useState([]);
    const [regionData, setRegionData] = useState([]);

    const collateInitialCodeData = (data) => {
        const initialCodeData = [];

        if (data && data.length > 0) {
            data.forEach((code) => {
                initialCodeData.push(code);
            });
            setInitialCodesData(initialCodeData);
        }
    };

    const collateInitialRegionData = (data) => {
        const initialRegionData = [];

        if (data && data.length > 0) {
            data.forEach((code) => {
                initialRegionData.push(code);
            });
            setInitialRegionsData(initialRegionData);
        }
    };

    useEffect(() => {
        collateInitialCodeData(values.organisationDetails.industryInfo);
        collateInitialRegionData(values.organisationDetails.deliveryAreaInfo);
        dispatch(actions.setOnlySelectedCodes([]));
        dispatch(actionsRegion.setOnlySelectedRegions([]));
    }, []);

    const removeCodesSelection = (parent, level) => {
        parent.map((el) => {
            const elem = el;
            if (el.level === level) {
                elem.selected = false;
            }
            if (el.children) removeCodesSelection(el.children, level);
            return null;
        });
        return initCodesData;
    };

    const updateCodesSelectionView = (pillName) => {
        dispatch(actions.setOnlySelectedCodes(onlySelectedCodes
            .filter((el) => el.level !== pillName)));
    };

    const handlePillDelete = (pillName) => {
        removeCodesSelection(initCodesData, pillName);
        updateCodesSelectionView(pillName);
    };

    const removePill = (pillName) => {
        handlePillDelete(pillName);
        const tempCodeData = initialCodesData;
        const updatedData = tempCodeData?.filter((code) => code.level !== pillName);
        const rawCodeData = values.organisationDetails.industryInfo;
        const industryInfoObject = [];
        rawCodeData?.forEach((codeObject) => {
            if (codeObject.level !== pillName) {
                industryInfoObject.push(codeObject);
            }
        });
        setInitialCodesData(updatedData);
        setValues({
            ...values,
            organisationDetails: {
                ...values.organisationDetails,
                industryInfo: industryInfoObject,
            },
        });
        if (onlySelectedCodes.length > 0) {
            setInitialCodesData([]);
        }
    };
    const removeDepartmentPill = async (item) => {
        try {
            const response = await userManagementAPIs.deleteDepartment(item.departmentID);
            if (response.status === 200) {
                const updatedData = values.organisationDetails.departments
                    .filter((department) => department.departmentID !== item.departmentID);
                setInitialDepartmentData(updatedData);
                setValues({
                    ...values,
                    organisationDetails: {
                        ...values.organisationDetails,
                        departments: updatedData,
                    },
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to delete department.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to delete department.',
            });
        }
    };

    const toggleRegionModal = () => {
        setShowRegionsModal(!showRegionsModal);
    };

    const removeRegionSelection = (parent, pillName) => {
        parent.map((el) => {
            const elem = el;
            if (el.description === pillName) {
                elem.selected = false;
            }
            if (el.cities) { removeRegionSelection(el.cities, pillName); }
            if (el.counties) { removeRegionSelection(el.counties, pillName); }
            return null;
        });
        return initCodesRegions;
    };

    const updateRegionSelectionView = (pillName) => {
        dispatch(actionsRegion.setOnlySelectedRegions(onlySelectedRegions
            .filter((el) => el.description !== pillName)));
    };

    const removeRegionPill = (pillName) => {
        removeRegionSelection(initCodesRegions, pillName);
        updateRegionSelectionView(pillName);
        const tempCodeData = initialRegionsData;
        const updatedData = tempCodeData?.filter((region) => region.description !== pillName);
        setInitialRegionsData(updatedData);
        setValues({
            ...values,
            organisationDetails: {
                ...values.organisationDetails,
                deliveryAreaInfo: updatedData,
            },
        });
        if (onlySelectedRegions.length > 0) {
            setInitialRegionsData([]);
        }
    };

    const mapCodes = (data) => {
        const industryInfoObject = [];
        data.forEach((codeObject) => {
            const industryObject = {
                classificationID: codeObject.classificationID,
                level: codeObject.level,
                category: codeObject.category,
            };
            industryInfoObject.push(industryObject);
        });
        setValues({
            ...values,
            organisationDetails: {
                ...values.organisationDetails,
                industryInfo: industryInfoObject,
            },
        });
    };

    const mapRegions = (data) => {
        const regionInfoObject = [];
        data.forEach((region) => {
            const regionObject = {
                code: region.code,
                description: region.description,
            };
            regionInfoObject.push(regionObject);
        });
        setValues({
            ...values,
            organisationDetails: {
                ...values.organisationDetails,
                deliveryAreaInfo: regionInfoObject,
            },
        });
    };

    useEffect(() => {
        if (onlySelectedCodes && onlySelectedCodes.length > 0) {
            setCodeData(onlySelectedCodes);
            mapCodes(onlySelectedCodes);
        } else if (initialCodesData) {
            setCodeData(initialCodesData);
        }
    }, [onlySelectedCodes, initialCodesData]);

    useEffect(() => {
        if (onlySelectedRegions && onlySelectedRegions.length > 0) {
            setRegionData(onlySelectedRegions);
            mapRegions(onlySelectedRegions);
        } else if (initialRegionsData) {
            setRegionData(initialRegionsData);
        }
    }, [onlySelectedRegions, initialRegionsData]);

    const collateInitialDepartmentData = (data) => {
        const initialDeptData = [];

        if (data && data.length > 0) {
            data.forEach((dept) => {
                initialDeptData.push(dept);
            });
        }
        setInitialDepartmentData(initialDeptData);
    };
    useEffect(() => {
        collateInitialDepartmentData(values.organisationDetails.departments || []);
    }, []);

    const generateCodeTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.classificationID}
                id={item.classificationID}
                tagTxt={(item.category && item.level) ? `${item.category} - ${item.level}` : `${item.classificationID}`}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => removePill(item.level || item.classificationID)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const handleInput = (event) => {
        const { value } = event.target;
        setValues({
            ...values,
            newDept: value,
        });
    };
    const handleModal = (show) => {
        setShowModal(show);
    };
    const generateDepartmentTag = (data, isDeletable) => {
        const TagList = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                const TagElement = <Tag key={`${item.departmentID}-${item.departmentName}`}
                    id={item.departmentID}
                    tagTxt={item.departmentName}
                    isDeletable={isDeletable}
                    size='large'
                    handleDeleteBtnClick={() => removeDepartmentPill(item)}
                />;
                TagList.push(TagElement);
            });
        }

        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };
    const generateDepartment = () => <>
        <LabelledInput id='dept'
            type={LabelledInputTypeText} label='Department'
            breakColumn={true} onChange={(e) => handleInput(e)}
            placeholder='Enter department'
            required={true} />
    </>;
    const generateSupplyRegionsTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.code}
                id={item.code}
                tagTxt={`${item.code} - ${item.description}` || item.code}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => removeRegionPill(item.description || item.code)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };
    const addDepartment = async (depName) => {
        try {
            const accID = values.organisationDetails.accountID;
            const updatedDepartment = await userManagementAPIs.createDepartment(accID,
                {
                    departmentDetails: [{
                        departmentName: depName,
                    }],
                });
            if (updatedDepartment.status === 200) {
                const updatedData = updatedDepartment.data;
                setInitialDepartmentData(updatedData);
                let updatedOrganisationDetails = { ...values.organisationDetails };
                updatedOrganisationDetails = {
                    ...updatedOrganisationDetails,
                    departments: updatedData,
                };
                setValues({
                    ...values,
                    organisationDetails: updatedOrganisationDetails,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to delete department.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to delete department.',
            });
        }
    };
    const handleAddDepartmentSubmit = async (event) => {
        event.preventDefault();
        if (!values.newDept) {
            Toast.fire({
                icon: 'error',
                titleText: 'Please Enter the Department',
            });
        } else if (initialDepartmentData.includes(values.newDept)) {
            Toast.fire({
                icon: 'error',
                titleText: `Department ${values.newDept} already added. Please add new department.`,
            });
        } else {
            addDepartment(values.newDept);
            handleModal(false);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            ...values,
        };
        delete payload.userDetails.verified;
        delete payload.organisationDetails.industryCode;
        delete payload.organisationDetails.deliveryCode;
        try {
            const updateUser = await userManagementAPIs.updateProfile(payload);
            if (updateUser.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: constants.successMessage,
                });
                handleCancel();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to update profile.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update profile.',
            });
        }
    };

    const generateDropdownOptions = (opts) => {
        const optionHtml = [];
        optionHtml.push(<option key={0} value='--' disabled>{'--'}</option>);
        opts.forEach((option, idx) => {
            optionHtml.push(<option key={`phoneCodeOption-${idx}`} value={option}>{option}</option>);
        });
        return optionHtml;
    };

    return (
        <div id='profileFormContainer'>
            <p className='title-large sectionHeader'>Personal Information</p>
            <Form id='profileForm' onSubmit={(e) => handleSubmit(e)}>
                <div className='sectionContent' id='personalInformation'>
                    <div className='sectionRow'>
                        <LabelledInput id='firstname'
                            type={LabelledInputTypeText} label='First name'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.USER_DETAILS, GeneralConstants.CONTACT_DETAILS)}
                            value={values.userDetails.contactDetails.firstname}
                            placeholder='Enter first name' /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='surname'
                            type={LabelledInputTypeText} label='Last name'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.USER_DETAILS, GeneralConstants.CONTACT_DETAILS)}
                            value={values.userDetails.contactDetails.surname}
                            placeholder='Enter last name' /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='userRole'
                            type={LabelledInputTypeText} label='Role'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.ORGANIZATION_DETAILS)}
                            value={values.userDetails.userRole}
                            placeholder='Enter Role'
                            readonly={true} /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='jobTitle'
                            type={LabelledInputTypeText} label='Job Title'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.ORGANIZATION_DETAILS)}
                            value={values.userDetails.jobTitle}
                            placeholder='Enter Job Title'
                            readonly={true} /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='department'
                            type={LabelledInputTypeText} label='Department'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.ORGANIZATION_DETAILS)}
                            value={values.userDetails.department}
                            placeholder='Enter department'
                            readonly={true} /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='email'
                            type={LabelledInputTypeText} label='Email'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.ORGANIZATION_DETAILS)}
                            value={values.userDetails.email}
                            placeholder='Enter email'
                            readonly={true}/>
                    </div>
                    <div className='sectionRow' id='phoneNo'>
                        <LabelledSelect id='countryCode'
                            options={generateDropdownOptions(GeneralConstants.strings.callingCodes)}
                            breakColumn={false}
                            label={'Phone Number:'}
                            value={values.userDetails?.contactDetails?.countryCode || '--'}
                            onChange={(e) => handleChange(e,
                                GeneralConstants.USER_DETAILS, GeneralConstants.CONTACT_DETAILS)} />
                        <LabelledInput id='mobile'
                            type={LabelledInputTypeText}
                            breakColumn={false}
                            label={null}
                            onChange={(e) => handleChange(e,
                                GeneralConstants.USER_DETAILS, GeneralConstants.CONTACT_DETAILS)}
                            value={values.userDetails?.contactDetails?.mobile}
                            pattern='^[0-9]+$'
                            title='Field has to be numbers only'
                            placeholder='' />
                    </div>
                </div>
                { rbac.can(rbac.action.userProfileManagement,
                    [values.organisationDetails.accountType.toLowerCase()
                        + values?.userDetails?.userRole])
                        && <>
                            <div className='sectionContent' id='companyInformation'>
                                <p className='title-large sectionHeader'>Company Information</p>
                                <div className='sectionContent' id='organisationalInformation'>
                                    <div className='sectionRow'>
                                        <p className='title sectionLabel' id='orgField'>Company type</p>
                                        <p className='caption sectionValue'>{values.organisationDetails.businessStructure || ''}</p>
                                    </div>
                                    <div className='sectionRow'>
                                        <p className='title sectionLabel' id='orgField'>Nature of business (SIC)</p>
                                        <p className='caption sectionValue'>{values.organisationDetails.natureOfBusiness || ''}</p>
                                    </div>
                                    <div className='sectionRow'>
                                        <p className='title sectionLabel' id='orgField'>VAT number</p>
                                        <p className='caption sectionValue'>{values.organisationDetails.vatNumber || ''}</p>
                                    </div>
                                    <div className='sectionRow'>
                                        <p className='title sectionLabel' id='orgField'>Registration number</p>
                                        <p className='caption sectionValue'>{values.organisationDetails.registrationNumber || ''}</p>
                                    </div>
                                    <div className='sectionRow'>
                                        <LabelledInput id='addressLine1'
                                            type={LabelledInputTypeText} label='Address Line 1'
                                            breakColumn={false} onChange={
                                                (e) => handleChange(e,
                                                    GeneralConstants.ORGANIZATION_DETAILS,
                                                    GeneralConstants.ADDRESS)
                                            }
                                            value={values.organisationDetails?.address?.addressLine1 || ''}
                                            placeholder='' />
                                    </div>
                                    <div className='sectionRow'>
                                        <LabelledInput id='addressLine2'
                                            type={LabelledInputTypeText} label='Address Line 2'
                                            breakColumn={false} onChange={
                                                (e) => handleChange(e,
                                                    GeneralConstants.ORGANIZATION_DETAILS,
                                                    GeneralConstants.ADDRESS)
                                            }
                                            required={false}
                                            value={values.organisationDetails?.address?.addressLine2 || ''}
                                            placeholder='' />
                                    </div>
                                    <div className='sectionRow'>
                                        <LabelledInput id='city'
                                            type={LabelledInputTypeText} label='Town/City'
                                            breakColumn={false} onChange={
                                                (e) => handleChange(e,
                                                    GeneralConstants.ORGANIZATION_DETAILS,
                                                    GeneralConstants.ADDRESS)
                                            }
                                            value={values.organisationDetails?.address?.city || ''}
                                            placeholder='' />
                                    </div>
                                    <div className='sectionRow' id='countrySelect'>
                                        <LabelledSelect id='country'
                                            options={
                                                generateDropdownOptions(
                                                    GeneralConstants.strings.countries,
                                                )
                                            }
                                            label='Country'
                                            breakColumn={false} onChange={
                                                (e) => handleChange(e,
                                                    GeneralConstants.ORGANIZATION_DETAILS,
                                                    GeneralConstants.ADDRESS)
                                            }
                                            value={values.organisationDetails?.address?.country || ''}
                                        />
                                    </div>
                                    <div className='sectionRow'>
                                        <LabelledInput id='postcode'
                                            type={LabelledInputTypeText} label='Postcode'
                                            pattern= {values.organisationDetails.address?.country === 'United Kingdom'
                                                ? '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$' : ''}
                                            title={values.organisationDetails.address?.country === 'United Kingdom'
                                                ? 'Please enter a valid UK postcode' : ''}
                                            breakColumn={false} onChange={
                                                (e) => handleChange(e,
                                                    GeneralConstants.ORGANIZATION_DETAILS,
                                                    GeneralConstants.ADDRESS)
                                            }
                                            value={values.organisationDetails?.address?.postcode || ''}
                                            placeholder='e.g. NE83DF' />
                                    </div>
                                    <div className='sectionRow two-inputs-row' id='companyPhoneNo'>
                                        <LabelledSelect id='companyCountryCode'
                                            options={generateDropdownOptions(GeneralConstants
                                                .strings.callingCodes)}
                                            breakColumn={false}
                                            label={GeneralConstants.PHONE_NUMBER}
                                            value={values.organisationDetails.companyCountryCode || '--'}
                                            onChange={(e) => handleChange(e,
                                                GeneralConstants.ORGANIZATION_DETAILS)} />
                                        <LabelledInput id='phoneNumber'
                                            type={LabelledInputTypeText}
                                            breakColumn={false}
                                            label={null}
                                            onChange={(e) => handleChange(e,
                                                GeneralConstants.ORGANIZATION_DETAILS)}
                                            value={values.organisationDetails.phoneNumber || ''}
                                            pattern={GeneralConstants.NUMBERS_PATTERN}
                                            title={GeneralConstants.FIEL_WITH_NUMBERS_ONLY}
                                            placeholder='' />
                                    </div>
                                    <div className='sectionRow'>
                                        <LabelledInput id='noOfEmployees'
                                            type={LabelledInputTypeText} label='Number of employees'
                                            breakColumn={false} onChange={(e) => handleChange(e,
                                                GeneralConstants.ORGANIZATION_DETAILS)}
                                            value={values.organisationDetails?.noOfEmployees || ''}
                                            placeholder='Enter number of employees'
                                            pattern='^[0-9]+$'
                                            title='Field has to be numbers only'
                                            readonly={false}/>
                                    </div>
                                </div>
                            </div>
                            <div id='supplierAdminSection'>
                                <div id='departmentSectionContent'>
                                    <div className='textIconContainer'><p className='title-large sectionHeader'>Departments</p>
                                        <Button id='addDepartment' size='small' variant='secondary skinless'
                                            label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                                            handleClick={(e) => {
                                                e.preventDefault();
                                                handleModal(true);
                                            }} />
                                    </div>
                                    {generateDepartmentTag(values.organisationDetails
                                        ?.departments, true)}
                                </div>
                                <div id='codesCategorySectionContent'>
                                    <div className='textIconContainer'><p className='title-large sectionHeader'>Codes and Categories</p>
                                        <Button id='addCodesCategoryButton' size='small' variant='secondary skinless'
                                            label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                                            handleClick={(e) => {
                                                e.preventDefault();
                                                setShowCodesModal(true);
                                            }} />
                                    </div>
                                    {generateCodeTag(codeData, true)}
                                </div>
                                <div id='supplyRegionSectionContent'>
                                    <div className='textIconContainer'><p className='title-large sectionHeader'>Delivery Area</p>
                                        <Button id='addSupplyRegionButton' size='small' variant='secondary skinless'
                                            label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                                            handleClick={(e) => {
                                                e.preventDefault();
                                                setShowRegionsModal(true);
                                            }} />
                                    </div>
                                    {generateSupplyRegionsTag(regionData, true)}
                                </div>
                            </div>
                        </>
                }
                <div className='twoActionBtnContainer'>
                    <Button id='cancel-action-btn'
                        variant='secondary'
                        label='Cancel'
                        type='button'
                        handleClick={handleCancel}/>
                    <Button id='submit-action-btn'
                        variant='primary'
                        label='Update'
                        type='submit'
                        handleClick={() => null}/>
                </div>
                {showModal && <Modal
                    id='addDepartment'
                    open={true}
                    size='small'
                    headerTitle='Add Department'
                    body={generateDepartment()}
                    footer={true}
                    mainActionButtonTxt='Add'
                    secondActionButtonTxt='Cancel'
                    secondActionButton={true}
                    handleMainActionBtnClick={handleAddDepartmentSubmit}
                    handleSecondaryActionBtnClick={() => { setShowModal(false); }}
                    helpOption={false}
                    closeModal={() => { setShowModal(false); }} />
                }
            </Form>
            {showCodesModal && <SelectCodesModal
                closeModal={toggleModal}
                initialData={initialCodesData} />}
            {showRegionsModal && <SupplyRegionsModal
                closeModal={toggleRegionModal}
                initialData={initialRegionsData} />}
        </div>
    );
};

MyProfileSupplierForm.propTypes = {
    values: propTypes.object.isRequired,
    handleCancel: propTypes.func.isRequired,
    setValues: propTypes.func.isRequired,
    handleChange: propTypes.func.isRequired,
    // onRadioChange: propTypes.func.isRequired,
};

export default MyProfileSupplierForm;
