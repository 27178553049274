import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
    generateDPSSolutionHeaderButtons,
} from './helpers';
import {
    getAwardUserPermissions,
    getSuppliersAndLotsOrderedByIncompleteSubmissions,
} from '../../services/award.service';
import {
    OPEN_CONFIRMATION_MODAL,
    EDIT_AWARD_STATUS,
} from '../../config/constants';
import SupplierSubmissions from './components/SupplierSubmissions';
import Modal from '../../components/Modal';
import dpsConstants, { getConfirmationText } from './constants';

const SummaryBuyerAwardDPS = ({
    projectDetails,
    awardSummary,
    userSteeringRole,
    readOnly,
    handleStatusUpdate,
    uploadIntentLetter,
    deleteIntentLetter,
    localStorageProperty,
    handleAwardModal,
    handleUpdateIntentStatus,
    handleSaveOpenNodesInLocalStorage,
}) => {
    const history = useHistory();
    const [awardInfo, setAwardInfo] = useState();
    const [userPermissions, setUserPermissions] = useState();
    const [suppliers, setSuppliers] = useState();
    const [showModal, setShowModal] = useState(false);
    const [actionParams, setActionParams] = useState({});
    const [showEditButton, setShowEditButton] = useState({ show: true, lotId: null });

    useEffect(() => {
        if (!awardSummary) {
            return;
        }
        const awardUserPermissions = getAwardUserPermissions(userSteeringRole);
        setUserPermissions(awardUserPermissions);
        setAwardInfo(awardSummary);
        setSuppliers(getSuppliersAndLotsOrderedByIncompleteSubmissions(awardSummary.responses));
    }, [awardSummary]);


    const scrollTo = (position) => {
        setTimeout(() => window.scrollTo({ top: position }), 50);
    };

    const actions = {
        [OPEN_CONFIRMATION_MODAL]: (params) => {
            setActionParams(params);
            setShowModal(true);
            scrollTo(window.scrollY);
        },
        [EDIT_AWARD_STATUS]: (params) => {
            setShowEditButton({ show: false, lotId: params[0] });
            scrollTo(window.scrollY);
        },
    };

    const handleClickAction = (clickAction, ...params) => {
        if (actions[clickAction]) {
            actions[clickAction](params);
        }
    };

    return (
        <>
            {showModal && <Modal
                id='confirmAwardStatusModal'
                open={true}
                size='small'
                headerTitle={dpsConstants.EDIT_AWARD_STATUS}
                body={<p>{getConfirmationText(actionParams[0])}</p>}
                helpOption={false}
                mainActionButtonTxt={dpsConstants.CONFIRM}
                handleMainActionBtnClick={() => {
                    handleUpdateIntentStatus(...actionParams);
                    setShowModal(false);
                    setShowEditButton({ show: true, lotId: null });
                }}
                secondActionButton={true}
                secondActionButtonTxt={dpsConstants.CANCEL}
                handleSecondaryActionBtnClick={() => { setShowModal(false); }}
                closeModal={() => { setShowModal(false); }}
            />}

            {awardInfo
            && generateDPSSolutionHeaderButtons(awardInfo.awardInfo, history)
            }
            {suppliers && userPermissions
            && suppliers?.map((supplier, index) => <>
                <SupplierSubmissions
                    awardSupplier = {supplier}
                    index={index}
                    key={uuidv4()}
                    awardInfo={awardInfo}
                    readOnly={readOnly}
                    userPermissions={userPermissions}
                    projectDetails={projectDetails}
                    uploadIntentLetter = {uploadIntentLetter}
                    handleStatusUpdate = {handleStatusUpdate}
                    handleAwardModal = {handleAwardModal}
                    deleteIntentLetter = {deleteIntentLetter}
                    handleClickAction={handleClickAction}
                    localStorageProperty = {localStorageProperty}
                    handleSaveOpenNodesInLocalStorage = {handleSaveOpenNodesInLocalStorage}
                    showEditButton= {showEditButton}
                />
            </>)
            }
        </>
    );
};

SummaryBuyerAwardDPS.propTypes = {
    projectDetails: PropTypes.object.isRequired,
    awardSummary: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    userSteeringRole: PropTypes.array,
    readOnly: PropTypes.bool,
    handleStatusUpdate: PropTypes.func.isRequired,
    uploadIntentLetter: PropTypes.func,
    deleteIntentLetter: PropTypes.func,
    handleAwardModal: PropTypes.func,
    handleUpdateIntentStatus: PropTypes.func.isRequired,
    localStorageProperty: PropTypes.string,
    handleSaveOpenNodesInLocalStorage: PropTypes.func,

};
export default SummaryBuyerAwardDPS;
