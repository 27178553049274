export const BUYER_STATUS = {
    UNREAD: { value: 'UNREAD', label: 'Unread', color: 'red' },
    ACTION_NEEDED: { value: 'ACTION_NEEDED', label: 'Action needed', color: 'red' },
    RESPONDED: { value: 'RESPONDED', label: 'Responded', color: 'green' },
    COMPLETED: { value: 'COMPLETED', label: 'Completed', color: 'green' },
};

export const BUYER_STATUS_ACTIONS = {
    NEW_MESSAGE: 'NEW_MESSAGE',
    VIEW_REPLIES: 'VIEW_REPLIES',
};

export const markAsCompleteBtnTitle = 'Mark as complete';
export const topicURLMessage = 'HERE';
export const messageWithLink1 = 'Please see';
export const topicIdMessage = 'topicId=';
export const messageWithLink2 = 'for a response.';
export const postedOn = 'Posted on';

export const Constants = {
    expand: 'Expand',
    hide: 'Hide',
};
