import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Constants from './constants';

const images = require.context('../../styles/images/', true);

const StaticImage = ({
    name,
    folder = Constants.FOLDER.BUYERS,
    extension = Constants.EXTENSION.SVG,
    className,
}) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [status, setStatus] = useState(Constants.STATUS.LOADING);

    useEffect(() => {
        const loadDefaultImage = () => {
            try {
                const defaultPath = images(`./${folder}/placeholder.${extension}`);
                setImageSrc(defaultPath);
                setStatus(Constants.STATUS.SUCCESS);
            } catch (error) {
                setStatus(Constants.STATUS.ERROR);
            }
        };

        const loadImage = async () => {
            try {
                const path = images(`./${folder}/${name}.${extension}`);
                const img = new Image();
                img.src = path;
                img.onload = () => {
                    setImageSrc(path);
                    setStatus(Constants.STATUS.SUCCESS);
                };
                img.onerror = () => loadDefaultImage();
            } catch (error) {
                loadDefaultImage();
            }
        };

        loadImage();
    }, [name]);

    if (status === Constants.STATUS.LOADING) {
        return null;
    }

    if (status === Constants.STATUS.ERROR) {
        return <p>{Constants.ERROR_LOADING_IMAGE}</p>;
    }

    return <img src={imageSrc} alt={name} className={className || ''} />;
};

StaticImage.propTypes = {
    name: PropTypes.string.isRequired,
    folder: PropTypes.string,
    extension: PropTypes.string,
    className: PropTypes.string,
};

export default StaticImage;
