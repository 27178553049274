import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { addDays, format } from 'date-fns';
import TimePicker from 'react-time-picker';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import DatePicker from 'react-date-picker';
import Form from '../../../../components/Form';
import Modal from '../../../../components/Modal';
import LabelledInput, { LabelledInputTypeNumber, LabelledInputTypeText } from '../../../../components/LabelledInput';
import LabelledTextarea from '../../../../components/LabelledTextArea';
import LabelledRadioButton from '../../../../components/LabelledRadioButton';
import Constants from './constants';
import clearIcon from '../../../../styles/icons/blue/clear.svg';
import LabelledSelect from '../../../../components/LabelledSelect';
import NoticeTypesDropdown from '../../../NoticeTypesDropdown';
import projectManagementAPIs from '../../../../services/project-management.service';
import {
    stageCategories, allSteeringRoles, workflowEvents, MAX_CHAR_COUNT_1000,
} from '../../../../config/constants';

const WorkflowEventModal = ({
    // interest,
    // accountId,
    mode,
    existingData,
    sectionNumber,
    sectionItemNumber,
    initialData,
    handleResult,
    closeModal,
}) => {
    const [eventDetails, setEventDetails] = useState({
        startDateReminderFlag: false,
        endDateReminderFlag: false,
        stageFirstApproval: false,
        stageSecondApproval: false,
        suppliersApprovalToRegInterest: false,
        addIntentProcedure: false,
        immediateResponse: false,
        publishProject: false,
        dpsRulesApplicable: false,
        suppliersResponsibleForEvent: [],
        ...initialData,
    });
    const [awardedSuppliers, setAwardedSuppliers] = useState([]);
    const [eventType, setEventType] = useState(initialData.eventType || '');
    const [steeringGroup, setSteeringGroup] = useState([]);
    const {
        projectId, routeToMarket, stageCategory, contractID,
    } = useParams();
    const location = useLocation();

    // const [accountUsers, setAccountUsers] = useState(initialData.assigned
    //     ? initialData.assigned.map((entry) => {
    //         const option = {
    //             name: entry.username,
    //             value: entry.userID,
    //             accountID: entry.accountID,
    //             accountName: entry.accountName,
    //         };
    //         return option;
    //     })
    //     : []);

    // const getAllSuppliersInProject = () => {
    //     const suppliers = [];
    //     for (let i = 0; i < interest.length; i += 1) {
    //         suppliers.push({
    //             name: interest[i].accountName,
    //             value: interest[i].userID,
    //             accountID: interest[i].accountID,
    //             accountName: interest[i].accountName,
    //         });
    //     }
    //     setAccountUsers(suppliers);
    // };

    // const getAllUsersInAccount = async () => {
    //     const response = await userManagementAPIs.getAllAccountUsers(accountId);
    //     if (response.status === 200) {
    //         const { data } = response;
    //         setAccountUsers(data.map((user) => ({
    //             name: `${user.contactDetails.firstname} ${user.contactDetails.surname}`,
    //             value: user.userID,
    //             accountID: user.accountID,
    //             accountName: user.accountName,
    //         })));
    //     } else {
    //         Toast.fire({
    //             icon: 'error',
    //             title: 'Unable to retrieve information.',
    //         });
    //     }
    // };

    const getSteeringGroup = async () => {
        if (projectId) {
            const response = await projectManagementAPIs.getProject(projectId);
            if (response.status === 200) {
                const { data } = response;
                const steeringArray = [];
                data.steeringInfo.forEach((user) => {
                    if (user?.userRole !== 'supplier') {
                        steeringArray.push({
                            name: user?.username,
                            value: user?.userID,
                        });
                    }
                });

                setSteeringGroup(steeringArray.filter((user, index, self) => index === self
                    .findIndex((t) => (
                        t.value === user.value
                    ))));
            }
        }
    };

    const getAwardedSuppliers = async (contId) => {
        const response = await projectManagementAPIs.manageContract(contId);
        if (response.status === 200) {
            if (response?.data?.publishLevel !== 'lot') {
                setAwardedSuppliers(response?.data?.awardedSuppliers?.filter((actSupp) => actSupp.supplierStatus === 'active').map((supp) => ({
                    name: supp.account.companyName,
                    value: supp.accountID,
                })));
            } else {
                const data = response?.data?.awardedSuppliers?.map((lot) => lot.suppliers.filter((actSupp) => actSupp.supplierStatus === 'active').map((supp) => ({
                    name: supp.account.companyName,
                    value: supp.accountID,
                })));
                const unique = [];
                if (data.length > 0) {
                    data.reduce((previousValue, currentValue) => previousValue
                        .concat(currentValue), [])
                        .map((supplier) => (unique
                            .filter((previousValue) => previousValue.value === supplier.value)
                            .length > 0 ? null : unique.push(supplier)));
                }
                setAwardedSuppliers(unique);
            }
        }
    };

    useEffect(() => {
        // if (eventDetails?.contractManagementEventType === 'rebate'
        //     || eventDetails?.contractManagementEventType === 'supplierDocumentation') {
        //     getAllSuppliersInProject();
        // } else {
        //     getAllUsersInAccount();
        // }
        getSteeringGroup();
        if (contractID) {
            getAwardedSuppliers(contractID);
        }
        setEventDetails({
            ...eventDetails,
            routeToMarket,
            stageCategory: eventDetails.stageCategory ? eventDetails.stageCategory : stageCategory,
        });
    }, []);

    const generateDropdownOptions = (key, option, placeholderContext) => ((key > 0)
        ? <option key={`dbDropdownOption-${key}`} value={option.value}>{option.label}</option>
        : <option key={`dbDropdownOption-${key}`} value='' selected={true} disabled>{`Select ${placeholderContext}`}</option>);

    // const generateSteeringOptions = (key, option) => ((key > 0)
    //     ? <option key={`sgDropdownOption-${key}`} value={option.id}>{option.name}</option>
    //     : <option key={`sgDropdownOption-${key}`} value='' selected={true} disabled>
    // {'Select Role'}</option>);

    const showCustomFormValidations = () => {
        if (eventDetails.eventType === 'publishNotices'
            && (eventDetails.noticeType === 'pin' || eventDetails.noticeType === 'contractsNotice' || eventDetails.noticeType === 'contractAwardNotice')) {
            if (!eventDetails.publishDestinations
                || (eventDetails.publishDestinations
                    && Object.keys(eventDetails.publishDestinations).length === 0)) {
                alert('Please select at least 1 publish destination for this notice type');
                return true;
            }
        }
        return false;
    };

    const getNewDateObject = (dateString) => {
        const dateStringParts = dateString.split('-');
        return new Date(+dateStringParts[2], dateStringParts[1] - 1, +dateStringParts[0]);
    };

    //    const generateEventType = () => {
    // const generateEvent = workflowEventTypes.map({
    //     label: 'Calendar Event',
    //         value: 'calendar',
    //         id: 'eventTypeCalendar',
    //         name: 'eventType',
    //         checked: eventType
    //             ? eventType === 'calendar'
    //             : false,
    //         required: true,
    // })

    //         label: 'Calendar Event',
    //         value: 'calendar',
    //         id: 'eventTypeCalendar',
    //         name: 'eventType',
    //         checked: eventType
    //             ? eventType === 'calendar'
    //             : false,
    //         required: true,
    //     };

    const getEventsUptoCurrentEvent = () => {
        const pastStages = [...existingData].slice(0, sectionNumber);
        const pastStagesEvents = [];
        pastStages.forEach((stage) => {
            if (stage.sectionItems) pastStagesEvents.push(...stage.sectionItems);
        });
        const currentStage = { ...existingData[sectionNumber] };
        let currentStageEventsUptoCurrentEvent = [];
        if (currentStage.sectionItems
            && currentStage.sectionItems.length > 0) {
            currentStageEventsUptoCurrentEvent = [...existingData[sectionNumber]
                .sectionItems].slice(0, sectionItemNumber);
        }
        return [...pastStagesEvents, ...currentStageEventsUptoCurrentEvent];
    };

    const getExistingEvents = (eventContext) => {
        const existingEvents = getEventsUptoCurrentEvent();
        const returnList = [];
        if (existingEvents.length > 0) {
            existingEvents.filter((event) => event.type === eventContext)
                .forEach((filteredEvent) => {
                    returnList.push({
                        name: filteredEvent.name,
                        value: filteredEvent.name,
                    });
                });
        }
        return returnList;
    };

    const generateUsersReponsible = (data) => {
        const result = [];

        if (data && data.length > 0 && Array.isArray(data)) {
            data.forEach((entry) => {
                result.push(entry.userID);
            });
        }
        return result;
    };

    const handleSteeringGroupChange = (event, data) => {
        const assignedArray = [];
        data.forEach((entry) => {
            const assignedObject = {
                username: entry.name,
                userID: entry.value,
                accountID: 'n/a',
                accountName: 'n/a',
            };
            assignedArray.push(assignedObject);
        });
        const steeringGroupArray = event;

        setEventDetails(({
            ...eventDetails,
            steeringGroupAssigned: assignedArray,
            selectedSteeringGroupAttendees: steeringGroupArray,
        }));
    };

    const handleNoticeTypeChange = (event) => {
        event.preventDefault();
        setEventDetails({
            ...eventDetails,
            noticeType: event.currentTarget.value,
        });
    };

    const generateEventBody = () => <>
        {(mode === 'Create')
            && <>
                <LabelledSelect id='eventType'
                    options={stageCategory === stageCategories.contractManagement
                        ? Constants.contractManagementEventTypes
                            .map((option, idx) => generateDropdownOptions(idx, option, 'type'))
                        : Constants.eventTypes
                            .map((option, idx) => generateDropdownOptions(idx, option, 'type'))}
                    label='Event type'
                    breakColumn={false}
                    value={eventDetails.eventType}
                    onChange={(event) => {
                        setEventDetails({
                            ...eventDetails,
                            eventType: event.target.value,
                        });
                    }}
                    required={true} />
                <div className='formElementsDivider'></div>
            </>
        }
        {eventDetails.eventType && <>
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('name'))
            && <>
                <LabelledInput
                    id='name'
                    type={LabelledInputTypeText}
                    label='Title'
                    placeholder='Enter title'
                    value={eventDetails.eventName || ''}
                    onChange={(event) => setEventDetails({
                        ...eventDetails,
                        eventName: event.target.value,
                    })}
                    breakColumn={false}
                    required={true}
                    readonly={mode === 'View'}
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('description'))
            && <>
                <LabelledTextarea
                    id='description'
                    label='Enter description'
                    onChange={(event) => setEventDetails({
                        ...eventDetails,
                        description: event.target.value,
                    })}
                    value={eventDetails.description || ''}
                    placeholder='Enter description'
                    maxCharCount={MAX_CHAR_COUNT_1000}
                    currentCharCount={eventDetails.description
                        ? eventDetails.description.length : 0}
                    breakColumn={false}
                    required={true}
                    readonly={mode === 'View'}
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('noticeType'))
                && <>
                    <LabelledRadioButton
                        id='noticePublisher'
                        label='Select the publisher for the notice type'
                        breakColumn={false}
                        onChange={(event) => {
                            setEventDetails({
                                ...eventDetails,
                                noticePublisher: event.currentTarget.value,
                            });
                        }}
                        required={true}
                        options={[{
                            label: 'Contracts Finder',
                            value: 'CF',
                            id: 'noticePublisherCF',
                            name: 'noticePublisher',
                            checked: eventDetails.noticePublisher === 'CF',
                            required: false,
                            disabled: (mode === 'View'),
                        }, {
                            label: 'Find a Tender',
                            value: 'FTS',
                            id: 'noticePublisherFTS',
                            name: 'noticePublisher',
                            checked: eventDetails.noticePublisher === 'FTS',
                            required: false,
                            disabled: (mode === 'View'),
                        }]}
                        renderAsRow={true} />
                    <div className='formElementsDivider'></div>

                    <NoticeTypesDropdown
                        type={eventDetails.noticeType}
                        handleOnChange={handleNoticeTypeChange}
                        publisher={eventDetails.noticePublisher}
                    />
                    <div className='formElementsDivider'></div>
                </>
            }
            <div className='formElementsRow'>
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('startDate'))
                && projectId && projectId !== '_' && mode === 'Edit' && !location.pathname.includes('create')
                && <div className='datePickerWrapper'>
                    <label className='title datePickerLabel'>Start date</label>
                    <DatePicker
                        className='customDateInput'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        calendarIcon={null}
                        format='dd-MM-y'
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(data) => {
                            if (eventDetails.eventDuration) {
                                const endDate = (data) ? addDays(data, parseInt(eventDetails.eventDuration, 10)) : '';
                                setEventDetails({
                                    ...eventDetails,
                                    startDate: (data) ? format(data, 'dd-MM-yyyy') : '',
                                    endDate: endDate ? format(endDate, 'dd-MM-yyyy') : '',
                                });
                            } else {
                                setEventDetails({
                                    ...eventDetails,
                                    startDate: (data) ? format(data, 'dd-MM-yyyy') : '',
                                });
                            }
                        }}
                        value={eventDetails.startDate && getNewDateObject(eventDetails.startDate)}
                        minDate={new Date()}
                        required={true}
                        disabled={mode === 'View'}
                    />
                    <div className='formElementsDivider'></div>
                </div>}
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('startTime'))
                && <div className='timePickerWrapper'>
                    <label className='title'>Start time<span className='caption'> (Optional)</span></label>
                    <TimePicker
                        className='customTimeInput'
                        hourPlaceholder='HH'
                        minutePlaceholder='MM'
                        locale='en-gb'
                        clockIcon={null}
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(time) => setEventDetails({
                            ...eventDetails,
                            startTime: time,
                        })}
                        value={eventDetails.startTime}
                        minTime='00:00'
                        required={false}
                        disabled={mode === 'View'}
                        disableClock={true}
                    />
                    <div className='formElementsDivider'></div>
                </div>}
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('duration'))
                && <>
                    <LabelledInput
                        id='duration'
                        type={LabelledInputTypeNumber}
                        label='Duration'
                        placeholder='Enter days'
                        minNumber={0}
                        value={eventDetails.eventDuration || ''}
                        onChange={(event) => {
                            if (eventDetails.startDate) {
                                const duration = (event.target.value)
                                    ? parseInt(event.target.value, 10) : 0;
                                const endDate = addDays(getNewDateObject(eventDetails.startDate),
                                    duration);
                                setEventDetails({
                                    ...eventDetails,
                                    eventDuration: event.target.value,
                                    endDate: endDate ? format(endDate, 'dd-MM-yyyy') : '',
                                });
                            } else {
                                setEventDetails({
                                    ...eventDetails,
                                    eventDuration: event.target.value,
                                });
                            }
                        }}
                        breakColumn={false}
                        required={true}
                        readonly={mode === 'View'}
                    />
                    <div className='formElementsDivider'></div>
                </>}
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('endDate'))
                && projectId && projectId !== '_' && mode === 'Edit' && !location.pathname.includes('create')
                && <div className='datePickerWrapper'>
                    <label className='title datePickerLabel'>End date</label>
                    <DatePicker
                        className='customDateInput'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        calendarIcon={null}
                        format='dd-MM-y'
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        value={eventDetails.endDate && getNewDateObject(eventDetails.endDate)}
                        minDate={eventDetails.startDate
                            ? getNewDateObject(eventDetails.startDate) : new Date()}
                        readonly
                    />
                    <div className='formElementsDivider'></div>
                </div>}
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('endTime'))
                && <div className='timePickerWrapper'>
                    <label className='title'>End time<span className='caption'> (Optional)</span></label>
                    <TimePicker
                        className='customTimeInput'
                        hourPlaceholder='HH'
                        minutePlaceholder='MM'
                        locale='en-gb'
                        clockIcon={null}
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(time) => setEventDetails({
                            ...eventDetails,
                            endTime: time,
                        })}
                        value={eventDetails.endTime}
                        minTime='00:00'
                        required={false}
                        disabled={mode === 'View'}
                        disableClock={true}
                    />
                    <div className='formElementsDivider'></div>
                </div>}
            </div>
            <div className='formElementsRow'>
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('startDateReminder'))
                && <>
                    <LabelledRadioButton
                        id='startDateReminder'
                        label='Add start date reminder'
                        breakColumn={false}
                        onChange={(event) => {
                            const updatedEventDetailsData = { ...eventDetails };
                            updatedEventDetailsData.startDateReminderFlag = event.target.value === 'true';
                            if (eventDetails.startDateReminder) {
                                delete updatedEventDetailsData.startDateReminder;
                            }
                            setEventDetails(updatedEventDetailsData);
                        }}
                        required={false}
                        options={[{
                            label: 'Yes',
                            value: 'true',
                            id: 'startDateReminderYes',
                            name: 'startDateReminder',
                            checked: eventDetails.startDateReminderFlag,
                            required: false,
                            disabled: (mode === 'View' && !eventDetails.startDateReminderFlag),
                        }, {
                            label: 'No',
                            value: 'false',
                            id: 'startDateReminderNo',
                            name: 'startDateReminder',
                            checked: !eventDetails.startDateReminderFlag,
                            required: false,
                            disabled: (mode === 'View' && eventDetails.startDateReminderFlag),
                        }]}
                        renderAsRow={true} />
                    <div className='formElementsDivider'></div>
                </>}
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('startDateReminderDays'))
                && eventDetails.startDateReminderFlag
                && <>
                    <LabelledInput
                        id='startDateReminderDays'
                        type={LabelledInputTypeNumber}
                        label='Number of days in advance'
                        placeholder='Enter days'
                        minNumber={0}
                        value={eventDetails.startDateReminder || ''}
                        onChange={(event) => setEventDetails({
                            ...eventDetails,
                            startDateReminder: event.target.value,
                        })}
                        breakColumn={false}
                        required={true}
                        readonly={mode === 'View'}
                    />
                    <div className='formElementsDivider'></div>
                </>}
            </div>
            <div className='formElementsRow'>
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('endDateReminder'))
                    && <>
                        <LabelledRadioButton
                            id='endDateReminder'
                            label='Add end date reminder'
                            breakColumn={false}
                            onChange={(event) => {
                                const updatedEventDetailsData = { ...eventDetails };
                                updatedEventDetailsData.endDateReminderFlag = event.target.value === 'true';
                                if (eventDetails.endDateReminder) {
                                    delete updatedEventDetailsData.endDateReminder;
                                }
                                setEventDetails(updatedEventDetailsData);
                            }}
                            required={false}
                            options={[{
                                label: 'Yes',
                                value: 'true',
                                id: 'endDateReminderYes',
                                name: 'endDateReminder',
                                checked: eventDetails.endDateReminderFlag,
                                required: false,
                                disabled: (mode === 'View' && !eventDetails.endDateReminderFlag),
                            }, {
                                label: 'No',
                                value: 'false',
                                id: 'endDateReminderNo',
                                name: 'endDateReminder',
                                checked: !eventDetails.endDateReminderFlag,
                                required: false,
                                disabled: (mode === 'View' && eventDetails.endDateReminderFlag),
                            }]}
                            renderAsRow={true} />
                        <div className='formElementsDivider'></div>
                    </>}
                {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('endDateReminderDays'))
                    && eventDetails.endDateReminderFlag
                    && <>
                        <LabelledInput
                            id='endDateReminderDays'
                            type={LabelledInputTypeNumber}
                            label='Number of days in advance'
                            placeholder='Enter days'
                            minNumber={0}
                            value={eventDetails.endDateReminder || ''}
                            onChange={(event) => setEventDetails({
                                ...eventDetails,
                                endDateReminder: event.target.value,
                            })}
                            breakColumn={false}
                            required={true}
                            readonly={mode === 'View'}
                        />
                        <div className='formElementsDivider'></div>
                    </>}
            </div>
            {eventDetails.eventType !== workflowEvents.supplierDocumentation && (Constants.eventLabelFields[eventDetails.eventType].fields.includes('rolesResponsible'))
            && <>
                <label className='form-label title selectSearchTitle' id='selectedResponsibleRoles-input-label' htmlFor='selectedResponsibleRoles-input'>Select role responsible for this event<span className='caption'> (Optional)</span></label>
                <SelectSearch
                    id='assigned'
                    closeOnSelect={true}
                    printOptions='on-focus'
                    options={allSteeringRoles.map((role) => ({
                        name: role.name,
                        value: role.role,
                    }))}
                    multiple={false}
                    search={true}
                    filterOptions={fuzzySearch}
                    placeholder='Select role'
                    emptyMessage='No role available'
                    value={[eventDetails.rolesResponsible]}
                    onChange={(event) => setEventDetails(({
                        ...eventDetails,
                        rolesResponsible: event,
                    }))}
                    disabled={mode === 'View'}
                    z-index='4'
                />
                <div className='formElementsDivider'></div>
            </>}
            {eventDetails.eventType === workflowEvents.supplierDocumentation
            && <>
                <label className='form-label title selectSearchTitle' id='selectedResponsibleRoles-input-label' htmlFor='selectedResponsibleRoles-input'>Select supplier(s) responsible for this event<span className='caption'> (Optional)</span></label>
                {!contractID && <p className='caption form-caption'>This selection can only be performed from contract management.</p>}
                {contractID && !awardedSuppliers
                && <p className='caption form-caption'>Awarded suppliers will be available here once the contract is live.</p>}
                <SelectSearch
                    id='assigned'
                    closeOnSelect={false}
                    printOptions='on-focus'
                    options={awardedSuppliers}
                    multiple
                    search
                    filterOptions={fuzzySearch}
                    placeholder='Select supplier(s)'
                    emptyMessage='No awarded suppliers(s) available'
                    value={eventDetails.suppliersResponsibleForEvent}
                    onChange={(event) => setEventDetails(({
                        ...eventDetails,
                        suppliersResponsibleForEvent: event,
                    }))}
                    disabled={mode === 'View' || !contractID}
                    z-index='4'
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('location'))
            && <>
                <LabelledTextarea
                    id='location'
                    label='Enter location'
                    onChange={(event) => setEventDetails({
                        ...eventDetails,
                        eventLocation: event.target.value,
                    })}
                    value={eventDetails.eventLocation || ''}
                    placeholder='Enter location'
                    maxCharCount={150}
                    currentCharCount={eventDetails.eventLocation
                        ? eventDetails.eventLocation.length : 0}
                    breakColumn={false}
                    required={true}
                    readonly={mode === 'View'}
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('stage1Approval'))
            && <>
                <LabelledRadioButton
                    id='stage1Approval'
                    label='Stage 1 approval required'
                    breakColumn={false}
                    onChange={(event) => {
                        if (eventDetails.stageSecondApproval) {
                            const updatedEventDetails = { ...eventDetails };
                            delete updatedEventDetails.stageSecondApproval;
                            updatedEventDetails.stageFirstApproval = event.target.value === 'true';
                            setEventDetails(updatedEventDetails);
                        } else {
                            setEventDetails({
                                ...eventDetails,
                                stageFirstApproval: event.target.value === 'true',
                            });
                        }
                    }}
                    required={false}
                    options={[{
                        label: 'Yes',
                        value: 'true',
                        id: 'stage1ApprovalYes',
                        name: 'stage1Approval',
                        checked: eventDetails.stageFirstApproval,
                        required: false,
                        disabled: (mode === 'View' && !eventDetails.stageFirstApproval),
                    }, {
                        label: 'No',
                        value: 'false',
                        id: 'stage1ApprovalNo',
                        name: 'stage1Approval',
                        checked: !eventDetails.stageFirstApproval,
                        required: false,
                        disabled: (mode === 'View' && eventDetails.stageFirstApproval),
                    }]}
                    renderAsRow={true} />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('stage2Approval'))
            && eventDetails.stageFirstApproval
            && <>
                <LabelledRadioButton
                    id='stage2Approval'
                    label='Stage 2 approval required'
                    breakColumn={false}
                    onChange={(event) => {
                        setEventDetails({
                            ...eventDetails,
                            stageSecondApproval: event.target.value === 'true',
                        });
                    }}
                    required={false}
                    options={[{
                        label: 'Yes',
                        value: 'true',
                        id: 'stage2ApprovalYes',
                        name: 'stage2Approval',
                        checked: eventDetails.stageSecondApproval,
                        required: false,
                        disabled: (mode === 'View' && !eventDetails.stageSecondApproval),
                    }, {
                        label: 'No',
                        value: 'false',
                        id: 'stage2ApprovalNo',
                        name: 'stage2Approval',
                        checked: !eventDetails.stageSecondApproval,
                        required: false,
                        disabled: (mode === 'View' && eventDetails.stageSecondApproval),
                    }]}
                    renderAsRow={true} />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('approveSupplier'))
            && <>
                <LabelledRadioButton
                    id='approveSupplier'
                    label='Approval required for Supplier(s) to Register Interest?'
                    breakColumn={false}
                    onChange={(event) => {
                        setEventDetails({
                            ...eventDetails,
                            suppliersApprovalToRegInterest: event.target.value === 'true',
                        });
                    }}
                    required={false}
                    options={[{
                        label: 'Yes',
                        value: 'true',
                        id: 'approveSupplierYes',
                        name: 'approveSupplier',
                        checked: eventDetails.suppliersApprovalToRegInterest,
                        required: false,
                        disabled: (mode === 'View' && !eventDetails.suppliersApprovalToRegInterest),
                    }, {
                        label: 'No',
                        value: 'false',
                        id: 'approveSupplierNo',
                        name: 'approveSupplier',
                        checked: !eventDetails.suppliersApprovalToRegInterest,
                        required: false,
                        disabled: (mode === 'View' && eventDetails.suppliersApprovalToRegInterest),
                    }]}
                    renderAsRow={true} />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('intendProcedure'))
            && <>
                <LabelledRadioButton
                    id='intendProcedure'
                    label='Intent Procedure'
                    breakColumn={false}
                    onChange={(event) => {
                        setEventDetails({
                            ...eventDetails,
                            addIntentProcedure: event.target.value === 'true',
                        });
                    }}
                    required={true}
                    options={[{
                        label: 'Yes',
                        value: 'true',
                        id: 'intendProcedureYes',
                        name: 'intendProcedure',
                        checked: eventDetails.addIntentProcedure,
                        required: false,
                        disabled: (mode === 'View' && !eventDetails.addIntentProcedure),
                    }, {
                        label: 'No',
                        value: 'false',
                        id: 'intendProcedureNo',
                        name: 'intendProcedure',
                        checked: !eventDetails.addIntentProcedure,
                        required: false,
                        disabled: (mode === 'View' && eventDetails.addIntentProcedure),
                    }]}
                    renderAsRow={true} />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('dpsRulesApplicable'))
            && <>
                <LabelledRadioButton
                    id='allowDPSRules'
                    label={Constants.ALLOW_DPS_RULES_LABEL}
                    breakColumn={false}
                    onChange={(event) => {
                        setEventDetails({
                            ...eventDetails,
                            dpsRulesApplicable: event.target.value === 'true',
                        });
                    }}
                    required={true}
                    options={[{
                        label: 'Yes',
                        value: 'true',
                        id: 'allowDPSRulesYes',
                        name: 'allowDPSRules',
                        checked: eventDetails.dpsRulesApplicable,
                        required: false,
                        disabled: (mode === 'View' && !eventDetails.dpsRulesApplicable),
                    }, {
                        label: 'No',
                        value: 'false',
                        id: 'allowDPSRulesNo',
                        name: 'allowDPSRules',
                        checked: !eventDetails.dpsRulesApplicable,
                        required: false,
                        disabled: (mode === 'View' && eventDetails.dpsRulesApplicable),
                    }]}
                    renderAsRow={true}
                    caption={Constants.DPS_RULES_OPTION_CAPTION}
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('publishType'))
            && <>
                <LabelledSelect id='publishType'
                    options={Constants.publishTypes
                        .map((option, idx) => generateDropdownOptions(idx, option, 'type'))}
                    label='Publish type'
                    breakColumn={false}
                    value={eventDetails.publishType}
                    onChange={(event) => setEventDetails({
                        ...eventDetails,
                        publishType: event.target.value,
                    })}
                    required={true}
                    readonly={mode === 'View'}
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('immediateResponse'))
            && <>
                <LabelledRadioButton
                    id='immediateResponse'
                    label='Immediate Response'
                    breakColumn={false}
                    onChange={(event) => {
                        setEventDetails({
                            ...eventDetails,
                            immediateResponse: event.target.value === 'true',
                        });
                    }}
                    required={false}
                    options={[{
                        label: 'Yes',
                        value: 'true',
                        id: 'immediateResponseYes',
                        name: 'immediateResponse',
                        checked: eventDetails.immediateResponse,
                        required: false,
                        disabled: (mode === 'View' && !eventDetails.immediateResponse),
                    }, {
                        label: 'No',
                        value: 'false',
                        id: 'immediateResponseNo',
                        name: 'immediateResponse',
                        checked: !eventDetails.immediateResponse,
                        required: false,
                        disabled: (mode === 'View' && eventDetails.immediateResponse),
                    }]}
                    renderAsRow={true} />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('questionnaireType'))
            && <>
                <label className='form-label title selectSearchTitle' id='selectedQuestionnaireType-input-label' htmlFor='selectedQuestionnaireType-input'>Select Questionnaire type(s) for this event</label>
                <SelectSearch
                    id='questionnaireType'
                    closeOnSelect={false}
                    printOptions='on-focus'
                    options={Constants.questionnaireTypes.map((type) => ({
                        name: type.label,
                        value: type.value,
                    }))}
                    multiple={true}
                    search={true}
                    filterOptions={fuzzySearch}
                    placeholder='Select type(s)'
                    emptyMessage='No type(s) available'
                    value={eventDetails.questionnairesType || []}
                    onChange={(event) => setEventDetails(({
                        ...eventDetails,
                        questionnairesType: event,
                    }))}
                    disabled={mode === 'View'}
                    z-index='4'
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('publishDestinations'))
            && <>
                <LabelledRadioButton
                    id='publishDestinations'
                    label='Publish at a project level?'
                    breakColumn={false}
                    onChange={(event) => {
                        setEventDetails({
                            ...eventDetails,
                            publishProject: event.target.value === 'true',
                        });
                    }}
                    required={false}
                    options={[{
                        label: 'Yes',
                        value: 'true',
                        id: 'publishProjectYes',
                        name: 'publishProject',
                        checked: eventDetails.publishProject,
                        required: false,
                        disabled: (mode === 'View' && !eventDetails.publishProject),
                    }, {
                        label: 'No',
                        value: 'false',
                        id: 'publishProjectNo',
                        name: 'publishProject',
                        checked: !eventDetails.publishProject,
                        required: false,
                        disabled: (mode === 'View' && eventDetails.publishProject),
                    }]}
                    renderAsRow={true}
                    caption={'If yes, responses to this event will not be against lot(s).'}
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('steeringGroupAttendees'))
            && projectId && projectId !== '_'
            && <>
                <label className='form-label title selectSearchTitle' id='selectedSteeringGroupAttendees-input-label' htmlFor='selectedSteeringGroupAttendees-input'>Attendees from the steering group<span className='caption'> (Optional)</span></label>
                <SelectSearch
                    id='selectedSteeringGroupAttendees-input'
                    closeOnSelect={false}
                    printOptions='on-focus'
                    options={steeringGroup}
                    multiple
                    search
                    filterOptions={fuzzySearch}
                    placeholder='Select attendee(s)'
                    emptyMessage='No attendee(s) available'
                    value={generateUsersReponsible(eventDetails.steeringGroupAssigned) || []}
                    onChange={(event, data) => handleSteeringGroupChange(event, data)}
                    disabled={mode === 'View'}
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('questionnairesIncluded'))
            && <>
                <label className='form-label title selectSearchTitle' id='selectedQuestionnaires-input-label' htmlFor='selectedQuestionnaires-input'>Questionnaire(s) included in the Evaluation<span className='caption'> (Optional)</span></label>
                <SelectSearch
                    id='selectedQuestionnaires-input'
                    closeOnSelect={false}
                    printOptions='on-focus'
                    options={getExistingEvents('questionnaire')}
                    multiple
                    search
                    filterOptions={fuzzySearch}
                    placeholder='Select questionnaire(s)'
                    emptyMessage='No questionnaire(s) available'
                    value={eventDetails.selectedQuestionnaires || []}
                    onChange={(event) => setEventDetails({
                        ...eventDetails,
                        selectedQuestionnaires: event,
                    })}
                    disabled={mode === 'View'}
                />
                <div className='formElementsDivider'></div>
            </>}
            {(Constants.eventLabelFields[eventDetails.eventType].fields.includes('linkedEvaluationEvents'))
            && <>
                <label className='form-label title selectSearchTitle' id='selectedEvaluationEvents-input-label' htmlFor='selectedEvaluationEvents-input'>Linked evaluation event(s)<span className='caption'> (Optional)</span></label>
                <SelectSearch
                    id='selectedEvaluationEvents-input'
                    closeOnSelect={false}
                    printOptions='on-focus'
                    options={getExistingEvents('evaluation')}
                    multiple
                    search
                    filterOptions={fuzzySearch}
                    placeholder='Select event(s)'
                    emptyMessage='No event(s) available'
                    value={eventDetails.selectedEvaluationEvents || []}
                    onChange={(event) => setEventDetails({
                        ...eventDetails,
                        selectedEvaluationEvents: event,
                    })}
                    disabled={mode === 'View'}
                />
                <div className='formElementsDivider'></div>
            </>}
        </>}
    </>;

    const generateEventTypeBody = () => <>
        <LabelledRadioButton
            id='eventType'
            label='Please select one of the following options'
            breakColumn={false}
            onChange={(event) => setEventType(event.target.value)}
            options={(stageCategory === stageCategories.contractManagement
                || eventDetails.stageCategory === stageCategories.contractManagement)
                ? [{
                    label: 'Supplier Documentation',
                    value: 'supplierDocumentation',
                    id: 'eventTypeSupplierDocumentation',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'supplierDocumentation'
                        : false,
                    required: true,
                },
                {
                    label: 'Market Understanding',
                    value: 'marketUnderstanding',
                    id: 'eventTypeMarketUnderstanding',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'marketUnderstanding'
                        : false,
                    required: true,
                },
                {
                    label: 'Demand Management',
                    value: 'demandManagement',
                    id: 'eventTypeDemandManagement',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'demandManagement'
                        : false,
                    required: true,
                },
                {
                    label: 'Cost Management',
                    value: 'costManagement',
                    id: 'eventTypeCostManagement',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'costManagement'
                        : false,
                    required: true,
                },
                {
                    label: 'Performance Monitoring & Supplier Relations',
                    value: 'performanceMonitoringSupplierRelations',
                    id: 'eventTypePerformanceMonitoringSupplierRelations',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'performanceMonitoringSupplierRelations'
                        : false,
                    required: true,
                },
                {
                    label: 'Risk Management',
                    value: 'riskManagement',
                    id: 'eventTypeRiskManagement',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'riskManagement'
                        : false,
                    required: true,
                },
                {
                    label: 'Update Contract Management Plan',
                    value: 'updateContractManagementPlan',
                    id: 'eventTypeUpdateContractManagementPlan',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'updateContractManagementPlan'
                        : false,
                    required: true,
                },
                {
                    label: 'Exit Strategy',
                    value: 'exitStrategy',
                    id: 'eventTypeExitStrategy',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'exitStrategy'
                        : false,
                    required: true,
                },
                {
                    label: 'Extension Event',
                    value: 'extension',
                    id: 'eventTypeExtension',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'extension'
                        : false,
                    required: true,
                },
                {
                    label: 'Variation Event',
                    value: 'variation',
                    id: 'eventTypeVariation',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'variation'
                        : false,
                    required: true,
                },
                {
                    label: 'Renew Contract Event',
                    value: 'renewContract',
                    id: 'eventTypeRenewContract',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'renewContract'
                        : false,
                    required: true,
                }]
                : [{
                    label: 'Calendar Event',
                    value: 'calendar',
                    id: 'eventTypeCalendar',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'calendar'
                        : false,
                    required: true,
                }, {
                    label: 'Document Event',
                    value: 'document',
                    id: 'eventTypeDocument',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'document'
                        : false,
                    required: true,
                }, {
                    label: 'Publish Event',
                    value: 'publish',
                    id: 'eventTypePublish',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'publish'
                        : false,
                    required: true,
                },
                // {
                //     label: 'Notice Event',
                //     value: 'notice',
                //     id: 'eventTypeNotice',
                //     name: 'eventType',
                //     checked: eventType
                //         ? eventType === 'notice'
                //         : false,
                //     required: true,
                // },
                {
                    label: 'Evaluation Event',
                    value: 'evaluation',
                    id: 'eventTypeEvaluation',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'evaluation'
                        : false,
                    required: true,
                }, {
                    label: 'Issue Award/Unsuccessful Notices Event',
                    value: 'issueAwardUnsuccessfulNotices',
                    id: 'eventTypeIssueAwardUnsuccessfulNotices',
                    name: 'eventType',
                    checked: eventType
                        ? eventType === 'issueAwardUnsuccessfulNotices'
                        : false,
                    required: true,
                }]}
            renderAsRow={false} />
    </>;

    return <Form className='workflowEventForm'
        id='workflowEventForm'
        onSubmit={(e) => {
            e.preventDefault();
            if (mode !== 'Create') {
                if (eventType) {
                    if (!showCustomFormValidations()) {
                        handleResult(e, eventDetails, mode, sectionNumber, sectionItemNumber);
                        // setEventDetails({});
                    }
                } else {
                    setEventDetails({
                        ...eventDetails,
                        eventType: e.target.value,
                    });
                }
            } else {
                handleResult(e, eventDetails);
            }
        }}>
        {(mode === 'View')
            && <Modal
                open={true}
                size='large-long'
                closeModal={closeModal}
                headerTitle={`${mode} Event`}
                handleSecondaryActionBtnClick={closeModal}
                closeButton={true}
                body={generateEventBody()}
                helpOption={false}
                footer={false}
            />}
        {(eventDetails.eventType && (mode === 'Add' || mode === 'Edit'))
            && <Modal
                open={false}
                size='large-long'
                closeModal={closeModal}
                headerTitle={`${mode} ${Constants.eventLabelFields[eventDetails.eventType].label} Event`}
                secondActionButton={true}
                secondActionButtonTxt='Cancel'
                secondActionButtonType='button'
                handleSecondaryActionBtnClick={closeModal}
                handleMainActionBtnClick={() => null}
                mainActionButtonTxt={(mode === 'Add' ? 'Add' : 'Update')}
                closeButton={true}
                body={generateEventBody()}
                helpOption={false}
            />}
        {(!eventDetails.eventType && (mode === 'Add' || mode === 'Edit'))
            && <Modal
                open={false}
                closeModal={closeModal}
                headerTitle='Select Event Type'
                secondActionButton={true}
                secondActionButtonTxt='Cancel'
                secondActionButtonType='button'
                handleSecondaryActionBtnClick={closeModal}
                handleMainActionBtnClick={() => setEventDetails({
                    ...eventDetails,
                    eventType,
                })}
                mainActionButtonTxt='Continue'
                closeButton={true}
                body={generateEventTypeBody()}
                helpOption={false}
            />}
        {(mode === 'Create') && <>
            <div id='createEventHeader'>
                <p className='title-xLarge'>Event Details</p>
            </div>
            <div id='createEventBody'>
                {generateEventBody()}
            </div>
        </>}
    </Form>;
};

WorkflowEventModal.propTypes = {
    // interest: PropTypes.array.isRequired,
    // accountId: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    existingData: PropTypes.array.isRequired,
    sectionNumber: PropTypes.number,
    sectionItemNumber: PropTypes.number,
    initialData: PropTypes.object,
    handleResult: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default WorkflowEventModal;
