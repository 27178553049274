import React from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from './RichTextEditor/RichTextEditor';

const LabelledTextarea = ({
    id,
    className = '',
    label,
    subLabel,
    breakColumn,
    onChange,
    value,
    placeholder,
    currentCharCount = 0,
    maxCharCount = 512,
    required,
    readonly = false,
    showCharCount = true,
    richText = false,
}) => <div className={`labelled-textarea ${className} labelled-item`}>
    {label
        && <label
            className='form-label title'
            id={`${id}-textarea-label`}
            htmlFor={`${id}-textarea`}>
            {label}{required ? null : <span className='caption'> (Optional)</span>}
        </label>}
    {subLabel
        && <label
            className='form-label title-small'
            id={`${id}-textarea-label`}
            htmlFor={`${id}-textarea`}>
            {subLabel}
        </label>}
    {richText
        ? (<RichTextEditor
            id={id}
            value={value}
            showCharCount={showCharCount}
            maxCharCount={maxCharCount}
            onChange={onChange}></RichTextEditor>
        )
        : (
            <><textarea
                className='form-textarea body-small remove-on-print'
                id={`${id}-textarea`}
                name={id}
                value={value}
                placeholder={placeholder}
                maxLength={maxCharCount}
                onChange={(event) => onChange(event)}
                required={required}
                readOnly={readonly}/>

            {showCharCount && <div id='char-count' className='remove-on-print'>
                <span id='current'>{value ? value.length : currentCharCount}</span>
                <span id='maximum'> / {maxCharCount}</span>
            </div>}
            </>
        )
    }{
        <div
            className='form-textarea body-small render-on-print'
            id={`${id}-textarea`}
            placeholder={placeholder}
            required={required}
            readOnly={readonly}>
            {value}
        </div>
    }
    {breakColumn && <div className='flex-break' />}
</div>;

LabelledTextarea.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    breakColumn: PropTypes.bool.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    currentCharCount: PropTypes.number,
    maxCharCount: PropTypes.number,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    showCharCount: PropTypes.bool,
    richText: PropTypes.bool,
};

export default LabelledTextarea;
