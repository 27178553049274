const awardConstants = {
    STAGE_1_APPROVED: 'Stage 1 Approved',
    APPROVED: 'Approved',
    SUBMIT_FOR_APPROVAL: 'Submit for Approval',
    REJECT: 'Reject',
    APPROVE: 'Approve',
    ACTION_S1_APPROVED: 's1approved',
    ACTION_S2_APPROVED: 's2approved',
    ACTION_REJECTED: 'rejected',
    AWARD_LETTER: 'awardletter',
    INTENT_LETTER: 'intentletter',
    SEND_AWARD_LETTERS: 'Send Award Letters',
    AWARD_LETTERS_SENT: 'Award Letter(s) Sent',
    NOT_STARTED: 'notStarted',
    INTENT_LETTER_TITLE: 'Intent letter',
    UPLOAD_INTENT_LETTER: 'Upload Intent Letter',
    UPLOAD_IDENTIFIER: 'intent letter',
    INTENT: 'intent',
    AWARD_LETTER_TITLE: 'Award Letter',
    UPLOAD_AWARD_LETTER: 'Upload Award letter',
    AWARD_LETTER_IDENTIFIER: 'award letter',
    AWARD: 'award',
    SUBMIT: 'submit',
    A_SUMMARY: 'a-summary',
    SUCCESSFUL: 'Successful',
    UNSUCCESSFUL: 'Unsuccessful',
    EDIT_AWARD_STATUS: 'Edit Award Status',
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
    VIEW_EVALUATION: 'View Evaluation',
};

export const getConfirmationText = (status) => `Are you sure you want to update the status of this supplier to '${status.charAt(0).toUpperCase()}${status.slice(1)}'? Click ‘Confirm’ to proceed or ‘Cancel’ to keep the current status.`;

export default awardConstants;
