import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Form from '../../../../components/Form';
import Modal from '../../../../components/Modal';
import LabelledInput, { LabelledInputTypeText, LabelledInputTypeNumber } from '../../../../components/LabelledInput';
import LabelledTextarea from '../../../../components/LabelledTextArea';
import LabelledRadioButton from '../../../../components/LabelledRadioButton';
import Button from '../../../../components/Button';
import addIcon from '../../../../styles/icons/blue/add-ellipse.svg';
import addIconNew from '../../../../styles/icons/blue/add-ellipse-2.svg';
import errorIcon from '../../../../styles/icons/red/error.svg';
import Upload from '../../../Upload';
import {
    MAX_CHAR_COUNT_5000,
    passFailLabels, passFailValues, view, selection,
    modalSizes,
} from '../../../../config/constants';

import { cleanupDeletedDocument, mergeUploadedDocuments } from '../../../../utils/uploadHelper';
import AuthContext from '../../../../context/AuthContext';
import Constants from '../../constants';
import { ThemeContext } from '../../../../context/ThemeContext';

const QuestionnaireQuestionModal = ({
    mode,
    sectionNumber,
    sectionItemNumber,
    initialData,
    handleResult,
    closeModal,
    type,
}) => {
    const [questionDetails, setQuestionDetails] = useState(initialData);
    const [responseOptions, setResponseOptions] = useState((initialData.responseOptions) ? initialData.responseOptions : ['']);
    const [showResponseOptions, setShowResponseOptions] = useState(!!((initialData.responseType
        && (initialData.responseType === Constants.RESP_TYPE.CHECKBOX
            || initialData.responseType === Constants.RESP_TYPE.RADIOBUTTON))));
    // const [showWeightageInput, setShowWeightageInput] = useState(
    //    !!((initialData.scoringMechanism && initialData.scoringMechanism === 'scored')));
    const authContext = useContext(AuthContext);
    const { templateType } = useParams();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addIconNew : addIcon;
    const setResponseOptionValue = (index, value) => {
        const updatedOptions = (questionDetails.responseOptions)
            ? [...questionDetails.responseOptions]
            : [];
        updatedOptions[index] = value;

        setQuestionDetails({
            ...questionDetails,
            responseOptions: updatedOptions,
        });
    };

    const linkSupportingFiles = (docs) => {
        const mergedDocs = mergeUploadedDocuments(questionDetails.attachments, docs,
            {}, authContext.user);

        setQuestionDetails({
            ...questionDetails,
            attachments: mergedDocs,
        });
    };

    const deleteSupportingFiles = async (doc) => {
        const mergedDocs = cleanupDeletedDocument(questionDetails.attachments, doc);

        setQuestionDetails({
            ...questionDetails,
            attachments: mergedDocs,
        });
    };

    const generateResponseOptionHTML = (option, idx) => {
        if (idx === 0) {
            return <LabelledInput
                key={`responseOptionInput-${idx}`}
                id={`responseOptionInput-${idx}`}
                type={LabelledInputTypeText}
                label='Response options the user can choose from'
                placeholder='Enter option'
                value={questionDetails.responseOptions ? questionDetails.responseOptions[idx] : ''}
                onChange={(event) => setResponseOptionValue(event.target.id.split('-')[1], event.target.value)}
                breakColumn={false}
                required={true}
                readonly={mode === view}
            />;
        }

        return <div key={`responseOptionsInputRow-${idx}`}
            id={`responseOptionsInputRow-${idx}`}
            className='responseOptionsInputRow'>
            <LabelledInput
                key={`responseOptionInput-${idx}`}
                id={`responseOptionInput-${idx}`}
                type={LabelledInputTypeText}
                placeholder='Enter option'
                value={questionDetails.responseOptions ? questionDetails.responseOptions[idx] : ''}
                onChange={(event) => setResponseOptionValue(event.target.id.split('-')[1], event.target.value)}
                breakColumn={false}
                required={true}
                readonly={mode === view}
            />
            {mode !== view && <button
                type='button'
                id={`responseOptionsDeleteBtn-${idx}`}
                onClick={(e) => {
                    const updatedResponseOptions = [...responseOptions];
                    updatedResponseOptions.splice(parseInt(e.target.id.split('-')[1], 10), 1);
                    if (questionDetails.responseOptions) {
                        const updatedQuestionResponseOptions = [...questionDetails
                            .responseOptions];
                        updatedQuestionResponseOptions.splice(parseInt(e.target.id.split('-')[1], 10), 1);
                        setQuestionDetails({
                            ...questionDetails,
                            responseOptions: updatedQuestionResponseOptions,
                        });
                    }
                    setResponseOptions(updatedResponseOptions);
                }}>
                <img className='responseOptionsDeleteBtn'
                    id={`responseOptionsDeleteBtnIcon-${idx}`}
                    alt='remove'
                    src={errorIcon}>
                </img>
            </button>}
        </div>;
    };

    const generateBody = () => <>
        <LabelledInput
            id='name'
            type={LabelledInputTypeText}
            label='Title'
            placeholder='Enter title'
            value={questionDetails.questionTitle || ''}
            onChange={(event) => setQuestionDetails({
                ...questionDetails,
                questionTitle: event.target.value,
            })}
            breakColumn={false}
            required={true}
            readonly={mode === view}
        />
        <div className='formElementsDivider'></div>
        <LabelledTextarea
            id='description'
            label={Constants.DESCRIPTION}
            onChange={(event) => setQuestionDetails({
                ...questionDetails,
                description: event.target.value,
            })}
            value={questionDetails.description || ''}
            placeholder='Enter description'
            maxCharCount={MAX_CHAR_COUNT_5000}
            currentCharCount={questionDetails.description
                ? questionDetails.description.length : 0}
            breakColumn={false}
            required={true}
            readonly={mode === view}
            richText={true}
        />
        <div className='formElementsDivider'></div>
        <Upload
            title={Constants.ATTACHMENTS}
            uploadedDocuments={questionDetails.attachments}
            allowMultiple={true}
            uploadCallback={linkSupportingFiles}
            deleteCallback={deleteSupportingFiles}
            sectionNumber={0}
            questionNumber={0}
            questionnaire={true}
            readOnly={mode === view}
        />
        <div className='formElementsDivider'></div>
        <LabelledRadioButton
            id='responseType'
            label='Response type'
            required={true}
            breakColumn={false}
            onChange={(event) => {
                if (event.target.value === Constants.RESP_TYPE.CHECKBOX
                    || event.target.value === Constants.RESP_TYPE.RADIOBUTTON) {
                    setQuestionDetails({
                        ...questionDetails,
                        responseType: event.target.value,
                    });
                    setShowResponseOptions(true);
                } else {
                    setQuestionDetails({
                        ...questionDetails,
                        responseType: event.target.value,
                        responseOptions: [],
                        supportingDocuments: event.target.value === Constants.RESP_TYPE.FILE,
                    });
                    setResponseOptions(['']);
                    setShowResponseOptions(false);
                }
            }}
            options={[{
                label: Constants.YES_NO,
                value: Constants.RESP_TYPE.YES_NO,
                id: 'responseTypeYesNo',
                name: 'responseType',
                checked: questionDetails.responseType
                    ? questionDetails.responseType === Constants.RESP_TYPE.YES_NO
                    : false,
                required: true,
                disabled: (mode === view && questionDetails.responseType
                    && questionDetails.responseType !== Constants.RESP_TYPE.YES_NO),
            }, {
                label: Constants.TRUE_FALSE,
                value: Constants.RESP_TYPE.TRUE_FALSE,
                id: 'responseTypeTrueFalse',
                name: 'responseType',
                checked: questionDetails.responseType
                    ? questionDetails.responseType === Constants.RESP_TYPE.TRUE_FALSE
                    : false,
                required: true,
                disabled: (mode === view && questionDetails.responseType
                    && questionDetails.responseType !== Constants.RESP_TYPE.TRUE_FALSE),
            }, {
                label: Constants.CHECKBOX,
                value: Constants.RESP_TYPE.CHECKBOX,
                id: 'responseTypeCheckbox',
                name: 'responseType',
                checked: questionDetails.responseType
                    ? questionDetails.responseType === Constants.RESP_TYPE.CHECKBOX
                    : false,
                required: true,
                disabled: (mode === view && questionDetails.responseType
                    && questionDetails.responseType !== Constants.RESP_TYPE.CHECKBOX),
            }, {
                label: Constants.RADIO_BUTTON,
                value: Constants.RESP_TYPE.RADIOBUTTON,
                id: 'responseTypeRadioButton',
                name: 'responseType',
                checked: questionDetails.responseType
                    ? questionDetails.responseType === Constants.RESP_TYPE.RADIOBUTTON
                    : false,
                required: true,
                disabled: (mode === view && questionDetails.responseType
                    && questionDetails.responseType !== Constants.RESP_TYPE.RADIOBUTTON),
            }, {
                label: Constants.FREE_TEXT,
                value: Constants.RESP_TYPE.FREETEXT,
                id: 'responseTypeFreeText',
                name: 'responseType',
                checked: questionDetails.responseType
                    ? questionDetails.responseType === Constants.RESP_TYPE.FREETEXT
                    : false,
                required: true,
                disabled: (mode === view && questionDetails.responseType
                    && questionDetails.responseType !== Constants.RESP_TYPE.FREETEXT),
            }, {
                label: Constants.FILE,
                value: Constants.RESP_TYPE.FILE,
                id: 'responseTypeFile',
                name: 'responseType',
                checked: questionDetails.responseType
                    ? questionDetails.responseType === Constants.RESP_TYPE.FILE
                    : false,
                required: true,
                disabled: (mode === view && questionDetails.responseType
                    && questionDetails.responseType !== Constants.RESP_TYPE.FILE),
            }, {
                label: Constants.NUMBER,
                value: Constants.RESP_TYPE.NUMBER,
                id: 'responseTypeNumber',
                name: 'responseType',
                checked: questionDetails.responseType
                    ? questionDetails.responseType === Constants.RESP_TYPE.NUMBER
                    : false,
                required: true,
                disabled: (mode === view && questionDetails.responseType
                    && questionDetails.responseType !== Constants.RESP_TYPE.NUMBER),
            }]}
            renderAsRow={true} />
        <div className='formElementsDivider'></div>
        {questionDetails.responseType
            && questionDetails.responseType === Constants.RESP_TYPE.FREETEXT
        && <LabelledInput
            id='freeTextLength'
            type={LabelledInputTypeNumber}
            label='Character limit (max 30,000 characters)'
            value={questionDetails.freeTextLength}
            placeholder='Enter amount'
            onChange={(event) => setQuestionDetails({
                ...questionDetails,
                freeTextLength: Number(event.target.value),
            })}
            maxNumber={30000}
            breakColumn={false}
            required={true}
            readonly={mode === view}
        />
        }
        <div className='formElementsDivider'></div>
        {showResponseOptions && <>
            {responseOptions.map((option, idx) => generateResponseOptionHTML(option, idx))}
            <Button id='addAnotherOptionBtn'
                type='button'
                label={'Add another option'}
                variant='secondary'
                additionalVariant='skinless'
                icon={true}
                iconSrc={addIconSrc}
                disabled={mode === view}
                handleClick={() => {
                    const updatedResponseOptions = [...responseOptions];
                    updatedResponseOptions.push('');
                    setResponseOptions(updatedResponseOptions);
                    if (questionDetails.responseOptions) {
                        const updatedQuestionResponseOptions = [...questionDetails
                            .responseOptions];
                        updatedQuestionResponseOptions.push('');
                        setQuestionDetails({
                            ...questionDetails,
                            responseOptions: updatedQuestionResponseOptions,
                        });
                    }
                }}
            />
            <div className='formElementsDivider'></div>
        </>}
        <LabelledRadioButton
            id='responseOptional'
            label='Is this response optional?'
            breakColumn={false}
            onChange={(event) => {
                setQuestionDetails({
                    ...questionDetails,
                    responseOptional: event.target.value === 'true',
                });
            }}
            options={[{
                label: 'Yes',
                value: 'true',
                id: 'responseOptionalYes',
                name: 'responseOptional',
                checked: questionDetails.responseOptional,
                required: true,
                disabled: (mode === view),
            }, {
                label: 'No',
                value: 'false',
                id: 'responseOptionalNo',
                name: 'responseOptional',
                checked: !questionDetails.responseOptional,
                required: true,
                disabled: (mode === view),
            },
            ]}
            renderAsRow={true} />
        <div className='formElementsDivider'></div>
        <LabelledRadioButton
            id='supportingDocuments'
            label='Include supporting documents with response?'
            breakColumn={false}
            onChange={(event) => {
                setQuestionDetails({
                    ...questionDetails,
                    supportingDocuments: event.target.value === 'true',
                });
            }}
            options={[{
                label: 'Yes',
                value: 'true',
                id: 'supportingDocumentsYes',
                name: 'supportingDocuments',
                checked: questionDetails.supportingDocuments,
                required: true,
                disabled: (mode === view && questionDetails.supportingDocuments),
            }, {
                label: 'No',
                value: 'false',
                id: 'supportingDocumentsNo',
                name: 'supportingDocuments',
                checked: !questionDetails.supportingDocuments,
                required: true,
                disabled: (mode === view && !questionDetails.supportingDocuments)
                    || (questionDetails.responseType === Constants.RESP_TYPE.FILE),
            },
            ]}
            renderAsRow={true} />
        <div className='formElementsDivider'></div>
        <LabelledRadioButton
            id='scoringMechanism'
            label='Scoring mechanism'
            breakColumn={false}
            caption={type === selection ? 'Scored and Unscored scoring mechanisms are not applicable for selection questionnaire' : ''}
            onChange={(event) => {
                setQuestionDetails({
                    ...questionDetails,
                    scoringMechanism: event.target.value,
                });
                // if (event.target.value === 'scored') setShowWeightageInput(true);
                // if (event.target.value !== 'scored' && showWeightageInput) {
                //     setShowWeightageInput(false);
                // }
            }}
            required={true}
            options={[{
                label: passFailLabels.passOrFail,
                value: passFailValues.passFailPseudo,
                id: 'scoringMechanismPassFailPseudoPass',
                name: 'scoringMechanism',
                checked: questionDetails.scoringMechanism
                    ? questionDetails.scoringMechanism === passFailValues.passFailPseudo
                    : false,
                required: true,
                disabled: (mode === view && questionDetails.scoringMechanism
                    && questionDetails.scoringMechanism !== passFailValues.passFailPseudo),
            }, {
                label: passFailLabels.scored,
                value: passFailValues.scored,
                id: 'scoringMechanismScored',
                name: 'scoringMechanism',
                checked: questionDetails.scoringMechanism
                    ? questionDetails.scoringMechanism === passFailValues.scored
                    : false,
                required: true,
                disabled: ((mode === view
                    && questionDetails.scoringMechanism
                    && questionDetails.scoringMechanism !== passFailValues.scored)
                || (templateType === selection || type === selection)),
            }, {
                label: passFailLabels.unscored,
                value: passFailValues.unscored,
                id: 'scoringMechanismUnscored',
                name: 'scoringMechanism',
                checked: questionDetails.scoringMechanism
                    ? questionDetails.scoringMechanism === passFailValues.unscored
                    : false,
                required: true,
                disabled: ((mode === view
                    && questionDetails.scoringMechanism
                    && questionDetails.scoringMechanism !== passFailValues.unscored)
                || (templateType === selection || type === selection)),
            }]}
            renderAsRow={true} />
        {/* {showWeightageInput && <>
            <div className='formElementsDivider'></div>
            <div id='questionWeightageContainer'>
                <LabelledInput
                    id='questionWeightage'
                    type={LabelledInputTypeNumber}
                    label='Enter question weightage'
                    placeholder='Enter number'
                    value={questionDetails.questionWeightage || ''}
                    onChange={(event) => setQuestionDetails({
                        ...questionDetails,
                        questionWeightage: event.target.value,
                    })}
                    breakColumn={false}
                    required={true}
                    maxNumber={100}
                    disabled={mode === view}
                />
                <p className='title-large'> / 100</p>
            </div>
        </>} */}
    </>;

    return <Form id='questionnaireQuestionForm'
        onSubmit={(e) => {
            e.preventDefault();
            handleResult(e, questionDetails, mode, sectionNumber, sectionItemNumber);
            setQuestionDetails({});
        }}>
        {(mode === view)
            ? <Modal
                open={true}
                size={modalSizes.modal_xxl}
                closeModal={closeModal}
                headerTitle={`${mode} Question`}
                handleSecondaryActionBtnClick={closeModal}
                closeButton={true}
                body={generateBody()}
                helpOption={false}
                footer={false}
            />
            : <Modal
                open={true}
                size={modalSizes.modal_xxl}
                closeModal={closeModal}
                headerTitle={`${mode} Question`}
                secondActionButton={true}
                secondActionButtonTxt='Cancel'
                handleSecondaryActionBtnClick={closeModal}
                handleMainActionBtnClick={() => null}
                mainActionButtonTxt={(mode === 'Add' ? 'Add' : 'Update')}
                closeButton={true}
                body={generateBody()}
                helpOption={false}
            />}
    </Form>;
};

QuestionnaireQuestionModal.propTypes = {
    mode: PropTypes.string.isRequired,
    sectionNumber: PropTypes.number,
    sectionItemNumber: PropTypes.number,
    initialData: PropTypes.object,
    handleResult: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
};

export default QuestionnaireQuestionModal;
