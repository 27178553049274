import React from 'react';
import { Link } from 'react-router-dom';
import isSupplierRole from '../../utils/isSupplierRole';
import { Constants, BIG_SIZE_LOGOS } from './constants';
import helperFunctions from '../../utils/helperFunctions';
import RefineSupplier from '../RefineSupplier/index';
import shareIcon from '../../styles/images/share-icon.png';
import {
    routeToMarket, questionnaireTypes, RICH_TEXT, evaluationPanel, workflowEventTypes,
    allSteeringRoles,
} from '../../config/constants';
import StaticImage from '../../components/StaticImage';
import Button from '../../components/Button';
import confirmIconBlue from '../../styles/icons/blue/confirm.svg';
import editIconBlue from '../../styles/icons/blue/edit.svg';
import BrowsePageHelper from '../../utils/browsePageHelper';
import ShowMoreShowLess from '../../components/SeeMoreSeeLess';

const getProjectType = (projectDescribes) => {
    if (!Array.isArray(projectDescribes) || !projectDescribes.length) {
        return '-';
    }
    return projectDescribes
        .filter(Boolean)
        .map((role) => helperFunctions.camel2title(role))
        .join(', ') || '-';
};

const getWorkflowUrl = ({
    data, context, projectID, accountID, callOffMechanism,
}) => (accountID
    ? `/${context.toLowerCase()}/view/${data.workflowID}/${projectID}/import/${accountID}/${callOffMechanism}`
    : `/${context.toLowerCase()}/view/${data.workflowID}/${projectID}/import`);


const getViewSectionUrl = ({
    context, data, responseID, templateType, questionnaireID, projectID, eventID,
}) => {
    if (responseID) {
        return `/${context.toLowerCase()}/view/${data.sectionID}/import/${templateType || '_'}/${questionnaireID}/${projectID}/${eventID}/${responseID}`;
    }
    return `/${context.toLowerCase()}/view/${data.sectionID}/import/${templateType || '_'}/${questionnaireID || '_'}`;
};

const getCorrespondenceLinkURL = (originalTopicID, topicList, resourceType, resourceId) => {
    if (isSupplierRole(topicList[originalTopicID]?.senderAccount?.accountType)) {
        return `/${resourceType}/correspondence/overview/${resourceId}/${originalTopicID}/single`;
    }
    return `/${resourceType}/correspondence/overview/${resourceId}/${originalTopicID}`;
};

const getRecipientsName = (recipients) => {
    const recipientsNames = recipients
        .map((recipient) => recipient.account?.companyName);
    const recipientsNamesJoined = recipientsNames.join(', ');
    return recipientsNamesJoined;
};

const getSelectEventButtons = ({
    data, eventsSelected, context, selectEvents,
}) => (
    eventsSelected.indexOf(data.eventID) > -1
        ? <> <span className='event-selected'>{Constants.EVENT_SELECTED} </span><Button
            id={`${context.toLowerCase()}Btn-${data.eventID}`}
            variant= 'remove-link'
            label={Constants.REMOVE}
            handleClick={() => selectEvents(data.eventID) } /></>
        : <Button
            id={`${context.toLowerCase()}Btn-${data.eventID}`}
            variant= 'secondary small'
            iconSrc= {confirmIconBlue}
            icon={true}
            label={Constants.SELECT_EVENT}
            handleClick={() => selectEvents(data.eventID) } />
);


const contentMap = {
    [Constants.PROJECTS]: ({ data, selectedTab }) => ({
        link: `/projects/overview/${data.projectID}`,
        header: {
            title: data.title,
            extra: <>{(data.stages && data.stages.length > 0)
                && <p>{Constants.STAGE} {data.currentStage} {Constants.OF}
                    {Object.keys(data.stages).length}</p>
            }</>,
            pills: [{
                value: Constants.cancelled.title,
                className: 'browse-pills cancelled',
                showOnlyIf: { value: data.projectStatus === Constants.cancelled.value },
            }],
        },
        rows: [
            [
                { label: Constants.DESCRIPTION, value: data?.description || '-' },
                { label: Constants.BUYER, value: data?.account?.companyName || '-', showOnlyIf: { value: selectedTab === Constants.NEPO } },
            ],
            [
                { label: Constants.CONTRACT_START_DATE, value: data?.contractStart || '-' },
                { label: Constants.CONTRACT_END_DATE, value: data?.contractEnd || '-' },
            ]],
    }),
    [Constants.OPPORTUNITIES]: ({ data, authContext }) => ({
        link: authContext?.authenticated === true ? `/opportunities/overview/${data.opportunityID}` : `/opportunities/overview/public/${data.opportunityID}`,
        header: {
            title: data.opportunityName,
        },
        tags: [{ value: data?.account?.companyName || '-', className: 'card-tag' },
            { value: data?.eventName || '-', className: 'card-tag' }],
        grid: {
            left: [
                { label: Constants.ESTIMATED_VALUE, value: helperFunctions.formatCurrency(data.estimatedValue) !== '' ? helperFunctions.formatCurrency(data.estimatedValue) : '-' },
                { label: Constants.EXPRESSION_INTEREST_START_DATE, value: data?.expressionWindowStartDate ? helperFunctions.generatePrettyDate(data.expressionWindowStartDate) : '-' },
                { label: Constants.EXPRESSION_INTEREST_END_DATE, value: data?.expressionWindowEndDate ? helperFunctions.generatePrettyDate(data.expressionWindowEndDate) : '-' },
                { label: Constants.SUBMISSION_END_DATE, value: data?.submissionEndDate ? helperFunctions.generatePrettyDate(data.submissionEndDate) : '-' },
            ],
            right: [
                {
                    value: data.group && <StaticImage
                        name={data.group}
                        className={`buyer-logo${BIG_SIZE_LOGOS.includes(data.group) ? ' buyer-logo--big' : ''}`}
                    />,
                },
            ],
        },

    }),
    [Constants.SOLUTIONS]: ({ data, context }) => ({
        link: `/${context.toLowerCase()}/overview/${data.solutionID}`,
        header: {
            title: data.contractTitle,
            pills: [{
                value: Constants.cancelled.title,
                className: 'browse-pills cancelled',
                showOnlyIf: { value: data.solutionStatus === Constants.cancelled.value },
            }],
        },
        rows: [[

            { label: Constants.SOLUTION_BY, value: data?.account?.companyName || '-' },
            { label: Constants.START_DATE, value: data?.contractStart || '-' },
        ], [

            { label: Constants.PROJECT_TYPE, value: getProjectType(data.projectDescribes) },
            { label: Constants.CURRENT_END_DATE, value: data?.contractEnd || '-' },
        ]],
    }),
    [Constants.CONTRACTSREGISTER]: ({ data }) => ({
        link: `/contracts-register/overview/${data.contractID}`,
        header: {
            title: data.title,
            pills: [{
                value: `${Constants.AWARD_DATE} ${ helperFunctions.generatePrettyDate(data.awardDate) || '-'}`,
            }],
        },
        rows: [[

            { label: Constants.BUYER, value: data?.account?.companyName || '-' },
            { label: Constants.START_DATE, value: helperFunctions.generatePrettyDate(data?.contractStart) || '-' },
        ], [

            { label: Constants.END_DATE, value: helperFunctions.generatePrettyDate(data?.contractEnd) || '-' },

        ], [

            { label: Constants.ESTIMATED_VALUE, value: helperFunctions.formatCurrency(data.awardValue) !== '' ? helperFunctions.formatCurrency(data.awardValue) : '-' },

        ]],
    }),

    [Constants.SUPPLIERS]: ({
        data, contextSingular, opportunityID, context, routeToMarketParam,
        newDataSet, filteredData, projectID, eventID, lotID, setFilteredData, setNewDataSet,
    }) => ({
        link: `/${contextSingular.toLowerCase()}/overview/${data.supplierID}`,
        header: {
            title: data.accountName,
        },
        rows: [
            [
                { label: Constants.COMPANY_SIZE, value: data?.noOfEmployees || '-' },
                { label: Constants.POSTCODE, value: data?.postcode || '-' },
            ],
            [
                { label: Constants.LOCALITY, value: data?.localityName || '-' },
            ],
        ],
        extra: opportunityID
            ? <RefineSupplier
                supplier={data}
                withTags={true}
                newDataSet = {newDataSet}
                filteredData = {filteredData}
                context={context}
                projectID={projectID}
                opportunityID={opportunityID}
                routeToMarketParam={routeToMarketParam}
                eventID={eventID}
                lotID={lotID}
                handleFilteredData = {setFilteredData}
                handleNewDataSet={setNewDataSet}
            /> : undefined,

    }),

    [Constants.REQUESTS]: ({ data }) => ({
        link: `/procurement-requests/overview/${data.projectID}`,
        header: {
            title: data.title,
            pills: [{ value: Constants.cancelled.title, className: 'browse-pills cancelled', showOnlyIf: { value: data.projectStatus === Constants.cancelled.value } }],
        },
        rows: [
            [
                { label: Constants.DESCRIPTION, value: data?.description || '-' },
            ],
            [
                { label: Constants.REQUEST_START_DATE, value: data?.contractStart || '-' },
                { label: Constants.REQUEST_DURATION, value: data?.contractDuration || '-' },
            ]],
    }),

    [Constants.SPENDS]: ({ data, context, handleDownload }) => ({
        header: {
            title: data.filename.split('_')[0],
        },
        rows: [[
            { label: Constants.UPLOAD_DATE, value: data?.lastModified || '-' },
        ]],
        extra: <div className='Mui-extra-buttons'><Button
            id={`${context.toLowerCase()}Btn-${data.documentID}`}
            variant='secondary small'
            label={Constants.DOWNLOAD}
            handleClick={
                () => handleDownload(data)
            } /></div>,
    }),

    [Constants.REBATES]: ({ data, context, handleDownload }) => ({
        header: {
            title: data.filename.split('_')[0],
        },
        rows: [[
            { label: Constants.UPLOAD_DATE, value: data?.lastModified || '-' },
        ]],
        extra: <div className='Mui-extra-buttons'><Button
            id={`${context.toLowerCase()}Btn-${data.documentID}`}
            variant='secondary small'
            label={Constants.DOWNLOAD}
            handleClick={
                () => handleDownload(data)
            } /></div>,
    }),

    [Constants.WORKFLOWS]: ({
        data, action, context, projectID, accountID, callOffMechanism, contextSingular,
    }) => ({
        link: (action.type === Constants.VIEW ? `/workflows/view/${data.workflowID}` : getWorkflowUrl({
            data, context, projectID, accountID, callOffMechanism,
        })),
        header: {
            title: data.workflowName,
            prevImg: { value: <img src={shareIcon} alt={'shared'} className='share-icon'></img>, showOnlyIf: { value: data.accessGroups?.length > 0 || data.isPublic } },
            pills: [
                { value: Constants.cancelled.title, className: 'browse-pills cancelled', showOnlyIf: { value: data.status === Constants.disabled.value } },
                { value: helperFunctions.getPrettyObject(routeToMarket, Constants.ID, data.route, Constants.NAME, Constants.NOT_SELECTED), className: 'browse-pills completed' },
            ],
        },
        rows: [[
            { label: Constants.DESCRIPTION, value: data?.workflowDescription || '-' },
        ]],
        extra: action.type !== Constants.VIEW ? <div className='Mui-extra-buttons'> <Button
            id={`${context.toLowerCase()}Btn-${data.workflowID}`}
            variant= 'secondary small'
            label={`${Constants.SELECT} ${contextSingular}`}
            iconSrc= {confirmIconBlue}
            icon={true}
            handleClick={() => action.cb(data.workflowID) } /></div> : undefined,
    }),

    [Constants.QUESTIONNAIRESSELECT]: ({
        data, context, contextSingular, action,
    }) => ({
        link: `/questionnaires/view/${data.templateID}`,
        header: {
            title: data.templateName,
            pills: [
                { value: helperFunctions.getPrettyObject(questionnaireTypes, Constants.VALUE, data.templateType, Constants.LABEL), className: 'browse-pills completed' },
            ],
        },
        rows: [[
            { label: Constants.DESCRIPTION, value: helperFunctions.getHtmlOrPlainTextBlock(data.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION) },
        ],
        [
            { label: Constants.TYPE, value: data?.templateType ? helperFunctions.getPrettyObject(questionnaireTypes, Constants.VALUE, data.templateType, Constants.LABEL) : '-' },
            { label: Constants.DATE_CREATED, value: data?.createdOn ? helperFunctions.generatePrettyDate(data.createdOn) : '-' },
        ], [
            {
                label: evaluationPanel,
                value: helperFunctions.getEvaluationPanelLabel(data.evaluationPanel),
                showOnlyIf: { value: data.evaluationPanel },
            },
        ]],
        extra: <div className='Mui-extra-buttons'> <Button
            id={`${context.toLowerCase()}Btn-${data.templateID}`}
            variant='secondary small'
            label={`${Constants.SELECT} ${contextSingular}`}
            iconSrc= {confirmIconBlue}
            icon={true}
            handleClick={() => action.cb(data) } /></div>,
    }),

    [Constants.QUESTIONNAIRES]: ({ data }) => ({
        link: `/questionnaires/view/${data.templateID}`,
        header: {
            title: data.templateName,
            prevImg: { value: <img src={shareIcon} alt={'shared'} className='share-icon'></img>, showOnlyIf: { value: data.accessGroups?.length > 0 || data.isPublic } },
            pills: [
                { value: Constants.draft.title, className: 'browse-pills cancelled', showOnlyIf: { value: data.templateStatus === Constants.draft.value } },
                { value: Constants.disabled.title, className: 'browse-pills cancelled', showOnlyIf: { value: (data.isDisabled === true && data.templateStatus !== Constants.draft.value) } },
                { value: helperFunctions.getPrettyObject(questionnaireTypes, Constants.VALUE, data.templateType, Constants.LABEL), className: 'browse-pills completed' },
            ],
        },
        rows: [[
            { label: Constants.DESCRIPTION, value: helperFunctions.getHtmlOrPlainTextBlock(data.description || '-', RICH_TEXT.QUESTIONNAIRE_DESCRIPTION) },
        ],
        [
            { label: Constants.TYPE, value: data?.templateType ? helperFunctions.getPrettyObject(questionnaireTypes, Constants.VALUE, data.templateType, Constants.LABEL) : '-' },
            { label: Constants.DATE_CREATED, value: data?.createdOn ? helperFunctions.generatePrettyDate(data.createdOn) : '-' },
        ],
        [
            {
                label: evaluationPanel,
                value: helperFunctions.getEvaluationPanelLabel(data.evaluationPanel),
                showOnlyIf: { value: data.evaluationPanel },
            },
        ]],
    }),

    [Constants.SECTIONS]: ({
        data, action, context, responseID, templateType,
        questionnaireID, projectID, eventID, selectSection,
    }) => ({
        link: (action.type === Constants.VIEW ? `/sections/view/${data.sectionID}` : getViewSectionUrl({
            context, data, responseID, templateType, questionnaireID, projectID, eventID,
        })),
        header: {
            title: data.title,
        },
        rows: [[
            { label: Constants.DESCRIPTION, value: helperFunctions.getHtmlOrPlainTextBlock(data.description || '-', 'section-description') },
        ]],
        extra: action.type !== Constants.VIEW ? <div className='Mui-extra-buttons'> <Button
            id={`${context.toLowerCase()}Btn-${data.sectionID}`}
            variant= 'secondary small'
            label={Constants.IMPORT_SECTION}
            iconSrc= {confirmIconBlue}
            icon={true}
            handleClick={() => selectSection(data.sectionID) } /></div> : undefined,
    }),

    [Constants.CONTRACTS]: ({ data, authContext }) => ({
        link: isSupplierRole(authContext?.user?.accountType)
            ? `/contracts/overview/${data.contractID}`
            : `/contracts/management/${data.contractID}`,

        header: {
            title: data.title,
            pills: [{ value: Constants.cancelled.title, className: 'browse-pills cancelled', showOnlyIf: { value: data.contractStatus === Constants.cancelled.value } }],
        },
        rows: [
            [
                { label: Constants.DESCRIPTION, value: data?.description || '-' },
            ],
            [
                { label: Constants.CONTRACT_START_DATE, value: data?.contractStart || '-' },
                { label: Constants.CONTRACT_END_DATE, value: data?.contractEnd || '-' },
            ]],
    }),

    [Constants.CORRESPONDENCE]: ({
        data, authContext, resourceType, resourceId, topicList,
    }) => ({
        link: !isSupplierRole(authContext?.user?.accountType)
        && data?.senderAccountID === authContext.user.accountId
            ? `/${resourceType}/correspondence/overview/${resourceId}/${data.topicID}`
            : `/${resourceType}/correspondence/overview/${resourceId}/${data.topicID}/single`,
        header: {
            title: data.subject,
            prevImg: { value: <p className='newOrUnreadData tag'> </p>, showOnlyIf: { value: data.lastUnreadMessageSystemTimestamp } },
            className: data.lastUnreadMessageSystemTimestamp ? 'newOrUnreadTitleMargin' : '',
        },
        rows: [
            [{
                value: <p className={`value caption line-break ${helperFunctions.isLongMessage((data?.message)) ? 'ellipse' : ''}`}>
                    {data?.message ? helperFunctions.getHtmlOrPlainTextBlock(data.message) : '-'}
                </p>,
            }],
            [
                { label: Constants.CREATED_BY, value: data?.senderAccount?.companyName || '-' },
                { label: Constants.CREATED_ON, value: data?.createdOn ? helperFunctions.formatPrettyDateTime(data.createdOn) : '-' },
            ],
            [
                {
                    label: Constants.SEND_TO,
                    value: data.recipientAccountIDs?.length > 0 ? <ShowMoreShowLess
                        inputText={getRecipientsName(data.recipients)}
                        inputTextCharacterLimit={Constants.RECIPIENTS_MAX_CHAR} /> : Constants.all,
                    showOnlyIf: { value: !isSupplierRole(authContext?.user?.accountType) },
                },

            ],
        ],
        extra: !isSupplierRole(authContext?.user?.accountType) && data.originalTopicID
            ? <><label><strong>{Constants.originalTopic}</strong></label>
                <Link className='value caption' key={data.id} to={
                    getCorrespondenceLinkURL(data.originalTopicID,
                        topicList, resourceType, resourceId)
                }>
                    {Constants.originalTopicLinkTitle}
                </Link></>
            : undefined,
    }),

    [Constants.CALLOFF]: ({ data }) => ({
        link: `/calloff/overview/${data.projectID}`,
        header: {
            title: data.title,
            pills: [
                { value: BrowsePageHelper.getStatusPill(data.projectStatus, data.isAwarded) },
            ],
        },
        rows: [[
            { label: Constants.DESCRIPTION, value: data?.description || '-' },
            { label: Constants.CONTRACT_START_DATE, value: data?.contractStart || '-' },

        ],
        [
            { label: Constants.SUBMISSION_END_DATE, value: data?.submissionEndDate ? helperFunctions.formatPrettyDate(data.submissionEndDate) : '-' },
            { label: Constants.CONTRACT_END_DATE, value: data?.contractEnd || '-' },
        ]],
    }),

    [Constants.EVENTS]: ({
        data, handleView, context, contextSingular,
        handleEdit, action, eventsSelected, selectEvents,
    }) => ({
        link: undefined,
        handleClick: () => handleView(data.eventID),
        header: {
            title: data.eventName,
        },
        rows: [[
            { label: Constants.DESCRIPTION, value: data?.description || '-' },
            { label: Constants.DATE_CREATED, value: data?.createdOn ? helperFunctions.generatePrettyDate(data.createdOn) : '-' },
        ], [
            { label: Constants.TYPE, value: data?.eventType ? helperFunctions.getPrettyObject(workflowEventTypes, Constants.VALUE, data.eventType, Constants.LABEL) : '-' },
            { label: Constants.ROLE_RESPONSIBLE, value: data?.rolesResponsible ? helperFunctions.getPrettyObject(allSteeringRoles, Constants.ROLE, data.rolesResponsible, Constants.NAME, ' - ') : '-' },
        ]],
        extra: <div className='Mui-extra-buttons'> {action.type === Constants.VIEW
            ? <Button
                id={`${context.toLowerCase()}Btn-${data.eventID}-edit`}
                variant='secondary small'
                label={`${Constants.EDIT} ${contextSingular}`}
                iconSrc= {editIconBlue}
                icon={true}
                handleClick={() => handleEdit(data.eventID) } />
            : getSelectEventButtons({
                data, eventsSelected, context, selectEvents,
            })}</div>,
    }),

    [Constants.BROWSEDATACONTEXT]: ({ data }) => ({
        link: undefined,
        header: {
            title: data.message,
        },
        rows: [[
            { label: Constants.CREATED_BY, value: `${data?.firstname} ${data?.surname}, ${data?.accountName}` || '-' },
            { label: Constants.CREATED_ON, value: data?.systemTimestamp ? helperFunctions.formatPrettyDateTime(data.systemTimestamp) : '-' },
        ]],
    }),
    [Constants.STAGES]: ({
        data, context, action, history, contextSingular, selectStage,
    }) => ({
        link: `/${context.toLowerCase()}/view/${data.stageID}`,
        header: {
            title: data.stageName,
        },
        rows: [[
            { label: Constants.ROUTE_TO_MARKET, value: data?.routeToMarket ? helperFunctions.getPrettyObject(routeToMarket, 'id', data.routeToMarket, Constants.NAME, Constants.NOT_SELECTED) : '-' },
            { label: Constants.DATE_CREATED, value: data?.createdOn ? helperFunctions.generatePrettyDate(data.createdOn) : '-' },
        ], [
            { label: Constants.NUMBER_OF_EVENTS, value: data?.events?.length || '-' },
        ]],
        extra: <div className='Mui-extra-buttons'> {action.type === Constants.VIEW
            ? <Button
                id={`${context.toLowerCase()}Btn-${data.stageID}-edit`}
                variant='secondary small'
                label={`${Constants.EDIT} ${contextSingular}`}
                iconSrc= {editIconBlue}
                icon={true}
                handleClick={() => history.push(`/${context.toLowerCase()}/edit/${data.stageID}`)}
            />
            : <Button
                id={`${context.toLowerCase()}Btn-${data.stageID}`}
                variant= {'secondary small'}
                label={Constants.SELECT_STAGE}
                iconSrc= {confirmIconBlue}
                icon={true}
                handleClick={() => selectStage(data.stageID) }
            />}</div>,
    }),
};

export default contentMap;
