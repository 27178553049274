import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SelectSearch from 'react-select-search/dist/cjs';
import DatePicker from 'react-date-picker';
import {
    format, parse,
} from 'date-fns';
import AuthContext from '../../context/AuthContext';
import Form from '../../components/Form';
import LabelledInput, { LabelledInputTypeText, LabelledInputTypeNumber, LabelledInputTypeCurrency } from '../../components/LabelledInput';
import LabelledTextArea from '../../components/LabelledTextArea';
import LabelledSelect from '../../components/LabelledSelect';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import ScreenOverlay from '../../components/ScreenOverlay';
import userManagementAPIs from '../../services/user-management.service';
import clearIcon from '../../styles/icons/blue/clear.svg';
import Constants from './constants';
import projectManagementAPIs from '../../services/project-management.service';
import SelectExtensionsAvailable from '../../components/SelectExtensionsAvailable';
import Toast from '../../components/Alerts/Toast/Toast';
import Alert from '../../components/Alerts/Alert';
import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import Tag from '../../components/Tag';
import AddSupplierModal from '../AddSupplierModal/AddSupplierModal';
import helperFunctions from '../../utils/helperFunctions';
import { ProjectDataContext } from '../../context/ProjectDataContext';
import { ThemeContext } from '../../context/ThemeContext';
import { DATES_FORMAT } from '../../config/constants';
import { calculateEndDate, isValidReviewDate } from './helpers';

const ContractForm = () => {
    const [values, setValues] = useState({});
    const [userList, setUserList] = useState([]);
    const [contractUserList, setContractUserList] = useState([]);
    const [contractManager, setContractManager] = useState({});
    const [responsibleOfficer, setResponsibleOfficer] = useState({});
    const [lotList, setLotList] = useState([]);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [screenOverlay, setScreenOverlay] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showSupplierModal, setShowSupplierModal] = useState(false);
    const [suppliersData, setSuppliersData] = useState([]);

    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { setProjectData } = useContext(ProjectDataContext);
    const {
        type,
        contractID,
        projectID,
    } = useParams();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;

    const generateDropdownOptions = (key, option, defaultValue) => (
        (key > 0)
            ? < option key={key} value={option}> {option}</option >
            : <option key={key} value='' disabled>{defaultValue}</option>
    );

    // We get these users by steeringInfo now.
    const getAllAccountUsersRequest = async () => {
        try {
            const response = await userManagementAPIs.getAllUsersByAccount('approved');
            setUserList(response.data.map((userItem) => ({
                name: `${userItem.contactDetails?.firstname} ${userItem.contactDetails?.surname}`,
                value: userItem.userID,
                departmentName: userItem.departmentName,
                departmentID: userItem.departmentID,
                accountID: userItem.accountID,
            })));
            setContractUserList(response.data.map((userItem) => ({
                name: `${userItem.contactDetails?.firstname} ${userItem.contactDetails?.surname}`,
                value: userItem.userID,
                departmentName: userItem.departmentName,
                departmentID: userItem.departmentID,
                accountID: userItem.accountID,
            })));
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        const getProjectData = async (projectId) => {
            try {
                const response = await projectManagementAPIs.getProject(projectId);
                const contractStart = response.data?.contractStart ? parse(response.data.contractStart, 'dd-MM-yyyy', new Date()) : '';
                const contractDuration = response.data?.contractDuration;
                const extensionsAvailableInt = response.data.extensionsAvailable
                    ? response.data.extensionsAvailable.length : 0;

                const projectData = {
                    title: response.data.title,
                    description: response.data.description,
                    awardValue: response.data.actualValue,
                    contractStart,
                    contractDuration,
                    extensionsAvailable: response.data.extensionsAvailable,
                    extensionsAvailableInt,
                    projectId: response.data.projectID,
                    accountId: response.data.accountID,
                    buyer: response.data.buyer,
                    route: response.data.route,
                };
                setProjectData({ title: projectData.title });

                // Pass empty array if Lots not present, don't render dropdown
                const lots = response.data.lots ? response.data.lots : [];
                setValues(projectData);
                setLotList(lots);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to retrieve information.',
                });
            }
        };

        const getContractData = async (id) => {
            try {
                const response = await projectManagementAPIs.getContract(id);
                const contractStart = response.data?.startDate && response.data?.startDate !== '' ? parse(response.data.startDate, 'dd-MM-yyyy', new Date()) : '';
                const reviewDate = response.data?.reviewDate && response.data?.reviewDate !== '' ? parse(response.data.reviewDate, 'dd-MM-yyyy', new Date()) : '';
                const awardDate = response.data?.awardDate && response.data?.awardDate !== '' ? parse(response.data.awardDate, 'dd-MM-yyyy', new Date()) : '';

                const contractData = {
                    title: response.data.title,
                    description: response.data.description,
                    annualValue: response.data.annualValue,
                    awardValue: response.data.awardValue,
                    awardDate,
                    contractStart,
                    reviewDate,
                    contractDuration: response.data.contractDuration,
                    extensionsAvailable: response.data.extensionsAvailable,
                    extensionsAvailableInt: response.data.extensionCount,
                    projectId: response.data.projectID,
                    accountId: response.data.accountID,
                    buyer: response.data.buyer,
                    rigour: response.data.rigour,
                    unrecoverableVAT: response.data.unrecoverableVAT,
                    rebatePercentage: response.data.rebatePercentage,
                    contractManager: response.data.contractManager,
                    responsibleOfficer: response.data.responsibleOfficer,
                };
                setProjectData({ title: response.data.title });

                const lots = response.data.lots ? response.data.lots : [];
                setLotList(lots);
                setContractManager({
                    name: contractData?.contractManager?.user?.contactDetails?.username,
                    value: contractData?.contractManager?.userID,
                    accountID: contractData?.contractManager?.accountID,
                    departmentID: contractData?.contractManager?.departmentID,
                    userID: contractData?.contractManager?.userID,
                });
                setResponsibleOfficer({
                    name: contractData.responsibleOfficer?.user?.contactDetails?.username,
                    value: contractData.responsibleOfficer?.userID,
                });
                setValues(contractData);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to retrieve information.',
                });
            }
        };

        if (contractID) {
            getContractData(contractID);
        }

        if (projectID) {
            getProjectData(projectID);
        }
        getAllAccountUsersRequest();
    }, [projectID, contractID]);

    const handleChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleExtensionsChange = (event) => {
        const noOfInputs = event.target.value;
        const extensions = [];
        // if (noOfInputs && noOfInputs > 10) {
        //     Alert('Error', 'Extension should not be greater than 10', null, true);
        //     return;
        // }
        // if (values.extensionsAvailable && values.extensionsAvailable.length > 0) {
        //     for (let i = 0; i < noOfInputs; i++) {
        //         if (values.extensionsAvailable[i]) {
        //             extensions.push(values.extensionsAvailable[i]);
        //         } else {
        //             const extension = {
        //                 no: i,
        //                 monthsAvailable: '',
        //                 extensionStatus: '',
        //             };
        //             extensions.push(extension);
        //         }
        //     }
        // } else {
        //     for (let i = 0; i < noOfInputs; i++) {
        //         const extension = {
        //             no: i,
        //             monthsAvailable: '',
        //             extensionStatus: '',
        //         };
        //         extensions.push(extension);
        //     }
        // }
        for (let i = 0; i < noOfInputs; i++) {
            const extension = {
                no: i,
                monthsAvailable: '',
                extensionStatus: '',
            };
            extensions.push(extension);
        }
        setValues({
            ...values,
            extensionsAvailable: extensions,
        });
    };

    const handleExtension = (event) => {
        const existingExtensions = values.extensionsAvailable;
        existingExtensions[event.target.name].monthsAvailable = parseInt(event.target.value, 10) || '';
        setValues({
            ...values,
            extensionsAvailable: existingExtensions,
        });
    };

    const handleSelectSearchChange = (data, name) => {
        setValues({
            ...values,
            [name]: {
                id: data.value,
                userID: data.value,
                accountID: data.accountID,
                departmentID: data.departmentID,
                name: data.name,
            },
        });

        if (name === 'responsibleOfficer') {
            setResponsibleOfficer(data);
        }

        if (name === 'contractManager') {
            setContractManager(data);
        }
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        if (values.contractStart && values.contractDuration) {
            values.endDate = calculateEndDate(values.contractStart, values.contractDuration);
        }

        if (!isValidReviewDate(values.reviewDate, values.awardDate, values.endDate)) {
            Alert(Constants.ERROR, Constants.REVIEW_DATE_ERROR, null, true);
            return null;
        }

        if (type === 'create-public') {
            if (values.contractManager === undefined) {
                Alert('Warning', 'Contract Manager is required', null, true);
                return null;
            }
            if (values.responsibleOfficer === undefined) {
                Alert('Warning', 'Responsible User is required', null, true);
                return null;
            }

            if (suppliersData.length === 0) {
                setShowCreateModal(false);
                setScreenOverlay(false);
                Alert('Error', 'Please add at least one supplier.', null, true);
                return null;
            }
        }

        setScreenOverlay(true);
        setShowCreateModal(true);
        return null;
    };

    const handleCancel = (event) => {
        event.preventDefault();
        setScreenOverlay(true);
        setShowCancelModal(true);
    };

    const formatDate = (date) => format(date, DATES_FORMAT);

    const createDraftProject = async () => {
        const startDate = values.contractStart ? formatDate(values.contractStart) : undefined;
        const reviewDate = values.reviewDate ? formatDate(values.reviewDate) : undefined;
        const awardDate = values.awardDate ? formatDate(values.awardDate) : undefined;
        const endDate = values.endDate ? formatDate(values.endDate) : undefined;

        // Lot - values.lot filter
        const selectedLot = values.lot
            ? lotList.filter((obj) => obj.lotID === values.lot)
            : undefined;
        const lots = selectedLot
            ? [{ lotID: values.lot, title: selectedLot[0].title }]
            : undefined;
        const dataSubmission = {
            ...values,
            startDate,
            awardDate,
            endDate,
            reviewDate,
            extensionsAvailable: values.extensionsAvailable,
            lots,
            accessGroups: [authContext.user.id, authContext.user.accountId],
        };
        dataSubmission.responsibleOfficer = dataSubmission?.responsibleOfficer?.id;
        dataSubmission.contractManagerSteeringInfo = {
            userID: dataSubmission?.contractManager?.userID,
            accountID: dataSubmission?.contractManager?.accountID,
            departmentID: dataSubmission?.contractManager?.departmentID,
        };
        dataSubmission.contractManager = dataSubmission?.contractManager?.userID;
        const buyer = {
            userID: responsibleOfficer?.value,
            accountID: authContext.user.accountId,
        };
        dataSubmission.buyer = buyer;

        delete dataSubmission.extensionsAvailableInt;
        delete dataSubmission.contractStart;
        delete dataSubmission.lot;
        delete dataSubmission.projectId;
        delete dataSubmission.accountId;
        // This probably needs changing at variation level.
        let response;
        let successMsg = '';
        let errorMsg = '';

        if (type === 'create') {
            response = await projectManagementAPIs.createContract(dataSubmission, projectID);
            successMsg = 'Contract created successfully';
            errorMsg = 'Unable to create contract';
        }

        if (type === 'edit') {
            delete dataSubmission.accessGroups;
            delete dataSubmission.extensionsAvailable;
            delete dataSubmission.contractManager;
            delete dataSubmission.buyer;
            delete dataSubmission.contractManagerSteeringInfo;
            response = await projectManagementAPIs.updateContract(dataSubmission, contractID);
            successMsg = 'Contract updated successfully';
            errorMsg = 'Unable to update contract';
        }

        if (type === 'create-public') {
            if (Object.keys(dataSubmission).filter((attr) => attr === 'awardedSuppliersName').length === 0
            || dataSubmission?.awardedSuppliersName?.length === 0) {
                setShowCreateModal(false);
                setScreenOverlay(false);
                Alert('Error', 'Please add at least one supplier.', null, true);
                return;
            }
            delete dataSubmission.extensionsAvailable;
            dataSubmission.contractSource = 'CONTRACT_REGISTER';
            response = await projectManagementAPIs.createContract(dataSubmission, null);
            successMsg = 'Contract created successfully';
            errorMsg = 'Unable to create contract';
        }

        if (response.status === 201 || response.status === 200) {
            Toast.fire({
                icon: 'success',
                title: successMsg,
            });
            history.goBack();
        } else {
            Toast.fire({
                icon: 'error',
                title: errorMsg,
            });
        }
    };

    const getCancelBody = () => {
        switch (type) {
            case ('create'): return 'Are you sure you wish to cancel creating this contract?';
            case ('edit'): return 'Are you sure you wish to cancel updating this contract?';
            case ('create-public'): return 'Are you sure you wish to cancel creating this public contract?';
            default: return '';
        }
    };

    const getConfirmBody = () => {
        switch (type) {
            case ('create'): return 'Are you sure you wish to create this contract?';
            case ('edit'): return 'Are you sure you wish to update this contract?';
            case ('create-public'): return 'Are you sure you wish to create this public contract?';
            default: return '';
        }
    };

    const toggleSupplierModal = () => {
        setShowSupplierModal(!showSupplierModal);
    };

    const AddSuppliersDetails = (suppliersFromModal) => {
        const updatedSupplierData = [...suppliersData, ...suppliersFromModal];
        setSuppliersData(updatedSupplierData);
        setValues({
            ...values,
            awardedSuppliersName: updatedSupplierData.map((suppl) => suppl.supplierName),
        });
    };

    const removePill = (pillName) => {
        const tempSupplierData = suppliersData;
        const updatedSupplierData = tempSupplierData.filter((code) => code.supplierId !== pillName);
        setSuppliersData(updatedSupplierData);

        setValues({
            ...values,
            awardedSuppliersName: updatedSupplierData.map((suppl) => suppl.supplierName),
        });
    };

    const generateSupplierCodeTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.supplierId}
                id={item.supplierId}
                tagTxt={item.supplierName}
                isDeletable={isDeletable}
                size='small'
                handleDeleteBtnClick={() => removePill(item.supplierId)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    return (
        <>
            <Form className='row' id='dCFormContent' onSubmit={handleSubmit}>
                <p className='title-large sectionHeader keyInfo'>Key Information</p>
                <LabelledInput
                    id='title'
                    type={LabelledInputTypeText}
                    label='Contract title'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.title || ''}
                    placeholder='Enter title'
                    required={true}
                />
                <div id='contractDescription'>
                    <LabelledTextArea
                        id='description'
                        placeholder='Enter description'
                        breakColumn={true}
                        label='Contract description'
                        value={values.description || ''}
                        currentCharCount={values.description ? values.description.length : 0}
                        maxCharCount={3000}
                        onChange={(e) => handleChange(e)}
                        required={true}
                    />
                </div>
                <LabelledInput
                    id='awardValue'
                    type={LabelledInputTypeCurrency}
                    label='Award value (£)'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.awardValue || ''}
                    placeholder='Enter value'
                    required={type === 'create-public'}
                />
                <LabelledInput
                    id='annualValue'
                    type={LabelledInputTypeCurrency}
                    label='Annual value (£)'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.annualValue || ''}
                    placeholder='Enter value'
                    required={type === 'create-public'}
                />
                <LabelledSelect
                    id='rigour'
                    label='Select Rigour Level'
                    required={false}
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.rigour || ''}
                    options={Constants.rigourLevels
                        .map((option, idx) => generateDropdownOptions(idx, option, 'Select rigour level'))}
                />
                <div className='datePickerLabel'>
                    <label className='datePickerLabel title' htmlFor='contractStart'>Contract Start Date {type !== 'create-public' ? '(Optional)' : ''}</label>
                </div>
                <div className='datePickerWrapper' id='interestWindowRangeWrapper'>
                    <DatePicker
                        className='customDateInput nmiDate-imput'
                        id='contractStart'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        format={'dd-MM-y'}
                        calendarIcon={null}
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(date) => setValues({
                            ...values, contractStart: date,
                        })}
                        value={values?.contractStart || ''}
                        required={type === 'create-public'}
                    />
                    <div className='flex-break'></div>
                </div>
                <LabelledInput
                    id='contractDuration'
                    type={LabelledInputTypeText}
                    label='Contract duration in month(s)'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.contractDuration || ''}
                    placeholder='Enter duration'
                    pattern='^[0-9]+$'
                    title='Please enter a whole number'
                    required={type === 'create-public'}
                />
                <div className='datePickerLabel'>
                    <label className='datePickerLabel title' htmlFor='contractStart'>Contract review Date {type !== 'create-public' ? '(Optional)' : ''}</label>
                </div>
                <div className='datePickerWrapper' id='reviewDate'>
                    <DatePicker
                        className='customDateInput nmiDate-imput'
                        id='reviewDate'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        format={'dd-MM-y'}
                        calendarIcon={null}
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(date) => setValues({
                            ...values, reviewDate: date,
                        })}
                        value={values?.reviewDate || ''}
                        required={type === 'create-public'}
                    />
                    <div className='flex-break'></div>
                </div>
                {type !== 'create-public'
                && <SelectExtensionsAvailable
                    numberOfInputs={values.extensionsAvailable?.length.toString() || '0'}
                    handleExtensionsChange={handleExtensionsChange}
                    handleExtension={handleExtension}
                    values={values}
                    readonly={type === 'edit' && true}
                />}
                <LabelledInput
                    id='unrecoverableVAT'
                    type={LabelledInputTypeCurrency}
                    label='VAT that cannot be recovered (£)'
                    breakColumn={true}
                    required={false}
                    onChange={(e) => handleChange(e)}
                    value={values.unrecoverableVAT || ''}
                    disabled={type === 'edit' && true}
                    placeholder='Enter amount'
                />
                <LabelledInput
                    id='rebatePercentage'
                    type={LabelledInputTypeNumber}
                    label='Rebate Percentage'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.rebatePercentage || ''}
                    disabled={type === 'edit' && true}
                    placeholder='Enter rebate percentage'
                    required={false}
                />
                {type !== 'edit'
                && <>
                    <div className='labelled-select'>
                        <label className='form-label title selectSearchTitle' htmlFor='contractManager'>Assign a Contract Manager</label>
                        <SelectSearch
                            id='contractManager'
                            options={helperFunctions.constructGroupedDropdownData(contractUserList, 'departmentName') || []}
                            filterOptions={helperFunctions.handleFilter}
                            search={true}
                            printOptions='on-focus'
                            closeOnSelect={true}
                            placeholder={contractUserList.length > 0 ? 'Search users on Open' : 'No Users Available'}
                            disabled={!contractUserList.length > 0}
                            onChange={(event, data) => handleSelectSearchChange(data, 'contractManager')}
                            value={contractManager}
                            z-index='4'
                            required={type === 'create-public'}
                        />
                    </div>
                    <div className='flex-break'></div>
                </>}
                <div className='labelled-select'>
                    <label className='form-label title selectSearchTitle' htmlFor='responsibleOfficer'>Assign the Responsible User</label>
                    <SelectSearch
                        id='responsibleOfficer'
                        options={helperFunctions.constructGroupedDropdownData(userList, 'departmentName') || []}
                        filterOptions={helperFunctions.handleFilter}
                        search={true}
                        printOptions='on-focus'
                        closeOnSelect={true}
                        placeholder={userList.length > 0 ? 'Search users on Open' : 'No Users Available'}
                        disabled={!userList.length > 0}
                        onChange={(event, data) => handleSelectSearchChange(data, 'responsibleOfficer')}
                        value={responsibleOfficer}
                        z-index='4'
                        required={type === 'create-public'}
                    />
                </div>
                <div className='flex-break'></div>
                <div className='datePickerLabel'>
                    <label className='datePickerLabel title' htmlFor='contractStart'>Award Date {type !== 'create-public' ? '(Optional)' : ''}</label>
                </div>
                <div className='datePickerWrapper' id='interestWindowRangeWrapper'>
                    <DatePicker
                        className='customDateInput nmiDate-imput'
                        id='awardDate'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        format={'dd-MM-y'}
                        calendarIcon={null}
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(date) => setValues({
                            ...values, awardDate: date,
                        })}
                        value={values?.awardDate || ''}
                        required={type === 'create-public'}
                    />
                    <div className='flex-break'></div>
                </div>
                {type === 'create-public' && <>
                    <div id='supplierSectionContent'>
                        <div className='textIconContainer'>
                            <p className='title-large sectionHeader'>Suppliers</p>
                            <Button
                                id='addCodeButton'
                                size='small'
                                variant='secondary skinless'
                                label='Add'
                                icon={true}
                                iconSrc={addIconSrc}
                                disabled={false}
                                handleClick={(e) => {
                                    e.preventDefault();
                                    setShowSupplierModal(true);
                                }}
                            />
                        </div>
                        {(type === 'create-public' || type === 'update-public') && generateSupplierCodeTag(suppliersData, true)}
                    </div></>}
                <div className='flex-break'></div>
                <div className='actionButtonContainer'>
                    <Button id='second-action-btn'
                        variant='secondary'
                        label='Cancel'
                        handleClick={handleCancel} />
                    <Button id='main-action-btn'
                        label={type === 'edit' ? 'Update' : 'Create'}
                        handleClick={() => null} />
                </div>
            </Form>
            {showCancelModal && <Modal
                id='cancelBtnModal'
                open={true}
                size='small'
                headerTitle='Are you sure?'
                body={<p className='body'>{getCancelBody()}</p>}
                footer={true}
                mainActionButtonTxt='Yes'
                secondActionButtonTxt='No'
                secondActionButton={true}
                helpOption={false}
                handleMainActionBtnClick={(event) => {
                    event.preventDefault();
                    history.goBack();
                }
                }
                handleSecondaryActionBtnClick={() => {
                    setShowCancelModal(false);
                    setScreenOverlay(false);
                }}
                closeModal={() => { setShowCancelModal(false); setScreenOverlay(false); }} />}
            {showCreateModal && <Modal
                id='createBtnModal'
                open={true}
                size='small'
                headerTitle='Are you sure?'
                body={<p className='body'>{getConfirmBody()}</p>}
                footer={true}
                mainActionButtonTxt='Yes'
                secondActionButtonTxt='No'
                secondActionButton={true}
                helpOption={false}
                handleMainActionBtnClick={(event) => {
                    event.preventDefault();
                    createDraftProject();
                }
                }
                handleSecondaryActionBtnClick={() => {
                    setShowCreateModal(false);
                    setScreenOverlay(false);
                }}
                closeModal={() => {
                    setShowCreateModal(false);
                    setScreenOverlay(false);
                }}
            />}
            {showSupplierModal && <AddSupplierModal
                closeModal={toggleSupplierModal}
                addSuppliers={AddSuppliersDetails}
            />}
            {screenOverlay && <ScreenOverlay />}
        </>
    );
};

export default ContractForm;
