/* eslint-disable no-empty */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../components/Alerts/Toast/Toast';
import Button from '../../components/Button';
import Tabs from '../../components/Tabs';
import AuthContext from '../../context/AuthContext';
import actions from '../../screens/EditProjectWorkflow/slice/workflows.actions';

import addIcon from '../../styles/icons/white/add-ellipse.svg';
import addIconNew from '../../styles/icons/white/add-ellipse-2.svg';
import filterIcon from '../../styles/icons/white/filter.svg';
import Accordion from '../Accordion';
import BrowseFilterPanel from '../BrowseFilterPanel';
import SearchBar from '../SearchBar';
import SortDropdown from '../SortDropdown';
import BrowsePageHelper from '../../utils/browsePageHelper';
import isAdminRole from '../../utils/isAdminRole';
import isNepoRole from '../../utils/isNepoRole';
import isSupplierRole from '../../utils/isSupplierRole';
import {
    handleDownload,
} from '../../utils/uploadHelper';
import { Constants } from './constants';
import { ThemeContext } from '../../context/ThemeContext';
import { removePill } from '../RefineSupplier/helpers';
import {
    handleScroll,
    fetchStage,
    fetchSection,
    fetchEvent,
    formattedContext,
    generateAccordionData,
    getHeaderForSelectPage,
} from './helpers';

const BrowseData = ({
    id,
    context,
    exportFunction,
    contextSingular,
    dataSet,
    searchPlaceholder,
    sortOptions,
    tabOptions,
    handleTabSelected,
    action,
    newSpend,
    newRebate,
    allowFilter = true,
    allowAddNew = false,
    labelAddNew,
    handleAddNew,
    handleView,
    handleEdit,
    allowEdit = true,
    allowSort = true,
    defaultTab = null,
    allowBackButton = false,
    allowExportButton = false,
    handleBack,
    searchSortFilter = {},
    selectedTab,
    topicList,
}) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [newDataSet, setNewDataSet] = useState(dataSet);
    const [filteredData, setFilteredData] = useState([]);
    const [filterPanelOpen, setFilterPanelOpen] = useState(false);
    const [eventsSelected, setEventsSelected] = useState([]);
    const [searchSortFilterData, setSearchSortFilterData] = useState(searchSortFilter);
    const [lastPosition, setLastPosition] = useState(20);
    const {
        documentBuilderState,
    } = useSelector((state) => ({
        documentBuilderState: state.WorkflowsReducer.documentBuilderState,
    }));
    const dispatch = useDispatch();
    const {
        workflowID,
        projectID,
        routeToMarketParam,
        type,
        questionnaireID,
        templateType,
        resourceName,
        responseID,
        eventID,
        accountID,
        opportunityID,
        callOffMechanism,
        contractID,
        resourceType,
        resourceId,
        lotID,
    } = useParams();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addIconNew : addIcon;
    const perPage = 20;

    const handleSearchSortFilter = (data) => {
        const updatedState = {
            ...searchSortFilterData,
            ...data,
            filter: {
                ...searchSortFilterData.filter,
                ...data.filter,
                ...searchSortFilter.filter,
            },
        };

        // This is a temporary fix to fix browse workflows.
        // I don't know why it does not filter normally.
        if (data?.filter?.route) {
            updatedState.filter.route = data.filter.route;
        }

        if (data.filter && Object.keys(data.filter).length === 0) {
            updatedState.filter = {};
        }

        const filteredSet = BrowsePageHelper.filterData(updatedState, newDataSet);

        setSearchSortFilterData({
            ...updatedState,
        });
        setFilteredData(filteredSet.slice(0, perPage));
        setLastPosition(perPage);
    };

    const loadMoreData = () => {
        const filteredSet = BrowsePageHelper.filterData(searchSortFilterData, newDataSet);
        setFilteredData(filteredSet.slice(0, lastPosition + perPage));
        setLastPosition(lastPosition + perPage);
    };

    const uploadSpend = (event) => {
        event.preventDefault();
        newSpend();
    };

    const uploadRebate = (event) => {
        event.preventDefault();
        newRebate();
    };

    const throttle = (callbackFn, limit) => {
        let wait = false;
        return () => {
            if (!wait) {
                callbackFn.call();
                wait = true;
                setTimeout(() => {
                    wait = false;
                }, limit);
            }
        };
    };


    const toggleFilterPanel = () => setFilterPanelOpen(!filterPanelOpen);

    const selectEvents = (event) => {
        if (eventsSelected.indexOf(event) === -1) {
            setEventsSelected((events) => [
                ...events,
                event,
            ]);
        } else {
            setEventsSelected(eventsSelected.filter((e) => e !== event));
        }
    };


    const selectSection = (sectionID) => {
        fetchSection(sectionID)
            .then((results) => {
                const currentState = documentBuilderState;
                if (!currentState.sections) {
                    currentState.sections = [];
                }
                currentState.sections.push(results);
                dispatch(actions.setBuilderState(currentState));
                if (responseID) {
                    history.push(`/questionnaires/edit/${questionnaireID}/${projectID}/${eventID}/imported/${responseID}`);
                } else if (questionnaireID) {
                    history.push(`/questionnaires/edit/${questionnaireID}/_/_/imported`);
                } else {
                    history.push(`/questionnaires/draft/${type}/_/_/imported`);
                }
                // history.goBack();
            })
            .catch(() => {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to import section.',
                });
            });
    };


    const selectStage = (stageID) => {
        fetchStage(stageID)
            .then((results) => {
                const currentState = documentBuilderState;
                if (!currentState.sections) {
                    currentState.sections = [];
                }
                currentState.sections.push(results);
                dispatch(actions.setBuilderState(currentState));
                if (contractID === '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                    history.push(`/workflows/project/edit/${projectID}/${workflowID}/imported`);
                } else if (contractID && contractID !== '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                    history.push(`/workflows/contract/edit/${projectID}/${workflowID}/${contractID}/imported`);
                } else if (resourceName && resourceName !== '_' && projectID && projectID !== '_') {
                    history.push(`/workflows/${resourceName}/${projectID}/${routeToMarketParam}/create/imported`);
                } else if (workflowID && workflowID !== '_') {
                    history.push(`/workflows/edit/${workflowID}/imported`);
                } else {
                    history.push(`/workflows/_/_/${routeToMarketParam}/create/imported`);
                }
            })
            .catch(() => {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to import stage.',
                });
            });
    };

    const generateFilterPanel = () => <BrowseFilterPanel
        open={filterPanelOpen}
        closePanel={toggleFilterPanel}
        handleFilterCallback={handleSearchSortFilter}
        context={isSupplierRole(authContext?.user?.accountType)
            && context.toLowerCase() === 'contracts' ? 'contracts_for_supplier' : context}
        searchSortFilterData={searchSortFilterData}
    />;


    const importEvents = () => {
        Promise.all(eventsSelected.map((event) => fetchEvent(event)))
            .then((results) => {
                const currentState = documentBuilderState;
                if (!currentState.sections[action.stage].sectionItems) {
                    currentState.sections[action.stage].sectionItems = [];
                }
                currentState.sections[action.stage].sectionItems.push(...results);
                dispatch(actions.setBuilderState(currentState));

                if (contractID === '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                    history.push(`/workflows/project/edit/${projectID}/${workflowID}/imported`);
                } else if (contractID && contractID !== '_' && projectID && projectID !== '_' && workflowID && workflowID !== '_') {
                    history.push(`/workflows/contract/edit/${projectID}/${workflowID}/${contractID}/imported`);
                } else if (resourceName && resourceName !== '_' && projectID && projectID !== '_') {
                    history.push(`/workflows/${resourceName}/${projectID}/${routeToMarketParam}/create/imported`);
                } else if (workflowID && workflowID !== '_') {
                    history.push(`/workflows/edit/${workflowID}/imported`);
                } else {
                    history.push(`/workflows/_/_/${routeToMarketParam}/create/imported`);
                }

                // if (workflowID) {
                //     history.push(`/workflows/edit/${workflowID}/imported`);
                // } else {
                //     history.push(`/workflows/${routeToMarketParam}/create/imported`);
                // }
            })
            .catch(() => {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to import event.',
                });
            });
    };

    const getTabs = () => {
        let rbacTabs = tabOptions;
        if (!(isNepoRole(authContext?.user?.accountType)
            && isAdminRole(authContext))) {
            rbacTabs = tabOptions?.filter((option) => option !== 'All Organisations');
        }
        if (rbacTabs) {
            return <Tabs
                data={rbacTabs}
                handleClick={(event) => handleTabSelected(event)}
                defaultTab={defaultTab} />;
        }
        return <></>;
    };

    const getContentHeader = () => getHeaderForSelectPage({
        context,
        action,
        eventsSelected,
        contractID,
        projectID,
        workflowID,
        history,
        resourceName,
        routeToMarketParam,
        responseID,
        questionnaireID,
        type,
        eventID,
        importEvents,
    });

    const getSortFilterContainer = () => <div className='sortFilterContainer'>
        {(allowExportButton || (context === 'contractsRegister'))
            && <Button
                id={`${context.toLowerCase()}ExportButton`}
                label={Constants.EXPORT}
                handleClick={() => exportFunction()}
            />}
        {allowSort && <SortDropdown
            id={`${context.toLowerCase()}SortDropdown`}
            options={sortOptions}
            handleSortCallback={handleSearchSortFilter}
        />}
        {allowBackButton && <Button
            id={`${context.toLowerCase()}backButton`}
            label='Back'
            variant='normal-link'
            handleClick={() => handleBack()}
        />}
        {allowFilter && <Button
            id={`${context.toLowerCase()}FilterButton`}
            label='Filter'
            icon={true}
            iconSrc={filterIcon}
            handleClick={() => toggleFilterPanel()}
            variant='secondary'
        />}
        {allowAddNew && <Button
            id={'addNewButton'}
            label={labelAddNew}
            icon={true}
            iconSrc={addIconSrc}
            handleClick={() => handleAddNew()}
            variant='primary'
        />}
        {(context.toLowerCase() === 'spends') && <Button
            id={`${context.toLowerCase()}Btn-upload`}
            label={'Upload Spend'}
            variant='export-link'
            handleClick={(e) => uploadSpend(e)}
        />}
        {(context.toLowerCase() === 'rebates') && <Button
            id={`${context.toLowerCase()}Btn-upload`}
            label={'Upload Rebate'}
            variant='export-link'
            handleClick={(e) => uploadRebate(e)}
        />}
    </div>;


    const getContentBody = () => {
        if (filteredData.length > 0) {
            return <Accordion id='bdAccordion'
                isSupplierRole={isSupplierRole(authContext?.user?.accountType)}
                data={generateAccordionData({
                    toggle,
                    action,
                    accountID,
                    context,
                    authContext,
                    contextSingular,
                    history,
                    removePill,
                    filteredData,
                    setFilteredData,
                    opportunityID,
                    newDataSet,
                    projectID,
                    eventID,
                    routeToMarketParam,
                    setNewDataSet,
                    lotID,
                    selectedTab,
                    handleDownload,
                    selectSection,
                    handleView,
                    topicList,
                    resourceType,
                    resourceId,
                    selectStage,
                    selectEvents,
                    allowEdit,
                    handleEdit,
                    eventsSelected,
                    callOffMechanism,
                    templateType,
                })} open={true} />;
        }
        if (context.toLowerCase() === 'spends') {
            return <p className='caption'>{`No ${contextSingular} files have been uploaded yet.`}</p>;
        }
        if (context.toLowerCase() === Constants.selectQuestionnaires) {
            return <p className='caption'>{Constants.noResults}</p>;
        }
        return <p className='caption'>{`No ${formattedContext(context)} currently available.`}</p>;
    };


    useEffect(() => {
        setNewDataSet(dataSet);
        const throttledScrollHandler = throttle(handleScroll, 10);
        window.addEventListener('scroll', throttledScrollHandler);
        return () => {
            window.removeEventListener('scroll', throttledScrollHandler);
        };
    }, []);

    useEffect(() => {
        const updatedState = {
            ...searchSortFilterData,
            ...searchSortFilter,
            filter: {
                ...searchSortFilterData.filter,
                ...searchSortFilter.filter,
            },
        };
        const filteredSet = BrowsePageHelper.filterData(updatedState, dataSet);
        setFilteredData(filteredSet.slice(0, perPage));
        setSearchSortFilterData(updatedState);
        setNewDataSet(dataSet);
    }, [dataSet]);

    return <>
        <div className='bdContentSectionWrapper'>
            {getContentHeader()}
            <section className={`bdContentSection ${toggle ? 'new-bdContentSection' : ''}`} id={id}>
                {getTabs()}
                <div className='browse-content'>
                    <div className='bdContentHeader' id='bdContentHeader'>
                        <div className='searchBarContainer'>
                            <SearchBar
                                id={`${context.toLowerCase()}SearchBar`}
                                placeholder={searchPlaceholder}
                                handleSearchCallback={handleSearchSortFilter}
                            />
                        </div>
                        {getSortFilterContainer()}
                    </div>
                    <div className='bdContentBody' aria-live='assertive'>
                        {filteredData && <InfiniteScroll
                            dataLength={filteredData.length}
                            next={loadMoreData}
                            hasMore={true}
                            endMessage={
                                <p className='title' id='endMessage'>
                                    <b>{Constants.YOU_HAVE_SEEN_IT_ALL}</b>
                                </p>
                            }
                        >
                            {getContentBody()}
                        </InfiniteScroll>}
                    </div>
                </div>
                {filterPanelOpen && generateFilterPanel()}
            </section >
        </div>
    </>;
};

BrowseData.propTypes = {
    id: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    exportFunction: PropTypes.func,
    action: PropTypes.object,
    newSpend: PropTypes.func,
    newRebate: PropTypes.func,
    contextSingular: PropTypes.string.isRequired,
    dataSet: PropTypes.array.isRequired,
    searchPlaceholder: PropTypes.string.isRequired,
    searchFilterAttribute: PropTypes.string.isRequired,
    sortOptions: PropTypes.array.isRequired,
    tabOptions: PropTypes.array,
    handleTabSelected: PropTypes.func,
    allowFilter: PropTypes.bool,
    allowAddNew: PropTypes.bool,
    labelAddNew: PropTypes.string,
    handleAddNew: PropTypes.func,
    handleView: PropTypes.func,
    handleEdit: PropTypes.func,
    allowEdit: PropTypes.bool,
    allowSort: PropTypes.bool,
    defaultTab: PropTypes.number,
    allowBackButton: PropTypes.bool,
    allowExportButton: PropTypes.bool,
    handleBack: PropTypes.func,
    searchSortFilter: PropTypes.object,
    selectedTab: PropTypes.string,
    topicList: PropTypes.array,
};

export default BrowseData;
