import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import userIcon from '../../styles/icons/white/user-2.svg';
import helperFunctions from '../../utils/helperFunctions';
import dpsConstants from './constants';
import { IS_SUPPLIER_DISQUALIFIED, CURRENT } from '../../config/constants';
import actions from '../../rbac/constants';

import {
    getSubmissionEvaluationStatus,
    lotWithIncompleteEvaluations,
    showSubmissionStatusLabel,
    getLineLabels,
    getLineButtons,
    buttonsContainButton,
    removePermissionFromPermissions,
    getPrettyQuestionnaireType,
    getSupplierSubmissionNumber,
    getSubmissionNumberIntentStatus,
    getSubmissionsGroupBySubmissionNumber,
    findResponsesById,
    getElementWithUniqueId,
    removeViewPermissionIfEvaluator,
    getTreeViewId,
    getAllLotsMap,
    getAllSuppliersMap,
    getSupplierAllLotsResponses,
} from '../../services/evaluation.service';

import { hasQuestionsAssigned } from '../../services/response.service';
import {
    getResponseLabels,
    getResponseButtons,
    getSupplierButtons,
    getSubmissionStatus,
    getSubmissionScoreLabels,
    labelObjectDisqualifySupplier,
    labelObjectIncompleteEvaluations,
    labelObjectIntentStatus,
    labelObjectAction,
    getAction,
} from './summaryBuyerDPSConfig';

const getSupplierLabel = (value) => (
    <p className='title'>
        <img src={userIcon} alt={dpsConstants.USER} id={dpsConstants.USER_ICON} className='user-icon'></img>{value}</p>
);

const getSubmissionLabel = (submissionNumber, value, index, withCurrentSubmission) => (
    <p className='submission-label'>
        {index === 0 && withCurrentSubmission ? `${dpsConstants.CURRENT_TITLE} ` : ''}{`${dpsConstants.SUBMISSION_TITLE} #${submissionNumber}`}
        <span>{dpsConstants.SUBMITTED_AT}: {helperFunctions.formatPrettyDateTime(value[0]
            .tenderSubmittedAt)}</span>
    </p>
);

const getResponsesMenu = (readOnly, userPermissions, response, id, userSteeringRole) => {
    const menu = [];
    let clonedUserPermissions = {
        ...userPermissions,
        [IS_SUPPLIER_DISQUALIFIED]: response.supplierDisqualified,
    };
    const evalStatus = response.evaluationStatus;
    const responseLabels = getResponseLabels(evalStatus);
    const responseButtons = hasQuestionsAssigned(response) ? getResponseButtons(evalStatus) : [];

    if (response.supplierDisqualified && !readOnly) {
        menu.push(getElementWithUniqueId(labelObjectDisqualifySupplier, id));
    } else if (responseLabels?.length) {
        menu.push(...getLineLabels(readOnly, undefined, responseLabels, response, id));
    }

    if (responseButtons?.length) {
        if (!readOnly) {
            clonedUserPermissions = removeViewPermissionIfEvaluator(responseButtons,
                clonedUserPermissions, dpsConstants);
        }
        menu.push(...getLineButtons(responseButtons, clonedUserPermissions,
            readOnly, response, id, userSteeringRole));
    }
    return menu;
};


const getSubmissionMenu = (readOnly, userPermissions, intentStatuses,
    submission, id, submitForApprovalAvailable, userSteeringRole) => {
    const menu = [];
    let clonedUserPermissions = { ...userPermissions };
    const supplierSubmissionNumber = getSupplierSubmissionNumber(submission[0]);
    const intentStatus = getSubmissionNumberIntentStatus(intentStatuses, supplierSubmissionNumber);
    const isCurrentSubmission = supplierSubmissionNumber === CURRENT;
    const evalStatus = getSubmissionEvaluationStatus(submission);
    const supplierButtons = isCurrentSubmission ? getSupplierButtons(evalStatus) : [];
    const { supplierDisqualified } = submission[0];

    if (supplierButtons?.length) {
        if (supplierDisqualified && buttonsContainButton(supplierButtons,
            dpsConstants.buttonDisqualifySupplierId)) {
            clonedUserPermissions = removePermissionFromPermissions(userPermissions,
                actions.disqualifySuppliers);
        }
        if (!submitForApprovalAvailable && buttonsContainButton(supplierButtons,
            dpsConstants.buttonSubmitForApprovalId)) {
            clonedUserPermissions = removePermissionFromPermissions(clonedUserPermissions,
                actions.submitEvaluationsForApproval);
        }
        const buttons = getLineButtons(supplierButtons, clonedUserPermissions,
            readOnly, submission[0], id, userSteeringRole);
        menu.push(...buttons);
    }

    const submissionStatusLabel = getSubmissionStatus(evalStatus);
    if (submissionStatusLabel && showSubmissionStatusLabel(evalStatus,
        submission[0].legalStatus)) {
        menu.push(...getLineLabels(readOnly, isCurrentSubmission,
            [submissionStatusLabel], submission, id));
    }

    if (intentStatus?.intentStatus) {
        const intentStatusLabel = labelObjectIntentStatus(intentStatus.intentStatus);
        menu.push(getElementWithUniqueId(intentStatusLabel, id));
    }

    const submissionScoreLabels = getSubmissionScoreLabels(evalStatus);
    if (submissionScoreLabels?.length) {
        menu.push(...getLineLabels(readOnly, isCurrentSubmission,
            submissionScoreLabels, submission, id));
    }
    return menu;
};

const getSupplierMenu = (currentSubmissionResponses, userPermissions, id) => {
    const menu = [];
    const evalStatus = getSubmissionEvaluationStatus(currentSubmissionResponses);
    const action = getAction(evalStatus);
    if (action) {
        const userHasPermission = userPermissions[action.permission];
        const actionLabel = labelObjectAction(action, userHasPermission);
        menu.push(getElementWithUniqueId(actionLabel, id));
    }
    return menu;
};

const getLotsMenu = (lot, userPermissions, id) => (lotWithIncompleteEvaluations(lot,
    userPermissions) ? [getElementWithUniqueId(labelObjectIncompleteEvaluations, id)] : []);

const getResponses = (
    readOnly, userPermissions, indexArray, responses, userSteeringRole,
) => (responses
    .reduce((acc, response, index) => {
        const indexes = [...indexArray, { id: dpsConstants.RESPONSE, value: index }];
        const id = getTreeViewId(indexes);
        return acc.concat({
            key: uuidv4(),
            id,
            label: `${response.responseName} ${response.responseType ? getPrettyQuestionnaireType(response.responseType) : ''}`,
            menu: getResponsesMenu(readOnly, userPermissions, response, id, userSteeringRole),
        });
    }, []));

const getSubmissions = (readOnly, userPermissions,
    indexArray, intentStatuses, currentResponses,
    previousResponses,
    submitForApprovalAvailable,
    userSteeringRole) => {
    let allSubmissions = {};
    allSubmissions = previousResponses
        ? getSubmissionsGroupBySubmissionNumber(previousResponses) : {};
    let withCurrentSubmission = false;
    if (currentResponses) {
        const lastSubmissionNumber = Object.keys(allSubmissions)[Object
            .keys(allSubmissions).length - 1];
        allSubmissions[Number(lastSubmissionNumber) + 1] = currentResponses;
        withCurrentSubmission = true;
    }

    return (Object.entries(allSubmissions)
        .reverse()?.reduce((acc, [, value], index) => {
            const indexes = [...indexArray, { id: dpsConstants.SUBMISSION, value: index }];
            const id = getTreeViewId(indexes);
            const submissionNumber = Object.entries(allSubmissions).length - index;
            return acc.concat({
                key: uuidv4(),
                id,
                openState: index === 0 && withCurrentSubmission,
                label: getSubmissionLabel(submissionNumber, value, index, withCurrentSubmission),
                nodes: value
                    ? getResponses(readOnly, userPermissions, indexes, value, userSteeringRole)
                    : [],
                menu: getSubmissionMenu(readOnly, userPermissions, intentStatuses,
                    value, id, submitForApprovalAvailable),
            });
        }, []));
};

const getAllSuppliersWithActions = (currentResponses, previousResponses) => {
    const allSuppliers = [...(currentResponses || []), ...(previousResponses || [])];
    return allSuppliers.map((supplier) => {
        const evalStatus = getSubmissionEvaluationStatus(supplier.responses);
        return ({
            ...supplier,
            withAction: !!getAction(evalStatus),
        });
    });
};

const getSuppliers = (readOnly, userPermissions, indexArray, suppliersResponses,
    previousSuppliersResponses, allResponses, userSteeringRole) => {
    const allSuppliersWithActions = getAllSuppliersWithActions(
        suppliersResponses, previousSuppliersResponses,
    );

    const suppliersMap = getAllSuppliersMap(allSuppliersWithActions);
    return Object.entries(suppliersMap)
        ?.reduce((acc, [key], index) => {
            const currentSupplier = findResponsesById(suppliersResponses,
                dpsConstants.ACCOUNT_ID, key);
            const previousSupplier = findResponsesById(previousSuppliersResponses,
                dpsConstants.ACCOUNT_ID, key);
            const intentStatuses = (currentSupplier
                ?.intentStatuses || []).concat(previousSupplier?.intentStatuses || []);
            const submitForApprovalAvailable = helperFunctions
                .isSubmitForApprovalAvailable(getSupplierAllLotsResponses(allResponses, key));
            const indexes = [...indexArray, { id: dpsConstants.SUPPLIER, value: index }];
            const id = getTreeViewId(indexes);
            const menu = currentSupplier
                ? getSupplierMenu(currentSupplier.responses, userPermissions, id) : undefined;
            return (acc.concat({
                key: uuidv4(),
                id,
                openState: menu?.length > 0,
                label: getSupplierLabel(currentSupplier?.account?.companyName
                    || previousSupplier?.account?.companyName),
                nodes: getSubmissions(readOnly, userPermissions, indexes,
                    intentStatuses, currentSupplier?.responses,
                    previousSupplier?.responses, submitForApprovalAvailable, userSteeringRole),
                menu,
            }));
        }, []);
};


const getLots = (readOnly, userPermissions, currentResponses,
    previousResponses, openNodes, userSteeringRole) => {
    const lotsMap = getAllLotsMap(currentResponses, previousResponses, userPermissions);
    return (Object.entries(lotsMap)
        .reduce((acc, [key, lot], index) => {
            const indexes = [{ id: dpsConstants.LOT, value: index }];
            const id = getTreeViewId(indexes);
            const supplierResponses = findResponsesById(currentResponses,
                dpsConstants.LOT_ID, key)?.responses;
            const supplierPreviousResponses = findResponsesById(previousResponses,
                dpsConstants.LOT_ID, key)?.responses;
            const lotsMenu = supplierResponses
                ? getLotsMenu(supplierResponses, userPermissions, id) : undefined;
            return acc.concat({
                key: uuidv4(),
                id,
                elementId: lot.lotID,
                openState: openNodes?.includes(lot.lotID) && lotsMenu?.length > 0,
                label: lot.lotTitle,
                nodes: getSuppliers(readOnly, userPermissions, indexes, supplierResponses,
                    supplierPreviousResponses, currentResponses, userSteeringRole),
                menu: lotsMenu,
            });
        }, []));
};

const prepareEvaluationSummary = (readOnly, userPermissions, currentResponses,
    previousResponses, openNodes, userSteeringRole) => getLots(
    readOnly, userPermissions, currentResponses, previousResponses, openNodes, userSteeringRole,
);


export default prepareEvaluationSummary;
