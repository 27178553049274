import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
    SelectComponent,
    InputComponent,
    RadioButtonComponent,
} from './components';
import Button from '../../components/Button';
import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import Tag from '../../components/Tag';
import { ThemeContext } from '../../context/ThemeContext';

const SelectDACodesModal = React.lazy(() => import('../RegionsOfSupplyDA/SelectDAModal'));

const FTSNameAndAddresses = ({
    prefix,
    formFields,
    handleChange,
    registerFlag = true,
    contactPointFlag = true,
    buyerAddressFlag = true,
    nationalIDFlag = true,
    nutsCodeFlag = true,
    smeFlag = false,
    internetAddressNameUpdateFlag = false,
}) => {
    const [showDaModal, setShowDaModal] = useState(false);
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;

    const getPropObjValue = (object) => {
        const parts = prefix?.split('.');
        let returnProp = null;
        if (parts !== null && parts !== undefined) {
            for (let i = 0; i < parts.length; i++) {
                if (object[parts[i]]) {
                    returnProp = object[parts[i]];
                    // eslint-disable-next-line no-param-reassign
                    object = returnProp;
                } else { return null; }
            }
        }
        return returnProp;
    };

    const handleRemoveDA = () => {
        handleChange('', `${prefix}.n2021`);
        handleChange([], `${prefix}.notMappedn2021Code`);
    };

    const generateDATag = (data, isDeletable) => {
        const TagList = [];

        data.forEach((item, index) => {
            const TagElement = <>
                <Tag key={item.code}
                    id={item.code}
                    tagTxt={`${item.code} - ${item.description}`}
                    isDeletable={isDeletable}
                    size='large'
                    handleDeleteBtnClick={() => handleRemoveDA()}
                />
                <input type='hidden' name={`${prefix}.n2021.${index}`} value={item.code} />
            </>;
            TagList.push(TagElement);
        });

        return (
            <div className='vpnItemTags'>
                {TagList}
            </div>
        );
    };

    const handleUpdateDACodes = (data) => {
        if (data.length > 0) {
            handleChange([data[0].code], `${prefix}.n2021`);
            handleChange(data, `${prefix}.notMappedn2021Code`);
        } else {
            handleChange('', `${prefix}.n2021`);
            handleChange([], `${prefix}.notMappedn2021Code`);
        }
    };

    return (<>
        <InputComponent
            id={`${prefix}.officialName`}
            label='Official Name'
            type='text'
            value={getPropObjValue(formFields)?.officialName}
            required={true}
            onChange={handleChange}
        />
        {registerFlag
        && <SelectComponent
            id={`${prefix}.register`}
            label='Identification Register'
            value={getPropObjValue(formFields)?.register}
            required={true}
            onChange={handleChange}
            options={{
                'GB-COH': 'Companies House',
                'GB-CHC': 'Charity Commission (England and Wales)',
                'GB-SC': 'Scottish Charity Register',
                'GB-NIC': 'Charity Commission for Northern Ireland',
                'GB-NHS': 'NHS Organisation Data Service',
                'GB-UKPRN': 'UK Register of Learning Providers (UKPRN number)',
                'GB-MPR': 'Mutuals Public Register',
                'GG-RCE': 'Guernsey Registry',
                'JE-FSC': 'Jersey Financial Services Commission Registry',
                'IM-CR': 'Isle of Man Companies Registry',
                OTHER: 'Other',
                NO_SCHEME: 'None',
            }}
        />}
        {nationalIDFlag && getPropObjValue(formFields)?.register !== 'NO_SCHEME'
        && <InputComponent
            id={`${prefix}.nationalID`}
            label='National ID'
            type='text'
            value={getPropObjValue(formFields)?.nationalID}
            required={true}
            onChange={handleChange}
            pattern={'\'.*[^\\s].*\'.'}

        />}
        {getPropObjValue(formFields)?.register === 'OTHER'
        && <InputComponent
            id={`${prefix}.registerOther`}
            label='Register Other'
            type='text'
            value={getPropObjValue(formFields)?.registerOther}
            required={true}
            onChange={handleChange}
        />}
        {getPropObjValue(formFields)?.register === 'NO_SCHEME'
        && <RadioButtonComponent
            id={`${prefix}.justification`}
            label='Justification'
            value={getPropObjValue(formFields)?.justification}
            required={true}
            onChange={handleChange}
            options={{
                PARTNERSHIP: 'Partnership',
                SOLE_TRADER: 'Sole trader',
                NOT_ON_ANY_REGISTER: 'Not on any register',
            }}
            renderAsRow={false}
        />}
        <InputComponent
            id={`${prefix}.address`}
            label='Address'
            type='text'
            value={getPropObjValue(formFields)?.address}
            required={false}
            onChange={handleChange}
        />
        <InputComponent
            id={`${prefix}.town`}
            label='Town/city'
            type='text'
            value={getPropObjValue(formFields)?.town}
            required={true}
            onChange={handleChange}
        />
        <InputComponent
            id={`${prefix}.postalCode`}
            label='Postcode'
            type='text'
            value={getPropObjValue(formFields)?.postalCode}
            required={false}
            onChange={handleChange}
        />
        <SelectComponent
            id={`${prefix}.country`}
            label='Country'
            value={getPropObjValue(formFields)?.country}
            required={true}
            onChange={handleChange}
            options={{
                UK: 'United Kingdom',
            }}
        />
        {nutsCodeFlag
        && <div className='vpnItem'>
            <div className='vpnItemNutsCode'>
                <h4 className='title'>NUTS Code</h4>
                <Button
                    id='addCodeButton'
                    size='small'
                    variant='secondary skinless'
                    label='Add'
                    icon={true}
                    iconSrc={addIconSrc}
                    disabled={false}
                    handleClick={(e) => {
                        e.preventDefault();
                        setShowDaModal(true);
                    }}
                />
            </div>
            {generateDATag(getPropObjValue(formFields)?.notMappedn2021Code || [], true)}
        </div>}
        {contactPointFlag
        && <InputComponent
            id={`${prefix}.contactPoint`}
            label='Contact Person'
            type='text'
            value={getPropObjValue(formFields)?.contactPoint}
            required={false}
            onChange={handleChange}
        />}
        <InputComponent
            id={`${prefix}.email`}
            label='Email Address'
            type='email'
            value={getPropObjValue(formFields)?.email}
            required={true}
            onChange={handleChange}
        />
        <InputComponent
            id={`${prefix}.phone`}
            label='Telephone'
            type='text'
            value={getPropObjValue(formFields)?.phone}
            required={false}
            onChange={handleChange}
            pattern={'(\\+\\d{1,3}\\s\\d+(\\-\\d+)*((\\s)?/(\\s)?(\\+\\d{1,3}\\s)?\\d+(\\-\\d+)*)*)'}
            title={'Telephone must start with the country code followed by a space, for example +44 1234567890'}
        />
        <div className='formElementsDivider'></div>
        <h4 className='title-large subHeading'>Internet address(es)</h4>

        {internetAddressNameUpdateFlag ? <InputComponent
            id={`${prefix}.url`}
            label='Internet Address'
            type='text'
            value={getPropObjValue(formFields)?.url}
            required={true}
            pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]{2,}'}
            title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
            onChange={handleChange}
        /> : <InputComponent
            id={`${prefix}.urlGeneral`}
            label='Main Address'
            type='text'
            value={getPropObjValue(formFields)?.urlGeneral}
            required={true}
            pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]{2,}'}
            title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
            onChange={handleChange}
        /> }

        {buyerAddressFlag
        && <InputComponent
            id={`${prefix}.buyerAddress`}
            label='Address of the buyer profile'
            type='text'
            pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]{2,}'}
            title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
            value={getPropObjValue(formFields)?.urlBuyer}
            required={false}
            onChange={handleChange}
        />}
        {smeFlag
        && <RadioButtonComponent
            id={`${prefix}.sme`}
            label='Is the contractor is an SME?'
            value={getPropObjValue(formFields)?.sme}
            required={true}
            onChange={handleChange}
            options={{
                true: 'Yes',
                false: 'No',
            }}
        />}
        {showDaModal
        && <SelectDACodesModal
            closeModal={() => setShowDaModal(false)}
            onSubmit={handleUpdateDACodes}
            selectedCodes={getPropObjValue(formFields)?.n2021}
            multiple={false}
        />}
    </>
    );
};

FTSNameAndAddresses.propTypes = {
    formFields: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    prefix: PropTypes.string.isRequired,
    contactPointFlag: PropTypes.bool,
    registerFlag: PropTypes.bool,
    buyerAddressFlag: PropTypes.bool,
    nationalIDFlag: PropTypes.bool,
    nutsCodeFlag: PropTypes.bool,
    smeFlag: PropTypes.bool,
    internetAddressNameUpdateFlag: PropTypes.bool,
};

export default FTSNameAndAddresses;
