import projectManagementAPIs from '../../services/project-management.service';
import {
    userRoles,
    SKELETON_MODES,
    LIMIT_3,
    LIMIT_5,
} from '../../config/constants';
import {
    NO_CONTRACTS_AVAILABLE,
    NO_CALLOFFS_AVAILABLE,
    NO_PROCUREMENTS_REQUESTS_AVAILABLE,
    NO_ASIGNED_TASK_AVAILABLE,
    NO_PROJECTS_AVAILABLE,
} from './constants';

const DASHBOARD_KEYS = {
    PROC_REQUESTS: 'getDashboardProcRequests',
    PROJECTS: 'getDashboardProjects',
    CALL_OFFS: 'getDashboardCallOffs',
    PROC_REQUESTS_APPROVAL: 'getDashboardProcRequestsAwaitingApproval',
    TASKS_AND_EVENTS: 'getDashboardTasksAndEvents',
    CONTRACTS: 'getDasboardContracts',
};


const DASHBOARD_OPTIONS = [
    {
        method: DASHBOARD_KEYS.PROC_REQUESTS,
        userRole: [userRoles.basic, userRoles.intermediate, userRoles.advanced, userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: NO_PROCUREMENTS_REQUESTS_AVAILABLE,
        limit: LIMIT_3,
        mode: SKELETON_MODES.COLUMN,
    },
    {
        method: DASHBOARD_KEYS.PROJECTS,
        userRole: [userRoles.basic, userRoles.intermediate, userRoles.advanced, userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: NO_PROJECTS_AVAILABLE,
        limit: LIMIT_3,
        mode: SKELETON_MODES.COLUMN,
    },
    {
        method: DASHBOARD_KEYS.CALL_OFFS,
        userRole: [userRoles.basic, userRoles.intermediate, userRoles.advanced, userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: NO_CALLOFFS_AVAILABLE,
        limit: LIMIT_3,
        mode: SKELETON_MODES.COLUMN,
    },
    {
        method: DASHBOARD_KEYS.PROC_REQUESTS_APPROVAL,
        userRole: [userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: NO_PROCUREMENTS_REQUESTS_AVAILABLE,
        limit: LIMIT_5,
        mode: SKELETON_MODES.ROW,
    },
    {
        method: DASHBOARD_KEYS.TASKS_AND_EVENTS,
        userRole: [userRoles.basic, userRoles.intermediate, userRoles.advanced, userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: NO_ASIGNED_TASK_AVAILABLE,
        limit: LIMIT_5,
        mode: SKELETON_MODES.ROW,
    },
    {
        method: DASHBOARD_KEYS.CONTRACTS,
        userRole: [userRoles.basic, userRoles.intermediate, userRoles.advanced, userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: NO_CONTRACTS_AVAILABLE,
        limit: LIMIT_5,
        mode: SKELETON_MODES.COLUMN,
    },
];


export { DASHBOARD_OPTIONS, DASHBOARD_KEYS };
