import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import userIcon from '../../../../styles/icons/white/user.svg';
import newUserIcon from '../../../../styles/icons/white/profile.svg';
import chevronDown from '../../../../styles/icons/white/chevron-down.svg';
import { ThemeContext } from '../../../../context/ThemeContext';

const UserNavMenuToggleButton = ({ handleToggleBtnClick, firstName }) => {
    const { toggle } = useContext(ThemeContext);
    return (
        <div id='userNavMenuButtonContainer'>
            <button id='userNavMenuToggleButton'
                onClick={(e) => handleToggleBtnClick(e)}>
                <img src={toggle ? newUserIcon : userIcon} id='userIcon' alt='user'/>
                <a className='title' id='userNavMenuToggleBtnLabel'>{`Hello, ${firstName}`}</a>
                <img src={chevronDown} alt='user-options' id='dropdownIcon' />
            </button>
        </div>
    );
};

UserNavMenuToggleButton.propTypes = {
    handleToggleBtnClick: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired,
};

export default UserNavMenuToggleButton;
