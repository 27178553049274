import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/Modal';
import LabelledInput from '../../../components/LabelledInput';
import monitoringAPIs from '../../../services/monitoring.service';
import ErrorIcon from '../../../styles/icons/red/error.svg';

const CompanySearch = ({
    currentStep, onSubmit, closeModal, skipStep,
}) => {
    const [companyNumber, setCompanyNumber] = useState('');
    const [error, setError] = useState(false);

    const companyHouseNumberRegex = /^[A-Za-z]{2}[0-9]{6}|[0-9]{8,8}$/;

    const handleInput = (e) => {
        setCompanyNumber(e.target.value);
    };

    const searchCompany = async (e) => {
        if (companyHouseNumberRegex.test(companyNumber)) {
            const res = await monitoringAPIs.getSupplierById(companyNumber);
            if (res.status === 200) {
                setError(false);
                onSubmit(e, res.data);
            } else {
                setError(true);
            }
        }
    };

    const modalBody = <>
        {
            error
                ? <div className='success-container'>
                    <img src={ErrorIcon} alt='Error' className='success-img'></img>
                    <p className='body error'>Sorry, we couldn’t find your company records with the number entered. Please try again or skip this step.</p>
                </div>
                : <></>
        }
        <LabelledInput
            id='company-id'
            type='text'
            label='Enter your company registration number and we will get your information from Companies House to help speed up the process.'
            placeholder='e.g. 0123456'
            value={companyNumber}
            onChange={(event) => handleInput(event)}
            breakColumn={false}
            required={true}
            maxLength={8}
            pattern='^[A-Za-z]{2}[0-9]{6}|[0-9]{8,8}$'
            title={'Please enter a valid 8 digit company house number, including zeros. For example, 12345678 or 00999999.'}
        />
    </>;
    if (currentStep !== 4) {
        return null;
    }
    return <>
        <Modal
            open={true}
            closeModal={closeModal}
            size='medium'
            headerTitle='Company Search '
            handleMainActionBtnClick={(e) => searchCompany(e)}
            mainActionButtonTxt='Continue'
            closeButton={false}
            body={modalBody}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='Skip'
            handleSecondaryActionBtnClick={(e) => { e.preventDefault(); skipStep(); }}
        />
    </>;
};

CompanySearch.propTypes = {
    currentStep: PropTypes.number,
    onSubmit: PropTypes.func,
    closeModal: PropTypes.func,
    skipStep: PropTypes.func,
};

export default CompanySearch;
