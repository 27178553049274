import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DATA_THEME, DATA_THEME_STYLES } from '../config/constants';

const ThemeContext = React.createContext(false);

const ThemeProvider = ({ children }) => {
    const [toggle, setToggle] = useState(true);
    const toggleFunction = () => {
        setToggle(!toggle);
    };

    useEffect(() => {
        document.documentElement.setAttribute(
            DATA_THEME,
            toggle ? DATA_THEME_STYLES.newStyle : DATA_THEME_STYLES.defaultStyle,
        );
    }, [toggle]);

    return (
        <ThemeContext.Provider value={{ toggle, toggleFunction }}>
            {children}
        </ThemeContext.Provider>
    );
};

ThemeProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { ThemeContext, ThemeProvider };
