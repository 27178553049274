const Constants = {
    current: 'current',
    upcoming: 'upcoming',
    past: 'past',
    pending: 'pending',
    inProgress: 'in-progress',
    stage1approval: 'stage1approval',
    stage2approval: 'stage2approval',
    complete: 'complete',
    cancelled: 'cancelled',
    documents: [
        {
            name: 'Terms & Conditions',
            btnLabel: 'View',
        },
        {
            name: 'Pricing Schedule',
            btnLabel: 'View',
        },
        {
            name: 'Selection Questionnaire',
            btnLabel: 'View',
        },
    ],

    managementDocuments: [
        {
            name: 'Rebate Template',
            btnLabel: 'Download',
        },
        {
            name: 'Rebate Uploads',
            btnLabel: 'View',
        },
        {
            name: 'KPI Questions Template',
            btnLabel: 'Download',
        },
        {
            name: 'KPI Uploads',
            btnLabel: 'View',
        },
    ],
    awardSummary: 'View Award Summary',
    activityLog: 'Activity Log',
    activityLogId: 'contractActivityLog',

};

export default Constants;
