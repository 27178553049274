import Toast from '../../components/Alerts/Toast/Toast';
import ToastConstants from '../../components/Alerts/Toast/constants';
import projectManagementAPIs from '../../services/project-management.service';
import { downloadDocumentsLimit } from '../../config/constants';

const flatLotResponses = (submissionResponses) => submissionResponses
    .flatMap((submission) => submission.responses);

const flatSubmissions = (submissions) => submissions
    .flatMap((submission) => (submission.lotID
        ? flatLotResponses(submission.responses) : submission.responses));

const getResponseIDsWithDocs = async (responsesIDs) => {
    if (!responsesIDs?.length) return null;

    try {
        const { status, data } = await projectManagementAPIs
            .getAllDocumentsByResponseIDs(responsesIDs);
        if (status === 200) return data;
        throw new Error(ToastConstants.unableToRetrieveInfo);
    } catch (error) {
        Toast.fire({
            icon: ToastConstants.error,
            titleText: ToastConstants.unableToRetrieveInfo,
        });
        return null;
    }
};

const getDocuments = (response) => response.supportingAnswerDocuments.map((doc) => doc);

const getLotsResponseDocuments = (response, docList) => {
    const result = {};
    response.lots.forEach((lot) => {
        const existingDocs = docList.withLots[lot.lotTitle] || [];
        const documents = getDocuments(response);
        result[lot.lotTitle] = [...new Set([...existingDocs, ...documents])];
    });
    return result;
};

const getLotResponseDocumentsBySupplier = (supplier, docList) => {
    const result = {};
    supplier.lots.forEach((lot) => {
        const existingDocs = docList.withLots[lot.lotTitle] || [];
        const lotDocs = getDocuments(lot);
        result[lot.lotTitle] = [...new Set([...existingDocs, ...lotDocs])];
    });
    return result;
};

const getDocListStructure = (response, docList, isBySupplier = false) => {
    const result = { ...docList };
    if (response.lots?.length) {
        const lotsResponseDocs = isBySupplier
            ? getLotResponseDocumentsBySupplier(response, result)
            : getLotsResponseDocuments(response, result);

        result.withLots = { ...result.withLots, ...lotsResponseDocs };
    } else {
        result.noLots = [...result.noLots, ...getDocuments(response)];
    }
    if (isBySupplier) {
        result.supplierName = response.supplier?.companyName;
    }

    return result;
};

const getDocumentsByLot = (lots, supportingAnswerDocuments) => lots.map((lot) => ({
    lotTitle: lot.lotTitle,
    supportingAnswerDocuments: [...new Set(supportingAnswerDocuments)],
}));

const addDocumentsByLot = (currentLots, newLots, supportingAnswerDocuments) => {
    const uniqueDocs = [...new Set(supportingAnswerDocuments)];
    const result = [...currentLots];

    newLots.forEach((lot) => {
        const lotIndex = result.findIndex((l) => l.lotTitle === lot.lotTitle);

        if (lotIndex > -1) {
            result[lotIndex].supportingAnswerDocuments = [
                ...new Set([...result[lotIndex].supportingAnswerDocuments, ...uniqueDocs]),
            ];
        } else {
            result.push({
                lotTitle: lot.lotTitle,
                supportingAnswerDocuments: uniqueDocs,
            });
        }
    });
    return result;
};

const getSuppliersWithDocsFromResponses = (responses) => responses
    .reduce((accBySupplier, currentResponse) => {
        const { supplierAccountID, lots = [], supportingAnswerDocuments = [] } = currentResponse;
        const lotted = lots.length > 0;
        const groupIndex = accBySupplier
            .findIndex((item) => item.supplierAccountID === supplierAccountID);

        if (groupIndex > -1) {
            const group = accBySupplier[groupIndex];

            if (lotted) {
                group.lots = addDocumentsByLot(group.lots, lots, supportingAnswerDocuments);
            } else {
                group.supportingAnswerDocuments = [
                    ...new Set([...group.supportingAnswerDocuments, ...supportingAnswerDocuments]),
                ];
            }
        } else {
            const supplierName = currentResponse.supplier.companyName.replace(/\./g, ' ');
            const newSupplier = {
                supplierAccountID,
                supplier: { companyName: supplierName },
            };

            if (lotted) {
                newSupplier.lots = getDocumentsByLot(lots, supportingAnswerDocuments);
            } else {
                newSupplier.supportingAnswerDocuments = [...supportingAnswerDocuments];
            }

            accBySupplier.push(newSupplier);
        }

        return accBySupplier;
    }, []);

const getDocumentsIds = async (responses) => {
    if (!responses?.length) {
        return { supplierName: '', withLots: {}, noLots: [] };
    }

    const responseIDs = [...new Set(responses.map((response) => response.responseID))];
    if (!responseIDs.length) {
        return { supplierName: '', withLots: {}, noLots: [] };
    }
    const responseIDWithDocsList = await getResponseIDsWithDocs(responseIDs);
    if (!responseIDWithDocsList) {
        return { supplierName: '', withLots: {}, noLots: [] };
    }

    const responsesWithDocs = responseIDWithDocsList.map((responseIDWithDocs) => {
        const flatResponse = responses
            .find((resp) => responseIDWithDocs.responseID === resp.responseID);
        return { ...flatResponse, ...responseIDWithDocs };
    });

    const totalDocs = responsesWithDocs
        .flatMap((response) => response.supportingAnswerDocuments || []);
    if (!totalDocs.length) {
        return [];
    }

    const newDocList = { supplierName: '', withLots: {}, noLots: [] };

    if (totalDocs.length > downloadDocumentsLimit) {
        const supplierWithDocs = getSuppliersWithDocsFromResponses(responsesWithDocs);
        return supplierWithDocs.map((supplier) => getDocListStructure(supplier, newDocList, true));
    }

    return [
        responsesWithDocs.reduce((acc, response) => getDocListStructure(response, acc), newDocList),
    ];
};

export {
    getDocumentsIds,
    flatSubmissions,
};
