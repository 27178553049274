import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import LabelledInput, { LabelledInputTypeCurrency, LabelledInputTypeNumber, LabelledInputTypeText } from '../../components/LabelledInput';
import AuthContext from '../../context/AuthContext';
import LabelledTextarea from '../../components/LabelledTextArea';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import LabelledSelect from '../../components/LabelledSelect';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Tag from '../../components/Tag';
import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
import actions from '../CodesModal/slice/codes.action';
import actionsRegion from '../RegionsOfSupply/slice/supplyRegions.action';
import actionsLocality from '../LocalityModal/slice/supplyLocalities.action';
import Constants from '../CreateProcurementForm/constants';
import Toast from '../../components/Alerts/Toast/Toast';
import Alert from '../../components/Alerts/Alert';
import projectService from '../../services/project.service';
import { ProjectDataContext } from '../../context/ProjectDataContext';
import { ThemeContext } from '../../context/ThemeContext';

const SelectCodesModal = React.lazy(() => import('../CodesModal/SelectCodesModal'));
const SupplyRegionsModal = React.lazy(() => import('../RegionsOfSupply/SupplyRegionsModal'));
const LotForm = ({
    mode, projectID, lotID, solutionID,
}) => {
    const [showCodesModal, setShowCodesModal] = useState(false);
    const [showRegionsModal, setShowRegionsModal] = useState(false);
    const [initialCodesData, setInitialCodesData] = useState([]);
    const [initialRegionsData, setInitialRegionsData] = useState([]);
    const [values, setValues] = useState({});
    const [codeData, setCodeData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const authContext = useContext(AuthContext);
    const { setProjectData } = useContext(ProjectDataContext);
    const history = useHistory();
    const dispatch = useDispatch();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;

    const {
        initCodesData, onlySelectedCodes, initCodesRegions, onlySelectedRegions,
    } = useSelector((state) => ({
        initCodesData: state.CodesReducer.selectedCodes,
        onlySelectedCodes: state.CodesReducer.onlySelectedCodes,
        initCodesRegions: state.SupplyRegionsReducer.selectedRegions,
        onlySelectedRegions: state.SupplyRegionsReducer.onlySelectedRegions,
    }));

    const collateInitialCodeData = (data) => {
        const initialCodeData = [];

        if (data && data.length > 0) {
            data.forEach((code) => {
                initialCodeData.push(code);
            });
            setInitialCodesData(initialCodeData);
        }
    };

    const collateInitialRegionData = (data) => {
        const initialRegionData = [];

        if (data && data.length > 0) {
            data.forEach((code) => {
                initialRegionData.push(code);
            });
            setInitialRegionsData(initialRegionData);
        }
    };

    useEffect(() => {
        collateInitialCodeData(values.industryInfo);
        collateInitialRegionData(values.deliveryAreaInfo);
        dispatch(actions.setOnlySelectedCodes([]));
        dispatch(actionsRegion.setOnlySelectedRegions([]));
    }, []);

    const removeCodesSelection = (parent, level) => {
        parent.map((el) => {
            const elem = el;
            if (el.classificationID === level) {
                elem.selected = false;
            }
            if (el.children) removeCodesSelection(el.children, level);
            return null;
        });
        return initCodesData;
    };

    const updateCodesSelectionView = (pillName) => {
        dispatch(actions.setOnlySelectedCodes(onlySelectedCodes
            .filter((el) => el.classificationID !== pillName)));
    };

    const handlePillDelete = (pillName) => {
        removeCodesSelection(initCodesData, pillName);
        updateCodesSelectionView(pillName);
    };

    const removePill = (pillName) => {
        handlePillDelete(pillName);
        const tempCodeData = initialCodesData;
        const updatedData = tempCodeData?.filter((code) => code.classificationID !== pillName);
        const rawCodeData = values.industryInfo;
        const industryInfoObject = [];
        rawCodeData?.forEach((codeObject) => {
            if (codeObject.classificationID !== pillName) {
                industryInfoObject.push(codeObject);
            }
        });
        setInitialCodesData(updatedData);
        setValues({
            ...values,
            industryInfo: updatedData,
        });
        if (onlySelectedCodes.length > 0) {
            setInitialCodesData([]);
        }
    };

    const removeRegionSelection = (parent, pillName) => {
        parent.map((el) => {
            const elem = el;
            if (el.code === pillName) {
                elem.selected = false;
            }
            if (el.cities) { removeRegionSelection(el.cities, pillName); }
            if (el.counties) { removeRegionSelection(el.counties, pillName); }
            return null;
        });
        return initCodesRegions;
    };

    const updateRegionSelectionView = (pillName) => {
        dispatch(actionsRegion.setOnlySelectedRegions(onlySelectedRegions
            .filter((el) => el.code !== pillName)));
    };

    const removeRegionPill = (pillName) => {
        removeRegionSelection(initCodesRegions, pillName);
        updateRegionSelectionView(pillName);
        const tempCodeData = initialRegionsData;
        const updatedData = tempCodeData?.filter((region) => region.code !== pillName);
        setInitialRegionsData(updatedData);
        setValues({
            ...values,
            deliveryAreaInfo: updatedData,
        });
        if (onlySelectedRegions.length > 0) {
            setInitialRegionsData([]);
        }
    };

    const mapCodes = (data) => {
        const industryInfoObject = [];
        data?.forEach((codeObject) => {
            const industryObject = {
                classificationID: codeObject.classificationID,
                level: codeObject.level,
                category: codeObject.category,
            };
            industryInfoObject.push(industryObject);
        });
        setValues({
            ...values,
            industryInfo: industryInfoObject,
        });
        return industryInfoObject;
    };

    const mapRegions = (data) => {
        const regionInfoObject = [];
        data?.forEach((region) => {
            const regionObject = {
                code: region.code,
                description: region.description,
            };
            regionInfoObject.push(regionObject);
        });
        setValues({
            ...values,
            deliveryAreaInfo: regionInfoObject,
        });
        return regionInfoObject;
    };

    useEffect(() => {
        if (onlySelectedCodes && onlySelectedCodes.length > 0) {
            setCodeData(onlySelectedCodes);
            const industryInfoObject = mapCodes(onlySelectedCodes);
            setValues({
                ...values,
                industryInfo: industryInfoObject,
            });
        } else if (initialCodesData) {
            setCodeData(initialCodesData);
        }
    }, [onlySelectedCodes, initialCodesData]);

    useEffect(() => {
        if (onlySelectedRegions && onlySelectedRegions.length > 0) {
            setRegionData(onlySelectedRegions);
            const regionInfoObject = mapRegions(onlySelectedRegions);
            setValues({
                ...values,
                deliveryAreaInfo: regionInfoObject,
            });
        } else if (initialRegionsData) {
            setRegionData(initialRegionsData);
        }
    }, [onlySelectedRegions, initialRegionsData]);

    const generateDropdownOptionsForCallOfMechanism = (key, value, label, defaultValue) => (
        (key > 0)
            ? <option key={key} value={value}>{label}</option >
            : <option key={key} value='' disabled>{defaultValue}</option>
    );

    const getProjectDetails = async () => {
        try {
            const res = await projectManagementAPIs.getProject(projectID);
            if (res.status === 200) {
                setInitialCodesData(res.data.industryInfo || []);
                setInitialRegionsData(res.data.deliveryAreaInfo || []);
                const industryInfoObject = mapCodes(res.data.industryInfo);
                const regionInfoObject = mapRegions(res.data.deliveryAreaInfo);
                setValues({
                    ...values,
                    industryInfo: industryInfoObject,
                    deliveryAreaInfo: regionInfoObject,
                });
                setProjectData({ title: res.data.title });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const getLotDetails = async () => {
        try {
            let response;
            if (authContext.authenticated === true) {
                response = await projectManagementAPIs.getLotDetails(lotID);
            } else {
                response = await projectManagementAPIs.getPublicLotDetails(lotID);
            }
            if (response.status === 200) {
                setInitialRegionsData(response.data.deliveryAreaInfo || []);
                setInitialCodesData(response.data.industryInfo || []);
                const industryInfoObject = mapCodes(response.data.industryInfo);
                const regionInfoObject = mapRegions(response.data.deliveryAreaInfo);
                setValues({
                    ...values,
                    industryInfo: industryInfoObject,
                    deliveryAreaInfo: regionInfoObject,
                    description: response.data.description,
                    callOffMechanism: response.data.callOffMechanism,
                    noOfSuppliers: response.data.noOfSuppliers,
                    competitionRules: response.data.competitionRules,
                    title: response.data.title,
                    estimatedValue: response.data.estimatedValue,
                    lotID: response.data.lotID,
                    contractStart: response.data.contractStart,
                    contractEnd: response.data.contractEnd,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const fetchLotForCallOff = async () => {
        const response = await projectManagementAPIs.getLotViaSolution(solutionID, lotID);
        if (response.status === 200) {
            setInitialRegionsData(response.data.deliveryAreaInfo || []);
            setInitialCodesData(response.data.industryInfo || []);
            const industryInfoObject = mapCodes(response.data.industryInfo);
            const regionInfoObject = mapRegions(response.data.deliveryAreaInfo);
            setValues({
                ...values,
                industryInfo: industryInfoObject,
                deliveryAreaInfo: regionInfoObject,
                description: response.data.description,
                callOffMechanism: response.data.callOffMechanism,
                noOfSuppliers: response.data.noOfSuppliers,
                competitionRules: response.data.competitionRules,
                title: response.data.title,
                estimatedValue: response.data.estimatedValue,
                lotID: response.data.lotID,
                contractStart: response.data.contractStart,
                contractEnd: response.data.contractEnd,
            });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const cleanup = () => {
        dispatch(actions.clearCodes());
        dispatch(actionsRegion.clearRegions());
        dispatch(actionsLocality.clearLocalities());
    };

    useEffect(() => {
        cleanup();
    }, []);

    useEffect(() => {
        if (lotID && solutionID) {
            fetchLotForCallOff();
        }
        if (lotID) {
            getLotDetails();
        }
        return () => {
            cleanup();
        };
    }, [projectID, lotID]);

    useEffect(() => {
        if (mode === 'create') {
            getProjectDetails();
        }
    }, [mode]);

    const addLot = async (event) => {
        event.preventDefault();
        if (values?.industryInfo?.length === 0) {
            Alert('Error', 'Please add at least one code and category.', null, true);
            return null;
        }
        if (values?.deliveryAreaInfo?.length === 0) {
            Alert('Error', 'Please add at least one delivery area.', null, true);
            return null;
        }

        const data = {
            ...values,
            noOfSuppliers: values.noOfSuppliers
                ? parseInt(values.noOfSuppliers, 10) : undefined,
            projectID,
        };
        const responseData = await projectManagementAPIs.createLot(data);
        if (responseData.status === 201) {
            Toast.fire({
                icon: 'success',
                titleText: 'Lot created successfully.',
            });
            history.push(`/projects/overview/${projectID}`);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to create lot.',
            });
        }
        return null;
    };

    const updateLot = async (event) => {
        event.preventDefault();
        if (values?.industryInfo?.length === 0) {
            Alert('Error', 'Please add at least one code and category.', null, true);
            return null;
        }
        if (values?.deliveryAreaInfo?.length === 0) {
            Alert('Error', 'Please add at least one delivery area.', null, true);
            return null;
        }
        const payload = {
            ...values,
            noOfSuppliers: parseInt(values.noOfSuppliers, 10),
            projectID,
        };
        delete payload.lotID;
        delete payload.createdOn;
        delete payload.contractStart;
        delete payload.contractEnd;
        if (payload.competitionRules === undefined) {
            delete payload.competitionRules;
        }
        const responseData = await projectManagementAPIs.updateLot(lotID, payload);

        if (responseData.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Lot updated successfully.',
            });
            history.push(`/projects/overview/${projectID}`);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update lot.',
            });
        }
        return null;
    };

    const handleCancel = (event) => {
        event.preventDefault();
        cleanup();
        if (mode === 'edit') {
            history.push(`/projects/overview/${projectID}/lot/browse`);
        }
        if (mode === 'create') {
            history.push(`/projects/overview/${projectID}`);
        }
    };

    const handleChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const generateFurtherCompetitionRule = (value) => (value.callOffMechanism === 'furtherComp' ? <div className='formInputContainer'>
        <LabelledRadioButton id={'furtherCompetitionRule'}
            label={Constants.furtherCompetitionRulesLabel}
            breakColumn={true}
            options={[{
                id: 'all-supplier',
                value: Constants.furtherCompetitionValues.allSupplierValue,
                label: Constants.furtherCompetition.allSupplier,
                checked: values.competitionRules
                    ? values.competitionRules
                        === Constants.furtherCompetitionValues.allSupplierValue
                    : false,
                name: Constants.competitionRules,
                required: true,
            },
            {
                id: 'any-supplier',
                value: Constants.furtherCompetitionValues.anySupplierValue,
                label: Constants.furtherCompetition.anySupplier,
                checked: values.competitionRules
                    ? values.competitionRules
                        === Constants.furtherCompetitionValues.anySupplierValue
                    : false,
                name: Constants.competitionRules,
                required: true,
            }]}
            onChange={(e) => handleChange(e)}
            renderAsRow={true}
            required={true}
        />
    </div>
        : <></>);

    const generateCodeTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.classificationID}
                id={item.classificationID}
                tagTxt={(item.category && item.level) ? `${item.category} - ${item.level}` : `${item.classificationID}`}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => removePill(item.classificationID)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const generateSupplyRegionsTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.code}
                id={item.code}
                tagTxt={`${item.code}-${item.description}`}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => removeRegionPill(item.code)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const getPrettyCallOffMechanism = (callOffMechanism) => Constants.callOffMechanism.filter(
        (eventType) => eventType.value === callOffMechanism,
    )[0]?.label;

    const generateViewLot = () => <>
        <div className='sectionContent'>
            <div id='title'className='sectionRow'>
                <p className='title sectionLabel' >Lot title:</p>
                <p className='caption sectionValue' >{values.title}</p>
            </div>
            <div id='description' className='sectionRow'>
                <p className='title sectionLabel' >Lot description:</p>
                <p className='caption sectionValue' >{values.description}</p>
            </div>
            <div id='callOffMechanism' className='sectionRow'>
                <p className='title sectionLabel' >Call Off Mechanism:</p>
                <p className='caption sectionValue' >{getPrettyCallOffMechanism(values.callOffMechanism)}</p>
            </div>
            <div id='estimatedValue' className='sectionRow'>
                <p className='title sectionLabel' >Estimated value:</p>
                <p className='caption sectionValue' >{values.estimatedValue
                    ? helperFunctions.formatCurrency(values.estimatedValue) : ''}</p>
            </div>
            <div id='noOfSuppliers' className='sectionRow'>
                <p className='title sectionLabel' >Number of suppliers:</p>
                <p className='caption sectionValue' >{values.noOfSuppliers}</p>
            </div>
            <div id='contractStartDate' className='sectionRow'>
                <p className='title sectionLabel' >Contract start date:</p>
                <p className='caption sectionValue' >{values.contractStart}</p>
            </div>
            <div id='contractEndDate' className='sectionRow'>
                <p className='title sectionLabel' >Contract end date:</p>
                <p className='caption sectionValue' >{values.contractEnd}</p>
            </div>
        </div>
        <div id='lotSectionContent'>
            <div className='textIconContainer'><p className='title-large sectionHeader'>Codes and Categories</p>
                {generateCodeTag(codeData || [], mode !== 'view')}
            </div>
        </div>
        <div id='lotSectionContent'>
            <div className='textIconContainer'><p className='title-large sectionHeader'>Delivery Area</p>
                {generateSupplyRegionsTag(regionData, mode !== 'view')}
            </div>
        </div>
    </>;

    return (
        <>
            {mode === 'view' && generateViewLot()}
            {mode === 'create' || mode === 'edit'
                ? <Form
                    id='lotFormContent'
                    onSubmit={mode === 'create' ? addLot : updateLot}>
                    <p className='title-large sectionHeader keyInfo' id='lotFormHeader'>Key Information</p>
                    <div className='formInputContainer'>
                        <LabelledInput id='title'
                            type={LabelledInputTypeText} label='Lot title' readonly={mode === 'view'}
                            breakColumn={true} onChange={(e) => handleChange(e)}
                            value={values.title || ''}
                            placeholder='Enter title'
                            maxLength={100} />
                    </div>
                    <div className='formInputContainer'>
                        <LabelledTextarea id='description'
                            placeholder='Enter description' readonly={mode === 'view'}
                            breakColumn={true} label='Lot description' value={values.description || ''}
                            currentCharCount={values.description
                                ? values.description.length : 0}
                            onChange={(e) => handleChange(e)}
                            maxCharCount={3000}
                            required={true} />
                    </div>
                    <div className='formInputContainer'>
                        <LabelledSelect id='callOffMechanism'
                            label='Call Off Mechanism'
                            required={true} readonly={mode === 'view'}
                            breakColumn={true}
                            onChange={(e) => {
                                if (projectService.isRTMDirectAward(e.target.value)) {
                                    setValues({
                                        ...values,
                                        competitionRules: undefined,
                                        callOffMechanism: e.target.value,
                                    });
                                } else {
                                    setValues({
                                        ...values,
                                        competitionRules: undefined,
                                        callOffMechanism: e.target.value,
                                    });
                                }
                            }}
                            value={values.callOffMechanism || ''}
                            options={Constants.callOffMechanismLots
                                .map((option, idx) => generateDropdownOptionsForCallOfMechanism(idx, option.value, option.label, 'Select call off mechanism'))} />
                    </div>
                    {mode === 'create' || mode === 'edit' ? generateFurtherCompetitionRule(values || '') : ''}

                    <div className='formInputContainer'>
                        <LabelledInput
                            id='estimatedValue'
                            type={LabelledInputTypeCurrency}
                            label='Estimated Value (£)'
                            required={false}
                            readonly={mode === 'view'}
                            breakColumn={true}
                            onChange={(e) => handleChange(e)}
                            value={values?.estimatedValue || ''}
                            placeholder='Enter value'
                        />
                    </div>
                    <div className='formInputContainer'>
                        <LabelledInput id='noOfSuppliers'
                            type={LabelledInputTypeNumber} label='Number of Suppliers'
                            required={false} readonly={mode === 'view'}
                            breakColumn={true} onChange={(e) => handleChange(e)}
                            value={values?.noOfSuppliers || ''}
                            placeholder='Enter value' />
                    </div>
                    <div id='lotSectionContent'>
                        <div className='textIconContainer'><p className='title-large sectionHeader'>Codes and Categories</p>
                            {(mode === 'create' || mode === 'edit') && <Button id='addButton' size='small' variant='secondary skinless'
                                label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                                handleClick={(e) => {
                                    e.preventDefault();
                                    setShowCodesModal(true);
                                }} />}
                        </div>
                        {(mode === 'create' || mode === 'edit') ? generateCodeTag(codeData, mode !== 'view') : ''}
                    </div>
                    <div id='lotSectionContent'>
                        <div className='textIconContainer'><p className='title-large sectionHeader'>Delivery Area</p>
                            {(mode === 'create' || mode === 'edit') && <Button id='addButton' size='small' variant='secondary skinless'
                                label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                                handleClick={(e) => {
                                    e.preventDefault();
                                    setShowRegionsModal(true);
                                }} />}
                        </div>
                        {(mode === 'create' || mode === 'edit') ? generateSupplyRegionsTag(regionData, mode !== 'view') : ''}
                    </div>
                    {mode === 'create' && <div id='actionButtonContainer'>
                        <Button id='cancel-action-btn'
                            variant='secondary'
                            label='Cancel'
                            handleClick={handleCancel} />
                        <Button id='create-action-btn'
                            variant='primary'
                            label='Add'
                            handleClick={() => null} />
                    </div>}
                    {mode === 'edit' && <div id='actionButtonContainer'>
                        <Button id='cancel-action-btn'
                            variant='secondary'
                            label='Cancel'
                            handleClick={handleCancel} />
                        <Button id='create-action-btn'
                            variant='primary'
                            label='Update'
                            handleClick={() => null} />
                    </div>}
                </Form>
                : ''}
            {showCodesModal && <SelectCodesModal
                closeModal={() => {
                    setShowCodesModal(!showCodesModal);
                }}
                initialData={initialCodesData} />}
            {showRegionsModal && <SupplyRegionsModal
                closeModal={() => {
                    setShowRegionsModal(!showRegionsModal);
                }}
                initialData={initialRegionsData} />}
        </>
    );
};

LotForm.propTypes = {
    mode: PropTypes.string,
    projectID: PropTypes.string,
    lotID: PropTypes.string,
    solutionID: PropTypes.string,
};

export default LotForm;
