import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import addIcon from '../../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../../styles/icons/blue/add-ellipse-2.svg';
import Button from '../../../components/Button';
import { ThemeContext } from '../../../context/ThemeContext';

const RegistrationHeader = ({ addAction, headingLabel }) => {
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addIcon;
    return <>
        <section className='registration-section-header'>
            <h4>{headingLabel}</h4>
            {/* <div className='add-item' onClick={addAction}>
            <img src={addIcon} />
            <span className='title large'>Add</span>
        </div> */}
            <div className='add-item'>
                <Button id='dashbaordActionBtn'
                    type='button'
                    variant={'secondary'}
                    additionalVariant={'skinless align-right'}
                    icon={true}
                    iconSrc={addIconSrc}
                    label={'Add'}
                    handleClick={addAction} /></div>
        </section>
    </>;
};

RegistrationHeader.propTypes = {
    headingLabel: PropTypes.string.isRequired,
    addAction: PropTypes.func.isRequired,
};

export default RegistrationHeader;
