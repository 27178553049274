/* eslint-disable max-len */
import { isPast, parseISO } from 'date-fns';
import React from 'react';
import Button from '../../../../../../components/Button';
import { eventActions, eventStatuses, workflowEvents as eventTypes } from '../../../../../../config/constants';
import rbac from '../../../../../../rbac';
import projectService from '../../../../../../services/project.service';


const calendarRbacRoles = ['po', 'pm', 'cs', 'cm'];
const genericRbacRoles = ['po', 'pm', 'cs', 's1a', 's2a'];
const evaluationRbacRoles = ['po', 'pm', 'cs', 's1a', 's2a', 'legal', 'eval'];

const getStartEventButton = (
    handleUpdateEventStatus,
    eventID,
    userSteeringRoles,
    responsibleRole,
    cardTitle = '',
) => responsibleRole
    && userSteeringRoles
    && (responsibleRole !== 'calendar' ? rbac.can([responsibleRole], userSteeringRoles) : rbac.can([...calendarRbacRoles], userSteeringRoles))
    && <Button id='actionBtn'
        size='small'
        variant={`primary skinless white card-${cardTitle?.toLowerCase().replace(' ', '-')}`}
        label='Start Event'
        handleClick={() => handleUpdateEventStatus(eventID, eventActions.started, 'start')}
    />;

const getEscalateButton = (
    setEscalateModal,
    userSteeringRoles,
) => userSteeringRoles
    && rbac.can(rbac.action.escalateEvent, userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless red danger'
        label='Escalate'
        handleClick={() => setEscalateModal(true)}
    />;

const getSendForApprovalButton = (
    handleUpdateEventStatus,
    eventID,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole && userSteeringRoles
    && rbac.can([responsibleRole], userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Send for approval'
        handleClick={() => handleUpdateEventStatus(
            eventID, eventActions.awaitingApproval, 'send it for approval',
        )}
    />;

const getCompleteButton = (
    handleUpdateEventStatus,
    eventID,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole
    && userSteeringRoles
    && (responsibleRole !== 'calendar' ? rbac.can([responsibleRole], userSteeringRoles) : rbac.can([...calendarRbacRoles], userSteeringRoles))
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white complete-event'
        label='Complete Event'
        handleClick={() => handleUpdateEventStatus(
            eventID, eventActions.complete, 'complete',
        )}
    />;

const getStage1ApproveButton = (
    handleUpdateEventStatus,
    eventID,
    userSteeringRoles,
) => userSteeringRoles && userSteeringRoles?.length > 0
    && rbac.can(rbac.action.approveStage1, userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Approve Stage 1'
        handleClick={() => handleUpdateEventStatus(
            eventID,
            eventActions.approveStage1,
            'approve',
        )}
    />;

const getStage2ApproveButton = (
    handleUpdateEventStatus,
    eventID,
    userSteeringRoles,
) => userSteeringRoles && userSteeringRoles?.length > 0
    && rbac.can(rbac.action.approveStage2, userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Approve Stage 2'
        handleClick={() => handleUpdateEventStatus(
            eventID,
            eventActions.approveStage2,
            'approve',
        )}
    />;

const getStage1RejectButton = (
    handleUpdateEventStatus,
    eventID,
    userSteeringRoles,
) => userSteeringRoles && userSteeringRoles?.length > 0
    && rbac.can(rbac.action.rejectStage1, userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless red'
        label='Reject'
        handleClick={() => handleUpdateEventStatus(
            eventID,
            eventActions.rejectStage1,
            'reject',
        )}
    />;

const getStage2RejectButton = (
    handleUpdateEventStatus,
    eventID,
    userSteeringRoles,
) => userSteeringRoles && userSteeringRoles?.length > 0
    && rbac.can(rbac.action.rejectStage2, userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless red'
        label='Reject'
        handleClick={() => handleUpdateEventStatus(
            eventID,
            eventActions.rejectStage2,
            'reject',
        )}
    />;

const getReopenButton = (
    handleUpdateEventStatus,
    eventID,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole
    && userSteeringRoles
    && (responsibleRole !== 'calendar' ? rbac.can([responsibleRole], userSteeringRoles) : rbac.can([...calendarRbacRoles], userSteeringRoles))
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Reopen'
        handleClick={() => handleUpdateEventStatus(
            eventID,
            eventActions.reopen,
            'reopen',
        )}
    />;

const manageQuestionnaireButton = (
    handleManageQuestionnaire,
    eventID,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole && userSteeringRoles
    && rbac.can([responsibleRole], userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Manage Questionnaire'
        handleClick={() => handleManageQuestionnaire(eventID)}
    />;

const draftNoticeButton = (
    handleDraftNotice,
    eventID,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole && userSteeringRoles
    && rbac.can([responsibleRole], userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Create Draft Notice'
        handleClick={() => handleDraftNotice(eventID)}
    />;

const viewNoticeButton = (
    handleViewNotice,
    eventID,
    userSteeringRoles,
    responsibleRole,
    label = 'Draft',
) => responsibleRole && userSteeringRoles
    && rbac.can([responsibleRole, 's1a', 's2a'], userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label={`View ${label} Notice`}
        handleClick={() => handleViewNotice(eventID)}
    />;

const viewInCFButton = (
    handleViewInCF,
    submissions,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole && userSteeringRoles
    && rbac.can([responsibleRole], userSteeringRoles)
    && Array.isArray(submissions)
    && submissions.length
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='View in CF'
        handleClick={handleViewInCF}
    />;

const viewInFTSButton = (
    handleViewInFTS,
    submissions,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole && userSteeringRoles
&& rbac.can([responsibleRole], userSteeringRoles)
&& Array.isArray(submissions)
&& submissions.length
&& <Button id='actionBtn'
    size='small'
    variant='primary skinless white'
    label='Download Notice'
    handleClick={handleViewInFTS}
/>;

const publishNoticeButton = (
    handlePublishNotice,
    eventID,
    submissions,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole && userSteeringRoles
    && rbac.can([responsibleRole], userSteeringRoles)
    && Array.isArray(submissions)
    && submissions.length
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Publish Notice'
        handleClick={() => handlePublishNotice(eventID)}
    />;

const manageEvaluationSummaryButton = (
    handleEvaluationSummary,
    eventID,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole && userSteeringRoles
    && (rbac.can([responsibleRole, ...evaluationRbacRoles], userSteeringRoles)
        || userSteeringRoles.includes(rbac.roles.individual_eval))
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Evaluation Summary'
        handleClick={() => handleEvaluationSummary(eventID)}
    />;

const manageIssueAwardSummaryButton = (
    handleAwardSummary,
    eventID,
    userSteeringRoles,
    responsibleRole,
) => responsibleRole && userSteeringRoles
    && rbac.can([responsibleRole, ...genericRbacRoles], userSteeringRoles)
    && <Button id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Award Summary'
        handleClick={() => handleAwardSummary(eventID)}
    />;

const manageSuppliersButton = (
    userSteeringRoles,
    responsibleRole,
    awardEventID,
    handleManageSupplier,
    route,
    callOffMechanism,
    projectType,
    workflow,
) => responsibleRole && userSteeringRoles
    && rbac.can([responsibleRole, ...genericRbacRoles], userSteeringRoles)
    && projectService.isProjectOrCallOffDirectAward({
        route, callOffMechanism, projectType, workflow,
    })
    && <Button
        id='actionBtn'
        size='small'
        variant='primary skinless white'
        label='Manage Suppliers'
        handleClick={() => handleManageSupplier(awardEventID)}
    />;


const getDefaultEventButtons = (
    event,
    handleUpdateEventStatus,
    setEscalateModal,
    userSteeringRoles,
) => {
    switch (event.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageFirstApproval
                    ? getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                    : getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
            </div>;
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
            </div>;
        case eventStatuses.stageSecondApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getPublishEventButtons = (
    event,
    handleUpdateEventStatus,
    setEscalateModal,
    handleManageQuestionnaire,
    handleOpenPublishEventModal,
    handleManageSupplier,
    handleEvaluationSummary,
    userSteeringRoles,
) => {
    switch (event.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible, event.eventName)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageQuestionnaireButton(handleManageQuestionnaire, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {event.stageFirstApproval
                && getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageQuestionnaireButton(handleManageQuestionnaire, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageQuestionnaireButton(handleManageQuestionnaire, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : <>
                        {event.rolesResponsible && userSteeringRoles && rbac.can([event.rolesResponsible], userSteeringRoles)
                        && <Button
                            id='actionBtn'
                            size='small'
                            variant='primary skinless white'
                            label='Publish'
                            handleClick={() => handleOpenPublishEventModal('default')}
                        />}
                        {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
                    </>
                }
            </div>;
        case eventStatuses.stageSecondApproved:
        case eventStatuses.awaitingPublish:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageQuestionnaireButton(handleManageQuestionnaire, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {event.rolesResponsible && userSteeringRoles && rbac.can([event.rolesResponsible], userSteeringRoles)
                 && <Button
                     id='actionBtn'
                     size='small'
                     variant={`primary skinless white card-${event.eventName?.toLowerCase().replace(' ', '-')}`}
                     label='Publish'
                     handleClick={() => handleOpenPublishEventModal('default')}
                 />}
            </div>;
        case eventStatuses.published:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageQuestionnaireButton(handleManageQuestionnaire, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {event.rolesResponsible
                && userSteeringRoles
                && rbac.can([event.rolesResponsible], userSteeringRoles)
                && <>
                    <Button
                        id='actionBtn'
                        size='small'
                        variant='primary skinless white'
                        label='Edit Opportunity'
                        handleClick={() => handleOpenPublishEventModal('edit')}
                    />
                    <Button
                        id='actionBtn'
                        size='small'
                        variant='primary skinless white'
                        label='Abort Publication'
                        handleClick={() => handleUpdateEventStatus(event.eventID, eventActions.unpublish, 'unpublish ')}
                        disabled={isPast(parseISO(event?.submissionEndDate))}
                    />
                    <Button
                        id='actionBtn'
                        size='small'
                        variant='primary skinless white'
                        label='Manage Suppliers'
                        handleClick={() => handleManageSupplier(event.eventID)}
                    />
                </>}
                {event.publishType === 'marketConsultation'
                && event.rolesResponsible
                && userSteeringRoles
                && rbac.can([event.rolesResponsible, ...genericRbacRoles], userSteeringRoles)
                && <Button
                    id='actionBtn'
                    size='small'
                    variant='primary skinless white'
                    label='Response Summary'
                    handleClick={() => handleEvaluationSummary(event.eventID, 'readOnly')}
                />}
                {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {manageQuestionnaireButton(handleManageQuestionnaire, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {event.rolesResponsible
                && userSteeringRoles
                && rbac.can([event.rolesResponsible], userSteeringRoles)
                && <>
                    <Button
                        id='actionBtn'
                        size='small'
                        variant='primary skinless white'
                        label='Manage Suppliers'
                        handleClick={() => handleManageSupplier(event.eventID)}
                    />
                </>}
                {event.publishType === 'marketConsultation'
                && event.rolesResponsible
                && userSteeringRoles
                && rbac.can([event.rolesResponsible, ...genericRbacRoles], userSteeringRoles)
                && <Button
                    id='actionBtn'
                    size='small'
                    variant='primary skinless white'
                    label='Response Summary'
                    handleClick={() => handleEvaluationSummary(event.eventID, 'readOnly')}
                />}
                {event.isPublished === false
                && getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getNoticeEventButtons = (
    event,
    handleDraftNotice,
    handlePublishNotice,
    handleUpdateEventStatus,
    setEscalateModal,
    handleViewNotice,
    handleViewInCF,
    handleViewInFTS,
    userSteeringRoles,
) => {
    switch (event.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {draftNoticeButton(handleDraftNotice, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {viewNoticeButton(handleViewNotice, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {viewNoticeButton(handleViewNotice, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : publishNoticeButton(handlePublishNotice, event.eventID, event.submissions, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.stageSecondApproved:
        case eventStatuses.awaitingPublish:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {event.noticePublisher === 'CF'
                && <>
                    {viewNoticeButton(handleViewNotice, event.eventID, userSteeringRoles, event.rolesResponsible)}
                    {publishNoticeButton(handlePublishNotice, event.eventID, event.submissions, userSteeringRoles, event.rolesResponsible)}
                </>}
                {event.noticePublisher === 'FTS'
                && viewInFTSButton(handleViewInFTS, event.submissions, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.published:
            return <div className='button-container'>
                {/* {getEscalateButton(setEscalateModal, userSteeringRoles)} */}
                {event.noticePublisher === 'CF'
                && <>
                    {viewNoticeButton(handleViewNotice, event.eventID, userSteeringRoles, event.rolesResponsible, 'Published')}
                    {viewInCFButton(handleViewInCF, event.submissions, userSteeringRoles, event.rolesResponsible)}
                </>}
                {event.noticePublisher === 'FTS'
                && viewInFTSButton(handleViewInFTS, event.submissions, userSteeringRoles, event.rolesResponsible)}
                {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {event.noticePublisher === 'CF'
                && <>
                    {viewNoticeButton(handleViewNotice, event.eventID, userSteeringRoles, event.rolesResponsible, 'Published')}
                    {viewInCFButton(handleViewInCF, event.submissions, userSteeringRoles, event.rolesResponsible)}
                </>}
                {event.noticePublisher === 'FTS'
                && viewInFTSButton(handleViewInFTS, event.submissions, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getEvaluationEventButtons = (
    event,
    handleUpdateEventStatus,
    setEscalateModal,
    handleEvaluationSummary,
    userSteeringRoles,
) => {
    switch (event.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageEvaluationSummaryButton(handleEvaluationSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {/* {event.stageFirstApproval && getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                } */}
                {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        // Note: these statuses are not defunct as approval is on
        // evaluation summary. Do we want these fields removed entirely?
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageEvaluationSummaryButton(handleEvaluationSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageEvaluationSummaryButton(handleEvaluationSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
            </div>;
        case eventStatuses.stageSecondApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageEvaluationSummaryButton(handleEvaluationSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {manageEvaluationSummaryButton(handleEvaluationSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getIssueAwardEventButtons = (
    event,
    handleUpdateEventStatus,
    setEscalateModal,
    handleAwardSummary,
    userSteeringRoles,
    handleManageSupplier,
    route,
    callOffMechanism,
    projectType,
    workflow,
) => {
    switch (event.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageIssueAwardSummaryButton(handleAwardSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {manageSuppliersButton(userSteeringRoles, event.rolesResponsible, event.eventID, handleManageSupplier, route, callOffMechanism, projectType, workflow)}
                {/* {event.stageFirstApproval && getSendForApprovalButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                } */}
                {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        // Note: these statuses are not defunct as approval is on
        // award summary. Do we want these fields removed entirely?
        case eventStatuses.awaitingApproval:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageIssueAwardSummaryButton(handleAwardSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {manageSuppliersButton(userSteeringRoles, event.rolesResponsible, event.eventID, handleManageSupplier, route, callOffMechanism, projectType, workflow)}
                {getStage1ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                {getStage1RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
            </div>;
        case eventStatuses.stageFirstApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageIssueAwardSummaryButton(handleAwardSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {manageSuppliersButton(userSteeringRoles, event.rolesResponsible, event.eventID, handleManageSupplier, route, callOffMechanism, projectType, workflow)}
                {event.stageSecondApproval
                    ? <>
                        {getStage2ApproveButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                        {getStage2RejectButton(handleUpdateEventStatus, event.eventID, userSteeringRoles)}
                    </>
                    : getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)
                }
            </div>;
        case eventStatuses.stageSecondApproved:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {manageIssueAwardSummaryButton(handleAwardSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {manageSuppliersButton(userSteeringRoles, event.rolesResponsible, event.eventID, handleManageSupplier, route, callOffMechanism, projectType, workflow)}
                {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {manageIssueAwardSummaryButton(handleAwardSummary, event.eventID, userSteeringRoles, event.rolesResponsible)}
                {manageSuppliersButton(userSteeringRoles, event.rolesResponsible, event.eventID, handleManageSupplier, route, callOffMechanism, projectType, workflow)}
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, event.rolesResponsible)}
            </div>;
        default: return <></>;
    }
};

const getCalendarEventButtons = (
    event,
    handleUpdateEventStatus,
    setEscalateModal,
    userSteeringRoles,
) => {
    switch (event.eventStatus) {
        case eventStatuses.pending:
            return <div className='button-container'>
                {getStartEventButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, 'calendar')}
            </div>;
        case eventStatuses.started:
            return <div className='button-container'>
                {getEscalateButton(setEscalateModal, userSteeringRoles)}
                {getCompleteButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, 'calendar')}
            </div>;
        case eventStatuses.completed:
            return <div className='button-container'>
                {getReopenButton(handleUpdateEventStatus, event.eventID, userSteeringRoles, 'calendar')}
            </div>;
        default: return <></>;
    }
};

const eventButtons = (
    event,
    handleUpdateEventStatus,
    setEscalateModal,
    handleManageQuestionnaire,
    handleOpenPublishEventModal,
    handleManageSupplier,
    handleDraftNotice,
    handlePublishNotice,
    handleViewNotice,
    handleViewInCF,
    handleViewInFTS,
    handleEvaluationSummary,
    handleAwardSummary,
    userSteeringRoles,
    route,
    callOffMechanism,
    projectType,
    workflow,
) => {
    switch (event.eventType) {
        case eventTypes.calendar:
            return getCalendarEventButtons(
                event,
                handleUpdateEventStatus,
                setEscalateModal,
                userSteeringRoles,
            );
        case eventTypes.document:
            return getDefaultEventButtons(
                event,
                handleUpdateEventStatus,
                setEscalateModal,
                userSteeringRoles,
            );
        case eventTypes.publish:
            return getPublishEventButtons(
                event,
                handleUpdateEventStatus,
                setEscalateModal,
                handleManageQuestionnaire,
                handleOpenPublishEventModal,
                handleManageSupplier,
                handleEvaluationSummary,
                userSteeringRoles,
            );
        case eventTypes.notice:
            return getNoticeEventButtons(
                event,
                handleDraftNotice,
                handlePublishNotice,
                handleUpdateEventStatus,
                setEscalateModal,
                handleViewNotice,
                handleViewInCF,
                handleViewInFTS,
                userSteeringRoles,
            );
        case eventTypes.evaluation:
            return getEvaluationEventButtons(
                event,
                handleUpdateEventStatus,
                setEscalateModal,
                handleEvaluationSummary,
                userSteeringRoles,
            );
        case eventTypes.issueAwardUnsuccessfulNotices:
            return getIssueAwardEventButtons(
                event,
                handleUpdateEventStatus,
                setEscalateModal,
                handleAwardSummary,
                userSteeringRoles,
                handleManageSupplier,
                route,
                callOffMechanism,
                projectType,
                workflow,
            );
        case eventTypes.supplierDocumentation:
        case eventTypes.marketUnderstanding:
        case eventTypes.demandManagement:
        case eventTypes.costManagement:
        case eventTypes.performanceMonitoringSupplierRelations:
        case eventTypes.riskManagement:
        case eventTypes.updateContractManagementPlan:
        case eventTypes.exitStrategy:
        case eventTypes.extension:
        case eventTypes.variation:
        case eventTypes.renewContract:
            return getDefaultEventButtons(
                event,
                handleUpdateEventStatus,
                setEscalateModal,
                userSteeringRoles,
            );
        default:
            return getDefaultEventButtons(
                event,
                handleUpdateEventStatus,
                setEscalateModal,
                userSteeringRoles,
            );
    }
};

const EventConstants = {
    PUBLISH: 'publish',
    CLOSED: 'closed',
    WARNING: 'warning',
    SUCCESS: 'success',
    ESCALATE: 'Escalate',
    ABORT: 'Abort Publication',
    COMPLETE_EVENT: 'Complete Event',
    EXPAND: 'Expand',
    COLLAPSE: 'Collapse',
    ROLE_RESPONSIBLE: 'Role Responsible:',
    NO_ROLE_SELECTED: 'No Role Selected',
    END_DATE: 'End Date:',
    PUBLISH_LEVEL: 'Publish Level:',
    PROJECT: 'Project',
    LOT: 'Lot',
    PUBLISH_TYPE: 'Publish Type:',
    NUMBER_OF_SUBMISSIONS: 'Number of Submissions:',
    GREEN: 'green',
    MORE_ACTIONS: 'More Actions',
};


export { eventButtons, EventConstants };
