/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    isPast, parse, parseISO,
} from 'date-fns';
import { saveAs } from 'file-saver';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import PropTypes from 'prop-types';
import clearIcon from '../../../../../../styles/icons/blue/clear.svg';
import Upload from '../../../../../../features/Upload';
import Button from '../../../../../../components/Button';
import rightArrowHead from '../../../../../../styles/icons/black/right-arrow.svg';
import StatusLabel from '../../../../../../components/StatusLabel';
import {
    eventStatuses, workflowEventTypes, allSteeringRoles, eventStatusesLabel, workflowEvents,
    PROJECT_DESCRIBES_VALUES,
} from '../../../../../../config/constants';
import externalServices from '../../../../../../config/endpoints/externalServices';
import projectManagementAPIs from '../../../../../../services/project-management.service';
import Confirm from '../../../../../../components/Alerts/Confirm/Confirm';
import Form from '../../../../../../components/Form';
import Modal from '../../../../../../components/Modal';
import LabelledTextarea from '../../../../../../components/LabelledTextArea';
import WorknotesModal from '../../../../../../features/WorknotesModal';
import { eventButtons, EventConstants } from './constants';
import GuidanceBanner from '../../../../../../components/GuidanceBanner';
import Toast from '../../../../../../components/Alerts/Toast/Toast';
import { publishNotice } from '../../../../../ViewNotice/index.helpers';
import Constants from '../../../../../../features/DocumentBuilder/components/EventModal/constants';
import AuthContext from '../../../../../../context/AuthContext';
import fileReader from '../../../../../../utils/fileReader';
import projectService from '../../../../../../services/project.service';
import rbac from '../../../../../../rbac';
import { fileShouldBeDisplayedToUser } from '../../../../../../utils/uploadHelper';
import { ThemeContext } from '../../../../../../context/ThemeContext';
import reorderEventButtons from './helpers';

const EventSection = ({
    event,
    projectId,
    workflowId,
    handleUpdateEvent,
    handlePublishEvent,
    handleEscalateEvent,
    handleUploadDocument,
    handleDeleteDocument,
    handleAddWorkNotes,
    userSteeringRoles = [],
    routeToMarket,
    callOffMechanism,
    contractType,
    projectType,
    readOnly,
    workflow,
}) => {
    const history = useHistory();
    const [expand, setExpand] = useState(false);
    const [escalateModal, setEscalateModal] = useState(false);
    const [showViewWorkNotes, setShowViewWorkNotes] = useState(false);
    const [showAddWorkNotes, setShowAddWorkNotes] = useState(false);
    const [escalationReason, setEscalationReason] = useState('');
    const [publishEventModal, setPublishEventModal] = useState(false);
    const [rtmModal, setRtmModal] = useState(false);
    const [callOffModal, setCallOffModal] = useState(false);
    const [publishProjectData, setPublishProjectData] = useState({});
    // const [publishProjectQuestionnaire, setPublishProjectQuestionnaire] = useState({});
    const [publishTypeModal, setPublishTypeModal] = useState();
    const getPrettyEventType = (eType) => workflowEventTypes.filter(
        (eventType) => eventType.value === eType,
    )[0]?.label;

    const authContext = useContext(AuthContext);
    const { toggle } = useContext(ThemeContext);

    const getPrettyEventStatus = () => {
        const finalStatus = (event.eventType === EventConstants.PUBLISH && isPast(parseISO(event?.submissionEndDate)))
            ? EventConstants.CLOSED
            : event.eventStatus;
        return eventStatusesLabel.find(
            (eventStatus) => eventStatus.value === finalStatus,
        );
    };

    const getEventCardStatus = () => {
        if (!toggle) {
            return (`${isPast(parse(event.endDate, 'dd-MM-yyyy', new Date()))
                && event.eventStatus !== eventStatuses.completed ? EventConstants.WARNING : EventConstants.SUCCESS}`);
        }
        return getPrettyEventStatus()?.value;
    };

    const handleUpdateEventStatus = (eventId, action, confirmAction) => {
        if (confirmAction === 'unpublish') {
            Confirm(handleUpdateEvent, {
                subTitle: 'Are you sure you want to unpublish this Opportunity? This action is irreversible.',
            });
        } else {
            Confirm(handleUpdateEvent, {
                subTitle: `Are you sure you want to ${confirmAction} the workflow event - ${event.eventName}`,
            }, {
                eventId,
                action,
            });
        }
    };

    const allowSubmissionDateChanges = () => {
        if (publishTypeModal === 'edit') {
            return isPast(parseISO(event?.submissionEndDate));
        }
        return false;
    };


    const publishProjectContent = () => (
        <>
            <div id='guidanceContent'>
                <GuidanceBanner id='publishEventGuidance'
                    headerText={<div className='title'>Publish this opportunity to suppliers</div>}
                    bannerText1={<span className='title'>Please review the details and click &apos;Publish&apos; to publish this event as an opportunity</span>}/>

            </div>
            {/* <div className='modalQuestionnairesAttached'>
                <div className='formElementsRow'>
                    <p className='title label'>Questionnaires Attached</p>
                </div>
                <div className='formElementsRow'>
                    <p className='title underline'>Market Consulting Questionnaire</p>
                    <p className='title underline'>Additional Questionnaire For Produce</p>
                    <p className='title underline'>High Risk Supplier Questionnaire</p>
                </div>
            </div> */}
            <div className='modalContentBody'>
                <div className='formElementsRow'>
                    <p className='title label'>Expression of interest deadline</p>
                </div>
                <div className='formElementsRow'>
                    <div className='datePickerWrapper'>
                        <label className='title datePickerLabel'>Start date</label>
                        <DatePicker
                            className='customDateInput'
                            dayPlaceholder='DD'
                            monthPlaceholder='MM'
                            yearPlaceholder='YYYY'
                            calendarIcon={null}
                            format='dd-MM-y'
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            onChange={(data) => {
                                setPublishProjectData({
                                    ...publishProjectData,
                                    interestStartDate: data,
                                });
                            }}
                            value={publishProjectData.interestStartDate}
                            required={true}
                            disabled = {allowSubmissionDateChanges()}
                        />
                    </div>
                    <div className='timePickerWrapper'>
                        <label className='title'>Start time</label>
                        <TimePicker
                            className='customTimeInput'
                            hourPlaceholder='HH'
                            minutePlaceholder='MM'
                            locale='en-gb'
                            clockIcon={null}
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            onChange={(data) => {
                                setPublishProjectData({
                                    ...publishProjectData,
                                    interestStartTime: data,
                                });
                            }}
                            value={publishProjectData.interestStartTime}
                            required={true}
                            disableClock={true}
                            disabled = {allowSubmissionDateChanges()}
                        />
                    </div>
                </div>
                <div className='formElementsRow'>
                    <div className='datePickerWrapper'>
                        <label className='title datePickerLabel'>End date</label>
                        <DatePicker
                            className='customDateInput'
                            dayPlaceholder='DD'
                            monthPlaceholder='MM'
                            yearPlaceholder='YYYY'
                            calendarIcon={null}
                            format='dd-MM-y'
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            onChange={(data) => {
                                setPublishProjectData({
                                    ...publishProjectData,
                                    interestEndDate: data,
                                });
                            }}
                            value={publishProjectData.interestEndDate}
                            required={true}
                            disabled = {allowSubmissionDateChanges()}
                        />
                    </div>
                    <div className='timePickerWrapper'>
                        <label className='title'>End time</label>
                        <TimePicker
                            className='customTimeInput'
                            hourPlaceholder='HH'
                            minutePlaceholder='MM'
                            locale='en-gb'
                            clockIcon={null}
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            onChange={(data) => {
                                setPublishProjectData({
                                    ...publishProjectData,
                                    interestEndTime: data,
                                });
                            }}
                            value={publishProjectData.interestEndTime}
                            required={true}
                            disableClock={true}
                            disabled = {allowSubmissionDateChanges()}
                        />
                    </div>
                </div>
            </div>
            <div className='modalContentBody'>
                <div className='formElementsRow'>
                    <p className='title label'>Submission Deadline</p>
                </div>
                <div className='formElementsRow'>
                    <div className='datePickerWrapper'>
                        <label className='title datePickerLabel'>Start date</label>
                        <DatePicker
                            className='customDateInput'
                            dayPlaceholder='DD'
                            monthPlaceholder='MM'
                            yearPlaceholder='YYYY'
                            calendarIcon={null}
                            format='dd-MM-y'
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            onChange={(data) => {
                                setPublishProjectData({
                                    ...publishProjectData,
                                    submissionStartDate: data,
                                });
                            }}
                            value={publishProjectData.submissionStartDate}
                            required={true}
                            disabled = {allowSubmissionDateChanges()}
                        />
                    </div>
                    <div className='timePickerWrapper'>
                        <label className='title'>Start time</label>
                        <TimePicker
                            className='customTimeInput'
                            hourPlaceholder='HH'
                            minutePlaceholder='MM'
                            locale='en-gb'
                            clockIcon={null}
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            onChange={(data) => {
                                setPublishProjectData({
                                    ...publishProjectData,
                                    submissionStartTime: data,
                                });
                            }}
                            value={publishProjectData.submissionStartTime}
                            required={true}
                            disableClock={true}
                            disabled = {allowSubmissionDateChanges()}
                        />
                    </div>
                </div>
                <div className='formElementsRow'>
                    <div className='datePickerWrapper'>
                        <label className='title datePickerLabel'>End date</label>
                        <DatePicker
                            className='customDateInput'
                            dayPlaceholder='DD'
                            monthPlaceholder='MM'
                            yearPlaceholder='YYYY'
                            calendarIcon={null}
                            format='dd-MM-y'
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            onChange={(data) => {
                                setPublishProjectData({
                                    ...publishProjectData,
                                    submissionEndDate: data,
                                });
                            }}
                            value={publishProjectData.submissionEndDate}
                            required={true}
                            disabled = {allowSubmissionDateChanges()}
                        />
                    </div>
                    <div className='timePickerWrapper'>
                        <label className='title'>End time</label>
                        <TimePicker
                            className='customTimeInput'
                            hourPlaceholder='HH'
                            minutePlaceholder='MM'
                            locale='en-gb'
                            clockIcon={null}
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            onChange={(data) => {
                                setPublishProjectData({
                                    ...publishProjectData,
                                    submissionEndTime: data,
                                });
                            }}
                            value={publishProjectData.submissionEndTime}
                            required={true}
                            disableClock={true}
                            disabled = {allowSubmissionDateChanges()}
                        />
                    </div>
                </div>
            </div>
        </>
    );

    const formatTime = (dateString) => {
        const fullDate = parseISO(dateString);
        const hours = fullDate.getHours();
        const minutes = fullDate.getMinutes();
        return `${hours}:${minutes}`;
    };

    const handleOpenPublishEventModal = async (type) => {
        if (event.publishType !== 'marketConsultation' && (!routeToMarket || routeToMarket === '_')) {
            setRtmModal(true);
            return;
        }

        if (event.publishType !== 'marketConsultation' && (!contractType || contractType === [])) {
            setCallOffModal(true);
            return;
        }

        if (event.publishProject === true && event.publishType !== 'marketConsultation' && (contractType.includes(PROJECT_DESCRIBES_VALUES.FRAMEWORK.value) || contractType.includes(PROJECT_DESCRIBES_VALUES.OPEN_FRAMEWORK.value) || contractType.includes(PROJECT_DESCRIBES_VALUES.DPS.value)) && !callOffMechanism) {
            setCallOffModal(true);
            return;
        }

        setPublishTypeModal(type);
        setPublishEventModal(true);
        if (type === 'edit') {
            if (event.expressionWindowStartDate) {
                publishProjectData.interestStartDate = new Date(event.expressionWindowStartDate.split('T'));
                publishProjectData.interestStartTime = formatTime(event.expressionWindowStartDate);
            }
            if (event.expressionWindowEndDate) {
                publishProjectData.interestEndDate = new Date(event.expressionWindowEndDate.split('T'));
                publishProjectData.interestEndTime = formatTime(event.expressionWindowEndDate);
            }
            if (event.submissionStartDate) {
                publishProjectData.submissionStartDate = new Date(event.submissionStartDate.split('T'));
                publishProjectData.submissionStartTime = formatTime(event.submissionStartDate);
            }
            if (event.submissionEndDate) {
                publishProjectData.submissionEndDate = new Date(event.submissionEndDate.split('T'));
                publishProjectData.submissionEndTime = formatTime(event.submissionEndDate);
            }
        }
    };

    const handleClosePublishEventModal = () => {
        setPublishEventModal(false);
        setPublishProjectData({});
    };

    const handleCloseEscalateEvent = () => {
        setEscalateModal(false);
        setEscalationReason('');
    };

    const handleCloseWorknote = () => {
        setShowAddWorkNotes(false);
        setShowViewWorkNotes(false);
    };

    const handleViewInCF = async () => {
        const submission = event.submissions?.find((sub) => (
            sub.publisher === 'Contracts Finder'
        ));

        if (submission) {
            const noticeId = submission.noticeID;
            window.open(`${externalServices.viewOnContractsFinder}/${noticeId}`);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Notice submission could not be found.',
            });
        }
    };

    const handleViewInFTS = async () => {
        const submission = event.submissions?.find((sub) => (
            sub.publisher === 'Find a Tender'
        ));

        if (submission) {
            const response = await projectManagementAPIs.viewFindTenderServiceNotice(projectId, event.eventID);
            if (response.status === 200) {
                try {
                    saveAs(response.data, 'notice');
                } catch (error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Unable to download document.',
                    });
                }
            } else {
                const { data } = response;
                const file = await fileReader(data);
                const { ErrorCode } = JSON.parse(file);
                if (ErrorCode === '8000') {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Uploaded document is in process, please try after sometime.',
                    });
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to download document.',
                    });
                }
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Notice submission could not be found.',
            });
        }
    };

    const getPrettyPublishType = (type) => Constants.publishTypes
        .filter((publishType) => (publishType?.value === type))[0]?.label;

    const getPrettySteeringGroupRole = (roleId) => allSteeringRoles
        .filter((steeringRole) => (steeringRole.role === roleId))[0]?.name;

    const handleManageQuestionnaire = (eventId) => {
        history.push(`/questionnaires/manage/${projectId}/${eventId}`);
    };

    const handleManageSupplier = (eventId) => {
        history.push(`/suppliers/manage/${projectId}/${eventId}`);
    };

    const handleDraftNotice = (eventId) => {
        history.push(`/projects/overview/notice/create/${projectId}/${eventId}`);
    };

    const handlePublishNotice = async (eventId, comment = undefined) => {
        try {
            Confirm(async () => {
                await publishNotice(projectId, eventId, comment);
                handleUpdateEvent({ eventId, action: 'refresh' });
            }, {
                subTitle: `Are you sure you want to publish the workflow event - ${event.eventName}`,
            }, {
                eventId,
                action: 'publish',
            });
        } catch (e) {
            Toast.fire({
                icon: 'error',
                titleText: e.message,
            });
        }
    };

    const handleViewNotice = (eventId) => {
        history.push(`/projects/overview/notice/view/${projectId}/${eventId}`);
    };

    const handleEvaluationSummary = (eventId, readMode) => {
        if (readMode) {
            history.push(`/consultation/summary/${projectId}/${eventId}`);
        } else {
            history.push(`/evaluation/summary/${projectId}/${eventId}`);
        }
    };

    const handleAwardSummary = (eventId) => {
        history.push(`/award/summary/${projectId}/${eventId}`);
    };

    const generateExpandableSection = () => {
        const relevantDocuments = event.additionalDocuments
            ? event.additionalDocuments.filter((file) => fileShouldBeDisplayedToUser(
                file.document, authContext,
            ))
            : [];
        return (<div className='event-section-content'>
            <p className={`body-small ${!relevantDocuments || relevantDocuments?.length !== 0
                ? 'success' : 'warning'}`}>
                {relevantDocuments.length} Documents
            </p>
            <p className={`body-small ${ !event.worknotes || event.worknotes?.length === 0
                ? 'warning' : 'success'}`}>
                {event.worknotes
                    ? event.worknotes.length
                    : 0} Worknotes
            </p>
        </div>);
    };

    const generateCollapsableSection = () => (<div className='component-container'>
        <Upload
            title='Documents'
            allowMultiple={true}
            uploadIdentifier='document'
            uploadCallback={(data) => handleUploadDocument(data, event.eventID)}
            deleteCallback={(data) => handleDeleteDocument(event.eventID, data.documentID)}
            uploadedDocuments={event.additionalDocuments}
            readOnly={readOnly || userSteeringRoles.includes(rbac.roles.individual_eval)}
        />
        {authContext?.user?.accountType !== 'supplier'
        && <div className='worknotes-container'>
            <p className='title sectionLabel' id='worknotes'> Worknotes</p>
            <p className='caption'> {event?.worknotes
                ? event?.worknotes?.length
                : 0} worknotes added</p>
            <div className='button-container'>
                <Button id='viewWorknoteBtn'
                    size='small'
                    variant='secondary'
                    label='View'
                    handleClick={() => setShowViewWorkNotes(true)} />
                {!readOnly && !userSteeringRoles.includes(rbac.roles.individual_eval)
                        && <Button id='addWorknoteBtn'
                            size='small'
                            variant='primary'
                            label='Add'
                            handleClick={() => setShowAddWorkNotes(true)} />
                }
            </div>
        </div>}
    </div>);

    const isStageDirectAward = projectService
        .isStageDirectAward({
            callOffMechanism, route: routeToMarket, projectType, workflow,
        }, event);

    const getEventButtons = () => {
        const eButtons = eventButtons(
            event,
            handleUpdateEventStatus,
            setEscalateModal,
            handleManageQuestionnaire,
            handleOpenPublishEventModal,
            handleManageSupplier,
            handleDraftNotice,
            handlePublishNotice,
            handleViewNotice,
            handleViewInCF,
            handleViewInFTS,
            handleEvaluationSummary,
            handleAwardSummary,
            userSteeringRoles,
            routeToMarket,
            callOffMechanism,
            projectType,
            workflow,
        );
        if (!toggle) {
            return eButtons;
        }
        return reorderEventButtons(eButtons);
    };

    return ((!isStageDirectAward
        || (isStageDirectAward && event.eventType !== workflowEvents.publish))
        && <><div className={`event-section-container ${toggle ? 'new-event-section-container' : ''}`}>
            <div key={event.eventID} className={`event-section-card ${getEventCardStatus()}`}>
                <div className = 'event-section-header'>
                    <div className='event-section-header-content'>
                        <p className='title mr-1'>{event.eventName}</p>
                        {isPast(parse(event.endDate, 'dd-MM-yyyy', new Date()) && event.status)
                            && <StatusLabel id={`event-${event.eventID}-status`}
                                labelTxt= {event.eventStatus !== eventStatuses.completed ? 'Overdue' : ''}
                                color= {event.eventStatus !== eventStatuses.completed ? 'amber' : ''}
                            />}
                    </div>
                    <button type='button' className='event-section-header-button' id={`event-section-${event.eventID}-header`}
                        onClick={() => setExpand(!expand)}>
                        <span>{ !expand ? EventConstants.EXPAND : EventConstants.COLLAPSE}</span>
                        <img src={rightArrowHead} className={`event-section-header-icon
                            ${!expand ? '' : 'rotate' }`} alt='expand-collapse'>
                        </img>
                    </button>
                </div>
                <div className='event-section-content'>
                    <p className='caption'>{getPrettyEventType(event.eventType)}</p>
                    {expand && <p className='body label'>{event.description}</p> }
                    <div className='workflowContent'>
                        {event.eventType !== 'calendar'
                    && <div className='workflowRow'>
                        <p className='label'>{EventConstants.ROLE_RESPONSIBLE} </p>
                        {event.rolesResponsible
                            ? <p className='value'>{getPrettySteeringGroupRole(event.rolesResponsible)}</p>
                            : <p className=' value'>{EventConstants.NO_ROLE_SELECTED}</p>}
                    </div>}
                        <div className='workflowRow'>
                            <p className='label'>{EventConstants.END_DATE}</p>
                            <p className='value'>{event.endDate}</p>
                        </div>
                        {workflowEvents.publish === event.eventType
                        && <div className='workflowRow'>
                            <p className='label'>{EventConstants.PUBLISH_LEVEL}</p>
                            <p className='value'> {event.publishProject ? EventConstants.PROJECT : EventConstants.LOT}</p>
                        </div>
                        }
                        {workflowEvents.publish === event.eventType
                        && <div className='workflowRow'>
                            <p className='label'>{EventConstants.PUBLISH_TYPE}</p>
                            <p className='value'>{getPrettyPublishType(event.publishType)}</p>
                        </div>}
                        {workflowEvents.publish === event.eventType && event?.isPublished === true
                        && <div className='workflowRow'>
                            <p className='label'>{EventConstants.NUMBER_OF_SUBMISSIONS}</p>
                            <p className='value'>{`${event?.numSubmissions || 0}`}</p>
                        </div> }
                    </div>
                    <StatusLabel id={`event-${event.eventID}-event-status`}
                        labelTxt= {getPrettyEventStatus()?.label}
                        color= {toggle ? getPrettyEventStatus()?.value : EventConstants.GREEN}
                    />
                </div>

                {!readOnly
                    && getEventButtons()}
                <div className='event-section-subsection event-docs'>
                    {expand ? (
                        generateCollapsableSection()

                    ) : (
                        generateExpandableSection()

                    )}
                </div>
            </div>
        </div>
        {publishEventModal
        && <Form onSubmit={(e) => {
            e.preventDefault();
            handlePublishEvent(publishProjectData,
                event.eventID,
                handleClosePublishEventModal,
                publishTypeModal);
        }}>
            <Modal
                id='publishEventModal'
                open={publishEventModal}
                size='medium'
                headerTitle='Publish Project'
                body={publishProjectContent()}
                footer={true}
                handleMainActionBtnClick={() => null}
                mainActionButtonTxt='Publish'
                mainActionButtonDisable = {allowSubmissionDateChanges()}
                secondActionButtonTxt='Cancel'
                secondActionButtonType='button'
                secondActionButton={true}
                handleSecondaryActionBtnClick={handleClosePublishEventModal}
                helpOption={false}
                closeModal={handleClosePublishEventModal}
            />
        </Form>}
        {escalateModal
            && <Form
                id={'escalateForm'}
                className={'escalateForm'}
                onSubmit={(e) => {
                    e.preventDefault();
                    handleEscalateEvent(
                        escalationReason,
                        event.eventID,
                        handleCloseEscalateEvent,
                    );
                }}>
                <Modal
                    id='escalateEventModal'
                    open={true}
                    size='medium'
                    headerTitle='Escalation Reason'
                    body={<LabelledTextarea
                        id='reason'
                        label='Enter your reason for raising an escalation for this workflow event.'
                        breakColumn={true}
                        onChange={(e) => setEscalationReason(e.target.value)}
                        value={escalationReason || ''}
                        placeholder='Enter reason'
                        required={true}
                    />}
                    footer={true}
                    mainActionButtonTxt='Submit'
                    secondActionButtonTxt='Cancel'
                    secondActionButton={true}
                    handleMainActionBtnClick={() => null}
                    handleSecondaryActionBtnClick={handleCloseEscalateEvent}
                    helpOption={false}
                    closeModal={handleCloseEscalateEvent}
                /></Form>}
        {showViewWorkNotes
        && <WorknotesModal
            closeModal={handleCloseWorknote}
            mode={'view'}
            worknotesInput={event && event.worknotes}
            handleCallback={(data, worknote) => handleAddWorkNotes(
                event.eventID, worknote, handleCloseWorknote,
            )}
        />}
        {showAddWorkNotes
        && <WorknotesModal
            closeModal={handleCloseWorknote}
            mode={'add'}
            worknotesInput={event && event.worknotes}
            handleCallback={(data, worknote) => handleAddWorkNotes(
                event.eventID, worknote, handleCloseWorknote,
            )}
        />}
        {rtmModal
        && <Modal
            id='rtmModal'
            headerTitle='No Route to Market'
            open={rtmModal}
            size='small'
            body={<p className='body'> Oops! You have no Route to Market selected.  Please update your workflow to continue. </p>}
            footer={true}
            handleMainActionBtnClick = {() => history.push(`/workflows/project/edit/${projectId}/${workflowId}`)}
            mainActionButtonTxt={'Update Workflow'}
            secondActionButton={true}
            handleSecondaryActionBtnClick={() => setRtmModal(false)}
            secondActionButtonTxt={'Cancel'}
            helpOption={false}
            closeModal={() => setRtmModal(false)}
        />}
        {callOffModal
        && <Modal
            id='rtmModal'
            headerTitle='Missing Project Details'
            open={callOffModal}
            size='small'
            body={!contractType || contractType === []
                ? <p className='body'> Oops! You have no Project Type selected.  Please update your project to continue. </p>
                : <p className='body'> Oops! You have no Call-off Mechanism selected.  Please update your project to continue. </p>}
            footer={true}
            handleMainActionBtnClick = {() => history.push(`/project/edit/${projectId}`)}
            mainActionButtonTxt={'Update Project'}
            secondActionButton={true}
            handleSecondaryActionBtnClick={() => setCallOffModal(false)}
            secondActionButtonTxt={'Cancel'}
            helpOption={false}
            closeModal={() => setCallOffModal(false)}
        />}
        </>
    );
};

EventSection.propTypes = {
    event: PropTypes.object.isRequired,
    projectDetails: PropTypes.object,
    projectId: PropTypes.string,
    workflowId: PropTypes.string,
    handleUpdateEvent: PropTypes.func,
    handleEscalateEvent: PropTypes.func,
    handlePublishEvent: PropTypes.func,
    handleUploadDocument: PropTypes.func,
    handleDeleteDocument: PropTypes.func,
    handleAddWorkNotes: PropTypes.func,
    userSteeringRoles: PropTypes.array,
    routeToMarket: PropTypes.string,
    callOffMechanism: PropTypes.string,
    contractType: PropTypes.array,
    projectType: PropTypes.string,
    readOnly: PropTypes.bool,
    workflow: PropTypes.object,
};

export default EventSection;
