import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Toast from '../../components/Alerts/Toast/Toast';
import projectManagementAPIs from '../../services/project-management.service';
import userManagementAPIs from '../../services/user-management.service';
import Button from '../../components/Button';
import filterIcon from '../../styles/icons/white/filter.svg';
import BrowseFilterPanel from '../../features/BrowseFilterPanel';
import Accordion from '../../features/Accordion';
import BrowsePageHelper from '../../utils/browsePageHelper';
import actions from './slice/supplier.actions';
import browseCleverQuotesConstants from './browseCleverQuotesConstants';
import { ProjectDataContext } from '../../context/ProjectDataContext';
import userAndDepartmentsHelper from '../../features/AddUsersAndDepartmentsToDirectAwardModal/usersAndDepartmentsHelper';
import { generateAccordionData, generateNewAccordionData } from './helpers';
import { ThemeContext } from '../../context/ThemeContext';


const BrowseCleverQuotes = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [isCleverQuotesRun, setIsCleverQuotesRun] = useState(false);
    const [filterPanelOpen, setFilterPanelOpen] = useState(false);
    const [searchSortFilterData, setSearchSortFilterData] = useState({});
    const { projectID, eventID, opportunityID } = useParams();
    const [interest, setInterest] = useState([]);
    const { setProjectData } = useContext(ProjectDataContext);
    const history = useHistory();
    const { toggle } = useContext(ThemeContext);

    const dispatch = useDispatch();
    const {
        supplierState,
    } = useSelector((state) => ({
        supplierState: state.SupplierReducer.supplierState,
    }));

    useEffect(() => {
        if (suppliers.length > 0) {
            dispatch(actions.setSupplierState(suppliers));
        }
    }, [suppliers]);

    useEffect(() => {
        if (supplierState.length > 0) {
            setIsCleverQuotesRun(true);
            setSuppliers(supplierState);
        }
    }, []);

    const fetchData = async (filterOptions) => {
        try {
            const response = await userManagementAPIs.getCleverQuotes(projectID, filterOptions);
            if (response.status === 200) {
                setSuppliers(response.data.items.map((item) => ({ ...item, selected: true })));
                setIsCleverQuotesRun(true);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: browseCleverQuotesConstants.errorOnRetrieval,
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: browseCleverQuotesConstants.errorOnRetrieval,
            });
        }
    };

    const fetchInterest = async () => {
        try {
            const response = await projectManagementAPIs.getInterest(projectID, eventID);
            if (response.status === 200) {
                setInterest(response.data);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: browseCleverQuotesConstants.errorOnRetrieval,
            });
        }
    };

    const fetchProject = async () => {
        try {
            const response = await projectManagementAPIs.getProject(projectID);
            const body = { accountIds: [response.data.accountID] };
            const account = await userManagementAPIs.findAccounts(body);
            const localInfo = [
                {
                    code: account.data[0].localityID,
                    description: account.data[0].localityName,
                },
            ];

            if (response.status === 200) {
                const filters = {
                    ...searchSortFilterData,
                    filter: {
                        industryCodes: response.data?.industryCodes
                            ? [...response.data?.industryCodes] : [],
                        deliveryArea: [],
                        industryInfo: response.data?.industryInfo
                            ? [...response.data?.industryInfo] : [],
                        deliveryAreaInfo: response.data?.deliveryAreaInfo
                            ? [...response.data?.deliveryAreaInfo] : [],
                        localityInfo: localInfo || [],
                    },
                };
                setSearchSortFilterData(filters);
                if (Object.keys(supplierState).length === 0) setFilterPanelOpen(true);
                setProjectData({ title: response.data.title });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: browseCleverQuotesConstants.errorOnRetrieval,
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: browseCleverQuotesConstants.errorOnRetrieval,
            });
        }
    };

    useEffect(() => {
        fetchInterest();
        if (projectID) {
            fetchProject();
        }
    }, []);

    const handleFilterData = (data, isFetchData) => {
        const updatedState = {
            ...data,
            filter: {
                ...data.filter,
            },
        };

        if (data.filter && Object.keys(data.filter).length === 0) {
            updatedState.filter = {};
        }

        setSearchSortFilterData({
            ...updatedState,
        });


        if (isFetchData) {
            const filterData = BrowsePageHelper.prepareBrowsePageQueryParams(updatedState, '');
            if (filterData) {
                delete filterData.search;
                delete filterData.startKey;
            }
            fetchData(filterData);
        }
    };

    const handleAddSuppliersToOpportunity = async () => {
        const payload = suppliers
            .filter((item) => item.selected === true)
            .map((item) => ({
                accountID: item.supplierID,
                accountName: item.accountName,
                ...(item.usersAndDepartments
                    && {
                        departments: userAndDepartmentsHelper
                            .mapUsersAndDepartments(item.usersAndDepartments),
                    }),
            }));

        try {
            const response = await projectManagementAPIs.registerInterestSuppliers(opportunityID,
                payload);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: browseCleverQuotesConstants.toastSuccessAdding,
                });
                dispatch(actions.setSupplierState({}));
                history.push(`/suppliers/manage/${projectID}/${eventID}`);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: browseCleverQuotesConstants.toastErrorAdding,
                });
            }
        } catch (e) {
            Toast.fire({
                icon: 'error',
                titleText: browseCleverQuotesConstants.toastErrorAdding,
            });
        }
    };


    const handleBackButton = () => {
        dispatch(actions.setSupplierState({}));
        history.push(`/suppliers/manage/${projectID}/${eventID}`);
    };

    const getContentBody = () => <Accordion
        id='bdAccordion'
        data={toggle ? generateNewAccordionData({
            opportunityID,
            projectID,
            eventID,
            suppliers,
            setSuppliers,
        }) : generateAccordionData({
            suppliers, setSuppliers, history, projectID, opportunityID, eventID,
        })}
        open={true}
    />;

    return <>
        <div className='bdContentSectionWrapper'>
            <section className='bdContentSection' id={''}>
                <div className='bdContentHeader' id='cleverQuotesContentHeader'>
                    <div className='sortFilterContainer'>
                        <Button
                            id={'cleverQuotesBackButton'}
                            label={browseCleverQuotesConstants.btnBack}
                            variant='normal-link'
                            additionalVariant='customButtonStyle'
                            handleClick={() => handleBackButton()}
                        />
                        {isCleverQuotesRun
                        && <>
                            {suppliers?.length > 0
                                ? <Button
                                    id={'addSupplierToOpportunityButton'}
                                    label={browseCleverQuotesConstants.btnConfirmSelection}
                                    variant='primary'
                                    disabled={suppliers.filter((item) => item.selected)
                                        .length === 0}
                                    additionalVariant='customButtonStyle'
                                    handleClick={handleAddSuppliersToOpportunity}
                                />
                                : <Button
                                    id={'addSupplierButton'}
                                    label={browseCleverQuotesConstants.btnAddSuppliers}
                                    variant='primary'
                                    additionalVariant='customButtonStyle'
                                    handleClick={() => history.push(`/suppliers/register-interest/${opportunityID}/${projectID}/${eventID}`)}
                                />}
                        </>}
                        { interest.length === 0 && <Button
                            id={'cleverQuotesFilterButton'}
                            label={browseCleverQuotesConstants.btnSetCriteria}
                            icon={true}
                            variant='secondary'
                            iconSrc={filterIcon}
                            disabled={suppliers.filter((item) => item.selected === true)
                                .length > 0}
                            handleClick={() => setFilterPanelOpen(true)}
                        /> }
                    </div>
                </div>
                <div className='bdContentBody' aria-live='assertive'>
                    { interest.length === 0
                        ? <> {isCleverQuotesRun
                            ? <>
                                {suppliers.length > 0
                                    ? getContentBody()
                                    : <p className = 'body'>{browseCleverQuotesConstants.suppliersNotFound}</p>}
                            </>
                            : <p className = 'body'>{browseCleverQuotesConstants.noticeRandomSuppliers}</p>}
                        </>
                        : <>
                            <p className = 'body'>{browseCleverQuotesConstants.errorSuppliersRegistered}</p>
                        </>}

                </div>
                {filterPanelOpen && interest.length === 0 && <BrowseFilterPanel
                    open={filterPanelOpen}
                    closePanel={() => setFilterPanelOpen(false)}
                    handleFilterCallback={handleFilterData}
                    context={'Suppliers'}
                    searchSortFilterData={searchSortFilterData}
                    handleUpdateSearchSortFilterData={(data) => handleFilterData(data, false)}
                />}
            </section>
        </div>
    </>;
};

export default BrowseCleverQuotes;
