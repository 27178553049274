import React from 'react';
import StatusLabel from '../../components/StatusLabel';
import Button from '../../components/Button';
import helperFunctions from '../../utils/helperFunctions';
import { RICH_TEXT, evaluationPanel } from '../../config/constants';
import Constants from './constants';
import {
    getSortedLots,
    isAllowedToEditDeleteQuestionnaire,
    isAllowedToAssignEditWeightage,
    isAllowedToEditLots,
} from './helpers';

const contentMap = {
    [Constants.MANAGE_QUESTIONNAIRE]: ({
        data, validateQuestionnaire, generateResponseWeightageMessage,
        checkResponseWeightage, isAssignWeightageApplicable, generateResponseWeightage,
        projectDetails, rbac, authContext, isOpportunityLive, hasSubmissionEndDatePassed,
        confirmRemove, history, projectId, eventId, lots, eventDetails, handleEditModal,
    }) => ({
        link: undefined,
        handleClick: () => history.push(`/questionnaires/view/${data.templateID}/${data.responseID}`, {
            deadlinePassed: hasSubmissionEndDatePassed,
        }),
        header: {
            title: data.templateName || Constants.N_A,
            subtitle: <>{data?.lots.length > 0 && <><p className='title bold'>{Constants.LOTS}:&nbsp;</p>
                {getSortedLots(data.lots)}
            </>}</>,
            pills: [{
                value: <StatusLabel
                    id={'invalidQuestionnaire'}
                    color={'red'}
                    labelTxt={Constants.INVALID_PUBLISH_LEVEL} />,
                showOnlyIf: { value: validateQuestionnaire(data) === false },
            }, {
                value: <StatusLabel
                    id={'intentDocsApproval'}
                    color={'amber'}
                    labelTxt={generateResponseWeightageMessage(data)} />,
                showOnlyIf: {
                    value: !checkResponseWeightage(data)
                    && isAssignWeightageApplicable(data),
                },
            }],
        },
        rows: [
            [
                {
                    value: helperFunctions.getHtmlOrPlainTextBlock(data.templateDescription
                    || Constants.N_A, RICH_TEXT.QUESTIONNAIRE_DESCRIPTION),
                },
            ],
            [
                {
                    label: Constants.WEIGHTING,
                    value: generateResponseWeightage(data) > 0 ? <p className='label title mqWeightage'>{`${generateResponseWeightage(data)}`}</p> : <p className='title assigned'>{Constants.YET_TO_BE_ASSGNED}</p>,
                    showOnlyIf: { value: isAssignWeightageApplicable(data) },
                },
            ],
            [
                {
                    label: evaluationPanel,
                    value: helperFunctions.getEvaluationPanelLabel(data.evaluationPanel),
                    showOnlyIf: { value: data.evaluationPanel },
                },
            ],
            [
                {
                    label: Constants.CREATED_DATE,
                    value: helperFunctions
                        .formatPrettyDateTime(data.createdDate) || Constants.N_A,
                },
            ],
        ],
        extra: <div className='mqAccordionFooter'>
            <div className='mqlotButtons'>
                {isAllowedToEditDeleteQuestionnaire({ rbac, authContext, projectDetails })
                && <>
                    <Button
                        id={'mqButton'}
                        variant='primary'
                        additionalVariant='red'
                        size='small'
                        disabled={isOpportunityLive || hasSubmissionEndDatePassed}
                        label={Constants.DELETE}
                        handleClick={() => confirmRemove(data)} />
                    {data.templateType !== Constants.DEFAULT_SELECTION_QUESTIONNAIRE && <Button
                        id={'mqButton'}
                        variant='secondary'
                        size='small'
                        disabled={hasSubmissionEndDatePassed
                            || validateQuestionnaire(data) === false}
                        label={Constants.EDIT}
                        handleClick={() => history.push(`/questionnaires/edit/${data.templateID}/${projectId}/${eventId}/_/${data.responseID}`)} />}
                </>
                }
            </div>
            <div className='mqWeightageButtons'>
                {isAllowedToEditLots({
                    rbac, authContext, projectDetails, lots, eventDetails,
                })
                && <Button
                    id={'mqButton'}
                    variant='secondary'
                    size='small'
                    disabled={isOpportunityLive
                        || hasSubmissionEndDatePassed || validateQuestionnaire(data) === false}
                    label={Constants.EDIT_LOTS}
                    handleClick={() => handleEditModal(data)} />
                }
                {
                    isAllowedToAssignEditWeightage({
                        rbac, authContext, data, projectDetails, isAssignWeightageApplicable,
                    })
                && <Button
                    id={'mqButton-wide'}
                    variant='primary'
                    size='small'
                    label={data.weightage ? Constants.EDIT_WEIGHTING : Constants.ASSIGN_WEIGHTING}
                    disabled={hasSubmissionEndDatePassed
                        || validateQuestionnaire(data) === false
                    }
                    handleClick={() => history.push(`/questionnaires/weightage/${eventId}/${data.responseID}`)} />}
            </div>
        </div>,

    }),
};


export default contentMap;
