const Constants = {
    IMAGES_PATH: '../../styles/images/',
    STATUS: {
        ERROR: 'error',
        LOADING: 'loading',
        SUCCESS: 'success',
    },
    ERROR_LOADING_IMAGE: 'Error loading image',
    FOLDER: {
        BUYERS: 'buyers',
        ICONS: 'icons',
    },
    EXTENSION: {
        SVG: 'svg',
    },
};
export default Constants;
