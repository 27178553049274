import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import SideDrawer from '../../components/SideDrawer';
import Accordion from '../Accordion';
import Button from '../../components/Button';
import closeBtn from '../../styles/icons/grey/close.svg';
import Constants from '../Header/constants';
import AuthContext from '../../context/AuthContext';
import { LOGIN } from '../../config/constants';

const BurgerMenu = ({
    open,
    menuData,
    userData,
    handleCloseBtnClick,
    // eslint-disable-next-line no-unused-vars
    showLoginModal,
    toggleRegModal,
}) => {
    // @ TODO: Change showlogin modal.
    const showLoginButton = menuData === Constants.menuData.offline && true;
    const context = useContext(AuthContext);
    const history = useHistory();

    const location = useLocation();
    const generateChildren = () => {
        const childrenHTML = [];
        let logout = false;
        const accordionData = [];
        if (userData.length > 0) {
            userData.forEach((data, idx) => {
                if (data.link !== '/logout') {
                    const { name } = data;
                    const { link } = data;
                    const dataId = (location.pathname === link) ? 'burger-menu-data-active' : `burger-usermenu-data-${idx}`;
                    const header = (location.pathname === link)
                        ? <><div className='burgerMenuActiveStrip'></div><p className='accordion-section-header-title title link-active'>{name}</p></>
                        : <p className='accordion-section-header-title title'>{name}</p>;
                    accordionData.push({ header, link, sectionId: dataId });
                } else {
                    logout = true;
                }
            });
        }

        menuData.forEach((data, idx) => {
            const { title } = data;
            let { link } = data;
            const { options } = data;
            const { hasOptions } = data;
            let dataId;
            let header;

            if (hasOptions) {
                options.forEach((option, index) => {
                    dataId = (location.pathname === option.link) ? 'burger-menu-data-active' : `burger-menu-data-${idx}-${index}`;
                    header = (location.pathname === option.link)
                        ? <><div className='burgerMenuActiveStrip'></div><p className='accordion-section-header-title title link-active'>{`${title} ${option.name}`}</p></>
                        : <p className='accordion-section-header-title title'>{`${title} ${option.name}`}</p>;
                    link = option.link;
                    accordionData.push({ header, link, sectionId: dataId });
                });
            } else {
                dataId = (location.pathname === link) ? 'burger-menu-data-active' : `burger-menu-data-${idx}`;

                header = (location.pathname === link)
                    ? <><div className='burgerMenuActiveStrip'></div><p className='accordion-section-header-title title link-active'>{title}</p></>
                    : <p className='accordion-section-header-title title'>{title}</p>;
                accordionData.push({ header, link, sectionId: dataId });
            }
        });

        childrenHTML.push(<React.Fragment key={'burger-menu'}>
            <div id={'burger-menu-header'}>
                <button
                    type='button'
                    className='closeBtn'
                    id={'burger-menu-close'}
                    onClick={handleCloseBtnClick}>
                    <img id='closeButton'
                        src={closeBtn}
                        className='closeBtn-img'
                        alt='modal-close-button'></img>
                </button>
            </div>
            {logout === true
            && <div id='burger-menu-offline-container'>
                <Button id='loginBtn'
                    variant='primary'
                    size='regular'
                    label='Log out'
                    handleClick={() => { context.logout(() => history.push('/')); }} />
            </div>
            }
            {showLoginButton && <div id='burger-menu-offline-container'>
                <Button id='getStartedBtn'
                    label='Get Started'
                    size='regular'
                    handleClick={() => { handleCloseBtnClick(); toggleRegModal(); }} />
                <Button id='loginBtn'
                    variant='secondary'
                    size='regular'
                    label={LOGIN}
                    handleClick={() => {
                        history.push('/login');
                        // handleCloseBtnClick(); showLoginModal(true);
                    }} />
            </div>
            }
            <Accordion id={'burger-menu-data'}
                data={accordionData}
                allowMultipleExpanded={false} />
        </React.Fragment>);

        return childrenHTML;
    };

    return (<SideDrawer open={open}
        id={'burger-menu'}
        openDirection='right'
        tabletsAndMobileOnly={true}>
        {generateChildren()}
    </SideDrawer>);
};

BurgerMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    menuData: PropTypes.array.isRequired,
    userData: PropTypes.array,
    handleCloseBtnClick: PropTypes.func.isRequired,
    showLoginModal: PropTypes.func,
    toggleRegModal: PropTypes.func,
};

export default BurgerMenu;
