import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { capitalize } from 'inflected';

import Button from '../../../components/Button';
import { antivirusStatus, fileDownloadLabels } from '../../../config/constants';
import { fileShouldBeDisplayedToUser } from '../../../utils/uploadHelper';
import AuthContext from '../../../context/AuthContext';

function UploadItem({
    document, removeDocument, downloadFile, readOnly = false, showExpiryDate = true,
}) {
    const authContext = useContext(AuthContext);
    const status = document.scanned;
    // Default to successful status
    const statusProps = {
        downloadButtonLabel: fileDownloadLabels.btn_success,
        downloadButtonEnabled: true,
        deleteButtonLabel: fileDownloadLabels.btn_delete,
        deleteButtonEnabled: true,
        descriptionText: fileDownloadLabels.description_success,
    };
    // Change the status if it's actually failed
    if (status === antivirusStatus.failed) {
        statusProps.downloadButtonLabel = fileDownloadLabels.btn_failed;
        statusProps.downloadButtonEnabled = false;
        statusProps.deleteButtonLabel = fileDownloadLabels.btn_delete_failed;
        statusProps.deleteButtonEnabled = true;
        statusProps.descriptionText = fileDownloadLabels.description_failed;
    }
    // Change the status if it's pending
    if (status === antivirusStatus.pending) {
        statusProps.downloadButtonLabel = fileDownloadLabels.btn_pending;
        statusProps.downloadButtonEnabled = false;
        statusProps.deleteButtonLabel = fileDownloadLabels.btn_delete;
        statusProps.deleteButtonEnabled = true;
        statusProps.descriptionText = fileDownloadLabels.description_pending;
    }
    if (!fileShouldBeDisplayedToUser(document, authContext.user)) {
        return '';
    }
    return <section className='worknote-item'>
        <p className='title-small bold'>{document.filename}</p>
        <div className='worknote-item-row'>
            <div className='worknote-section'>
                {document.document?.uploadedByUser && <div className='sectionRow'>
                    <span className='title-small sectionLabel'>Uploaded By: </span>
                    <span className='title-small'>
                        {document.document?.uploadedByUser?.contactDetails?.username || 'Unknown'}
                    </span>
                </div>}
                {document.document?.lastModified && <div className='sectionRow'>
                    <span className='title-small sectionLabel'>Uploaded Date: </span>
                    <span className='title-small'>{document.document?.lastModified}</span>
                </div>}
                {showExpiryDate && document.document?.expiryDate && <div className='sectionRow'>
                    <span className='title-small sectionLabel'>Expiry Date: </span>
                    <span className='title-small'>{document.document?.expiryDate}</span>
                </div>}
                {document.resourceType && <span className='title-small'>{capitalize(document.resourceType)}</span>}
                <span className='title-small sectionLabel av-status-message'>{statusProps.descriptionText}
                </span>
            </div>
            <div className='buttonContainer'>
                <Button
                    id='downloadDocument'
                    label={statusProps.downloadButtonLabel}
                    disabled={!statusProps.downloadButtonEnabled}
                    size='small'
                    variant='primary'
                    handleClick={(e) => {
                        e.preventDefault();
                        downloadFile(document);
                    }}
                />
                <div className='buttonDivider'></div>
                {!readOnly && <Button
                    id='deleteDocument'
                    label={statusProps.deleteButtonLabel}
                    disabled={!statusProps.deleteButtonEnabled}
                    size='small'
                    variant='secondary'
                    handleClick={(e) => {
                        e.preventDefault();
                        removeDocument(document);
                    }}
                />}
            </div>
        </div>

        <div className='worknote-item-divider'></div>
    </section>;
}
UploadItem.propTypes = {
    document: propTypes.oneOfType([
        propTypes.array,
        propTypes.object,
    ]).isRequired,
    removeDocument: propTypes.func,
    downloadFile: propTypes.func.isRequired,
    readOnly: propTypes.bool,
    showExpiryDate: propTypes.bool,
};
export default UploadItem;
