/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { set } from 'lodash';
import TimePicker from 'react-time-picker';
import { useHistory } from 'react-router-dom';
import {
    InputComponent,
    DatePickerComponent,
    RadioButtonComponent,
    SelectSearchComponent,
    ErrorMessagesFor,
} from './components';
import FTSNameAndAddresses from './FTSNameAndAddresses';
import LabelledInput from '../../components/LabelledInput';
import LabelledTextArea from '../../components/LabelledTextArea';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import Button from '../../components/Button';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import clearIcon from '../../styles/icons/blue/clear.svg';
import FTSContracts from './FTSContracts';
import Confirm from '../../components/Alerts/Confirm/Confirm';

const FTSF02NoticeForm = ({
    project,
    noticeEvent,
}) => {
    const [formFields, setFormFields] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);
    const history = useHistory();

    const handleErrors = (errors) => {
        setErrorMessages(errors?.filter((v, i, a) => a.indexOf(v) === i));
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleFormChange = (e, fieldName) => {
        let name;
        let value;

        if (e?.target) {
            if (e?.target.type === 'checkbox') {
                name = e.target.name;
                value = e.target.checked ? e.target.value : '';
            } else {
                name = e.target.name;
                value = e.target.value;
            }
        } else {
            name = fieldName;
            value = e;
        }

        const nodeTree = name.split('.');

        setFormFields((prevState) => (
            { ...set((prevState || {}), nodeTree.join('.'), value) }
        ));
    };

    const confirmAction = async (e, formData) => {
        const { projectID } = project;
        const { eventID, noticeType } = noticeEvent;
        let responseBody = {};
        setErrorMessages([]);
        e.preventDefault();
        const validationErrorMessages = [];
        let IsValid = true;
        formData.forEach((value, property) => {
            if (value !== '' && !property.includes('notMapped') && property !== 'day'
            && property !== 'month' && property !== 'year' && property !== 'hour24'
            && property !== 'minute' && property !== 'time') {
                if (property === 'procedure.languages' || property.includes('cpvSupplementaryCodes')) {
                    // eslint-disable-next-line no-param-reassign
                    value = value.split(',');
                }
                if (value === 'false') {
                    // eslint-disable-next-line no-param-reassign
                    value = (value === 'true');
                }
                if (value === 'true') {
                    // eslint-disable-next-line no-param-reassign
                    value = (value === 'true');
                }

                if (property.includes('weighting')) {
                    // eslint-disable-next-line no-param-reassign
                    value = Number(value);
                }
                if (property === 'procedure.receiptTenders.dateReceiptTenders') {
                    if (new Date(value) <= new Date()) {
                        validationErrorMessages.push('Time limit for receipt of tenders or requests to participation deadline must be in the future.');
                        IsValid = false;
                    }
                }
                if (property === 'procedure.openingCondition.dateOpeningTenders') {
                    if (new Date(value) <= new Date(formFields?.procedure?.receiptTenders?.dateReceiptTenders)) {
                        validationErrorMessages.push('The opening of tenders must be after the tender/participation request deadline');
                        IsValid = false;
                    }
                }
                if (property.includes('lotMaxOneTenderer')) {
                    const maxOneTender = parseInt(value, 10);
                    if (maxOneTender < 1 || maxOneTender > 1000) {
                        validationErrorMessages.push('Maximum number of lots to be awarded to one tenderer must be between 1 & 1000.');
                        IsValid = false;
                    } else if (formFields?.notMapped?.lotsQuantity > maxOneTender) {
                        validationErrorMessages.push('Number of lots exceeds maximun number of lots that can be awarded to one tenderer.');
                        IsValid = false;
                    }
                }
                if (property.includes('cpvCode')) {
                    const prefix = parseInt(value.substring(0, 2), 10);
                    const contractType = formFields?.objectContracts ? formFields?.objectContracts[0]?.typeContract?.ctype : '';
                    if (contractType === 'WORKS') {
                        if (prefix !== 45) {
                            validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of WORKS must have a CPV code that begins with 45.');
                            IsValid = false;
                        }
                    } else if (contractType === 'SUPPLIES') {
                        if (prefix > 44) {
                            if (prefix !== 48) {
                                validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of SUPPLIES must have a CPV code that begins with 0 to 44, or 48.');
                                IsValid = false;
                            }
                        }
                    } else if (contractType === 'SERVICES') {
                        if (prefix < 49) {
                            validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of SERVICES must have a CPV code that begins with a number higher than 49.');
                            IsValid = false;
                        }
                    }
                }
                const nodeTree = property.split('.');
                responseBody = ({ ...set((responseBody || {}), nodeTree, value) });
            }
        });
        const payload = {
            type: noticeType,
            formSection: responseBody,
        };

        if (IsValid && projectID && eventID) {
            try {
                const response = await projectManagementAPIs.publishFTSNotice(projectID,
                    eventID, payload);
                if (response.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        titleText: 'Created notice successfully.',
                    });
                    history.push(`/projects/overview/${project.projectID}`);
                } else {
                    handleErrors(response?.data?.Errors?.body || response?.data?.Errors);
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to create notice.',
                });
            }
        } else {
            handleErrors(validationErrorMessages);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        Confirm(async () => {
            await confirmAction(e, formData);
        }, {
            subTitle: 'Are you sure you want to submit?',
        });
    };

    const prettyProcedureType = (pType) => {
        switch (pType) {
            case 'OPEN':
                return 'Open procedure';
            case 'RESTRICTED':
                return 'Restricted procedure';
            case 'COMPETITIVE_NEGOTIATION':
                return 'Competitive procedure with negotiation';
            case 'COMPETITIVE_DIALOGUE':
                return 'Competitive dialogue';
            case 'INNOVATION_PARTNERSHIP':
                return 'Innovation partnership';
            default:
                return '';
        }
    };

    return <>
        <form id='form_02' onSubmit={handleFormSubmit}>
            <div className='vpnDivider'></div>
            <ErrorMessagesFor
                errorMessages={errorMessages}
                origin={'FTS'}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>F02 Form: {noticeEvent?.noticeType}</h3>
            <div className='formElementsDivider'></div>

            <h3 className='title-large subHeading'>Preliminary questions</h3>
            <RadioButtonComponent
                id='objectContracts.0.typeContract.ctype'
                label='Contract Type'
                value={formFields?.objectContracts ? formFields?.objectContracts[0]?.typeContract?.ctype : ''}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    WORKS: 'Works',
                    SUPPLIES: 'Supplies',
                    SERVICES: 'Services',
                }}
            />
            <RadioButtonComponent
                id='procedure.pt'
                label='Procedure Type'
                value={formFields?.procedure?.pt}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    OPEN: 'Open procedure',
                    RESTRICTED: 'Restricted procedure',
                    COMPETITIVE_NEGOTIATION: 'Competitive procedure with negotiation',
                    COMPETITIVE_DIALOGUE: 'Competitive dialogue',
                    INNOVATION_PARTNERSHIP: 'Innovation partnership',
                }}
            />
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'notMapped.jointProcurement'}
                label={'Joint procurement'}
                breakColumn={true}
                options={[{
                    id: 'notMapped.jointProcurement',
                    value: true,
                    label: 'The contract involves joint procurement',
                    checked: formFields?.notMapped?.jointProcurement === 'true',
                    name: 'notMapped.jointProcurement',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.notMapped?.jointProcurement === 'true'
            && <InputComponent
                id={'notMapped.numberOfContractingAuthorities'}
                label='Number of contracting authorities responsible for the procedure'
                value={formFields?.notMapped?.numberOfContractingAuthorities}
                type='number'
                minNumber={1}
                onChange={handleFormChange}
                required={false}
            />}
            <div className='formElementsDivider'></div>

            <h3 className='title-large subHeading'>Section I. Contracting authority</h3>
            <h3 className='title-large subHeading'>I.1. Name and addresses</h3>
            <FTSNameAndAddresses
                prefix={'contractingBody.addressContractingBody'}
                handleChange={handleFormChange}
                formFields={formFields}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>I.2. Joint Procurement</h3>
            {formFields?.notMapped?.jointProcurement === 'true'
            && <LabelledTextArea
                id='contractingBody.procurementLaw'
                breakColumn={true}
                label='In the case of joint procurement involving different countries, state applicable national procurement law'
                value={formFields?.contractingBody?.procurementLaw || ''}
                maxCharCount={3000}
                onChange={(e) => handleFormChange(e)}
                required={false}
            />}
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'contractingBody.centralPurchasing'}
                label={'The contract involves joint procurement (as specified in preliminary questions)'}
                breakColumn={true}
                options={[{
                    id: 'contractingBody.centralPurchasing',
                    value: true,
                    label: 'The contract is awarded by a central purchasing body',
                    checked: formFields?.contractingBody?.centralPurchasing === 'true',
                    name: 'contractingBody.centralPurchasing',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>I.3. Communication</h3>
            <RadioButtonComponent
                id='contractingBody.urlDocument.type'
                label='How are the procurement documents being made available?'
                value={formFields?.contractingBody?.urlDocument?.type}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    FULL: 'The procurement documents are available for unrestricted and full direct access, free of charge, at',
                    RESTRICTED: 'Access to the procurement documents is restricted. Further information can be obtained at',
                }}
            />
            <InputComponent
                id={'contractingBody.urlDocument.url'}
                label='URL'
                value={formFields?.contractingBody?.urlDocument?.url || ''}
                type='text'
                pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]{2,}'}
                title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
                required={true}
                onChange={handleFormChange}
            />
            <RadioButtonComponent
                id='notMapped.contractingBody.addressContractingBody.additionalInfo'
                label='Additional information can be obtained from'
                value={formFields?.notMapped?.contractingBody?.addressContractingBody?.additionalInfo}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    MENTIONED_ADDRESS: 'The above-mentioned address',
                    ANOTHER_ADDRESS: 'Another address',
                }}
            />
            {formFields?.notMapped?.contractingBody?.addressContractingBody?.additionalInfo === 'ANOTHER_ADDRESS'
            && <FTSNameAndAddresses
                prefix= {'contractingBody.addressFurtherInfo'}
                handleChange={handleFormChange}
                formFields={formFields}
            />}
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'notMapped.tendersRequestElectronicallyVia'}
                label={'Tenders or requests to participate must be submitted'}
                breakColumn={true}
                options={[{
                    id: 'notMapped.tendersRequestElectronicallyVia',
                    value: true,
                    label: 'Electronically via',
                    checked: formFields?.notMapped?.tendersRequestElectronicallyVia === 'true',
                    name: 'notMapped.tendersRequestElectronicallyVia',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.notMapped?.tendersRequestElectronicallyVia === 'true'
                && <InputComponent
                    id={'contractingBody.tendersRequest.urlParticipation'}
                    label='URL'
                    value={formFields?.contractingBody?.tendersRequest?.urlParticipation || ''}
                    type='text'
                    pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]{2,}'}
                    title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
                    required={false}
                    onChange={handleFormChange}
                />
            }
            <RadioButtonComponent
                id='notMapped.postTo'
                label='And/or by post to'
                value={formFields?.notMapped?.postTo}
                required={formFields?.notMapped?.tendersRequestElectronicallyVia !== 'true' || true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    ABOVE_ADDRESS: 'The above-mentioned address',
                    ANOTHER_ADDRESS: 'Another address',
                    NONE: 'None of the Above',
                }}
            />
            {formFields?.notMapped?.postTo === 'ANOTHER_ADDRESS'
                && <FTSNameAndAddresses
                    prefix= {'contractingBody.tendersRequest.addressParticipation'}
                    handleChange={handleFormChange}
                    formFields={formFields}
                />
            }
            <input
                type='hidden'
                name='contractingBody.tendersRequest.addressParticipationType'
                id='contractingBody.tendersRequest.addressParticipationType'
                value={formFields?.notMapped?.postTo || ''}
            />
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'notMapped.electronicCommunication'}
                label={''}
                breakColumn={true}
                options={[{
                    id: 'notMapped.electronicCommunication',
                    value: true,
                    label: 'Electronic communication requires the use of tools and devices that are not generally available. Unrestricted and full direct access to these tools and devices is possible, free of charge, at',
                    checked: formFields?.notMapped?.electronicCommunication === 'true',
                    name: 'notMapped.electronicCommunication',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.notMapped?.electronicCommunication === 'true'
            && <InputComponent
                id={'contractingBody.urlTool'}
                label='URL'
                value={formFields?.contractingBody?.urlTool || ''}
                type='text'
                pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]{2,}'}
                title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
                required={true}
                onChange={handleFormChange}
            />}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>I.4. Type of the contracting authority</h3>
            <RadioButtonComponent
                id='contractingBody.caType'
                label=''
                value={formFields?.contractingBody?.caType}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    MINISTRY: 'Ministry or any other national or federal authority, including their regional or local subdivisions',
                    NATIONAL_AGENCY: 'National or federal agency/office',
                    REGIONAL_AUTHORITY: 'Regional or local authority',
                    REGIONAL_AGENCY: 'Regional or local agency/office',
                    BODY_PUBLIC: 'Body governed by public law',
                    OTHER: 'Other type',
                }}
            />
            <div className='formElementsDivider'></div>
            {formFields?.contractingBody?.caType === 'OTHER'
            && <LabelledTextArea
                id={'contractingBody.caTypeOther'}
                label='Please enter details here'
                value={formFields?.contractingBody?.caTypeOther || ''}
                type='textarea'
                required={false}
                onChange={handleFormChange}
            />}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>I.5. Main activity</h3>
            <RadioButtonComponent
                id='contractingBody.caActivity'
                label=''
                value={formFields?.contractingBody?.caActivity}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    GENERAL_PUBLIC_SERVICES: 'General public services',
                    DEFENCE: 'Defence',
                    PUBLIC_ORDER_AND_SAFETY: 'Public order and safety',
                    ENVIRONMENT: 'Environment',
                    ECONOMIC_AND_FINANCIAL_AFFAIRS: 'Economic and financial affairs',
                    HEALTH: 'Health',
                    HOUSING_AND_COMMUNITY_AMENITIES: 'Housing and community amenities',
                    SOCIAL_PROTECTION: 'Social protection',
                    RECREATION_CULTURE_AND_RELIGION: 'Recreation, culture and religion',
                    EDUCATION: 'Education',
                    OTHER: 'Other activity',
                }}
            />
            {formFields?.contractingBody?.caActivity === 'OTHER'
            && <LabelledTextArea
                id={'contractingBody.caActivityOther'}
                label='Please enter details here'
                value={formFields?.contractingBody?.caActivityOther || ''}
                type='textarea'
                required={false}
                onChange={handleFormChange}
            />}
            <div className='formElementsDivider'></div>
            {// eslint-disable-next-line no-undef
                _.times(formFields?.notMapped?.numberOfContractingAuthorities, (index) => (
                    <>
                        <h3 className='title-large subHeading'>Section I.1. Additional contracting authority ({index + 1})</h3>
                        <h3 className='title-large subHeading'>I.1. Name and addresses ({index + 1}) </h3>
                        <FTSNameAndAddresses
                            prefix={`contractingBody.addressContractingBodyAdditional.${index}`}
                            handleChange={handleFormChange}
                            formFields={formFields}
                        />
                    </>
                ))}
            <div className='formElementsDivider'></div>

            <h3 className='title-large subHeading'>Section II. Object</h3>
            <FTSContracts
                prefix={'objectContracts.0'}
                noticeEvent={noticeEvent}
                handleChange={handleFormChange}
                formFields={formFields}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>Section III. Legal, economic, financial and technical information</h3>
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>III.1. Conditions for participation</h3>
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>III.1.1. Suitability to pursue the professional activity, including requirements relating to enrolment on professional or trade registers</h3>
            <LabelledTextArea
                id='lefti.suitability'
                breakColumn={true}
                label='List and brief description of conditions'
                value={formFields?.lefti?.suitability || ''}
                maxCharCount={10000}
                onChange={(e) => handleFormChange(e)}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>III.1.2. Economic and financial standing</h3>
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'lefti.economicCriteriaDoc'}
                breakColumn={true}
                options={[{
                    id: 'lefti.economicCriteriaDoc',
                    value: true,
                    label: 'Selection criteria as stated in the procurement documents ',
                    checked: formFields?.lefti?.economicCriteriaDoc === 'true',
                    name: 'lefti.economicCriteriaDoc',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.lefti?.economicCriteriaDoc === 'false' && <>
                <LabelledTextArea
                    id='lefti.economicFinancialInfo'
                    breakColumn={true}
                    label='List and brief description of selection criteria'
                    value={formFields?.lefti?.economicFinancialInfo || ''}
                    maxCharCount={10000}
                    onChange={(e) => handleFormChange(e)}
                    required={false}
                />
                <LabelledTextArea
                    id='lefti.economicFinancialMinLevel'
                    breakColumn={true}
                    label='Minimum level(s) of standards possibly required'
                    value={formFields?.lefti?.economicFinancialMinLevel || ''}
                    maxCharCount={10000}
                    onChange={(e) => handleFormChange(e)}
                    required={false}
                />
            </>}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>III.1.3. Technical and professional ability</h3>
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'lefti.technicalCriteriaDoc'}
                breakColumn={true}
                options={[{
                    id: 'lefti.technicalCriteriaDoc',
                    value: true,
                    label: 'Selection criteria as stated in the procurement documents',
                    checked: formFields?.lefti?.technicalCriteriaDoc === 'true',
                    name: 'lefti.technicalCriteriaDoc',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.lefti?.technicalCriteriaDoc === 'false' && <>
                <LabelledTextArea
                    id='lefti.technicalProfessionalInfo'
                    breakColumn={true}
                    label='List and brief description of selection criteria'
                    value={formFields?.lefti?.technicalProfessionalInfo || ''}
                    maxCharCount={10000}
                    onChange={(e) => handleFormChange(e)}
                    required={false}
                />
                <LabelledTextArea
                    id='lefti.technicalProfessionalMinLevel'
                    breakColumn={true}
                    label='Minimum level(s) of standards possibly required'
                    value={formFields?.lefti?.technicalProfessionalMinLevel || ''}
                    maxCharCount={10000}
                    onChange={(e) => handleFormChange(e)}
                    required={false}
                />
            </>}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>III.1.5. Information about reserved contracts</h3>
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'lefti.restrictedShelteredWorkshop'}
                breakColumn={true}
                options={[{
                    id: 'lefti.restrictedShelteredWorkshop',
                    value: true,
                    label: 'The contract is reserved to sheltered workshops and economic operators aiming at the social and professional integration of disabled or disadvantaged persons',
                    checked: formFields?.lefti?.restrictedShelteredWorkshop === 'true',
                    name: 'lefti.restrictedShelteredWorkshop',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'lefti.restrictedShelteredProgram'}
                breakColumn={true}
                options={[{
                    id: 'lefti.restrictedShelteredProgram',
                    value: true,
                    label: 'The execution of the contract is restricted to the framework of sheltered employment programme',
                    checked: formFields?.lefti?.restrictedShelteredProgram === 'true',
                    name: 'lefti.restrictedShelteredProgram',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>III.2. Conditions related to the contract</h3>
            <h3 className='title-large subHeading'>III.2.1. Information about a particular profession</h3>
            <LabelledCheckBox
                id={'lefti.particularProfession'}
                breakColumn={true}
                options={[{
                    id: 'lefti.particularProfession',
                    value: true,
                    label: 'Execution of the service is reserved to a particular profession',
                    checked: formFields?.lefti?.particularProfession,
                    name: 'lefti.particularProfession',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.lefti?.particularProfession
            && <LabelledTextArea
                id='lefti.referenceToLaw'
                breakColumn={true}
                label='Reference to the relevant law, regulation or administrative provision'
                value={formFields?.lefti?.referenceToLaw || ''}
                maxCharCount={3000}
                onChange={(e) => handleFormChange(e)}
                required={false}
            />}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>III.2.2. Contract performance conditions</h3>
            <LabelledTextArea
                id='lefti.performanceConditions'
                breakColumn={true}
                label='Contract performance conditions'
                value={formFields?.lefti?.performanceConditions || ''}
                maxCharCount={4000}
                onChange={(e) => handleFormChange(e)}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>III.2.3. Information about staff responsible for the performance of the contract</h3>
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'lefti.performanceStaffQualifications'}
                breakColumn={true}
                options={[{
                    id: 'lefti.performanceStaffQualifications',
                    value: true,
                    label: 'Obligation to indicate the names and professional qualifications of the staff assigned to performing the contract',
                    checked: formFields?.lefti?.performanceStaffQualifications,
                    name: 'lefti.performanceStaffQualifications',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>Section IV. Procedure</h3>
            <h3 className='title-large subHeading'>IV.1. Description</h3>
            <h3 className='title-large subHeading'>IV.1.1. Procedure type</h3>
            <h3 className='title-large subHeading'>{prettyProcedureType(formFields?.procedure?.pt)}</h3>
            <div className='formElementsDivider'></div>
            {formFields?.procedure?.pt && formFields?.procedure?.pt !== 'INNOVATION_PARTNERSHIP'
            && <>
                <LabelledCheckBox
                    id={'notMapped.procedure.acceleratedProcCheck'}
                    breakColumn={true}
                    options={[{
                        id: 'notMapped.procedure.acceleratedProcCheck',
                        value: true,
                        label: 'Accelerated procedure',
                        checked: formFields?.notMapped?.procedure?.acceleratedProcCheck === 'true',
                        name: 'notMapped.procedure.acceleratedProcCheck',
                    }]}
                    onChange={(e) => handleFormChange(e)}
                    renderAsRow={true}
                    required={false}
                />
                <div className='formElementsDivider'></div>
                {formFields?.notMapped?.procedure?.acceleratedProcCheck === 'true'
                && <LabelledTextArea
                    id={'procedure.acceleratedProc'}
                    label='Justification'
                    value={formFields?.procedure?.acceleratedProc}
                    type='text'
                    maxCharCount={1000}
                    onChange={handleFormChange}
                    required={true}
                />}
            </>}
            <h3 className='title-large subHeading'>IV.1.3. Information about a framework agreement or a dynamic purchasing system</h3>
            <LabelledCheckBox
                id={'notMapped.procedure.frameworkCheck'}
                breakColumn={true}
                options={[{
                    id: 'notMapped.procedure.frameworkCheck',
                    value: true,
                    label: 'The procurement involves the establishment of a framework agreement',
                    checked: formFields?.notMapped?.procedure?.frameworkCheck === 'true',
                    name: 'notMapped.procedure.frameworkCheck',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.notMapped?.procedure?.frameworkCheck && formFields?.notMapped?.procedure?.frameworkCheck === 'true'
            && <>
                <div className='formElementsDivider'></div>
                <h3 className='title-large subHeading'>How many operators will be included in the framework agreement?</h3>
                <RadioButtonComponent
                    id='notMapped.procedure.framework.operator'
                    label=''
                    value={formFields?.notMapped?.procedure?.framework?.operator}
                    required={true}
                    onChange={handleFormChange}
                    renderAsRow={false}
                    options={{
                        single: 'Framework agreement with a single operator',
                        multiple: 'Framework agreement with several operators',
                    }}
                />
                {formFields?.notMapped?.procedure?.framework?.operator === 'multiple'
                && <InputComponent
                    id={'procedure.framework.nbParticipants'}
                    label='Envisaged maximum number of participants to the framework agreement'
                    value={formFields?.procedure?.framework?.nbParticipants}
                    type='number'
                    onChange={handleFormChange}
                    required={false}
                />}
                <div className='formElementsDivider'></div>
                <LabelledTextArea
                    id='notMapped.procedure.framework.justification'
                    breakColumn={true}
                    label='In the case of framework agreements, provide justification for any duration exceeding 4 years (optional)'
                    value={formFields?.notMapped.procedure?.framework?.justification || ''}
                    maxCharCount={2000}
                    onChange={(e) => handleFormChange(e)}
                />
            </>}
            {formFields?.procedure?.pt && formFields?.procedure?.pt === 'RESTRICTED'
            && <LabelledCheckBox
                id={'procedure.dpsPurchasers'}
                breakColumn={true}
                options={[{
                    id: 'procedure.dpsPurchasers',
                    value: true,
                    label: 'The procurement involves the setting up of a dynamic purchasing system',
                    checked: formFields?.procedure?.dpsPurchasers === 'true',
                    name: 'procedure.dpsPurchasers',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />}
            {(formFields?.procedure?.pt === 'COMPETITIVE_NEGOTIATION' || formFields?.procedure?.pt === 'COMPETITIVE_DIALOGUE')
            && <>
                <div className='formElementsDivider'></div>
                <h3 className='title-large subHeading'>IV.1.4. Information about reduction of the number of solutions or tenders during negotiation or dialogue (optional)</h3>
                <LabelledCheckBox
                    id={'procedure.reductionRecourse'}
                    breakColumn={true}
                    options={[{
                        id: 'procedure.reductionRecourse',
                        value: true,
                        label: 'Recourse to staged procedure to gradually reduce the number of solutions to be discussed or tenders to be negotiated',
                        checked: formFields?.procedure?.reductionRecourse === 'true',
                        name: 'procedure.reductionRecourse',
                    }]}
                    onChange={(e) => handleFormChange(e)}
                    renderAsRow={true}
                    required={false}
                />
            </>}
            {formFields?.procedure?.pt === 'COMPETITIVE_NEGOTIATION'
            && <>
                <div className='formElementsDivider'></div>
                <h3 className='title-large subHeading'>IV.1.5. Information about negotiation</h3>
                <LabelledCheckBox
                    id={'procedure.rightContractInitialTenders'}
                    breakColumn={true}
                    options={[{
                        id: 'procedure.rightContractInitialTenders',
                        value: true,
                        label: 'The contracting authority reserves the right to award the contract on the basis of the initial tenders without conducting negotiations (optional)',
                        checked: formFields?.procedure?.rightContractInitialTenders === 'true',
                        name: 'procedure.rightContractInitialTenders',
                    }]}
                    onChange={(e) => handleFormChange(e)}
                    renderAsRow={true}
                    required={false}
                />
            </>}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>IV.1.6. Information about electronic auction</h3>
            <LabelledCheckBox
                id={'procedure.eauction'}
                breakColumn={true}
                options={[{
                    id: 'procedure.eauction',
                    value: true,
                    label: 'An electronic auction will be used',
                    checked: formFields?.procedure?.eauction === 'true',
                    name: 'procedure.eauction',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            <div className='formElementsDivider'></div>
            {formFields?.procedure?.eauction === 'true'
            && <LabelledTextArea
                id='procedure.infoAddEauction'
                breakColumn={true}
                label='Additional information about electronic auction'
                value={formFields?.procedure?.infoAddEauction || ''}
                maxCharCount={3000}
                onChange={(e) => handleFormChange(e)}
            />}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>IV.1.8. Information about the Government Procurement Agreement (GPA)</h3>
            <RadioButtonComponent
                id='procedure.contractCoveredGPA'
                label='The procurement is covered by the Government Procurement Agreement'
                value={formFields?.procedure?.contractCoveredGPA}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    true: 'Yes',
                    false: 'No',
                }}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>IV.2. Administrative information</h3>
            <h3 className='title-large subHeading'>IV.2.1. Previous publication concerning this procedure</h3>
            <RadioButtonComponent
                id='notMapped.procedure.previousPublication'
                label='Previous publication concerning this procedure'
                value={formFields?.notMapped?.procedure?.previousPublication}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    PUBLISHED_ON_TED: 'Published on TED',
                    PUBLISHED_ON_FTS: 'Published on Find a Tender',
                    NONE: 'None of the above',
                }}
            />
            {formFields?.notMapped?.procedure?.previousPublication === 'PUBLISHED_ON_TED'
            && <InputComponent
                id='procedure.noticeNumberOJ'
                label='Notice number in the OJS'
                type='text'
                value={formFields?.procedure?.noticeNumberOJ}
                required={false}
                onChange={handleFormChange}
            />}
            {formFields?.notMapped?.procedure?.previousPublication === 'PUBLISHED_ON_FTS'
            && <InputComponent
                id='procedure.noticeNumberOJ'
                label='Linked notice'
                type='text'
                value={formFields?.procedure?.noticeNumberOJ}
                required={false}
                onChange={handleFormChange}
            />}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>IV.2.2. Time limit for receipt of tenders or requests to participate</h3>
            <DatePickerComponent
                id='procedure.receiptTenders.dateReceiptTenders'
                label='Time limit for receipt of tenders or requests to participate'
                value={formFields?.procedure?.receiptTenders?.dateReceiptTenders}
                onChange={handleFormChange}
                minDateFlag={true}
                required={true}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>Local Time</h3>
            <TimePicker
                clockIcon={null}
                className='customDateInput timeReceipt'
                id='procedure.receiptTenders.timeReceiptTenders'
                clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                hourPlaceholder='HH'
                minutePlaceholder='mm'
                format={'HH:mm'}
                locale='en-gb'
                minTime={'00:00'}
                disableClock={true}
                value={formFields?.procedure?.receiptTenders?.timeReceiptTenders}
                onChange={(time) => setFormFields({
                    ...formFields,
                    procedure: {
                        ...formFields?.procedure,
                        receiptTenders: {
                            ...formFields?.procedure?.receiptTenders,
                            timeReceiptTenders: time,
                        },
                    },
                })}
            />
            <input
                type='hidden'
                name='procedure.receiptTenders.timeReceiptTenders'
                id='procedure.receiptTenders.timeReceiptTenders'
                value={formFields?.procedure?.receiptTenders?.timeReceiptTenders}
            />
            {formFields?.procedure?.pt && formFields?.procedure?.pt === 'COMPETITIVE_NEGOTIATION'
            && <>
                <div className='formElementsDivider'></div>
                <h3 className='title-large subHeading'>IV.2.3. Estimated date of dispatch of invitations to tender or to participate to selected candidates</h3>
                <DatePickerComponent
                    id='procedure.dateDispatchInvitations'
                    value={formFields?.procedure?.dateDispatchInvitations}
                    onChange={handleFormChange}
                    minDateFlag={false}
                />
            </>}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>IV.2.4. Languages in which tenders or requests to participate may be submitted</h3>
            <SelectSearchComponent
                id='notMapped.procedure.languages'
                label='Language'
                value={formFields?.notMapped?.procedure?.languages}
                onChange={(e) => handleFormChange(e, 'notMapped.procedure.languages')}
                required={true}
                options={[
                    { EN: 'English' },
                    { DE: 'German' },
                ]}
                optionInputType='obj'
            />
            <input
                type='hidden'
                name='procedure.languages'
                id='procedure.languages'
                value={formFields?.notMapped?.procedure?.languages}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>IV.2.6. Minimum time frame during which the tenderer must maintain the tender (optional)</h3>
            <RadioButtonComponent
                id='notMapped.procedure.minTime'
                value={formFields?.notMapped?.procedure?.minTime}
                required={true}
                onChange={handleFormChange}
                renderAsRow={false}
                options={{
                    TENDERS: 'Tender must be valid until',
                    DURATION_IN_MONTHS: 'Duration in months(from the date stated for receipt of tender)',
                    NONE: 'None of the above',
                }}
            />
            {formFields?.notMapped?.procedure?.minTime === 'TENDERS'
            && <DatePickerComponent
                id='procedure.timeFrameTenderValid.dateTenderValid'
                label='Date'
                value={formFields?.procedure?.timeFrameTenderValid?.dateTenderValid}
                onChange={handleFormChange}
                minDateFlag={false}
                required={true}
            />}
            {formFields?.notMapped?.procedure?.minTime === 'DURATION_IN_MONTHS'
            && <LabelledInput
                id='procedure.timeFrameTenderValid.durationTenderValid'
                label='Months'
                type='number'
                value={formFields?.procedure?.timeFrameTenderValid?.durationTenderValid}
                required={true}
                onChange={handleFormChange}
            />}
            {formFields?.procedure?.pt && formFields?.procedure?.pt === 'OPEN'
            && <>
                <div className='formElementsDivider'></div>
                <h3 className='title-large subHeading'>IV.2.7. Conditions for opening of tenders</h3>
                <DatePickerComponent
                    id='procedure.openingCondition.dateOpeningTenders'
                    label='Conditions for opening of tenders'
                    value={formFields?.procedure?.openingCondition?.dateOpeningTenders}
                    onChange={handleFormChange}
                    minDateFlag={false}
                    required={formFields?.procedure?.pt === 'OPEN'}
                />
                <div className='formElementsDivider'></div>
                <h3 className='title-large subHeading'>Local Time</h3>
                <TimePicker
                    clockIcon={null}
                    className='customDateInput timeReceipt'
                    id='procedure.openingCondition.timeOpeningTenders'
                    clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                    hourPlaceholder='HH'
                    minutePlaceholder='mm'
                    format={'HH:mm'}
                    locale='en-gb'
                    minTime={'00:00'}
                    disableClock={true}
                    required={formFields?.procedure?.pt === 'OPEN'}
                    value={formFields?.procedure?.openingCondition?.timeOpeningTenders}
                    onChange={(time) => setFormFields({
                        ...formFields,
                        procedure: {
                            ...formFields.procedure,
                            openingCondition: {
                                ...formFields.procedure.openingCondition,
                                timeOpeningTenders: time,
                            },
                        },
                    })}
                />
                <input
                    type='hidden'
                    name='procedure.openingCondition.timeOpeningTenders'
                    id='procedure.openingCondition.timeOpeningTenders'
                    value={formFields?.procedure?.openingCondition?.timeOpeningTenders || ''}
                />
                <div className='formElementsDivider'></div>
                <LabelledTextArea
                    id='procedure.openingCondition.place'
                    label='Place'
                    type='text'
                    value={formFields?.procedure?.openingCondition?.place}
                    required={false}
                    maxCharCount={400}
                    onChange={handleFormChange}
                />
                <div className='formElementsDivider'></div>
                <LabelledTextArea
                    id='procedure.openingCondition.infoAdd'
                    label='Information about authorised persons and opening procedure'
                    type='text'
                    value={formFields?.procedure?.openingCondition?.infoAdd}
                    required={false}
                    maxCharCount={1000}
                    onChange={handleFormChange}
                />
            </>}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>Section VI. Complementary information</h3>
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>VI.1. Information about recurrence</h3>
            <RadioButtonComponent
                id={'complementaryInfo.recurrentProcurement'}
                label='This is a recurrent procurement'
                value={formFields?.complementaryInfo?.recurrentProcurement}
                required={true}
                onChange={handleFormChange}
                renderAsRow={true}
                options={{
                    true: 'Yes',
                    false: 'No',
                }}
            />
            {formFields?.complementaryInfo?.recurrentProcurement === 'true'
            && <LabelledTextArea
                id='complementaryInfo.estimatedTiming'
                label='Estimated timing for further notices to be published'
                type='text'
                value={formFields?.complementaryInfo?.estimatedTiming}
                required={false}
                maxCharCount={400}
                onChange={handleFormChange}
            />}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>VI.2. Information about electronic workflows</h3>
            <div className='formElementsDivider'></div>
            <LabelledCheckBox
                id={'complementaryInfo.eordering'}
                breakColumn={true}
                options={[{
                    id: 'complementaryInfo.eordering',
                    value: true,
                    label: 'Electronic ordering will be used',
                    checked: formFields?.complementaryInfo?.eordering === 'true',
                    name: 'complementaryInfo.eordering',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            <LabelledCheckBox
                id={'complementaryInfo.einvoicing'}
                breakColumn={true}
                options={[{
                    id: 'complementaryInfo.einvoicing',
                    value: true,
                    label: 'Electronic invoicing will be accepted',
                    checked: formFields?.complementaryInfo?.einvoicing === 'true',
                    name: 'complementaryInfo.einvoicing',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            <LabelledCheckBox
                id={'complementaryInfo.epayment'}
                breakColumn={true}
                options={[{
                    id: 'complementaryInfo.epayment',
                    value: true,
                    label: 'Electronic payment will be used',
                    checked: formFields?.complementaryInfo?.epayment === 'true',
                    name: 'complementaryInfo.epayment',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>VI.3. Additional information</h3>
            <LabelledTextArea
                id='complementaryInfo.infoAdd'
                breakColumn={true}
                label='Additional information'
                value={formFields?.complementaryInfo?.infoAdd || ''}
                maxCharCount={10000}
                onChange={(e) => handleFormChange(e)}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>VI.4. Procedures for review</h3>
            <h3 className='title-large subHeading'>VI.4.1. Review body</h3>
            <FTSNameAndAddresses
                prefix={'complementaryInfo.addressReviewBody'}
                handleChange={handleFormChange}
                formFields={formFields}
                registerFlag={false}
                contactPointFlag={false}
                buyerAddressFlag={false}
                nationalIDFlag={false}
                nutsCodeFlag={false}
                internetAddressNameUpdateFlag={true}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>VI.4.2. Body responsible for mediation procedures</h3>
            <LabelledCheckBox
                id={'notMapped.complementaryInfo.mediationProcedure'}
                breakColumn={true}
                options={[{
                    id: 'notMapped.complementaryInfo.mediationProcedure',
                    value: true,
                    label: 'There is a body responsible for mediation procedures',
                    checked: formFields?.notMapped?.complementaryInfo?.mediationProcedure === 'true',
                    name: 'notMapped.complementaryInfo.mediationProcedure',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.notMapped?.complementaryInfo?.mediationProcedure === 'true'
            && <FTSNameAndAddresses
                prefix={'complementaryInfo.addressMediationBody'}
                handleChange={handleFormChange}
                formFields={formFields}
                registerFlag={false}
                contactPointFlag={false}
                buyerAddressFlag={false}
                nationalIDFlag={false}
                nutsCodeFlag={false}
                internetAddressNameUpdateFlag={true}
            />}
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>VI.4.3. Review procedure</h3>
            <LabelledTextArea
                id='complementaryInfo.reviewProcedure'
                breakColumn={true}
                label='Precise information on deadline(s) for review procedures'
                value={formFields?.complementaryInfo?.reviewProcedure || ''}
                maxCharCount={4000}
                onChange={(e) => handleFormChange(e)}
                required={false}
            />
            <div className='formElementsDivider'></div>
            <h3 className='title-large subHeading'>VI.4.4. Service from which information about the review procedure may be obtained</h3>
            <LabelledCheckBox
                id={'notMapped.complementaryInfo.reviewProcedureCheckbox'}
                breakColumn={true}
                options={[{
                    id: 'notMapped.complementaryInfo.reviewProcedureCheckbox',
                    value: true,
                    label: 'There is a service from which information about the review procedure may be obtained',
                    checked: formFields?.notMapped?.complementaryInfo?.reviewProcedureCheckbox === 'true',
                    name: 'notMapped.complementaryInfo.reviewProcedureCheckbox',
                }]}
                onChange={(e) => handleFormChange(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.notMapped?.complementaryInfo?.reviewProcedureCheckbox === 'true'
            && <FTSNameAndAddresses
                prefix={'complementaryInfo.addressReviewInfo'}
                handleChange={handleFormChange}
                formFields={formFields}
                registerFlag={false}
                contactPointFlag={false}
                buyerAddressFlag={false}
                nationalIDFlag={false}
                nutsCodeFlag={false}
                internetAddressNameUpdateFlag={true}
            />}
            <div className='vpnItemButtonGroup'>
                <Button
                    id='cancel'
                    label='Cancel'
                    variant='secondary'
                    handleClick={() => history.goBack() }
                    disabled={false}
                />
                <div className='buttonDivider'></div>
                <Button
                    id='submit'
                    type={'submit'}
                    value='Create'
                    label='Create'
                    breakColumn={false}
                />
            </div>
        </form>
    </>;
};

FTSF02NoticeForm.propTypes = {
    project: PropTypes.object.isRequired,
    noticeEvent: PropTypes.object.isRequired,
};

export default FTSF02NoticeForm;
