export const CALLOFF_CARD_TITLE = 'Project';
export const CALLOFF_CARD_ACTION = 'View Call Off';
export const ERROR_PROCESSING_CARD = 'Error processing request';
export const NO_CONTRACTS_AVAILABLE = 'No contracts available.';
export const NO_CALLOFFS_AVAILABLE = 'No call-offs available.';
export const NO_PROCUREMENTS_REQUESTS_AVAILABLE = 'No procurement request available.';
export const NO_ASIGNED_TASK_AVAILABLE = 'You don\'t have any assigned tasks and events in-progress or pending.';
export const NO_PROJECTS_AVAILABLE = 'No projects available.';
export const ESTIMATED_VALUE = 'Estimated Value';
export const CURRENT_STAGE = 'Current Stage';
export const STAGE_PROGRESS = 'Stage Progress';
export const VIEW_CONTRACT = 'View Contract';
export const VIEW_PROJECT = 'View Project';
export const VIEW_CALLOFF = 'View Call Off';
export const STATUS = 'Status';
export const CONTRACT_VALUE = 'Contract Value';
export const RAISED_BY = 'Raised By';
export const SUBMITTED_ON = 'Submitted On';
export const VALUE = 'Value';
export const RIGOUR_LEVEL = 'Rigour Level';
export const NO_TASKS_EVENTS_AVAILABLE = 'No tasks and events available.';
export const NO_PROCUREMENT_REQUESTS_AVAILABLE = 'No procurement requests available.';
export const VIEW_REQUEST = 'View Request';
export const VIEW = 'View';
