import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import SelectSearch from 'react-select-search';
import FilterPanel from '../../components/FilterPanel';
import LabelledSelect from '../../components/LabelledSelect';
import Button from '../../components/Button';
import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import userManagementAPIs from '../../services/user-management.service';
import arrowRightIcon from '../../styles/icons/blue/arrow-right.svg';
import clearIcon from '../../styles/icons/blue/clear.svg';
import constants from './constants';
import helper from './helper';
import actions from '../CodesModal/slice/codes.action';
import Tag from '../../components/Tag';
import actionsRegion from '../RegionsOfSupply/slice/supplyRegions.action';
import AuthContext from '../../context/AuthContext';
import actionsLocality from '../LocalityModal/slice/supplyLocalities.action';
import { ThemeContext } from '../../context/ThemeContext';
import helperFunctions from '../../utils/helperFunctions';

const SelectCodesModal = React.lazy(() => import('../CodesModal/SelectCodesModal'));
const SupplyRegionsModal = React.lazy(() => import('../RegionsOfSupply/SupplyRegionsModal'));
const SupplyLocalitiesModal = React.lazy(() => import('../LocalityModal/SupplyLocalityModal'));


const BrowseFilterPanel = ({
    open,
    closePanel,
    handleFilterCallback,
    context,
    searchSortFilterData,
    handleUpdateSearchSortFilterData,
}) => {
    const [filterData, setFilterData] = useState({});
    const [dynamicFilterOptions, setDynamicFilterOptions] = useState({});
    const [showCodesModal, setShowCodesModal] = useState(false);
    const [initialCodesData, setInitialCodesData] = useState([]);
    const [showRegionsModal, setShowRegionsModal] = useState(false);
    const [initialRegionsData, setInitialRegionsData] = useState([]);
    const [showLocalityModal, setShowLocalityModal] = useState(false);
    const [initialLocalityData, setInitialLocalityData] = useState([]);

    const dispatch = useDispatch();
    const authContext = useContext(AuthContext);
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;

    const {
        initCodesData, onlySelectedCodes, initCodesRegions, onlySelectedRegions,
        initLocality, onlySelectedLocalities,
    } = useSelector((state) => ({
        initCodesData: state.CodesReducer.selectedCodes,
        onlySelectedCodes: state.CodesReducer.onlySelectedCodes,
        initCodesRegions: state.SupplyRegionsReducer.selectedRegions,
        onlySelectedRegions: state.SupplyRegionsReducer.onlySelectedRegions,
        initLocality: state.SupplyLocalitiesReducer.selectedLocalities,
        onlySelectedLocalities: state.SupplyLocalitiesReducer.onlySelectedLocalities,
    }));

    const [codeData, setCodeData] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [localityData, setLocalityData] = useState([]);

    const generateDropdownOptions = (key, option) => ((key > 0)
        ? <option key={`bwFilterOption-${key}`} value={option.value}>{option.label}</option>
        : <option key={`bwFilterOption-${key}`} value=''>{'All'}</option>);

    const clearAll = () => {
        if (onlySelectedCodes?.length > 0) {
            dispatch(actions.setOnlySelectedCodes([]));
        }

        if (onlySelectedRegions?.length > 0) {
            dispatch(actionsRegion.setOnlySelectedRegions([]));
        }

        if (onlySelectedLocalities?.length) {
            dispatch(actionsLocality.setOnlySelectedLocalities([]));
        }

        if (onlySelectedLocalities?.length) {
            dispatch(actionsLocality.setOnlySelectedLocalities([]));
        }

        if (initLocality.length) {
            dispatch(actionsLocality.setLocalities([]));
        }

        setFilterData({});

        handleFilterCallback({
            filter: {},
        }, false);
    };

    const removeCodesSelection = (parent, level) => {
        parent.map((el) => {
            const elem = el;
            if (el.classificationID === level) {
                elem.selected = false;
            }
            if (el.children) removeCodesSelection(el.children, level);
            return null;
        });
        return initCodesData;
    };

    const updateCodesSelectionView = (pillName) => {
        dispatch(actions.setOnlySelectedCodes(
            onlySelectedCodes.filter((el) => el.classificationID !== pillName),
        ));
    };

    const handlePillDelete = (pillName) => {
        removeCodesSelection(initCodesData, pillName);
        updateCodesSelectionView(pillName);
    };

    const removePill = (pillName) => {
        handlePillDelete(pillName);
        const tempCodeData = initialCodesData;
        const updatedData = tempCodeData.filter((code) => code.classificationID !== pillName);
        setInitialCodesData(updatedData);

        if (onlySelectedCodes.length > 0) {
            setInitialCodesData([]);
        }
    };

    const toggleModal = () => {
        setShowCodesModal(!showCodesModal);
    };

    const generateCodeTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.classificationID}
                id={item.classificationID}
                tagTxt={(item.category && item.level) ? `${item.category} - ${item.level}` : `${item.classificationID}`}
                isDeletable={isDeletable}
                size=''
                handleDeleteBtnClick={() => removePill(item.classificationID)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const toggleRegionModal = () => {
        setShowRegionsModal(!showRegionsModal);
    };

    const toggleLocalityModal = () => {
        setShowLocalityModal(!showLocalityModal);
    };

    const removeRegionSelection = (parent, code) => {
        parent.map((el) => {
            const elem = el;
            if (el.code === code) {
                elem.selected = false;
            }
            if (el.cities) { removeRegionSelection(el.cities, code); }
            if (el.counties) { removeRegionSelection(el.counties, code); }
            return null;
        });
        return initCodesRegions;
    };

    const updateRegionSelectionView = (pillName) => {
        dispatch(actionsRegion.setOnlySelectedRegions(onlySelectedRegions
            .filter((el) => el.code !== pillName)));
    };

    const removeRegionPill = (pillName) => {
        removeRegionSelection(initCodesRegions, pillName);
        updateRegionSelectionView(pillName);
        const tempCodeData = initialRegionsData;
        const updatedData = tempCodeData.filter((region) => region.code !== pillName);
        setInitialRegionsData(updatedData);
        if (onlySelectedRegions.length > 0) {
            setInitialRegionsData([]);
        }
    };

    const generateSupplyRegionsTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.code}
                id={item.code}
                tagTxt={`${item.code } - ${ item.description}`}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => removeRegionPill(item.code)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const removeLocalitySelection = (code) => {
        const localities = initLocality.map((el) => ({
            ...el,
            ...el.code === code && { selected: false },
        }));

        dispatch(actionsLocality.setLocalities(localities));
    };

    const updateLocalitySelectionView = (pillName) => {
        dispatch(actionsLocality.setOnlySelectedLocalities(onlySelectedLocalities
            .filter((el) => el.code !== pillName)));
    };

    const removeLocalityPill = (pillName) => {
        removeLocalitySelection(pillName);
        updateLocalitySelectionView(pillName);
        const tempCodeData = initialLocalityData;
        const updatedData = tempCodeData.filter((local) => local.code !== pillName);
        setInitialLocalityData(updatedData);
        if (onlySelectedLocalities.length > 0) {
            setInitialLocalityData([]);
        }
    };

    const generateLocalityTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.code}
                id={item.code}
                tagTxt={`${ item.description}`}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => removeLocalityPill(item.code)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    useEffect(() => {
        if (onlySelectedCodes && onlySelectedCodes.length > 0) {
            setCodeData(onlySelectedCodes);
            setFilterData((prev) => ({
                ...prev,
                industryCodes: onlySelectedCodes.map((dt) => dt.classificationID),
            }));
        } else if (initialCodesData) {
            setCodeData(initialCodesData);
            setFilterData((prev) => ({
                ...prev,
                industryCodes: initialCodesData.map((dt) => dt.classificationID),
                industryInfo: initialCodesData,
            }));
        }
    }, [onlySelectedCodes, initialCodesData]);

    useEffect(() => {
        if (onlySelectedRegions && onlySelectedRegions.length > 0) {
            const selectedArray = onlySelectedRegions.map((region) => region.code);
            setFilterData((prev) => ({
                ...prev,
                deliveryArea: selectedArray,
            }));
            setRegionData(onlySelectedRegions);
        } else if (initialRegionsData) {
            setRegionData(initialRegionsData);
            setFilterData((prev) => ({
                ...prev,
                deliveryArea: initialRegionsData.map((region) => region.code),
                deliveryAreaInfo: initialRegionsData,
            }));
        }
    }, [onlySelectedRegions, initialRegionsData]);

    useEffect(() => {
        if (onlySelectedLocalities && onlySelectedLocalities.length > 0) {
            const selectedArray = onlySelectedLocalities.map((locality) => locality.description);
            setFilterData((prev) => ({
                ...prev,
                localities: selectedArray,
            }));
            setLocalityData(onlySelectedLocalities);
        } else if (initialLocalityData) {
            setLocalityData(initialLocalityData);
            setFilterData((prev) => ({
                ...prev,
                localities: initialLocalityData.map((locality) => locality.description),
                localityInfo: initialLocalityData,
            }));
        }
    }, [onlySelectedLocalities, initialLocalityData]);

    const fetchAndSetDeptFilterOptions = async (field) => {
        const departments = await userManagementAPIs.listAllDepartments();
        const authorities = await userManagementAPIs.getAllAccountsByType('buyer');
        const depts = departments.data.departments.map((el) => ({
            label: el.departmentName,
            value: el.departmentID,
        }));
        // added to fix and retain 0th element replaced by All.
        const deptsWithAll = [{
            value: '',
            label: '',
        }, ...depts];
        const auths = authorities.data.map((el) => ({
            label: el.companyName,
            value: el.accountID,
        }));
        // added to fix and retain 0th element replaced by All.
        const authsWithAll = [{
            value: '',
            label: '',
        }, ...auths];
        setDynamicFilterOptions({
            ...dynamicFilterOptions,
            [field]: deptsWithAll,
            authority: authsWithAll,
        });
        return null;
    };

    // Separate function for APIs that can accessed by unauthenticated users.
    const fetchUnauthenticatedFilterOptions = async () => {
        const authorities = await userManagementAPIs.getAllAccountsByType('buyer');
        const auths = authorities.data.map((el) => ({
            label: el.companyName,
            value: el.accountID,
        }));
        // added to fix and retain 0th element replaced by All.
        const authsWithAll = [{
            value: '',
            label: '',
        }, ...auths];
        setDynamicFilterOptions({
            ...dynamicFilterOptions,
            authority: authsWithAll,
        });
        return null;
    };

    /**
     * This useEffect fetches data for filter dropdowns that are dynamic.
     * We have two such filters
     * Departments
     * Authority/Buyer
     */

    useEffect(() => {
        if (authContext?.authenticated) {
            fetchAndSetDeptFilterOptions('departments');
        } else {
            fetchUnauthenticatedFilterOptions();
        }
        if (initialCodesData.length === 0) {
            setInitialCodesData(searchSortFilterData?.filter?.industryInfo || []);
        }
        if (initialLocalityData.length === 0) {
            setInitialLocalityData(searchSortFilterData?.filter?.localityInfo || []);
        }
        const filterObject = {
            ...searchSortFilterData.filter,
        };

        setFilterData({
            ...filterData,
            ...filterObject,
        });
    }, []);

    useEffect(() => {
        if (handleUpdateSearchSortFilterData) {
            handleUpdateSearchSortFilterData({
                filter: {
                    ...filterData,
                },
            });
        }
    }, [filterData]);

    const showResults = () => {
        handleFilterCallback({
            filter: filterData,
        }, true);
        closePanel();
    };

    const generateFieldBodyByType = (attrs, field, index) => {
        switch (attrs.filterType.toLowerCase()) {
            case constants.filterTypes.SELECT:
                return <>
                    <LabelledSelect
                        id={`bwFilterWorkflowType_${index}`}
                        key={`bwFilterWorkflowType_${index}`}
                        options={attrs.dynamicOptions ? dynamicFilterOptions[field]
                            ?.map((option, idx) => generateDropdownOptions(idx, option))
                            : helper.getSelectOptionsByField(field)
                                .map((option, idx) => generateDropdownOptions(idx, option))}
                        label={attrs.label}
                        breakColumn={false}
                        value={filterData[attrs.stateKey] || 'default'}
                        onChange={(event) => setFilterData({
                            ...filterData,
                            [attrs.stateKey]: event.target.value,
                        })}
                    />
                    <div className='filterOptionsDivider'></div>
                </>;
            case constants.filterTypes.SELECT_SEARCH:
                return <>
                    <div id={`select-search-${index}`} key={`bsSectionContent_${index}`} >
                        <label className='form-label title selectSearchTitle'>{attrs.label}</label>
                        <SelectSearch
                            id='route-select'
                            options={helperFunctions.constructGroupedDropdownData(
                                helper.getSelectOptionsByField(field),
                                attrs.key,
                                undefined,
                                attrs.sortedByAlphabeticalName,
                            )}
                            value={filterData[attrs.stateKey] || ''}
                            search={false}
                            printOptions='on-focus'
                            closeOnSelect={true}
                            multiple={false}
                            onChange={(event) => {
                                setFilterData({
                                    ...filterData,
                                    [attrs.stateKey]: event,
                                });
                            }
                            }
                        />
                    </div>
                    <div className='filterOptionsDivider'></div>
                </>;
            case constants.filterTypes.RANGE:
                return <>
                    <div className='datePickerWrapper' id={`createdDateRangeWrapper_${index}`} key={`createdDateRangeWrapper_${index}`}>
                        <label className='datepickerLabel title dateRangeBetween'>{attrs.label}</label>
                        <DateRangePicker className='customDateInput'
                            dayPlaceholder='DD'
                            monthPlaceholder='MM'
                            yearPlaceholder='YYYY'
                            calendarIcon={null}
                            clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                            rangeDivider={<img src={arrowRightIcon} alt='right-arrow' ></img>}
                            showLeadingZeros={true}
                            value={filterData[attrs.stateKey] || [null, null]}
                            onChange={(event) => setFilterData({
                                ...filterData,
                                [attrs.stateKey]: event,
                            })}
                        />
                    </div>
                    <div className='filterOptionsDivider'></div>
                </>;
            case constants.filterTypes.CODESANDCATEGORIES:
                return <>
                    <div id='bsSectionContent' key={`bsSectionContentCd_${index}`}>
                        <div id='lotSectionContent_Filter'>
                            <div className='textIconContainer'><p className='title-large sectionHeader'>{attrs.label}</p>
                                <Button id='addButton' size='small' variant='secondary skinless'
                                    label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        setShowCodesModal(true);
                                    }}
                                />
                            </div>
                            {generateCodeTag(codeData, true)}
                        </div>
                    </div>
                    <div className='filterOptionsDivider'></div>
                </>;
            case constants.filterTypes.DELIVERYAREA:
                return <>
                    <div id='bsSectionContent' key={`bsSectionContentda_${index}`}>
                        <div id='lotSectionContent_Filter'>
                            <div className='textIconContainer'><p className='title-large sectionHeader'>{attrs.label}</p>
                                {<Button id='addRegionButton' size='small' variant='secondary skinless'
                                    label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        setShowRegionsModal(true);
                                    }}
                                />}
                            </div>
                            {generateSupplyRegionsTag(regionData, true)}
                        </div>
                    </div>
                    <div className='filterOptionsDivider'></div>
                </>;
            case constants.filterTypes.LOCALITY:
                return <>
                    <div id='bsSectionContent' key={`bsSectionContentda_${index}`}>
                        <div id='lotSectionContent_Filter'>
                            <div className='textIconContainer'><p className='title-large sectionHeader'>{attrs.label}</p>
                                {<Button id='addLocalityButton' size='small' variant='secondary skinless'
                                    label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        setShowLocalityModal(true);
                                    }}
                                />}
                            </div>
                            {generateLocalityTag(localityData, true)}
                        </div>
                    </div>
                    <div className='filterOptionsDivider'></div>
                </>;
            case constants.filterTypes.INPUT:
                return <>
                    <div className='labelled-input' key={`bsSectionContentinput_${index}`}>
                        <label className='form-label title'>{attrs.label}</label>
                        <input
                            className ='form-input body-small'
                            type='text'
                            id='inputValue'
                            placeholder={`Enter ${attrs.label}`}
                            value={filterData[attrs.stateKey] || ''}
                            onChange={(event) => setFilterData({
                                ...filterData,
                                [attrs.stateKey]: event.target.value,
                            })} />
                    </div>
                    <div className='filterOptionsDivider'></div>
                </>;
            case constants.filterTypes.INPUT_RANGE:
                return <>
                    <div className='inputRangeFilter' key={`bsSectionContentinputrange_${index}`}>
                        <label className='form-label title'>{attrs.label}</label>
                        <div className='labelled-input'>
                            <input
                                className ='form-input body-small from'
                                type='number'
                                id='from'
                                placeholder='Enter from'
                                value={filterData[attrs.stateKeyFrom] || ''}
                                onChange={(event) => setFilterData({
                                    ...filterData,
                                    [attrs.stateKeyFrom]: event.target.value,
                                })}
                            />
                            <input
                                className ='form-input body-small to'
                                type='number'
                                id='to'
                                placeholder='Enter to'
                                value={filterData[attrs.stateKeyTo] || ''}
                                onChange={(event) => setFilterData({
                                    ...filterData,
                                    [attrs.stateKeyTo]: event.target.value,
                                })}
                            />
                        </div>
                        <div className='filterOptionsDivider'></div>
                    </div>
                </>;
            default:
                return {};
        }
    };

    const generateFields = () => {
        const data = [];
        constants.fieldsByContext[context.toLowerCase()]?.map((field, idx) => {
            const fieldProps = helper.getFilterPropertiesByFieldName(field);
            if (fieldProps.filterType) {
                data.push(generateFieldBodyByType(fieldProps, field, idx));
            }
            return null;
        });
        return data;
    };

    return <>
        <FilterPanel
            id='bwFilterPanel'
            open={open}
            closePanel={closePanel}
            footerBtnsDisabled={Object.keys(filterData).length === 0}
            handleClearAll={clearAll}
            handleShowResults={showResults}
        >
            {generateFields()}
        </FilterPanel>
        {showCodesModal
        && <SelectCodesModal
            closeModal={toggleModal}
            initialData={initialCodesData}
        />}
        {showRegionsModal
        && <SupplyRegionsModal
            closeModal={toggleRegionModal}
            initialData={initialRegionsData}
        />}
        {showLocalityModal
        && <SupplyLocalitiesModal
            closeModal={toggleLocalityModal}
            initialData={initialLocalityData}
        />}
    </>;
};

BrowseFilterPanel.propTypes = {
    open: PropTypes.bool.isRequired,
    closePanel: PropTypes.func.isRequired,
    handleFilterCallback: PropTypes.func.isRequired,
    context: PropTypes.string.isRequired,
    searchSortFilterData: PropTypes.object,
    handleUpdateSearchSortFilterData: PropTypes.object,
};

export default BrowseFilterPanel;
