const Constants = {
    COMMENTS_CHAR_LIMIT: 3000,
    questionnaireTypes: [
        { label: 'Selection Questionnaire', value: 'selection' },
        { label: 'Selection Questionnaire', value: 'defaultSelectionQuestionnaire' },
        { label: 'Tender Questionnaire', value: 'tender' },
        { label: 'Award Questionnaire', value: 'award' },
        { label: 'Market Consultation Questionnaire', value: 'marketConsultation' },
        { label: 'Additional Questionnaire', value: 'additional' },
    ],
    header: 'header',
    selectToRoutePlaceholder: 'Select route to market',
    selectRouteToMarketLabel: 'Select Route to Market',
    optional: ' (Optional)',
    routeToMarket: [{
        name: 'Not Selected',
        id: '',
        header: '',
    }, {
        name: 'Competitive Flexible Procedure',
        id: 'CFP',
        header: 'PA23',
    }, {
        name: 'Open Procedure',
        id: 'openProcedure',
        header: 'PA23',
    }, {
        name: 'Direct Award Process A',
        id: 'directAwardA',
        header: 'PSR',
    }, {
        name: 'Direct Award Process B',
        id: 'directAwardB',
        header: 'PSR',
    }, {
        name: 'Direct Award Process C',
        id: 'directAwardC',
        header: 'PSR',
    }, {
        name: 'Most Suitable Provider Process',
        id: 'mostSuitableProviderProcess',
        header: 'PSR',
    }, {
        name: 'Competitive Process',
        id: 'competitiveProcess',
        header: 'PSR',
    }, {
        name: 'Restricted Procedure',
        id: 'restrictedProcedure',
        header: 'PCR15',
    }, {
        name: 'Competitive with Dialogue',
        id: 'CPD',
        header: 'PCR15',
    }, {
        name: 'Competitive with Negotiated',
        id: 'CPN',
        header: 'PCR15',
    }, {
        name: 'Request for Quotation',
        id: 'requestQuotation',
        header: '',
    }, {
        name: 'Request for Quotation - Clever Quotes',
        id: 'requestQuotationCleverQuotes',
        header: '',
    }, {
        name: 'Under Threshold',
        id: 'underThreshold',
        header: '',
    }, {
        name: 'Direct Award',
        id: 'directAward',
        header: '',
    }, {
        name: 'Established Solution',
        id: 'establishedSolution',
        header: '',
    }],
    DOCUMENT_CONTEXT: {
        WORKFLOW: 'workflow',
        QUESTIONNAIRE: 'questionnaire',
    },
    DOCUMENT_TARGET: {
        UPDATE: 'update',
    },
    DOCUMENT_CREATE_NEW: 'Create and save edits in a new',
    DOCUMENT_OVERWRITE: 'Overwrite this',
    // Sync with event Types.
    workflowEventsDisplayFields: {
        calendar: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            startTime: {
                label: 'Start time',
                noDataMessage: 'No start time entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            eventLocation: {
                label: 'Location',
                noDataMessage: 'No location entered',
            },
            steeringGroupAssigned: {
                label: 'Steering group attendee(s)',
                noDataMessage: 'No attendee(s) selected',
            },
        },
        document: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        publish: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        notice: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        evaluation: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        issueAwardUnsuccessfulNotices: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        supplierDocumentation: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        marketUnderstanding: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        demandManagement: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        costManagement: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        performanceMonitoringSupplierRelations: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        riskManagement: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        updateContractManagementPlan: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        exitStrategy: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        extension: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        variation: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
        renewContract: {
            startDate: {
                label: 'Start date',
                noDataMessage: 'No start date entered',
            },
            eventDuration: {
                label: 'Duration (in days)',
            },
            endDate: {
                label: 'End date',
                noDataMessage: 'No end date entered',
            },
            rolesResponsible: {
                label: 'Role responsible',
                noDataMessage: 'No role selected',
            },
        },
    },
    QUESTIONNAIRE: 'questionnaire',
    QUESTIONNAIRE_SECTION_TITLE: 'Section {0}: ',
    SUPPLIER_FILES_TITLE: 'Supplier attachments: ',
    BUYER_FILES_TITLE: 'Buyer attachments: ',
    VIEW_ATTACHMENTS: 'View attachments',
    VIEW_ATTACHMENTS_DESC: ' uploaded by the buyer that would be useful for answering this question.',
    RESPONSE: 'response',
    RESPONSE_LABEL: 'Response',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    TYPE: 'Type',
    QUESTIONNAIRE_SHARED: 'Questionnaire shared',
    QUESTIONNAIRE_SHARED_WITH: 'Questionnaire shared with',
    QUESTIONNAIRE_SHARED_BY: 'Questionnaire shared by',
    ARE_SECTIONS_WEIGHTED: 'Are sections weighted?',
    MIN_SCORING_VALUE: 'Min scoring value',
    MAX_SCORING_VALUE: 'Max scoring value',
    SHOW_SECTION_WEIGHTINGS: 'Show section weightings',
    ADDITIONAL_DETAILS: 'Additional Details',
    SECTIONS: 'Sections',
    CHANGES_DESCRIPTION: 'Changes Description',
    CURRENT_VERSION: 'Current version',
    SECTION_WEIGHTING: 'Section weighting',
    SCORING_MECHANISM: 'Scoring mechanism',
    QUESTION_WEIGHTING: 'Question weighting',
    CHARACTER_LIMIT: 'Character limit',
    RESPONSE_TYPE: 'Response type',
    RESP_TYPE: {
        YES_NO: 'Yes/No',
        TRUE_FALSE: 'True/False',
        CHECKBOX: 'checkbox',
        RADIOBUTTON: 'radioButton',
        FREETEXT: 'freeText',
        FILE: 'file',
        NUMBER: 'number',
    },
    RESPONSE_OPTIONS: 'Response options',
    UPLOADS: 'Uploads',
    UPLOADS_NUMBER: 'Number of Files Uploaded',
    QUESTION_ANSWER: 'Question Answer',
    SUPPORTING_DOCUMENTS_WITH_RESPONSE: 'Supporting documents with response',
    OPTIONAL: 'Optional',
    OPTIONAL_QUESTION: 'Optional question',
    PUBLIC: 'Public',
    NOT_SHARED_EXTERNALLY: 'Not shared externally',
    YES: 'Yes',
    NO: 'No',
    COMPARE_WITH_PREVIOUS_VERSION: 'Compare previous version(s)',
    sections: 'sections',
    limit: 'limit',
    sectionItems: 'sectionItems',
    equal: 'equal',
    error: 'Error',
    weightingTotalMisMatch: 'weighting total does not match expected weighting. Please re-adjust weighting and try again.',
    weightingOver100Error: 'Questionnaire weighting total cannot be more than 100. Please re-adjust weighting and try again.',
    liveQuestionnaireModel: {
        subtitle: 'You\'re editing a live questionnaire, this will require suppliers to answer this questionnaire again. Are you sure you want to update this questionnaire?',
        textarea: 'textarea',
        reason: 'Reason',
        inputPlaceholder: 'Type your reason here',
        pleaseEnterReason: 'Please enter the reason',
        question: 'question',
        weightingSubtitle: 'Are you sure you want to confirm weighting for this questionnaire?',
    },
    attachedToType: {
        response: 'resp',
        project: 'proj',
    },
    PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE: 'questionnaire',
    PREVIOUS_VERSIONS_RESPONSE_TYPE: 'response',

    ENGLISH_LANGUAGE_TYPE: {
        EN: 'en-en',
        GB: 'en-GB',
    },

    UK_TIMEZONE: 'Europe/London',
    WARNING: 'Warning',
    ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS: 'Are you sure you want to create this',
    ARE_YOU_SURE_YOU_WANT_TO_UPDATE_THIS: 'Are you sure you want to update this',
    LIVE_QUESTIONNAIRE_EDIT_MESSAGE: 'You\'re editing a live questionnaire, this will require '
        + 'suppliers to answer this questionnaire again. Are you sure you want to update this questionnaire?',
    PLEASE_ENSURE_YOU_ENTERED_CORRECT_DETAILS: 'Please ensure you have entered the correct details for your scores, weightings and evaluation method.',

    EVALUATOR: 'Evaluator(s) (optional):',
    EVALUATOR_DROPDOWN_PLACEHOLDER: 'Search evaluators on Open',
    SHOW_HIDE_INDIVIDUAL_EVALUATORS_TABLE_BUTTON: 'Show / Hide Evaluators\' Scores',
    SHOW_HIDE_INDIVIDUAL_EVALUATORS_TABLE_DATA_HEADERS: ['Evaluator', 'Type', 'Score', 'Evaluation Comment'],
    USER_ACCOUNT_APPROVED: 'approved',
    GET_ACCOUNT_USERS_ERROR: 'Unable to retrieve information.',
    SELECT_AT_LEAST_ONE_MEMBER_AUTHORITY: 'Select at least one member authority',

    VIEW_BUYER_CHANGES: 'View Buyer Changes',
    INDIVIDUAL_EVALUATOR_NO_QUESTIONS_ASSIGNED: 'You are part of Steering Group but no questions to evaluate have been assigned to you',
    ADD_MODE: 'Add',
    ATTACHMENTS: 'Attachments',
    VIEW_ATTACHMENTS_TO_HELP_ANSWER_QUESTION: 'View and/or download supporting attachments to help you answer this question',
    THIRTY_SECONDS_INTERVAL: 30000,
    TOTAL_NUMBER_OF_AMENDED_QUESTIONS: 'Total number of amended questions to review:',
    YES_NO: 'Yes/No',
    TRUE_FALSE: 'True/False',
    CHECKBOX: 'Checkbox',
    RADIO_BUTTON: 'Radio button',
    FREE_TEXT: 'Free text',
    FILE: 'File',
    NUMBER: 'Number',
    NUMBER_PLACEHOLDER: 'Add a number',
};
export default Constants;
