import React from 'react';
import { EventConstants } from './constants';
import ButtonMenu from '../../../../../../components/ButtonMenu/index';

const getLinkElements = (options) => options.flatMap((child) => {
    if (child.props.children) {
        return getLinkElements(child.props.children);
    }
    return ({
        label: child.props.label,
        handleClick: child.props.handleClick,
        className: child.props.label.toLowerCase().replace(' ', '-'),
    });
});


const getAllButtons = (ebuttonsChildren) => {
    const buttons = React.Children
        .toArray(ebuttonsChildren)?.flatMap((child) => {
            if (child.props.children) {
                return getAllButtons(child.props.children);
            }
            return child;
        });
    return buttons;
};

const reorderEventButtons = (eButtons) => {
    const moreActionButtonLabels = [EventConstants.ESCALATE, EventConstants.ABORT];
    const allButtons = getAllButtons(eButtons.props.children);
    const { principalButtons, otherButtons, completeButton } = React.Children
        .toArray(allButtons).reduce(
            (acc, child) => {
                const { label } = child.props;
                if (moreActionButtonLabels.includes(label)) {
                    acc.otherButtons.push(child);
                } else if (label === EventConstants.COMPLETE_EVENT) {
                    acc.completeButton = child;
                } else {
                    const className = child.props.label.toLowerCase().replace(' ', '-');
                    acc.principalButtons.push(React.cloneElement(child, { className }));
                }
                return acc;
            },
            { principalButtons: [], otherButtons: [], completeButton: undefined },
        );
    const options = getLinkElements(otherButtons) || [];

    return (principalButtons.length > 0 || otherButtons.length > 0 || completeButton)
        ? <div className='event-section-button project-overview-options'>
            <div className='new-button-container'>
                <div className='button-group button-group-new-options'>{principalButtons}</div>
                {(options.length > 0 || completeButton)
        && <div className='button-group button-group-collapsed'>
            <ButtonMenu options={options} label={EventConstants.MORE_ACTIONS} classes='button secondary' />
            {completeButton && completeButton}
        </div>
                }
            </div></div>
        : <></>;
};

export default reorderEventButtons;
