const getDepartmentsName = (supplier) => {
    let departmentsID = [supplier.departmentID] || [];
    if (supplier.departments) {
        departmentsID = supplier.departments.map((dept) => dept.departmentID);
    }
    return departmentsID.map((deptID) => supplier.account?.departments?.filter(
        (dept) => dept.departmentID === deptID,
    )[0]?.departmentName).join(', ');
};

const getUsersName = (supplier) => {
    let usersName = [supplier.user?.contactDetails?.username] || [];
    if (supplier.departments) {
        usersName = supplier.departments.flatMap((dept) => dept.users
            .map((userItem) => userItem.user.contactDetails.username));
    }
    return usersName.join(', ');
};

export { getDepartmentsName, getUsersName };
