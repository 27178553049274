import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../components/Button';
import { view, viewQuestionnaireUrl } from '../../config/constants';
import { getButtonLabel, isButtonDisabled, getIntentStatusColor } from '../../services/current-submissions.service';
import Constants from './constants';
import { getResponseStatusColor, getResponseStatusLabel } from '../../services/response.service';
import './index.scss';
import helperFunctions from '../../utils/helperFunctions';
import { exportFunction } from '../../services/award.service';

const getMenuWithWeightage = (response) => {
    if (!response?.weightage) return {};
    return {
        id: uuidv4(),
        type: 'textLabel',
        labelTxt: `${Constants.totalScore} ${response.score}/${response.weightage}`,
    };
};
const getResponsesMenu = (initialData, response, questionnaireAwaitingRefresh) => [
    getMenuWithWeightage(response, initialData),
    {
        id: uuidv4(),
        type: 'button',
        size: 'regular',
        variant: getButtonLabel(response, initialData) === view ? 'view-link' : 'edit-link',
        additionalVariant: 'btnSize',
        disabled: isButtonDisabled(response, initialData, questionnaireAwaitingRefresh),
        label: getButtonLabel(response, initialData),
        onClick: `${viewQuestionnaireUrl}${response.responseID}`,
        onClickOptions: {
            deadlinePassed: true,
        },
    },
];

const getPreviousResponsesMenu = (status, responseId) => ([{
    id: uuidv4(),
    type: 'statusLabel',
    color: getResponseStatusColor(status),
    labelTxt: getResponseStatusLabel(status),
},
{
    id: uuidv4(),
    type: 'button',
    size: 'small',
    variant: 'view-link',
    additionalVariant: 'btnSize',
    label: view,
    onClick: `${viewQuestionnaireUrl}${responseId}`,
},
]);

const getPreviousLotsMenu = (data) => {
    const menuOptions = [];
    if (data.intentStatus) {
        menuOptions.push({

            id: uuidv4(),
            type: 'statusLabel',
            color: getIntentStatusColor(data.intentStatus),
            labelTxt: data.intentStatus,
        });
    }
    return menuOptions;
};

const getBanner = (data, handleNewSubmission, projectId,
    approvedDirectAward, handleDirectAwardSummaryButton) => (
    <div className='awardSummaryContentContainer'>
        <div className='awardSummaryContent'>
            <div id='buttonDiv'>
                {data.recordFound && data.isProjectDPS && (
                    <Button
                        id='primary-action-btn'
                        type='button'
                        size='x-large'
                        variant='secondary'
                        additionalVariant='additionalnewsubmissionbtn'
                        label={Constants.newSubmissionBtn}
                        handleClick={handleNewSubmission}
                    />
                )}
                {approvedDirectAward.eventID && (
                    <Button
                        id='primary-action-btn'
                        type='button'
                        size='x-large'
                        variant='primary'
                        additionalVariant='additionalnewsubmissionbtn'
                        label={Constants.DIRECT_AWARD_SUMMARY_LABEL}
                        handleClick={handleDirectAwardSummaryButton}
                    />)}
                <Button
                    id='primary-action-btn'
                    type='button'
                    size='regular'
                    variant='primary'
                    additionalVariant='additionalexportbtn'
                    label='Export All'
                    handleClick={() => exportFunction(projectId)}
                />
            </div>
        </div>
    </div>
);

const getCurrenSubmissionTitle = (date) => (
    <h2 className='submission-label'>
        {`${Constants.CURRENT_SUBMISSION_TITLE}`}
        <span>{`${Constants.SUBMITTED_AT}: ${helperFunctions.formatPrettyDateTime(date)}`}</span>
    </h2>
);

export {
    getBanner,
    getResponsesMenu,
    getPreviousResponsesMenu,
    getPreviousLotsMenu,
    getCurrenSubmissionTitle,
};
