import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { ThemeContext } from '../../../context/ThemeContext';

const SupplierCard = ({
    id,
    header = true,
    type,
    caption,
    headerTitle,
    alertMessage,
    content,
    actionBtnText,
    handlerActionBtn,
    rtm,
    url,
}) => {
    const { toggle } = useContext(ThemeContext);

    const getSectionContent = () => <>
        <section className='itemHeaderContainer'>
            <div className='header'>
                {type && <p className='card-type'>{type}</p>}
                {caption && <p className='caption-small'>{caption}</p>}
                {alertMessage && <p className='title-small projectTitle alertRed' title={alertMessage}>{alertMessage}</p>}
                {header && <p className='title-small projectTitle' title={headerTitle}>{headerTitle}</p>}
                {rtm && <p className='caption-small rtm'>{rtm}</p>}
            </div>
        </section>
        <div className='content'>
            {content}
        </div>
        {actionBtnText && !toggle && <div className='btnFooter'>
            <Button
                id='actionBtn'
                variant={toggle ? 'small secondary' : 'tertiary'}
                label={actionBtnText}
                handleClick={handlerActionBtn} />
        </div>}
    </>;

    return (<div className={`supplierCard ${toggle ? 'new-supplierCard' : ''}`} id={id}>
        {toggle && url
            ? <a href={url} className='supplier-card-container' title={caption}>{getSectionContent()}</a>
            : <section className='supplier-card-container' onClick={handlerActionBtn}>{getSectionContent()} </section>
        }
    </div >);
};

SupplierCard.propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.bool,
    headerTitle: PropTypes.string,
    alertMessage: PropTypes.string,
    type: PropTypes.string,
    caption: PropTypes.string,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    footer: PropTypes.bool,
    actionBtn: PropTypes.bool,
    actionBtnText: PropTypes.string,
    handlerActionBtn: PropTypes.func,
    rtm: PropTypes.string,
    url: PropTypes.string,
};

export default SupplierCard;
