import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SelectSearch from 'react-select-search/dist/cjs';
import DatePicker from 'react-date-picker';
import { format } from 'date-fns';
import { isBoolean } from 'lodash';
import Toast from '../../components/Alerts/Toast/Toast';

import LabelledInput, { LabelledInputTypeText, LabelledInputTypeCurrency } from '../../components/LabelledInput';
import LabelledTextarea from '../../components/LabelledTextArea';
import LabelledSelect from '../../components/LabelledSelect';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import Button from '../../components/Button';
import Form from '../../components/Form';
import Constants from './constants';
import {
    routeToMarket,
    callOffMechanismValues, workflowRestrictionConstants, projectTypes,
    userStatus, PROJECT_DESCRIBES_VALUES,
} from '../../config/constants';
import Tag from '../../components/Tag';
import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import Modal from '../../components/Modal';
import ScreenOverlay from '../../components/ScreenOverlay';
import AuthContext from '../../context/AuthContext';
import projectManagementAPIs from '../../services/project-management.service';
import clearIcon from '../../styles/icons/blue/clear.svg';
import SelectExtensionsAvailable from '../../components/SelectExtensionsAvailable';
import userManagementAPIs from '../../services/user-management.service';
import actions from '../CodesModal/slice/codes.action';
import actionsRegion from '../RegionsOfSupply/slice/supplyRegions.action';
import actionsLocality from '../LocalityModal/slice/supplyLocalities.action';
import helperFunctions from '../../utils/helperFunctions';
import Alert from '../../components/Alerts/Alert';
import projectService from '../../services/project.service';
import { ProjectDataContext } from '../../context/ProjectDataContext';
import { ThemeContext } from '../../context/ThemeContext';


const SelectCodesModal = React.lazy(() => import('../CodesModal/SelectCodesModal'));
const SupplyRegionsModal = React.lazy(() => import('../RegionsOfSupply/SupplyRegionsModal'));

const CreateProcurementForm = ({
    type,
    lotID,
    awardedSuppliers,
    callOffMechanism,
    competitionRules,
    solutionAccountId,
    loggedUserStatusInSolution,
}) => {
    const [values, setValues] = useState({});
    const [initialProjectData, setInitialProjectData] = useState({});
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [userList, setUserList] = useState([]);
    const [responsibleUser, setResponsibleUser] = useState();
    const [procurementOfficer, setProcurementOfficer] = useState();
    const [routeToMarketSelect, setRouteToMarketSelect] = useState();

    const { projectId, contractId } = useParams();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { setProjectData } = useContext(ProjectDataContext);

    const [showCodesModal, setShowCodesModal] = useState(false);
    const [showRegionsModal, setShowRegionsModal] = useState(false);
    const [initialCodesData, setInitialCodesData] = useState([]);
    const [initialRegionsData, setInitialRegionsData] = useState([]);

    const toggleModal = () => {
        setShowCodesModal(!showCodesModal);
    };
    const dispatch = useDispatch();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;

    const { value: contractValue } = PROJECT_DESCRIBES_VALUES.CONTRACT;
    const { value: frameworkValue } = PROJECT_DESCRIBES_VALUES.FRAMEWORK;
    const { value: openFrameworkValue } = PROJECT_DESCRIBES_VALUES.OPEN_FRAMEWORK;
    const { value: dpsValue } = PROJECT_DESCRIBES_VALUES.DPS;


    const contractOptions = [
        dpsValue,
        frameworkValue,
        openFrameworkValue,
    ];

    const {
        initCodesData, onlySelectedCodes, initCodesRegions, onlySelectedRegions,
    } = useSelector((state) => ({
        initCodesData: state.CodesReducer.selectedCodes,
        onlySelectedCodes: state.CodesReducer.onlySelectedCodes,
        initCodesRegions: state.SupplyRegionsReducer.selectedRegions,
        onlySelectedRegions: state.SupplyRegionsReducer.onlySelectedRegions,
    }));
    const [codeData, setCodeData] = useState([]);
    const [regionData, setRegionData] = useState([]);

    const removeCodesSelection = (parent, code) => {
        parent.map((el) => {
            const elem = el;
            if (el.classificationID === code) {
                elem.selected = false;
            }
            if (el.children) removeCodesSelection(el.children, code);
            return null;
        });
        return initCodesData;
    };

    const updateCodesSelectionView = (pillName) => {
        dispatch(actions.setOnlySelectedCodes(onlySelectedCodes
            .filter((el) => el.classificationID !== pillName)));
    };

    const handlePillDelete = (pillName) => {
        removeCodesSelection(initCodesData, pillName);
        updateCodesSelectionView(pillName);
    };

    const removePill = (pillName) => {
        handlePillDelete(pillName);
        const tempCodeData = initialCodesData;
        const updatedData = tempCodeData.filter((code) => code.classificationID !== pillName);
        setInitialCodesData(updatedData);
        setValues({
            ...values,
            industryInfo: updatedData,
        });
        if (onlySelectedCodes.length > 0) {
            setInitialCodesData([]);
        }
    };

    const toggleRegionModal = () => {
        setShowRegionsModal(!showRegionsModal);
    };

    const removeRegionSelection = (parent, code) => {
        parent.map((el) => {
            const elem = el;
            if (el.code === code) {
                elem.selected = false;
            }
            if (el.cities) { removeRegionSelection(el.cities, code); }
            if (el.counties) { removeRegionSelection(el.counties, code); }
            return null;
        });
        return initCodesRegions;
    };

    const updateRegionSelectionView = (pillName) => {
        dispatch(actionsRegion.setOnlySelectedRegions(onlySelectedRegions
            .filter((el) => el.code !== pillName)));
    };

    const removeRegionPill = (pillName) => {
        removeRegionSelection(initCodesRegions, pillName);
        updateRegionSelectionView(pillName);
        const tempCodeData = initialRegionsData;
        const updatedData = tempCodeData.filter((region) => region.code !== pillName);
        setValues({
            ...values,
            deliveryAreaInfo: updatedData,
        });
        setInitialRegionsData(updatedData);
        if (onlySelectedRegions.length > 0) {
            setInitialRegionsData([]);
        }
    };

    useEffect(() => {
        if (onlySelectedCodes && onlySelectedCodes.length > 0) {
            const selectedCodes = onlySelectedCodes.map((code) => {
                const container = {
                    classificationID: code.classificationID,
                    level: code.level,
                    category: code.category,
                };
                return container;
            });
            setValues({
                ...values,
                industryInfo: selectedCodes,
            });
            setCodeData(onlySelectedCodes);
        } else if (initialCodesData) {
            setCodeData(initialCodesData);
        }
    }, [onlySelectedCodes, initialCodesData]);

    useEffect(() => {
        if (onlySelectedRegions && onlySelectedRegions.length > 0) {
            const selectedRegions = onlySelectedRegions.map((region) => {
                const container = {
                    code: region.code,
                    description: region.description,
                };
                return container;
            });
            setValues({
                ...values,
                deliveryAreaInfo: selectedRegions,
            });
            setRegionData(onlySelectedRegions);
        } else if (initialRegionsData) {
            setRegionData(initialRegionsData);
        }
    }, [onlySelectedRegions, initialRegionsData]);

    const generateDropDownValues = (data, role) => {
        const result = [];
        if (data && data.length > 0) {
            const entries = data.filter((member) => member.userRole === role);
            entries.forEach((entry) => {
                result.push(entry.userID);
            });
        }
        return result;
    };

    const getAllAccountUsersRequest = async (data) => {
        try {
            const { verified } = authContext.user.details;
            const response = await userManagementAPIs.getAllUsersByAccount(verified);
            setUserList(response.data.map((userItem) => ({
                name: `${userItem.contactDetails?.firstname} ${userItem.contactDetails?.surname}`,
                value: userItem.userID,
                departmentID: userItem.departmentID,
                accountID: userItem.accountID,
                departmentName: userItem.departmentName,
            })));
            if (data?.responsibleUser && type !== 'calloff') setResponsibleUser(data?.responsibleUser?.userID);
            if (type !== 'calloff') setProcurementOfficer(generateDropDownValues(data?.steeringInfo, 'po'));
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: 'Unable to retrieve information.',
            });
        }
    };

    const generateDropdownOptionsForCallOfMechanism = (key, value, label, defaultValue) => (
        (key > 0)
            ? <option key={key} value={value}>{label}</option >
            : <option key={key} value='' hidden>{defaultValue}</option>
    );

    const setDates = (date) => {
        if (date) {
            const result = new Date(date.substr(6, 4), date.substr(3, 2) - 1, date.substr(0, 2));
            return result;
        }
        return '';
    };

    const cleanup = () => {
        dispatch(actions.setOnlySelectedCodes([]));
        dispatch(actionsRegion.setOnlySelectedRegions([]));
        dispatch(actionsRegion.clearRegions());
        dispatch(actions.clearCodes());
        dispatch(actionsLocality.clearLocalities());
    };

    const formatResponse = (data) => {
        const project = {};
        if (type === 'prf') project.projectID = projectId;
        project.title = data.title;
        project.description = data.description;
        project.estimatedValue = data.estimatedValue;
        if (data.route) project.route = data.route;
        project.contractStart = data.contractStart;
        if (data.extensionsAvailable) project.extensionsAvailable = data.extensionsAvailable;
        if (data.contractDuration) project.contractDuration = data.contractDuration;
        if (data.unrecoverableVAT) project.unrecoverableVAT = data.unrecoverableVAT;
        if (isBoolean(data.isExternal)) project.isExternal = data.isExternal;
        if (data.steeringInfo && type === 'calloff') {
            project.steeringInfo = data.steeringInfo?.map((user) => ({
                ...user,
                username: undefined,
            })).filter((user) => user.userRole === 'po');
        }
        if (data.steeringInfo && type !== 'calloff') {
            project.steeringInfo = data.steeringInfo?.map((user) => ({
                ...user,
                username: undefined,
            }));
        }
        if (data.responsibleUser) project.responsibleUser = data.responsibleUser?.userID;
        if (data.industryInfo) project.industryInfo = data.industryInfo;
        if (data.deliveryAreaInfo) project.deliveryAreaInfo = data.deliveryAreaInfo;
        if (type === 'calloff') {
            project.solutionID = data.solutionID;
            project.contractID = data.contractID;
        }
        if (type !== 'calloff') {
            project.projectDescribes = data.projectDescribes;
        }
        // if (type !== 'renewal') {
        // project.callOffMechanism = data.callOffMechanism || callOffMechanism; }
        project.callOffMechanism = data.callOffMechanism || callOffMechanism;
        if (data.callOffMechanism === 'furtherComp') project.competitionRules = data.competitionRules;
        if (data?.awardLetterSent !== undefined && data?.awardLetterSent !== null) {
            project.awardLetterSent = data.awardLetterSent;
        }
        if (type === projectTypes.callOff
            && (project.callOffMechanism === callOffMechanismValues.directAward
                || callOffMechanism === callOffMechanismValues.directAward)) {
            project.route = workflowRestrictionConstants.routeToMarket_directAward;
        }
        return project;
    };

    const getProjectOverview = async (projectID) => {
        if (authContext.user.accountId !== solutionAccountId
            && loggedUserStatusInSolution === userStatus.approved) {
            return projectManagementAPIs.getRestrictedProject(projectID, lotID);
        }

        return projectManagementAPIs.getProject(projectID);
    };

    useEffect(() => {
        cleanup();
        const getProject = async (id) => {
            try {
                const response = type === 'prf'
                    ? await projectManagementAPIs.getPRF(id)
                    : await getProjectOverview(id);
                if (response.status === 200) {
                    const newData = response.data;
                    newData.contractStart = setDates(newData.contractStart);
                    if (response.data.industryInfo) {
                        setInitialCodesData(response.data.industryInfo);
                    }
                    if (response.data.deliveryAreaInfo) {
                        setInitialRegionsData(response.data.deliveryAreaInfo);
                    }
                    const formattedResponse = formatResponse(newData);
                    setValues(formattedResponse);
                    setRouteToMarketSelect(formattedResponse.route);
                    setInitialProjectData(newData);
                    getAllAccountUsersRequest(newData);

                    if (newData.projectType) {
                        setProjectData({
                            projectId: id,
                            projectType: newData.projectType,
                            title: newData.title,
                        });
                    }
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Unable to retrieve information.',
                    });
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to retrieve information.',
                });
            }
        };

        switch (type) {
            case 'create':
                getAllAccountUsersRequest();
                break;
            case 'edit':
            case 'prf':
            case 'renewal':
            case 'calloff':
                getProject(projectId);
                break;
            default:
                break;
        }
    }, [type, projectId]);

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!values.steeringInfo || values.steeringInfo.filter((user) => user.userRole === 'po').length === 0) {
            Alert(
                Constants.warningMessages.messageTitle,
                Constants.warningMessages.procurementOfficeRequiredWarning, null, true,
            );
            return false;
        }

        if (!responsibleUser) {
            Alert(
                Constants.warningMessages.messageTitle,
                Constants.warningMessages.responsibleOfficerRequiredWarning, null, true,
            );
            return false;
        }

        if (values) {
            setShowCreateModal(true);
        }
        return true;
    };

    const handleCancel = (event) => {
        event.preventDefault();
        setShowCancelModal(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const removeItems = (arr, item) => arr.filter((i) => !item.includes(i));

    const isContractOption = (val) => contractOptions.includes(val);

    const getProjectDescriptions = (projectDescriptions, value) => {
        const opposites = {
            framework: [openFrameworkValue, dpsValue],
            openFramework: [frameworkValue, dpsValue],
            dps: [frameworkValue, openFrameworkValue],
        };
        let newDescriptions = [...projectDescriptions];

        if (!newDescriptions.includes(contractValue)) {
            newDescriptions.push(contractValue);
        }

        if ([frameworkValue, openFrameworkValue, dpsValue].includes(value)) {
            newDescriptions = removeItems(newDescriptions, opposites[value]);
        }

        if (!newDescriptions.includes(value)) {
            newDescriptions.push(value);
        }

        return newDescriptions;
    };

    const oncheckBoxChange = (e) => {
        const { value, checked } = e.target;
        const pdArray = values.projectDescribes || [];
        const projectDescriptions = [...pdArray];

        if (!checked) {
            let updatedDescriptions = removeItems(pdArray, value);

            // If unchecking 'contract', also remove all contract options
            if (value === contractValue) {
                updatedDescriptions = updatedDescriptions.filter((item) => !isContractOption(item));
            }

            setValues({
                ...values,
                projectDescribes: updatedDescriptions,
            });
            return;
        }

        if (isContractOption(value)) {
            const newDescriptions = getProjectDescriptions(projectDescriptions, value);
            setValues({
                ...values,
                projectDescribes: newDescriptions,
            });
            return;
        }

        setValues({
            ...values,
            projectDescribes: [...projectDescriptions, value],
        });
    };

    const setRouteToMarket = (e) => {
        setRouteToMarketSelect(e);
        if (e === '') {
            setValues({
                ...values,
                route: undefined,
                isExternal: undefined,
            });
        } else if (e === Constants.manualCreationId) {
            setValues({
                ...values,
                route: e,
                isExternal: false,
            });
        } else {
            setValues({
                ...values,
                route: e,
                isExternal: undefined,
            });
        }
    };

    const handleExtensionsChange = (event) => {
        const noOfInputs = event.target.value;
        const extensions = [];
        // if (noOfInputs && noOfInputs > 10) {
        //     Alert('Error', 'Extension should not be greater than 10', null, true);
        //     return;
        // }
        // if (values.extensionsAvailable && values.extensionsAvailable.length > 0) {
        //     for (let i = 0; i < noOfInputs; i++) {
        //         if (values.extensionsAvailable[i]) {
        //             extensions.push(values.extensionsAvailable[i]);
        //         } else {
        //             const extension = {
        //                 no: i,
        //                 monthsAvailable: '',
        //                 extensionStatus: '',
        //             };
        //             extensions.push(extension);
        //         }
        //     }
        // } else {
        //     for (let i = 0; i < noOfInputs; i++) {
        //         const extension = {
        //             no: i,
        //             monthsAvailable: '',
        //             extensionStatus: '',
        //         };
        //         extensions.push(extension);
        //     }
        // }
        for (let i = 0; i < noOfInputs; i++) {
            const extension = {
                no: i,
                monthsAvailable: '',
                extensionStatus: '',
            };
            extensions.push(extension);
        }
        setValues({
            ...values,
            extensionsAvailable: extensions,
        });
    };

    const handleExtension = (event) => {
        const existingExtensions = values.extensionsAvailable;
        existingExtensions[event.target.name].monthsAvailable = parseInt(event.target.value, 10) || '';
        setValues({
            ...values,
            extensionsAvailable: existingExtensions,
        });
    };

    const handleResponsibleUser = (data) => {
        // This may look strange, but it is needed to ensure that responsible user
        // renders on edit/renew/calloff project.
        if (data.length === 0) {
            const existingValues = { ...values };
            delete existingValues.responsibleUser;
            setResponsibleUser('');
            setValues(existingValues);
        } else if (values.responsibleUser && data[1]) {
            setResponsibleUser(data[1].value);
            setValues({
                ...values,
                responsibleUser: data[1].value,
            });
        } else {
            setResponsibleUser(data[0].value);
            setValues({
                ...values,
                responsibleUser: data[0].value,
            });
        }
    };

    const handleProcurementOfficer = (data) => {
        const selectionObjects = [];
        data.forEach((user) => {
            const userObject = {
                departmentID: user.departmentID,
                userID: user.value,
                accountID: user.accountID,
                userRole: 'po',
            };
            selectionObjects.push(userObject);
        });
        if (values.steeringInfo) {
            const otherEntries = [];
            values.steeringInfo.forEach((entry) => {
                if (entry.userRole !== 'po') {
                    otherEntries.push({
                        ...entry,
                        username: undefined,
                    });
                }
            });
            const fullSteeringDetails = selectionObjects.concat(otherEntries);
            setProcurementOfficer(selectionObjects.map((item) => item.userID));
            setValues({
                ...values,
                steeringInfo: fullSteeringDetails,
            });
        } else {
            setProcurementOfficer(selectionObjects.map((item) => item.userID));
            setValues({
                ...values,
                steeringInfo: selectionObjects,
            });
        }
    };

    const removeSteeringInfoHydration = (payload) => {
        let steeringInfo = payload?.steeringInfo;
        const hydrationExists = payload?.steeringInfo?.filter(
            (si) => si?.account?.accountID === si?.accountID,
        );
        if (hydrationExists?.length > 0) {
            steeringInfo = steeringInfo?.map(({ account, user, ...rest }) => rest);
        }
        return steeringInfo;
    };

    const updateProject = async () => {
        const payload = JSON.parse(JSON.stringify(values));
        payload.contractStart = values.contractStart ? format(values.contractStart, 'dd-MM-yyyy') : '';
        payload.steeringInfo = removeSteeringInfoHydration(payload);
        delete payload.awardLetterSent;
        const response = await projectManagementAPIs
            .updateProject(projectId, payload);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                title: 'Project updated successfully.',
            });
            cleanup();
            history.push(`/projects/overview/${projectId}`);
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Unable to update project.',
            });
        }
    };

    const createProject = async () => {
        let payload = JSON.parse(JSON.stringify(values));
        payload.contractStart = values.contractStart ? format(values.contractStart, 'dd-MM-yyyy') : '';
        payload.steeringInfo = removeSteeringInfoHydration(payload);
        delete payload.awardLetterSent;
        if (type === 'renewal') {
            const response = await projectManagementAPIs
                .createRenewedProject(contractId, payload);
            if (response.status === 201) {
                cleanup();
                Toast.fire({
                    icon: 'success',
                    title: 'Contract renewed successfully.',
                });
                history.push(`/projects/overview/${response.data}`);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to create renewed project.',
                });
            }
        } else if (type === 'calloff') {
            const workflowID = callOffMechanism === 'furtherComp'
                ? authContext?.user?.defaultWorkflow?.fCWorkflow?.workflowID
                : authContext?.user?.defaultWorkflow?.dAWorkflow?.workflowID;

            const calloffPayload = {
                ...payload,
                awardedSuppliers,
                callOffMechanism,
                competitionRules,
                workflowID,
                visibleToCR: payload.visibleToCR ?? true,
            };
            if (lotID) {
                calloffPayload.callOffLotID = lotID;
            }

            const response = await projectManagementAPIs.createCallOff(calloffPayload);
            if (response.status === 201) {
                cleanup();
                Toast.fire({
                    icon: 'success',
                    title: 'Call Off created successfully.',
                });
                history.push(`/calloff/overview/${response.data}`);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to create call off.',
                });
            }
        } else {
            payload = {
                ...payload,
                visibleToCR: payload.visibleToCR ?? true,
            };
            const response = await projectManagementAPIs
                .createProject(payload);
            if (response.status === 201) {
                cleanup();
                Toast.fire({
                    icon: 'success',
                    title: 'Project created successfully.',
                });
                history.push(`/projects/overview/${response.data}`);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Unable to create project.',
                });
            }
        }
    };

    const generateCodeTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.classificationID}
                id={item.classificationID}
                tagTxt={(item.category && item.level) ? `${item.category} - ${item.level}` : `${item.classificationID}`}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => removePill(item.classificationID)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    const generateSupplyRegionsTag = (data, isDeletable) => {
        const TagList = [];
        data.forEach((item) => {
            const TagElement = <Tag key={item.code}
                id={item.code}
                tagTxt={`${item.code} - ${item.description}`}
                isDeletable={isDeletable}
                size='large'
                handleDeleteBtnClick={() => removeRegionPill(item.code)}
            />;
            TagList.push(TagElement);
        });
        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };

    return (<>
        <Form id='cPPFormContent' onSubmit={handleSubmit}>
            <p className='title-large sectionHeader keyInfo' id='cPPFormHeader'>Key Information</p>
            <div className='formInputContainer'>
                <LabelledInput
                    id='title'
                    type={LabelledInputTypeText}
                    label='Project title'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.title || ''}
                    placeholder='Enter title'
                />
            </div>
            <div className='formInputContainer' id={'projectDescription'}>
                <LabelledTextarea
                    id='description'
                    placeholder='Enter description'
                    breakColumn={true}
                    label='Project description'
                    value={values.description || ''}
                    currentCharCount={values.description ? values.description.length : 0}
                    onChange={(e) => handleChange(e)}
                    required={true}
                    maxCharCount={3000}
                />
            </div>
            <div className='formInputContainer'>
                <LabelledInput
                    id='estimatedValue'
                    type={LabelledInputTypeCurrency}
                    label='Estimated Value (£)'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.estimatedValue || ''}
                    placeholder='Enter value'
                />
            </div>
            <div className='formInputContainer'>
                <div className='labelled-input' id='route'>
                    <label className='form-label title selectSearchTitle'>{Constants.selectToRouteLabel}
                        <span className='caption'>{Constants.optional}</span>
                    </label>
                    <SelectSearch
                        id='route-select'
                        options={helperFunctions.constructGroupedDropdownData(routeToMarket, 'header', undefined, false)}
                        filterOptions={helperFunctions.handleFilter}
                        value={routeToMarketSelect}
                        search={false}
                        printOptions='on-focus'
                        closeOnSelect={true}
                        multiple={false}
                        placeholder={Constants.selectToRoutePlaceholder}
                        onChange={(e) => setRouteToMarket(e)}
                        readonly={(initialProjectData?.workflow?.freezeRoute === true || (type === 'renewal' || type === 'calloff')) && true}
                        required={false}
                        disabled={type === 'calloff'}
                    />
                </div>
            </div>
            <div className='flex-break' />
            {values.route === Constants.manualCreationId && (
                <div className='formInputContainer'>
                    <LabelledRadioButton
                        id={'isExternal'}
                        label={Constants.isExternalLabels.label}
                        breakColumn={false}
                        onChange={(event) => {
                            const updatedValues = { ...values };
                            updatedValues.isExternal = event.target.value === 'true';
                            setValues(updatedValues);
                        }}
                        defaultValue={false}
                        options={[{
                            label: Constants.isExternalLabels.myOrganisation,
                            value: false,
                            id: 'isMyOrganisation',
                            name: 'isExternal',
                            checked: !values.isExternal,
                        }, {
                            label: Constants.isExternalLabels.external,
                            value: true,
                            id: 'isExternal',
                            name: 'isExternal',
                            checked: values.isExternal,
                        }]}
                        renderAsRow={true}
                        required={true} />
                </div>
            )}
            {type !== 'calloff'
                && <div className='formInputContainer'>
                    <LabelledCheckBox
                        id='projectDescribes'
                        label='Project Type'
                        renderAsRow={false}
                        breakColumn={true}
                        onChange={(e) => oncheckBoxChange(e)}
                        options={[{
                            id: 'dps',
                            value: dpsValue,
                            label: PROJECT_DESCRIBES_VALUES.DPS.label,
                            checked: values.projectDescribes
                                ?.includes(dpsValue)
                                || false,
                        }, {
                            id: 'framework',
                            value: frameworkValue,
                            label: PROJECT_DESCRIBES_VALUES.FRAMEWORK.label,
                            checked: values.projectDescribes
                                ?.includes(frameworkValue) || false,
                        }, {
                            id: 'openFramework',
                            value: openFrameworkValue,
                            label: PROJECT_DESCRIBES_VALUES.OPEN_FRAMEWORK.label,
                            checked: values.projectDescribes
                                ?.includes(openFrameworkValue) || false,
                        }, {
                            id: 'contract',
                            value: contractValue,
                            label: PROJECT_DESCRIBES_VALUES.CONTRACT.label,
                            checked: values.projectDescribes
                                ?.includes(contractValue)
                                || false,
                        }]}
                        required={false} />
                </div>}
            {type !== 'calloff'
                && <div className='formInputContainer'>
                    <LabelledSelect
                        id='callOffMechanism'
                        label='Call Off Mechanism'
                        required={false}
                        readonly={type === 'calloff'}
                        breakColumn={true}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setValues({
                                    ...values,
                                    competitionRules: undefined,
                                    callOffMechanism: undefined,
                                });
                            } else if (projectService.isRTMDirectAward(e.target.value)) {
                                setValues({
                                    ...values,
                                    competitionRules: undefined,
                                    callOffMechanism: e.target.value,
                                });
                            } else {
                                setValues({
                                    ...values,
                                    competitionRules: undefined,
                                    callOffMechanism: e.target.value,
                                });
                            }
                        }} // unset competitionRules.
                        value={values.callOffMechanism || ''}
                        options={Constants.callOffMechanism.map(
                            (option, idx) => generateDropdownOptionsForCallOfMechanism(
                                idx, option.value, option.label, 'Select call off mechanism',
                            ),
                        )}
                    />
                    {values.callOffMechanism === 'furtherComp'
                        && <div className='formInputContainer'>
                            <LabelledRadioButton id={'furtherCompetitionRule'}
                                label={Constants.furtherCompetitionRulesLabel}
                                required={true}
                                breakColumn={true}
                                options={[{
                                    id: 'all-supplier',
                                    value: Constants.furtherCompetitionValues.allSupplierValue,
                                    label: Constants.furtherCompetition.allSupplier,
                                    checked: values.competitionRules ? values.competitionRules
                                        === Constants.furtherCompetitionValues.allSupplierValue
                                        : false,
                                    name: Constants.competitionRules,
                                    required: true,
                                }, {
                                    id: 'any-supplier',
                                    value: Constants.furtherCompetitionValues.anySupplierValue,
                                    label: Constants.furtherCompetition.anySupplier,
                                    checked: values.competitionRules ? values.competitionRules
                                        === Constants.furtherCompetitionValues.anySupplierValue
                                        : false,
                                    name: Constants.competitionRules,
                                    required: true,
                                }]}
                                onChange={(e) => handleChange(e)}
                                renderAsRow={true}
                            />
                        </div>}
                </div>}
            <div className='formInputContainer'>
                <div className='labelled-input' id='estimatedContractStartDate'>
                    <label className='form-label title datePickerLabel'>Estimated contract start date <span className='caption'>(Optional)</span></label>
                    <DatePicker
                        className='customDateInput nmiDate-imput'
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        calendarIcon={null}
                        format={'dd-MM-y'}
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(data) => {
                            setValues({
                                ...values,
                                contractStart: data,
                            });
                        }}
                        value={values.contractStart ? values.contractStart : ''}
                    />
                </div>
            </div>
            <div className='flex-break' />
            <div className='formInputContainer'>
                <LabelledInput
                    id='contractDuration'
                    type={LabelledInputTypeText}
                    label='Initial Contract Duration (months)'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.contractDuration || ''}
                    placeholder='Enter duration'
                    required={false}
                    readonly={false}
                    pattern='^[0-9]+$'
                    title='Please enter a whole number'
                />
            </div>
            <SelectExtensionsAvailable
                numberOfInputs={values.extensionsAvailable
                    && values.extensionsAvailable.length.toString()}
                handleExtensionsChange={(e) => handleExtensionsChange(e)}
                handleExtension={(e) => handleExtension(e)}
                values={values}
                readonly={values.projectDescribes?.includes('dps') ? false : values.awardLetterSent || false}
            />
            <div className='formInputContainer'>
                <LabelledInput
                    id='unrecoverableVAT'
                    type={LabelledInputTypeCurrency}
                    label='VAT that cannot be recovered (£)'
                    breakColumn={true}
                    onChange={(e) => handleChange(e)}
                    value={values.unrecoverableVAT || ''}
                    placeholder='Enter amount'
                    required={false}
                    readonly={false}
                />
            </div>
            <div className='formInputContainer'>
                <div className='labelled-input' id='po'>
                    <label className='form-label title selectSearchTitle'>Assign a Procurement Officer</label>
                    <SelectSearch
                        id='po'
                        options={helperFunctions.constructGroupedDropdownData(userList, 'departmentName') || []}
                        filterOptions={helperFunctions.handleFilter}
                        value={procurementOfficer}
                        search={true}
                        printOptions='on-focus'
                        closeOnSelect={false}
                        multiple={true}
                        placeholder='Search users on Open'
                        onChange={(event, data) => handleProcurementOfficer(data)}
                        z-index='4'
                        required={true}
                    />
                </div>
            </div>
            <div className='flex-break' />
            <div className='formInputContainer'>
                <div className='labelled-input'>
                    <label className='form-label title selectSearchTitle'>Assign the Responsible User</label>
                    <p className='caption' id='responsibleCaption'>The name of this person will appear on the Opportunites page,
                        Contracts Register, Find a Tender/Contracts Finder etc.</p>
                    <SelectSearch
                        id='responsibleUser'
                        options={helperFunctions.constructGroupedDropdownData(userList, 'departmentName') || []}
                        filterOptions={helperFunctions.handleFilter}
                        value={responsibleUser}
                        search={true}
                        printOptions='on-focus'
                        closeOnSelect={true}
                        multiple={true}
                        placeholder='Search users on Open'
                        onChange={(e, data) => handleResponsibleUser(data)}
                        z-index='4'
                        required={true}
                    />
                </div>
            </div>
            <div className='flex-break' />
            {(type === 'create' || type === 'edit' || type === Constants.callOff)
                && <><div className='formInputContainer'>
                    <LabelledRadioButton
                        id={'visibleToCR'}
                        label='Would you like to publish to contracts register?'
                        breakColumn={false}
                        onChange={(event) => {
                            const updatedValues = { ...values };
                            updatedValues.visibleToCR = event.target.value === 'true';
                            setValues(updatedValues);
                        }}
                        options={[{
                            label: 'Yes',
                            value: true,
                            id: 'publishToContractRegister_Yes',
                            name: 'visibleToCR',
                            checked: values.visibleToCR,
                            defaultChecked: true,
                        }, {
                            label: 'No',
                            value: false,
                            id: 'publishToContractRegister_No',
                            name: 'visibleToCR',
                            checked: values.visibleToCR === false,
                        }]}
                        renderAsRow={true}
                        required={true} />
                </div><div className='flex-break' /></>}
            <div id='cPPSectionContent'>
                <div id='lotSectionContent'>
                    <div className='textIconContainer'><p className='title-large sectionHeader'>Codes and Categories</p>
                        {type !== 'calloff'
                            && <Button
                                id='addCodeButton'
                                size='small'
                                variant='secondary skinless'
                                label='Add'
                                icon={true}
                                iconSrc={addIconSrc}
                                disabled={false}
                                handleClick={(e) => {
                                    e.preventDefault();
                                    setShowCodesModal(true);
                                }}
                            />}
                    </div>
                    {type === 'calloff' ? generateCodeTag(codeData, false) : generateCodeTag(codeData, true)}
                </div>
                <div id='lotSectionContent'>
                    <div className='textIconContainer'><p className='title-large sectionHeader'>Delivery Area</p>
                        {type !== 'calloff'
                            && <Button
                                id='addRegionButton'
                                size='small'
                                variant='secondary skinless'
                                label='Add'
                                icon={true}
                                iconSrc={addIconSrc}
                                disabled={false}
                                handleClick={(e) => {
                                    e.preventDefault();
                                    setShowRegionsModal(true);
                                }}
                            />}
                    </div>
                    {type === 'calloff' ? generateSupplyRegionsTag(regionData, false) : generateSupplyRegionsTag(regionData, true)}
                </div>
            </div>
            <div id='actionButtonContainer'>
                <Button
                    id='cancel-action-btn'
                    variant='secondary'
                    label='Cancel'
                    handleClick={handleCancel}
                />
                <Button
                    id='create-action-btn'
                    variant='primary'
                    label={type === 'edit' ? 'Update' : 'Create'}
                    handleClick={() => null}
                />
            </div>
            {showCancelModal && <>
                <Modal
                    id='cancelBtnModal'
                    open={true}
                    size='small'
                    headerTitle='Are you sure?'
                    body={type === 'edit'
                        ? <p className='body'>{`Are you sure you want to cancel editing of Project ${values.title}?`}</p>
                        : <p className='body'>{`Are you sure you want to cancel creation of Project ${values.title}?`}</p>}
                    footer={true}
                    mainActionButtonTxt='Yes'
                    secondActionButtonTxt='No'
                    secondActionButton={true}
                    helpOption={false}
                    handleMainActionBtnClick={(event) => {
                        event.preventDefault();
                        cleanup();
                        history.goBack();
                    }
                    }
                    handleSecondaryActionBtnClick={() => setShowCancelModal(false)}
                    closeModal={() => setShowCancelModal(false)} />
                <ScreenOverlay />
            </>}
            {showCreateModal && <>
                <Modal
                    id='createBtnModal'
                    open={true}
                    size='small'
                    headerTitle='Are you sure?'
                    body={type === 'edit'
                        ? <p className='body'>{`Are you sure you want to update Project ${values.title}?`}</p>
                        : <p className='body'>{`Are you sure you want to create Project ${values.title}?`}</p>}
                    footer={true}
                    mainActionButtonTxt='Yes'
                    secondActionButtonTxt='No'
                    secondActionButton={true}
                    helpOption={false}
                    handleMainActionBtnClick={
                        type === 'edit'
                            ? (event) => {
                                event.preventDefault();
                                updateProject();
                            }
                            : (event) => {
                                event.preventDefault();
                                createProject();
                            }
                    }
                    handleSecondaryActionBtnClick={() => setShowCreateModal(false)}
                    closeModal={() => setShowCreateModal(false)} />
                <ScreenOverlay />
            </>}
        </Form>
        {showCodesModal && <SelectCodesModal
            closeModal={toggleModal}
            initialData={initialCodesData} />}
        {showRegionsModal && <SupplyRegionsModal
            closeModal={toggleRegionModal}
            initialData={initialRegionsData} />}
    </>);
};

CreateProcurementForm.propTypes = {
    type: PropTypes.string,
    lotID: PropTypes.string,
    awardedSuppliers: PropTypes.array,
    callOffMechanism: PropTypes.string,
    competitionRules: PropTypes.string,
    solutionAccountId: PropTypes.string,
    loggedUserStatusInSolution: PropTypes.string,
};

export default CreateProcurementForm;
