import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import store from './store';

import Routes from './config/routes';
import AuthInterceptor from './config/authInterceptor';
import LoaderInterceptor from './config/loaderInterceptor';

import Auth from './features/Auth';
import { ThemeProvider } from './context/ThemeContext';
import ToogleThemeChange from './components/ToggleThemeChange/ToggleThemeChange';

import './styles/scss/main.scss';

ReactDOM.render(
    <ThemeProvider>
        <Provider store={store}>
            <Auth>
                <Router>
                    <AuthInterceptor />
                    <LoaderInterceptor />
                    <ToogleThemeChange />
                    <Routes />
                </Router>
            </Auth>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root'),
);
