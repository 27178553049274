import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
// import serviceHelper from '../../utils/serviceHelper';
// import { evaluationStatuses } from '../../config/constants';
import Toast from '../../components/Alerts/Toast/Toast';
import AuthContext from '../../context/AuthContext';
import { accountTypes } from '../../config/constants';
import { ProjectDataContext } from '../../context/ProjectDataContext';

const EvaluateQuestionnaire = () => {
    const { responseID } = useParams();
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { setProjectData } = useContext(ProjectDataContext);
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const [steeringInfo, setSteeringInfo] = useState();

    useEffect(() => {
        const fetchData = async () => {
            if (authContext.user.accountType !== accountTypes.supplier) {
                const responseData = await projectManagementAPIs.getResponse(responseID);
                // responseData = initialData;
                if (responseData.status === 200) {
                    const preparedData = helperFunctions
                        .prepareDocumentBuilderState('questionnaire', responseData.data, responseID);
                    setInitialState(preparedData);
                    setType(preparedData.details.type);
                    setSteeringInfo(responseData?.data?.steeringInfo);
                    setProjectData({
                        projectId: responseData.data.projectID,
                        eventId: responseData.data.eventID,
                        title: responseData.data.projectInfo?.title,
                    });
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to retrieve information.',
                    });
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'You do not have permission to view this content.',
                });
                history.push('/dashboard');
            }
        };
        // TODO: if (templateId && authContext.user.isAuthenticated) {
        if (responseID) {
            fetchData();
        }
    }, [responseID]);

    // const handleStatusUpdate = async (status) => {
    //     const hasStatusUpdated = serviceHelper
    //         .updateStatusForResponse(responseID, status);
    //     if (!hasStatusUpdated) {
    //         Toast.fire({
    //             icon: 'error',
    //             titleText: 'Unable to submit evaluation.',
    //         });
    //     }
    // };

    const handleSubmit = async (data, submissionType) => {
        const payload = {
            dateModified: format(new Date(), 'dd-MM-yyyy'),
            responseType: data.details.responseType,
        };

        const sections = [];
        let score = 0;
        data.sections.forEach((section) => {
            const sectionData = {
                description: section.description,
                title: section.title,
                weightage: section.sectionWeightage,
            };
            const questions = [];
            section.sectionItems.forEach((question) => {
                const questionItem = {
                    ...question,
                    // description: question.description,
                    // questionTitle: question.questionTitle,
                    // questionAnswer: question.questionAnswer,
                    // weightage: question.weightage,
                    // responseOptions: question.responseOptions,
                    // responseType: question.responseType,
                    // // type: question.type,
                    // scoringMechanism: question.scoringMechanism,
                    supportingDocuments: question.supportingDocuments === true,
                    scoring: question.scoring?.toString(),
                    // supportingAnswerDocuments: question.supportingAnswerDocuments,
                };
                if (question.scoring && question.scoringMechanism === 'scored') score += parseFloat(question.scoring);
                if (question.comments) questionItem.comments = question.comments;
                if (questionItem.scoring === 'Pseudo Pass') questionItem.pseudoPassReason = question.pseudoPassReason;
                questions.push(questionItem);
            });
            sectionData.questions = questions;
            sections.push(sectionData);
        });
        payload.sections = sections;
        payload.score = score.toString();

        let response;
        if (submissionType === 'save') {
            response = await projectManagementAPIs.saveEvaluation(responseID, payload);
        } else {
            response = await projectManagementAPIs.submitEvaluation(responseID, payload);
        }
        // const response = await projectManagementAPIs.updateResponse(responseID, payload);
        if (response.status === 200) {
            // handleStatusUpdate(submissionType === 'save'
            // ? evaluationStatuses.underEvaluation : evaluationStatuses.stage1Approved);
            Toast.fire({
                icon: 'success',
                titleText: 'Answers evaluated successfully.',
            });
            history.goBack();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to evaluate answers.',
            });
        }
    };

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'evaluate'}
        context='Questionnaire'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Section'
        sectionItemLabel='Question'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
        sectionDetailsUserList={[]}
        handlePrimaryToolbarSubmitButton={handleSubmit}
        steeringInfo={steeringInfo}
    /> : <></>;
};

export default EvaluateQuestionnaire;
