import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import checkIcon from '../../styles/icons/white/check.svg';
import ProgressBarItem from './components/ProgressBarItem';

const ProgressBar = ({
    options,
    activeStep,
    id,
    context,
}) => {
    const [mobileMode, setMobileMode] = useState({ matches: window.innerWidth <= 600 });
    useEffect(() => {
        const mobileMediaQuery = window.matchMedia('(max-width: 600px)');
        mobileMediaQuery.addEventListener('change', setMobileMode);

        return () => {
            mobileMediaQuery.removeEventListener('change', setMobileMode);
        };
    }, []);

    const generateViewMode = (dataToProcess) => {
        if (dataToProcess.length === 1) {
            return dataToProcess;
        }
        const numberOfSteps = (mobileMode.matches) ? 1 : 2;
        const numberOfPastSteps = (activeStep - numberOfSteps <= 0)
            ? 0
            : (activeStep - numberOfSteps) - 1;
        const numberOfUpcomingSteps = (activeStep + numberOfSteps >= dataToProcess.length)
            ? dataToProcess.length
            : activeStep + numberOfSteps;
        const previousAndCurrent = [...dataToProcess].slice(numberOfPastSteps, activeStep);
        const upcoming = [...dataToProcess].slice(activeStep, numberOfUpcomingSteps);
        return [...previousAndCurrent, ...upcoming];
    };

    const generateOptionSteps = () => {
        const optionsHTML = [];
        let mode = 'pending';
        options.forEach((option, idx) => {
            if ((idx + 1) < activeStep) {
                mode = 'past'; // complete circle with icon
            } else if ((idx + 1) === activeStep) {
                mode = 'current'; // circle with green border and half green connector line
            } else if ((idx + 1) > activeStep) {
                mode = 'pending'; // gray circle with gray line
            }
            optionsHTML.push(
                <li key={`progressBarListItem-${idx}`}>
                    <ProgressBarItem key={`progressBarItem-${idx}`}
                        mode={mode}
                        icon={checkIcon}
                        heading={option}
                        title={option} />
                </li>,
            );
        });

        if (context === 'pastCurrentUpcoming') {
            return generateViewMode(optionsHTML);
        }
        return optionsHTML;
    };

    return (
        <div className='progress-bar' id={id}>
            <ul className='progress-bar-items-list'>
                {generateOptionSteps()}
            </ul>
        </div>
    );
};

ProgressBar.propTypes = {
    options: PropTypes.array.isRequired,
    activeStep: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
};

export default ProgressBar;
