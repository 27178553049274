import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { v4 as uuidv4 } from 'uuid';

function ButtonMenu({ label, options, classes }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const optionHandleClick = (option) => {
        option.handleClick();
        handleClose();
    };

    return (
        <div>
            <Button
                id={`menu-button-${uuidv4()}`}
                className= { `${classes || ''} menu-button`}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                variant='contained'
                disableElevation
                onClick={handleClick}
            >
                {label}
            </Button>
            <Menu
                id={`menu-button-links-${uuidv4()}`}
                className='menu-button-links'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock
            >
                {options?.map((option) => (
                    <MenuItem key={uuidv4()}
                        onClick={() => optionHandleClick(option)}
                        className={`icon-${option.className}`} disableRipple>
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

ButtonMenu.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            handleClick: PropTypes.func.isRequired,
            className: PropTypes.string,
        }),
    ).isRequired,
    classes: PropTypes.string,
};

export default ButtonMenu;
