import React from 'react';
import Tag from '../../components/Tag';
import Constants from './constants';

const getAllChildren = (parent, currentTierLevel, usersAndDepartmentsLevelsdata) => {
    const prepareParent = { ...parent };
    const newChildren = usersAndDepartmentsLevelsdata[`level-${currentTierLevel}`]?.reduce((accChildren, newChild) => {
        if (newChild[currentTierLevel].fromID === prepareParent.classificationID) {
            accChildren.push({ ...newChild[currentTierLevel] });
        }
        return accChildren;
    }, []);
    prepareParent.children = [...new Set(newChildren)];
    return prepareParent;
};

const recursivelyUpdateSelectionState = (currentData, previousData) => {
    const preparedData = currentData.map((el) => {
        const elem = { ...el };
        if (!previousData) {
            elem.selected = false;
            return elem;
        }
        const filterInitialData = previousData.filter(
            (e) => e.classificationID === el.classificationID,
        );
        if (filterInitialData.length > 0) {
            elem.selected = filterInitialData[0].selected;
            if (elem.children) {
                elem.children = recursivelyUpdateSelectionState(
                    elem.children, filterInitialData[0].children,
                );
            }
        } else {
            elem.selected = false;
        }
        return elem;
    });
    return preparedData;
};

const transformIntoUsersAndDepartmentsData = (departmentsData) => {
    const usersList = [];
    const departments = departmentsData?.map((departmentData) => {
        const departmentsUsers = departmentData.userInfo
            ?.filter(
                (userInf) => userInf.isUserEnable && userInf.verified === Constants.userApproved,
            )
            .map((userInf) => ({
                1: {
                    name: departmentData.deptInfo.departmentName,
                    lowestLevel: 2,
                    currentLevel: 1,
                    classificationID: departmentData.deptInfo.departmentID,
                },
                2: {
                    name: `${userInf.contactDetails.firstname} ${userInf.contactDetails.surname}`,
                    lowestLevel: 2,
                    currentLevel: 2,
                    classificationID: userInf.userID,
                    fromID: departmentData.deptInfo.departmentID,
                },
            }));

        usersList.push(...departmentsUsers);
        return {
            name: departmentData.deptInfo.departmentName,
            lowestLevel: 2,
            currentLevel: 1,
            classificationID: departmentData.deptInfo.departmentID,
        };
    });
    return {
        'level-2': usersList,
        'level-1': departments,
    };
};

const updateSelected = (usersAndDepartments, e) => usersAndDepartments.map((el) => {
    const preparedEl = { ...el };
    if (`checkbox-${el.classificationID}-checkBox-btn` === e.target.id) {
        preparedEl.selected = !el.selected;
    }
    if (preparedEl.children) {
        preparedEl.children.forEach((child, idx) => {
            if (`checkbox-${child.classificationID}-checkBox-btn` === e.target.id) {
                const preparedChildren = preparedEl.children;
                preparedChildren[idx].selected = !preparedChildren[idx].selected;
            }
        });
    }
    return preparedEl;
});

const getFilteredUsersAndDepartments = (usersAndDepartments, search) => usersAndDepartments
    .reduce((acc, userAndDepartment) => {
        if (search == null) return acc;

        const searchLower = search.toLowerCase();

        if (userAndDepartment.name.toLowerCase().includes(searchLower)) {
            acc.push(userAndDepartment);
        }

        if (userAndDepartment.children) {
            const matchingChildren = userAndDepartment.children
                .filter((child) => child.name.toLowerCase().includes(searchLower))
                .map((child) => ({
                    ...child,
                    parent: userAndDepartment.name,
                }));

            acc.push(...matchingChildren);
        }

        return acc;
    }, []);

const getNestedData = (usersAndDepartmentsLevels) => usersAndDepartmentsLevels['level-1'].map((el) => getAllChildren(el, 2, usersAndDepartmentsLevels));

const createTag = (currentSupplierID, userAndDepartment, isDeletable, removePill) => <Tag
    key={userAndDepartment.classificationID}
    id={userAndDepartment.classificationID}
    tagTxt={`${userAndDepartment.name}`}
    isDeletable={isDeletable}
    size='large'
    handleDeleteBtnClick={
        () => removePill(currentSupplierID, userAndDepartment.classificationID)
    }
/>;

const generateUsersAndDepartmentsTag = (currentSupplierID, data, isDeletable, removePill) => {
    if (!data) return <></>;
    const TagList = data.reduce((acc, userAndDepartment) => {
        if (userAndDepartment.selected) {
            acc.push(createTag(currentSupplierID, userAndDepartment, isDeletable, removePill));
        }
        if (userAndDepartment.children) {
            userAndDepartment.children
                .filter((user) => user.selected)
                .forEach((selectedUser) => {
                    acc.push(createTag(currentSupplierID, selectedUser, isDeletable, removePill));
                });
        }
        return acc;
    }, []);

    return <div className='sectionContentTags'>
        {TagList}
    </div>;
};

const mapUsersAndDepartments = (data) => {
    const departments = data?.reduce((acc, department) => {
        const users = department.children
            ?.filter((user) => user.selected)
            .map((user) => ({ userID: user.classificationID }));

        if (department.selected || users?.length > 0) {
            const departmentObject = {
                ...(department.selected && {
                    departmentID: department.classificationID,
                }),
                users,
            };
            acc.push(departmentObject);
        }
        return acc;
    }, []);

    return departments;
};

const removeUsersAndDepartmentsSelection = (elements, pillID) => elements.map((el) => {
    const elem = { ...el };
    if (el.classificationID === pillID) {
        elem.selected = false;
    }
    if (el.children) {
        elem.children = removeUsersAndDepartmentsSelection(el.children, pillID);
    }
    return elem;
});

export default {
    getAllChildren,
    recursivelyUpdateSelectionState,
    transformIntoUsersAndDepartmentsData,
    updateSelected,
    getFilteredUsersAndDepartments,
    getNestedData,
    mapUsersAndDepartments,
    removeUsersAndDepartmentsSelection,
    generateUsersAndDepartmentsTag,
};
