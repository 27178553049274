import React from 'react';
import helperFunctions from '../../utils/helperFunctions';
import {
    CALLOFF_CARD_TITLE, CALLOFF_CARD_ACTION, ESTIMATED_VALUE,
    CURRENT_STAGE, STAGE_PROGRESS, STATUS, CONTRACT_VALUE, RAISED_BY,
    SUBMITTED_ON, VALUE, RIGOUR_LEVEL, VIEW_CONTRACT, VIEW, NO_PROCUREMENTS_REQUESTS_AVAILABLE,
    VIEW_PROJECT, VIEW_REQUEST,
} from './constants';
import { callOffMechanisms, dashboard, routeToMarket } from '../../config/constants';
import GeneralConstants from '../../utils/generalConstants';
import SupplierCard from '../SupplierDashboard/components/SupplierCard';
import Button from '../../components/Button';


const taskEventContent = ({ content }) => {
    const taskEventItems = content.map((taskEventItem, index) => {
        const status = helperFunctions.getStatus(taskEventItem.startDate,
            taskEventItem.eventStatus);
        return <div className='cardContentContainer' key={`event${index}`}>
            <div className='eventItem'>
                <div className='leftColumn'><p className='caption-xSmall'>{helperFunctions.formatPrettyDate(taskEventItem.startDate)}</p></div>
                <div className='middleColumn'>
                    <div className='eventHeader'>
                        <p className='title-xSmall'>{taskEventItem.eventName}</p>
                    </div>
                    <div className='eventContent'>
                        <p className='caption-xSmall'>{taskEventItem.projectName}</p>
                    </div>
                </div>
                <div className='rightColumn'><p className='eventStatus title-xSmall'>
                    <div className={`statusText ${helperFunctions.getStatusClass(status)}`} id={status}>
                        <p className='title-small'>{status}</p>
                    </div>
                </p></div>
            </div>
        </div>;
    });
    return taskEventItems;
};

const myProjectContentItem = (projectItem) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{ESTIMATED_VALUE}:</p>
        <p className='title-small itemValue'>{projectItem.estimatedValue ? helperFunctions.formatCurrency(projectItem.estimatedValue) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{CURRENT_STAGE}:</p>
        <p className='title-small itemValue'>{projectItem.currentStage ?? '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{STAGE_PROGRESS}:</p>
        <p className='title-small itemValue'>{`${projectItem.stageProgress}`}</p>
    </div>
</>;


const myRequestContentItem = (requestItem) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{STATUS}:</p>
        <p className='title-small itemValue'>{helperFunctions.camel2title(requestItem.projectStatus)}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{CONTRACT_VALUE}:</p>
        <p className='title-small itemValue'>{requestItem.estimatedValue ? helperFunctions.formatCurrency(requestItem.estimatedValue) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{RAISED_BY}:</p>
        <p className='title-small itemValue'>{requestItem.username}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{SUBMITTED_ON}:</p>
        <p className='title-small itemValue'>{helperFunctions.formatPrettyDate(requestItem.createdOn)}</p>
    </div>
</>;

const myContractContentItem = (contractItem) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{VALUE}:</p>
        <p className='title-small itemValue'>{contractItem.awardValue ? helperFunctions.formatCurrency(contractItem.awardValue) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{RIGOUR_LEVEL}:</p>
        <p className='title-small itemValue'>{contractItem.rigour || '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{CURRENT_STAGE}:</p>
        <p className='title-small itemValue'>{contractItem.currentStage ?? '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{STAGE_PROGRESS}:</p>
        <p className='title-small itemValue'>{contractItem.stageProgress}</p>
    </div></>;

const getPrettyRTM = (rtm) => routeToMarket.filter((route) => route.id === rtm)[0]?.name;

const myContractContent = ({ content, toggle, history }) => {
    const contractItems = content.map((contractItem, index) => {
        const url = `/contracts/management/${contractItem.contractID}`;
        return ((index < 5) ? <SupplierCard
            key={index}
            id={`contractId${index}`}
            header
            type={toggle && GeneralConstants.CONTRACT}
            caption={`${!toggle ? GeneralConstants.CONTRACT : ''} #${contractItem?.contractReference ?? '-'}`}
            headerTitle={contractItem.title}
            content={myContractContentItem(contractItem)}
            actionBtnText={VIEW_CONTRACT}
            handlerActionBtn={() => history.push(url)}
            url={url}
        /> : null);
    });
    return contractItems;
};

const submitProcrumentRequest = ({ content, history }) => <>
    {content?.length > 0
        ? <div className='cardContentContainer'>
            {content.map((prf) => (
                <div className='submitRequestItem' key={prf.projectID}>
                    <div><p className='title'>{prf.title}</p></div>
                    <div>
                        <Button
                            id='view'
                            variant='primary'
                            label={VIEW}
                            handleClick={() => history.push(`/procurement-requests/overview/${prf.projectID}`)}
                        />
                    </div>
                </div>
            ))}
        </div>
        : <div className='cardContentContainer'>
            <p className='title' id='noProcurementRequestsContent'>{NO_PROCUREMENTS_REQUESTS_AVAILABLE}</p>
        </div>}
</>;

const myProjectContent = ({ content, toggle, history }) => {
    const projectItems = content.map((projectItem, index) => {
        if (index < 3) {
            const url = `/projects/overview/${projectItem.projectID}`;
            return <SupplierCard
                key={index}
                id={`projetcId${index}`}
                header
                headerTitle={projectItem.title}
                type={toggle && GeneralConstants.PROJECT}
                caption={`${!toggle ? GeneralConstants.PROJECT : ''} # ${projectItem.projectReference ? projectItem.projectReference : '-'}`}
                content={myProjectContentItem(projectItem)}
                actionBtnText={VIEW_PROJECT}
                alertMessage={projectItem.projectStatus === 'cancelled' ? 'Cancelled' : null}
                handlerActionBtn={() => history.push(url)}
                url = {url}
                rtm={projectItem.route ? getPrettyRTM(projectItem.route) : ''}
            />;
        }
        return null;
    });

    return projectItems;
};

const getPrettyCallOffMechanism = (callOffMechanism) => callOffMechanisms.filter(
    (eventType) => eventType.value === callOffMechanism,
)[0]?.label;

const myCallOffContentItem = (item) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel projectTitle'>{item?.title ?? '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='title-small itemValue'>{item?.callOffMechanism ? getPrettyCallOffMechanism(item.callOffMechanism) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{dashboard.CARD_PROPERTIES.estimatedValue}:</p>
        <p className='title-small itemValue'>£{item?.estimatedValue ?? '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{dashboard.CARD_PROPERTIES.stage}:</p>
        <p className='title-small itemValue'>{item?.currentStage ?? '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{dashboard.CARD_PROPERTIES.originalSolution}:</p>
        <p className='title-small itemValue ellipsis-on-unhover'>{item?.originalSolution ?? '-'}</p>
    </div>
</>;

const callOffContent = ({ content, toggle, history }) => {
    const callOffItems = content?.map((item, index) => {
        const url = `/calloff/overview/${item.projectID}`;
        return (<SupplierCard
            key={index}
            type={toggle && GeneralConstants.CALL_OFF}
            id={`callOff${index}`}
            header
            headerTitle={`${!toggle ? CALLOFF_CARD_TITLE : ''} #${item.projectReference}`}
            content={myCallOffContentItem(item)}
            actionBtnText={CALLOFF_CARD_ACTION}
            handlerActionBtn={() => history.push(url)}
            url = {url}
        />);
    });
    return callOffItems;
};

const myRequestContent = ({ content, history }) => {
    const requestItems = content.map((requestItem, index) => {
        const url = `/procurement-requests/overview/${requestItem.projectID}`;
        return (<SupplierCard
            key={index}
            id={`requestId${index}`}
            header
            caption={`Request # ${requestItem.projectReference ? requestItem.projectReference : '-'}`}
            headerTitle={requestItem.title}
            content={myRequestContentItem(requestItem)}
            actionBtnText={VIEW_REQUEST}
            handlerActionBtn={() => history.push(url)}
            url={url}
        />);
    });
    return requestItems;
};


export {
    taskEventContent,
    myProjectContent,
    myRequestContent,
    myContractContent,
    callOffContent,
    submitProcrumentRequest,
};
