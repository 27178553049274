import React, { useEffect, useRef } from 'react';
import Proptypes from 'prop-types';
import FocusTrap from 'focus-trap-react';
import Button from '../Button';
import closeIconBtn from '../../styles/icons/grey/close.svg';
import ScreenOverlay from '../ScreenOverlay';

const Modal = ({
    id,
    open,
    size,
    headerTitle,
    body,
    footer = true,
    handleMainActionBtnClick,
    mainActionButtonTxt,
    secondActionButtonTxt,
    secondActionButtonType,
    secondActionButton,
    handleSecondaryActionBtnClick,
    handleHelpActionBtnClick,
    helpBtnTxt,
    helpTxt,
    helpOption,
    closeModal,
    mainActionButtonDisable = false,
    allowCloseButton = true,
    classes = '',
}) => {
    const closeRef = useRef(null);
    const mainActionRef = useRef(null);
    const secondoryActionRef = useRef(null);

    useEffect(() => {
        function keyListener(e) {
            if (allowCloseButton && e.keyCode === 27) {
                closeModal();
            }
        }
        document.addEventListener('keydown', keyListener);
        return () => document.removeEventListener('keydown', keyListener);
    });

    useEffect(() => {
        closeRef?.current?.focus();
    }, []);

    const resetToCloseFocus = () => {
        setTimeout(() => closeRef?.current?.focus());
    };

    const resetToMainActionFocus = () => {
        setTimeout(() => mainActionRef?.current?.focus());
    };

    const resetToSecondaryActionFocus = () => {
        setTimeout(() => secondoryActionRef?.current?.focus());
    };

    /* Note : Please update the cases in case the footer/Close button Id changes */
    const checkKeyPress = (e) => {
        const { keyCode } = e;
        if (keyCode === 9) {
            if (e.shiftKey) {
                switch (e.target.id) {
                    case 'main-action-btn':
                    case 'main-only-action-btn':
                        resetToSecondaryActionFocus();
                        break;
                    case 'close':
                        resetToMainActionFocus();
                        break;
                    default:
                }
            } else {
                switch (e.target.id) {
                    case 'main-action-btn':
                    case 'main-only-action-btn':
                        resetToCloseFocus();
                        break;
                    default:
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', checkKeyPress);
        return () => {
            window.removeEventListener('keydown', checkKeyPress);
        };
    }, []);

    return (<>
        <ScreenOverlay classes={classes} />
        <FocusTrap active={open}>
            <div className={`modal-container ${open && 'open'} ${classes}`}>
                <div className={`modal ${size}`} id={id}>
                    <header className='modal-header'>
                        <p className='modal-header-title title-xLarge'>{headerTitle}</p>
                        {allowCloseButton && <button
                            ref={closeRef}
                            type='button'
                            className='modal-header-close-btn'
                            id='close'
                            onClick={closeModal}
                        >
                            <img src={closeIconBtn} className='modal-header-close-btn-img'
                                alt='modal-close-button'></img>
                        </button>}
                    </header>
                    <main id={`${id}_main`} className='modal-content'>{body}</main>
                    {footer && <footer className='modal-footer'>
                        {(secondActionButton)
                            ? <>
                                <Button id='second-action-btn'
                                    buttonReference={secondoryActionRef}
                                    type={secondActionButtonType}
                                    variant='secondary'
                                    label={secondActionButtonTxt}
                                    handleClick={handleSecondaryActionBtnClick} />
                                <Button id='main-action-btn'
                                    buttonReference={mainActionRef}
                                    label={mainActionButtonTxt}
                                    handleClick={handleMainActionBtnClick}
                                    disabled = {mainActionButtonDisable}/>
                            </>
                            : <><Button id='main-only-action-btn'
                                buttonReference={mainActionRef}
                                label={mainActionButtonTxt}
                                handleClick={handleMainActionBtnClick}
                                disabled = {mainActionButtonDisable}/>
                            {helpOption && <div id='helper-txt-container'>
                                <p className='caption-small'>{helpTxt}</p>
                                <button id='help-action-button' className='button links-small'
                                    onClick={handleHelpActionBtnClick}>{helpBtnTxt}</button>
                            </div>}
                            </>}
                    </footer>}
                </div>
            </div>
        </FocusTrap>
    </>);
};

Modal.propTypes = {
    id: Proptypes.string,
    open: Proptypes.bool.isRequired,
    size: Proptypes.string,
    headerTitle: Proptypes.string,
    body: Proptypes.oneOfType([
        Proptypes.string,
        Proptypes.object,
    ]),
    footer: Proptypes.bool,
    closeModal: Proptypes.func.isRequired,
    mainActionButton: Proptypes.bool,
    mainActionButtonTxt: Proptypes.string,
    handleMainActionBtnClick: Proptypes.func,
    secondActionButton: Proptypes.bool,
    secondActionButtonTxt: Proptypes.string,
    secondActionButtonType: Proptypes.string,
    handleSecondaryActionBtnClick: Proptypes.func,
    helpAction: Proptypes.bool,
    helpTxt: Proptypes.string,
    helpBtnTxt: Proptypes.string,
    handleHelpActionBtnClick: Proptypes.func,
    helpOption: Proptypes.bool.isRequired,
    mainActionButtonDisable: Proptypes.bool,
    allowCloseButton: Proptypes.bool,
    classes: Proptypes.string,
};

export default Modal;
