import React from 'react';
import PropTypes from 'prop-types';

const Form = ({
    id, className, onSubmit, children, autocomplete, noValidate = false,
}) => (
    <form
        className={`${className || ''} form`}
        id={id}
        autoComplete={autocomplete}
        onSubmit={(event) => onSubmit(event)}
        noValidate={noValidate}
    >
        {children}
    </form>
);

Form.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    autocomplete: PropTypes.string,
    noValidate: PropTypes.boolean,
};

export default Form;
