const browseCleverQuotesConstants = {
    noOfEmployees: 'Company Size: ',
    postcode: 'Postcode: ',
    localityName: 'Locality: ',

    btnSetCriteria: 'Set Criteria',
    btnViewSupplier: 'View Supplier',
    btnAddSupplier: 'Add Supplier',
    btnRemoveSupplier: 'Remove Supplier',
    btnRefineSelection: 'Refine Selection',
    btnBack: 'Back',
    btnConfirmSelection: 'Confirm Selection',
    btnAddSuppliers: 'Add Supplier(s)',

    toastSuccessAdding: 'Suppliers added to the opportunity successfully',
    toastErrorAdding: 'Error adding suppliers to the opportunity',
    suppliersNotFound: 'We\'re sorry! There are no suppliers that meet the criteria. You can set different criteria to get other results or manually add suppliers.',
    noticeRandomSuppliers: 'Set the criteria you want for the suppliers and the system will randomly select and recommend 3 for you to use.',
    errorSuppliersRegistered: 'You cannot run clever quotes since suppliers are already registered for this opportunity.',
    errorOnRetrieval: 'Unable to retrieve information.',
    CLEVER_QUOTES: 'clever-quotes',
};

export default browseCleverQuotesConstants;
