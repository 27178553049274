import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import arrowDown from '../../../styles/icons/white/chevron-down.svg';

const MenuItem = ({
    id,
    title,
    link,
    hasOptions,
    options,
    dropdownRef,
    dropdownOpen,
    dropdownHeight = '0px',
    handleDropdownClick,
    currentPathname,
}) => {
    const generateOptions = (data) => {
        const optionsHTML = [];
        data.forEach((el, idx) => {
            optionsHTML.push(<div key={`menuItem-option-${idx}`} className='dropdown-options-item'>
                <Link className='body-small' key={`menuItem-${el.name}-${idx}`} to={el.link}>
                    {el.name}
                </Link>
            </div>);
        });
        return optionsHTML;
    };

    return (<>
        {!hasOptions && <li className='item' id={id}
        >
            <Link className='item-link title' to={link}>{title}</Link>
            {currentPathname === link && <div className='item-active-strip'></div>}
        </li>}
        {(hasOptions) && <li className='item dropdown' id={id}
            onClick={(e) => handleDropdownClick(id, e)}
            onKeyPress={(e) => handleDropdownClick(id, e)}
        >
            <a className={dropdownOpen ? 'item-title active title' : 'item-title title'} tabIndex={0}>{title}</a>
            <img className='dropdown-item-arrow' src={arrowDown} alt='arrowDown' />
            {hasOptions && <div key={id}
                className='dropdown-options hidden'
                style={{ maxHeight: `${dropdownHeight}`, overflow: 'auto' }} ref={dropdownRef}
            >
                {generateOptions(options)}
            </div>}
        </li>}
    </>);
};

MenuItem.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    hasOptions: PropTypes.bool,
    options: PropTypes.array,
    dropdownRef: PropTypes.any,
    dropdownOpen: PropTypes.bool,
    dropdownHeight: PropTypes.string,
    handleDropdownClick: PropTypes.func,
    currentPathname: PropTypes.string,
};

export default MenuItem;
