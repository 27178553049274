import { useContext } from 'react';
import { ProjectDataContext } from '../context/ProjectDataContext';
import Toast from '../components/Alerts/Toast/Toast';
import ToastConstants from '../components/Alerts/Toast/constants';
import projectManagementAPIs from '../services/project-management.service';

const useGetOpportunity = () => {
    const { setProjectData } = useContext(ProjectDataContext);

    const getOpportunity = async (projectId) => {
        try {
            const response = await projectManagementAPIs.getOpportunityByProjectId(projectId);
            if (response.status === 200) {
                setProjectData({
                    opportunityId: response.data.opportunityID,
                    title: response.data.opportunityName,
                });
            } else {
                Toast.fire({
                    icon: ToastConstants.error,
                    titleText: ToastConstants.unableToRetrieveInfo,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: ToastConstants.unableToRetrieveInfo,
            });
        }
    };

    return { getOpportunity };
};

export default useGetOpportunity;
