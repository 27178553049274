import React, { useContext, useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import AuthContext from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext';

const PageHeader = ({ title, className }) => {
    const authContext = useContext(AuthContext);
    const { toggle } = useContext(ThemeContext);
    const [headerContent, setHeaderContent] = useState(<>
        <h1>{title}</h1>
    </>);
    useEffect(() => {
        const { authenticated, user } = authContext;
        if (authenticated && user.accountType
            && title === 'User Dashboard') {
            setHeaderContent(<>
                <h1>{`${user.firstName}\u00A0${user.lastName}`}</h1>
                <p id='userAccountName'>{user.accountName}</p>
            </>);
        }
        document.title = `${title ? `${title} - Open | Developed by Nepo` : 'Open | Developed by Nepo'}`;
    }, [authContext, title]);

    return <section id='pageHeader' className={`${className} ${toggle ? 'new-page-header' : ''}`}>
        {headerContent}
    </section>;
};

PageHeader.propTypes = {
    title: Proptypes.string,
    className: Proptypes.string,
    breadcrumbs: Proptypes.object,
};

export default PageHeader;
