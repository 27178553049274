const Constants = {
    sortOptions: [
        null,
        { label: 'Created on (newest)', value: 'systemTimestamp-descending-dateTime' },
        { label: 'Name (ascending)', value: 'templateName-ascending-string' },
        { label: 'Name (descending)', value: 'templateName-descending-string' },
    ],
    tabOptions: [
        'My Questionnaires',
        'My Department',
        'My Organisation',
        'All Organisations',
    ],
    questionnaires: 'Questionnaires',
    selectQuestionnaires: 'questionnairesselect',
    questionnairesToBeImported: 'Questionnaires to be imported will appear here.',
};

export default Constants;
