import React, {
    useRef, createRef, useState, useEffect, useContext,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import BurgerMenuToggleBtn from '../BurgerMenu/components/ToggleButton';
import logo from '../../styles/images/openLogo.png';
import logoNew from '../../styles/icons/white/open.svg';
import MenuItem from './MenuItem';
import ScreenOverlay from '../../components/ScreenOverlay';
import NotificationToggleButton from '../Notifications/components/NotificationToggleButton';
import Notifications from '../Notifications';
import Button from '../../components/Button';
import AuthContext from '../../context/AuthContext';
import UserNavMenu from '../UserNavMenu';
import UserNavMenuToggleButton from '../UserNavMenu/components/UserNavMenuToggleButton';
import { ThemeContext } from '../../context/ThemeContext';
import { LOGIN } from '../../config/constants';

const NavBar = ({
    toggleRegModal,
    isLoggedIn,
    firstName,
    menuData,
    userMenuData,
    handleBurgerMenuBtnClick,
    // eslint-disable-next-line no-unused-vars
    showLoginModal,
}) => {
    // @ TODO: Change showlogin modal.
    const location = useLocation();
    const history = useHistory();
    const [dropdownData, setDropdownData] = useState({});
    const [showOverlay, setShowOverlay] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showUserNavMenu, setShowUserNavMenu] = useState(false);
    const [menuDropdown, setMenuDropdown] = useState(false);
    const [menuDropdownOpenId, setMenuDropdownOpenId] = useState(null);
    const dropdownRef = useRef(menuData.map(() => createRef()));
    const authContext = useContext(AuthContext);
    const { toggle } = useContext(ThemeContext);
    const notificationsData = useSelector((state) => state.notifications.notifications);
    let getUnReadCount = 0;

    notificationsData?.forEach((data) => {
        if (!data.readStatus) {
            getUnReadCount += 1;
        }
    });

    const getLogo = () => {
        if (toggle) {
            return logoNew;
        }
        return logo;
    };

    const toggleDropdownMenu = (elementId, event) => {
        event.stopPropagation();
        setMenuDropdown(!menuDropdown);
        const itemSelected = elementId.split('-')[1];
        const currentDropdownRef = dropdownRef.current[itemSelected].current;
        if (!menuDropdown && currentDropdownRef) {
            currentDropdownRef.className = 'dropdown-options';
            setDropdownData({
                [itemSelected]: {
                    isOpen: true,
                    height: 'calc(100vh - 120px)',
                },
            });
            setMenuDropdownOpenId(elementId);
            if (showNotifications) {
                setShowNotifications(false);
            }
            if (showUserNavMenu) {
                setShowUserNavMenu(false);
            }
            setShowOverlay(true);
        } else {
            if (currentDropdownRef) {
                currentDropdownRef.className = 'dropdown-options hidden';
            }
            setDropdownData({
                [itemSelected]: {
                    isOpen: false,
                    height: '0px',
                },
            });
            setMenuDropdownOpenId(null);
            setShowOverlay(false);
        }
    };

    const toggleNotificationsDropdown = (event) => {
        event?.stopPropagation();
        if (showUserNavMenu) {
            setShowUserNavMenu(false);
        }
        if (menuDropdownOpenId !== null) {
            toggleDropdownMenu(menuDropdownOpenId, event);
        }
        setShowNotifications(!showNotifications);
        setShowOverlay(!showNotifications);
    };

    const toggleUserNavMenuDropdown = (event) => {
        if (!event) {
            return;
        }
        event.stopPropagation();

        if (showNotifications) {
            setShowNotifications(false);
        }
        if (menuDropdownOpenId !== null) {
            toggleDropdownMenu(menuDropdownOpenId, event);
        }
        setShowUserNavMenu(!showUserNavMenu);
        setShowOverlay(!showUserNavMenu);
    };

    useEffect(() => {
        const clickDropdown = (e) => {
            switch (true) {
                case authContext.user.accountType === 'supplier' && e.target.innerText === 'Contracts' && e.key === 'Tab':
                    toggleDropdownMenu(menuDropdownOpenId, e);
                    break;
                case authContext.user.accountType === 'supplier' && e.key === 'Tab' && e.shiftKey === true && (e.target.innerText === 'Opportunities' || e.target.innerText === 'Browse'):
                    toggleDropdownMenu(menuDropdownOpenId, e);
                    break;
                case e.key === 'Tab' && e.shiftKey === true && (e.target.innerText === 'Procurement Requests' || e.target.innerText === 'Browse'):
                    toggleDropdownMenu(menuDropdownOpenId, e);
                    break;
                case e.target.innerText === 'Call Off' && e.key === 'Tab':
                    toggleDropdownMenu(menuDropdownOpenId, e);
                    break;
                default:
                    break;
            }

            if (e.key && e.key !== 'Escape') {
                return [];
            }

            if (menuDropdownOpenId !== null) toggleDropdownMenu(menuDropdownOpenId, e);
            window.removeEventListener('click', clickDropdown);
            window.removeEventListener('keydown', clickDropdown);
            return [];
        };

        const clickNotifications = (e) => {
            switch (true) {
                case e.key === 'Tab' && e.shiftKey === true && e.target.id === 'notificationToggleButton':
                    toggleNotificationsDropdown();
                    window.removeEventListener('click', clickNotifications);
                    window.removeEventListener('keydown', clickNotifications);
                    break;
                case e.key !== 'Enter' && (e.target.id === 'markAllAsReadBtn' || e.target.id === 'closeBtn'):
                    toggleNotificationsDropdown();
                    window.removeEventListener('click', clickNotifications);
                    window.removeEventListener('keydown', clickNotifications);
                    break;
                default:
                    break;
            }

            if (e.key && e.key !== 'Escape') {
                return [];
            }

            if (showNotifications !== null) toggleNotificationsDropdown();
            window.removeEventListener('click', clickNotifications);
            window.removeEventListener('keydown', clickNotifications);
            return [];
        };

        const clickUserNavMenu = (e) => {
            switch (true) {
                case e.key === 'Tab' && e.shiftKey === true && (e.target.innerText === 'My Profile' || e.target.id === 'userNavMenuToggleButton'):
                    toggleUserNavMenuDropdown(e);
                    window.removeEventListener('click', clickUserNavMenu);
                    window.removeEventListener('keydown', clickUserNavMenu);
                    break;
                case e.key !== 'Enter' && e.target.innerText === 'Logout':
                    toggleUserNavMenuDropdown(e);
                    window.removeEventListener('click', clickUserNavMenu);
                    window.removeEventListener('keydown', clickUserNavMenu);
                    break;
                default:
                    break;
            }

            if (e.key && e.key !== 'Escape') {
                return [];
            }
            if (showNotifications !== null) toggleUserNavMenuDropdown(e);
            window.removeEventListener('click', clickUserNavMenu);
            window.removeEventListener('keydown', clickUserNavMenu);
            return [];
        };

        if (menuDropdownOpenId !== null) {
            window.addEventListener('click', clickDropdown);
            window.addEventListener('keydown', clickDropdown);
        }

        if (showNotifications) {
            window.addEventListener('click', clickNotifications);
            window.addEventListener('keydown', clickNotifications);
        }

        if (showUserNavMenu) {
            window.addEventListener('click', clickUserNavMenu);
            window.addEventListener('keydown', clickUserNavMenu);
        }
    }, [menuDropdownOpenId, showNotifications, showUserNavMenu]);

    const createMenuItemsHTML = () => {
        const menuItemsHTML = [];
        menuData.forEach(((data, idx) => {
            menuItemsHTML.push(<MenuItem key={idx}
                id={`menuItem-${idx}`}
                title={data.title}
                link={data.link}
                hasOptions={data.hasOptions}
                options={data.options}
                dropdownRef={dropdownRef.current[idx]}
                dropdownOpen={dropdownData[idx] && dropdownData[idx].isOpen}
                dropdownHeight={dropdownData[idx] && dropdownData[idx].height}
                handleDropdownClick={(id, event) => toggleDropdownMenu(id, event)}
                currentPathname={location.pathname}
            />);
        }));
        return menuItemsHTML;
    };

    const handleRedirect = () => {
        if (authContext.authenticated) {
            history.push('/dashboard');
        } else {
            history.push('/');
        }
    };

    return (
        <>
            <header className={`navbar remove-on-print ${toggle ? 'new-navbar' : ''} ${isLoggedIn && 'logged-navbar'}`}>
                <nav className='navbar-primary-navigation'>
                    <div className='logo' onClick={handleRedirect}>
                        <img src={getLogo()} alt='logo' />
                        {/* once out of beta, this block can be removed. */}
                        <p className='betabox caption-small'>BETA</p>
                        <p className='betatext body-xSmall'>This is a new service. Your <a href='/contact'>feedback</a> can help to improve it.</p>
                    </div>
                    <a id='skipper' href='#main' className='caption'>skip to main content</a>
                    <div id='navMenuItems'>
                        <div id='additionalItems'>
                            {!isLoggedIn ? <>
                                <Button id='loginBtn'
                                    label= {LOGIN}
                                    variant={'secondary'}
                                    additionalVariant={'white skinless'}
                                    handleClick={() => history.push('/login')} />
                                <Button id='getStartedBtn'
                                    label='Get Started'
                                    variant='primary'
                                    additionalVariant='white'
                                    handleClick={() => toggleRegModal(true)} /></>
                                : <>
                                    <><div id='notificationWrapper'>
                                        <NotificationToggleButton
                                            handleToggleBtnClick={toggleNotificationsDropdown}
                                            numberOfNotifications={getUnReadCount || 0} />
                                        {showNotifications && <Notifications
                                            notificationsData={notificationsData} />}</div>
                                    </>
                                    <><div id='userNavMenuWrapper'>
                                        <UserNavMenuToggleButton
                                            handleToggleBtnClick={toggleUserNavMenuDropdown}
                                            firstName={firstName} />
                                        {showUserNavMenu && <UserNavMenu
                                            handleToggleBtnClick={toggleUserNavMenuDropdown}
                                            userMenuData={userMenuData} />}</div>
                                    </>
                                </>}
                        </div>
                        <BurgerMenuToggleBtn handleToggleBtnClick={handleBurgerMenuBtnClick} />
                    </div>
                </nav>
                <nav className='navbar-secondary-navigation remove-on-print'>
                    {/* Once out of beta, this block can be removed. */}
                    <div className='betabar'>
                        <p className='betabox caption-small'>BETA</p>
                        <p className='betatext body-xSmall'>This is a new service. Your <a href='/contact'>feedback</a> can help to improve it.</p>
                    </div>
                    <ul className='items'>
                        {createMenuItemsHTML()}
                    </ul>
                </nav>
            </header>
            {showOverlay && <ScreenOverlay />}
        </>
    );
};

NavBar.propTypes = {
    isLoggedIn: PropTypes.bool,
    firstName: PropTypes.string,
    menuData: PropTypes.array,
    userMenuData: PropTypes.array,
    handleBurgerMenuBtnClick: PropTypes.func,
    showLoginModal: PropTypes.func,
    toggleRegModal: PropTypes.func,
};

export default NavBar;
