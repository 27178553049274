const Constants = {
    current: 'current',
    upcoming: 'upcoming',
    past: 'past',
    pending: 'pending',
    inProgress: 'in-progress',
    stage1approval: 'stage1approval',
    stage2approval: 'stage2approval',
    complete: 'complete',
    approved: 'approved',
    rejected: 'rejected',
    documents: [
        {
            name: 'Terms & Conditions',
            btnLabel: 'View',
        },
        {
            name: 'Pricing Schedule',
            btnLabel: 'View',
        },
        {
            name: 'Selection Questionnaire',
            btnLabel: 'View',
        },
    ],

    managementDocuments: [
        {
            name: 'Rebate Template',
            btnLabel: 'Download',
        },
        {
            name: 'Rebate Uploads',
            btnLabel: 'View',
        },
        {
            name: 'KPI Questions Template',
            btnLabel: 'Download',
        },
        {
            name: 'KPI Uploads',
            btnLabel: 'View',
        },
    ],
    NO_ASIGNED_TASK_AVAILABLE: 'You don\'t have any assigned tasks and events in-progress or pending.',
    NO_OPPORTUNITIES_AVAILABLE: 'No suggested opportunities found at the moment.',
    NO_CONTRACTS_AVAILABLE: 'No contracts available.',
    NO_ACCOUNTS_AWAITING_APPROVAL_AVAILABLE: 'No accounts awaiting approval available.',
    NO_USERS_AWAITING_APPROVAL_AVAILABLE: 'There are no open user requests.',
    NO_SUGGESTED_OPPORTUNITIES_AVAILABLE: 'No suggested opportunities available.',
    BUYER: 'Buyer',
    ESTIMATED_VALUE: 'Estimated Value',
    RIGOUR_LEVEL: 'Rigour Level',
    REGISTER_INTEREST_BY: 'Register interest by',
    VIEW_OPPORTUNITY: 'View Opportunity',
    VALUE: 'Value',
    CURRENT_STAGE: 'Current Stage',
    VIEW_CONTRACT: 'View Contract',
    NO_PENDING_ACCOUNT_REQUESTS: 'No pending open account requests.',
    NO_PENDING_USER_REQUESTS: 'No pending open user requests.',
    REQUEST_FOR_REGISTRATION_COMPANY_APPROVAL: 'Request for registration is now with your company admin for approval.',
    YOU_WILL_EMAIL_WHEN_ACCESS: 'We will email you as soon as you have access to your company’s account on Open.',
    YOU_REQUEST_TO_JOIN_ACCOUNT_REJECTED: 'Your request to join this account has been rejected by your company admin. Please contact them directly or register under a separate company account.',

};

export default Constants;
