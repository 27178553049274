import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../components/Button';
import userIcon from '../../styles/icons/white/user-2.svg';
import dpsConstants from './constants';
import helperFunctions from '../../utils/helperFunctions';
import {
    getPrettyQuestionnaireType,
    getLineLabels,
    getLineButtons,
    getElementWithUniqueId,
    isActionNeededInSubmission,
    isActionNeededInLot,
} from '../../services/award.service';
import {
    labelArrayResponseScore,
    labelArraySubmissionScore,
    buttonObjectViewEvaluateQuestionnaire,
    getAwardApprovalAction,
    labelObjectAction,
    labelActionNeeded,
    labelObjectIntentStatus,
    buttonsSuccessfulUnSuccesfulLots,
    labelObjectActionNeeded,
    buttonsEditSuccessfulUnSuccesfulLots,
} from './summaryBuyerAwardDPSConfig';
import { intentStatuses } from '../../config/constants';
import SupplierDataUpload from '../SummaryBuyerAward/components/SupplierDataUpload';

const generateDPSSolutionHeaderButtons = (awardInfo, history) => (
    <div className='sbContentHeader'>
        <div className='headerEvaluationContainer'>
            {awardInfo?.awardLetterSent
                ? <>
                    <p className='body'>{dpsConstants.AWARDS_SENT_SUCCESSFULLY}</p>
                    <div className='headerButtonContainer'>
                        {awardInfo?.solutionID
                    && <Button
                        id={'viewSolution'}
                        key={uuidv4()}
                        label={dpsConstants.VIEW_SOLUTION}
                        variant={'secondary'}
                        handleClick={() => history.push(`/solutions/overview/${awardInfo?.solutionID}`)}
                    />}
                        {awardInfo?.contractID
                    && <Button
                        id={'viewContract'}
                        key={uuidv4()}
                        label={dpsConstants.VIEW_CONTRACT}
                        variant={'primary'}
                        handleClick={() => history.push(`/contracts/management/${awardInfo?.contractID}`)}
                    />}
                    </div>
                </>
                : <Button
                    id={'back'}
                    key={uuidv4()}
                    size={'small'}
                    label={dpsConstants.BACK}
                    variant={'secondary'}
                    handleClick={() => history.goBack()}
                />}
        </div>
    </div>
);

const getSupplierLabel = (value) => (
    <p className='title'>
        <img src={userIcon} alt={dpsConstants.USER} id={dpsConstants.USER_ICON} className='user-icon'></img>{value}</p>
);


const getResponsesMenu = (readOnly, response, id) => {
    const menu = [];
    const responseLabels = labelArrayResponseScore;
    const responseButtons = [buttonObjectViewEvaluateQuestionnaire];

    if (responseLabels?.length) {
        menu.push(...getLineLabels(responseLabels, response, id));
    }
    if (responseButtons?.length) {
        menu.push(...getLineButtons(readOnly, responseButtons, response, id));
    }

    return menu;
};

const getResponses = (readOnly, responses, idLot) => (responses
    .reduce((acc, response, index) => {
        const id = `${idLot }-response-${ index}`;
        return acc.concat({
            key: uuidv4(),
            id,
            openState: true,
            label: `${response.templateName} ${response.responseType ? getPrettyQuestionnaireType(response.responseType) : ''}`,
            menu: getResponsesMenu(readOnly, response, id),
        });
    }, []));

const getLotsMenu = (readOnly, lot, id, isCurrentSubmission, awardInfo, showEditButton) => {
    const menu = [];

    const lotLabels = [
        ...(isCurrentSubmission && isActionNeededInLot(lot, awardInfo)
            ? [labelObjectActionNeeded] : []),
        ...(lot.intentStatus ? [labelObjectIntentStatus(lot.intentStatus)] : []),
        ...labelArraySubmissionScore];

    let lotButtons = [];

    const isLotStatusAlreadySet = lot.intentStatus === intentStatuses.successful
        || lot.intentStatus === intentStatuses.unsuccessful;

    const isEditButtonVisible = (showEditButton?.show && !showEditButton?.lotId)
        || (showEditButton?.lotId !== lot.lotID);

    if (!lot.awardLetterSent && isCurrentSubmission) {
        lotButtons = [
            ...(isEditButtonVisible)
               && isLotStatusAlreadySet
                ? buttonsEditSuccessfulUnSuccesfulLots
                : buttonsSuccessfulUnSuccesfulLots,
        ];
    }

    if (lotButtons?.length) {
        menu.push(...getLineButtons(readOnly, lotButtons, lot, id));
    }
    if (lotLabels?.length) {
        menu.push(...getLineLabels(lotLabels, lot, id));
    }
    return menu;
};

const getViewAwardLettersButton = (idTree, submission, awardInfo, steeringInfo) => ({
    key: uuidv4(),
    id: `${idTree}-prev-submissions`,
    label: <div className='prev-submissions-sent'>
        <SupplierDataUpload
            key = {uuidv4()}
            dataSet = {awardInfo}
            data = {submission}
            userSteeringInfo = {steeringInfo}
            isDPS = {true}
            readOnly = {true}
            isPreviousSubmission={true}/>
    </div>,
});

const getLots = (readOnly, submission, idTree,
    isCurrentSubmission, supplierId, awardInfo, steeringInfo, showEditButton) => {
    const lotsTree = submission?.lotsOrdered
        ?.reduce((acc, lot, index) => {
            const id = `${ idTree }-lot-${ index}`;
            const clonedLot = { ...lot, supplierAccountID: supplierId };
            return acc.concat({
                key: uuidv4(),
                id,
                openState: !!isCurrentSubmission,
                label: clonedLot.title,
                nodes: getResponses(readOnly, clonedLot.responses, id, isCurrentSubmission),
                menu: getLotsMenu(readOnly, clonedLot, id, isCurrentSubmission,
                    awardInfo, showEditButton),
            });
        }, []);

    if (!isCurrentSubmission && submission?.docs?.length) {
        lotsTree.push(getViewAwardLettersButton(idTree, submission, awardInfo, steeringInfo));
    }

    return lotsTree;
};

const getSubmissionLabel = (submissionNumber, submission) => (
    <p className='submission-label'>
        {submission.isCurrentSubmission ? `${dpsConstants.CURRENT_TITLE} ` : ''}{`${dpsConstants.SUBMISSION_TITLE} #${submissionNumber}`}
        <span>{dpsConstants.SUBMITTED_AT}: {helperFunctions.formatPrettyDateTime(submission
            .submissionDate)}</span>
    </p>
);

const getPreviousSubmissions = (
    readOnly, supplier, idSupplier, awardInfo, steeringInfo,
) => {
    const previousSubmissions = { ...supplier.previousSubmissions || {} };
    const previousSubmissionsLength = Object.entries(previousSubmissions).length;

    return Object.entries(previousSubmissions).reverse().reduce((acc, [, submission], index) => {
        const id = `${ idSupplier }-submission-${ previousSubmissionsLength - index}`;
        const numberOfSubmission = previousSubmissionsLength - index;
        return acc.concat({
            key: uuidv4(),
            id,
            label: getSubmissionLabel(numberOfSubmission, submission),
            nodes: getLots(readOnly, submission, idSupplier, false, id, awardInfo, steeringInfo),
        });
    }, []);
};

const getSupplierOrSubmissionsMenu = (readOnly, lot, userPermissions, id, awardInfo) => {
    const menu = [];
    if (!readOnly) {
        const action = getAwardApprovalAction(lot?.awardApproval);
        if (action) {
            const userHasPermission = userPermissions[action.permission];
            const actionLabel = labelObjectAction(action, userHasPermission);
            menu.push(getElementWithUniqueId(actionLabel, id));
        } else if (isActionNeededInSubmission(awardInfo, lot)) {
            menu.push(getElementWithUniqueId(labelActionNeeded, id));
        }
    }
    return menu;
};


const getCurrentSubmission = (
    readOnly, supplier, idTree, userPermissions, awardInfo, showEditButton,
) => {
    const { currentSubmission } = supplier;
    const numberOfSubmission = Object.entries(supplier.previousSubmissions || {}).length + 1;
    const id = `supplier-${ idTree }-submission-${ numberOfSubmission}`;
    return [{
        key: uuidv4(),
        id,
        openState: true,
        label: getSubmissionLabel(numberOfSubmission, currentSubmission),
        nodes: getLots(readOnly, currentSubmission, id, true,
            supplier.accountID, awardInfo, undefined, showEditButton),
    }];
};

const getSupplierTree = (supplierInfo, index, openNodes) => {
    const {
        menu, label, tree, supplier,
    } = supplierInfo;
    return [{
        key: uuidv4(),
        id: `tree-supplier-${ index}`,
        elementId: supplier?.accountID,
        label,
        menu,
        openState: openNodes?.includes(supplier?.accountID) && menu?.length > 0,
        nodes: [{
            key: uuidv4(),
            id: `supplier-submissions-${ index}`,
            label: tree,
            openState: menu?.length > 0,
        }],
    }];
};

const getFirstLotIdCurrentSubmission = (currentSubmission) => currentSubmission
    ?.lotsOrdered?.[0]?.lotID;

export {
    generateDPSSolutionHeaderButtons,
    getCurrentSubmission,
    getPreviousSubmissions,
    getSupplierLabel,
    getSupplierOrSubmissionsMenu,
    getSupplierTree,
    getFirstLotIdCurrentSubmission,
};
