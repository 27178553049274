import { useContext } from 'react';
import { ProjectDataContext } from '../context/ProjectDataContext';
import Toast from '../components/Alerts/Toast/Toast';
import ToastConstants from '../components/Alerts/Toast/constants';
import projectManagementAPIs from '../services/project-management.service';

const useGetProjectTitle = () => {
    const { setProjectData } = useContext(ProjectDataContext);

    const getProjectTitle = async (projectId) => {
        try {
            const res = await projectManagementAPIs.getProjectTitle(projectId);
            if (res.status === 200) {
                setProjectData({ title: res.data });
            } else {
                Toast.fire({
                    icon: ToastConstants.error,
                    titleText: ToastConstants.unableToRetrieveInfo,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: ToastConstants.unableToRetrieveInfo,
            });
        }
    };

    return { getProjectTitle };
};

export default useGetProjectTitle;
