import projectManagementAPIs from '../../services/project-management.service';
import userManagementAPIs from '../../services/user-management.service';
import {
    userRoles,
    SKELETON_MODES,
    LIMIT_3,
    LIMIT_5,
} from '../../config/constants';
import Constants from './constants';

const DASHBOARD_KEYS = {
    TASKS_AND_EVENTS: 'getDashboardTasksAndEvents',
    OPPORTUNITIES: 'getDashboardOpportunities',
    CONTRACTS: 'getDasboardContracts',
    ACCOUNTS_AWAITING_APPROVAL: 'getDashboardAccountsAwaitingApproval',
    USERS_AWAITING_APPROVAL: 'getDashboardUsersAwaitingApproval',
    SUGGESTED_OPORTUNITIES: 'getDashboardSuggestedOpportunities',
};

const DASHBOARD_OPTIONS = [
    {
        method: DASHBOARD_KEYS.TASKS_AND_EVENTS,
        userRole: [userRoles.basic, userRoles.intermediate, userRoles.advanced, userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: Constants.NO_ASIGNED_TASK_AVAILABLE,
        limit: LIMIT_5,
        mode: SKELETON_MODES.ROW,
    },
    {
        method: DASHBOARD_KEYS.OPPORTUNITIES,
        userRole: [userRoles.basic, userRoles.intermediate, userRoles.advanced, userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: Constants.NO_OPPORTUNITIES_AVAILABLE,
        limit: LIMIT_3,
        mode: SKELETON_MODES.COLUMN,
    },
    {
        method: DASHBOARD_KEYS.CONTRACTS,
        userRole: [userRoles.basic, userRoles.intermediate, userRoles.advanced, userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: Constants.NO_CONTRACTS_AVAILABLE,
        limit: LIMIT_3,
        mode: SKELETON_MODES.COLUMN,
    },
    {
        method: DASHBOARD_KEYS.ACCOUNTS_AWAITING_APPROVAL,
        userRole: [userRoles.admin],
        microservice: userManagementAPIs,
        emptyContent: Constants.NO_ACCOUNTS_AWAITING_APPROVAL_AVAILABLE,
        limit: LIMIT_5,
        mode: SKELETON_MODES.ROW,
    },
    {
        method: DASHBOARD_KEYS.USERS_AWAITING_APPROVAL,
        userRole: [userRoles.admin],
        microservice: userManagementAPIs,
        emptyContent: Constants.NO_USERS_AWAITING_APPROVAL_AVAILABLE,
        limit: LIMIT_5,
        mode: SKELETON_MODES.ROW,
    },
    {
        method: DASHBOARD_KEYS.SUGGESTED_OPORTUNITIES,
        userRole: [userRoles.admin],
        microservice: projectManagementAPIs,
        emptyContent: Constants.NO_SUGGESTED_OPPORTUNITIES_AVAILABLE,
        limit: LIMIT_5,
        mode: SKELETON_MODES.COLUMN,
    },

];


export { DASHBOARD_OPTIONS, DASHBOARD_KEYS };
