const Constants = {
    SUCCESFULLY_ADDED_SUPPLIER: 'Successfully added supplier.',
    SUPPLIER_ALREADY_REGISTERED: 'Supplier already registered.',
    FAILED_TO_ADD_SUPPLIER: 'Failed to add supplier.',
    ADD_SUPPLIER: 'Add Supplier',
    CONFIRM: 'Confirm',
    REFINE: 'Refine',
    REMOVE_SUPPLIER: 'Remove Supplier',
    SUPPLIER_SELECTED: 'Supplier Selected',


};

export default Constants;
