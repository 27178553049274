import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DocumentBuilder from '../../../features/DocumentBuilder';
import projectManagementAPIs from '../../../services/project-management.service';
import helperFunctions from '../../../utils/helperFunctions';
import Toast from '../../../components/Alerts/Toast/Toast';
import AuthContext from '../../../context/AuthContext';
import {
    ERROR_RETRIEVING_DIFF_INFO, QUESTIONNAIRE, accountTypes,
} from '../../../config/constants';
import responseVersionsManagementAPIs from '../../../services/response-versions.service';
import useGetOpportunity from '../../../hooks/useGetOpportunity';

const ViewAnsweredQuestionnaire = () => {
    const history = useHistory();
    const { responseId } = useParams();
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const [structureToCompare, setStructureToCompare] = useState();

    const authContext = useContext(AuthContext);
    const { getOpportunity } = useGetOpportunity();

    const getResponseVersion = async (idTemplate) => {
        const responseData = await responseVersionsManagementAPIs.getResponseVersion(idTemplate);
        return responseData;
    };


    useEffect(() => {
        const fetchData = async () => {
            if (authContext.user.accountType === accountTypes.supplier) {
                const responseData = await projectManagementAPIs.getResponse(responseId);
                if (responseData.status === 200) {
                    const preparedData = helperFunctions
                        .prepareDocumentBuilderState(QUESTIONNAIRE, responseData.data, responseId);
                    setInitialState(preparedData);
                    setType(preparedData.details.responseType);
                    getOpportunity(preparedData.projectId);
                } else {
                    Toast.fire({
                        icon: 'error',
                        titleText: 'Unable to retrieve information.',
                    });
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'You do not have permission to view this content.',
                });
                history.push('/dashboard');
            }
        };
        fetchData();
    }, [responseId]);

    const handleSubmit = async () => {
        const payload = {
            status: 'inProgress',
        };
        const response = await projectManagementAPIs.updateResponseStatus(responseId, payload);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Answers updated successfully.',
            });
            history.replace(`/questionnaires/answer/${responseId}`);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update answers.',
            });
        }
    };


    const handleShowDiffsInViewer = async (versionId) => {
        const responseData = await getResponseVersion(versionId);
        if (responseData.status === 200) {
            const oldPreparedVersion = helperFunctions
                .prepareDocumentBuilderState(QUESTIONNAIRE, responseData.data, versionId);
            setStructureToCompare(helperFunctions
                .prepareVersionsToCompare(QUESTIONNAIRE, oldPreparedVersion, initialState));
        } else {
            Toast.fire({
                icon: 'error',
                titleText: ERROR_RETRIEVING_DIFF_INFO,
            });
        }
    };

    return Object.keys(initialState).length > 0
        ? <DocumentBuilder
            documentMode={'viewanswer'}
            context='Questionnaire'
            type={type}
            initialDocumentState={initialState}
            sectionLabel='Section'
            sectionItemLabel='Question'
            allowSectionReordering={false}
            allowSectionItemsReordering={false}
            sectionDetailsUserList={[]}
            handlePrimaryToolbarSubmitButton={handleSubmit}
            showVersionDropdown
            handleShowDiffsInViewer={handleShowDiffsInViewer}
            structureToCompare={structureToCompare}
        />
        : <></>;
};

export default ViewAnsweredQuestionnaire;
