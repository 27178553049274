import { treeTypes } from '../../components/TreeViewer/index';
import {
    evaluationStatuses,
    evaluationStatusesLabels,
    evaluationStatusesColor,
    view,
    ID,
    COMPANY_NAME,
    DISQUALIFY_REASON,
    IS_SUPPLIER_DISQUALIFIED,
    HANDLE_DISQUALIFY,
    HANDLE_EVALUATE,
    HANDLE_SHOW_REASON_MODAL,
    HANDLE_STATUS_UPDATE,
    QUESTIONNAIRE_VIEW_EVALUATE_URL,
    QUESTIONNAIRE_EVALUATE_URL,
    RESPONSE_ID,
} from '../../config/constants';
import { getAwardStatusColor, getAwardStatusLabel } from '../../services/award.service';
import actions from '../../rbac/constants';
import dpsConstants from './constants';
import roles from '../../rbac/roles';

// LABELS
const labelObjectDisqualifySupplier = {
    id: dpsConstants.labelDisqualifySupplierId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: evaluationStatusesColor.red,
    labelTxt: dpsConstants.DISQUALIFIED,
};
const labelObjectIncompleteEvaluations = {
    id: dpsConstants.labelIncompleteEvaluationsId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: evaluationStatusesColor.red,
    labelTxt: dpsConstants.INCOMPLETE_EVALUATIONS,
};
const labelArrayResponseScore = [{
    id: dpsConstants.labelResponseScoreId,
    type: treeTypes.MENU_OPTIONS.TEXT_LABEL,
    showIfReadOnly: false,
}, {
    id: dpsConstants.labelResponsePassFailResultId,
    type: treeTypes.MENU_OPTIONS.TEXT_LABEL,
    showIfReadOnly: false,
}, {
    id: dpsConstants.labelResponseStatusId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: evaluationStatusesColor.green,
    labelTxt: dpsConstants.EVALUATION_COMPLETE,
    showIfReadOnly: false,
}];

const labelObjectIntentStatus = (intentStatus) => ({
    id: dpsConstants.labelIntentStatusId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: getAwardStatusColor(intentStatus),
    labelTxt: getAwardStatusLabel(intentStatus),
});

const labelObjectSubmissionStatus = (colour, text) => ({
    id: dpsConstants.labelSubmissionStatusId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: colour,
    labelTxt: text,
    showIfReadOnly: false,
    showIfPreviousSubmission: false,
});

const labelObjectAction = (action, userHasPermission) => ({
    id: dpsConstants.labelActionId,
    type: treeTypes.MENU_OPTIONS.STATUS_LABEL,
    color: action.values[userHasPermission].color,
    labelTxt: action.values[userHasPermission].label,
});
const labelArraySubmissionScore = [
    {
        id: dpsConstants.labelSubmissionScoreId,
        type: treeTypes.MENU_OPTIONS.TEXT_LABEL,
    }, {
        id: dpsConstants.labelSubmissionPassFailResultId,
        type: treeTypes.MENU_OPTIONS.TEXT_LABEL,
    },
];

// BUTTONS
const buttonObjectViewEvaluateQuestionnaire = {
    id: dpsConstants.buttonViewEvaluateQuestionnaireId,
    label: view,
    additionalVariant: 'small view-link',
    clickType: treeTypes.CLICK_TYPE.URL,
    onClick: QUESTIONNAIRE_VIEW_EVALUATE_URL,
};

const buttonObjectDisqualifySupplier = {
    id: dpsConstants.buttonDisqualifySupplierId,
    label: dpsConstants.DISQUALIFY_SUPPLIER,
    variant: 'primary',
    additionalVariant: evaluationStatusesColor.red,
    clickType: treeTypes.CLICK_TYPE.EVENT,
    onClickOptions: [HANDLE_DISQUALIFY, COMPANY_NAME, ID],
    showIfReadOnly: false,
};

const buttonObjectEvaluateQuestionnaire = {
    id: dpsConstants.buttonEvaluateQuestionnaireId,
    label: dpsConstants.EVALUATE,
    additionalVariant: 'small',
    clickType: treeTypes.CLICK_TYPE.EVENT,
    onClickOptions: [
        HANDLE_EVALUATE,
        RESPONSE_ID,
    ],
    showIfReadOnly: false,
};

const buttonObjectEditScoreQuestionnaire = {
    id: dpsConstants.buttonEditScoreQuestionnaireId,
    label: dpsConstants.EDIT_SCORE,
    additionalVariant: 'edit-link',
    clickType: treeTypes.CLICK_TYPE.EVENT,
    onClick: QUESTIONNAIRE_EVALUATE_URL,
    showIfReadOnly: false,
};

const buttonObjectResumeQuestionnaire = {
    id: dpsConstants.buttonResumeQuestionnaireId,
    label: dpsConstants.RESUME,
    additionalVariant: 'small',
    clickType: treeTypes.CLICK_TYPE.URL,
    onClick: QUESTIONNAIRE_EVALUATE_URL,
    showIfReadOnly: false,
};

const buttonObjectSubmitForApproval = {
    id: dpsConstants.buttonSubmitForApprovalId,
    label: dpsConstants.SUBMIT_FOR_APPROVAL,
    clickType: treeTypes.CLICK_TYPE.EVENT,
    onClickOptions: [HANDLE_STATUS_UPDATE,
        evaluationStatuses.evaluationComplete,
        null,
        ID],
    showIfReadOnly: false,
};

const buttonObjectStageReject = (stage, action) => ({
    id: dpsConstants.buttonStageRejectId,
    label: dpsConstants.REJECT,
    variant: 'primary',
    additionalVariant: evaluationStatusesColor.red,
    clickType: treeTypes.CLICK_TYPE.EVENT,
    onClickOptions: [HANDLE_STATUS_UPDATE,
        stage,
        action,
        ID,
    ],
    showIfReadOnly: false,
});

const buttonObjectStageApprove = (stage, action) => ({
    id: dpsConstants.buttonStageApproveId,
    label: dpsConstants.APPROVE,
    clickType: treeTypes.CLICK_TYPE.EVENT,
    onClickOptions: [HANDLE_STATUS_UPDATE,
        stage,
        action,
        ID],
    showIfReadOnly: false,
});

const buttonObjectViewReason = ({
    id: dpsConstants.buttonViewReasonId,
    label: dpsConstants.VIEW_REASON,
    additionalVariant: 'small',
    clickType: treeTypes.CLICK_TYPE.EVENT,
    onClickOptions: [HANDLE_SHOW_REASON_MODAL, DISQUALIFY_REASON],
});

// LOGIC OPTIONS
const evaluationStatusOptions = [{
    value: evaluationStatuses.sealed,
    label: evaluationStatusesLabels.sealed,
    colour: evaluationStatusesColor.red,
    submissionStatusLabel: labelObjectSubmissionStatus(evaluationStatusesColor
        .red, evaluationStatusesLabels.sealed),
}, {
    value: evaluationStatuses.released,
    label: evaluationStatusesLabels.released,
    colour: evaluationStatusesColor.green,
    submissionStatusLabel: labelObjectSubmissionStatus(evaluationStatusesColor
        .green, evaluationStatusesLabels.released),
    submissionButtons: [{
        permission: actions.disqualifySuppliers,
        buttons:
           [buttonObjectDisqualifySupplier],
    }],
    showResponseStatusLabel: false,
    responseButtons: [
        {
            permission: IS_SUPPLIER_DISQUALIFIED,
            buttons: [buttonObjectViewReason],
        },
        {
            permission: actions.evaluateQuestionnaire,
            buttons: [buttonObjectEvaluateQuestionnaire],
        }, {
            permission: actions.viewEvaluateQuestionnaire,
            buttons: [buttonObjectViewEvaluateQuestionnaire],
        },
    ],
}, {
    value: evaluationStatuses.notStarted,
    label: evaluationStatusesLabels.notStarted,
    colour: evaluationStatusesColor.green,
    action: {
        permission: actions.evaluateQuestionnaire,
        values: {
            true: {
                label: dpsConstants.ACTION_NEEDED,
                color: evaluationStatusesColor.red,
            },
            false: {
                label: dpsConstants.EVALUATION_PENDING,
                color: evaluationStatusesColor.amber,
            },
        },
    },
    submissionStatusLabel: labelObjectSubmissionStatus(evaluationStatusesColor
        .green, evaluationStatusesLabels.notStarted),
    submissionButtons: [{
        permission: actions.disqualifySuppliers,
        buttons:
        [buttonObjectDisqualifySupplier],
    }],
    showResponseStatusLabel: false,
    responseButtons: [
        {
            permission: IS_SUPPLIER_DISQUALIFIED,
            buttons: [buttonObjectViewReason],
        },
        {
            permission: actions.evaluateQuestionnaire,
            buttons: [buttonObjectEvaluateQuestionnaire],
        }, {
            permission: actions.viewEvaluateQuestionnaire,
            showIfReadOnly: false,
            buttons: [buttonObjectViewEvaluateQuestionnaire],
        },
    ],
}, {
    value: evaluationStatuses.underEvaluation,
    label: evaluationStatusesLabels.underEvaluation,
    colour: evaluationStatusesColor.amber,
    action: {
        permission: actions.evaluateQuestionnaire,
        values: {
            true: {
                label: dpsConstants.ACTION_NEEDED,
                color: evaluationStatusesColor.red,
            },
            false: {
                label: dpsConstants.EVALUATION_PENDING,
                color: evaluationStatusesColor.amber,
            },
        },
    },
    submissionStatusLabel: labelObjectSubmissionStatus(evaluationStatusesColor
        .amber, evaluationStatusesLabels.underEvaluation),
    submissionButtons: [{
        permission: actions.disqualifySuppliers,
        buttons:
           [buttonObjectDisqualifySupplier],
    }],
    responseLabels: [],
    showResponseStatusLabel: false,
    responseButtons: [
        {
            permission: IS_SUPPLIER_DISQUALIFIED,
            buttons: [buttonObjectViewReason],
        },
        {
            permission: actions.evaluateQuestionnaire,
            showIfReadOnly: false,
            buttons: [buttonObjectResumeQuestionnaire],
        }, {
            permission: actions.viewEvaluateQuestionnaire,
            buttons: [buttonObjectViewEvaluateQuestionnaire],
        },
    ],
}, {
    value: evaluationStatuses.evaluationComplete,
    label: evaluationStatusesLabels.evaluationComplete,
    colour: evaluationStatusesColor.green,
    action: {
        permission: actions.submitEvaluationsForApproval,
        values: {
            true: {
                label: dpsConstants.ACTION_NEEDED,
                color: evaluationStatusesColor.red,
            },
            false: {
                label: dpsConstants.APPROVAL_PENDING,
                color: evaluationStatusesColor.amber,
            },
        },
    },
    submissionButtons: [{
        permission: actions.submitEvaluationsForApproval,
        buttons:
           [buttonObjectSubmitForApproval],
    }],
    submissionScoreLabels: labelArraySubmissionScore,
    showResponseStatusLabel: true,
    responseLabels: labelArrayResponseScore,
    responseButtons: [
        {
            permission: IS_SUPPLIER_DISQUALIFIED,
            buttons: [buttonObjectViewReason],
        },
        {
            permission: actions.evaluateQuestionnaire,
            showIfReadOnly: false,
            buttons: [buttonObjectEditScoreQuestionnaire],
        },
        {
            notAllow: [roles.evaluator, roles.individual_eval],
            permission: actions.viewEvaluateQuestionnaire,
            buttons: [buttonObjectViewEvaluateQuestionnaire],
        },
    ],
}, {
    value: evaluationStatuses.awaitingApproval,
    label: evaluationStatusesLabels.awaitingApproval,
    colour: evaluationStatusesColor.amber,
    action: {
        permission: actions.approveStage1,
        values: {
            true: {
                label: dpsConstants.ACTION_NEEDED,
                color: evaluationStatusesColor.red,
            },
            false: {
                label: dpsConstants.STAGE_1_APPROVAL_PENDING,
                color: evaluationStatusesColor.amber,
            },
        },
    },
    submissionStatusLabel:
    labelObjectSubmissionStatus(evaluationStatusesColor.amber,
        dpsConstants.STAGE_1_APPROVAL_PENDING),
    submissionScoreLabels: labelArraySubmissionScore,
    submissionButtons: [{
        permission: actions.approveStage1,
        buttons:
           [buttonObjectStageReject(evaluationStatuses.stage1Approved,
               dpsConstants.ACTION_REJECTED),
           buttonObjectStageApprove(evaluationStatuses.stage1Approved,
               dpsConstants.ACTION_S1_APPROVED)],
    }],
    showResponseStatusLabel: true,
    responseLabels: labelArrayResponseScore,
    responseButtons: [
        {
            permission: IS_SUPPLIER_DISQUALIFIED,
            buttons: [buttonObjectViewReason],
        },
        {
            permission: actions.viewEvaluateQuestionnaire,
            buttons: [buttonObjectViewEvaluateQuestionnaire],
        },
    ],
}, {
    value: evaluationStatuses.stage1Approved,
    label: evaluationStatusesLabels.stage1Approved,
    colour: evaluationStatusesColor.amber,
    action: {
        permission: actions.approveStage2,
        values: {
            true: {
                label: dpsConstants.ACTION_NEEDED,
                color: evaluationStatusesColor.red,
            },
            false: {
                label: dpsConstants.STAGE_2_APPROVAL_PENDING,
                color: evaluationStatusesColor.amber,
            },
        },
    },
    submissionStatusLabel:
    labelObjectSubmissionStatus(evaluationStatusesColor.amber,
        dpsConstants.STAGE_2_APPROVAL_PENDING),
    submissionScoreLabels: labelArraySubmissionScore,
    submissionButtons: [{
        permission: actions.approveStage2,
        buttons:
           [buttonObjectStageReject(evaluationStatuses.stage2Approved,
               dpsConstants.ACTION_REJECTED),
           buttonObjectStageApprove(evaluationStatuses.stage2Approved,
               dpsConstants.ACTION_S2_APPROVED)],
    }],
    responseLabels: labelArrayResponseScore,
    showResponseStatusLabel: true,
    responseButtons: [
        {
            permission: IS_SUPPLIER_DISQUALIFIED,
            buttons: [buttonObjectViewReason],
        },
        {
            permission: actions.viewEvaluateQuestionnaire,
            buttons: [buttonObjectViewEvaluateQuestionnaire],
        },
    ],
}, {
    value: evaluationStatuses.approved,
    label: evaluationStatusesLabels.approved,
    colour: evaluationStatusesColor.green,
    showResponseStatusLabel: true,
    submissionStatusLabel: labelObjectSubmissionStatus(evaluationStatusesColor
        .green, evaluationStatusesLabels.approved),
    submissionScoreLabels: labelArraySubmissionScore,
    responseLabels: labelArrayResponseScore,
    responseButtons: [
        {
            permission: IS_SUPPLIER_DISQUALIFIED,
            buttons: [buttonObjectViewReason],
        },
        {
            permission: actions.viewEvaluateQuestionnaire,
            buttons: [buttonObjectViewEvaluateQuestionnaire],
        },
    ],
}, {
    value: evaluationStatuses.legalNotApplicable,
    label: evaluationStatusesLabels.legalNotApplicable,
    showResponseStatusLabel: false,
}];

const evaluationStatusValues = {
    colors: evaluationStatusOptions.map((option) => option.colour),
    labels: evaluationStatusOptions.map((option) => option.label),
    showResponsesStatusLabels: evaluationStatusOptions
        .map((option) => option.showResponseStatusLabel),
    actions: evaluationStatusOptions.map((option) => option.action),
    submissionStatusLabels: evaluationStatusOptions.map((option) => option.submissionStatusLabel),
    submissionScoreLabels: evaluationStatusOptions.map((option) => option.submissionScoreLabels),
    submissionButtons: evaluationStatusOptions.map((option) => option.submissionButtons),
    responseButtons: evaluationStatusOptions.map((option) => option.responseButtons),
    responseLabels: evaluationStatusOptions.map((option) => option.responseLabels),

};

const getEvaluationStatusValues = (status, values) => ({
    [evaluationStatuses.sealed]: values[0],
    [evaluationStatuses.released]: values[1],
    [evaluationStatuses.notStarted]: values[2],
    [evaluationStatuses.underEvaluation]: values[3],
    [evaluationStatuses.evaluationComplete]: values[4],
    [evaluationStatuses.awaitingApproval]: values[5],
    [evaluationStatuses.stage1Approved]: values[6],
    [evaluationStatuses.approved]: values[7],
    [evaluationStatuses.legalNotApplicable]: values[8],
    [evaluationStatuses.disqualified]: values[9],
}[status || '']);

const getEvaluationStatusLabel = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.labels);
const getEvaluationStatusColor = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.colors);
const showResponseStatus = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.showResponsesStatusLabels);
const getAction = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.actions);
const getSupplierButtons = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.submissionButtons);
const getResponseButtons = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.responseButtons);
const getResponseLabels = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.responseLabels);
const getSubmissionStatus = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.submissionStatusLabels);
const getSubmissionScoreLabels = (status) => getEvaluationStatusValues(status,
    evaluationStatusValues.submissionScoreLabels);


export {
    getEvaluationStatusLabel,
    getEvaluationStatusColor,
    showResponseStatus,
    getAction,
    getSupplierButtons,
    getResponseButtons,
    getResponseLabels,
    getSubmissionStatus,
    getSubmissionScoreLabels,
    labelObjectIntentStatus,
    labelObjectAction,
    labelObjectIncompleteEvaluations,
    labelObjectDisqualifySupplier,
};
