import React from 'react';
import SupplierCard from './components/SupplierCard';
import helperFunctions from '../../utils/helperFunctions';
import GeneralConstants from '../../utils/generalConstants';
import Button from '../../components/Button';
import constants from './constants';

const taskEventContent = ({ content }) => {
    const taskEventItems = content.map((taskEventItem, index) => {
        const status = helperFunctions.getStatus(taskEventItem.startDate,
            taskEventItem.eventStatus);
        return <div className='cardContentContainer' key={`event${index}`}>
            <div className='eventItem'>
                <div className='leftColumn'><p className='caption-xSmall'>{taskEventItem.startDate ? helperFunctions.formatPrettyDate(taskEventItem.startDate) : '-'}</p></div>
                <div className='middleColumn'>
                    <div className='eventHeader'>
                        <p className='title-xSmall'>{taskEventItem.eventName}</p>
                    </div>
                    <div className='eventContent'>
                        <p className='caption-xSmall'>{taskEventItem.projectName}</p>
                    </div>
                </div>
                <div className='rightColumn'>
                    <div className='eventHeader'>
                        <p className='eventStatus title-xSmall'>
                            <div className={`statusText ${helperFunctions.getStatusClass(status)}`} id={status}>
                                <p className='title-small'>{status}</p>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>;
    });
    return taskEventItems;
};

const opportunityCont = (data) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{constants.BUYER}:</p>
        <p className='title-small itemValue'>{data.account?.companyName}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{constants.ESTIMATED_VALUE}:</p>
        <p className='title-small itemValue'>{data.estimatedValue ? helperFunctions.formatCurrency(data.estimatedValue) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{constants.REGISTER_INTEREST_BY}:</p>
        <p className='title-small itemValue'>{helperFunctions.formatPrettyDateTime(data.expressionWindowEndDate)}</p>
    </div>
</>;

const opportunityContent = ({ content, toggle, history }) => {
    const contentsHtml = [];
    content?.forEach((item, idx) => {
        const url = `/opportunities/overview/${item.opportunityID}`;
        contentsHtml.push(
            <SupplierCard
                key={`OpportunityId${idx}`}
                id={`oppId${idx}`}
                header={true}
                type={toggle && GeneralConstants.OPPORTUNITY}
                caption={`${!toggle ? GeneralConstants.OPPORTUNITY : ''} #${item.projectReference ? item.projectReference : '-'}`}
                headerTitle={item.opportunityName}
                content={opportunityCont(item)}
                actionBtnText={constants.VIEW_OPPORTUNITY}
                handlerActionBtn={() => history.push(url)}
                url={url}
            />,
        );
    });
    return contentsHtml;
};

const contractContent = (data) => <>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{constants.VALUE}:</p>
        <p className='title-small itemValue'>{data.awardValue ? helperFunctions.formatCurrency(data.awardValue) : '-'}</p>
    </div>
    <div className='contentItem'>
        <p className='caption-small itemLabel'>{constants.CURRENT_STAGE}:</p>
        <p className='title-small itemValue'>{data.currentStage ? data.currentStage : '-'}</p>
    </div>
</>;

const contractsContent = ({ content, toggle, history }) => {
    const contentsHtml = [];
    content.forEach(async (item, idx) => {
        if (idx < 3) {
            const url = `/contracts/overview/${item.contractID}`;
            contentsHtml.push(
                <SupplierCard
                    id={`contractId${idx}`}
                    key={`contractId${idx}`}
                    header
                    type={toggle && GeneralConstants.CONTRACT}
                    headerTitle={item.title}
                    caption={`${!toggle ? GeneralConstants.CONTRACT : ''} #${item.contractReference ? item.contractReference : '-'}`}
                    content={contractContent(item)}
                    actionBtnText={constants.VIEW_CONTRACT}
                    handlerActionBtn={() => history.push(url)}
                    url={url}
                />,
            );
        }
    });
    return contentsHtml;
};

const openAccountRequestContent = ({ content, handleRequest }) => {
    const contentsHtml = [];
    content.forEach((item, idx) => {
        contentsHtml.push(
            <div className='accountAccessRequestItem' key={idx}>
                <div className='userDetails'>
                    <p className='title'>{`${item?.contactDetails?.firstname} ${item?.contactDetails?.surname}`}</p>
                    <p className='title-xSmall'>{`${item.companyName}`}</p>
                </div>
                <div className='actionsButtonsContainer'>
                    <Button
                        size='small'
                        id='approve'
                        label='Approve'
                        variant='primary'
                        handleClick={() => handleRequest(item.accountID, 'approved', 'account')} />
                    <Button
                        size='small'
                        id='reject'
                        label='Reject'
                        variant='secondary'
                        handleClick={() => handleRequest(item.accountID, 'rejected', 'account')} />
                </div>
            </div>,
        );
    });
    if (contentsHtml.length === 0) {
        contentsHtml.push(<p className='body caption caption-small'>{constants.NO_PENDING_ACCOUNT_REQUESTS}</p>);
    }
    return contentsHtml;
};


const openUserRequestContent = ({ content, authContext, handleRequest }) => {
    const contentsHtml = [];
    content.filter((el) => el.userID !== authContext.user.id).forEach(async (item, idx) => {
        contentsHtml.push(
            <div className='accountAccessRequestItem' key={idx}>
                <div className='userDetails'>
                    <p className='title'>{`${item.contactDetails?.firstname} ${item.contactDetails?.surname}`}</p>
                    <p className='caption'>{item.email}</p>
                    <p className='caption'>{item.departmentName}</p>
                </div>
                <div className='actionsButtonsContainer'>
                    <Button
                        size='small'
                        id='approve'
                        label='Approve'
                        variant='primary'
                        handleClick={() => handleRequest(item.userID, 'approved', 'user')} />
                    <Button
                        size='small'
                        id='reject'
                        label='Reject'
                        variant='secondary'
                        handleClick={() => handleRequest(item.userID, 'rejected', 'user')} />
                </div>
            </div>,
        );
    });
    if (contentsHtml.length === 0) {
        contentsHtml.push(<p className='body caption caption-small'>{constants.NO_PENDING_USER_REQUESTS}</p>);
    }
    return contentsHtml;
};
const generateUnVerifiedContent = (authContext) => {
    switch (true) {
        case authContext.user.accountVerified === constants.pending:
            return <>
                <p className='title-large' id='boError'>{constants.REQUEST_FOR_REGISTRATION_COMPANY_APPROVAL}</p>
                <p className='caption-large'>{constants.YOU_WILL_EMAIL_WHEN_ACCESS}</p>
            </>;
        case authContext.user.accountVerified === constants.rejected:
            return <>
                <p className='title-large' id='boError'>{constants.YOU_REQUEST_TO_JOIN_ACCOUNT_REJECTED}</p>
            </>;
        case authContext.user.accountVerified === constants.approved
        && authContext.user?.details?.verified === constants.pending:
            return <>
                <p className='title-large' id='boError'>{constants.REQUEST_FOR_REGISTRATION_COMPANY_APPROVAL}</p>
                <p className='caption-large'>{constants.YOU_WILL_EMAIL_WHEN_ACCESS}</p>
            </>;
        case authContext.user.accountVerified === constants.approved
        && authContext.user?.details?.verified === constants.rejected:
            return <>
                <p className='title-large' id='boError'>{constants.YOU_REQUEST_TO_JOIN_ACCOUNT_REJECTED}</p>
            </>;
        default:
            return <></>;
    }
};

export {
    taskEventContent,
    opportunityContent,
    contractsContent,
    openAccountRequestContent,
    openUserRequestContent,
    generateUnVerifiedContent,
};
