import React, {
    useContext, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { useGetDashboardData } from '../../hooks/useGetDashboardData';
import Button from '../../components/Button';

import DashboardCard from '../../components/DashboardCard';
import userManagementAPIs from '../../services/user-management.service';
import documentManagementAPIs from '../../services/document-management.service';
import Upload from '../../features/Upload';
import Modal from '../../components/Modal';
import rbac from '../../rbac';
import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';

import Toast from '../../components/Alerts/Toast/Toast';
import GuidanceBanner from '../../components/GuidanceBanner';
import { ThemeContext } from '../../context/ThemeContext';
import {
    taskEventContent,
    opportunityContent,
    contractsContent,
    openAccountRequestContent,
    openUserRequestContent,
    generateUnVerifiedContent,
} from './helpers';
import { DASHBOARD_OPTIONS, DASHBOARD_KEYS } from './config';

import { cleanupDeletedDocument, mergeUploadedDocuments, persistUploadedDocuments } from '../../utils/uploadHelper';

const SupplierDashboard = () => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [selectionQuestionnaire, setSelectionQuestionnaire] = useState({});
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);
    const [requestStatus, setRequestStatus] = useState({});
    const [userDetails, setUserDetails] = useState({});
    const [documents, setDocuments] = useState([]);
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;

    const { data, fetchDashboard, getCardContent } = useGetDashboardData(DASHBOARD_OPTIONS,
        DASHBOARD_KEYS, authContext.user);

    // I think this could be added to some further complexity.
    const getSelectionQuestionnaire = async () => {
        const response = await documentManagementAPIs.getDefaultTemplate();
        if (response.status === 200) {
            setSelectionQuestionnaire(response.data[0]);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const fetchSupplierData = () => {
        setUserDetails(authContext?.user);
        setDocuments(authContext?.user?.additionalDocuments);
    };

    useEffect(() => {
        if (authContext.authenticated && authContext.user?.details?.verified === 'approved') {
            fetchDashboard();
            fetchSupplierData();
            getSelectionQuestionnaire();
        }
    }, [authContext, fetchDashboard]);


    const handleRequest = (id, status, type) => {
        if (type === 'account') {
            setShowAccountModal(!showAccountModal);
        } else {
            setShowUserModal(!showUserModal);
        }
        setRequestStatus({ id, status });
    };

    const updateAccountRequests = async () => {
        try {
            const responseData = await userManagementAPIs.approveAccount(
                requestStatus.id,
                {
                    action: requestStatus.status,
                    reason: '',
                },
            );
            if (responseData && responseData.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Account request updated successfully.',
                });
                fetchDashboard();
                setShowAccountModal(!showAccountModal);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update account request.',
            });
        }
    };

    const updateUserRequests = async () => {
        try {
            const responseData = await userManagementAPIs.approveUser(
                requestStatus.id,
                { action: requestStatus.status },
            );
            if (responseData && responseData.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Account request updated successfully.',
                });
                fetchDashboard();
                setShowUserModal(!showUserModal);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update account request.',
            });
        }
    };


    const uploadDocuments = async (newDocs) => {
        const mergedDocs = mergeUploadedDocuments(documents, newDocs,
            {}, authContext.user);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'upload',
            () => userManagementAPIs.updateAccountDocuments(payload),
            fetchSupplierData, // Success
            fetchSupplierData, // Fail
        );

        authContext.updateAuthContext({
            ...authContext.user,
            additionalDocuments: mergedDocs,
        });
    };

    const deleteDocument = async (doc) => {
        const mergedDocs = cleanupDeletedDocument(documents, doc);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'delete',
            () => userManagementAPIs.updateAccountDocuments(payload),
            fetchSupplierData, // Success
            fetchSupplierData, // Fail
        );

        authContext.updateAuthContext({
            ...authContext.user,
            additionalDocuments: mergedDocs,
        });
    };


    return (authContext.user.accountVerified === 'approved' && authContext.user?.details?.verified === 'approved')
        ? <>
            {((userDetails.organisationDetails?.deliveryAreaInfo?.length === 0
                || !userDetails.organisationDetails?.deliveryAreaInfo
                || !userDetails.organisationDetails?.industryInfo
                || userDetails.organisationDetails?.industryInfo?.length === 0))
                // || selectionQuestionnaire?.templateStatus !== 'submitted')
                ? <div className={`dashboardContainer ${toggle ? 'new-dashboardContainer' : ''}`}>
                    <GuidanceBanner
                        id={'supplierGuidanceBanner'}
                        type={''}
                        headerText={'Complete your profile'}
                        // bannerText1={(selectionQuestionnaire?.templateStatus !== 'submitted'
                        //     && rbac.can(rbac.action.addEditSelectionQuestionnaire,
                        //         [authContext.user.accountType.toLowerCase()
                        //             + authContext.user.role,
                        //         ]))
                        //     // ?
                        //     && <>Your company has not yet completed a Selection Questionnaire.
                        // eslint-disable-next-line max-len
                        //          Click <a href='' onClick ={() => { history.push('/supplier/view/sq'); }}>here</a> to complete and save some time later.</>
                        //     //  What's the rbac solution here?
                        //     // : <>{!userDetails.questionnaire
                        //     //     ? `Your company has not completed the questionnaire.
                        //     //          Please ask Admin to answer the selection questionnaire.`
                        //     //     : <></>}
                        //     // </>
                        // }
                        bannerText2={
                            ((userDetails.organisationDetails?.deliveryAreaInfo?.length === 0
                                || !userDetails.organisationDetails?.deliveryAreaInfo
                                || !userDetails.organisationDetails?.industryInfo
                                || userDetails.organisationDetails?.industryInfo?.length === 0)
                            && rbac.can(rbac.action.addEditSelectionQuestionnaire,
                                [authContext.user.accountType.toLowerCase()
                                    + authContext.user.role,
                                ]))
                                ? <>Your company doesn’t have Codes and Categories
                                     and/or Delivery Areas assigned.
                                Click <a href='' onClick ={() => { history.push('/my-profile'); }}>here</a> to assign by editing your Profile.</>
                                : <>{(
                                    userDetails.organisationDetails?.deliveryAreaInfo?.length
                                        === 0
                                    || userDetails.organisationDetails?.industryInfo?.length === 0)
                                    ? `Your company doesn't have Codes and Categories
                                     and/or Delivery Areas assigned.
                                     Please ask Admin to assign.`
                                    : <></>}
                                </>}
                        buttonArr={[]}
                        timerEndDate={''}
                        showStatus={''}
                        actionableItem={''}
                    />
                </div>
                : <></>}
            <div className={`dashboardContainer opportunities-container ${toggle ? 'new-dashboardContainer' : ''}`}>
                <DashboardCard
                    id='suggestedOpportunities'
                    size='large'
                    header={true}
                    headerTitle='Suggested Opportunities'
                    caption='Using the information you provided during registration, we suggest the following opportunities that we feel would be relevant for you to pursue.'
                    content={getCardContent({
                        method: DASHBOARD_KEYS.SUGGESTED_OPORTUNITIES,
                        contentFunction: opportunityContent,
                        content: data,
                        history,
                        toggle,
                    })}
                    footer={true}
                    footerBtnText='View All'
                    footerUrl={'/opportunities'}
                    footerBtnHandler={() => history.push('/opportunities')}
                    customStyle = 'customStyle'
                />
            </div>
            <div className={`dashboardContainer ${toggle ? 'new-dashboardContainer' : ''}`}>
                <DashboardCard
                    id='taskEventCard'
                    size='small'
                    header={true}
                    headerTitle={'My Tasks and Events'}
                    actionBtn={false}
                    content={getCardContent({
                        method: DASHBOARD_KEYS.TASKS_AND_EVENTS,
                        contentFunction: taskEventContent,
                        content: data,
                    })}
                    footer={true}
                    footerBtnText='View All'
                    footerUrl={'/calendar'}
                    footerBtnHandler={() => history.push('/calendar')}
                    customStyle = 'customStyle'
                />
                <DashboardCard
                    id='myOpportunities'
                    size='medium'
                    header={true}
                    headerTitle={'My Opportunities'}
                    caption='Opportunities you have registered interest in and are currently working on.'
                    content={getCardContent({
                        method: DASHBOARD_KEYS.OPPORTUNITIES,
                        contentFunction: opportunityContent,
                        content: data,
                        history,
                        toggle,
                    })}
                    footer={true}
                    footerBtnText='View All'
                    footerUrl={'/opportunities/myOpportunities'}
                    footerBtnHandler={() => history.push(`/opportunities/${'myOpportunities'}`)}
                    customStyle = 'customStyle'
                />
            </div>
            <div className={`dashboardContainer ${toggle ? 'new-dashboardContainer' : ''}`}>
                { rbac.can(rbac.action.openAccountRequests,
                    [authContext.user.accountType.toLowerCase() + authContext.user.role])
            && <DashboardCard
                id='openRequestContentCard'
                size='small'
                header={true}
                headerTitle='Regional Office Requests'
                caption='Regional Offices created in Open, pending your approval.'
                actionBtn={false}
                content={getCardContent({
                    method: DASHBOARD_KEYS.ACCOUNTS_AWAITING_APPROVAL,
                    contentFunction: openAccountRequestContent,
                    content: data,
                    handleRequest,
                })}
                customStyle = 'customStyle'
            />
                }
                <DashboardCard
                    id='myContracts'
                    size='medium'
                    header={true}
                    headerTitle='My Contracts'
                    actionBtn={false}
                    content={getCardContent({
                        method: DASHBOARD_KEYS.CONTRACTS,
                        contentFunction: contractsContent,
                        content: data,
                        history,
                        toggle,
                    })}
                    footer={true}
                    footerBtnText='View All'
                    footerUrl={'/contracts'}
                    footerBtnHandler={() => history.push('/contracts')}
                    customStyle = 'customStyle'
                />
            </div>
            <div className={`dashboardContainer ${toggle ? 'new-dashboardContainer' : ''}`}>
                { rbac.can(rbac.action.openUserRequests,
                    [authContext.user.accountType.toLowerCase() + authContext.user.role])
                    && <DashboardCard
                        id='openUserRequestContentCard'
                        size='small'
                        header={true}
                        headerTitle='Open User Requests'
                        caption='Users created on Open by a member of your organisation, pending your approval.'
                        actionBtn={false}
                        content={getCardContent({
                            method: DASHBOARD_KEYS.USERS_AWAITING_APPROVAL,
                            contentFunction: openUserRequestContent,
                            content: data,
                            authContext,
                            handleRequest,
                        })}
                        customStyle = 'customStyle'
                    />}
                <DashboardCard
                    id='actions'
                    size='small'
                    header={true}
                    headerTitle='Documents'
                    caption={'Please note: Documents added into this area will not only be accessible to you but also to all Buyers registered in Open. Documents which may be useful to upload in this area are: insurance certificates, health & safety certificates, Modern Slavery policy and case studies, among others.'}
                    content={<div className='overview-body' >
                        <Upload
                            title='Documents'
                            allowMultiple={true}
                            uploadIdentifier='Document'
                            uploadCallback={uploadDocuments}
                            deleteCallback={deleteDocument}
                            questionnaire={false}
                            uploadedDocuments={documents}
                        />
                    </div>}
                    customStyle = 'customStyle'
                />

                <DashboardCard
                    id='selection-questionnaire'
                    size='small'
                    header={true}
                    headerTitle='Selection Questionnaire'
                    // actionBtn={rbac.can(rbac.action.addEditSelectionQuestionnaire,
                    //     [authContext.user.accountType.toLowerCase() + authContext.user.role])
                    // }
                    // Right now we just retrieve the default questionnaire
                    actionBtn={false}
                    actionBtnText={'Add'}
                    handlerActionBtn={() => history.push('/supplier/edit/sq')}
                    iconSrc={addIconSrc}
                    icon={true}
                    additionalVariant={'secondary skinless align-right'}
                    caption={''}
                    // eslint-disable-next-line max-len
                    // content={<p className='caption'>The Selection Questionnaire has been temporarily disabled while the Open team are working on updates.</p>}
                    content={selectionQuestionnaire
                        ? <div className='overview-body' >
                            <p className='title sectionLabel'>The Selection Questionnaire has been temporarily disabled while the Open team are working on updates.</p>
                            <div className='buttonContainer'>
                                {selectionQuestionnaire?.templateStatus !== 'submitted'
                                && <Button id='actionBtnS'
                                    size='small'
                                    variant='secondary'
                                    label='View'
                                    handleClick={() => history.push('/supplier/view/sq')}
                                />}
                                {/* {rbac.can(rbac.action.addEditSelectionQuestionnaire,
                                    [authContext.user.accountType.toLowerCase()
                                    + authContext.user.role])
                                    && <Button id='actionBtnS'
                                        size='small'
                                        variant='primary'
label={selectionQuestionnaire?.templateType === 'defaultSelectionResponse' ? 'Edit' : 'Respond'}
                                        handleClick={() => history.push('/supplier/edit/sq')}
                                    />} */}
                            </div>
                        </div>
                        : <div className='overview-body' >
                            <p className='caption'>Please answer the selection questionnaire.</p>
                        </div>}
                    customStyle = 'customStyle'
                />
            </div>
            {showAccountModal && <Modal
                id='approveRejectBtn'
                open={true}
                size='small'
                headerTitle='Are you sure?'
                body={<p className='body'>{`Are you sure you want to ${requestStatus.status === 'approved' ? 'approve' : 'reject'} the open account request?`}</p>}
                footer={true}
                mainActionButtonTxt='Yes'
                secondActionButtonTxt='No'
                secondActionButton={true}
                helpOption={false}
                handleMainActionBtnClick={(event) => {
                    event.preventDefault();
                    updateAccountRequests();
                }
                }
                handleSecondaryActionBtnClick={() => {
                    setRequestStatus({});
                    setShowAccountModal(!showAccountModal);
                }}
                closeModal={() => { setShowAccountModal(!showAccountModal); }}
            />}
            {showUserModal && <Modal
                id='approveRejectBtn'
                open={true}
                size='small'
                headerTitle='Are you sure?'
                body={<p className='body'>{`Are you sure you want to ${requestStatus.status === 'approved' ? 'approve' : 'reject'} the user request?`}</p>}
                footer={true}
                mainActionButtonTxt='Yes'
                secondActionButtonTxt='No'
                secondActionButton={true}
                helpOption={false}
                handleMainActionBtnClick={(event) => {
                    event.preventDefault();
                    updateUserRequests();
                }
                }
                handleSecondaryActionBtnClick={() => {
                    setRequestStatus({});
                    setShowUserModal(!showUserModal);
                }}
                closeModal={() => { setShowUserModal(!showUserModal); }}
            />}
        </>
        : <div id='supplierPendingApproval'>
            {generateUnVerifiedContent(authContext)}
        </div>;
};

export default SupplierDashboard;
