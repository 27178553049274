import React from 'react';
import PropTypes from 'prop-types';
import HeaderContent from './HeaderContent';
import SectionContent from './SectionContent';
import Accordion from '../../Accordion';
import rbac from '../../../rbac';
import { evaluationStatuses } from '../../../config/constants';

const SupplierItem = ({
    id,
    item,
    isDPS,
    projectDetails,
    isLotLevel = false,
    handleStatusUpdate,
    handleDisqualifySupplier,
    readOnly,
    userSteeringRole,
    openNodes,
    handleSaveOpenNodesInLocalStorage,
}) => {
    const getEvaluationStatus = () => {
        const evalStatusArray = [];
        item.responses.forEach((response) => {
            evalStatusArray.push(response.evaluationStatus);
        });
        const evalCompleteArray = evalStatusArray.filter(
            (status) => status === evaluationStatuses.evaluationComplete,
        );
        if (evalCompleteArray.length === evalStatusArray.length) {
            return true;
        }
        return false;
    };

    const getResponse = () => {
        const response = {
            legalStatus: item?.responses[0].legalStatus || undefined,
            evaluationStatus: item?.responses[0].evaluationStatus || undefined,
        };
        if (response.evaluationStatus === evaluationStatuses.evaluationComplete) {
            const result = getEvaluationStatus();
            if (result === false) {
                response.evaluationStatus = evaluationStatuses.underEvaluation;
            }
        }
        return response;
    };

    const getHeaderStatus = () => {
        const response = getResponse();
        switch (response.evaluationStatus
            || response.legalStatus) {
            case evaluationStatuses.sealed:
                return 'red';
            case evaluationStatuses.released:
            case evaluationStatuses.notStarted:
            case evaluationStatuses.evaluationComplete:
            case evaluationStatuses.approved:
                return 'green';
            case evaluationStatuses.underEvaluation:
            case evaluationStatuses.awaitingApproval:
            case evaluationStatuses.stage1Approved:
                return 'amber';
            default: return '';
        }
    };

    const getEvaluationStatusText = () => {
        const response = getResponse();
        switch (response.evaluationStatus || response.legalStatus) {
            case evaluationStatuses.sealed: return 'Sealed';
            case evaluationStatuses.released: return 'Released';
            case evaluationStatuses.notStarted: return 'Released';
            case evaluationStatuses.underEvaluation: return 'Under Evaluation';
            case evaluationStatuses.evaluationComplete: return 'Evaluation Complete';
            case evaluationStatuses.awaitingApproval: return 'Stage 1 Approval Pending';
            case evaluationStatuses.stage1Approved: return 'Stage 2 Approval Pending';
            case evaluationStatuses.approved: return 'Approved';
            default: return '';
        }
    };

    const showActionLabel = () => {
        const response = getResponse();
        if (isDPS === true && response.evaluationStatus === evaluationStatuses.evaluationComplete) {
            return rbac.can(rbac.action.submitEvaluationsForApproval, userSteeringRole) ? 'Action Needed' : 'Approval Pending';
        }
        if (isDPS === true && response?.evaluationStatus === evaluationStatuses.awaitingApproval) {
            return rbac.can(rbac.action.approveStage1, userSteeringRole) ? 'Action Needed' : 'Stage 1 Approval Pending';
        }
        if (isDPS === true && response?.evaluationStatus === evaluationStatuses.stage1Approved) {
            return rbac.can(rbac.action.approveStage2, userSteeringRole) ? 'Action Needed' : 'Stage 2 Approval Pending';
        }
        if (response.evaluationStatus === evaluationStatuses.notStarted
            || response.evaluationStatus === evaluationStatuses.underEvaluation) {
            return rbac.can(rbac.action.evaluateQuestionnaire, userSteeringRole) ? 'Action Needed' : 'Evaluation Pending';
        }
        return undefined;
    };

    const showStatusLabel = () => {
        const response = getResponse();
        response.evaluationStatus = (response.evaluationStatus === evaluationStatuses.notStarted
            && (!response?.legalStatus || response?.legalStatus?.trim() === '')) ? evaluationStatuses.legalNotApplicable : response.evaluationStatus || response.legalStatus;
        switch (response.evaluationStatus || response.legalStatus) {
            case evaluationStatuses.evaluationComplete:
            case evaluationStatuses.legalNotApplicable:
                return false;
            case evaluationStatuses.awaitingApproval:
            case evaluationStatuses.stage1Approved:
            case evaluationStatuses.approved:
                return true;
            default:
                return true;
        }
    };

    const showScoreLabel = () => {
        const response = getResponse();
        switch (response.evaluationStatus || response.legalStatus) {
            case evaluationStatuses.evaluationComplete:
            case evaluationStatuses.awaitingApproval:
            case evaluationStatuses.stage1Approved:
            case evaluationStatuses.approved:
                return true;
            default:
                return false;
        }
    };
    const showPassFailLabel = () => item?.responses?.filter(
        (response) => response?.passFailResult,
    )?.length > 0;

    const generatePassFailResult = () => (item?.responses?.filter((x) => x.passFailResult?.toLowerCase() === 'fail').length > 0 ? 'Fail' : 'Pass');

    const generateScore = () => {
        let score = 0;
        item.responses.forEach((response) => {
            if (response.score) {
                score += parseFloat(response.score);
            }
        });
        return score;
    };

    const generateAccordionSectionHeader = () => <HeaderContent
        supplierName={item?.account?.companyName || ''}
        submittedAt={item?.responses[0]?.tenderSubmittedAt || ''}
        evaluationStatus={!readOnly ? getEvaluationStatusText() : ''}
        color={!readOnly ? getHeaderStatus() : ''}
        actionLabel={showActionLabel()}
        showStatusLabel={!readOnly ? showStatusLabel() : false}
        showScoreLabel={!readOnly ? showScoreLabel() : false}
        showPassFailLabel={!readOnly ? showPassFailLabel() : false}
        score={!readOnly ? generateScore() : 0}
        passFail={!readOnly ? generatePassFailResult() : false}
        weightage={item?.responses?.reduce(
            (totalWeightage, res) => totalWeightage + res.weightage, 0,
        ) || 0}
    />;

    const generateAccordionSectionContent = () => {
        switch (item.responses[0].evaluationStatus) {
            case evaluationStatuses.notStarted:
            case evaluationStatuses.underEvaluation:
            case evaluationStatuses.evaluationRejected:
                return <SectionContent
                    data={item}
                    showStatusLabel={item.supplierDisqualified}
                    showButton={true}
                    projectDetails={projectDetails}
                    isLotLevel={isLotLevel}
                    isDPS={isDPS}
                    handleStatusUpdate={handleStatusUpdate}
                    handleDisqualifySupplier={handleDisqualifySupplier}
                    readOnly={readOnly}
                />;
            case evaluationStatuses.evaluationComplete:
            case evaluationStatuses.awaitingApproval:
            case evaluationStatuses.stage1Approved:
            case evaluationStatuses.approved:
                return <SectionContent
                    data={item}
                    showStatusLabel={true}
                    showButton={true}
                    projectDetails={projectDetails}
                    isLotLevel={isLotLevel}
                    isDPS={isDPS}
                    handleStatusUpdate={handleStatusUpdate}
                    handleDisqualifySupplier={handleDisqualifySupplier}
                    readOnly={readOnly}
                />;
            default:
                return <></>;
        }
    };

    const generateAccordionData = () => {
        const elements = [];
        elements.push({
            sectionId: `${!isLotLevel ? item?.accountID : `${id}-${item?.accountID}`}`,
            sectionClass: 'supplierLevel',
            headerClass: `${!isLotLevel ? '' : 'headerLevel'}`,
            header: generateAccordionSectionHeader(),
            content: generateAccordionSectionContent(),
        });
        return elements;
    };

    return <section className='sbContentSection' key={`section-${id}`}>
        <div className='sbContentBody'>
            <Accordion id='sbAccordion_Supplier'
                key={`section-accordion-${id}`}
                data={generateAccordionData()}
                handleToogleAccordion={handleSaveOpenNodesInLocalStorage}
                open={openNodes?.includes(!isLotLevel ? item?.accountID : `${id}-${item?.accountID}`)}
                clickOnExpandIcon={false}
            />
        </div>
    </section >;
};

SupplierItem.propTypes = {
    id: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    isDPS: PropTypes.bool.isRequired,
    projectDetails: PropTypes.object.isRequired,
    isLotLevel: PropTypes.bool,
    handleStatusUpdate: PropTypes.func,
    handleDisqualifySupplier: PropTypes.func,
    userSteeringRole: PropTypes.array,
    readOnly: PropTypes.bool,
    openNodes: PropTypes.array,
    handleSaveOpenNodesInLocalStorage: PropTypes.func,
};

export default SupplierItem;
