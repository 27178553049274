import React, {
    useState, useCallback, useMemo,
} from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
    REVIEWER, NO_CONTENT_AVAILABLE, SKELETON_MODES, LINE_DEFAULT_HEIGHT,
} from '../config/constants';
import Toast from '../components/Alerts/Toast/Toast';
import ToastConstants from '../components/Alerts/Toast/constants';


const handleRequestError = (error, method) => {
    Toast.fire({
        icon: ToastConstants.errorIcon,
        titleText: ` ${method}: ${error.message}`,
    });
};

const createSkeletonItems = (count, height = null) => Array.from({ length: count }, (_, index) => (
    <Skeleton
        key={`skeleton-${index}`}
        height={height}
        data-testid={`skeleton-item-${index}`}
    />
));

const getSkeletonStructure = (limit, mode) => {
    const isColumn = mode === SKELETON_MODES.COLUMN;
    const count = isColumn ? limit : limit * 2;
    const items = createSkeletonItems(count, isColumn ? null : LINE_DEFAULT_HEIGHT);

    return isColumn ? (
        <div className='skeletonRow' data-testid='skeleton-container'>
            {items}
        </div>
    ) : items;
};


export const useGetDashboardData = (options, keys, user) => {
    const [data, setData] = useState({});


    const isReviewer = useMemo(() => user?.privilege?.length && user.privilege.includes(REVIEWER),
        [user?.privilege]);

    const validRequests = useMemo(() => options?.filter((request) => {
        const userRole = user?.role?.toLowerCase();
        return request?.microservice
                   && request?.method
                   && userRole
                   && request.userRole.includes(userRole);
    }),
    [options, user?.role]);

    const processRequest = useCallback(async (request) => {
        try {
            if (request.method === keys.PROC_REQUESTS_APPROVAL && !isReviewer) {
                setData((prev) => ({ ...prev, [request.method]: [] }));
                return;
            }
            const param = request.method === keys.USERS_AWAITING_APPROVAL
                ? user.accountId : request.limit;

            const result = await request.microservice[request.method](param);

            setData((prev) => ({ ...prev, [request.method]: result.data }));
        } catch (error) {
            handleRequestError(error, request.method);
            setData((prev) => ({ ...prev, [request.method]: [] }));
        }
    }, [isReviewer, user.accountId, keys.PROC_REQUESTS_APPROVAL]);

    const fetchDashboard = useCallback(async () => {
        await Promise.all(validRequests?.map(processRequest));
    }, [validRequests, processRequest]);

    const getCardContent = ({
        method, contentFunction, content, toggle, history, handleRequest, authContext,
    }) => {
        const cardOption = options?.find((option) => option.method === method);
        if (!cardOption) {
            return <></>;
        }
        const dataContent = content?.[method];
        if (!dataContent) {
            return <>{getSkeletonStructure(cardOption.limit, cardOption.mode)}</>;
        }
        return (Array.isArray(dataContent) && dataContent?.length > 0)
            ? <div className={`dashboardCardMain${cardOption.mode === SKELETON_MODES.COLUMN ? 'Row' : ''}`}>{contentFunction({
                content: dataContent, toggle, history, handleRequest, authContext,
            })}</div>
            : <><p className='caption caption-small'>{cardOption?.emptyContent || NO_CONTENT_AVAILABLE}</p></>;
    };

    return { data, fetchDashboard, getCardContent };
};
export default useGetDashboardData;
