/* eslint-disable consistent-return */
import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from './actions';
import AuthContext from '../../context/AuthContext';
import { userStatus } from '../../config/constants';

const NOTIFICATION_INTERVAL = 120000; // 2 minutes

const NotificationsChecker = () => {
    const dispatch = useDispatch();
    const authContext = useContext(AuthContext);
    const {
        notificationsState,
    } = useSelector((state) => ({
        notificationsState: state?.notifications?.notifications,
    }));


    const isUserApproved = authContext?.user?.accountVerified === userStatus.approved
    && authContext?.user?.details?.verified === userStatus.approved;

    useEffect(() => {
        if (!isUserApproved) {
            dispatch({ type: 'clearNotifications' });
            return;
        }
        if (!notificationsState) {
            dispatch(fetchData());
        }

        const intervalID = setInterval(() => {
            if (isUserApproved) {
                dispatch(fetchData());
                // TODO check the last unread notifications from the last time we feched.
                // Not all unread notifications.
            }
        }, NOTIFICATION_INTERVAL);

        return () => {
            clearInterval(intervalID);
        };
    }, [dispatch, isUserApproved, notificationsState]);

    return <div>
    </div>;
};

export default NotificationsChecker;
