const dpsConstants = {
    USER: 'User',
    USER_ICON: 'userIcon',
    LOT: 'lot',
    SUBMISSION: 'submission',
    RESPONSE: 'response',
    SUPPLIER: 'supplier',
    LOT_ID: 'lotID',
    ACCOUNT_ID: 'accountID',
    SUBMITTED_AT: 'Submitted at',
    CURRENT_TITLE: 'Current',
    LOT_TITLE: 'Lot',
    SUBMISSION_TITLE: 'Submission',
    SUCCESSFUL: 'Successful',
    UNSUCCESSFUL: 'Unsuccessful',
    buttonViewEvaluateQuestionnaireId: 'view',
    labelResponseScoreId: 'score',
    labelActionId: 'labelActionId',
    labelResponsePassFailResultId: 'passFailResult',
    labelSubmissionScoreId: 'totalScore',
    labelSubmissionPassFailResultId: 'totalPassFailResult',
    labelIntentStatusId: 'labelIntentStatusId',
    labelLotActionNeededId: 'labelLotActionNeededId',
    succesfulBtn: 'succesfulBtn',
    unsuccesfulBtn: 'unsuccesfulBtn',
    editBtn: 'editBtn',
    ACTION_NEEDED: 'Action Needed',
    AWARDS_SENT_SUCCESSFULLY: 'Awards Sent Successfully',
    VIEW_SOLUTION: 'View Solution',
    VIEW_CONTRACT: 'View Contract',
    BACK: 'Back',
    AWARD_LETTERS_SENT: 'Award Letter(s) sent',
    EDIT_AWARD_STATUS: 'Edit Award Status',
    CONFIRM: 'Confirm',
    CANCEL: 'Cancel',
};

export const getConfirmationText = (status) => `Are you sure you want to update the status of this supplier to '${status.charAt(0).toUpperCase()}${status.slice(1)}'? Click ‘Confirm’ to proceed or ‘Cancel’ to keep the current status.`;

export default dpsConstants;
