import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import Constants from './constants';
import {
    selectSupplier,
    isSupplierRefined,
    removePill,
    addUsersAndDepartmentsToMap,
} from './helpers';
import helper from '../AddUsersAndDepartmentsToDirectAwardModal/usersAndDepartmentsHelper';
import { ThemeContext } from '../../context/ThemeContext';
import confirmIcon from '../../styles/icons/white/confirm.svg';
import editIcon from '../../styles/icons/blue/edit.svg';
import confirmIconBlue from '../../styles/icons/blue/confirm.svg';
import isProdEnvironment from '../../utils/isProdEnvironment';

const AddUsersAndDepartmentsToDirectAwardModal = React.lazy(() => import('../AddUsersAndDepartmentsToDirectAwardModal/AddUsersAndDepartmentsToDirectAwardModal'));

function RefineSupplier({
    context,
    supplier,
    opportunityID,
    projectID,
    eventID,
    lotID,
    routeToMarketParam,
    filteredData,
    newDataSet,
    withTags,
    handleFilteredData,
    handleNewDataSet,
    fromCleverQuotes = false,
}) {
    const history = useHistory();
    const [showDepartmentAndUsersModal, setShowUsersAndDepartmentsModal] = useState(false);
    const [initialUsersAndDepartmentsData, setInitialUsersAndDepartmentsData] = useState();
    const [supplierID, setSupplierId] = useState([]);
    const { toggle } = useContext(ThemeContext);
    const isProductionEnvironment = isProdEnvironment();

    const toggleModal = () => {
        setShowUsersAndDepartmentsModal(!showDepartmentAndUsersModal);
    };


    const addUserAndDepartmentToFilteredData = (cardID, usersAndDepartments) => {
        handleFilteredData(addUsersAndDepartmentsToMap(filteredData, cardID, usersAndDepartments));
        if (handleNewDataSet) {
            handleNewDataSet(addUsersAndDepartmentsToMap(newDataSet, cardID, usersAndDepartments));
        }
    };

    const refineSupplier = (currentSupplierID) => {
        setInitialUsersAndDepartmentsData(filteredData
            .find((item) => item.supplierID === currentSupplierID)?.usersAndDepartments);
        setSupplierId(currentSupplierID);
        setShowUsersAndDepartmentsModal(true);
    };

    const handleAddRemoveSupplier = (currentSupplierID) => {
        handleFilteredData(filteredData.map((data) => {
            if (data.supplierID === currentSupplierID) {
                return { ...data, selected: !data.selected, usersAndDepartments: [] };
            }
            return data;
        }));
    };

    const getAddRemoveStyle = (selected) => {
        if (!toggle) {
            return 'tertiary';
        }
        return selected ? 'remove-link' : 'secondary small';
    };

    const supplierRefined = isSupplierRefined(supplier.usersAndDepartments);
    return (
        <>
            <div className='refine-supplier'>
                {withTags && !isProductionEnvironment
                && <div className='refine-tags'>
                    {helper
                        .generateUsersAndDepartmentsTag(
                            supplier.supplierID, supplier.usersAndDepartments, true,
                            (cardID, pillID) => removePill(cardID,
                                pillID, filteredData, handleFilteredData),
                        )}

                </div>
                }
                <div className='refine-buttons'>
                    {!fromCleverQuotes
                    && <Button
                        id={`${(supplierRefined && !isProductionEnvironment) ? 'confirm' : 'add'}${context.toLowerCase()}Btn-${supplier.supplierID}`}
                        variant={
                            (() => {
                                if (toggle) return 'primary small';
                                if (supplierRefined) return 'tertiary worknoteAdd';
                                return 'tertiary';
                            })()
                        }
                        iconSrc= {confirmIcon}
                        icon={toggle}
                        label={(supplierRefined && !isProductionEnvironment)
                            ? Constants.CONFIRM : Constants.ADD_SUPPLIER}
                        handleClick={
                            () => selectSupplier(supplier, routeToMarketParam,
                                projectID, eventID,
                                lotID, opportunityID, history)
                        } />
                    }
                    {fromCleverQuotes
                         && <>
                             {toggle && supplier.selected && <span className='event-selected'>{Constants.SUPPLIER_SELECTED} </span>}
                             <Button
                                 id={`addSupplierBtn-${supplier.id}`}
                                 variant={getAddRemoveStyle(supplier.selected)}
                                 iconSrc= {confirmIconBlue}
                                 icon={toggle && !supplier.selected}
                                 label={supplier.selected
                                     ? Constants.REMOVE_SUPPLIER
                                     : Constants.ADD_SUPPLIER}
                                 handleClick={() => handleAddRemoveSupplier(supplier.supplierID)}
                             />
                         </>
                    }
                    {!isProductionEnvironment
                    && <Button
                        id={`refine${context.toLowerCase()}Btn-${supplier.supplierID}`}
                        iconSrc= {editIcon}
                        icon={toggle}
                        variant={!toggle ? 'tertiary' : 'secondary small'}
                        handleClick={() => refineSupplier(supplier.supplierID)}
                        label={Constants.REFINE} />
                    }

                </div>
            </div>
            {showDepartmentAndUsersModal
                && <AddUsersAndDepartmentsToDirectAwardModal
                    closeModal={toggleModal}
                    initialData={initialUsersAndDepartmentsData}
                    accountID={supplierID}
                    onSubmit={addUserAndDepartmentToFilteredData}
                />
            }
        </>
    );
}

RefineSupplier.propTypes = {
    supplier: PropTypes.object.isRequired,
    opportunityID: PropTypes.string.isRequired,
    withTags: PropTypes.bool.isRequired,
    handleFilteredData: PropTypes.func,
    context: PropTypes.string,
    projectID: PropTypes.string,
    eventID: PropTypes.string,
    lotID: PropTypes.string,
    routeToMarketParam: PropTypes.string,
    filteredData: PropTypes.array,
    fromCleverQuotes: PropTypes.bool,
    newDataSet: PropTypes.array,
    handleNewDataSet: PropTypes.func,
};

export default RefineSupplier;
