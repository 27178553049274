/* eslint-disable max-len */
import React from 'react';

// import laCardImage from '../../styles/images/banner1.png';
// import supplierCardImage from '../../styles/images/banner2.png';
// import raCardImage from '../../styles/images/banner3.png';
import laCardImage from '../../styles/images/homepage/illustration3.png';
import supplierCardImage from '../../styles/images/homepage/illustration4.png';
import raCardImage from '../../styles/images/homepage/illustration2.png';


const Constants = {
    userCardsData: [{
        id: 'laInfoCard',
        title: 'The future of e-procurement',
        image: laCardImage,
        description: <>We think that e-procurement systems should unlock new ways of working,
        so that you can focus on delivering the best outcomes for your stakeholders.
        <br></br><br></br>
        Technology is constantly evolving. Open is built on a leading-edge platform so that
        we can continuously develop and meet public sector needs, today and tomorrow.
        <br></br><br></br>
        With the right tools, public sector procurement can achieve outstanding results for our
        stakeholders. That’s why we built Open.</>,
    }, {
        id: 'supplierInfoCard',
        title: 'By the public sector, for the public sector',
        image: supplierCardImage,
        description: <>NEPO has over 40 years’ experience of working in partnership with the public sector.
        We have brought this expertise to Open, to ensure that it meets the needs of the public sector and the communities we serve.
        <br></br><br></br>
        Built by NEPO in partnership with the twelve North East local authorities and wider public sector,
         Open is the result of extensive collaboration and a shared ambition to drive excellence in public sector procurement.</>,
    }, {
        id: 'raInfoCard',
        title: 'Helping you to achieve',
        image: raCardImage,
        description: <>Whether you are a public sector buyer, or a supplier interested in doing business with the public sector,
        Open will help you to achieve your goals.
        <br></br><br></br>
        Open is an intuitive and user-friendly system that streamlines the procurement process on both sides of the competitive process.
        <br></br><br></br>
        Cloud-based technology means there is no software to install, simply register or log-in to get started.</>,
    }],
    H1_TITLE_1: 'The e-procurement platform designed',
    H1_TITLE_2: 'by the public sector,',
    H1_TITLE_3: 'for the public sector',
    WELCOME_TO_OPEN: 'Welcome to Open',
};

export default Constants;
