import React, {
    useContext, useEffect, useRef, useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import Proptypes from 'prop-types';
import { ProjectDataContext } from '../../context/ProjectDataContext';
import {
    getPath, getBreadcrumbs, getName, isOverflowing,
} from './utils';
import Constants from '../../config/route.constants';
import AuthContext from '../../context/AuthContext';
import BreadcrumbItem from './BreadcrumbItem';

const Breadcrumbs = ({ routes = Constants.routes }) => {
    const match = useRouteMatch();
    const [crumbs, setCrumbs] = useState([]);
    const authContext = useContext(AuthContext);
    const { projectData } = useContext(ProjectDataContext);
    const breadcrumbRefs = useRef([]);

    useEffect(() => {
        const route = routes.find(({ path }) => path === match.path);

        if (!route?.breadcrumbs?.length) return;

        const userType = authContext?.user?.accountType;
        const breadcrumbs = getBreadcrumbs(route, match, userType, projectData);

        const formattedCrumbs = [
            ...breadcrumbs.map(({ path, name }) => ({
                path: getPath(path, match, projectData),
                name: getName(name, projectData),
            })),
            { path: '', name: getName(route.name, projectData) },
        ];

        const invalidPath = formattedCrumbs.some((item) => item.path.includes('undefined'));

        if (!invalidPath) {
            setCrumbs(formattedCrumbs);
        }
    }, [projectData]);


    useEffect(() => {
        breadcrumbRefs.current.forEach((el, index) => {
            if (el && isOverflowing(el)) {
                el.setAttribute('data-title', crumbs[index].name);
            }
        });
    }, [crumbs]);


    return (
        crumbs.length > 1 && (
            <div className='breadcrumbsContainer body'>
                {crumbs.map(({ name, path }, index) => (
                    <BreadcrumbItem
                        key={`${name}-${index}`}
                        name={name}
                        path={path}
                        isLast={index === crumbs.length - 1}
                        ref={(el) => { breadcrumbRefs.current[index] = el; }}
                    />
                ))}
            </div>
        )
    );
};

Breadcrumbs.propTypes = {
    routes: Proptypes.array,
};

export default Breadcrumbs;
