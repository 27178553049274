import notificationsManagementAPIs from '../../services/notifications.service';

const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

const fetchNotificationsSuccess = (data) => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: data,
});

const fetchNotificationsFailure = (error) => ({
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
});

const fetchData = () => async (dispatch) => {
    const responseData = await notificationsManagementAPIs.getAll();
    if (responseData.status === 200 && responseData.data.length > 0) {
        dispatch(fetchNotificationsSuccess(responseData.data));
    } else {
        dispatch(fetchNotificationsFailure('Unable to get Notifications. Please try again later'));
    }
};

export {
    FETCH_NOTIFICATIONS_FAILURE, FETCH_NOTIFICATIONS_SUCCESS, fetchData,
    fetchNotificationsFailure, fetchNotificationsSuccess,
};
