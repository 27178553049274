import { FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_FAILURE } from './actions';

const initialState = {
    notifications: undefined,
    error: '',
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications: action.payload,
                error: '',
            };
        case FETCH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                notifications: undefined,
                error: action.payload,
            };
        case 'clearNotifications':
            return {
                ...state,
                notifications: undefined,
                error: '',
            };
        default:
            return state;
    }
};

export default notificationsReducer;
