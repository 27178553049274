import projectService from '../../services/project.service';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import ToastConstants from '../../components/Alerts/Toast/constants';
import Constants from './constants';
import userAndDepartmentsHelper from '../AddUsersAndDepartmentsToDirectAwardModal/usersAndDepartmentsHelper';

const removeUsersAndDepartmentsSelection = (elements, pillID) => elements.map((el) => {
    const elem = { ...el };
    if (el.classificationID === pillID) {
        elem.selected = false;
    }
    if (el.children) {
        elem.children = removeUsersAndDepartmentsSelection(el.children, pillID);
    }
    return elem;
});

const selectSupplier = async (supplier, rtm, projectID, eventID,
    lotID, opportunityID, history) => {
    const departments = userAndDepartmentsHelper
        .mapUsersAndDepartments(supplier.usersAndDepartments);
    const response = projectService.isRTMDirectAward(rtm)
        ? await projectManagementAPIs.registerSupplierDirectAward(
            projectID, eventID, supplier.supplierID, supplier.accountName, lotID, departments,
        )
        : await projectManagementAPIs.registerSupplier(
            opportunityID, supplier.supplierID, supplier.accountName, departments,
        );

    if (response.status === 200) {
        Toast.fire({
            icon: ToastConstants.success,
            title: Constants.SUCCESFULLY_ADDED_SUPPLIER,
        });
        history.goBack();
    } else if (response.data && response.data.ErrorCode === '9000') {
        Toast.fire({
            icon: ToastConstants.error,
            title: Constants.SUPPLIER_ALREADY_REGISTERED,
        });
    } else {
        Toast.fire({
            icon: ToastConstants.error,
            title: Constants.FAILED_TO_ADD_SUPPLIER,
        });
    }
};

const mapUsersAndDepartments = (data) => {
    const departments = data.reduce((acc, department) => {
        const users = department.children
            ?.filter((user) => user.selected)
            .map((user) => ({ userID: user.classificationID }));

        if (department.selected || users?.length > 0) {
            const departmentObject = {
                ...(department.selected && {
                    departmentID: department.classificationID,
                }),
                users,
            };
            acc.push(departmentObject);
        }
        return acc;
    }, []);

    return departments;
};

const isSupplierRefined = (usersAndDepartments) => (usersAndDepartments
    && (usersAndDepartments.find((department) => department.selected)
    || usersAndDepartments.flatMap(
        (department) => department.children,
    ).find((user) => user.selected)));

const removePill = (cardID, pillID, filteredData, setFilteredData) => {
    const newData = filteredData.map((item) => {
        const newItem = { ...item };
        if (item.supplierID === cardID) {
            const newUsersAndDepartments = removeUsersAndDepartmentsSelection(
                item.usersAndDepartments, pillID,
            );
            newItem.usersAndDepartments = newUsersAndDepartments;
            return newItem;
        }
        return newItem;
    });
    setFilteredData(newData);
};

const addUsersAndDepartmentsToMap = (data, cardID, usersAndDepartments) => (data.map((item) => {
    if (item.supplierID === cardID) {
        return {
            ...item, usersAndDepartments,
        };
    }
    return item;
}));


export {
    selectSupplier,
    removeUsersAndDepartmentsSelection,
    mapUsersAndDepartments,
    isSupplierRefined,
    removePill,
    addUsersAndDepartmentsToMap,
};
