import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// import StatusLabel from '../../../../components/StatusLabel';
// import Button from '../../../../components/Button';
import DashboardCard from '../../../../components/DashboardCard';

const Suppliers = ({
    id,
    contractDetails,
    headerTitle,
    actionBtn,
    actionBtnText,
    handlerActionBtn,
    icon,
    iconSrc,
    additionalVariant,
    data,
}) => {
    const history = useHistory();
    const suppliersContent = (suppliers) => {
        if (suppliers?.filter((supplier) => supplier.supplierStatus === 'active').length === 0) {
            return <div className='coSupplierItem'><p className='title'>No active Awarded Suppliers.</p></div>;
        }
        return suppliers.filter((supplier) => supplier.supplierStatus === 'active').map((supplier, idx) => <div key={idx} className='coSupplierItem'>
            <div>
                <p className='title'>{supplier.account?.companyName || supplier.accountID}</p>
                {/* <p className='body'>{supplier.departmentName || supplier.departmentID}</p> */}
                {/* add logic to get dept name from dept id */}
            </div>
            {/* <div className='coSupplierButtonContainer'>
                <Button id='view' variant='tertiary' label={'View Supplier'}
                handleClick={() => history.push(`/supplier/overview/${supplier.accountID}`)} />
                <StatusLabel id={`${idx}-supplierStatus`}
                color={supplier?.supplierStatus === 'active' ? 'green' : 'red'}
                labelTxt={supplier.supplierStatus === 'active' ? 'Active' : 'Deactivated'}/>
            </div> */}
        </div>);
    };
    const lotContent = (lots) => lots.sort((a, b) => a.lotOrderNo - b.lotOrderNo).map((lot, idx) => <div key={idx} className={'coLotItem'}><p className='caption'>{`Lot ${lot.lotOrderNo} : ${lot.lotTitle}`}</p>{suppliersContent(lot.suppliers)}</div>);

    return <DashboardCard
        id={id}
        size='small'
        header={true}
        headerTitle={headerTitle}
        actionBtn={actionBtn}
        actionBtnText={actionBtnText}
        handlerActionBtn={handlerActionBtn}
        iconSrc={iconSrc}
        icon={icon}
        // content={(data && data.length > 0)
        //     ? <div className='suppliersContainer'>{suppliersContent(data)}</div>
        //     : <p className='caption' >No Data for suppliers</p>}
        content={data.length > 0 && contractDetails.publishLevel === 'lot' ? lotContent(data) : suppliersContent(data)}
        additionalVariant={additionalVariant}
        footer={true}
        footerBtnText='View All'
        footerUrl={`/contracts/suppliers/${contractDetails.contractID}`}
        footerBtnHandler={() => history.push(`/contracts/suppliers/${contractDetails.contractID}`)}
    />;
};

Suppliers.propTypes = {
    id: PropTypes.string.isRequired,
    contractDetails: PropTypes.object.isRequired,
    headerTitle: PropTypes.string.isRequired,
    actionBtn: PropTypes.bool.isRequired,
    actionBtnText: PropTypes.string,
    handlerActionBtn: PropTypes.func,
    icon: PropTypes.bool.isRequired,
    iconSrc: PropTypes.string,
    additionalVariant: PropTypes.string,
    data: PropTypes.array.isRequired,
};

export default Suppliers;
