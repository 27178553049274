import React from 'react';
import browseCleverQuotesConstants from './browseCleverQuotesConstants';
import RefineSupplier from '../../features/RefineSupplier';

const contentMap = {
    [browseCleverQuotesConstants.CLEVER_QUOTES]: ({
        data, opportunityID,
        projectID, eventID, suppliers, setSuppliers,
    }) => ({
        link: `/supplier/overview/${data.supplierID}`,
        header: {
            title: data.accountName,
        },
        rows: [
            [
                { label: browseCleverQuotesConstants.noOfEmployees, value: data?.noOfEmployees || '-' },
                { label: browseCleverQuotesConstants.postcode, value: data?.postcode || '-' },
            ],
            [
                { label: browseCleverQuotesConstants.localityName, value: data?.localityName || '-' },
            ],
        ],
        extra: opportunityID
            ? <RefineSupplier
                supplier={data}
                withTags={true}
                filteredData = {suppliers}
                context= {browseCleverQuotesConstants.CLEVER_QUOTES}
                projectID={projectID}
                opportunityID={opportunityID}
                eventID={eventID}
                handleFilteredData = {setSuppliers}
                fromCleverQuotes={true}
            /> : undefined,

    }),

};


export default contentMap;
