import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import PropTypes from 'prop-types';
import StatusLabel from '../../components/StatusLabel';
import Modal from '../../components/Modal';
import { evaluationStatuses, publishAtLevel } from '../../config/constants';
import LabelledTextArea from '../../components/LabelledTextArea';
import rbac from '../../rbac';
import AuthContext from '../../context/AuthContext';
import SupplierItem from './components/SupplierItem';
import Accordion from '../Accordion';
import { evaluationButtons, E_SUMMARY } from './constants';
import SummaryBuyerDPS from '../SummaryBuyerDPS/index';
import {
    getOpenNodes, getLocalStorageProperty,
    updateAndSaveOpenNodesInLocalStorage,
} from '../../services/localstorage.service';

const SummaryBuyer = ({
    projectDetails,
    dataSet,
    handleStatusUpdate,
    handleDisqualifySupplier,
    isDPS,
    exportData,
    readOnly,
    handleEvaluationMultipleDownload,
    isProjectDPS,
    publishLevel,
    previousResponses,
    eventId,
}) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const [disqualifyModal, setDisqualifyModal] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState([]);
    const [userSteeringRole, setUserSteeringRole] = useState();

    const localStorageProperty = getLocalStorageProperty(E_SUMMARY, authContext.user.id,
        projectDetails?.projectID, eventId);


    const getSuppliersListLot = (data) => {
        const list = [];
        data?.responses?.forEach((lot) => {
            lot?.responses?.forEach((supplier) => {
                const listFilter = list.filter((item) => item.value === supplier.accountID);
                if (listFilter.length === 0) {
                    list.push({
                        name: supplier.account.companyName,
                        value: supplier.accountID,
                        supplierDisqualified: supplier?.supplierDisqualified && true,
                    });
                }
            });
        });
        setSuppliers(list);
    };

    const getSuppliersList = (data) => {
        const list = [];
        data.responses.forEach((response) => {
            const listObject = {
                name: response?.account?.companyName,
                value: response?.account?.accountID,
                supplierDisqualified: response?.supplierDisqualified && true,
            };
            list.push(listObject);
        });
        setSuppliers(list);
    };

    useEffect(() => {
        if (dataSet.publishLevel && dataSet.publishLevel === 'lot') {
            getSuppliersListLot(dataSet);
        } else {
            getSuppliersList(dataSet);
        }
    }, [dataSet]);

    useEffect(() => {
        const userRole = rbac.util.getSteeringGroupRole(
            authContext.user.id,
            projectDetails?.steeringInfo,
        );
        setUserSteeringRole(userRole);
    }, [projectDetails]);

    // We need to loop through each response and ensure the lowest level eval status is shown.
    const getEvaluationStatus = (data) => {
        const evalStatusArray = [];
        data.forEach((item) => {
            item.responses.forEach((response) => {
                evalStatusArray.push(response.evaluationStatus);
            });
        });
        const evalCompleteArray = evalStatusArray.filter(
            (status) => status === evaluationStatuses.evaluationComplete,
        );
        if (evalCompleteArray.length === evalStatusArray.length) {
            return true;
        }
        return false;
    };

    const generateHeaderButtons = () => {
        // This also might differ for legal view.
        if (dataSet.isDeadlinePassed && dataSet.isDeadlinePassed === true) {
            let response = {};
            if (dataSet.legalUserView && dataSet.legalUserView === true && !isDPS && !readOnly) {
                response = {
                    legalStatus: dataSet.responses[0].legalStatus,
                };
            } else if (dataSet.publishLevel === 'project' && !isDPS && !readOnly) {
                response.legalStatus = dataSet?.responses[0].responses[0].legalStatus || undefined;
                response.evaluationStatus = dataSet?.responses[0].responses[0].evaluationStatus
                || undefined;
                // Cannot progress past underEvaluation unless all of them are complete.
                if (response.evaluationStatus === evaluationStatuses.evaluationComplete) {
                    const result = getEvaluationStatus(dataSet.responses);
                    if (result === false) {
                        response.evaluationStatus = evaluationStatuses.underEvaluation;
                    }
                }
            } else if (!isDPS && !readOnly) {
                response.legalStatus = dataSet?.responses[0]?.responses[0]?.responses[0].legalStatus
                || undefined;
                response.evaluationStatus = dataSet?.responses[0]?.responses[0]?.responses[0]
                    ?.evaluationStatus || undefined;
                if (response.evaluationStatus === evaluationStatuses.evaluationComplete) {
                    dataSet.responses.forEach((lot) => {
                        const result = getEvaluationStatus(lot.responses);
                        if (result === false) {
                            response.evaluationStatus = evaluationStatuses.underEvaluation;
                        }
                    });
                }
            }
            switch (response.evaluationStatus || response.legalStatus || undefined || isDPS) {
                case evaluationStatuses.sealed:
                    return <>
                        <div className='sbContentHeader'>
                            <div className='headerButtonContainer'>
                                <StatusLabel
                                    id='esHeaderLabel'
                                    color='amber'
                                    labelTxt='Seals Not Opened'
                                />
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.openAllSeal, userSteeringRole)
                                && evaluationButtons.getOpenAndReleaseButton(handleStatusUpdate)}
                            </div>
                        </div>
                    </>;
                case evaluationStatuses.released:
                    return <>
                        <div className='sbContentHeader'>
                            <div className='headerButtonContainer'>
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.disqualifySuppliers, userSteeringRole)
                            && evaluationButtons.getDisqualifySupplierButton(setDisqualifyModal)}
                                {rbac.can(rbac.action.exportData, userSteeringRole)
                                && evaluationButtons.getExportButton(exportData)}
                                {rbac.can(rbac.action.downloadAll, userSteeringRole)
                                && evaluationButtons.getDownloadAllButton(
                                    handleEvaluationMultipleDownload,
                                    evaluationButtons.downloadAllLabel,
                                    dataSet?.responses, projectDetails.title,
                                )
                                }
                            </div>
                        </div>
                    </>;
                case evaluationStatuses.notStarted:
                    return <>
                        <div className='sbContentHeader'>
                            <div className='headerButtonContainer'>
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.disqualifySuppliers, userSteeringRole)
                            && evaluationButtons.getDisqualifySupplierButton(setDisqualifyModal)}
                                {rbac.can(rbac.action.exportData, userSteeringRole)
                                && evaluationButtons.getExportButton(exportData)}
                                {rbac.can(rbac.action.downloadAll, userSteeringRole)
                                && evaluationButtons.getDownloadAllButton(
                                    handleEvaluationMultipleDownload,
                                    evaluationButtons.downloadAllLabel,
                                    dataSet?.responses, projectDetails.title,
                                )}
                            </div>
                        </div>

                    </>;
                case evaluationStatuses.underEvaluation:
                    return <>
                        <div className='sbContentHeader'>
                            <div className='headerButtonContainer'>
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.disqualifySuppliers, userSteeringRole)
                            && evaluationButtons.getDisqualifySupplierButton(setDisqualifyModal)}
                                {rbac.can(rbac.action.exportData, userSteeringRole)
                                && evaluationButtons.getExportButton(exportData)}
                                {rbac.can(rbac.action.downloadAll, userSteeringRole)
                                && evaluationButtons.getDownloadAllButton(
                                    handleEvaluationMultipleDownload,
                                    evaluationButtons.downloadAllLabel,
                                    dataSet?.responses, projectDetails.title,
                                )}
                            </div>
                        </div>
                    </>;
                case evaluationStatuses.evaluationComplete:
                    return <>
                        <div className='sbContentHeader'>
                            <div className='headerButtonContainer'>
                                <StatusLabel
                                    id='esHeaderLabel'
                                    color='amber'
                                    labelTxt='Approval Pending'
                                />
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.disqualifySuppliers, userSteeringRole)
                                && evaluationButtons.getDisqualifySupplierButton(setDisqualifyModal)
                                }
                                {rbac.can(rbac.action.exportData, userSteeringRole)
                                && evaluationButtons.getExportButton(exportData)}
                                {rbac.can(rbac.action.downloadAll, userSteeringRole)
                                && evaluationButtons.getDownloadAllButton(
                                    handleEvaluationMultipleDownload,
                                    evaluationButtons.downloadAllLabel,
                                    dataSet?.responses, projectDetails.title,
                                )}
                                {rbac.can(
                                    rbac.action.submitEvaluationsForApproval,
                                    userSteeringRole,
                                )
                                && evaluationButtons.getSubmitForApprovalButton(handleStatusUpdate)}
                            </div>
                        </div>
                    </>;
                case evaluationStatuses.awaitingApproval:
                    return <>
                        <div className='sbContentHeader'>
                            <div className='headerButtonContainer'>
                                {rbac.can(rbac.action.approveStage1, userSteeringRole)
                        && <StatusLabel
                            id='esHeaderLabel'
                            color='red'
                            labelTxt='Action Needed'
                        />}
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.approveStage1, userSteeringRole)
                        && <>
                            {evaluationButtons.getStage1RejectButton(handleStatusUpdate)}
                            {evaluationButtons.getStage1ApproveButton(handleStatusUpdate)}
                        </>}
                            </div>
                        </div>
                    </>;
                case evaluationStatuses.stage1Approved:
                    return <>
                        <div className='sbContentHeader'>
                            <div className='headerButtonContainer'>
                                {rbac.can(rbac.action.approveStage2, userSteeringRole)
                        && <StatusLabel
                            id='esHeaderLabel'
                            color='red'
                            labelTxt='Action Needed'
                        />}
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.approveStage2, userSteeringRole)
                        && <>
                            {evaluationButtons.getStage2RejectButton(handleStatusUpdate)}
                            {evaluationButtons.getStage2ApproveButton(handleStatusUpdate)}
                        </>}
                            </div>
                        </div>
                    </>;
                case evaluationStatuses.approved:
                    return <div className='sbContentHeader'>
                        <div className='headerEvaluationContainer'>
                            <p className='title-large'>Evaluation Complete</p>
                            <div className='headerButtonContainer'>
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.exportData, userSteeringRole)
                                && evaluationButtons.getExportButton(exportData)}
                                {rbac.can(rbac.action.downloadAll, userSteeringRole)
                                && evaluationButtons.getDownloadAllButton(
                                    handleEvaluationMultipleDownload,
                                    evaluationButtons.downloadAllLabel,
                                    dataSet?.responses, projectDetails.title,
                                )}
                            </div>
                        </div>
                    </div>;
                case isDPS:
                    return <div className='sbContentHeader'>
                        <div className='headerEvaluationContainer'>
                            <p className='title-large'>Evaluation Complete</p>
                            <div className='headerButtonContainer'>
                                {evaluationButtons.getBackButton(() => history.goBack())}
                                {rbac.can(rbac.action.exportData, userSteeringRole)
                                    && evaluationButtons.getExportButton(exportData)}
                                {rbac.can(rbac.action.downloadAll, userSteeringRole)
                                    && evaluationButtons.getDownloadAllButton(
                                        handleEvaluationMultipleDownload,
                                        evaluationButtons.downloadAllLabel,
                                        dataSet?.responses, projectDetails.title,
                                    )}
                            </div>
                        </div>
                    </div>;
                default:
                    return <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            {evaluationButtons.getBackButton(() => history.goBack())}
                        </div>
                    </div>;
            }
        }
        return <></>;
    };

    const getLotActions = (data, idx) => {
        const incompleteEvaluation = [];
        data.responses.forEach((supplier) => {
            supplier.responses.forEach((response) => {
                if (isDPS === true) {
                    if (response?.evaluationStatus !== evaluationStatuses.approved) {
                        incompleteEvaluation.push(response);
                    }
                    if (response?.evaluationStatus === evaluationStatuses.awaitingApproval
                        && rbac.can(rbac.action.approveStage1, userSteeringRole)) {
                        incompleteEvaluation.push(response);
                    }
                    if (response?.evaluationStatus === evaluationStatuses.stage1Approved
                        && rbac.can(rbac.action.approveStage2, userSteeringRole)) {
                        incompleteEvaluation.push(response);
                    }
                } else if (response?.evaluationStatus === (evaluationStatuses.notStarted
                    || evaluationStatuses.underEvaluation)) {
                    incompleteEvaluation.push(response);
                }
            });
        });

        switch (true) {
            case (incompleteEvaluation.length > 0):
                return <StatusLabel
                    id={idx}
                    color='red'
                    labelTxt='Incomplete Evaluations'
                />;
            default: return <></>;
        }
    };

    const generateLotAccordionData = (data, idx) => ([{
        sectionId: data.lotID,
        header: <><p className='headerTitle title lot-title'>{data.lotTitle}</p>{getLotActions(data, idx)}</>,
        content: data.responses?.map((supplierItem, idx0) => (
            <SupplierItem
                key={`supplier-item-${idx0}`}
                id={data.lotID}
                item={supplierItem}
                isDPS={isDPS}
                projectDetails={projectDetails}
                isLotLevel={true}
                handleStatusUpdate={handleStatusUpdate}
                handleDisqualifySupplier={handleDisqualifySupplier}
                readOnly={readOnly}
                userSteeringRole={userSteeringRole}
                openNodes = {getOpenNodes(localStorageProperty)}
                handleSaveOpenNodesInLocalStorage={(elementId,
                    status) => updateAndSaveOpenNodesInLocalStorage(localStorageProperty, elementId,
                    status)}
            />
        )),
    }]);

    const generateLotAccordion = () => <section className='sbContentSection' key={'section-lot'}>
        <div className='sbContentBody'> {dataSet?.responses?.map((data, idx) => <Accordion id='sbAccordion'
            key={'lot-accordion'}
            data={generateLotAccordionData(data, idx)}
            open= {getOpenNodes(localStorageProperty)?.includes(data.lotID)}
            handleToogleAccordion={(elementId,
                status) => updateAndSaveOpenNodesInLocalStorage(localStorageProperty, elementId,
                status)}
            clickOnExpandIcon={false}
        />)}
        </div>
    </section>;


    const generateProjectAccordion = () => dataSet?.responses?.map(
        (supplierItem, idx) => <SupplierItem
            key={`supplier-item-${idx}`}
            id={idx}
            item={supplierItem}
            isDPS={isDPS}
            projectDetails={projectDetails}
            isLotLevel={false}
            handleStatusUpdate={handleStatusUpdate}
            handleDisqualifySupplier={handleDisqualifySupplier}
            readOnly={readOnly}
            userSteeringRole={userSteeringRole}
            openNodes={getOpenNodes(localStorageProperty)}
            handleSaveOpenNodesInLocalStorage={(elementId,
                status) => updateAndSaveOpenNodesInLocalStorage(localStorageProperty, elementId,
                status)}
        />,
    );

    const generateLegalUserContent = () => <>
        {dataSet.responses.map(
            (supplierItem, idx) => <>
                <div className='sbLegalViewContainer' id={idx}>
                    <p className='body companyName'>{supplierItem?.account?.companyName || ''}</p>
                    <div className='statusLabel'>
                        {supplierItem?.legalStatus?.trim() !== ''
                            && <StatusLabel
                                id={`legalStatus-${idx}`}
                                color={supplierItem?.legalStatus === evaluationStatuses.sealed ? 'red' : 'green'}
                                labelTxt={supplierItem?.legalStatus === evaluationStatuses.sealed ? 'Sealed' : 'Released'}
                            />}
                        {isDPS
                            && supplierItem?.legalStatus === evaluationStatuses.sealed
                            && evaluationButtons.getOpenAndReleaseButton(
                                handleStatusUpdate,
                                supplierItem.accountID,
                            )}
                    </div>
                </div>
            </>,
        )}
    </>;

    const handleChange = (event) => {
        const { name } = event.target;
        const { value } = event.target;
        setSelectedSupplier({
            ...selectedSupplier,
            [name]: value,
        });
    };

    const generateModalBody = () => <>
        <div className='disqualifySuppliersModal'>
            <p className='body'>Please select the supplier you want to disqualify from this opportunity. If they have multiple submissions, they will be marked as unsuccessful in all of them.</p>
            <div className='disqualifySelect'>
                <p className='title selectSearchTitle'>Select Supplier</p>
                <SelectSearch
                    id='disqualifySuppliers-SelectSearch'
                    options={suppliers || []}
                    closeOnSelect={false}
                    printOptions='on-focus'
                    search
                    placeholder='Select Supplier'
                    onChange={(_event, data) => setSelectedSupplier(data)}
                    z-index='40'
                />
            </div>
            <div className='disqualifyItem'>
                {selectedSupplier && <>
                    {selectedSupplier.supplierDisqualified
                        ? <p className='body'>This supplier is already disqualified</p>
                        : <><p className = 'label caption'>Reason for disqualification:</p>
                            <LabelledTextArea
                                id={'internalReason'}
                                placeholder='Enter reason'
                                onChange={(e) => handleChange(e)}
                                value={selectedSupplier.internalReason}
                            /></>
                    }
                </>
                }
            </div>
        </div>
    </>;


    const getEvaluationSummaryNoDPS = () => {
        const options = {
            [publishAtLevel.lot]: generateLotAccordion,
            [publishAtLevel.project]: generateProjectAccordion,
        };
        return options[publishLevel]() || '';
    };

    const summaryBuyerDPSComponent = () => <SummaryBuyerDPS
        publishLevel={publishLevel}
        currentResponses={dataSet}
        previousResponses={previousResponses}
        readOnly={readOnly}
        userSteeringRole={userSteeringRole}
        localStorageProperty = {localStorageProperty}
        handleStatusUpdate={handleStatusUpdate}
        handleDisqualifySupplier={handleDisqualifySupplier}
        handleSaveOpenNodesInLocalStorage={(elementId,
            status) => updateAndSaveOpenNodesInLocalStorage(localStorageProperty, elementId,
            status)}
    />;

    const getEvaluationSummaryDPS = () => {
        const options = {
            [publishAtLevel.project]: generateProjectAccordion,
            [publishAtLevel.lot]: summaryBuyerDPSComponent,
        };
        return options[publishLevel]() || '';
    };

    const getEvaluationSummaryContent = () => {
        if (isProjectDPS) {
            return getEvaluationSummaryDPS();
        }
        return getEvaluationSummaryNoDPS();
    };

    return <>
        {generateHeaderButtons()}

        <p className='caption flex-container description'>
            There {`${dataSet?.numSubmissions === 1 ? 'is' : 'are'}`} {dataSet?.numSubmissions} {`${dataSet?.numSubmissions === 1 ? 'submission' : 'submissions'}`}
        </p>

        {dataSet?.legalUserView === true
            ? generateLegalUserContent()
            : getEvaluationSummaryContent()
        }

        {disqualifyModal
        && <Modal
            open={true}
            closeModal={() => {
                setSelectedSupplier();
                setDisqualifyModal(false);
            }}
            size='medium'
            headerTitle='Disqualify Supplier'
            handleMainActionBtnClick={() => {
                handleDisqualifySupplier({
                    supplierAccountID: selectedSupplier.value,
                    internalReason: selectedSupplier.internalReason,
                });
                setSelectedSupplier();
                setDisqualifyModal(false);
            }}
            mainActionButtonTxt='Confirm'
            mainActionButtonDisable={(!selectedSupplier
                || selectedSupplier.supplierDisqualified === true)}
            closeButton={false}
            body={generateModalBody()}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='Cancel'
            handleSecondaryActionBtnClick={() => {
                setSelectedSupplier();
                setDisqualifyModal(false);
            }}
        />}
    </>;
};

SummaryBuyer.propTypes = {
    projectDetails: PropTypes.object,
    dataSet: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    handleStatusUpdate: PropTypes.func.isRequired,
    handleAwardsUpdate: PropTypes.func.isRequired,
    handleSendAwards: PropTypes.func.isRequired,
    handleDisqualifySupplier: PropTypes.func.isRequired,
    isDPS: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    exportData: PropTypes.func.isRequired,
    handleEvaluationMultipleDownload: PropTypes.func.isRequired,
    isProjectDPS: PropTypes.bool,
    publishLevel: PropTypes.string,
    previousResponses: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    eventId: PropTypes.string,
};

export default SummaryBuyer;
