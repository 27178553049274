import React from 'react';
import MuiAccordion from '../../components/Accordion';
import StatusLabel from '../../components/StatusLabel';
import { BUYER_STATUS } from '../Correspondence/ViewCorrespondence/constants';

const getStatusPills = (buyerStatus, id) => (buyerStatus ? <StatusLabel
    id={`status-label-${id}`}
    color={BUYER_STATUS[buyerStatus].color}
    labelTxt={BUYER_STATUS[buyerStatus].label}/>
    : <></>);

const generateSectionsWithNewAccordion = ({
    data, id, open, toggleNewAccordion, clickOnExpandIcon,
}) => data.map((sectionData, idx) => {
    const statusPills = getStatusPills(sectionData.buyerStatus, id);
    return <MuiAccordion
        key={sectionData.sectionId}
        sectionData={sectionData} idx={idx} statusPills={statusPills}
        open={open} id={sectionData.sectionId}
        handleClickAccordion={toggleNewAccordion}
        clickOnExpandIcon={clickOnExpandIcon}
    />;
});

export default generateSectionsWithNewAccordion;
