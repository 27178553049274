import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import informationIcon from '../../styles/icons/blue/info.svg';

export const LabelledInputTypeText = 'text';
export const LabelledInputTypeTel = 'tel';
export const LabelledInputTypeEmail = 'email';
export const LabelledInputTypeSubmit = 'submit';
export const LabelledInputTypePassword = 'password';
export const LabelledInputTypeNumber = 'number';
export const LabelledInputTypeCurrency = 'currency';
export const LabelledInputTypeFile = 'file';
export const LabelledInputTypeRadio = 'radio';
export const LabelledInputTypeCheckBox = 'checkbox';

const LabelledInput = ({
    id,
    type,
    label,
    breakColumn,
    onChange,
    onBlur = () => {},
    placeholder,
    value,
    readonly = false,
    required = true,
    minNumber,
    pattern = '',
    title = '',
    maxNumber,
    maxLength,
    multipleFiles = false,
    inputReference = null,
    tooltip = false,
    tooltipMessage = '',
    step = undefined,
}) => {
    const patternAttrs = {};
    if (pattern !== '' && title !== '') {
        patternAttrs.pattern = pattern;
        patternAttrs.title = title;
    }
    return (<div className='labelled-input labelled-item'>
        {label
        && <label
            className='form-label title flex-container'
            id={`${id}-input-label`}
            htmlFor={`${id}-input`}>{label}{required ? null : <span className='caption'> (Optional)</span>}
            {tooltip && <div className='info-tooltip' data-tooltip={tooltipMessage}>
                <img id='score-info-tooltip' src={informationIcon}
                    alt='info-tooltip' />
            </div>}
        </label>
        }
        {type !== LabelledInputTypeCurrency
            ? <input
                type={type}
                className ={`form-input body-small${readonly ? ' disabled' : ''}`}
                id={`${id}-input`}
                ref={inputReference}
                name={id}
                value={value}
                placeholder={placeholder}
                onChange={(event) => onChange(event)}
                onBlur={(event) => onBlur(event)}
                required={required}
                readOnly={readonly}
                min={minNumber}
                max={maxNumber}
                maxLength={maxLength}
                multiple={multipleFiles}
                {...patternAttrs}
                step={step}
                onWheel={(event) => {
                    if (type === LabelledInputTypeNumber) {
                        event.currentTarget.blur();
                    }
                }}
            />
            : <NumberFormat
                value={value}
                className ={`form-input body-small${readonly ? ' disabled' : ''}`}
                thousandSeparator={true}
                id={`${id}-input`}
                placeholder={placeholder}
                prefix={'£ '}
                onValueChange={(values) => {
                    const eve = {
                        target: {
                            name: id,
                            value: values.value,
                        },
                    };
                    onChange(eve);
                }}
                required={required}
                readOnly={readonly}
                allowNegative={false}
            />
        }

        {breakColumn && <div className='flex-break' />}
    </div>);
};

LabelledInput.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    breakColumn: PropTypes.bool.isRequired,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    pattern: PropTypes.string,
    title: PropTypes.string,
    readonly: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    minNumber: PropTypes.number,
    maxNumber: PropTypes.number,
    maxLength: PropTypes.number,
    multipleFiles: PropTypes.bool,
    inputReference: PropTypes.func,
    tooltip: PropTypes.bool,
    tooltipMessage: PropTypes.string,
    step: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

export default LabelledInput;
