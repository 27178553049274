import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import SupplierCurrentSubmission from './SupplierCurrentSubmission';
import SupplierPreviousSubmissions from './SupplierPreviousSubmissions';
import SupplierDataUpload from '../../SummaryBuyerAward/components/SupplierDataUpload';
import {
    getSupplierLabel,
    getSupplierOrSubmissionsMenu,
    getSupplierTree,
    getFirstLotIdCurrentSubmission,
} from '../helpers';
import { lotSubmissionIsIncomplete } from '../../../services/award.service';
import TreeViewer from '../../../components/TreeViewer/TreeViewer';
import { getOpenNodes } from '../../../services/localstorage.service';

const SupplierSubmissions = ({
    projectDetails,
    awardInfo,
    awardSupplier,
    index,
    userPermissions,
    readOnly,
    handleStatusUpdate,
    uploadIntentLetter,
    deleteIntentLetter,
    localStorageProperty,
    handleAwardModal,
    handleClickAction,
    handleSaveOpenNodesInLocalStorage,
    showEditButton,
}) => {
    const [supplierInfo, setSupplierInfo] = useState();
    const openNodes = getOpenNodes(localStorageProperty);

    const getSubmissionsTree = () => <>
        <SupplierCurrentSubmission
            key = {uuidv4()}
            awardInfo={awardInfo}
            supplier={awardSupplier}
            readOnly={readOnly}
            index={index}
            userPermissions={userPermissions}
            handleActionClick={handleClickAction}
            showEditButton={showEditButton}
        />
        {awardSupplier.currentSubmission
        && !lotSubmissionIsIncomplete(awardSupplier.currentSubmission)
        && <div className='supplier-data-upload' id = {`supplier-data-upload-${index}`}>
            <div className='supplier-data-upload-divs'>
                <SupplierDataUpload
                    key = {uuidv4()}
                    dataSet = {awardInfo.awardInfo}
                    data = {awardSupplier.currentSubmission}
                    userSteeringInfo = {projectDetails.steeringInfo}
                    isDPS = {true}
                    readOnly = {readOnly}
                    uploadIntentLetter = {uploadIntentLetter}
                    handleStatusUpdate = {handleStatusUpdate}
                    handleAwardModal = {(accountId) => handleAwardModal(accountId,
                        getFirstLotIdCurrentSubmission(awardSupplier.currentSubmission))}
                    deleteIntentLetter = {deleteIntentLetter}
                />
            </div>
        </div>
        }
        <SupplierPreviousSubmissions
            key = {uuidv4()}
            supplier={awardSupplier}
            readOnly={readOnly}
            index={index}
            userPermissions={userPermissions}
            awardInfo={awardInfo.awardInfo}
            steeringInfo={projectDetails.steeringInfo}
        />
    </>;

    useEffect(() => {
        if (!awardSupplier) {
            return;
        }
        setSupplierInfo({
            supplier: awardSupplier,
            tree: getSubmissionsTree(),
            label: getSupplierLabel(awardSupplier.account?.companyName
                || awardSupplier.accountID),
            ...awardSupplier.currentSubmission && {
                menu: getSupplierOrSubmissionsMenu(readOnly,
                    awardSupplier.currentSubmission?.lotsOrdered[0],
                    userPermissions, `supplier-parent-${ index}`, awardInfo),
            },
        });
    }, [awardSupplier]);


    return (
        <>
            {supplierInfo
        && <TreeViewer
            key = {uuidv4()}
            id = {'tree-suppliers'}
            classAdded='award-summary-tree-supplier'
            data={getSupplierTree(supplierInfo, index, openNodes)}
            hasSearch={false}
            handleStatusNodeOnClick={handleSaveOpenNodesInLocalStorage}
        />
            }
        </>
    );
};

SupplierSubmissions.propTypes = {
    index: PropTypes.number,
    projectDetails: PropTypes.object.isRequired,
    awardSupplier: PropTypes.object,
    awardInfo: PropTypes.object,
    userSteeringRole: PropTypes.array,
    readOnly: PropTypes.bool,
    handleStatusUpdate: PropTypes.func.isRequired,
    uploadIntentLetter: PropTypes.func,
    deleteIntentLetter: PropTypes.func,
    handleAwardModal: PropTypes.func,
    handleClickAction: PropTypes.func.isRequired,
    userPermissions: PropTypes.object,
    localStorageProperty: PropTypes.string,
    handleSaveOpenNodesInLocalStorage: PropTypes.func,
    showEditButton: PropTypes.object,
};
export default SupplierSubmissions;
