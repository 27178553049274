import { useEffect, useState } from 'react';
import projectManagementAPIs from '../services/project-management.service';
import Toast from '../components/Alerts/Toast/Toast';
import ToastConstants from '../components/Alerts/Toast/constants';

const useGetActivityLog = (resourceId, resourceType) => {
    const [state, setState] = useState({
        activityLog: [],
        isLoading: true,
        error: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setState((prev) => ({ ...prev, isLoading: true }));
                const response = await projectManagementAPIs
                    .getActivityLogByResourceID(resourceId, resourceType);

                if (response.status === 200) {
                    setState({
                        activityLog: response.data?.items,
                        isLoading: false,
                        error: null,
                    });
                }
            } catch (error) {
                setState({
                    activityLog: [],
                    isLoading: false,
                    error: error.message,
                });
                Toast.fire({
                    icon: ToastConstants.icon,
                    titleText: ToastConstants.titleText,
                });
            }
        };

        fetchData();
    }, [resourceId, resourceType]);

    return state;
};

export default useGetActivityLog;
