const Constants = {
    WELCOME_BACK: 'Welcome back',
    LOGIN_TO_YOUR_ACCOUNT: 'Login to your account',
    NEW_TO_OPEN: 'New to Open?',
    CREATE_AN_ACCOUNT: 'Create an account',
    EMAIL_ADDRESS: 'Email Address',
    ENTER_EMAIL_ADDRESS: 'Enter email address',
};

export default Constants;
