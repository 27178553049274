import React, { useState, useContext } from 'react';
import durhamIcon from '../../styles/images/about-us/Durham.png';
import darlingtonIcon from '../../styles/images/about-us/Darlington.png';
import gatesheadIcon from '../../styles/images/about-us/gateshead-counsil.png';
import hartlepoolIcon from '../../styles/images/about-us/hartlepool.png';
import middlesboroghIcon from '../../styles/images/about-us/middlesborogh.png';
import newcastleCityIcon from '../../styles/images/about-us/newcastle-city.png';
import northTynesideIcon from '../../styles/images/about-us/north-tyneside.png';
import northemberlandIcon from '../../styles/images/about-us/northemberland.png';
import redcarIcon from '../../styles/images/about-us/redcar.png';
import southTynesideIcon from '../../styles/images/about-us/south-tyneside.png';
import stocktonIcon from '../../styles/images/about-us/stockton-on-tees.png';
import sunderlandIcon from '../../styles/images/about-us/sunderland.png';
import chevronLeftIcon from '../../styles/icons/blue/chevron-left.svg';
import chevronRight from '../../styles/icons/blue/chevron-right.svg';
import openTeam from '../../styles/images/openTeam.png';
import coverImage from '../../styles/images/ICT-Services_Transparent BKGRD.gif';
import { ThemeContext } from '../../context/ThemeContext';
import Button from '../../components/Button';
import SupplierRegistration from '../../features/SupplierRegistration';
import constants from './constants';

const AboutOpen = () => {
    const icons = [
        [southTynesideIcon, stocktonIcon, sunderlandIcon],
        [darlingtonIcon, durhamIcon, gatesheadIcon],
        [hartlepoolIcon, middlesboroghIcon, newcastleCityIcon],
        [northTynesideIcon, northemberlandIcon, redcarIcon],
    ];

    const [activeGroupIndex, setActiveGroupIndex] = useState(0);
    const { toggle } = useContext(ThemeContext);
    const [showRegModal, setShowRegModal] = useState(false);
    const toggleRegModal = () => {
        setShowRegModal(!showRegModal);
    };

    const printGroup = (i = 0) => <div className='group'>
        <div className='column'>
            <img src={icons[i][0]} />
        </div>
        <div className='column'>
            <img src={icons[i][1]} />
        </div>
        <div className='column'>
            <img src={icons[i][2]} />
        </div>
    </div>;

    return <div className='bdContentSection'>
        <h2><strong>{constants.UNLOCKING_BETTER_WAYS}</strong> {constants.OF_DOING_PROCUREMENT}</h2>
        <section>
            <p className='about-us-content body'>{constants.P1}</p>
            <p className='about-us-content body'>{constants.P2}  </p>
            <div className='container about-us-content'>
                <button className='prevButton' onClick={() => { setActiveGroupIndex((activeGroupIndex - 1) % 4); }}>
                    <img className='prevButtomImage' src={chevronLeftIcon}/>
                </button>
                { printGroup(activeGroupIndex) }
                <button className='nextButton' onClick={() => { setActiveGroupIndex((activeGroupIndex + 1) % 4); }}>
                    <img className='nextButtomImage' src={chevronRight} />
                </button>
            </div>
        </section>
        <section className='about-us-content about-us-means'>
            <div className=''>
                <h3>{constants.WHAT_THIS_MEANS} <strong>{constants.BUYERS}</strong></h3>
                <ul className='body'>
                    <li>{constants.BUYERS_LI_1}</li>
                    <li>{constants.BUYERS_LI_2}</li>
                    <li>{constants.BUYERS_LI_3}</li>
                    <li>{constants.BUYERS_LI_4}</li>
                </ul>
            </div>
            <div className=''>
                <h3>{constants.WHAT_THIS_MEANS} <strong>{constants.SUPPLIERS}</strong></h3>
                <ul className='body'>
                    <li>{constants.SUPPLIERS_LI_1}</li>
                    <li>{constants.SUPPLIERS_LI_2}</li>
                    <li>{constants.SUPPLIERS_LI_3}</li>
                    <li>{constants.SUPPLIERS_LI_4}</li>
                </ul>
            </div>

        </section>
        <h2><strong>{constants.MEET_THE_TEAM}</strong> {constants.BEHIND_OPEN}</h2>
        <img src={openTeam} className='about-us-content' title={constants.OPEN_TEAM} alt={`${constants.IMG_FOOTER_1} ${constants.IMG_FOOTER_2}`}/>
        <p className='about-us-content body img-footer'>{constants.IMG_FOOTER_1}<br/>
            {constants.IMG_FOOTER_2}</p>

        <section className='about-us-banner'>
            <div>
                <label>{constants.START_YOUR_JOURNEY}</label>
                <Button
                    className={`started-button ${toggle ? 'new-started-button' : ''}`}
                    handleClick={toggleRegModal}
                    label={constants.GET_STARTED}>
                </Button>
            </div>
            <img src={coverImage} alt={constants.START_YOUR_JOURNEY}></img>
        </section>
        {showRegModal && <SupplierRegistration showRegModal={showRegModal}
            closeModal={toggleRegModal} />}
    </div>;
};

export default AboutOpen;
