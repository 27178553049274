export const Constants = {
    createdBy: 'Created By: ',
    createdOn: 'Created On: ',
    sentTo: 'Sent to: ',
    all: 'All',
    originalTopic: 'Original Topic: ',
    originalTopicLinkTitle: 'Link',
    companySize: 'Company Size: ',
    postcode: 'Postcode: ',
    locality: 'Locality: ',
    selectQuestionnaires: 'questionnairesselect',
    noResults: 'No results found',
    submissionEndDateLabel: 'Submission end date:',
    contractStartDateLabel: 'Contract Start Date:',
    contractEndDateLabel: 'Contract End Date:',
    descriptionLabel: 'Description:',
    addSupplierLabel: 'Add Supplier',
    refineSelectionLabel: 'Refine',
    confirmSelectionLable: 'Confirm',
    OPPORTUNITIES: 'opportunities',
    CONTRACTSREGISTER: 'contractsregister',
    SUPPLIERS: 'suppliers',
    PROJECTS: 'projects',
    REQUESTS: 'requests',
    WORKFLOWS: 'workflows',
    QUESTIONNAIRESSELECT: 'questionnairesselect',
    QUESTIONNAIRES: 'questionnaires',
    SECTIONS: 'sections',
    CONTRACTS: 'contracts',
    CORRESPONDENCE: 'correspondence',
    CALLOFF: 'calloff',
    EVENTS: 'events',
    STAGES: 'stages',
    BROWSEDATACONTEXT: 'activitylogs',
    SPENDS: 'spends',
    REBATES: 'rebates',
    DESCRIPTION: 'Description',
    BUYER: 'Buyer',
    CONTRACT_START_DATE: 'Contract Start Date',
    CONTRACT_END_DATE: 'Contract End Date',
    EXPRESSION_INTEREST_START_DATE: 'Expression of interest start date',
    EXPRESSION_INTEREST_END_DATE: 'Expression of interest end date',
    SUBMISSION_END_DATE: 'Submission end date',
    EVENT: 'Event',
    ESTIMATED_VALUE: 'Estimated Value',
    SOLUTIONS: 'solutions',
    cancelled: {
        title: 'Cancelled',
        value: 'cancelled',
    },
    disabled: {
        title: 'Disabled',
        value: 'disabled',
    },
    draft: {
        title: 'Draft',
        value: 'draft',
    },
    SOLUTION_BY: 'Solution by',
    START_DATE: 'Start Date',
    PROJECT_TYPE: 'Project Type',
    CURRENT_END_DATE: 'Current End Date',
    VIEW: 'view',
    SELECT: 'Select',
    TYPE: 'Type',
    DATE_CREATED: 'Date Created',
    ROLE_RESPONSIBLE: 'Role Responsible',
    EDIT: 'Edit',
    ROUTE_TO_MARKET: 'Route to Market',
    NUMBER_OF_EVENTS: 'Number of Events',
    CREATED_BY: 'Created By',
    CREATED_ON: 'Created On',
    SEND_TO: 'Send To',
    ORIGINAL_TOPIC: 'Original Topic',
    UPLOAD_DATE: 'Upload Date',
    END_DATE: 'End Date',
    REQUEST_START_DATE: 'Request Start Date',
    REQUEST_DURATION: 'Request Duration',
    STAGE: 'Stage',
    OF: 'of',
    EVENT_SELECTED: 'Event Selected',
    SELECT_EVENT: 'Select Event',
    AWARD_DATE: 'Award Date',
    LOCALITY: 'Locality',
    COMPANY_SIZE: 'Company Size',
    POSTCODE: 'Postcode',
    DOWNLOAD: 'Download',
    ID: 'id',
    NAME: 'name',
    NOT_SELECTED: 'Not selected',
    VALUE: 'value',
    LABEL: 'label',
    IMPORT_SECTION: 'Import Section',
    MESSAGE_MAX_CHAR: 525,
    RECIPIENTS_MAX_CHAR: 30,
    ROLE: 'role',
    SELECT_STAGE: 'Select Stage',
    REMOVE: 'Remove',
    NEPO: 'nepo',
    YOU_HAVE_SEEN_IT_ALL: 'You have seen it all.',
    EXPORT: 'Export',
};

export const BIG_SIZE_LOGOS = ['tpplnepo'];
