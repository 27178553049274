import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LabelledInput from '../../../components/LabelledInput';
import LabelledSelect from '../../../components/LabelledSelect';
import LabelledRadioButton from '../../../components/LabelledRadioButton';
// import LabelledCheckBox from '../../../components/LabelledCheckBox';
import Button from '../../../components/Button';
import addEllipseIcon from '../../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../../styles/icons/blue/add-ellipse-2.svg';
// import closeIcon from '../../../styles/icons/grey/close.svg';
import actions from '../slice/supplier-reg.action';
import constants from '../constants';
import GeneralConstants from '../../../utils/generalConstants';
import userManagementAPIs from '../../../services/user-management.service';
import Toast from '../../../components/Alerts/Toast/Toast';
import Modal from '../../../components/Modal';
import Alert from '../../../components/Alerts/Alert';
import { companyTypes } from '../../../config/constants';
import { ThemeContext } from '../../../context/ThemeContext';

const CompanyDetails = ({
    formValues, prev, regionalOfficeFormData, showRO, showROForm, selectedHQROData,
}) => {
    const [formData, setFormData] = useState(formValues);
    const [rOFormData, setROFormData] = useState(regionalOfficeFormData);
    const [showRegionalOffice, setShowRegionalOffice] = useState(showRO);
    const [showNoHQTag, setShowNoHQTag] = useState(false);
    const [showNewRegionalOfficeForm, setShowNewRegionalOfficeForm] = useState(showROForm);
    // const [rOListData, setROListData] = useState();
    const [selectedHQData, setSelectedHQData] = useState();
    const [companyExistData, setCompanyExistData] = useState();
    const [openModalToShowHQ, setOpenModalToShowHQ] = useState(false);
    const [selectHQAccountID, setSelectHQAccountID] = useState('');
    const [selectROAccountID, setSelectROAccountID] = useState('');
    const dispatch = useDispatch();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;
    const persistFormOnChange = (data) => {
        const preparedFormState = {};
        data.map((el) => {
            preparedFormState[el.id] = el.value;
            preparedFormState[`${el.id}-isChanged`] = el.isChanged;
            return el;
        });

        dispatch(actions.setCompanyDetails(preparedFormState));
    };
    const persistROFormOnChange = (data) => {
        const preparedFormState = {};
        data.map((el) => {
            preparedFormState[el.id] = el.value;
            preparedFormState[`${el.id}-isChanged`] = el.isChanged;
            return el;
        });

        dispatch(actions.setNewRODetails(preparedFormState));
    };
    const persistShowROOption = (data) => {
        dispatch(actions.setShowRO({ showRO: data }));

        // clear form value when user select No to RO
        if (!data) {
            const preparedFormState = {};
            rOFormData.map((el) => {
                preparedFormState[el.id] = '';
                preparedFormState[`${el.id}-isChanged`] = el.isChanged;
                return el;
            });

            dispatch(actions.setNewRODetails(preparedFormState));
        }
    };
    const persistShowROFORM = (data) => {
        dispatch(actions.setShowROForm({ showROForm: data }));
    };
    const persistHiddenData = (data) => {
        const hiddenData = {
            accountID: data.accountID || '',
            departments: data.departments || [],
            wishToJoin: data.wishToJoin,
        };
        dispatch(actions.setHiddenDetails(hiddenData));
    };
    const persistSelectedHQROData = (roAccountID) => {
        const companyData = {
            companyExistData,
            selectHQAccountID,
            selectROAccountID: roAccountID || selectROAccountID,
        };
        dispatch(actions.setSelectedHQROData(companyData));
    };

    const countryCodes = GeneralConstants.strings.callingCodes;

    const generateDropdownOptions = (key, option, dropdownType) => ((key > 0)
        ? <option key={`${dropdownType}-${key}`} value={option.value}>{option.label}</option>
        : <><option key={`${dropdownType}-default`} value='default' selected='selected'>{constants.pleaseSelect}</option>
            <option key={`${dropdownType}-${key}`} value={option.value}>{option.label}</option>
        </>);

    // Handling new Regional office form changes
    const handleROInput = (event) => {
        const attribute = event.target.name;
        const value = event.target.value || event.currentTarget.value;


        let updatedROFormData = rOFormData.map((el) => {
            const preparedEl = {};
            if (el.id === attribute) {
                preparedEl.value = value;
                preparedEl.isChanged = true;
            }
            return { ...el, ...preparedEl };
        });


        // Postcode validation...
        const countryData = updatedROFormData
            ?.filter((ele) => ele.id === 'roCountry');
        if (countryData[0]?.value === 'United Kingdom') {
            const pattern = '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$';
            updatedROFormData = updatedROFormData.map((el) => {
                const preparedE2 = {};
                if (el.id === 'roPostcode') {
                    preparedE2.isChanged = true;
                    preparedE2.pattern = pattern;
                    preparedE2.title = 'Please enter a valid UK postcode';
                }
                return { ...el, ...preparedE2 };
            });
        } else {
            updatedROFormData = updatedROFormData.map((el) => {
                let preparedEl = {};
                if (el.id === 'roPostcode') {
                    preparedEl = el;
                    preparedEl.isChanged = true;
                    delete preparedEl.pattern;
                    delete preparedEl.title;
                }
                return { ...el, ...preparedEl };
            });
        }

        setROFormData(updatedROFormData);
        persistROFormOnChange(updatedROFormData);
    };

    const getDropdowns = (element) => {
        const dropdownMap = {
            roBusinessStructure: companyTypes,
            country: constants.countryList,
            roCountry: constants.countryList,
            companyCountryCode: countryCodes,
            roCompanyCountryCode: countryCodes,
        };

        const options = dropdownMap[element];

        if (options) {
            return options.map((elem, index) => generateDropdownOptions(index, {
                label: elem.name || elem,
                value: elem.name || elem,
            }));
        }
        return element;
    };

    const renderInput = (el, idx) => (el.type !== GeneralConstants.TYPE_SELECT
        ? <LabelledInput
            key={`ro-details-inp-${idx}`}
            id={el.id}
            type={el.type}
            label={el.label}
            placeholder={el.placeholder}
            value={el.value}
            pattern={el.pattern}
            title={el.title}
            onChange={handleROInput}
            breakColumn={false}
            required={el.required}
            readonly={el.readonly} />
        : <LabelledSelect id={el.id}
            options={getDropdowns(el.id)}
            label={el.label}
            breakColumn={false}
            required={el.required}
            value={el.value}
            onChange={(e) => handleROInput(e)}
        />);


    // Generating new regional office structure
    const generateNewRegionalOfficeForm = () => <div className='wrapper m-50'>
        {
            rOFormData.filter((elem) => !elem.formGroup).map((el, idx) => <div key={`ro-details-${idx}`} className='ro-input-wrapper'>
                { renderInput(el, idx) }
            </div>)
        }
        {
            <div className='ro-input-wrapper reg-input-grp'>
                {rOFormData.filter((elem) => elem.formGroup)
                    .map((el, idx) => <> { renderInput(el, idx) }</>)
                }
            </div>
        }
    </div>;


    // handling when we clicked on New Regional Office button
    const onNewRegionalOfficeFormChange = () => {
        if (!showNewRegionalOfficeForm) {
            // when newRo form is open unchecked all checkbox
            const cbs = document.getElementsByClassName('roHQCB');
            for (let i = 0; i < cbs.length; i++) {
                cbs[i].checked = false;
            }
            setSelectROAccountID('');
            const hiddenData = {
                accountID: selectHQAccountID || '',
                departments: [],
                wishToJoin: !!selectHQAccountID,
            };
            persistHiddenData(hiddenData);
        } else {
            const updatedROFormData = rOFormData.map((el) => {
                const preparedEl = {};
                preparedEl.value = '';
                preparedEl.isChanged = true;

                return { ...el, ...preparedEl };
            });
            dispatch(actions.setNewRODetails(updatedROFormData));
        }
        setShowNewRegionalOfficeForm(!showNewRegionalOfficeForm);
        persistShowROFORM(!showNewRegionalOfficeForm);
    };

    // handling HQ select when we have multiple HQ (on Modal)-disable multiple checkbox to select
    const handleHQSelect = (e) => {
        setSelectHQAccountID(e.target.value);
        const currentStatus = e.target.checked;
        const cbs = document.getElementsByClassName('roHQCB');
        for (let i = 0; i < cbs.length; i++) {
            cbs[i].checked = false;
        }
        if (cbs.length === 1) {
            e.target.checked = currentStatus;
        } else {
            e.target.checked = true;
        }
    };

    // handling Regional Office select and setting the data
    const handleROSelect = (e) => {
        setSelectROAccountID(e.target.value);
        const currentStatus = e.target.checked;
        const cbs = document.getElementsByClassName('roHQCB');
        for (let i = 0; i < cbs.length; i++) {
            cbs[i].checked = false;
        }
        if (cbs.length === 1) {
            e.target.checked = currentStatus;
        } else {
            e.target.checked = true;
        }
        setShowNewRegionalOfficeForm(false);
        persistShowROFORM(false);
        // clear new roform value if have any value
        const updatedROFormData = rOFormData.map((el) => {
            const preparedEl = {};
            preparedEl.value = '';
            preparedEl.isChanged = true;

            return { ...el, ...preparedEl };
        });
        dispatch(actions.setNewRODetails(updatedROFormData));

        const roList = selectedHQData.regionalOffices;
        const roData = roList.find((o) => o.accountID === e.target.value);

        const hiddenData = {
            accountID: e.target.value,
            departments: roData?.departments,
            wishToJoin: true,
        };
        persistHiddenData(hiddenData);
        persistSelectedHQROData(e.target.value);
    };
    const roHQAddressHtml = (item, isRO) => <>
        <div className='formInputContainer'>
            <div className='labelled-checkBox checkBox-group '>
                <div className='form-checkBox-container'>
                    <input type='checkbox' className='form-checkBox green roHQCB' id={item.name} name={item.name} value={item.accountID}
                        onClick={isRO ? handleROSelect : handleHQSelect}
                        checked={isRO ? item.accountID === selectROAccountID
                            : item.accountID === selectHQAccountID}>

                    </input>
                    <label className='form-label checkBox-label title' id={item.name} htmlFor={item.name}>{item.name}</label>
                </div>
                <div>
                    <label className='form-label checkBox-label title roData' >{item.businessStructure}</label>
                </div>
                <div>
                    <label className='form-label checkBox-label title roData' id={item.address.addressLine1}>{item.address.addressLine1}</label>
                </div>
                {item.address.addressLine2 && <div>
                    <label className='form-label checkBox-label title roData' id={item.address.addressLine2}>{item.address.addressLine2}</label>
                </div>
                }
                <div>
                    <label className='form-label checkBox-label title roData'>{item.address.city}</label>
                </div>
                {item.address.county
                    && <div>
                        <label className='form-label checkBox-label title roData'>{item.address.county}</label>
                    </div>
                }
                <div>
                    <label className='form-label checkBox-label title roData' id={item.address.postcode}>{item.address.postcode}</label>
                </div>

                <div>
                    <label className='form-label checkBox-label title roData'>{item.address.country}</label>
                </div>
                <div>
                    <label className='form-label checkBox-label title roData'>{item.companyCountryCode || ''} {item.phoneNumber || ''}</label>
                </div>
            </div>
        </div>
    </>;

    //  handling content generator for Regional Office List to select
    const getRegionalOfficeList = (regionalOfficeOptionList) => {
        const contentList = [];

        if (regionalOfficeOptionList && regionalOfficeOptionList.length > 0) {
            regionalOfficeOptionList.forEach((item) => {
                const element = roHQAddressHtml(item, true);
                contentList.push(element);
            });
        }
        const contentData = <>
            <div className='formInputContainer'>
                <label className='form-label title' id='regionalOffices-checkBox-label'>Select an existing regional office to join or create a new one</label>
                {regionalOfficeOptionList && regionalOfficeOptionList.length > 0
                    ? contentList
                    : <></>}
            </div>
        </>;
        return regionalOfficeOptionList && regionalOfficeOptionList.length > 0 ? contentData
            : <><label className='form-label title' id='regionalOffices-checkBox-label'>No regional offices found.</label></>;
    };
    const generateRegionalOfficeData = (regionalOffices) => {
        const regionalOfficeOptionList = [];
        regionalOffices.forEach((item) => {
            const optionElement = {
                name: item.companyName || '',
                businessStructure: item.businessStructure,
                companyCountryCode: item.companyCountryCode,
                phoneNumber: item.phoneNumber,
                accountID: item.accountID || '',
                address: {
                    addressLine1: item.address.addressLine1 || '',
                    addressLine2: item.address.addressLine2 || '',
                    postcode: item.address.postcode || '',
                    city: item.address.city,
                    county: item.address.county,
                    country: item.address.country,
                },
            };
            regionalOfficeOptionList.push(optionElement);
        });

        const generateRegionalOfficeList = getRegionalOfficeList(regionalOfficeOptionList);
        return <>
            {generateRegionalOfficeList}
            <div className='ro-btn-wrapper'>
                <Button
                    id='addButton'
                    size='small'
                    variant={`secondary ${!showNewRegionalOfficeForm ? 'skinless' : ''}`}
                    label={`${!showNewRegionalOfficeForm ? 'New Regional Office' : 'Cancel'}`}
                    icon={!showNewRegionalOfficeForm}
                    iconSrc={addIconSrc}
                    disabled={false}
                    handleClick={(e) => {
                        e.preventDefault();
                        onNewRegionalOfficeFormChange();
                    }}
                />
            </div>

        </>;
    };
    const handleInput = (event) => {
        const attribute = event.target.name;
        const value = event.target.value || event.currentTarget.value;


        let updatedFormData = formData.map((el) => {
            const preparedEl = {};
            if (el.id === attribute) {
                preparedEl.value = attribute === 'noOfEmployees' ? Number(value) : value;
                preparedEl.isChanged = true;
            }
            return { ...el, ...preparedEl };
        });


        // Postcode validation...
        const countryData = updatedFormData
            ?.filter((ele) => ele.id === 'country');
        if (countryData[0]?.value === 'United Kingdom') {
            const pattern = '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$';
            updatedFormData = updatedFormData.map((el) => {
                const preparedE2 = {};
                if (el.id === 'postcode') {
                    preparedE2.isChanged = true;
                    preparedE2.pattern = pattern;
                    preparedE2.title = 'Please enter a valid UK postcode';
                }
                return { ...el, ...preparedE2 };
            });
        } else {
            updatedFormData = updatedFormData.map((el) => {
                let preparedEl = {};
                if (el.id === 'postcode') {
                    preparedEl = el;
                    preparedEl.isChanged = true;
                    delete preparedEl.pattern;
                    delete preparedEl.title;
                }
                return { ...el, ...preparedEl };
            });
        }
        setFormData(updatedFormData);
        persistFormOnChange(updatedFormData);
    };

    // const isPostcodeOrCNameEmpty = () => {
    //     const accountName = formData?.filter((ele) => ele.id
    //      === '/registration/company-details/enter');
    //     const postcode = formData?.filter((ele) => ele.id === 'postcode');
    //     return accountName[0]?.value === '' || postcode[0]?.value === '';
    // };

    const validatetoEnableRegionalOffice = () => {
        const accountName = formData?.filter((ele) => ele.id === 'accountName');
        const noOfEmployees = formData?.filter((ele) => ele.id === 'noOfEmployees');
        const addressLine1 = formData?.filter((ele) => ele.id === 'addressLine1');
        const city = formData?.filter((ele) => ele.id === 'city');
        const country = formData?.filter((ele) => ele.id === 'country');
        const postcode = formData?.filter((ele) => ele.id === 'postcode');

        return accountName[0]?.value === '' || postcode[0]?.value === ''
            || noOfEmployees[0]?.value === '' || addressLine1[0]?.value === ''
            || city[0]?.value === '' || country[0]?.value === '';
    };

    // const countriesDropdown = [{ name: '' },
    //     { name: 'United Kingdom' },
    // ];

    // handling when we selected the HQ and clicked on Next
    const onHQSelect = () => {
        setOpenModalToShowHQ(false);
        const companyData = companyExistData.find((o) => o.accountID === selectHQAccountID);
        setSelectedHQData(companyData);
        setShowRegionalOffice(true);
        setShowNoHQTag(false);
        const hiddenData = {
            accountID: selectHQAccountID,
            departments: companyData.departments,
            wishToJoin: true,
        };
        persistHiddenData(hiddenData);
        persistSelectedHQROData();
    };

    // Generate Modal Body when we have multiple headquarter
    const generateModalBody = () => {
        const contentList = [];
        companyExistData.forEach((item) => {
            const element = roHQAddressHtml(item, false);
            contentList.push(element);
        });

        return <>
            <div className='formInputContainer'>
                <label className='form-label caption' id='regionalOffices-checkBox-label'>The details you have entered match with multiple headquarters. Please select your headquarter from the list below</label>
                {contentList}
            </div>

        </>;
    };

    const closeModal = () => {
        setOpenModalToShowHQ(false);
    };

    // Handling  registering or wish to join  to Regional Offices
    // If Yes- show RegionalOffice List if exist
    // If No- hide RegionalOffice List section
    const onRegionalOfficeChange = async (e) => {
        let show = false;
        if (e.target.id === 'yes-register-radio-btn') {
            show = true;
            try {
                const body = {
                    companyName: formData.filter((item) => item.id === 'accountName')[0].value,
                    regNo: formData.filter((item) => item.id === 'registrationNumber')[0].value,
                    postcode: formData.filter((item) => item.id === 'postcode')[0].value,
                };
                let companyExistApiResponse = [];
                const responseData = await userManagementAPIs
                    .checkHQAndListRO(body);

                if (responseData.status === 200 && responseData.data.length === 0) {
                    setShowNoHQTag(true);
                    setShowRegionalOffice(false);
                    Alert('Error',
                        'Your company\'s headquarter is not registered on Open. Please register the headquarter before enrolling a regional office.',
                        'error');
                    setSelectROAccountID('');
                    setSelectHQAccountID('');
                    setSelectedHQData();

                    const hiddenData = {
                        accountID: '',
                        departments: [],
                        wishToJoin: true,
                    };
                    persistHiddenData(hiddenData);
                } else if (responseData.status === 200 && responseData.data.length > 0) {
                    companyExistApiResponse = responseData.data;
                }


                setCompanyExistData(companyExistApiResponse);
                if (companyExistApiResponse.length > 1) {
                    setOpenModalToShowHQ(true);
                } else if (companyExistApiResponse.length === 1) {
                    setSelectHQAccountID(companyExistApiResponse[0].accountID);
                    setSelectedHQData(companyExistApiResponse[0]);
                    setShowRegionalOffice(true);
                    setShowNoHQTag(false);
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        } else {
            setShowNoHQTag(false);
            setSelectROAccountID('');
            setShowRegionalOffice(false);

            const hiddenData = {
                accountID: '',
                departments: [],
                wishToJoin: true,
            };
            persistHiddenData(hiddenData);
        }
        // if (selectHQAccountID) {
        //     setShowRegionalOffice(true);
        // } else {
        //     setShowRegionalOffice(false);
        // }

        if (showNewRegionalOfficeForm && !show) {
            setShowNewRegionalOfficeForm(false);
            persistShowROFORM(false);
        }
        persistShowROOption(show);
    };

    useEffect(() => {
        // setShowRegionalOffice(showRO);
        setShowNewRegionalOfficeForm(showROForm);
        setROFormData(regionalOfficeFormData);
    }, [showRO, showROForm, regionalOfficeFormData]);

    useEffect(() => {
        if (selectedHQROData.selectHQAccountID) {
            const companyData = selectedHQROData.companyExistData;
            setCompanyExistData(companyData);
            const hqData = companyData
                .find((o) => o.accountID === selectedHQROData.selectHQAccountID);
            setSelectedHQData(hqData);
            setSelectHQAccountID(selectedHQROData.selectHQAccountID);
            setSelectROAccountID(selectedHQROData.selectROAccountID);
            // setShowRegionalOffice(true);
        }
    }, []);

    const renderHeadquarterDetailsInput = (el, idx) => (el.type === 'text' || el.type === 'email' || el.type === 'number'
        ? <LabelledInput
            key={`company-details-inp-${idx}`}
            id={el.id}
            type={el.type}
            label={el.label}
            placeholder={el.placeholder}
            value={el.value}
            pattern={el.pattern}
            title={el.title}
            onChange={handleInput}
            breakColumn={false}
            required={el.required}
            readonly={el.readonly || showRegionalOffice === true} />
        : <LabelledSelect id={el.id}
            options={getDropdowns(el.id)}
            label={el.label}
            breakColumn={false}
            required={el.required}
            value={el.value}
            onChange={(e) => handleInput(e)} />
    );

    return <>
        <div className='form-header-wrapper'><h4>{constants.headers.headquarterDetails}</h4> </div>
        {
            formData.filter((elem) => !elem.formGroup).map((el, idx) => <div key={`company-details-${idx}`} className='reg-input-wrapper'>
                {renderHeadquarterDetailsInput(el, idx)}
            </div>)
        }
        {
            <div className='reg-input-wrapper reg-input-grp'>
                {formData.filter((elem) => elem.formGroup).map((el, idx) => <div key={`company-details-${idx}`} className='reg-input-wrapper'>
                    {renderHeadquarterDetailsInput(el, idx)}
                </div>)
                }
            </div>
        }
        <div className='ro-interest-wrapper'>
            <LabelledRadioButton id={'registerInterest'}
                label={'Are you registering a regional office or wish to join one?'}
                breakColumn={true}
                options={[
                    {
                        id: 'yes-register',
                        value: 'restricted',
                        label: 'Yes',
                        checked: showRegionalOffice === true,
                        name: 'radio-register',
                        disabled: validatetoEnableRegionalOffice(),
                    },
                    {
                        id: 'no-register',
                        value: 'none',
                        label: 'No',
                        checked: showRegionalOffice === false,
                        name: 'radio-register',
                        disabled: validatetoEnableRegionalOffice(),
                    }]}
                renderAsRow={true}
                onChange={(e) => onRegionalOfficeChange(e)}
                required={true} />
        </div>
        {showRegionalOffice && !showNoHQTag
            ? generateRegionalOfficeData(selectedHQData?.regionalOffices || [])
            : <></>}
        {/* {showNoHQTag ? <>
            <div id='no-HQ-tag'>
                <p>
                    Your company&#39;s headquarter is not registered on Open.
                    Please register the headquarter
                    before enrolling a regional office.
                </p>
            </div></> : <></>} */}
        {showNewRegionalOfficeForm && showRegionalOffice ? generateNewRegionalOfficeForm() : <></>}
        {openModalToShowHQ && <Modal
            open={true}
            closeModal={closeModal}
            size='large'
            headerTitle='Please select your headquarter'
            handleMainActionBtnClick={() => onHQSelect()}
            mainActionButtonTxt='Next'
            closeButton={false}
            body={generateModalBody()}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='No'
            handleSecondaryActionBtnClick={(e) => { e.preventDefault(); closeModal(); }}
        />}
        <div className='form-btn-wrapper'>
            <Button id='second-action-btn'
                additionalVariant='sup-reg-back'
                variant='secondary'
                label='Back'
                handleClick={(event) => prev(event)} />
            <Button id='main-action-btn'
                label='Continue'
                handleClick={() => null} />
        </div>
    </>;
};


CompanyDetails.propTypes = {
    formValues: PropTypes.array.isRequired,
    prev: PropTypes.func.isRequired,
    regionalOfficeFormData: PropTypes.func.isRequired,
    showRO: PropTypes.func.isRequired,
    showROForm: PropTypes.func.isRequired,
    selectedHQROData: PropTypes.func.isRequired,
};
export default CompanyDetails;
