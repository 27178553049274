import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelectSearch, { fuzzySearch } from 'react-select-search/dist/cjs';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import { format } from 'date-fns';
import Modal from '../../components/Modal';
import LabelledInput from '../../components/LabelledInput';
import LabelledSelect from '../../components/LabelledSelect';
import Form from '../../components/Form';
import ScreenOverlay from '../../components/ScreenOverlay';
import helperFunctions from '../../utils/helperFunctions';
import clearIcon from '../../styles/icons/blue/clear.svg';
import userManagementAPIs from '../../services/user-management.service';
import Upload from '../Upload';
import { cleanupDeletedDocument, mergeUploadedDocuments } from '../../utils/uploadHelper';
import AuthContext from '../../context/AuthContext';

const NewMeetingInviteModal = ({
    userID,
    open,
    closeModal,
    start,
    end,
    meetingDate,
    projects,
    additionalAttendees,
}) => {
    const [meetingEvent, setMeetingEvent] = useState({
        title: '',
        descritpion: '',
        location: '',
        date: meetingDate,
        startTime: '',
        endTime: '',
        project: '',
        workingGroupAttendees: [],
        selectedWorkingGroupAttendees: [],
        selectedAdditionalAttendees: [],
        additionalAttendees: [],
        documents: [],
    });
    const [eventDay, setEventDay] = useState('');

    const authContext = useContext(AuthContext);
    useEffect(() => {
        setMeetingEvent((preMeetingEvent) => ({
            ...preMeetingEvent,
            date: meetingDate,
            startTime: start !== '00:00' ? start : '',
            endTime: end !== '00:00' ? end : '',
        }));
    }, [start, end, meetingDate]);

    const getWorkingGroupAttendees = () => {
        const target = document.getElementById('nmiProject-select-select');

        const listOfUserIDs = [];
        const requests = [];
        if (target) {
            const projectNum = target.selectedIndex;
            if (projectNum > 0 && projects[projectNum - 1].steeringInfo) {
                projects[projectNum - 1].steeringInfo.forEach((object) => {
                    listOfUserIDs.push(object.user);
                });
            }

            listOfUserIDs.forEach((user) => {
                requests.push(userManagementAPIs.getUserDetails(user));
            });
            const memberNames = [];
            Promise.all(requests).then((response) => {
                response.forEach((call) => {
                    if (call.data && Object.keys(call.data).length > 0) {
                        const first = call.data.contactDetails.firstname;
                        const last = call.data.contactDetails.surname;
                        memberNames.push({
                            name: first.concat(' ', last),
                            value: call.data.userID,
                        });
                    }
                });
            }).then(() => {
                setMeetingEvent({
                    ...meetingEvent,
                    workingGroupAttendees: [
                        memberNames,
                    ],
                });
            });
        }
    };

    useEffect(() => {
        const dt = new Date(meetingEvent.date);
        const day = `${dt.getDate()}`.padStart(2, '0');
        const month = `${dt.getMonth() + 1}`.padStart(2, '0');
        const year = dt.getFullYear();
        const stringDate = [year, month, day].join('');
        setEventDay(stringDate);
    }, [meetingEvent.date]);

    useEffect(() => {
        getWorkingGroupAttendees();
    }, [meetingEvent.project]);

    const checkAttendeeOptions = () => {
        if (meetingEvent.workingGroupAttendees.length > 0) {
            return meetingEvent.workingGroupAttendees[0];
        }
        return [];
    };
    const generateProjectDropdownOptions = (key, option) => ((key > 0)
        ? <option key={`nmiProjectOption-${key}`} value={option.value}>{option.label}</option>
        : <option key={`nmiProjectOption-${key}`} value=''>{'Select Project'}</option>);

    const timeAddMinutes = ({ time, min }) => {
        if (time) {
            const t = time.split(':');
            let h = Number(t[0]);
            let m = Number(t[1]);
            m += min % 60;
            h += Math.floor(min / 60);
            if (m >= 60) { h += 1; m -= 60; }
            return `${(`${h}`).padStart(2, '0')}:${
                (`${m}`).padStart(2, '0')}:${
                t[2]}`;
        }
        return '00:01';
    };

    const isLeftTimeGreaterOrEqual = (t1, t2) => {
        const tmp1 = t1.split(':');
        const tmp2 = t2.split(':');
        const hm1 = Number(tmp1[0]) * 60 + Number(tmp1[1]);
        const hm2 = Number(tmp2[0]) * 60 + Number(tmp2[1]);
        if (hm1 >= hm2) {
            return true;
        }
        return false;
    };
    const handleCancleOrClose = () => {
        closeModal();
        setMeetingEvent({
            title: '',
            descritpion: '',
            location: '',
            date: meetingDate,
            startTime: '',
            endTime: '',
            project: '',
            workingGroupAttendees: [],
            additionalAttendees: [],
            attachments: [],
        });
    };

    const handleFileSubmit = (docs) => {
        const mergedDocs = mergeUploadedDocuments(meetingEvent.documents, docs,
            {}, authContext.user);

        setMeetingEvent({
            ...meetingEvent,
            documents: mergedDocs,
        });
    };

    const deleteFileLink = (doc) => {
        const mergedDocs = cleanupDeletedDocument(meetingEvent.documents, doc);

        setMeetingEvent({
            ...meetingEvent,
            documents: mergedDocs,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const target = document.getElementById('nmiProject-select-select');
        const projectNum = target.selectedIndex;
        const newMeeting = {
            title: '',
            calendarType: '',
            eventType: '',
            startDate: '',
            endDate: '',
            projectID: '',
            projectTitle: '',
            eventID: '',
            description: '',
            worknotes: [],
            documents: [],
            approval: [],
            creator: '',
            approvalStatus: [],
            attendees: [],
            attendeesType: [],
            eventStatus: 'PENDING',
            stage1Approval: false,
            stage2Approval: false,
            completedDate: '',
        };
        if ((meetingEvent.workingGroupAttendees.length === 0
                || meetingEvent.workingGroupAttendees[0].length === 0)) {
            alert('Working Group Attendees Or Additional Attendees field is empty.');
        } else if (meetingEvent.startTime
            && meetingEvent.endTime
            && isLeftTimeGreaterOrEqual(meetingEvent.startTime, meetingEvent.endTime)) {
            alert('The start time of the meeting is equal or after the end time. Please correct.');
        } else if (!meetingEvent.startTime || !meetingEvent.endTime) {
            alert('The start time or the end time of the meeting is empty. Please correct.');
        } else {
            const attendeesType = [];
            meetingEvent.selectedWorkingGroupAttendees.forEach((userId) => {
                attendeesType.push({
                    iD: userId,
                    type: 'Steering Info',
                });
            });
            meetingEvent.selectedAdditionalAttendees.forEach((userId) => {
                attendeesType.push({
                    iD: userId,
                    type: 'Additional',
                });
            });
            newMeeting.title = meetingEvent.title;
            newMeeting.calendarType = 'meeting';
            newMeeting.eventType = 'Meeting';
            newMeeting.startDate = `${format(meetingEvent.date, 'dd-MM-yyyy')} ${meetingEvent.startTime}:00`;
            newMeeting.endDate = `${format(meetingEvent.date, 'dd-MM-yyyy')} ${meetingEvent.endTime}:00`;
            newMeeting.projectID = projects[projectNum - 1].projectID;
            newMeeting.projectTitle = projects[projectNum - 1].title;
            newMeeting.description = meetingEvent.descritpion;
            newMeeting.creator = userID;
            newMeeting.attendees = meetingEvent.selectedWorkingGroupAttendees
                .concat(meetingEvent.selectedAdditionalAttendees);
            newMeeting.attendeesType = attendeesType;
            newMeeting.documents = meetingEvent.documents;
            const response = await userManagementAPIs.createMeeting(newMeeting);
            if (response.status === 201) {
                alert('Meeting Created');
                setTimeout(handleCancleOrClose(), 5000);
            } else {
                alert('Unable to create event');
                setMeetingEvent({
                    ...meetingEvent,
                    attachments: [
                        ...meetingEvent.attachments,
                        event.target.value,
                    ],
                });
            }
        }
    };


    return (<>
        { open && <ScreenOverlay handleClick={closeModal} /> }
        {open && (
            <Form id='NewMeetingInvite-form' onSubmit={handleSubmit}>
                <Modal
                    id='nmiModal'
                    open={open}
                    size='large'
                    headerTitle='New Meeting Event'
                    secondActionButton
                    secondActionButtonTxt='Cancel'
                    handleMainActionBtnClick={() => null}
                    handleSecondaryActionBtnClick={handleCancleOrClose}
                    mainActionButtonTxt='Create'
                    closeModal={handleCancleOrClose}
                    body={<div className='nmiBody'>
                        <div className='nmiContent'>
                            <div className='nmiItem'>
                                <LabelledInput
                                    id='nmiTitle-input'
                                    type='text'
                                    label='Title'
                                    breakColumn={false}
                                    onChange={(event) => setMeetingEvent({
                                        ...meetingEvent,
                                        title: event.target.value,
                                    })}
                                    placeholder='Enter meeting title'
                                    value={meetingEvent.title}
                                    required
                                />
                            </div>
                            <div className='nmiItem'>
                                <LabelledInput
                                    id='nmiDescription-input'
                                    type='text'
                                    label='Description'
                                    breakColumn
                                    onChange={(event) => setMeetingEvent({
                                        ...meetingEvent,
                                        descritpion: event.target.value,
                                    })}
                                    placeholder='Enter meeting descritpion'
                                    value={meetingEvent.descritpion}
                                />
                            </div>
                        </div>
                        <div className='nmiFlexContent'>
                            <div className='nmiItem'>
                                <LabelledInput
                                    id='nmiLocation-input'
                                    type='text'
                                    label='Location'
                                    breakColumn
                                    onChange={(event) => setMeetingEvent({
                                        ...meetingEvent,
                                        location: event.target.value,
                                    })}
                                    placeholder='Enter meeting location'
                                    value={meetingEvent.location}
                                />
                            </div>
                            <div className='nmiItem'>
                                <div className='nmiDateLabel'>
                                    <label className='title datePickerLabel'>Date</label>
                                </div>
                                <DatePicker
                                    className='customDateInput nmiDate-imput'
                                    required
                                    dayPlaceholder='DD'
                                    monthPlaceholder='MM'
                                    yearPlaceholder='YYYY'
                                    calendarIcon={null}
                                    clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                                    onChange={(inputDate) => setMeetingEvent({
                                        ...meetingEvent,
                                        date: inputDate,
                                    })}
                                    value={meetingEvent.date}
                                    minDate={new Date()}
                                />
                            </div>
                        </div>
                        {eventDay === format(new Date(), 'yyyyMMdd')
                            ? <div className='nmiFlexContent'>
                                <div className='nmiItem'>
                                    <div className='nmiFieldLabel'>
                                        <label className='title'>Start Time</label>
                                    </div>
                                    <TimePicker
                                        className='customDateInput nmiStartTime-input'
                                        hourPlaceholder='HH'
                                        minutePlaceholder='MM'
                                        clockIcon={null}
                                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                                        onChange={(time) => setMeetingEvent({
                                            ...meetingEvent,
                                            startTime: time,
                                        })}
                                        value={meetingEvent.startTime}
                                        minTime={format(new Date(), 'HH:mm')}
                                        locale='en-GB'
                                        disableClock={true}
                                    />
                                </div>
                                <div className='nmiItem'>
                                    <div className='nmiFieldLabel'>
                                        <label className='title'>End Time</label>
                                    </div>
                                    <TimePicker
                                        className='customDateInput nmiEndTime-input'
                                        hourPlaceholder='HH'
                                        minutePlaceholder='MM'
                                        clockIcon={null}
                                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                                        onChange={(time) => setMeetingEvent({
                                            ...meetingEvent,
                                            endTime: time,
                                        })}
                                        value={meetingEvent.endTime}
                                        minTime={meetingEvent.startTime
                                            ? timeAddMinutes(meetingEvent.startTime, 1)
                                            : format(new Date(), 'HH:mm')
                                        }
                                        name='sameDayEndTime'
                                        locale='en-GB'
                                        disableClock={true}
                                    />
                                </div>
                            </div>
                            : <div className='nmiFlexContent'>
                                <div className='nmiItem'>
                                    <div className='nmiFieldLabel'>
                                        <label className='title'>Start Time</label>
                                    </div>
                                    <TimePicker
                                        className='customDateInput nmiStartTime-input'
                                        hourPlaceholder='HH'
                                        minutePlaceholder='MM'
                                        clockIcon={null}
                                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                                        onChange={(time) => setMeetingEvent({
                                            ...meetingEvent,
                                            startTime: time,
                                        })}
                                        value={meetingEvent.startTime}
                                        minTime='00:00'
                                        locale='en-GB'
                                        disableClock={true}
                                    />
                                </div>
                                <div className='nmiItem'>
                                    <div className='nmiFieldLabel'>
                                        <label className='title'>End Time</label>
                                    </div>
                                    <TimePicker
                                        className='customDateInput nmiEndTime-input'
                                        hourPlaceholder='HH'
                                        minutePlaceholder='MM'
                                        clockIcon={null}
                                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                                        onChange={(time) => setMeetingEvent({
                                            ...meetingEvent,
                                            endTime: time,
                                        })}
                                        value={meetingEvent.endTime}
                                        minTime={meetingEvent.startTime ? timeAddMinutes(meetingEvent.startTime, 1) : '00:01'}
                                        name='futureDayEndTime'
                                        locale='en-GB'
                                        disableClock={true}
                                    />
                                </div>
                            </div>
                        }
                        <div className='nmiFlexContent nmiAaLabel'>
                            <div className='nmiItem'>
                                <LabelledSelect
                                    required
                                    id='nmiProject-select'
                                    options={helperFunctions.constructDropdownData(projects, 'title').map((option, idx) => generateProjectDropdownOptions(idx, option))}
                                    label='Project'
                                    breakColumn={false}
                                    value={meetingEvent.project || 'default'}
                                    onChange={(event) => setMeetingEvent({
                                        ...meetingEvent,
                                        project: event.target.value,
                                    })}
                                />
                            </div>
                            <div className='nmiItem'>
                                <div className='nmiFieldLabel'>
                                    <label className='title selectSearchTitle'>Steering Group Attendees</label>
                                </div>
                                <SelectSearch
                                    id='nmiWgaOptions'
                                    options={checkAttendeeOptions()}
                                    closeOnSelect={false}
                                    printOptions='on-focus'
                                    multiple
                                    placeholder='Select Steering Group Attendees'
                                    onChange={(e) => setMeetingEvent({
                                        ...meetingEvent,
                                        selectedWorkingGroupAttendees: e,
                                    })}
                                    z-index='4'
                                    value={meetingEvent.workingGroupAttendees.map((a) => a.name).toString() || ''}
                                />
                            </div>
                        </div>
                        <div className='nmiALabel'>
                            <label className='title selectSearchTitle'>Additional Attendees</label>
                        </div>
                        <SelectSearch
                            id='nmiAaOptions'
                            options={additionalAttendees}
                            closeOnSelect={false}
                            printOptions='on-focus'
                            multiple
                            search
                            placeholder='Select Additional Attendees'
                            filterOptions={fuzzySearch}
                            onChange={(e) => setMeetingEvent({
                                ...meetingEvent,
                                selectedAdditionalAttendees: e,
                            })}
                            value={meetingEvent.additionalAttendees.map((a) => a.name).toString() || ''}
                        />
                        <Upload
                            title={'Attachments'}
                            uploadedDocuments={meetingEvent.documents}
                            allowMultiple={true}
                            uploadCallback={handleFileSubmit}
                            deleteCallback={deleteFileLink}
                            questionnaire={true}
                            // questionnaire set to true to avoid
                            // triggering page re render on when file is uploaded
                        />
                    </div>}
                    helpOption={false}
                />;
            </Form>
        )}
    </>);
};

NewMeetingInviteModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    additionalAttendees: PropTypes.array,
    userID: PropTypes.string,
    projects: PropTypes.array,
    open: PropTypes.bool.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    meetingDate: PropTypes.any.isRequired,
};

export default NewMeetingInviteModal;
