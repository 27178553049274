import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import closeIconBtn from '../../styles/icons/grey/close.svg';
import closeIconBtnBlack from '../../styles/icons/black/cross.svg';
import { ThemeContext } from '../../context/ThemeContext';

const Tag = ({
    id,
    size,
    handleDeleteBtnClick,
    tagTxt,
    isDeletable,
}) => {
    const { toggle } = useContext(ThemeContext);
    return (<div id={`${id}-tag`} className={`tag-content ${size} ${toggle ? 'new-tag-content' : ''}`}>
        <div className='tag-txt-container'>
            {size === 'small' ? <p id={`${id}-tag-text`} className='tag-txt body-small '>{tagTxt}</p>
                : <a id={`${id}-tag-text`} className='tag-txt body'>{tagTxt}</a>}
        </div>
        {isDeletable && <button type='button' onClick={handleDeleteBtnClick} className='tag-delete-btn-container tag-del-btn-wrapper'>
            <img id={`${id}-tag-btn`} src={toggle ? closeIconBtnBlack : closeIconBtn } className='tag-delete-btn'
                alt='tag-delete-btn' ></img>
        </button>}
    </div >);
};


// (<div id={`${id}-tag`} className={`tag-content ${size}`}>
//     <p id={`${id}-tag-text`} className='tag-txt body'>{tagTxt}</p>
//     {(isDeletable)
//         ?
//         : <></>}
// </div>);


Tag.propTypes = {
    id: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    handleDeleteBtnClick: PropTypes.func,
    tagTxt: PropTypes.string.isRequired,
    isDeletable: PropTypes.bool.isRequired,
};

export default Tag;
