const Constants = {
    linkType: {
        external: 'external',
        router: 'router',
    },
    footerData: [{
        title: 'About Us',
        link: '/about-open',
        type: 'router',
    }, {
        title: 'Terms & Conditions',
        link: '/terms-and-conditions',
        type: 'router',
    }, {
        title: 'Privacy Policy',
        link: '/privacy-policy',
        type: 'router',
    }, {
        title: 'Contact Us',
        link: '/contact',
        type: 'router',
    }, {
        title: 'Accessibility Statement',
        link: '/accessibility-statement',
        type: 'router',
    }, {
        title: 'Follow us',
        link: 'https://www.linkedin.com/company/nepo-north-east-procurement-organisation-/',
        type: 'external',
        class: 'linkedin',
    }],
};

export default Constants;
