import React from 'react';
import PropTypes from 'prop-types';
import ScreenOverlay from '../ScreenOverlay';
import SideDrawer from '../SideDrawer';
import Button from '../Button';
import closeIcon from '../../styles/icons/grey/close.svg';

const FilterPanel = ({
    open,
    id,
    children,
    closePanel,
    footerBtnsDisabled,
    handleClearAll,
    handleShowResults,
}) => <>
    {open && <ScreenOverlay handleClick={closePanel} />}
    <SideDrawer open={open}
        id={id}
        openDirection='right'
        tabletsAndMobileOnly={false}>
        <header className='filterPanelHeader'>
            <p className='title-large'>Filter by</p>
            <button
                type='button'
                className='closeBtn'
                aria-labelledby='Close'
                id={`close-${id}`}
                onClick={closePanel}>
                <img src={closeIcon} className='closeBtn-img'
                    alt='Close'></img>
            </button>
        </header>
        <main className='filterPanelContent'>{children}</main>
        <footer className='filterPanelFooter'>
            <Button className='clearAllBtn'
                id={`${id}-clearAll`}
                variant='secondary'
                additionalVariant='skinless'
                label='Reset'
                handleClick={handleClearAll}
                disabled={footerBtnsDisabled} />
            <Button className='showResultsBtn'
                id={`${id}-showResults`}
                variant='primary'
                label='Show Results'
                handleClick={handleShowResults}
                disabled={footerBtnsDisabled} />
        </footer>
    </SideDrawer>
</>;

FilterPanel.propTypes = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
    closePanel: PropTypes.func.isRequired,
    footerBtnsDisabled: PropTypes.bool.isRequired,
    handleClearAll: PropTypes.func.isRequired,
    handleShowResults: PropTypes.func.isRequired,
};

export default FilterPanel;
