import React from 'react';
import Constants from './constants';

const getSortedLots = (lots) => lots.sort((a, b) => a.lotOrderNo - b.lotOrderNo)
    .map((lot, idx) => <p key={idx} className='title'>{`${Constants.LOT} ${lot?.lotOrderNo}:
                    ${lot.lotTitle}`}{idx + 1 !== lots.length && <>,</>}&nbsp;</p>);

const isAllowedToEditDeleteQuestionnaire = ({ rbac, authContext, projectDetails }) => projectDetails
    ?.steeringInfo && rbac.can(
    rbac.action.editAssignedQuestionnaire,
    rbac.util.getSteeringGroupRole(authContext.user.id,
        projectDetails?.steeringInfo),
);

const isAllowedToAssignEditWeightage = ({
    rbac, authContext, data, projectDetails, isAssignWeightageApplicable,
}) => projectDetails?.steeringInfo && rbac.can(
    rbac.action.editAssignedQuestionnaire,
    rbac.util.getSteeringGroupRole(authContext.user.id,
        projectDetails?.steeringInfo),
) && isAssignWeightageApplicable(data);

const isAllowedToEditLots = ({
    rbac, authContext, projectDetails, lots, eventDetails,
}) => lots?.length > 0
&& !eventDetails?.publishProject
&& projectDetails?.steeringInfo && rbac.can(
    rbac.action.editAssignedQuestionnaire,
    rbac.util.getSteeringGroupRole(authContext.user.id,
        projectDetails?.steeringInfo),
);

export {
    getSortedLots,
    isAllowedToEditDeleteQuestionnaire,
    isAllowedToAssignEditWeightage,
    isAllowedToEditLots,
};
