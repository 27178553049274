import React from 'react';
import PropTypes from 'prop-types';
import ShowMoreShowLess from '../../../components/SeeMoreSeeLess';
import './index.scss';
import { noRegisteredSuppliersMessage, listOfRecipientsTitle } from './constants';

function getRecipientsLengthTitle(recipientsLength) {
    return recipientsLength > 0 ? `(${recipientsLength})` : '';
}

const CorrespondenceRecipients = ({ recipients }) => <div className='recipients-container'>
    <label className='form-label title selectSearchTitle'>{ `${listOfRecipientsTitle} ${getRecipientsLengthTitle(recipients.length)}` }</label>
    <div className='recipients'>
        { recipients.length === 0
            ? noRegisteredSuppliersMessage
            : <ShowMoreShowLess
                inputText={recipients.map((recipient) => recipient.account?.companyName).join(', ')}
                inputTextCharacterLimit={30} /> }
    </div>
</div>;

CorrespondenceRecipients.propTypes = {
    recipients: PropTypes.array.isRequired,
};

export default CorrespondenceRecipients;
