export const specialRouteKeys = {
    CORRESPONDENCE: '/:resourceType/correspondence/:resourceId',
    CORRESPONDENCE_OVERVIEW: '/:resourceType/correspondence/overview/:resourceId/:topicId/:responseType?',
    PROJECT_OVERVIEW: '/projects/overview/:projectId/lot/view/:lotId',
    PROJECT_EDIT: '/project/edit/:projectId',
    SUPPLIER_MANAGE: '/suppliers/manage/:projectID/:eventID',
    ACTIVITY_LOG: '/:resourceType/activity/:resourceId',
    AWARD_SUMMARY: '/opportunity/award/Summary/:projectId/:eventId',
};

export const correspondencePath = '/correspondence';
export const activityLogPath = '/activity';

export const urlParams = {
    projectId: 'projectId',
    contractID: 'contractID',
};

export const mainPageTitles = [
    'Project Overview',
    'Opportunity Overview',
    'Call Off Overview',
    'Contract Management',
    'Contract Overview',
    'Supplier Overview',
    'Solution Overview',
    'View Workflow',
];

export const additionalPageTitles = [
    'Correspondence Overview',
];
