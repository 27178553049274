const Constants = {
    CREATED_DATE: 'Created Date',
    MANAGE_QUESTIONNAIRE: 'manage_questionnaires',
    LOT: 'Lot',
    N_A: 'N/A',
    LOTS: 'Lots',
    INVALID_PUBLISH_LEVEL: 'Invalid publish level',
    WEIGHTING: 'Weighting',
    YET_TO_BE_ASSGNED: 'Yet to be assigned',
    DELETE: 'Delete',
    EDIT: 'Edit',
    DEFAULT_SELECTION_QUESTIONNAIRE: 'defaultSelectionQuestionnaire',
    EDIT_WEIGHTING: 'Edit Weighting',
    ASSIGN_WEIGHTING: 'Assign Weighting',
    EDIT_LOTS: 'Edit Lots',
};

export default Constants;
