import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Accordion, AccordionSummary, AccordionDetails, Icon,
} from '@mui/material';
import expandIcon from '../../styles/icons/blue/expand.svg';


function MuiAccordion({
    open = false,
    sectionData,
    idx,
    statusPills,
    id,
    handleClickAccordion,
    clickOnExpandIcon = true,
}) {
    const [expand, setExpand] = React.useState(open);
    const openCloseAccordion = (event) => {
        event.stopPropagation();
        setExpand((prev) => !prev);
    };


    const ExpandMoreIcon = () => (
        <Icon onClick={clickOnExpandIcon ? openCloseAccordion : null}>
            <img src={expandIcon} />
        </Icon>
    );

    useEffect(() => {
        if (handleClickAccordion) {
            handleClickAccordion(sectionData?.sectionId, expand);
        }
    }, [expand]);

    return (
        sectionData.link ? <a href={sectionData.link}>{sectionData.header}</a>
            : <Accordion id={id} expanded={expand}
                classes={{ root: sectionData.sectionClass }} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls={`panel${idx}-content`}
                    id={`panel${idx}-header`}
                    classes={{ root: sectionData.headerClass }}
                    onClick={clickOnExpandIcon ? null : openCloseAccordion}
                >
                    {sectionData.header}
                    {statusPills}
                </AccordionSummary>
                <AccordionDetails >
                    { sectionData.content}
                </AccordionDetails>
            </Accordion>
    );
}

MuiAccordion.propTypes = {
    open: PropTypes.bool,
    sectionData: PropTypes.object.isRequired,
    idx: PropTypes.number.isRequired,
    id: PropTypes.string,
    statusPills: PropTypes.object,
    handleClickAccordion: PropTypes.func,
    clickOnExpandIcon: PropTypes.bool,
};

export default MuiAccordion;
