import React, { useContext, useState, useEffect } from 'react';
import propTypes from 'prop-types';
// import SelectSearch, { fuzzySearch } from 'react-select-search/dist/cjs';

import LabelledInput, { LabelledInputTypeText } from '../../components/LabelledInput';
import LabelledSelect from '../../components/LabelledSelect';
import Form from '../../components/Form';
import Tag from '../../components/Tag';
import GeneralConstants from '../../utils/generalConstants';
import Button from '../../components/Button';
// import UserRoles from '../Header/constants';
import AuthContext from '../../context/AuthContext';
import rbac from '../../rbac';
import userManagementAPIs from '../../services/user-management.service';
import Modal from '../../components/Modal';

import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import constants from './constants';
import Toast from '../../components/Alerts/Toast/Toast';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import actions from '../../rbac/constants';
import { ThemeContext } from '../../context/ThemeContext';

const MyProfileForm = ({
    values,
    handleChange,
    setValues,
    handleCancel,
    // handlePrivilegeChange,

}) => {
    const authContext = useContext(AuthContext);
    // const [userList, setUserList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    // const [splAccountPrivileges, setsplAccountPrivileges] = useState({});-
    const [initialDepartmentData, setInitialDepartmentData] = useState();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;
    const generateDropdownOptions = (opts) => {
        const optionHtml = [];
        optionHtml.push(<option key={0} value='--' disabled>{'--'}</option>);
        opts.forEach((option, idx) => {
            optionHtml.push(<option key={`phoneCodeOption-${idx}`} value={option}>{option}</option>);
        });
        return optionHtml;
    };
    // const handleSelectSearchChange = (data, privilege) => {
    //     const prepareRequest = [];
    //     const selectedData = {
    //         ...splAccountPrivileges,
    //         [privilege]: data,
    //     };
    //     setsplAccountPrivileges(selectedData);
    //     Object.keys(selectedData).map((el) => {
    //         selectedData[el].map((user) => {
    //             prepareRequest.push({
    //                 userID: user.value,
    //                 username: user.name,
    //                 privilege: constants.specialAccountPrivileges
    //                     .filter((privilegeName) => privilegeName.id === el)[0].name,
    //             });
    //             return null;
    //         });
    //         return null;
    //     });
    //     handlePrivilegeChange(prepareRequest);
    // };

    // const getAllAccountUsersRequest = async (accountID) => {
    //     try {
    //         const response = await userManagementAPIs.getAllAccountUsers(accountID);
    //         setUserList(response.data.map((userItem) => ({
    //             name: `${userItem.contactDetails.firstname} ${userItem.contactDetails.surname}`,
    //             value: userItem.userID,
    //         })));
    //     } catch (error) {
    //         Toast.fire({
    //             icon: 'error',
    //             titleText: 'Unable to retrieve information.',
    //         });
    //     }
    // };

    const handleInput = (event) => {
        const { value } = event.target;
        setValues({
            ...values,
            newDept: value,
        });
    };
    const handleModal = (show) => {
        setShowModal(show);
    };
    const addDepartment = async (depName) => {
        try {
            const accID = values.organisationDetails.accountID;
            const updatedDepartment = await userManagementAPIs.createDepartment(accID,
                {
                    departmentDetails: [{
                        departmentName: depName,
                    }],
                });
            if (updatedDepartment.status === 200) {
                const updatedData = updatedDepartment.data;
                setInitialDepartmentData(updatedData);
                let updatedOrganisationDetails = { ...values.organisationDetails };
                updatedOrganisationDetails = {
                    ...updatedOrganisationDetails,
                    departments: updatedData,
                };
                setValues({
                    ...values,
                    organisationDetails: updatedOrganisationDetails,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to delete department.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to delete department.',
            });
        }
    };
    const handleAddDepartmentSubmitAfterConfirm = async () => {
        if (!values.newDept) {
            Toast.fire({
                icon: 'error',
                titleText: 'Please Enter the Department',
            });
        } else if (initialDepartmentData.includes(values.newDept)) {
            Toast.fire({
                icon: 'error',
                titleText: `Department ${values.newDept} already added. Please add new department.`,
            });
        } else {
            addDepartment(values.newDept);
            handleModal(false);
        }
    };
    const handleAddDepartmentSubmit = async (event) => {
        event.preventDefault();
        handleModal(false);
        const attrs = {
            title: 'Are you sure ?',
            subTitle: 'Please confirm that you want to create a new department.',
            confirmBtnText: 'Confirm',
            input: '',
            inputOptions: null,
            inputValidator: null,
        };
        Confirm(handleAddDepartmentSubmitAfterConfirm, attrs);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const payload = {
            ...values,
            userDetails: {
                ...values.userDetails,
                verified: undefined,
            },
            organisationDetails: {
                ...values.organisationDetails,
                industryCode: undefined,
                deliveryCode: undefined,
                worknotes: undefined,
            },
        };

        try {
            const updateUser = await userManagementAPIs.updateProfile(payload);
            if (updateUser.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: constants.successMessage,
                });
                handleCancel();
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to update profile.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to update profile.',
            });
        }
    };
    const removeDepartmentPill = async (item) => {
        try {
            const response = await userManagementAPIs.deleteDepartment(item.departmentID);
            if (response.status === 200) {
                const updatedData = values.organisationDetails.departments
                    .filter((department) => department.departmentID !== item.departmentID);
                setInitialDepartmentData(updatedData);
                setValues({
                    ...values,
                    organisationDetails: {
                        ...values.organisationDetails,
                        departments: updatedData,
                    },
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to delete department.',
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to delete department.',
            });
        }
    };
    const generateDepartmentTag = (data, isDeletable) => {
        const TagList = [];
        if (data && data.length > 0) {
            data.forEach((item) => {
                const TagElement = <Tag key={`${item.departmentID}-${item.departmentName}`}
                    id={item.departmentID}
                    tagTxt={item.departmentName}
                    isDeletable={isDeletable}
                    size='large'
                    handleDeleteBtnClick={() => removeDepartmentPill(item)}
                />;
                TagList.push(TagElement);
            });
        }

        return <div className='sectionContentTags'>
            {TagList}
        </div>;
    };


    const collateInitialDepartmentData = (data) => {
        const initialDeptData = [];

        if (data && data.length > 0) {
            data.forEach((dept) => {
                initialDeptData.push(dept);
            });
        }
        setInitialDepartmentData(initialDeptData);
    };
    useEffect(() => {
        collateInitialDepartmentData(values.organisationDetails.departments || []);
        // getAllAccountUsersRequest(authContext.user.accountId);
    }, []);

    useEffect(() => {
        const data = {};
        values.specialAccountPrivileges?.map((el) => {
            if (data[constants.specialAccountPrivileges
                .filter((privilegeName) => privilegeName.name === el.privilege)[0]?.id]) {
                data[constants.specialAccountPrivileges
                    .filter((privilegeName) => privilegeName.name === el.privilege)[0]?.id].push({
                    name: el.username,
                    value: el.userID,
                });
            } else {
                data[constants.specialAccountPrivileges
                    .filter((privilegeName) => privilegeName.name === el.privilege)[0]?.id] = [];
                data[constants.specialAccountPrivileges
                    .filter((privilegeName) => privilegeName.name === el.privilege)[0]?.id].push({
                    name: el.username,
                    value: el.userID,
                });
            }
            return null;
        });
        // setsplAccountPrivileges(data);
    }, []);

    const generateDepartment = () => <>
        <LabelledInput id='dept'
            type={LabelledInputTypeText} label='Department'
            breakColumn={true} onChange={(e) => handleInput(e)}
            placeholder='Enter department'
            required={true} />
    </>;
    return (
        <div id='profileFormContainer'>
            <p className='title-large sectionHeader'>Personal Information</p>
            <Form id='profileForm' onSubmit={(e) => handleSubmit(e)}>
                <div className='sectionContent' id='personalInformation'>
                    <div className='sectionRow'>
                        <LabelledInput id='firstname'
                            type={LabelledInputTypeText} label='First name'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.USER_DETAILS,
                                GeneralConstants.CONTACT_DETAILS)}
                            value={values.userDetails.contactDetails.firstname}
                            placeholder='Enter first name' /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='surname'
                            type={LabelledInputTypeText} label='Last name'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.USER_DETAILS,
                                GeneralConstants.CONTACT_DETAILS)}
                            value={values.userDetails.contactDetails.surname}
                            placeholder='Enter last name' /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='userRole'
                            type={LabelledInputTypeText} label='Role'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.ORGANIZATION_DETAILS)}
                            value={values.userDetails.userRole}
                            placeholder='Enter Role'
                            readonly={true} /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='jobTitle'
                            type={LabelledInputTypeText} label='Job Title'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.ORGANIZATION_DETAILS)}
                            value={values.userDetails.jobTitle}
                            placeholder='Enter Job Title'
                            readonly={true} /></div>
                    <div className='sectionRow'>
                        <LabelledInput id='department'
                            type={LabelledInputTypeText} label='Department'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.ORGANIZATION_DETAILS)}
                            value={values.userDetails.department}
                            placeholder='Enter department' readonly={true}/></div>
                    <div className='sectionRow'>
                        <LabelledInput id='email'
                            type={LabelledInputTypeText} label='Email'
                            breakColumn={false} onChange={(e) => handleChange(e,
                                GeneralConstants.ORGANIZATION_DETAILS)}
                            value={values.userDetails.email}
                            readonly={true}
                            placeholder='' />
                    </div>
                    <div className='sectionRow' id='phoneNo'>
                        <LabelledSelect id='countryCode'
                            options={generateDropdownOptions(GeneralConstants.strings.callingCodes)}
                            breakColumn={false}
                            label={'Phone Number:'}
                            value={values.userDetails?.contactDetails?.countryCode || '--'}
                            onChange={(e) => handleChange(e, GeneralConstants.USER_DETAILS,
                                GeneralConstants.CONTACT_DETAILS)} />
                        <LabelledInput id='mobile'
                            type={LabelledInputTypeText}
                            breakColumn={false}
                            label={null}
                            onChange={(e) => handleChange(e, GeneralConstants.USER_DETAILS,
                                GeneralConstants.CONTACT_DETAILS)}
                            value={values.userDetails?.contactDetails?.mobile}
                            pattern='^[0-9]+$'
                            title='Field has to be numbers only'
                            placeholder='' />
                    </div>
                </div>

                <div id='departmentSectionContent'>
                    <div className='textIconContainer'><p className='title-large sectionHeader'>Department</p>
                        {rbac.can(actions.manageDepartments, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button id='addDepartment' size='small' variant='secondary skinless'
                            label='Add' icon={true} iconSrc={addIconSrc} disabled={false}
                            handleClick={(e) => {
                                e.preventDefault();
                                handleModal(true);
                            }} />}
                    </div>
                    { generateDepartmentTag(values.organisationDetails.departments,
                        rbac.can(actions.manageDepartments, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]))}
                </div>


                { rbac.can(rbac.action.userProfileManagement,
                    [values.organisationDetails.accountType.toLowerCase() + authContext.user.role])
                && <>
                    <div className='sectionContent' id='organisationalInformation'>
                        <p className='title-large sectionHeader'>Organisational Information</p>
                        <div className='sectionRow'>
                            <LabelledInput id='accountName'
                                type={LabelledInputTypeText} label='Organisation Name'
                                breakColumn={false} onChange={(e) => handleChange(e,
                                    GeneralConstants.ORGANIZATION_DETAILS)}
                                value={values.organisationDetails.accountName}
                                placeholder='' />
                        </div>
                        <div className='sectionRow'>
                            <LabelledInput id='addressLine1'
                                type={LabelledInputTypeText} label='Address Line 1'
                                breakColumn={false} onChange={(e) => handleChange(e,
                                    GeneralConstants.ORGANIZATION_DETAILS,
                                    GeneralConstants.ADDRESS)}
                                value={values.organisationDetails.address?.addressLine1}
                                placeholder='' />
                        </div>
                        <div className='sectionRow'>
                            <LabelledInput id='addressLine2'
                                type={LabelledInputTypeText} label='Address Line 2'
                                breakColumn={false} onChange={(e) => handleChange(e,
                                    GeneralConstants.ORGANIZATION_DETAILS,
                                    GeneralConstants.ADDRESS)}
                                value={values.organisationDetails.address?.addressLine2}
                                required={false}
                                placeholder='' />
                        </div>
                        <div className='sectionRow'>
                            <LabelledInput id='city'
                                type={LabelledInputTypeText} label='Town/City'
                                breakColumn={false} onChange={(e) => handleChange(e,
                                    GeneralConstants.ORGANIZATION_DETAILS,
                                    GeneralConstants.ADDRESS)}
                                value={values.organisationDetails.address?.city}
                                placeholder='' />
                        </div>
                        <div className='sectionRow' id='countrySelect'>
                            <LabelledSelect id='country'
                                options={generateDropdownOptions(
                                    GeneralConstants.strings.countries,
                                )
                                }
                                label={'Country:'}
                                breakColumn={false} onChange={(e) => handleChange(e,
                                    GeneralConstants.ORGANIZATION_DETAILS,
                                    GeneralConstants.ADDRESS)}
                                value={values.organisationDetails.address?.country}
                            />
                        </div>
                        <div className='sectionRow'>
                            <LabelledInput id='postcode'
                                type={LabelledInputTypeText} label='Postcode'
                                pattern= {values.organisationDetails.address?.country === 'United Kingdom'
                                    ? '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$' : ''}
                                title={values.organisationDetails.address?.country === 'United Kingdom'
                                    ? 'Please enter a valid UK postcode' : ''}
                                breakColumn={false} onChange={(e) => handleChange(e,
                                    GeneralConstants.ORGANIZATION_DETAILS,
                                    GeneralConstants.ADDRESS)}
                                value={values.organisationDetails.address?.postcode}
                                placeholder='e.g. NE83DF' />
                        </div>
                        <div className='sectionRow two-inputs-row' id='companyPhoneNo'>
                            <LabelledSelect id='companyCountryCode'
                                options={generateDropdownOptions(GeneralConstants
                                    .strings.callingCodes)}
                                breakColumn={false}
                                label={GeneralConstants.PHONE_NUMBER}
                                value={values.organisationDetails.companyCountryCode || ''}
                                onChange={(e) => handleChange(e,
                                    GeneralConstants.ORGANIZATION_DETAILS)} />
                            <LabelledInput id='phoneNumber'
                                type={LabelledInputTypeText}
                                breakColumn={false}
                                label={null}
                                onChange={(e) => handleChange(e,
                                    GeneralConstants.ORGANIZATION_DETAILS)}
                                value={values.organisationDetails.phoneNumber || ''}
                                pattern={GeneralConstants.NUMBERS_PATTERN}
                                title={GeneralConstants.FIEL_WITH_NUMBERS_ONLY}
                                placeholder='' />
                        </div>
                        {/* <div className='sectionRow' id='phoneNo'>
                            <LabelledSelect id='countryCode'
                                options={generateDropdownOptions(
                                    GeneralConstants.strings.callingCodes,
                                )
                                }
                                breakColumn={false}
                                label={'Organisation Contact Number:'}
                                value={values.organisationDetails?.organisationContact?.countryCode
                                    || '--'}
                                onChange={(e) => handleOrganisationChange(e)} />
                            <LabelledInput id='contactNumber'
                                type={LabelledInputTypeText}
                                breakColumn={false}
                                label={null}
                                onChange={(e) => handleOrganisationChange(e)}
                                value={values.organisationDetails
                                    ?.organisationContact?.contactNumber}
                                placeholder='' />
                        </div> */}
                    </div>
                    {/* {userList.length > 0 && <div
                    className='sectionContent' id='specialPrivileges'>
                        <p className='title-large sectionHeader'>Special Account Privileges</p>
                        {
                            constants.specialAccountPrivileges.map((el) => <div
                            key={el.id} className='sectionRow'>
                                <label className='form-label title'>{el.name}</label>
                                <SelectSearch
                                    id={el.id}
                                    options={userList || []}
                                    filterOptions={fuzzySearch}
                                    search={true}
                                    multiple={true}
                                    value={values.specialAccountPrivileges
                                        ?.filter((ele) => ele.privilege === el.name)
                                        .map((elem) => elem.userID)}
                                    printOptions='on-focus'
                                    closeOnSelect={false}
                                    placeholder='Search users on Open'
                                    onChange={(event, data) => handleSelectSearchChange(data,
                                        el.id)}
                                    z-index='4'
                                />
                            </div>)
                        }
                    </div>} */}
                </>
                }
                <div className='twoActionBtnContainer'>
                    <Button id='cancel-action-btn'
                        variant='secondary'
                        label='Cancel'
                        type='button'
                        handleClick={() => handleCancel()}/>
                    <Button id='submit-action-btn'
                        variant='primary'
                        label='Update'
                        type='submit'
                        handleClick={() => null}/>
                </div>
                {showModal && <Modal
                    id='addDepartment'
                    open={true}
                    size='small'
                    headerTitle='Add Department'
                    body={generateDepartment()}
                    footer={true}
                    mainActionButtonTxt='Add'
                    secondActionButtonTxt='Cancel'
                    secondActionButton={true}
                    handleMainActionBtnClick={ handleAddDepartmentSubmit }
                    handleSecondaryActionBtnClick={() => { setShowModal(false); }}
                    helpOption={false}
                    closeModal={() => { setShowModal(false); }}
                />
                }
            </Form>
        </div>
    );
};

MyProfileForm.propTypes = {
    values: propTypes.object.isRequired,
    handleCancel: propTypes.func.isRequired,
    setValues: propTypes.func.isRequired,
    handleChange: propTypes.func.isRequired,
    // onRadioChange: propTypes.func.isRequired,
    handlePrivilegeChange: propTypes.func,
};

export default MyProfileForm;
