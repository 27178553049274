import React from 'react';
import PropTypes from 'prop-types';
import { DiffMethod } from 'react-diff-viewer';
import SelectSearch from 'react-select-search';
import getModeOptions from './helpers';
import Constants from './constants';

const ViewerMode = ({
    selectedMode = DiffMethod.LINES,
    placeHolder = Constants.METHOD_FOR_DIFF,
    handleChangeMode,
}) => {
    const handleSelectChange = (data) => {
        handleChangeMode(data.value);
    };

    return <SelectSearch
        id = 'changeCompareMethod'
        key = 'changeCompareMethod'
        options={getModeOptions()}
        value = {selectedMode}
        closeOnSelect={true}
        printOptions='on-focus'
        search
        placeholder= {placeHolder}
        onChange={(_event, data) => handleSelectChange(data)}
    />;
};
ViewerMode.propTypes = {
    selectedMode: PropTypes.string,
    placeHolder: PropTypes.string,
    handleChangeMode: PropTypes.func,
};

export default ViewerMode;
