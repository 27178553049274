import { saveAs } from 'file-saver';
import {
    awardStatuses,
    awardStatus,
    questionnaireTypes,
    approvalStatuses,
    intentStatuses,
    ID,
    COMPANY_NAME,
    SCORE,
    SCORE_TITLE,
    PASS_FAIL_RESULT,
    PASS_FAIL_RESULT_TITLE,
    TOTAL_PASS_FAIL_RESULT,
    TOTAL_SCORE,
    TOTAL_SCORE_TITLE,
    FAIL,
    FAIL_TITLE,
    PASS_TITLE,
    RESPONSE_ID,
    LOT_ID,
    ToastErrorMessages,
    exportCsvText,
    responseHeaders,
} from '../config/constants';
import helperFunctions from '../utils/helperFunctions';
import rbac from '../rbac';
import { treeTypes } from '../components/TreeViewer/index';
import Toast from '../components/Alerts/Toast/Toast';
import gatewayOrchestratorAPIs from './gateway.service';
import ToastConstants from '../components/Alerts/Toast/constants';

const awardStatusValues = {
    colors: awardStatus.map((award) => award.colour),
    labels: awardStatus.map((award) => award.label),
};

const getAwardStatusValues = (status, values) => ({
    [awardStatuses.successful]: values[0],
    [awardStatuses.unsuccessful]: values[1],
    [awardStatuses.pending]: values[2],
}[status || '']);

const getAwardStatusColor = (status) => getAwardStatusValues(status,
    awardStatusValues.colors);
const getAwardStatusLabel = (status) => getAwardStatusValues(status,
    awardStatusValues.labels);

const getPrettyQuestionnaireType = (qType) => questionnaireTypes.find(
    (eventType) => eventType.value === qType,
)?.label || '';

const getAwardUserPermissions = (userSteeringRole) => ({
    approveStage1: rbac.can(rbac.action.approveStage1, userSteeringRole),
    approveStage2: rbac.can(rbac.action.approveStage2, userSteeringRole),
});

const generatePassFailResult = (responses) => (responses
    ?.some((response) => response.passFailResult?.toLowerCase() === FAIL)
    ? FAIL_TITLE : PASS_TITLE);

const calculateScore = (responses) => responses?.reduce(
    (acc, response) => acc + parseInt(response.score, 10), 0,
) || 0;

const calculateWeightage = (responses) => responses?.reduce(
    (acc, response) => acc + response.weightage,
    0,
) || 0;

const getElementWithUniqueId = (element, treeViewId) => ({
    ...element,
    id: `${element.id }-${ treeViewId}`,
});


const getLabelText = (labelText, id, object) => {
    const labelMappings = {
        [SCORE]: object.weightage
            ? `${SCORE_TITLE}: ${parseFloat(object.score)}/${object.weightage}`
            : '',
        [PASS_FAIL_RESULT]: object.passFailResult
            ? `${PASS_FAIL_RESULT_TITLE}: ${helperFunctions.camel2title(object.passFailResult)}`
            : '',
        [TOTAL_SCORE]: object?.responses?.length && calculateWeightage(object.responses) !== 0
            ? `${TOTAL_SCORE_TITLE}: ${calculateScore(object.responses)}/${calculateWeightage(object.responses)}`
            : '',
        [TOTAL_PASS_FAIL_RESULT]: object?.responses?.length
            ? `${PASS_FAIL_RESULT_TITLE}: ${helperFunctions.camel2title(generatePassFailResult(object.responses))}`
            : '',
    };
    return labelText || (id in labelMappings ? labelMappings[id] : '');
};

const getLineLabels = (lineLabels, object, id) => lineLabels
    .map((label) => ({
        id: `${label.id}-${id}`,
        type: label.type,
        color: label.color,
        labelTxt: getLabelText(label.labelTxt, label.id, object),
    }));


const getOnClickOptions = (options, object) => {
    const optionMapping = {
        [ID]: object.supplierAccountID,
        [COMPANY_NAME]: object?.supplier?.companyName,
        [RESPONSE_ID]: object.responseID,
        [LOT_ID]: object.lotID,
    };
    return options?.map((option) => optionMapping[option] ?? option) ?? [];
};


const getLineButtons = (readOnly, lineButtons, object, id) => (lineButtons
    ?.filter((bn) => !(readOnly && bn.showIfReadOnly === false)) || []).map((button) => {
    const onClickOptions = getOnClickOptions(button?.onClickOptions, object);
    return {
        id: `${button.id}-${id}`,
        type: treeTypes.MENU_OPTIONS.BUTTON,
        size: 'small',
        label: button.label,
        ...(button.additionalVariant
                && { additionalVariant: button.additionalVariant }),
        ...(button.variant
                    && { variant: button.variant }),
        clickType: button.clickType,
        ...(button.onClick && { onClick: `${button.onClick}${object.responseID}` }),
        ...(onClickOptions && { onClickOptions }),
    };
});

const isActionNeededInSubmission = (awardInfo, submission) => ((submission
    ?.awardApproval === approvalStatuses.pending)
            || (submission?.awardApproval === approvalStatuses.approved
                && !submission?.awardLetterSent)
            || (submission?.awardApproval === approvalStatuses.notStarted
                || !submission.awardApproval)
            || (awardInfo.addIntentProcedure === true
                && (submission.withIncompleteIntent || submission?.docs?.length < 1))
            || (awardInfo.addIntentProcedure === false
                && (submission.withIncompleteIntent || submission?.docs?.length < 1))
            || (awardInfo?.awardInfo?.intentLetterSent === true && submission?.docs?.length < 2)
);

const isActionNeededInLot = (lot, awardInfo) => {
    if (!lot.withIncompleteIntent) {
        return false;
    }
    if ((awardInfo.addIntentProcedure === true
        && (lot.withIncompleteIntent === true
        || lot?.docs?.length < 1))
        || (awardInfo.addIntentProcedure === false
            && (lot.withIncompleteIntent === true || lot?.docs?.length < 1))
            || (awardInfo?.intentLetterSent === true && lot?.docs?.length < 2)
    ) {
        return true;
    }
    return false;
};

const withIncomplenteIntent = (lot) => !lot?.intentStatus
    || lot.intentStatus === intentStatuses.pending;

const getLotsOrderedByIncompleteSubmissions = (lotsMap) => {
    const lots = Object.entries(lotsMap)?.map(([, lot]) => ({
        ...lot,
        withIncompleteIntent: withIncomplenteIntent(lot),
    }));
    return lots
        ?.sort((a, b) => (b.intentStatus === intentStatuses.pending)
        - (a.intentStatus === intentStatuses.pending)
        || (b.intentStatus === undefined) - (a.intentStatus === undefined)
    || a.title.localeCompare(b.title));
};

const getPreviousSubmissionsOrderedByIncompleteSubmissions = (previousSubmissions) => Object
    .entries(previousSubmissions)?.reduce((acc, [, submission]) => {
        const lots = getLotsOrderedByIncompleteSubmissions(submission.lotsOrProjects);
        const firstLot = lots?.length ? lots[0] : undefined;
        return acc.concat({
            ...submission,
            lotsOrdered: lots,
            ...firstLot?.docs && { docs: firstLot.docs },
        });
    }, []);

const withNoAwardLetterSent = (supplier) => {
    if (!supplier.currentSubmission) {
        return false;
    }
    const allLots = Object.entries(supplier.currentSubmission.lotsOrProjects)
        .map(([, value]) => value);
    return allLots.some((lot) => !lot?.awardLetterSent || lot.awardLetterSent === false);
};

const getSuppliersAndLotsOrderedByIncompleteSubmissions = (suppliersMaps) => {
    const suppliers = Object.entries(suppliersMaps).map(([, value]) => value)
        .map((supplier) => (
            {
                ...supplier,
                withIncompleteIntent: withNoAwardLetterSent(supplier),
            }
        ));
    const allSuppliersOrdered = suppliers
        .sort((a, b) => (+b.withIncompleteIntent)
        - (+a.withIncompleteIntent)
    || a.account?.companyName.localeCompare(b.account?.companyName));

    return allSuppliersOrdered.map((supplier) => {
        const firstSubmission = supplier.currentSubmission;
        let lotsOrdered;
        let firstLot;
        if (firstSubmission) {
            lotsOrdered = getLotsOrderedByIncompleteSubmissions(supplier.currentSubmission
                ?.lotsOrProjects);
            firstLot = lotsOrdered?.length ? lotsOrdered[0] : undefined;
        }
        let previousSubmissions = [];
        if (supplier.previousSubmissions) {
            previousSubmissions = getPreviousSubmissionsOrderedByIncompleteSubmissions(
                supplier.previousSubmissions,
            );
        }
        return {
            ...supplier,
            ...firstSubmission && {
                currentSubmission: {
                    ...firstSubmission,
                    lotsOrdered,
                    ...firstLot?.intentStatus && { intentStatus: firstLot.intentStatus },
                    ...firstLot?.awardLetterSent && { awardLetterSent: firstLot.awardLetterSent },
                    ...firstLot?.intentApproval && { intentApproval: firstLot?.intentApproval },
                    ...firstLot?.awardApproval && { awardApproval: firstLot?.awardApproval },
                    ...firstLot?.docs && { docs: firstLot?.docs },
                },
            },
            previousSubmissions,
        };
    });
};

const lotSubmissionIsIncomplete = (submission) => {
    const allLots = Object.entries(submission.lotsOrProjects)
        .map(([, value]) => value);
    return allLots?.some((lot) => !lot?.intentStatus
    || lot.intentStatus === intentStatuses.pending);
};
const projectSubmissionIsIncomplete = (response) => !response?.intentStatus
    || response.intentStatus === intentStatuses.pending;


const exportFunction = async (projectId) => {
    const response = await gatewayOrchestratorAPIs.exportAnswerSummary(projectId);
    if (response.status === 200) {
        const blob = new Blob([response.data], {
            type: response.headers[responseHeaders.CONTENT_TYPE],
        });
        const filename = response.headers[responseHeaders.CONTENT_DISPOSITION].split('"')[1]
        || exportCsvText;
        try {
            saveAs(blob, filename);
        } catch (error) {
            Toast.fire({
                icon: ToastConstants.error,
                titleText: ToastErrorMessages.UNABLE_TO_DOWNLOAD_DOC,
            });
        }
    } else {
        Toast.fire({
            icon: ToastConstants.error,
            titleText: ToastErrorMessages.UNABLE_TO_DOWNLOAD_DOC,
        });
    }
};

const handleIntentAwardDocumentDownload = async (
    type, attachedToId, attachedToType, docs, handleDownload, handleMultipleDownload,
) => {
    const documents = helperFunctions.getFileNamesByType(type, docs);
    if (documents.length === 1) {
        await handleDownload(documents[0], attachedToId, attachedToType);
    } else {
        await handleMultipleDownload(documents, attachedToId, attachedToType);
    }
};

const getPrettyResponseStatus = (status) => awardStatus.filter(
    (rStatus) => rStatus.value === status,
)[0]?.label;

export {
    getAwardStatusColor,
    getAwardStatusLabel,
    getPrettyQuestionnaireType,
    getAwardUserPermissions,
    calculateScore,
    calculateWeightage,
    getLabelText,
    getLineLabels,
    getLineButtons,
    generatePassFailResult,
    getElementWithUniqueId,
    isActionNeededInSubmission,
    getLotsOrderedByIncompleteSubmissions,
    getSuppliersAndLotsOrderedByIncompleteSubmissions,
    isActionNeededInLot,
    lotSubmissionIsIncomplete,
    projectSubmissionIsIncomplete,
    exportFunction,
    handleIntentAwardDocumentDownload,
    getPrettyResponseStatus,
};
