const constants = {
    UNLOCKING_BETTER_WAYS: 'Unlocking better ways',
    OF_DOING_PROCUREMENT: 'of doing procurement',
    P1: 'Open is an e-procurement platform, designed and developed by NEPO. As procurement professionals, we understand the ongoing challenges that are faced by buyers and suppliers when carrying out public sector procurement. Our aim is to build a system that solves your headaches over the procurement process to help you achieve your goals.',
    P2: 'Developed in partnership with the twelve North East local authorities, Open has been tried and tested by public sector colleagues to ensure it continously meets your needs today and tomorrow.',
    WHAT_THIS_MEANS: 'What this means for',
    BUYERS: 'buyers:',
    BUYERS_LI_1: 'Greater efficiency of workflow management and workforce planning',
    BUYERS_LI_2: 'Improved visibility and acccess to the supply chain',
    BUYERS_LI_3: 'Improved ways of keeping in touch with stakeholders at every step of the procurement journey',
    BUYERS_LI_4: 'Enhanced data & analytics so you can focus on areas of strategic importance',
    SUPPLIERS: 'suppliers:',
    SUPPLIERS_LI_1: 'Enhanced user experience when tendering for opportunities',
    SUPPLIERS_LI_2: 'Improved visibility and access to regional tendering opportunities',
    SUPPLIERS_LI_3: 'Less administrative burden',
    SUPPLIERS_LI_4: 'Improved ‘on the go’ access across a range of devices',
    MEET_THE_TEAM: 'Meet the team',
    BEHIND_OPEN: 'behind Open',
    IMG_FOOTER_1: 'Top (L-R): Georgia Smithson, Graham Kelly, Joe Taylor, Joanne Emery, Adam Smith',
    IMG_FOOTER_2: 'Bottom (L-R): Emanuela Codara, Alia Nabi',
    START_YOUR_JOURNEY: 'Start your journey with Open today.',
    GET_STARTED: 'Get started',
    OPEN_TEAM: 'Open Team',
};
export default constants;
