import React from 'react';
import { parseISO, isPast } from 'date-fns';
import PropTypes from 'prop-types';

import StatusLabel from '../../../../components/StatusLabel';
import { getSubmissionStatusFromProjectOrLots } from '../../helpers';
import { responseStatus } from '../../../../config/constants';
import Button from '../../../../components/Button';
import dpsConstants from '../../dpsConstants';
import ExportButton from '../ExportButton';

const ExportEditBanner = ({
    submissionsData,
    questionnaireAwaitingRefresh,
    projectId,
    setSelectLotsModal,
}) => {
    const verifiedAction = getSubmissionStatusFromProjectOrLots(
        submissionsData, questionnaireAwaitingRefresh,
    );
    const getColorVerifiedAction = () => (verifiedAction === dpsConstants.responseSubmitted
        ? dpsConstants.green : dpsConstants.red);

    const isAnswerSummaryEditable = () => (!isPast(parseISO(submissionsData.submissionEndDate))
        && submissionsData.bidStatus !== responseStatus.bidSubmitted
         && submissionsData.projectStatus !== responseStatus.cancelled);

    const getEditBannerInfo = () => (isAnswerSummaryEditable() ? <>
        <span className='title'>{dpsConstants.editLotsTitle}</span>
        <Button
            id='primary-action-btn'
            type='button'
            size='small'
            variant='primary'
            additionalVariant='additionalexportbtn'
            label={dpsConstants.editLotsLabel}
            handleClick={() => setSelectLotsModal(true)} /></> : <></>);

    return (
        <div className='answerSummaryContent'>
            <div className='answerSummaryActionsContainer'>
                <StatusLabel
                    id={'actionLabel'}
                    color={getColorVerifiedAction()}
                    labelTxt={verifiedAction}
                />
                <div className='answerSummaryBtnContainer'>
                    {getEditBannerInfo()}
                    {ExportButton(projectId)}
                </div>
            </div>
        </div>

    );
};

ExportEditBanner.propTypes = {
    submissionsData: PropTypes.object,
    questionnaireAwaitingRefresh: PropTypes.bool,
    projectId: PropTypes.string,
    setSelectLotsModal: PropTypes.func.isRequired,
};

export default ExportEditBanner;
