const Constants = {
    callOff: 'calloff',
    callOffMechanism: [
        {
            value: 'test',
            label: 'Test',
        },
        {
            value: '',
            label: 'Not Selected',
        },
        {
            value: 'any',
            label: 'Any',
        },
        {
            value: 'directAward',
            label: 'Direct Award',
        },
        {
            value: 'furtherComp',
            label: 'Further Competition',
        },

    ],
    callOffMechanismLots: [
        {
            value: 'test',
            label: 'Test',
        },
        {
            value: 'any',
            label: 'Any',
        },
        {
            value: 'directAward',
            label: 'Direct Award',
        },
        {
            value: 'furtherComp',
            label: 'Further Competition',
        },

    ],
    warningMessages: {
        messageTitle: 'Warning',
        procurementOfficeRequiredWarning: 'Please select at least one Procurement Officer.',
        responsibleOfficerRequiredWarning: 'Please select at least one Responsible Officer.',
        radioButtonRequiredWarning: 'Please select one of these options.',
    },
    furtherCompetition: {
        allSupplier: 'All Suppliers',
        anySupplier: 'Selected Suppliers',
        notSelected: 'Not Selected',
    },
    furtherCompetitionValues: {
        allSupplierValue: 'all',
        anySupplierValue: 'any',
    },
    competitionRules: 'competitionRules',
    isExternalLabels: {
        myOrganisation: 'My Organisation',
        external: 'External',
        label: 'Choose Project Owner',
    },
    manualCreationId: 'manualCreation',
    selectToRouteLabel: 'Select Route to Market',
    selectToRoutePlaceholder: 'Select route to market',
    furtherCompetitionRulesLabel: 'Further Competition Rules',
    optional: ' (Optional)',
};

export default Constants;
