import { projectTypes, RESOURCE_KEYS } from '../../config/constants';
import isSupplierRole from '../../utils/isSupplierRole';
import {
    specialRouteKeys,
    correspondencePath, activityLogPath, urlParams, mainPageTitles, additionalPageTitles,
} from './constants';

const isSupplier = (userType) => isSupplierRole(userType);
const isCallOffProject = (data) => data?.projectType === projectTypes.callOff;

const extractParamsFromPath = (path) => Array.from(path.matchAll(/:(\w+)/g), (match) => match[1]);

const getValueFromData = (param, data) => {
    if (!data) return undefined;
    const foundKey = Object.keys(data).find((key) => key.toLowerCase() === param.toLowerCase());

    return data[foundKey];
};

const getParamValue = (param, match, data) => {
    const { url, params } = match;

    if ((url.includes(correspondencePath) || url.includes(activityLogPath))
        && ((param === urlParams.projectId) || param === urlParams.contractID)) {
        return match.params.resourceId;
    }

    return params[param] || getValueFromData(param, data);
};

const generatePath = (path, match, data) => {
    const params = extractParamsFromPath(path);
    const updatedPath = params.reduce(
        (pathToReplace, param) => pathToReplace.replace(`:${param}`, getParamValue(param, match, data)), path,
    );

    return updatedPath;
};


export const getBreadcrumbs = (route, match, userType, data) => {
    const breadcrumbsByPath = {
        [specialRouteKeys.CORRESPONDENCE]: () => {
            const projectKey = isSupplier(userType)
                ? RESOURCE_KEYS.OPPORTUNITY : RESOURCE_KEYS.PROJECT;
            const paramKey = match.params.resourceType === RESOURCE_KEYS.PROJECT
                ? projectKey : match.params.resourceType;
            return route.breadcrumbs[0][paramKey];
        },
        [specialRouteKeys.CORRESPONDENCE_OVERVIEW]: () => (
            isSupplier(userType)
                ? route.breadcrumbs[0][RESOURCE_KEYS.OPPORTUNITY]
                : route.breadcrumbs[0][RESOURCE_KEYS.PROJECT]
        ),
        [specialRouteKeys.PROJECT_OVERVIEW]: () => (
            isSupplier(userType)
                ? route.breadcrumbs[0][RESOURCE_KEYS.OPPORTUNITY]
                : route.breadcrumbs[0][RESOURCE_KEYS.PROJECT]
        ),
        [specialRouteKeys.PROJECT_EDIT]: () => (
            !isCallOffProject(data)
                ? route.breadcrumbs[0][RESOURCE_KEYS.PROJECT]
                : route.breadcrumbs[0][RESOURCE_KEYS.CALLOFF]
        ),
        [specialRouteKeys.SUPPLIER_MANAGE]: () => (
            !isCallOffProject(data)
                ? route.breadcrumbs[0][RESOURCE_KEYS.PROJECT]
                : route.breadcrumbs[0][RESOURCE_KEYS.CALLOFF]
        ),
        [specialRouteKeys.ACTIVITY_LOG]: () => {
            const paramKey = match.params.resourceType === RESOURCE_KEYS.PROJECT
                ? RESOURCE_KEYS.PROJECT : RESOURCE_KEYS.CONTRACT;
            return route.breadcrumbs[0][paramKey];
        },
        [specialRouteKeys.AWARD_SUMMARY]: () => {
            const paramKey = data?.opportunityId
                ? RESOURCE_KEYS.OPPORTUNITY : RESOURCE_KEYS.CONTRACT;
            return route.breadcrumbs[0][paramKey];
        },
        default: () => route.breadcrumbs,
    };

    return (breadcrumbsByPath[route.path] || breadcrumbsByPath.default)();
};

export const getPath = (path, match, data) => (
    path.includes(':') ? generatePath(path, match, data) : path
);

export const getName = (name, data) => (
    mainPageTitles.includes(name) && data?.title)
    || (additionalPageTitles.includes(name) && data?.additionalTitle)
    || name;


export const isOverflowing = (element) => element.scrollWidth > element.clientWidth;
