import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import AuthContext from '../../context/AuthContext';
import DocumentBuilder from '../../features/DocumentBuilder';
import projectManagementAPIs from '../../services/project-management.service';
import gatewayAPIs from '../../services/gateway.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import userManagementAPIs from '../../services/user-management.service';
import actions from './slice/workflows.actions';
import useGetProjectTitle from '../../hooks/useGetProjectTitle';


const EditProjectWorkflow = () => {
    const {
        workflowID, isImport, projectId, contractID,
    } = useParams();
    const history = useHistory();
    // const authContext = useContext(AuthContext);
    const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const {
        documentBuilderState,
    } = useSelector((state) => ({
        documentBuilderState: state.WorkflowsReducer.documentBuilderState,
    }));
    const [memberAuthorities, setMemberAuthorities] = useState([]);
    const { getProjectTitle } = useGetProjectTitle();
    const dispatch = useDispatch();

    const fetchMemberAuthorities = async () => {
        const authorities = await userManagementAPIs.getAllAccountsByType('buyer');
        const auths = authorities.data.map((el) => ({
            value: el.accountID,
            label: el.companyName,
        }));
        setMemberAuthorities(auths);
    };

    useEffect(() => {
        const fetchData = async () => {
            const responseData = await gatewayAPIs.getWorkflow(workflowID);
            if (responseData.status === 200) {
                const preparedData = helperFunctions
                    .prepareDocumentBuilderState('workflow', responseData.data, workflowID);
                setInitialState(preparedData);
                setType(preparedData.details.type);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }

            getProjectTitle(projectId);
        };
        // TODO: if (id && authContext.user.isAuthenticated) {
        if (workflowID) {
            if (documentBuilderState.details && isImport) {
                setInitialState(documentBuilderState);
            } else {
                fetchData();
                dispatch(actions.setBuilderState({}));
            }
        }
        fetchMemberAuthorities();
    }, [workflowID]);

    const constructAndPostWorkflowData = async (documentState, confirmAction) => {
        const stagesInDocument = [];
        documentState.sections.forEach((stage, idx) => {
            const eventsInSection = [];
            stage?.sectionItems?.forEach((event, i) => {
                const eventToAdd = {
                    ...event,
                    orderNo: i,
                    eventDuration: parseInt(event.eventDuration, 10),
                    startDateReminder: event.startDateReminderFlag
                        ? parseInt(event.startDateReminder, 10)
                        : undefined,
                    endDateReminder: event.endDateReminderFlag
                        ? parseInt(event.endDateReminder, 10)
                        : undefined,
                    stageCategory: stage.category,
                };

                eventsInSection.push(eventToAdd);
            });

            const stageDataToAdd = {
                stageName: stage.name,
                category: stage.category,
                orderNo: idx,
                events: eventsInSection,
            };

            stagesInDocument.push(stageDataToAdd);
        });

        const putBody = {
            workflowName: documentState.details.name,
            workflowDescription: documentState.details.description,
            workflowType: documentState.details.type,
            stages: stagesInDocument,
            isPublic: documentState.details.shareWorkflowWithEveryone === 'true',
            sharedWith: documentState.details.shareWorkflowWithEveryone === 'true' ? undefined : documentState.details.sharedWith,
        };

        if (documentState.details.route) putBody.route = documentState.details.route;

        if (confirmAction) {
            const responseData = await projectManagementAPIs
                .updateProjectWorkflow(workflowID, projectId, putBody);
            if (responseData.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Workflow updated successfully.',
                });
                if (contractID && contractID !== '_') {
                    history.push(`/contracts/overview/${contractID}`);
                } else if (projectId && projectId !== '_') {
                    history.push(`/projects/overview/${projectId}`);
                } else {
                    history.goBack();
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to update workflow.',
                });
            }
        }

        // if (confirmAction === 'overwrite') {
        //     responseData = await projectManagementAPIs.updateWorkflow(workflowID, putBody);
        // } else if (confirmAction === 'create') {
        //     responseData = await projectManagementAPIs.createWorkflow(putBody);
        // }

        // if (confirmAction === 'overwrite' && responseData.status === 200) {
        //     Toast.fire({
        //         icon: 'success',
        //         titleText: 'Workflow updated successfully.',
        //     });
        //     /** TODO: Update route as per journey */
        //     history.push(`/projects/overview/${projectId}`);
        // } else if (confirmAction === 'create' && responseData.status === 201) {
        //     Toast.fire({
        //         icon: 'success',
        //         titleText: 'Workflow created successfully.',
        //     });
        //     /** TODO: Update route as per journey */
        //     history.push(`/projects/overview/${projectId}`);
        // } else {
        //     Toast.fire({
        //         icon: 'error',
        //         titleText: `Unable to ${confirmAction === 'overwrite' ?
        // 'update' : 'create'} workflow.`,
        //     });
        // }
    };

    const handleEnableDisableBtnClick = async (enabled) => {
        const responseData = await projectManagementAPIs.enableWorkflow(workflowID, {
            decision: enabled,
        });
        if (responseData.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Workflow updated successfully.',
            });
            history.back();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to updated workflow.',
            });
        }
    };

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'Edit'}
        context='Workflow'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Stage'
        sectionItemLabel='Event'
        allowSectionReordering={true}
        allowSectionItemsReordering={true}
        handlePrimaryToolbarSubmitButton={constructAndPostWorkflowData}
        handleEnableDisableBtnClick={handleEnableDisableBtnClick}
        memberAuthorities={memberAuthorities}
    /> : <></>;
};

export default EditProjectWorkflow;
