import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ProjectDataContext = createContext();

export const ProjectDataProvider = ({ children }) => {
    const [projectData, setProjectData] = useState(null);

    return (
        <ProjectDataContext.Provider value={{ projectData, setProjectData }}>
            {children}
        </ProjectDataContext.Provider>
    );
};

ProjectDataProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
