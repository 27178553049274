import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import jsxGenerateData from '../../utils/jsxGenerateData';
import generateData from './components/generateJsxData';
import Button from '../../components/Button';
import AuthContext from '../../context/AuthContext';
import MyProfileForm from '../../features/MyProfileForm';
import MyProfileSupplierForm from '../../features/MyProfileSupplierForm';
import Modal from '../../components/Modal';
import rbac from '../../rbac';
import isSupplierRole from '../../utils/isSupplierRole';
import GeneralConstants from '../../utils/generalConstants';
import { ThemeContext } from '../../context/ThemeContext';
import helperFunctions from '../../utils/helperFunctions';

const MyProfile = () => {
    const [workflowModal, setWorkflowModal] = useState(false);
    const [data, setData] = useState({});
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const { user } = authContext;
    const { toggle } = useContext(ThemeContext);
    const handleChange = (event, section, subSection = null) => {
        const { name, value: rawValue } = event.target;
        let value = rawValue;

        if (name === GeneralConstants.NO_OF_EMPLOYESS) {
            value = parseInt(value, 10);
        }

        let updatedSection = { ...user[section] };

        if (subSection) {
            updatedSection = {
                ...updatedSection,
                [subSection]: {
                    ...updatedSection[subSection],
                    [name]: value,
                },
            };
        } else {
            updatedSection = {
                ...updatedSection,
                [name]: value,
            };
        }

        authContext.updateAuthContext({
            ...user,
            [section]: updatedSection,
        });
    };


    const generateEditActions = () => (edit ? null
        : <div className='editBtn'>
            <Button id='actionBtn'
                type='submit'
                variant='secondary'
                additionalVariant='skinless'
                icon
                iconSrc={helperFunctions.getEditIcon(toggle)}
                label='Edit'
                handleClick={() => setEdit(true)} />
        </div>);

    const backBtn = () => <Button id='backBtn'
        type='submit'
        variant='secondary'
        label='Back'
        handleClick={() => history.goBack()}/>;

    const workflowBtn = () => <>
        {!edit && <div className='editBtn'>
            {rbac.can(rbac.action.setDefaultWorkflow, [
                authContext.user.accountType.toLowerCase() + authContext?.user?.role,
            ]) && <Button id='actionBtn'
                type='submit'
                variant='secondary'
                label='Default Workflows'
                handleClick={() => setWorkflowModal(true)} />}
        </div>}
    </>;

    // We need a workflow button to render regardless! Maybe some filtering?
    // Create bespoke items for each one.
    const generateModalBody = () => <>
        <div className='mpWorkflowItem'>
            <div className='mpHeader'>
                <p className='title-large'>Direct Award</p>
                <p className='caption'>{user?.organisationDetails?.dAWorkflow?.workflowName || 'No Workflow Selected'}</p>
            </div>
            <div className='mpButtonContainer'>
                {user?.organisationDetails?.dAWorkflow
                    ? <>
                        <Button
                            id='viewButton'
                            variant='tertiary'
                            label='View Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/view/${user?.organisationDetails?.dAWorkflow?.workflowID}`)}
                        />
                        {rbac.can(rbac.action.setDefaultWorkflow, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button
                            id='viewButton'
                            variant='tertiary'
                            label='Select Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/browse/_/_/${user?.organisationDetails?.accountID}/directAward`)}
                        />}
                    </>
                    : <>
                        {rbac.can(rbac.action.setDefaultWorkflow, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button
                            id='viewButton'
                            variant='tertiary'
                            label='Select Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/browse/_/_/${user?.organisationDetails?.accountID}/directAward`)}
                        />}
                    </>
                }
            </div>
        </div>
        <div className='mpWorkflowItem'>
            <div className='mpHeader'>
                <p className='title-large'>Further Competition</p>
                <p className='caption'>{user?.organisationDetails?.fCWorkflow?.workflowName || 'No Workflow Selected'}</p>
            </div>
            <div className='mpButtonContainer'>
                {user?.organisationDetails?.fCWorkflow
                    ? <>
                        <Button
                            id='viewButton'
                            variant='tertiary'
                            label='View Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/view/${user?.organisationDetails?.fCWorkflow?.workflowID}`)}
                        />
                        {rbac.can(rbac.action.setDefaultWorkflow, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button
                            id='viewButton'
                            variant='tertiary'
                            label='Select Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/browse/_/_/${user?.organisationDetails?.accountID}/furtherComp`)}
                        />}
                    </>
                    : <>
                        {rbac.can(rbac.action.setDefaultWorkflow, [
                            authContext.user.accountType.toLowerCase() + authContext?.user?.role,
                        ]) && <Button
                            id='viewButton'
                            variant='tertiary'
                            label='Select Workflow'
                            // size='small'
                            handleClick={() => history.push(`./workflows/browse/_/_/${user?.organisationDetails?.accountID}/furtherComp`)}
                        />}
                    </>
                }
            </div>
        </div>
    </>;

    useEffect(() => {
        if (authContext.authenticated) {
            setData(generateData(user));
        }
    }, [edit]);

    return <>{user && data && <section id='supplierProfileData'>

        {Object.keys(data).length > 0 && jsxGenerateData('supplierProfile', data, null, null, edit, generateEditActions,
            isSupplierRole(user?.organisationDetails?.accountType)
                ? <MyProfileSupplierForm
                    values={user}
                    handleCancel={() => setEdit(false)}
                    setValues={authContext.updateAuthContext}
                    handleChange={handleChange}
                    // onRadioChange={(e) => onRadioChange(e)}
                />
                : <MyProfileForm
                    values={user}
                    handleCancel={() => setEdit(false)}
                    setValues={authContext.updateAuthContext}
                    handleChange={handleChange}
                    // handlePrivilegeChange={handlePrivilegeChange}
                    // onRadioChange={(e) => onRadioChange(e)}

                />, false,
            !isSupplierRole(user?.organisationDetails?.accountType)
                ? workflowBtn : null,
            backBtn)
        }

        {workflowModal && <Modal
            id='workflowModal'
            headerTitle='Default Workflows'
            closeModal={() => setWorkflowModal(false)}
            body={generateModalBody()}
            footer={false}
            size='small'

        />}
    </section>}</>;
};

export default MyProfile;
