import React from 'react';
import PropTypes from 'prop-types';


const LabelledSelect = ({
    id,
    options,
    label,
    breakColumn,
    onChange,
    value,
    required = true,
    readonly = false,
}) => (
    <div className='labelled-select labelled-item'>
        {label
        && <label
            className='form-label title'
            id={`${id}-select-label`}
            htmlFor={`${id}Select`}>{label}{required ? null : <span className='caption'> (Optional)</span>}
        </label>}
        <select
            className ={`form-select body${readonly ? ' disabled' : ''}`}
            id={`${id}-select`}
            name={id}
            value={value}
            onChange={onChange}
            required={required}
            disabled={readonly}>{options}
        </select>
        {breakColumn && <div className='flex-break'/>}
    </div>);

LabelledSelect.propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array,
    label: PropTypes.string,
    breakColumn: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
};

export default LabelledSelect;
