import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import projectManagementAPIs from '../../services/project-management.service';
import helperFunctions from '../../utils/helperFunctions';
import DashboardCard from '../../components/DashboardCard';
import Toast from '../../components/Alerts/Toast/Toast';
import { breadcrumbPaths, routeToMarket, userRoles } from '../../config/constants';
import PillLarge from '../../components/PillLarge';
import Constants from './constants';
import AuthContext from '../../context/AuthContext';

const AwardedContractOverview = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const authContext = useContext(AuthContext);
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const history = useHistory();

    async function fetchData() {
        try {
            const contractInfo = await projectManagementAPIs.getPublicContract(id);
            if (contractInfo.status === 200) {
                setShowDeleteButton(
                    authContext?.user?.accountId === contractInfo.data?.buyer?.accountID
                    && authContext?.user?.role === userRoles.admin
                    && contractInfo.data?.contractSource !== Constants.CONTRACT_PROJECT,
                );

                setData(contractInfo.data);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    }

    const deleteContract = async () => {
        try {
            const response = await projectManagementAPIs.deleteContract(id);
            if (response.status === 200) {
                history.push(breadcrumbPaths.CONTRACT_REGISTER.path);
                Toast.fire({
                    icon: 'success',
                    titleText: Constants.deleteContractSuccess,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: Constants.deleteContractError,
                });
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                titleText: Constants.deleteContractError,
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const renderSuppliersList = (suppliersList, notFoundMsg, title) => {
        const activeSuppliers = suppliersList
            .filter((supplier) => supplier.supplierStatus === Constants.supplier.status.active);

        return activeSuppliers.length === 0
            ? <div>
                <p className='caption sectionValue'>{notFoundMsg || Constants.notFoundDefault}</p>
            </div>
            : activeSuppliers.map((supplier, idx) => (
                <div className='sectionRow supplier' key={`${supplier.accountID}-${idx}${title ? `-${title}` : ''}`}>
                    <div className='sectionRowPanel'>
                        <p className='title sectionLabel'>{`${idx + 1}. ${Constants.supplier.supplierName}`}</p>
                        <p className='caption sectionValue'>{supplier.account?.companyName
                            ? supplier.account?.companyName
                            : Constants.noData}
                        </p>
                    </div>
                    <div className='sectionRowPanel small-fields'>
                        <p className='title sectionLabel'>{Constants.supplier.sme}</p>
                        <p className='caption sectionValue'>{supplier.isSME ? Constants.yes : Constants.no}</p>
                        <p className='title sectionLabel'>{Constants.supplier.vcs}</p>
                        <p className='caption sectionValue'>{supplier.isVCS ? Constants.yes : Constants.no}</p>
                        <p className='title sectionLabel'>{Constants.supplier.regNumber}</p>
                        <p className='caption sectionValue'>{supplier.registrationNumber || Constants.noData}</p>
                    </div>
                </div>));
    };
    const buildAwardedSuppliersPanel = () => {
        if (data.publishLevel === 'lot') {
            return data.awardedSuppliers.sort((a, b) => a.lotOrderNo - b.lotOrderNo).map((lot) => <div className='sectionContent' key={lot.lotID}>
                <div className='sectionRow'>
                    <p className='lotSectionLabel'>Lot {lot.lotOrderNo}: {lot?.lotTitle}</p>
                </div>
                {renderSuppliersList(lot?.suppliers, Constants.notFoundLot, lot?.title)}
            </div>);
        }
        return <div className='sectionContent'>
            {renderSuppliersList(data?.awardedSuppliers, Constants.notFoundContract)}
        </div>;
    };

    const getAwardedSuppliersName = () => {
        if (data?.awardedSuppliersName) {
            return <div className='sectionContent'>
                {data.awardedSuppliersName?.map((supplier, idx) => <div className='sectionRow' key={idx}>
                    <p className='title sectionLabel'>{`${idx + 1}. ${Constants.supplier.supplierName}`}</p>
                    <p className='caption sectionValue'>{supplier}</p>
                </div>)}
            </div>;
        }
        return <div className='sectionContent'>
            <p className='caption sectionValue'>{Constants.notFoundDefault}</p>
        </div>;
    };

    const getTextTransform = (type) => ({
        [Constants.contract.projectTypeDPS]: (text) => text.toUpperCase(),
        [Constants.contract.projectTypeOpenFramework]:
            () => Constants.contract.projectTypeOpenFrameworkLabel,
    })[type];

    const getPrettyProjectTypes = (projectDescribes) => {
        const sortOrder = [
            Constants.contract.projectTypeDPS,
            Constants.contract.projectTypeFramework,
            Constants.contract.projectTypeOpenFramework,
            Constants.contract.projectTypeContract,
        ];

        projectDescribes.sort(
            (a, b) => {
                const idxA = sortOrder.indexOf(a) >= 0 ? sortOrder.indexOf(a) : sortOrder.length;
                const idxB = sortOrder.indexOf(b) >= 0 ? sortOrder.indexOf(b) : sortOrder.length;
                return idxA - idxB;
            },
        );

        return projectDescribes
            .map((type) => {
                const transform = getTextTransform(type);
                return transform ? transform(type) : type;
            })
            .join(', ');
    };

    const getPrettyRTM = (rtm) => routeToMarket
        .filter((route) => route.id === rtm)[0]?.name;

    const generateInfoContent = () => (
        data ? <section className='itemBody'>
            <p className='title-large sectionHeader keyInfo'>{Constants.contract.header}</p>
            <div className='sectionContent'>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.description}</p>
                    <p className='caption sectionValue'>{helperFunctions.simpleFormat(data?.description || Constants.noData, 'p', 'caption', true)}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.startDate}</p>
                    <p className='caption sectionValue'>{data?.startDate ?? Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.endDate}</p>
                    <p className='caption sectionValue'>{data?.endDate ?? Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.awardDate}</p>
                    <p className='caption sectionValue'>{data.awardDate ? helperFunctions.formatPrettyDate(data.awardDate) : Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.annualValue}</p>
                    <p className='caption sectionValue'>{data.annualValue ? helperFunctions.formatCurrency(data.annualValue) : Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.contractDuration}</p>
                    <p className='caption sectionValue'>{data?.contractDuration ?? Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.reviewDate}</p>
                    <p className='caption sectionValue'>{data.reviewDate ?? Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.extensionsAvailable}</p>
                    <p className='caption sectionValue'>{data?.extensionsAvailable?.length ?? Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.awardValue}</p>
                    <p className='caption sectionValue'>{data.awardValue ? helperFunctions.formatCurrency(data.awardValue) : Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.unrecoverableVAT}</p>
                    <p className='caption sectionValue'>{data.unrecoverableVAT ? helperFunctions.formatCurrency(data.unrecoverableVAT) : Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.route}</p>
                    <p className='caption sectionValue cap-first-letters'>{data.route ? getPrettyRTM(data.route) : Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.projectDescribes}</p>
                    <p className='caption sectionValue cap-first-letters'>{data?.projectDescribes?.length ? getPrettyProjectTypes(data.projectDescribes) : Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.contract.industryInfo}</p>
                    <p className='caption sectionValue pill-container'>
                        {data?.industryInfo ? data.industryInfo
                            .map((industryInfo) => <PillLarge key={industryInfo.category}
                                content={`${industryInfo.category} -  ${industryInfo.level}`}
                            />) : ''}
                    </p>
                </div>
            </div>
            <p className='title-large sectionHeader'>{Constants.buyer.header}</p>
            <div className='sectionContent'>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.buyer.companyName}</p>
                    <p className='caption sectionValue'>{data?.buyer?.companyName || Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.buyer.contactUsername}</p>
                    <p className='caption sectionValue'>{data?.buyer?.user?.contactDetails?.username || Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.buyer.email}</p>
                    <p className='caption sectionValue'>{data?.buyer?.user?.email || Constants.noData}</p>
                </div>
                <div className='sectionRow'>
                    <p className='title sectionLabel'>{Constants.buyer.departmentName}</p>
                    <p className='caption sectionValue'>{data?.responsibleOfficerDepartmentName || Constants.noData}</p>
                </div>
            </div>
            <p className='title-large sectionHeader'>{Constants.supplier.header}</p>
            { data.isPublic && data.awardedSuppliers && data.awardedSuppliers.length > 0
                ? buildAwardedSuppliersPanel()
                : getAwardedSuppliersName()}
        </section> : <div className='itemHeader'>
            <p className='caption-large'>{Constants.errorUnable}</p>
        </div>);

    return <div className='contractOverviewContainer'>
        <DashboardCard
            id='contractDetails'
            size='large'
            header={true}
            headerTitle={data.title}
            caption={`${Constants.contract.reference} # ${data?.contractReference || Constants.noData }`}
            secondaryActionBtn={showDeleteButton}
            secondaryActionBtnText={Constants.deleteContractRecords}
            handleSecondaryActionBtn={() => deleteContract()}
            content={generateInfoContent()}
            footer={false}
        />
    </div>;
};

export default AwardedContractOverview;
