import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';
import helperFunctions from '../../utils/helperFunctions';
import Constants from './constants';
import LabelledRadioButton from '../../components/LabelledRadioButton';
import {
    evaluationPanelTypes, evaluationPanel, responseStatus, VIEW_ANSWER_MODE,
} from '../../config/constants';
import { handleDownload, handleMultipleDownload } from '../../utils/uploadHelper';
import userManagementAPIs from '../../services/user-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import ShowMoreShowLess from '../../components/SeeMoreSeeLess';
import Table from '../../components/Table';
import ToastConstants from '../../components/Alerts/Toast/constants';
import isSupplierRole from '../../utils/isSupplierRole';

const getVersionsStructure = (options) => options?.map((option) => ({
    ...option,
    ...!option.type && { type: Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE },
}));

const haveVersions = (versions, type) => {
    const structuredVersions = getVersionsStructure(versions);
    return structuredVersions?.some((version) => version.type === type);
};


const downloadAttachedFiles = async (event, files, responseId) => {
    event.preventDefault();
    const { response } = Constants.attachedToType;
    if (!files) return;
    if (files.length > 1) {
        handleMultipleDownload(files, responseId, response);
    } else {
        handleDownload(files[0], responseId, response);
    }
};
const EvaluationPanelRadioButtonGroup = ({
    questionnaireDetails,
    handleChange,
}) => <LabelledRadioButton
    id='evaluationPanel'
    label={evaluationPanel}
    breakColumn={false}
    onChange={handleChange}
    required={true}
    options={[{
        label: evaluationPanelTypes.individual.label,
        value: evaluationPanelTypes.individual.value,
        id: evaluationPanelTypes.individual.id,
        name: 'evaluationPanel',
        required: true,
        checked: questionnaireDetails.evaluationPanel
            ? questionnaireDetails.evaluationPanel === evaluationPanelTypes.individual.value
            : true,
    }, {
        label: evaluationPanelTypes.consensus.label,
        value: evaluationPanelTypes.consensus.value,
        id: evaluationPanelTypes.consensus.id,
        name: 'evaluationPanel',
        required: true,
        checked: questionnaireDetails.evaluationPanel
            ? questionnaireDetails.evaluationPanel === evaluationPanelTypes.consensus.value
            : false,
        disabled: true, // TODO Enable for Individual Evaluations
    }]}
    renderAsRow={true} />;

EvaluationPanelRadioButtonGroup.propTypes = {
    questionnaireDetails: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
};

const isBidSubmitted = (documentMode, documentState) => documentMode === VIEW_ANSWER_MODE
    && documentState?.bidStatus === responseStatus.bidSubmitted;

const getConfirmationTextPopup = (isAddMode, liveQuestionnaire, context, showReason) => {
    const actionConfirmation = isAddMode
        ? `${Constants.ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS} ${context.toLowerCase()}? `
        : `${Constants.ARE_YOU_SURE_YOU_WANT_TO_UPDATE_THIS} ${context.toLowerCase()}? `;

    const userConfirmed = liveQuestionnaire && showReason
        ? Constants.LIVE_QUESTIONNAIRE_EDIT_MESSAGE
        : actionConfirmation;

    return `${userConfirmed} ${Constants.PLEASE_ENSURE_YOU_ENTERED_CORRECT_DETAILS}`;
};
const getAllAccountUsersRequest = async () => {
    try {
        const response = await userManagementAPIs.getAllUsersByAccount(
            Constants.USER_ACCOUNT_APPROVED,
        );
        return response.data.map((userItem) => ({
            name: `${userItem.contactDetails?.firstname} ${userItem.contactDetails?.surname}`,
            value: userItem.userID,
            departmentName: userItem.departmentName,
            departmentID: userItem.departmentID,
            accountID: userItem.accountID,
        }));
    } catch (error) {
        Toast.fire({
            icon: ToastConstants.error,
            titleText: Constants.GET_ACCOUNT_USERS_ERROR,
        });
        return [];
    }
};

const handleFilter = (items) => (searchValue) => {
    if (!searchValue.length) {
        return items;
    }

    return items.filter((item) => (
        item.name.toLowerCase().includes(searchValue.toLowerCase())
    ));
};

const getSelectedEvaluatorsNames = (selectedEvaluators, evaluators) => selectedEvaluators
    ?.reduce((accumulator, evaluator) => {
        const selectedEvaluator = evaluators?.find((item) => item.value === evaluator);
        return selectedEvaluator ? `${accumulator}${selectedEvaluator.name}, ` : accumulator;
    }, '').slice(0, -2); // Remove the trailing comma and space

const evaluatorDropdownIsShown = (isEvaluateMode, isViewEvaluateMode,
    evalPanel, steeringInfo) => !isEvaluateMode()
&& !isViewEvaluateMode() && evalPanel === evaluationPanelTypes.consensus.value
&& steeringInfo?.length;

const EvaluatorDropdownSection = ({
    steeringInfo, handleChange, selectedEvaluators, isViewMode,
}) => {
    const evaluatorsOptions = helperFunctions.getEvaluatorUsers(steeringInfo);
    return <div className='sectionItemTextRow evaluators-dropdown'>
        <div className='labelValueContainer'>
            <p className='title label'>{Constants.EVALUATOR}</p>
            {isViewMode
                ? <p className='body value'>{getSelectedEvaluatorsNames(selectedEvaluators, evaluatorsOptions)}</p>
                : <SelectSearch
                    id={'users'}
                    options={evaluatorsOptions}
                    filterOptions={handleFilter}
                    search={true}
                    multiple={true}
                    value={selectedEvaluators}
                    printOptions='on-focus'
                    closeOnSelect={false}
                    placeholder={Constants.EVALUATOR_DROPDOWN_PLACEHOLDER}
                    onChange={handleChange}
                    z-index='4'
                />
            }
        </div>
    </div>;
};
EvaluatorDropdownSection.propTypes = {
    steeringInfo: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    selectedEvaluators: PropTypes.array,
    isViewMode: PropTypes.bool.isRequired,
};
const showDiffViewerDropdown = (
    accountType,
    showVersionDropdown,
    previousVersions,
    versionType = Constants.PREVIOUS_VERSIONS_RESPONSE_TYPE,
) => isSupplierRole(accountType)
&& showVersionDropdown
    && haveVersions(previousVersions, versionType);

const haveQuestionnairesAndResponsesPreviousVersions = (
    accountType, previousVersions,
) => (
    isSupplierRole(accountType)
    && haveVersions(previousVersions, Constants.PREVIOUS_VERSIONS_RESPONSE_TYPE)
    && haveVersions(previousVersions, Constants.PREVIOUS_VERSIONS_QUESTIONNAIRE_TYPE)
);

const prepareIndividualEvaluatorsScoringTable = (individualScorings) => (
    individualScorings.map((individualEval) => ({
        evaluator: <>{individualEval.userName}</>,
        type: <>{individualEval.type || '-' }</>,
        score: <>{individualEval.scoring || '-'}</>,
        evaluationComment: <ShowMoreShowLess
            inputText={individualEval.comments || '-' }
            inputTextCharacterLimit={80}
        />,
    })));

const generateEmptyContentForIndividualEvaluator = () => (<p className='empty-content'>{Constants.INDIVIDUAL_EVALUATOR_NO_QUESTIONS_ASSIGNED}.</p>);

const IndividualEvaluatorsScores = ({ individualScorings, questionID }) => {
    const [showIndividualEvaluatorScores, setShowIndividualEvaluatorScores] = useState(true);
    return <>
        <button
            id={'expand-collipse-button'}
            className='individual-evaluators-scoring-table-button'
            onClick={(e) => {
                e.preventDefault();
                setShowIndividualEvaluatorScores(!showIndividualEvaluatorScores);
            }}
        >{Constants.SHOW_HIDE_INDIVIDUAL_EVALUATORS_TABLE_BUTTON}</button>
        {showIndividualEvaluatorScores
            && <div className='individual-evaluators-scoring-table'>
                <Table
                    id={`${questionID}-individual-eval-scorings-table`}
                    headerData={Constants.SHOW_HIDE_INDIVIDUAL_EVALUATORS_TABLE_DATA_HEADERS}
                    bodyData={prepareIndividualEvaluatorsScoringTable(
                        individualScorings,
                    )}
                />
            </div>
        }
    </>;
};
IndividualEvaluatorsScores.propTypes = {
    individualScorings: PropTypes.array.isRequired,
    questionID: PropTypes.string.isRequired,
};


const updateQuestionsWithIndividualEvaluatorsFromSection = (data, sectionNumber, documentState) => {
    const updatedSections = documentState.sections.map((section, index) => {
        if (index === sectionNumber) {
            return {
                ...section,
                sectionItems: section.sectionItems.map((question) => ({
                    ...question,
                    individualEvaluators: data,
                })),
            };
        }
        return section;
    });
    return updatedSections;
};

const getSectionIndividualEvaluators = (sectionData) => {
    const { sectionItems } = sectionData;
    if (!sectionItems?.length) return [];
    const individualEvaluators = sectionItems
        .map((question) => question.individualEvaluators?.sort());
    const areArraysEqual = individualEvaluators?.every((val, _i,
        arr) => JSON.stringify(val) === JSON.stringify(arr[0]));
    return areArraysEqual ? individualEvaluators[0] : [];
};

const removeEvaluatorsFromSections = ({ sections, ...questionnaire }) => ({
    ...questionnaire,
    sections: sections.map(({ sectionItems, ...section }) => ({
        ...section,
        sectionItems: sectionItems?.map(({ individualEvaluators, ...sectionItem }) => sectionItem),
    })),
});

const showReasonIfChangesInQuestionnaire = (questionnaire, prevSavedQuestionnaire) => {
    const questionnaireWithNoEvals = removeEvaluatorsFromSections(questionnaire);
    const prevSaveQuestionnaireWithNoEvals = removeEvaluatorsFromSections(prevSavedQuestionnaire);
    return !helperFunctions.areObjectsEqual(questionnaireWithNoEvals,
        prevSaveQuestionnaireWithNoEvals);
};

const updateButtonIsDisabledInQuestionnaires = (context, initialDocumentState,
    documentState, documentBuilderState) => context
    .toLowerCase() === Constants.DOCUMENT_CONTEXT.QUESTIONNAIRE
    && helperFunctions.areObjectsEqual(initialDocumentState, documentState)
    && Object.keys(documentBuilderState).length === 0;

const getNumberOfAmendedQuestions = (sections) => sections.reduce((acc, section) => {
    const questions = section.sectionItems?.filter(
        (question) => question.isNewQuestion || question.isUpdatedQuestion,
    );
    return acc + questions?.length;
}, 0);

const limitToTwoDecimals = (value) => {
    const [integer, decimal = ''] = value.split('.');
    return decimal.length > 2 ? `${integer}.${decimal.substring(0, 2)}` : value;
};

export {
    getVersionsStructure,
    haveVersions,

    downloadAttachedFiles,
    EvaluationPanelRadioButtonGroup,
    isBidSubmitted,
    getConfirmationTextPopup,
    getAllAccountUsersRequest,
    EvaluatorDropdownSection,
    prepareIndividualEvaluatorsScoringTable,
    IndividualEvaluatorsScores,
    showDiffViewerDropdown,
    generateEmptyContentForIndividualEvaluator,
    haveQuestionnairesAndResponsesPreviousVersions,
    getSectionIndividualEvaluators,
    updateQuestionsWithIndividualEvaluatorsFromSection,
    evaluatorDropdownIsShown,
    showReasonIfChangesInQuestionnaire,
    updateButtonIsDisabledInQuestionnaires,
    getNumberOfAmendedQuestions,
    limitToTwoDecimals,
};
