import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import TimePicker from 'react-time-picker';
import LabelledRadioButton from '../../../components/LabelledRadioButton';
import Form from '../../../components/Form';
import Modal from '../../../components/Modal';
import clearIcon from '../../../styles/icons/blue/clear.svg';
import helperFunctions from '../../../utils/helperFunctions';

const StandstillModal = ({
    onCancel, onConfirm, label, endDateString,
}) => {
    const [standstillForm, setStandstillForm] = useState({
        standstillEndDate: '',
        standstillEndTime: '',
        standstillApplicable: false,
    });
    const [originalEndDateAndTime, setOriginalEndDateAndTime] = useState('');

    const handleEndDate = () => {
        setStandstillForm({
            standstillApplicable: true,
        });
        setOriginalEndDateAndTime(helperFunctions.formatPrettyDateTime(endDateString));
    };

    useEffect(() => {
        if (endDateString) {
            handleEndDate();
        }
    }, [endDateString]);

    const handleRadioChange = (selectedValue) => {
        setStandstillForm({
            ...standstillForm,
            standstillApplicable: selectedValue === 'Yes',
            standstillEndDate: selectedValue === 'No' ? '' : standstillForm.standstillEndDate,
            standstillEndTime: selectedValue === 'No' ? '' : standstillForm.standstillEndTime,
        });
    };

    const modalBody = <>
        <div className='standstill-container'>
            {label && endDateString === undefined && <p className='title sectionHeader'>{label}</p>}
            {endDateString === undefined && <LabelledRadioButton id={'standstill-question'}
                label={'Is a Standstill Timer Required?'}
                breakColumn={false}
                options= {[{
                    id: 'no-standstill',
                    value: 'Yes',
                    label: 'Yes',
                    checked: standstillForm.standstillApplicable,
                    required: true,
                    name: 'standstill-option',
                }, {
                    id: 'yes-standstill',
                    value: 'No',
                    label: 'No',
                    checked: !standstillForm.standstillApplicable,
                    required: true,
                    name: 'standstill-option',
                }]}
                onChange={(event) => handleRadioChange(event.target.value)}
                renderAsRow={true}/>}
            {standstillForm.standstillApplicable && <>
                <p className='title sectionHeader'>Please enter the Date/Time for the Standstill timer to expire</p>
                { endDateString && <p className='title sectionHeader'>Once resumed, standstill timer will end on <span className='caption bold formateDate'>{originalEndDateAndTime}</span>. If you wish to extend the timer, please enter a new end date and time</p>}
                <div className='standstill-form-item'>
                    <div className='standstill-form-label'>
                        <label className='title datePickerLabel'>End Date {endDateString ? <span className='optional'> (Optional)</span> : ''}</label>
                    </div>
                    <DatePicker
                        className='customDateInput standstill-date-input'
                        required={ endDateString === undefined }
                        dayPlaceholder='DD'
                        monthPlaceholder='MM'
                        yearPlaceholder='YYYY'
                        calendarIcon={null}
                        format={'dd-MM-yyyy'}
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(inputDate) => setStandstillForm({
                            ...standstillForm,
                            standstillEndDate: inputDate,
                        })}
                        value={standstillForm.standstillEndDate}
                        minDate={new Date()}
                    />
                </div>
                <div className='standstill-form-item'>
                    <div className='standstill-form-label'>
                        <label className='title'>End Time {endDateString ? <span className='optional'> (Optional)</span> : ''}</label>
                    </div>
                    <TimePicker
                        className='customDateInput standstill-date-input'
                        hourPlaceholder='HH'
                        minutePlaceholder='MM'
                        clockIcon={null}
                        required={ endDateString === undefined }
                        format={'HH:mm'}
                        clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                        onChange={(time) => setStandstillForm({
                            ...standstillForm,
                            standstillEndTime: time,
                        })}
                        value={standstillForm.standstillEndTime}
                        locale='en-GB'
                        disableClock={true}
                    />
                </div>
            </>}
        </div>
    </>;

    const onSubmit = (e) => {
        e.preventDefault();
        let formattedDate = '';
        if (standstillForm.standstillApplicable) {
            if (standstillForm.standstillEndDate) {
                const endDate = standstillForm.standstillEndDate;
                // Preventing a hard crash while Nepo determines what the behavior here should be
                // Likely to force endTime to be mandatory if endDate is filled
                endDate?.setHours(standstillForm?.standstillEndTime?.split(':')[0] || '00');
                endDate?.setMinutes(standstillForm?.standstillEndTime?.split(':')[1] || '00');
                formattedDate = endDate.toISOString();
            } else {
                formattedDate = endDateString;
            }

            onConfirm({
                standstillPaused: false,
                standstillEndDate: formattedDate,
                standstillApplicable: standstillForm.standstillApplicable,
            });
        } else {
            onConfirm({
                standstillPaused: false,
                standstillEndDate: '',
                standstillApplicable: standstillForm.standstillApplicable,
            });
        }
        return null;
    };

    return <>
        <Form className={'standstill'} id='standstill-form' onSubmit={(e) => onSubmit(e)}>
            <Modal
                open={true}
                closeModal={onCancel}
                size='medium'
                headerTitle='Are you sure?'
                handleMainActionBtnClick={() => null}
                mainActionButtonTxt='Confirm'
                closeButton={false}
                body={modalBody}
                helpOption={false}
                secondActionButton={true}
                secondActionButtonTxt='Cancel'
                handleSecondaryActionBtnClick={(e) => { e.preventDefault(); onCancel(); }}
            />
        </Form>
    </>;
};

StandstillModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    label: PropTypes.func.isRequired,
    endDateString: PropTypes.string,
};

export default StandstillModal;
