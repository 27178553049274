import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import SearchBar from '../../features/SearchBar';
import Pagination from '../../features/Pagination';
import chevronUpWhiteIcon from '../../styles/icons/white/chevron-up.svg';
import chevronDownWhiteIcon from '../../styles/icons/white/chevron-down.svg';
import Button from '../../components/Button';
import helperFunctions from '../../utils/helperFunctions';
import addIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import Confirm from '../../components/Alerts/Confirm/Confirm';
import { ProjectDataContext } from '../../context/ProjectDataContext';
import { ThemeContext } from '../../context/ThemeContext';

const MangeLots = () => {
    const { projectId } = useParams();
    const history = useHistory();
    const [lots, setLots] = useState([]);
    const [projectDetails, setProjectDetails] = useState({});
    const [searchFilteredDataSet, setSearchFilteredDataSet] = useState([]);
    const [currentPageNum, setCurrentPageNum] = useState(0);
    const [tabletMode, setTabletMode] = useState({ matches: window.innerWidth <= 840 });
    const [mobileMode, setMobileMode] = useState({ matches: window.innerWidth <= 600 });
    const [noFilterResults, setNoFilterResults] = useState(false);
    const { setProjectData } = useContext(ProjectDataContext);
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addIcon;

    const getLots = async () => {
        const responseData = await projectManagementAPIs.browseLots(projectId);
        if (responseData.status === 200) {
            responseData.data.items.sort((a, b) => (a.lotOrderNo > b.lotOrderNo ? 1 : -1));
            setLots(responseData.data.items);
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const getProject = async () => {
        const responseData = await projectManagementAPIs.getProject(projectId);
        if (responseData.status === 200) {
            setProjectDetails(responseData.data);
            setProjectData({ title: responseData.data.title });
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    useEffect(() => {
        const tabletMediaQuery = window.matchMedia('(max-width: 840px)');
        const mobileMediaQuery = window.matchMedia('(max-width: 600px)');
        tabletMediaQuery.addEventListener('change', setTabletMode);
        mobileMediaQuery.addEventListener('change', setMobileMode);
        getLots();
        getProject();
        setSearchFilteredDataSet([]);
        return () => {
            tabletMediaQuery.removeEventListener('change', setTabletMode);
            mobileMediaQuery.removeEventListener('change', setMobileMode);
        };
    }, []);

    const handleSearchFilterResult = (searchData) => {
        let filteredDataSet = [];
        const filterAttribute = 'title';
        const dataToProcess = [...lots];
        if (searchData?.search !== '' && dataToProcess.length > 0) {
            filteredDataSet = dataToProcess.filter((data) => data[filterAttribute]
                ?.toLowerCase().includes(searchData?.search.toLowerCase()));
        } else {
            filteredDataSet = [...lots];
        }
        if (filteredDataSet === null) {
            setNoFilterResults(true);
            setSearchFilteredDataSet(lots);
        } else {
            if (noFilterResults) {
                setNoFilterResults(false);
            }
            setSearchFilteredDataSet(filteredDataSet);
            if (filteredDataSet.length > 1) {
                setCurrentPageNum(0);
            }
        }
    };

    const calculateNumDisplayPages = () => {
        if (mobileMode.matches) {
            return 5;
        }

        if (tabletMode.matches) {
            return 8;
        }

        return 10;
    };

    const reorderLot = async (lot1, lot2) => {
        const payload = {
            lots: [lot1.lotID, lot2.lotID],
        };
        const responseData = await projectManagementAPIs.reorderLots(projectId, payload);
        if (responseData.status === 200) {
            getLots();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to retrieve information.',
            });
        }
    };

    const deleteLot = async (lotID) => {
        const response = await projectManagementAPIs.deleteLot(projectId, lotID);
        if (response.status === 200) {
            Toast.fire({
                icon: 'success',
                titleText: 'Lot deleted successfully.',
            });
            getLots();
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to delete lot.',
            });
        }
    };

    const getCodes = (codes) => codes.map((code) => (`${code.category} - ${code.level}`)).join(', ');

    const getRegions = (deliveryAreas) => deliveryAreas.map((item) => (`${item.code} - ${item.description}`)).join(', ');

    const getContentBody = (data) => {
        const currentPageData = helperFunctions.splitDataSetForPagination(
            data, calculateNumDisplayPages(),
        )[currentPageNum];
        const dataSet = currentPageData && currentPageData[0]?.length
            ? [...currentPageData[0]]
            : [];
        return <div id='lotItemMainContent'>
            {dataSet && dataSet.length > 0
            && dataSet.map((lot, idx, elements) => (
                <div className={'sectionItemMainContentRow'} key={lot.lotID}>
                    <div className='sectionItemReorderBtnsCol'>
                        {projectDetails.projectStatus !== 'cancelled'
                        && <>
                            <button className={'orderBtn up'}
                                id={'orderUpBtn-1'}
                                disabled={idx === 0}
                                onClick={() => reorderLot(lot, elements[idx - 1])}>
                                <img src={chevronUpWhiteIcon} alt='move-up'></img>
                            </button>
                            <button className={'orderBtn down'}
                                id={'orderDownBtn-1'}
                                disabled={idx === lots.length - 1}
                                onClick={() => reorderLot(lot, elements[idx + 1])}>
                                <img src={chevronDownWhiteIcon} alt='move-down'></img>
                            </button>
                        </>}
                    </div>
                    <div className='sectionItemTextCol'>
                        <p className='title-large sectionItemHeading'>{`Lot ${lot.lotOrderNo}`}: {lot.title}</p>
                        <p className='caption sectionItemDescription'>{lot.description}</p>
                        <p className='caption sectionItemDescription'>
                            Codes and Categories: <span className='caption-small'>{lot.industryInfo?.length > 0 ? getCodes(lot?.industryInfo) : '-'}
                            </span></p>
                        <p className='caption sectionItemDescription'>
                            Delivery Area: <span className='caption-small'>{lot.deliveryAreaInfo?.length > 0 ? getRegions(lot?.deliveryAreaInfo) : '-'}</span></p>
                    </div>
                    <div className='sectionItemBtnCol'>
                        <Button id={'viewSectionItemBtn-1'}
                            label={'View'}
                            size='small'
                            handleClick={() => history.push(`/projects/overview/${projectId}/lot/view/${lot.lotID}`)}
                        />
                        {projectDetails.projectStatus !== 'cancelled'
                        && <><Button id={'editSectionItemBtn-1'}
                            variant='secondary'
                            label={'Edit'}
                            size='small'
                            handleClick={() => history.push(`/projects/overview/${projectId}/lot/edit/${lot.lotID}`)}
                        />
                        <Button id={'removeSectionItemBtn-1'}
                            variant='primary red'
                            size='small'
                            label={'Remove'}
                            handleClick={() => {
                                Confirm(() => deleteLot(lot.lotID), {
                                    subTitle: `Are you sure you want to delete lot ${lot.title}?`,
                                });
                            }}
                        />
                        </>}
                    </div>
                </div>
            ))}
        </div>;
    };

    return <>
        <div id='manageLotsContentSectionWrapper'>
            <section id='manageLotsContentSection'>
                <div id='manageLotsContentHeader'>
                    <div className='searchBarContainer'>
                        <SearchBar
                            id={'manageLotsSearchBar'}
                            placeholder={'Search for a lot'}
                            handleSearchCallback={handleSearchFilterResult} />
                    </div>
                    <div className='buttonContainer'>
                        {projectDetails.projectStatus !== 'cancelled'
                        && <Button
                            id={'addNewLotBtn'}
                            label={'Add Lot'}
                            icon={true}
                            iconSrc={addIconSrc}
                            handleClick={() => history.push(`/projects/overview/${projectId}/lot/create`)}
                            variant='secondary skinless'
                        />}
                        <Button
                            id={'backBtn'}
                            label={'Back'}
                            icon={true}
                            handleClick={() => history.push(`/projects/overview/${projectId}`)}
                            variant='secondary'
                        />
                    </div>
                </div>
                <div id='manageLotsContentBody' aria-live='assertive'>
                    {searchFilteredDataSet.length > 0
                        ? getContentBody(searchFilteredDataSet)
                        : getContentBody(lots) }
                </div>
                <div id='manageLotsContentFooter'>
                    {lots && searchFilteredDataSet
                    && <Pagination
                        id={'manageLotsPagination'}
                        pages={(searchFilteredDataSet.length > 0)
                            ? helperFunctions.splitDataSetForPagination(
                                searchFilteredDataSet, calculateNumDisplayPages(),
                            )
                            : helperFunctions.splitDataSetForPagination(
                                lots, calculateNumDisplayPages(),
                            )}
                        currentPageNum={currentPageNum}
                        numOfDisplayPages={calculateNumDisplayPages()}
                        paginate={(pageNumber) => {
                            setCurrentPageNum(pageNumber);
                        }}
                    />}
                </div>
            </section >
        </div>
    </>;
};

MangeLots.propTypes = {
    handleSearchSortFilter: PropTypes.func,
};

export default MangeLots;
