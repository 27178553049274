import React from 'react';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import dpsConstants from '../../dpsConstants';
import Toast from '../../../../components/Alerts/Toast/Toast';
import gatewayOrchestratorAPIs from '../../../../services/gateway.service';


const ToastErrorComponent = () => Toast.fire({
    icon: dpsConstants.errorIcon,
    titleText: dpsConstants.unableToDownloadDoc,
});

const exportFunction = async (projectId) => {
    const response = await gatewayOrchestratorAPIs.exportAnswerSummary(projectId);
    if (response.status === 200) {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const filename = response.headers['content-disposition']?.split('"')[1] || 'export.csv';
        try {
            saveAs(blob, filename);
        } catch (error) {
            ToastErrorComponent();
        }
    } else {
        ToastErrorComponent();
    }
};

const ExportButton = (projectId) => <Button
    id='primary-action-btn'
    type='button'
    size='small'
    variant='export-link'
    label={dpsConstants.exportAllLabel}
    handleClick={() => exportFunction(projectId)}
/>;


ExportButton.propTypes = {
    projectId: PropTypes.string,
};

export default ExportButton;
