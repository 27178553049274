import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { questionnaireTypes, intentStatuses } from '../../../config/constants';
import helperFunctions from '../../../utils/helperFunctions';
import tickIcon from '../../../styles/icons/black/tick.svg';
import crossIcon from '../../../styles/icons/black/cross.svg';
import awardConstants, { getConfirmationText } from '../constants';
import Modal from '../../../components/Modal';

const SectionContent = ({
    data,
    accountID,
    showButton = false,
    handleUpdateIntentStatus,
    isLotLevel = false,
    readOnly,
    isDPS,
}) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [status, setStatus] = useState(null);
    const [showEditButton, setShowEditButton] = useState(false);

    useEffect(() => {
        if (data.intentStatus) {
            setShowEditButton(true);
        } else {
            setShowEditButton(false);
        }
    }, [data]);

    const sectionLength = (data.publishLevel === 'project' ? data?.responses?.questionnaires?.length
        : data?.questionnaires?.length) - 1;

    const generateButtons = (response, idx) => <Button
        id={`view-Btn-${idx}`}
        label={awardConstants.VIEW_EVALUATION}
        size='small'
        additionalVariant='view-link'
        handleClick={() => history.push(`/questionnaires/evaluate/view/${response.responseID}`) }
    />;

    const getPrettyQuestionnaireType = (qType) => questionnaireTypes.filter(
        (eventType) => eventType.value === qType,
    )[0]?.label;

    const awardSummaryButtons = () => (<>
        {(!data.awardLetterSent)
            && !readOnly
            && !isDPS
            && (showEditButton
                ? <Button
                    id='editAwardBtn'
                    className='edit-link'
                    size='small'
                    label={awardConstants.EDIT_AWARD_STATUS}
                    handleClick={() => {
                        setShowEditButton(false);
                    }}
                />
                : <>
                    <Button
                        id='succesfulBtn'
                        className='successful'
                        size={'small'}
                        label={awardConstants.SUCCESSFUL}
                        icon={true}
                        iconSrc={tickIcon}
                        handleClick={() => {
                            setShowModal(true);
                            setStatus(intentStatuses.successful);
                        }}
                    />
                    <Button
                        id='unsuccesfulBtn'
                        className='unsuccessful'
                        size={'small'}
                        label={awardConstants.UNSUCCESSFUL}
                        icon={true}
                        iconSrc={crossIcon}
                        handleClick={() => {
                            setShowModal(true);
                            setStatus(intentStatuses.unsuccessful);
                        }}
                    />
                </>
            )}
    </>);

    return (<>
        {showModal && <Modal
            id='confirmAwardStatusModal'
            open={true}
            size='small'
            headerTitle={awardConstants.EDIT_AWARD_STATUS}
            body={<p>{getConfirmationText(status)}</p>}
            helpOption={false}
            mainActionButtonTxt={awardConstants.CONFIRM}
            handleMainActionBtnClick={() => {
                handleUpdateIntentStatus(status, accountID, data?.lotID);
                setShowModal(false);
            }}
            secondActionButton={true}
            secondActionButtonTxt={awardConstants.CANCEL}
            handleSecondaryActionBtnClick={() => { setShowModal(false); }}
            closeModal={() => { setShowModal(false); }} />}

        {data.publishLevel === 'project'
            ? data?.responses?.questionnaires?.map(
                (item, idx) => (<div className='sbAccordionSectionContentRow' key={idx}>
                    <div className= {`sbAccordionSectionContentData ${isLotLevel && 'lotLevelAdditionalPadding'}`} >
                        <p className='label title'>{item.responseName}</p>
                        <p className='label body'>{getPrettyQuestionnaireType(item.responseType)}</p>
                    </div>
                    <div className='sbAccordionSectionContentAddon'>
                        {item?.weightage !== undefined
                       && <p className='label body'> Score: {`${parseFloat(item.score)}/${item.weightage}`}</p>}
                        {item?.passFailResult
                    && <p className='label body'>{`Pass/ Fail Result: ${helperFunctions.camel2title(item.passFailResult)}`}</p>}
                        {showButton && generateButtons(item, idx)}
                        { awardSummaryButtons() }
                    </div>
                    {sectionLength > idx && <div className='splitter' />}
                </div>),
            )
            : data?.questionnaires?.map(
                (item, idx) => (<div className='sbAccordionSectionContentRow' key={idx}>
                    <div className= {`sbAccordionSectionContentData ${isLotLevel && 'lotLevelAdditionalPadding'}`} >
                        <p className='label title'>{item.responseName}</p>
                        <p className='label body'>{getPrettyQuestionnaireType(item.responseType)}</p>
                    </div>
                    <div className='sbAccordionSectionContentAddon'>
                        {item?.weightage !== undefined
                       && <p className='label body'> Score: {`${parseFloat(item.score)}/${item.weightage}`}</p>}
                        {item?.passFailResult
                    && <p className='label body'>{`Pass/ Fail Result: ${helperFunctions.camel2title(item.passFailResult)}`}</p>}
                        {showButton && generateButtons(item, idx)}
                        { awardSummaryButtons() }
                    </div>
                    {sectionLength > idx && <div className='splitter' />}
                </div>),
            )
        }
    </>);
};

SectionContent.propTypes = {
    data: PropTypes.object.isRequired,
    accountID: PropTypes.string.isRequired,
    showButton: PropTypes.bool,
    handleUpdateIntentStatus: PropTypes.func,
    isLotLevel: PropTypes.bool,
    readOnly: PropTypes.bool,
    isDPS: PropTypes.bool,
};

export default SectionContent;
