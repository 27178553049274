const ToastConstants = {
    success: 'success',
    error: 'error',
    info: 'info',
    unableToRetrieveInfo: 'Unable to retrieve information.',
    successfullyAddedSupplier: 'Successfully added supplier.',
    supplierAlreadyRegistered: 'Supplier already registered.',
    failedToAddSupplier: 'Failed to add supplier.',
    hasToBeDirectAward: 'It has to be a Direct Award Project.',
};

export default ToastConstants;
