/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, {
    useEffect, useContext, useState, Suspense,
} from 'react';
import {
    BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';

import Constants from './route.constants';
import ScrollToTop from './ScrollToTop';

import AuthContext from '../context/AuthContext';

import Footer from '../components/Footer';

import Header from '../features/Header';
import Breadcrumbs from '../features/Breadcrumbs';
import PageHeader from '../features/PageHeader';

import gatewayAPIs from '../services/gateway.service';
import { ThemeContext } from '../context/ThemeContext';
import { ProjectDataProvider } from '../context/ProjectDataContext';

const Routes = () => {
    const authContext = useContext(AuthContext);
    const [waitForInit, setWaitForInit] = useState(true);
    const { toggle } = useContext(ThemeContext);

    useEffect(() => {
        (async () => {
            const trySilentAuth = await gatewayAPIs.verify();
            if (trySilentAuth.status === 200) {
                authContext.silentLogin(trySilentAuth.data);
            }
            setWaitForInit(false);
        })();
    }, []);

    return (!waitForInit && authContext.authenticated !== undefined)
    && <Suspense fallback={<div>Loading... </div>}>
        <ScrollToTop />
        <Switch>
            {Constants.routes.map(({
                path, name, Component, publicRoute,
            }, idx) => <Route key={`route-${idx}`}
                exact path={path}
                render={() => ((authContext.authenticated || true) ? (path === '/' || path.includes('/login'))
                    ? (path === '/') ? <>
                        <Header />
                        <Component />
                    </> : <>
                        <Component />
                    </>
                    : <>
                        <Header />
                        <main className={`mainContent ${toggle ? 'new-mainContent' : ''}`} id='main'>
                            <PageHeader
                                title={name}
                                className={`remove-on-print ${path === '/dashboard' && toggle ? 'dashboard-header' : ''}`}
                            />
                            <ProjectDataProvider>
                                <Breadcrumbs />
                                <Component />
                            </ProjectDataProvider>
                        </main>
                    </>
                    : <Redirect to='/' />)}
            />)}
        </Switch>
        <Footer />
    </Suspense>;
};

export default Routes;
