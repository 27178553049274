import React from 'react';
import { ThemeContext } from '../../context/ThemeContext';
import Constants from './constants';

export default function ToogleThemeChange() {
    const { toggle, toggleFunction } = React.useContext(ThemeContext);
    return (
        <div className='theme-change-button'>
            <button onClick={toggleFunction}>{Constants.CHANGE_TO} {toggle
                ? Constants.CURRENT_STYLE
                : Constants.NEW_STYLE}
            </button>
        </div>
    );
}
