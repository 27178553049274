import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import LOADER_TYPES from './constants';
import { ThemeContext } from '../../context/ThemeContext';


const LinearProgressBar = ({ progress }) => (<Box sx={{ width: '100%', height: '4px' }}>
    {progress >= 0 && progress <= 100
        ? <LinearProgress variant='determinate' value={progress} />
        : <LinearProgress />}
</Box>);

const CircularProgressBar = () => (<Box sx={{ width: '90%', height: '90%' }}>
    <div className={'loader full-centered'}>
        <CircularProgress size={50} />
    </div>
</Box>);
/**
 * Different loading indicators based on the type provided. If supported, and progress is provided,
 * the 'determinate' variant will be shown. These ones show the percentage
 * All params are provided within an object
 * @param {'circle'|'bar'} type type of loader we want to show
 * @param {number} progress progress of the loader, if available
 * @returns A Progress indicator that intends to use most of the space of the parent container.
 *   It is centered in the middle of the parent container. and has some margin around it.
 */
const LoadingInProgressIndicator = ({ type = LOADER_TYPES.CIRCLE, progress = undefined }) => {
    const { toggle } = useContext(ThemeContext);
    return <div className={`progress-loader-container ${toggle ? 'new' : ''}`}>
        {type === LOADER_TYPES.BAR
            ? LinearProgressBar({ progress })
            : CircularProgressBar()}
    </div>;
};


LoadingInProgressIndicator.propTypes = {
    progress: PropTypes.number,
    type: PropTypes.oneOf([LOADER_TYPES.CIRCLE, LOADER_TYPES.BAR]),
};

LinearProgressBar.propTypes = {
    progress: PropTypes.number,
};

export default LoadingInProgressIndicator;
