import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import logo from '../../styles/images/NewFooterLogo.png';
import Constants from './constants';
import AuthContext from '../../context/AuthContext';
import { ThemeContext } from '../../context/ThemeContext';

const generateFooterLinks = () => {
    const { footerData } = Constants;

    return footerData.map((data, idx) => {
        if (data.type === Constants.linkType.router) {
            return (
                <Link key={idx} className='footerLink links' to={data.link}>
                    {data.title}
                </Link>
            );
        }
        return (
            <a key={idx} target='_blank' className={`footerLink links ${data.class}`} title={data.title} href={data.link} rel='noreferrer'>
                {data.title}
            </a>
        );
    });
};


const currentYear = () => {
    const year = format(new Date(), 'yyyy');
    return year;
};

const Footer = () => {
    const authContext = useContext(AuthContext);
    const { toggle } = useContext(ThemeContext);
    const handleRedirect = () => {
        if (authContext.authenticated) {
            window.open('https://www.nepo.org/', '_blank');
        }
    };

    return <footer className={`footer remove-on-print ${toggle ? 'new-footer' : ''}`}>
        <div id='footerLogo' onClick={handleRedirect}>
            <img src={logo} alt='footer-logo' />
        </div>
        <div id='footerLinks'>
            {generateFooterLinks()}
        </div>
        <div id='copyrightTextContainer'>
            <p className='caption'>© {currentYear()} NEPO. All Rights Reserved.</p>
        </div>
    </footer>;
};

export default Footer;
