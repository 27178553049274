import React from 'react';
import Tag from '../../../components/Tag';
import {
    routeToMarket, callOffMechanisms, projectDescribes,
} from '../../../config/constants';
import helperFunctions from '../../../utils/helperFunctions';
import {
    manualCreationId,
    previousContractLink, projectDetailsTitles, projectOwnerType, publishQuestionData,
} from './constants';

const generateCodeTags = (item) => {
    const TagList = [];
    item?.forEach((tag, index) => {
        const TagElement = <Tag key={`${tag.classificationID}-${index}`}
            id={`row-${tag.classificationID}-${index}`}
            tagTxt={`${tag.category} - ${tag.level}`}
            isDeletable={false}
            size='large' />;
        TagList.push(TagElement);
    });
    if (TagList.length === 0) {
        return <p className={'caption'}>{'No codes and categories found.'}</p>;
    }
    return <div className='sectionContentTags'>
        {TagList}
    </div>;
};

const generateRegionTags = (item) => {
    const TagList = [];
    item?.forEach((tag, index) => {
        const TagElement = <Tag key={`${tag.code}-${index}`}
            id={`row-${tag.code}-${index}`}
            tagTxt={`${tag.code} - ${tag.description}`}
            isDeletable={false}
            size='large' />;
        TagList.push(TagElement);
    });
    if (TagList.length === 0) {
        return <p className={'caption'}>{'No region(s) of supply found.'}</p>;
    }
    return <div className='sectionContentTags'>
        {TagList}
    </div>;
};

const getPrettyRTM = (rtm) => (routeToMarket.find((route) => route.id === rtm)?.name);

const getPrettyCallOffMechanism = (callOffMechanism) => callOffMechanisms.filter(
    (eventType) => eventType.value === callOffMechanism,
)[0]?.label;

const getPrettyProjectType = (pType) => projectDescribes.filter(
    (type) => type.value === pType,
)[0]?.label;

const generateProjectDataChildren = (details, handleViewContract) => <>
    <div className='sectionContent'>
        <div id='description' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.DESCRIPTION}</p>
            <p className='caption sectionValue'>{helperFunctions.simpleFormat(details.description ? details.description : '-', 'p', 'caption', true)}</p>
        </div>
        <div id='estimatedValue' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.ESTIMATED_VALUE}</p>
            <p className='caption sectionValue'>{details.estimatedValue ? helperFunctions.formatCurrency(details.estimatedValue) : '-'}</p>
        </div>
        <div id='contractStart' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.CONTRACT_START_DATE}</p>
            <p className='caption sectionValue'>{details.contractStart ? details.contractStart : '-'}</p>
        </div>
        <div id='contractEnd' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.CONTRACT_END_DATE}</p>
            <p className='caption sectionValue'>{details.contractEnd ? details.contractEnd : '-'}</p>
        </div>
        <div id='contractDuration' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.CONTRACT_DURATION}</p>
            <p className='caption sectionValue'>{details.contractDuration ? details.contractDuration : '-'}</p>
        </div>
        <div id='routeToMarket' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.ROUTE_TO_MARKET}</p>
            <p className='caption sectionValue'>{getPrettyRTM(details.route) ? getPrettyRTM(details.route) : '-'}</p>
        </div>
        {details.route === manualCreationId && (
            <div id='projectOwner' className='sectionRow'>
                <p className='title sectionLabel'>{projectDetailsTitles.PROJECT_OWNER}</p>
                <p className='caption sectionValue'>{details.isExternal ? projectOwnerType.EXTERNAL : details.account.companyName}</p>
            </div>
        )}
        {details.projectType !== 'calloff'
        && <div id='contractStart' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.PROJECT_TYPE}</p>
            <p className='caption sectionValue'>{details.projectDescribes?.length > 0
                ? details?.projectDescribes.map((role, i) => {
                    if (i + 1 === details?.projectDescribes.length) {
                        return `${getPrettyProjectType(role)}`;
                    }
                    return `${getPrettyProjectType(role)}, `;
                })
                : '-'}</p>
        </div>}
        <div id='callOfMechanism' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.CALL_OF_MECHANISM}</p>
            <p className='caption sectionValue'>{getPrettyCallOffMechanism(details.callOffMechanism) ? getPrettyCallOffMechanism(details.callOffMechanism) : '-'}</p>
        </div>
        <div id='extensionsAvailable' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.EXTENSIONS_AVAILABLE}</p>
            <p className='caption sectionValue'>{Array.isArray(details.extensionsAvailable) ? details.extensionsAvailable.length : '-'}</p>
        </div>
        {Array.isArray(details.extensionsAvailable)
            ? details.extensionsAvailable.map((extension, index) => <div id='contractDuration' className='sectionRow' key={`extendion${index}`}>
                <p className='title sectionLabel'>{projectDetailsTitles.EXTENSION_DURATION} {index + 1}:</p>
                <p className='caption sectionValue'>{extension.monthsAvailable}</p>
            </div>)
            : <></>}
        <div id='vatCannotRecovered' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.VAT_THAT_CANNOT_BE_RECOVERED}</p>
            <p className='caption sectionValue'>{details.unrecoverableVAT ? helperFunctions.formatCurrency(details.unrecoverableVAT) : '-'}</p>
        </div>
        <div id='responsibleOfficer' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.RESPONSIBLE_OFFICER}</p>
            <p className='caption sectionValue'>{details.responsibleUser?.user?.contactDetails?.username}</p>
        </div>
        <div id='publishQuestion' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.PUBLISH_QUESTION}</p>
            <p className='caption sectionValue'>{details.visibleToCR ? publishQuestionData.YES : publishQuestionData.NO}</p>
        </div>
        {details?.parentContractID
        && <div id='previousContract' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.PREVIOUS_CONTRACT}</p>
            <p className='caption sectionValue'><a href='' onClick = {(e) => handleViewContract(e)}>{previousContractLink}</a> </p>
        </div>}
        <div id='codesAndCategories' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.CODES_AND_CATEGORIES}</p>
            <div className='caption sectionValue'>{generateCodeTags(details.industryInfo)}</div>
        </div>
        <div id='deliveryArea' className='sectionRow'>
            <p className='title sectionLabel'>{projectDetailsTitles.DELIVERY_AREA}</p>
            <div className='caption sectionValue'>{generateRegionTags(details.deliveryAreaInfo)}</div>
        </div>
    </div>
</>;

export default generateProjectDataChildren;
