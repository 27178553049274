/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isPast, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Button from '../../components/Button';
import {
    approvalStatuses,
    intentStatuses,
    intentLetterStatuses,
    sendAwardLetterModalMessage,
    publishRound,
    publishAtLevel,
} from '../../config/constants';
import rbac from '../../rbac';
import AuthContext from '../../context/AuthContext';
import SupplierItem from './components/SupplierItem';
import Accordion from '../Accordion';
import StandstillTimer from '../StandstillTimer';
import StandstillModal from './components/StandstillModal';
import Modal from '../../components/Modal';
import StatusLabel from '../../components/StatusLabel';
import SummaryBuyerAwardDPS from '../SummaryBuyerAwardDPS/index';
import SupplierDataUpload from './components/SupplierDataUpload';
import awardConstants from './constants';
import userIcon from '../../styles/icons/blue/user-2.svg';

import { getOpenNodes, updateAndSaveOpenNodesInLocalStorage, getLocalStorageProperty } from '../../services/localstorage.service';

const SummaryBuyerAward = ({
    projectDetails,
    dataSet,
    handleStatusUpdate,
    handleIntentLetters,
    handleSendAwards,
    handleStandstill,
    handleUpdateIntentStatus,
    uploadIntentLetter,
    deleteIntentLetter,
    isDPS,
    intentDocumentsRequired,
    awardDocumentsRequired,
    publishLevel,
    readOnly,
    isProjectDPS,
    eventId,
}) => {
    const [showStandstillModal, setShowStandstillModal] = useState(false);
    const [draftContractModal, setDraftContractModal] = useState(false);
    const [sendAwardModal, setSendAwardModal] = useState(false);
    const [standStillLabel, setStandStillLabel] = useState('');
    const [supplierAccountId, setSupplierAccountId] = useState('');
    const [firstLotId, setFirstLotId] = useState();
    const history = useHistory();
    const authContext = useContext(AuthContext);

    const localStorageProperty = getLocalStorageProperty(awardConstants.A_SUMMARY, authContext.user.id,
        projectDetails?.projectID, eventId);

    const userSteeringRole = rbac.util.getSteeringGroupRole(
        authContext.user.id,
        projectDetails?.steeringInfo,
    );


    const handleResumeStandstill = () => {
        setStandStillLabel('In order to resume the standstill timer, a new end date is required.');
        setShowStandstillModal(true);
    };

    const handleSendIntentLetter = () => {
        setStandStillLabel('Are you sure you want to send intent letters to suppliers?');
        setShowStandstillModal(true);
    };

    const handleAwardModal = (accountId, lotId) => {
        if (projectDetails.contractID) {
            setSupplierAccountId(accountId);
            setFirstLotId(lotId);
            setSendAwardModal(true);
        } else {
            setDraftContractModal(true);
        }
    };

    const getIntentStatus = (data) => {
        const mappedStatuses = [...new Set(data.map(
            (item) => item?.intentStatus,
        ))];
        const filteredStatuses = mappedStatuses.filter(
            (status) => status === intentStatuses.successful
        || status === intentStatuses.unsuccessful,
        );
        if (filteredStatuses.length === mappedStatuses.length
        ) {
            return 'successful';
        }
        return 'pending';
    };

    const getIntentStatusLot = (data) => {
        const mappedStatuses = [];
        data.forEach((lot) => {
            let result;
            const mappedLot = [...new Set(lot.responses.map((item) => item.intentStatus))];
            const filteredLot = mappedLot.filter(
                (status) => status === intentStatuses.successful
            || status === intentStatuses.unsuccessful,
            );
            if (filteredLot.length === mappedLot.length) {
                result = 'successful';
            } else {
                result = 'pending';
            }
            mappedStatuses.push(result);
        });
        const filteredStatuses = mappedStatuses.filter(
            (status) => status === intentStatuses.successful
        || status === intentStatuses.unsuccessful,
        );
        if (filteredStatuses.length === mappedStatuses.length) {
            return 'successful';
        }
        return 'pending';
    };

    const generateDSPSolutionHeaderButtons = () => (dataSet?.awardInfo?.awardLetterSent
        ? <div className='sbContentHeader'>
            <div className='headerEvaluationContainer'>
                <p className='body'>Awards Sent Successfully</p>
                <div className='headerButtonContainer'>
                    {dataSet?.awardInfo?.solutionID
                        && <Button
                            id={'viewSolution'}
                            label='View Solution'
                            variant={'secondary'}
                            handleClick={() => history.push(`/solutions/overview/${dataSet?.awardInfo?.solutionID}`)}
                        />}
                    {dataSet?.awardInfo?.contractID
                        && <Button
                            id={'viewContract'}
                            label='View Contract'
                            variant={'primary'}
                            handleClick={() => history.push(`/contracts/management/${dataSet?.awardInfo?.contractID}`)}
                        />}
                </div>
            </div>
        </div>
        : <div className='sbContentHeader'>
            <div className='headerButtonContainer'>
                <Button
                    id={'back'}
                    size={'small'}
                    label='Back'
                    variant={'normal-link'}
                    handleClick={() => history.goBack()}
                />
            </div>
        </div>);

    const generateAwardHeaderButtons = () => {
        const response = {};
        if (dataSet.publishLevel === 'project' && dataSet.groupBy === 'lotProject' && !isDPS) {
            response.intentStatus = getIntentStatus(dataSet?.responses?.responses);
        } else if (publishLevel === 'project' && !isDPS) {
            response.intentStatus = getIntentStatus(dataSet.responses);
        } else if (!isDPS) {
            response.intentStatus = getIntentStatusLot(dataSet.responses);
        }

        switch (true) {
            case dataSet?.awardInfo?.awardLetterSent:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerEvaluationContainer'>
                            <p className='body'>Awards Sent Successfully</p>
                            <div className='headerButtonContainer'>
                                <Button
                                    id={'back'}
                                    size={'small'}
                                    label='Back'
                                    variant={'normal-link'}
                                    handleClick={() => history.goBack()}
                                />
                                {dataSet?.awardInfo?.solutionID
                                && <Button
                                    id={'viewSolution'}
                                    label='View Solution'
                                    size={'small'}
                                    variant={'secondary'}
                                    handleClick={() => history.push(`/solutions/overview/${dataSet?.awardInfo?.solutionID}`)}
                                />}
                                {dataSet?.awardInfo?.contractID
                                && <Button
                                    id={'viewContract'}
                                    label='View Contract'
                                    size={'small'}
                                    variant={'primary'}
                                    handleClick={() => history.push(`/contracts/management/${dataSet?.awardInfo?.contractID}`)}
                                />}
                            </div>
                        </div>
                    </div>
                </>;
            case dataSet?.awardInfo?.awardApprovalAllResponses === approvalStatuses.approved && !readOnly:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            {<StatusLabel
                                id={'asHeaderLabel'}
                                color='amber'
                                labelTxt='Award Letters Not Sent'
                            />}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            {rbac.can(
                                rbac.action.submitAwardForApproval,
                                rbac.util.getSteeringGroupRole(
                                    authContext.user.id,
                                    projectDetails?.steeringInfo,
                                ),
                            ) && <Button
                                id={'sendAwardLetters'}
                                label='Send Award Letters'
                                size={'small'}
                                handleClick={() => handleAwardModal()}
                            />}
                        </div>
                    </div>
                </>;
            case dataSet?.awardInfo?.awardApprovalAllResponses === approvalStatuses.stage2ApprovalPending && !readOnly:
                return <> {
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            {<StatusLabel
                                id={'asHeaderLabel'}
                                color='amber'
                                labelTxt='Stage 2 Approval Pending'
                            />}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            {rbac.can(rbac.action.approveStage2,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <> <Button
                                id={'rejectAll'}
                                label='Reject All'
                                variant={'primary'}
                                size={'small'}
                                additionalVariant={'red'}
                                handleClick={() => handleStatusUpdate('rejected', 'awardletter')}
                            />
                            <Button
                                id={'approveAll'}
                                label='Approve All'
                                size={'small'}
                                handleClick={() => handleStatusUpdate('s2approved', 'awardletter')}
                            /></>}
                        </div>
                    </div>}
                </>;
            case dataSet?.awardInfo?.awardApprovalAllResponses === approvalStatuses.stage1ApprovalPending && !readOnly:
                return <> {
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            {<StatusLabel
                                id={'asHeaderLabel'}
                                color='amber'
                                labelTxt='Stage 1 Approval Pending'
                            />}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            {rbac.can(rbac.action.approveStage1,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <> <Button
                                id={'rejectAll'}
                                label='Reject All'
                                variant={'primary'}
                                size={'small'}
                                additionalVariant={'red'}
                                handleClick={() => handleStatusUpdate('rejected', 'awardletter')}
                            />
                            <Button
                                id={'approveAll'}
                                label='Approve All'
                                size={'small'}
                                handleClick={() => handleStatusUpdate('s1approved', 'awardletter')}
                            /></>}
                        </div>
                    </div>}
                </>;
            case dataSet.addIntentProcedure === true && !intentDocumentsRequired && !awardDocumentsRequired && ((dataSet?.awardInfo?.standstillEndDate && dataSet.awardInfo?.standstillPaused === false && isPast(parseISO(dataSet.awardInfo.standstillEndDate)) === true) || (dataSet?.awardInfo?.standstillApplicable !== undefined && dataSet?.awardInfo?.standstillApplicable === false)) && !readOnly:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            {rbac.can(rbac.action.submitAwardLetterForApproval,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <Button
                                id={'submitForApproval'}
                                label='Submit for Approval'
                                size={'small'}
                                handleClick={() => handleStatusUpdate('submit')}
                            />}
                        </div>
                    </div>
                </>;
            case dataSet?.awardInfo?.standstillEndDate && dataSet?.awardInfo?.standstillPaused === true && dataSet.addIntentProcedure === true && !readOnly:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            <p className='body bold'>Standstill Timer : <span className='body bold' id='colorRed'>Paused</span></p>
                            { rbac.can(rbac.action.updateStandstillTimer,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <Button
                                id={'resumeStandstill'}
                                label='Resume Standstill'
                                variant={'primary'}
                                size={'small'}
                                handleClick={() => handleResumeStandstill(
                                    dataSet?.awardInfo?.standstillEndDate,
                                )}
                            />}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                        </div>
                    </div>
                </>;
            case dataSet?.awardInfo?.standstillEndDate && dataSet?.awardInfo?.standstillPaused === false && dataSet.addIntentProcedure === true && dataSet?.awardInfo?.standstillEndDate && dataSet.awardInfo?.standstillPaused === false && isPast(parseISO(dataSet.awardInfo.standstillEndDate)) === false && !readOnly:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            <StandstillTimer
                                timerEndDate={dataSet?.awardInfo?.standstillEndDate}
                            />
                            {rbac.can(rbac.action.updateStandstillTimer,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <Button
                                id={'pauseStandstill'}
                                label='Pause Standstill'
                                variant={'primary'}
                                // additionalVariant={'skinless'}
                                size={'small'}
                                handleClick={() => handleStandstill({ standstillPaused: true })}
                            />}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                        </div>
                    </div>
                </>;
            case dataSet.addIntentProcedure === true && !intentDocumentsRequired && !awardDocumentsRequired && response.intentStatus === intentStatuses.successful && !readOnly:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            { rbac.can(rbac.action.submitAwardLetterForApproval,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <Button
                                id={'submitForApproval'}
                                size={'small'}
                                label='Submit for Approval'
                                handleClick={() => handleStatusUpdate('submit')}
                            />}
                        </div>
                    </div>
                </>;
            case response.intentStatus === 'successful' && dataSet?.awardInfo?.intentApprovalAllResponses === intentLetterStatuses.approved && dataSet.addIntentProcedure === true && !dataSet?.awardInfo?.intentLetterSent && !readOnly:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            {<StatusLabel
                                id={'asHeaderLabel'}
                                color='amber'
                                labelTxt='Intent Letters Not Sent'
                            />}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            { rbac.can(rbac.action.sendIntentLetters,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <Button
                                id={'sendIntentLetters'}
                                label='Send Intent Letters'
                                size={'small'}
                                handleClick={() => handleSendIntentLetter()}
                            />}
                        </div>
                    </div>
                </>;
            case response.intentStatus === 'successful' && dataSet?.awardInfo?.intentApprovalAllResponses === intentLetterStatuses.stage2ApprovalPending && dataSet.addIntentProcedure === true && !readOnly:
                return <> {
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            {<StatusLabel
                                id={'asHeaderLabel'}
                                color='amber'
                                labelTxt='Stage 2 Approval Pending'
                            />}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            {rbac.can(rbac.action.approveStage2,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <> <Button
                                id={'rejectAll'}
                                label='Reject All'
                                variant={'primary'}
                                size={'small'}
                                additionalVariant={'red'}
                                handleClick={() => handleStatusUpdate('rejected', 'intentletter')}
                            />
                            <Button
                                id={'approveAll'}
                                label='Approve All'
                                size={'small'}
                                handleClick={() => handleStatusUpdate('s2approved', 'intentletter')}
                            /></>}
                        </div>
                    </div>}
                </>;
            case response.intentStatus === 'successful' && dataSet?.awardInfo?.intentApprovalAllResponses === intentLetterStatuses.stage1ApprovalPending && dataSet.addIntentProcedure === true && !readOnly:
                return <> {
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            {<StatusLabel
                                id={'asHeaderLabel'}
                                color='amber'
                                labelTxt='Stage 1 Approval Pending'
                            />}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            {rbac.can(rbac.action.approveStage1,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <> <Button
                                id={'rejectAll'}
                                label='Reject All'
                                variant={'primary'}
                                size={'small'}
                                additionalVariant={'red'}
                                handleClick={() => handleStatusUpdate('rejected', 'intentletter')}
                            />
                            <Button
                                id={'approveAll'}
                                label='Approve All'
                                size={'small'}
                                handleClick={() => handleStatusUpdate('s1approved', 'intentletter')}
                            /></>}
                        </div>
                    </div>}
                </>;
            case dataSet.addIntentProcedure === true && !intentDocumentsRequired && response.intentStatus === intentStatuses.successful && !dataSet?.awardInfo?.intentLetterSent && !readOnly:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            {rbac.can(
                                rbac.action.submitIntentLetterForApproval,
                                rbac.util.getSteeringGroupRole(
                                    authContext.user.id,
                                    projectDetails?.steeringInfo,
                                ),
                            ) && <Button
                                id={'submitForApproval'}
                                label='Submit for Approval'
                                size={'small'}
                                handleClick={() => handleStatusUpdate('submit')}
                            />}
                        </div>
                    </div>
                </>;
            case dataSet.addIntentProcedure === false && !awardDocumentsRequired && response.intentStatus === intentStatuses.successful && !readOnly:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                            {rbac.can(rbac.action.submitAwardLetterForApproval,
                                rbac.util.getSteeringGroupRole(authContext.user.id,
                                    projectDetails?.steeringInfo)) && <Button
                                id={'submitForApproval'}
                                label='Submit for Approval'
                                size={'small'}
                                handleClick={() => handleStatusUpdate('submit')}
                            />}
                        </div>
                    </div>
                </>;
            default:
                return <>
                    <div className='sbContentHeader'>
                        <div className='headerButtonContainer'>
                            {dataSet.awardInfo?.standstillEndDate && isPast(parseISO(dataSet.awardInfo?.standstillEndDate))
                                && <p className='body bold'>Standstill Timer : <span className='body bold' id='addPadding'>Completed </span> </p>}
                            <Button
                                id={'back'}
                                size={'small'}
                                label='Back'
                                variant={'normal-link'}
                                handleClick={() => history.goBack()}
                            />
                        </div>
                    </div>
                </>;
        }
    };

    const generateActionsLabel = (data, idx) => {
        const incompleteIntent = [];
        data.responses.forEach((response) => {
            if (response.intentStatus === intentStatuses.pending || !response.intentStatus) {
                incompleteIntent.push(response);
            }
        });

        if (isDPS === true && publishLevel === 'lot' && data?.awardApproval === approvalStatuses.stage1ApprovalPending) {
            return <>{rbac.can(rbac.action.approveStage1, userSteeringRole) ? <StatusLabel
                id={idx}
                color='red'
                labelTxt='Action Needed'
            />
                : <StatusLabel
                    id={idx}
                    color='amber'
                    labelTxt='Stage 1 Approval Pending'/>
            }</>;
        }
        if (isDPS === true && publishLevel === 'lot' && data?.awardApproval === approvalStatuses.stage2ApprovalPending) {
            return <>{rbac.can(rbac.action.approveStage2, userSteeringRole) ? <StatusLabel
                id={idx}
                color='red'
                labelTxt='Action Needed'
            />
                : <StatusLabel
                    id={idx}
                    color='amber'
                    labelTxt='Stage 2 Approval Pending'/>
            }</>;
        }

        switch (true) {
            case (isDPS === true && publishLevel === 'lot' && data?.awardApproval === approvalStatuses.pending):
            case (isDPS === true && publishLevel === 'lot' && data?.awardApproval === approvalStatuses.approved && !data?.awardLetterSent):
            case (isDPS === true && publishLevel === 'lot' && (data?.awardApproval === approvalStatuses.notStarted || !data.awardApproval)):
            case (dataSet.addIntentProcedure === true && (incompleteIntent.length > 0 || data?.docs?.length < 1)):
            case (dataSet.addIntentProcedure === false && (incompleteIntent.length > 0 || data?.docs.length < 1)):
            case (dataSet?.awardInfo?.intentLetterSent === true && data?.docs?.length < 2):
                return <StatusLabel
                    id={idx}
                    color='red'
                    labelTxt='Action Needed'
                />;
            default: return <></>;
        }
    };

    const generateLotAccordionData = (data, idx) => ([{
        sectionId: data.accountID,
        header: <><p className='headerTitle title award-lot-supplier'><img src={userIcon} id='userIcon' alt='user'/>{data?.account?.companyName}</p>{!readOnly && generateActionsLabel(data, idx)}</>,
        content: <>
            {data.responses?.map((supplierItem, idx0) => (
                <SupplierItem
                    key={`supplier-item-${idx0}`}
                    id={idx0}
                    accountId={data.accountID}
                    item={supplierItem}
                    publishLevel={publishLevel}
                    awardInfo={dataSet.awardInfo}
                    addIntentProcedure={dataSet.addIntentProcedure}
                    handleUpdateIntentStatus={handleUpdateIntentStatus}
                    handleStatusUpdate={handleStatusUpdate}
                    uploadIntentLetter={uploadIntentLetter}
                    deleteIntentLetter={deleteIntentLetter}
                    isLotLevel={true}
                    isDPS={isDPS}
                    userSteeringRole={userSteeringRole}
                    handleAwardModal={handleAwardModal}
                    readOnly={readOnly}
                    openNodes = {getOpenNodes(localStorageProperty)}
                    handleSaveOpenNodesInLocalStorage={(elementId,
                        status) => updateAndSaveOpenNodesInLocalStorage(localStorageProperty, elementId, status)}
                />
            ))}

            <SupplierDataUpload
                dataSet = {dataSet}
                data = {data}
                userSteeringInfo = {projectDetails?.steeringInfo}
                isDPS = {isDPS}
                readOnly = {readOnly}
                uploadIntentLetter = {uploadIntentLetter}
                handleStatusUpdate = {handleStatusUpdate}
                handleAwardModal = {handleAwardModal}
                deleteIntentLetter = {deleteIntentLetter}
                publishLevel = {publishLevel}
                projectRoute = {projectDetails?.route}
                isAwardLetterSent = {projectDetails?.awardLetterSent}
            />
        </>,

    }]);

    const generateLotAccordion = () => <section className='sbContentSection' key={'section-lot'}>
        <div className='sbContentBody'> {dataSet?.responses?.map((data, idx) => <Accordion id='sbAccordion'
            key={`lot-accordion-${ data.accountID}`}
            data={generateLotAccordionData(data, idx)}
            open= {getOpenNodes(localStorageProperty)?.includes(data.accountID)}
            handleToogleAccordion={(elementId,
                status) => updateAndSaveOpenNodesInLocalStorage(localStorageProperty, elementId, status)}
        />)}
        </div>
    </section>;


    const generateProjectAccordion = () => (dataSet.responses.map((supplierItem, idx) => <SupplierItem
        key={`supplier-item-${idx}`}
        id={idx}
        accountId={supplierItem.accountID}
        item={supplierItem}
        publishLevel={publishLevel}
        awardInfo={dataSet.awardInfo}
        addIntentProcedure={dataSet.addIntentProcedure}
        handleUpdateIntentStatus={handleUpdateIntentStatus}
        handleStatusUpdate={handleStatusUpdate}
        uploadIntentLetter={uploadIntentLetter}
        deleteIntentLetter={deleteIntentLetter}
        isLotLevel={false}
        isDPS={isDPS}
        userSteeringRole={userSteeringRole}
        handleAwardModal={handleAwardModal}
        readOnly={readOnly}
        openNodes = {getOpenNodes(localStorageProperty)}
        handleSaveOpenNodesInLocalStorage={(elementId,
            status) => updateAndSaveOpenNodesInLocalStorage(localStorageProperty, elementId, status)}
        isAwardLetterSent = {projectDetails?.awardLetterSent}
        projectRoute = {projectDetails?.route}
    />));

    const isPublishRoundFirstorNotApplicable = (round) => !round || [publishRound.first, publishRound.notapplicable].includes(round);

    const handleClickViewContract = () => {
        if (supplierAccountId && firstLotId) {
            return history.push(`/contracts/management/supplier/${projectDetails.contractID}/${supplierAccountId}/${firstLotId}`);
        }
        return history.push(`/contracts/management/${projectDetails.contractID}`);
    };

    return <>

        {isProjectDPS && publishLevel === publishAtLevel.lot
            ? <SummaryBuyerAwardDPS
                projectDetails={projectDetails}
                awardSummary={dataSet}
                readOnly={readOnly}
                userSteeringRole = {userSteeringRole}
                addIntentProcedure={dataSet.addIntentProcedure}
                handleUpdateIntentStatus={handleUpdateIntentStatus}
                handleStatusUpdate={handleStatusUpdate}
                uploadIntentLetter={uploadIntentLetter}
                deleteIntentLetter={deleteIntentLetter}
                handleAwardModal={handleAwardModal}
                localStorageProperty = {localStorageProperty}
                handleSaveOpenNodesInLocalStorage={(elementId,
                    status) => updateAndSaveOpenNodesInLocalStorage(localStorageProperty, elementId, status)}
            />
            : <>
                {isDPS ? generateDSPSolutionHeaderButtons() : generateAwardHeaderButtons() }
                {publishLevel === publishAtLevel.lot
                    ? generateLotAccordion()
                    : generateProjectAccordion()}
            </>
        }
        {showStandstillModal
        && <StandstillModal
            onCancel={() => setShowStandstillModal(false)}
            onConfirm={(data) => {
                if (dataSet.awardInfo?.standstillPaused) { handleStandstill(data); } else { handleIntentLetters(data); }
                setShowStandstillModal(false);
            }}
            endDateString={dataSet?.awardInfo?.standstillEndDate}
            label={standStillLabel}
        />}
        {draftContractModal
        && <Modal
            open={true}
            closeModal={() => setDraftContractModal(false)}
            size='small'
            headerTitle='Are you sure?'
            handleMainActionBtnClick={() => history.push(`/projects/overview/contract/create/${projectDetails.projectID}`)}
            mainActionButtonTxt='Draft Contract'
            closeButton={false}
            body={<div>
                {isPublishRoundFirstorNotApplicable(dataSet?.publishRound) && <p className='body'>{sendAwardLetterModalMessage.draftContract}</p>}
                <p className='body'>{sendAwardLetterModalMessage.pleaseClick}</p>
            </div>}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='Cancel'
            handleSecondaryActionBtnClick={() => setDraftContractModal(false)}
        />}
        {sendAwardModal
        && <Modal
            id={'sendAwardModal'}
            open={true}
            closeModal={() => {
                setSendAwardModal(false);
                setSupplierAccountId('');
                setFirstLotId(undefined);
            }}
            size='small'
            headerTitle='Are you sure?'
            handleMainActionBtnClick={() => {
                handleSendAwards(supplierAccountId, () => {
                    setSupplierAccountId('');
                    setFirstLotId(undefined);
                    setSendAwardModal(false);
                });
            }}
            mainActionButtonTxt='Confirm'
            closeButton={false}
            body={<div>
                <p className='body'>{sendAwardLetterModalMessage.areYouSure}</p>
                <p className='body'>{sendAwardLetterModalMessage.publicRecord}</p>
                <p className='body'>{sendAwardLetterModalMessage.reviewAwardLetter}</p>
                <Button
                    id={'viewContract'}
                    label='View Contract'
                    variant={'primary'}
                    size={'small'}
                    handleClick={handleClickViewContract}
                />
            </div>}
            helpOption={false}
            secondActionButton={true}
            secondActionButtonTxt='Cancel'
            handleSecondaryActionBtnClick={() => {
                setSendAwardModal(false);
                setSupplierAccountId('');
            }}
        />}
    </>;
};

SummaryBuyerAward.propTypes = {
    projectDetails: PropTypes.object.isRequired,
    dataSet: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    handleStatusUpdate: PropTypes.func.isRequired,
    handleSendAwards: PropTypes.func.isRequired,
    handleIntentLetters: PropTypes.func.isRequired,
    handleStandstill: PropTypes.func.isRequired,
    handleUpdateIntentStatus: PropTypes.func.isRequired,
    uploadIntentLetter: PropTypes.func,
    deleteIntentLetter: PropTypes.func,
    intentDocumentsRequired: PropTypes.bool.isRequired,
    awardDocumentsRequired: PropTypes.bool.isRequired,
    isDPS: PropTypes.bool.isRequired,
    publishLevel: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    isProjectDPS: PropTypes.bool.isRequired,
    eventId: PropTypes.string,
};

export default SummaryBuyerAward;
