import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isPast, parse, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Upload from '../../../../../../features/Upload';
import Button from '../../../../../../components/Button';
import rightArrowHead from '../../../../../../styles/icons/black/right-arrow.svg';
import StatusLabel from '../../../../../../components/StatusLabel';
import {
    allSteeringRoles, eventStatuses, eventStatusesLabel, workflowEventTypes, workflowEvents,
} from '../../../../../../config/constants';
import WorknotesModal from '../../../../../../features/WorknotesModal';
import { eventButtons, EventConstants } from './constants';
import Form from '../../../../../../components/Form';
import Modal from '../../../../../../components/Modal';
import LabelledTextarea from '../../../../../../components/LabelledTextArea';
import Confirm from '../../../../../../components/Alerts/Confirm/Confirm';
import Constants from '../../../../../../features/DocumentBuilder/components/EventModal/constants';
import AuthContext from '../../../../../../context/AuthContext';
import { fileShouldBeDisplayedToUser } from '../../../../../../utils/uploadHelper';
import { ThemeContext } from '../../../../../../context/ThemeContext';

const EventSection = ({
    event,
    projectId,
    contractId,
    handleUpdateEvent,
    handleEscalateEvent,
    handleUploadDocument,
    handleDeleteDocument,
    handleAddWorkNotes,
    handleExtentContract,
    handleCreateVariation,
    updateCompleteEventContract,
    awardedSupplierData = [],
    readOnly,
    userSteeringRoles = [],
}) => {
    const history = useHistory();
    const [expand, setExpand] = useState(false);
    const [showViewWorkNotes, setShowViewWorkNotes] = useState(false);
    const [showAddWorkNotes, setShowAddWorkNotes] = useState(false);
    const [escalateModal, setEscalateModal] = useState(false);
    const [showVariation, setShowVariation] = useState(false);
    const [escalationReason, setEscalationReason] = useState('');
    const [variationReason, setVariationReason] = useState('');
    const authContext = useContext(AuthContext);
    const { toggle } = useContext(ThemeContext);

    const getPrettyEventType = (eType) => workflowEventTypes.filter(
        (eventType) => eventType.value === eType,
    )[0]?.label;

    const getPrettyPublishType = (type) => Constants.publishTypes
        .filter((publishType) => (publishType?.value === type))[0]?.label;

    const getPrettySteeringGroupRole = (roleId) => allSteeringRoles
        .filter((steeringRole) => (steeringRole.role === roleId))[0]?.name;

    const getPrettyEventStatus = () => {
        const finalStatus = (event
            .eventType === EventConstants.PUBLISH && isPast(parseISO(event?.submissionEndDate)))
            ? EventConstants.CLOSED
            : event.eventStatus;
        return eventStatusesLabel.find(
            (eventStatus) => eventStatus.value === finalStatus,
        );
    };

    const getEventCardStatus = () => {
        if (!toggle) {
            return (`${isPast(parse(event.endDate, 'dd-MM-yyyy', new Date()))
                && event.eventStatus !== eventStatuses.completed ? EventConstants.WARNING : EventConstants.SUCCESS}`);
        }
        return getPrettyEventStatus()?.value;
    };

    const handleUpdateEventStatus = (eventId, action, confirmAction) => {
        Confirm(handleUpdateEvent, {
            subTitle: `Are you sure you want to ${confirmAction} the workflow event - ${event.eventName}`,
        }, {
            eventId,
            action,
        });
    };

    const handleCompleteEventStatus = (eventId, action) => {
        Confirm(updateCompleteEventContract, {
            subTitle: `Are you sure you want to complete the workflow event - ${event.eventName}`,
        }, {
            eventId,
            action,
        });
    };
    const handleCloseEscalateEvent = () => {
        setEscalateModal(false);
        setEscalationReason('');
    };
    const handleCloseWorknote = () => {
        setShowAddWorkNotes(false);
        setShowViewWorkNotes(false);
    };
    const handleCloseVariationEventModal = () => {
        setShowVariation(false);
        setVariationReason('');
    };
    const handleRenewContract = () => {
        history.push(`/project/renewal/${projectId}/${contractId}`);
    };

    const generateExpandableSection = () => {
        const relevantDocuments = event.additionalDocuments
            ? event.additionalDocuments.filter((file) => fileShouldBeDisplayedToUser(
                file.document, authContext.user,
            ))
            : [];
        return (<div className='event-section-content'>
            <p className={`body-small ${relevantDocuments?.length !== 0
                ? 'success' : 'warning'}`}>
                {relevantDocuments.length} Documents
            </p>
            <p className={`body-small ${event.worknotes?.length === 0
                ? 'warning' : 'success'}`}>
                {event.worknotes
                    ? event.worknotes.length
                    : 0} Worknotes
            </p>
        </div>);
    };

    const generateCollapsableSection = () => (<div className='component-container'>
        <Upload
            title={'Documents'}
            allowMultiple={true}
            uploadIdentifier={'document'}
            uploadCallback={(data) => handleUploadDocument(data, event.eventID)}
            deleteCallback={(data) => handleDeleteDocument(event.eventID, data.documentID)}
            uploadedDocuments={event.additionalDocuments}
            readOnly={readOnly}
        />
        {authContext?.user?.accountType !== 'supplier'
            && <div className='worknotes-container'>
                <p className='title sectionLabel' id='worknotes'> Worknotes</p>
                <p className='caption'> {event?.worknotes
                    ? event?.worknotes?.length
                    : 0} worknotes added</p>
                <div className='button-container'>
                    <Button id='viewWorknoteBtn'
                        size='small'
                        variant='secondary'
                        label='View'
                        handleClick={() => setShowViewWorkNotes(true)} />
                    {!readOnly
                        && <Button id='addWorknoteBtn'
                            size='small'
                            variant='primary'
                            label='Add'
                            handleClick={() => setShowAddWorkNotes(true)} />
                    }
                </div>
            </div>}
    </div>);

    const getResponsibleSuppliers = (supplierIds) => {
        const suppliers = [];
        const awardedSupplier = awardedSupplierData.flatMap((supObj) => supObj.suppliers || supObj);
        supplierIds.map((Id) => (
            suppliers.push(awardedSupplier.filter(
                (sup) => sup.accountID === Id,
            )[0]?.account?.companyName)
        ));
        return suppliers.join(', ');
    };
    return (
        <><div className={`event-section-container ${toggle ? 'new-event-section-container' : ''}`}>
            <div key={event.eventID} className={`event-section-card ${getEventCardStatus()}`}>
                <div className='event-section-header'>
                    <div className='event-section-header-content'>
                        <p className='title mr-1'>{event.eventName}</p>
                        {isPast(parse(event.endDate, 'dd-MM-yyyy', new Date()) && event.status)
                            && <StatusLabel id={`event-${event.eventID}-status`}
                                labelTxt={event.eventStatus !== eventStatuses.completed ? 'Overdue' : 'Complete'}
                                color={event.eventStatus !== eventStatuses.completed ? 'amber' : 'green'}
                            />}
                    </div>
                    <button type='button' className='event-section-header-button' id={`event-section-${event.eventID}-header`}
                        onClick={() => setExpand(!expand)}>
                        <span>{ !expand ? EventConstants.EXPAND : EventConstants.COLLAPSE}</span>
                        <img src={rightArrowHead} className={`event-section-header-icon
                            ${!expand ? '' : 'rotate'}`} alt='expand-collapse'>
                        </img>
                    </button>
                </div>
                <div className='event-section-content'>
                    <p className='caption'>{getPrettyEventType(event.eventType)}</p>
                    {expand && <p className='body label'>{event.description}</p>}
                    <div className='workflowContent'>

                        {event.eventType !== workflowEvents.supplierDocumentation
                            ? <div className='workflowRow'><p className=' label'>{EventConstants.ROLE_RESPONSIBLE} </p>
                                {event.rolesResponsible
                                    ? <p className='value'>{getPrettySteeringGroupRole(event.rolesResponsible)}</p>
                                    : <p className='value'>{EventConstants.NO_ROLE_SELECTED}</p>}
                            </div>
                            : <>{authContext?.user?.accountType !== 'supplier'
                                && <div className='workflowRow'><p className='label'>Supplier Responsible: </p>
                                    {event.suppliersResponsibleForEvent.length > 0
                                        ? <p className='value'>{getResponsibleSuppliers(event.suppliersResponsibleForEvent)}</p>
                                        : <p className='value'>No Supplier Selected</p>}
                                </div>
                            }</>
                        }
                        <div className='workflowRow'>
                            <p className='label'>{EventConstants.END_DATE}</p>
                            <p className='value'>{event.endDate}</p>
                        </div>
                        {workflowEvents.publish === event.eventType
                         && <div className='workflowRow'>
                             <p className='label'>{EventConstants.PUBLISH_LEVEL}</p>
                             <p className='value'>{event.publishProject ? EventConstants.PROJECT : EventConstants.LOT}</p>
                         </div>}
                        {workflowEvents.publish === event.eventType
                        && <div className='workflowRow'>
                            <p className='label'>{EventConstants.PUBLISH_TYPE} </p>
                            <p className='value'>{getPrettyPublishType(event.publishType)}</p>
                        </div>
                        }
                    </div>
                    <StatusLabel id={`event-${event.eventID}-event-status`}
                        labelTxt={getPrettyEventStatus()?.label}
                        color= {toggle ? getPrettyEventStatus()?.value : EventConstants.GREEN}
                    />
                </div>
                <div className='event-section-button'>
                    {!readOnly
                        && eventButtons(
                            event,
                            setEscalateModal,
                            setShowVariation,
                            handleUpdateEventStatus,
                            handleExtentContract,
                            handleRenewContract,
                            handleCompleteEventStatus,
                            userSteeringRoles,
                        )}
                </div>
                {expand ? (
                    <div className='event-section-subsection'>
                        {generateCollapsableSection()}
                    </div>
                ) : (
                    <div className='event-section-subsection'>
                        {generateExpandableSection()}
                    </div>
                )}
            </div>
        </div>
        {showVariation
                && <Form onSubmit={(e) => {
                    e.preventDefault();
                    handleCreateVariation(variationReason,
                        handleCloseVariationEventModal);
                }}>
                    <Modal
                        id='variationContract'
                        open={true}
                        size='medium'
                        headerTitle='Create Variation'
                        body={
                            <LabelledTextarea
                                id='variation'
                                label={'Create variation for contract'}
                                breakColumn={true}
                                onChange={(e) => setVariationReason(e.target.value)}
                                value={variationReason || ''}
                                placeholder='Enter reason'
                                required={true}
                            />}
                        footer={true}
                        mainActionButtonTxt='Create'
                        secondActionButtonTxt='Cancel'
                        secondActionButton={true}
                        handleMainActionBtnClick={() => null}
                        handleSecondaryActionBtnClick={handleCloseVariationEventModal}
                        helpOption={false}
                        closeModal={handleCloseVariationEventModal}
                    />
                </Form>
        }
        {escalateModal
                && <Form
                    id={'escalateForm'}
                    className={'escalateForm'}
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleEscalateEvent(
                            escalationReason,
                            event.eventID,
                            handleCloseEscalateEvent,
                        );
                    }}>
                    <Modal
                        id='escalateEventModal'
                        open={true}
                        size='medium'
                        headerTitle='Escalation Reason'
                        body={<LabelledTextarea
                            id='reason'
                            label='Enter your reason for raising an escalation for this workflow event.'
                            breakColumn={true}
                            onChange={(e) => setEscalationReason(e.target.value)}
                            value={escalationReason || ''}
                            placeholder='Enter reason'
                            required={true}
                        />}
                        footer={true}
                        mainActionButtonTxt='Submit'
                        secondActionButtonTxt='Cancel'
                        secondActionButton={true}
                        handleMainActionBtnClick={() => null}
                        handleSecondaryActionBtnClick={handleCloseEscalateEvent}
                        helpOption={false}
                        closeModal={handleCloseEscalateEvent}
                    />
                </Form>
        }
        {showViewWorkNotes
                && <WorknotesModal
                    closeModal={handleCloseWorknote}
                    mode={'view'}
                    worknotesInput={event && event.worknotes}
                    handleCallback={(data, worknote) => handleAddWorkNotes(
                        event.eventID, worknote, handleCloseWorknote,
                    )}
                />}
        {showAddWorkNotes
                && <WorknotesModal
                    closeModal={handleCloseWorknote}
                    mode={'add'}
                    worknotesInput={event && event.worknotes}
                    handleCallback={(data, worknote) => handleAddWorkNotes(
                        event.eventID, worknote, handleCloseWorknote,
                    )}
                />}
        </>
    );
};

EventSection.propTypes = {
    event: PropTypes.object.isRequired,
    projectId: PropTypes.string,
    contractId: PropTypes.string,
    handleUpdateEvent: PropTypes.func,
    handleEscalateEvent: PropTypes.func,
    handleUploadDocument: PropTypes.func,
    handleDeleteDocument: PropTypes.func,
    handleAddWorkNotes: PropTypes.func,
    handleExtentContract: PropTypes.func,
    handleCreateVariation: PropTypes.func,
    updateCompleteEventContract: PropTypes.func,
    awardedSupplierData: PropTypes.array,
    readOnly: PropTypes.bool,
    userSteeringRoles: PropTypes.array,
};

export default EventSection;
