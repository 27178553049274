import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { parseISO, isPast } from 'date-fns';
import { saveAs } from 'file-saver';
import GuidanceBanner from '../../components/GuidanceBanner';
import Button from '../../components/Button';
import StatusLabel from '../../components/StatusLabel';
import Accordion from '../../features/Accordion';
import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import shareIcon from '../../styles/images/share-icon.png';
import SeeMoreSeeLess from '../../components/SeeMoreSeeLess';
import { responseStatus } from '../../config/constants';
import gatewayOrchestratorAPIs from '../../services/gateway.service';
import constants from './constants';
import AnswerSummaryModal from '../../components/AnswerSummaryModal';
import { getResponseStatusColor, getResponseStatusLabel } from '../../services/response.service';
import useGetOpportunity from '../../hooks/useGetOpportunity';

const AnswerSummary = () => {
    const history = useHistory();
    const [data, setData] = useState('');
    const [selectLotsModal, setSelectLotsModal] = useState(false);
    const [selectedLots, setSelectedLots] = useState([]);
    const [questionnaireAwaitingRefresh, setQuestionnaireAwaitingRefresh] = useState([]);
    const { projectId, eventId } = useParams();
    const { getOpportunity } = useGetOpportunity();

    const getAnswerSummaryResponse = async () => {
        try {
            const response = await projectManagementAPIs.getAnswerSummary(projectId, eventId);
            if (response.status === 200) {
                setData(response.data);
                if (response.data.publishLevel === 'lot' && response.data.lotSelected) {
                    setSelectedLots(response.data.responses?.map((lot) => lot.lotID));
                }
                const quesAwaitingRefresh = [];
                if (response.data.publishLevel === 'lot') {
                    response.data.responses.map((lot) => {
                        lot.responses.map((res) => {
                            if (res.requiresUpdate) {
                                quesAwaitingRefresh.push({
                                    ...res,
                                    lotTitle: `${lot.lotTitle}: `,
                                });
                            }
                            return null;
                        });
                        return null;
                    });
                    setQuestionnaireAwaitingRefresh(quesAwaitingRefresh);
                }
                if (response.data.publishLevel === 'project') {
                    response.data.responses.map((res) => {
                        if (res.requiresUpdate) {
                            quesAwaitingRefresh.push({
                                ...res,
                                lotTitle: '',
                            });
                        }
                        return null;
                    });
                    setQuestionnaireAwaitingRefresh(quesAwaitingRefresh);
                }
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: constants.retrieveInfoError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: constants.retrieveInfoError,
            });
        }
    };

    useEffect(() => {
        getAnswerSummaryResponse();
        getOpportunity(projectId);
    }, []);

    const handleLotSubmit = async (lotList) => {
        try {
            const response = await projectManagementAPIs.assignLotsToOpportunity(projectId,
                eventId, {
                    lots: lotList,
                });
            if (response.status === 200) {
                getAnswerSummaryResponse(projectId, eventId);
                Toast.fire({
                    icon: 'success',
                    titleText: constants.lotSubmitSuccess,
                });
                setSelectLotsModal(false);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: constants.lotSelectError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: constants.lotSelectError,
            });
        }
    };

    const handleSubmitAll = async () => {
        try {
            const response = await projectManagementAPIs.submitResponses(projectId, eventId, {
                action: 'submit',
            });
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: constants.answerSubmitSuccess,
                });
                getAnswerSummaryResponse(projectId, eventId);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: constants.answerSubmitError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: constants.lotSelectError,
            });
        }
    };

    const handleWithdrawAll = async () => {
        try {
            const response = await projectManagementAPIs.submitResponses(projectId, eventId, {
                action: 'withdraw',
            });
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: constants.answerWithdrawSuccess,
                });
                getAnswerSummaryResponse(projectId, eventId);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: constants.answerWithdrawError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: constants.lotSelectError,
            });
        }
    };

    const refreshAnswers = async () => {
        try {
            const response = await projectManagementAPIs.refreshAnswers(projectId, eventId);
            if (response.status === 200) {
                getAnswerSummaryResponse();
                Toast.fire({
                    icon: 'success',
                    titleText: constants.answerRefreshSuccess,
                });
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: constants.answerRefreshError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: constants.lotSelectError,
            });
        }
    };

    const verifyAction = () => {
        const isSubmissionDeadline = isPast(parseISO(data.submissionEndDate));
        let responseArray;
        if (data?.publishLevel === 'project') {
            responseArray = [
                ...new Set(data?.responses?.map((response) => response.responseStatus))];
        } else {
            const tempArray = [];
            data?.responses?.forEach((lot) => {
                const singleLotArray = [
                    ...new Set(lot?.responses?.map((response) => response.responseStatus))];
                tempArray.push(...singleLotArray);
            });
            responseArray = [...new Set(tempArray)];
        }
        switch (true) {
            case (questionnaireAwaitingRefresh.length > 0 && !isSubmissionDeadline): return 'Action Needed';
            case (isSubmissionDeadline && !responseArray.includes(responseStatus.bidSubmitted)): return 'Response Not Submitted';
            case (responseArray.includes(responseStatus.bidSubmitted)):
                return constants.responseSubmitted;
            case (responseArray.length === 1 && responseArray.includes('submitted')): return constants.readyToSubmit;
            case (responseArray.includes('inProgress') || responseArray.includes('notStarted')): return 'Action Needed';
            default: return '';
        }
    };

    const getBannerText = (status, deadline) => {
        switch (true) {
            case deadline: return constants.viewResponse;
            case status === constants.readyToSubmit: return constants.readyToSubmitText;
            case status === constants.responseSubmitted: return constants.responseSubmittedText;
            default: return 'Submit Answer(s)';
        }
    };

    const getBannerColour = (status, deadline) => {
        switch (true) {
            case deadline: return null;
            case status === constants.readyToSubmit: return constants.red;
            case status === constants.responseSubmitted: return constants.green;
            default: return null;
        }
    };

    const generateBanner = () => {
        const isSubmissionDeadline = isPast(parseISO(data.submissionEndDate));
        const submissionStatus = verifyAction();
        switch (true) {
            case data.publishLevel === 'project': {
                return <GuidanceBanner
                    id={'submitAnswerGuidance'}
                    type={getBannerColour(submissionStatus, isSubmissionDeadline)}
                    headerText={getBannerText(submissionStatus, isSubmissionDeadline)}
                    timerTextType={!isSubmissionDeadline ? 'flat' : ''}
                    timerEndDate={!isSubmissionDeadline ? data.submissionEndDate : null}
                    bannerText1={isSubmissionDeadline
                        && 'The deadline for submission has passed.'
                    }
                    buttonArr={[{
                        id: 'btn-WithdrawAll', label: 'Withdraw All', size: 'medium', variant: 'primary', additionalVariant: constants.red, action: handleWithdrawAll, allowAction: !isSubmissionDeadline, disabled: (isSubmissionDeadline || data.bidStatus !== responseStatus.bidSubmitted || data.projectStatus === responseStatus.cancelled),
                    }, {
                        id: 'btn-SubmitAll', label: 'Submit All', size: 'medium', variant: 'primary', action: handleSubmitAll, allowAction: !isSubmissionDeadline, disabled: (isSubmissionDeadline || data.bidStatus === responseStatus.bidSubmitted || data.projectStatus === responseStatus.cancelled),
                    }]}
                />;
            }
            case data.publishLevel === 'lot' && !data.lotSelected:
                return <GuidanceBanner
                    id={'defaultGuidance'}
                    headerText={getBannerText(submissionStatus, isSubmissionDeadline)}
                    bannerText1={isSubmissionDeadline
                        && 'The deadline for submission has passed.'
                    }
                />;

            case data.publishLevel === 'lot' && data.lotSelected: {
                return <GuidanceBanner
                    id={'submitAnswerGuidance'}
                    type={getBannerColour(submissionStatus, isSubmissionDeadline)}
                    headerText={getBannerText(submissionStatus, isSubmissionDeadline)}
                    timerTextType={!isSubmissionDeadline ? 'flat' : ''}
                    timerEndDate={!isSubmissionDeadline ? data.submissionEndDate : null}
                    bannerText1={isSubmissionDeadline
                        && 'The deadline for submission has passed.'
                    }
                    buttonArr={[{
                        id: 'btn-WithdrawAll', label: 'Withdraw All', size: 'medium', variant: 'primary', additionalVariant: constants.red, action: handleWithdrawAll, allowAction: !isSubmissionDeadline, disabled: (isSubmissionDeadline || data.bidStatus !== responseStatus.bidSubmitted || data.projectStatus === responseStatus.cancelled),
                    }, {
                        id: 'btn-SubmitAll', label: 'Submit All', size: 'medium', variant: 'primary', action: handleSubmitAll, allowAction: !isSubmissionDeadline, disabled: (isSubmissionDeadline || data.bidStatus === responseStatus.bidSubmitted || data.projectStatus === responseStatus.cancelled),
                    }]}
                />;
            }
            case data.length === 0: return <></>;
            default:
                return <GuidanceBanner
                    id={'defaultGuidance'}
                    type={getBannerColour(submissionStatus, isSubmissionDeadline)}
                    headerText={getBannerText(submissionStatus, isSubmissionDeadline)}
                    bannerText1={isSubmissionDeadline
                        && 'The deadline for submission has passed.'
                    }
                    bannerText2={!isSubmissionDeadline
                        && <>Find out more about what you need to know before submitting a bid</>
                    }
                />;
        }
    };

    const getListQuestionnairesToRefresh = () => (
        questionnaireAwaitingRefresh?.map((el) => <>
            <li>{el.lotTitle} {el.templateName}</li>
            <li style={{ fontStyle: 'italic' }}>{constants.amendmentReason}{el.versionReason}</li>
        </>)
    );

    const getTextQuestionnaires = () => (
        <>
            <p>{constants.refreshBannerText2}<b>{constants.refreshBannerText2b}</b></p>
            <br />
            <p>{constants.refreshBannerText3}</p>
            <p>
                {constants.refreshBannerText4}
                <b>{constants.refreshBannerText4b}</b>
                {constants.refreshBannerText4c}
            </p>
            <br />
            <p>{constants.refreshBannerText5}</p>
            <ul>
                <SeeMoreSeeLess inputText={getListQuestionnairesToRefresh()}
                    inputTextCharacterLimit={0} />
            </ul>
        </>
    );

    const generateRefreshGuidance = () => (questionnaireAwaitingRefresh.length > 0 ? <div id='answerSummaryBanner'><GuidanceBanner
        id={'questionnaireRefreshGuidance'}
        type={'warning'}
        headerText={constants.refreshHeaderText}
        bannerText1={constants.refreshBannerText1}
        bannerText2={getTextQuestionnaires()}
        buttonArr={[{
            id: 'btn-WithdrawAll', label: constants.review, size: 'medium', variant: 'secondary', action: refreshAnswers, allowAction: true, disabled: (data.projectStatus === data.cancelled),
        }]}
    /> </div> : <></>);

    const exportFunction = async () => {
        const response = await gatewayOrchestratorAPIs.exportAnswerSummary(projectId);
        if (response.status === 200) {
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const filename = response.headers['content-disposition'].split('"')[1] || 'export.csv';
            try {
                saveAs(blob, filename);
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to download document.',
                });
            }
        } else {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to download document.',
            });
        }
    };

    const ExportButton = () => <Button
        id='primary-action-btn'
        type='button'
        size='small'
        variant='export-link'
        additionalVariant='additionalexportbtn'
        label='Export All'
        handleClick={exportFunction}
    />;

    const getViewEditLabel = (questionnaire) => (questionnaire
        .responseStatus === responseStatus.notStarted
        || isPast(parseISO(data.submissionEndDate))
        || data.bidStatus === responseStatus.bidSubmitted
        || data.projectStatus === responseStatus.cancelled
        ? constants.view : constants.edit);

    return <div id='answerSummaryContainer'>
        <div id='answerSummaryBanner'>
            {generateBanner()}
        </div>
        {data.publishLevel === 'project' && !data.lotSelected
            && <div className='answerSummaryContentContainer'>
                {!isPast(parseISO(data.submissionEndDate))
                    && generateRefreshGuidance()
                }
                <div className='answerSummaryContent'>
                    <div className='answerSummaryActionsContainer'>
                        <StatusLabel
                            id={'actionLabel'}
                            color={verifyAction() === constants.responseSubmitted
                                ? constants.green : constants.red}
                            labelTxt={verifyAction()}
                        />
                        {ExportButton()}
                    </div>
                </div>
                <div className='answerSummaryContent'>
                    <div className='answerSummaryContentSection'>
                        {data.responses.map((questionnaire, idx) => <div className='answerSummaryContentBody' key={questionnaire.responseID}>
                            <div className='answerSummaryQuestionTitle'><span className='title'>{questionnaire.templateName}</span></div>
                            <div className='answerSummaryBtnGroup'>
                                <StatusLabel
                                    id='questionStatus'
                                    color={getResponseStatusColor(questionnaire.responseStatus)}
                                    labelTxt={getResponseStatusLabel(questionnaire.responseStatus)}
                                />
                                <Button
                                    id={`primary-action-btn-${idx}`}
                                    type='button'
                                    size='small'
                                    variant={getViewEditLabel(questionnaire) === constants.view ? 'view-link' : 'edit-link'}
                                    additionalVariant='btnSize'
                                    disabled={questionnaireAwaitingRefresh.length > 0
                                    && !(questionnaire.responseStatus === responseStatus.notStarted
                                        || isPast(parseISO(data.submissionEndDate))
                                        || data.bidStatus === responseStatus.bidSubmitted
                                        || data.projectStatus === responseStatus.cancelled)}
                                    label={getViewEditLabel(questionnaire)}

                                    handleClick=
                                        {questionnaire.responseStatus === responseStatus.notStarted
                                        || isPast(parseISO(data.submissionEndDate))
                                        || data.bidStatus === responseStatus.bidSubmitted
                                        || data.projectStatus === responseStatus.cancelled
                                            ? () => history.push(`/questionnaires/answer/view/${questionnaire.responseID}`, {
                                                deadlinePassed: isPast(
                                                    parseISO(data.submissionEndDate),
                                                ) || data.bidStatus === responseStatus.bidSubmitted
                                                || data.projectStatus === responseStatus.cancelled,
                                            })
                                            : () => history.push(`/questionnaires/answer/${questionnaire.responseID}`)
                                        }
                                />
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>}
        {data.publishLevel === 'lot' && !data.lotSelected && data.responses.length === 0
            && <div id='answerSummaryNoLots'>
                <div className='title'>This opportunity contains different lots, please select the lot(s) in which you&apos;d like to respond.</div>
                <div id='lotButton'>
                    {!isPast(parseISO(data.submissionEndDate))
                        && data.projectStatus !== responseStatus.cancelled
                        && <Button
                            id='primary-action-btn'
                            type='button'
                            size='regular'
                            variant='primary'
                            label={constants.selectLots}
                            handleClick={() => setSelectLotsModal(true)}
                        />}
                </div>
            </div>
        }
        {data.publishLevel === 'lot' && data.lotSelected
            && <div className='answerSummaryContentContainer' >
                <div className='answerSummaryContent'>
                    <div className='answerSummaryActionsContainer lot'>
                        <StatusLabel
                            id={'actionLabel'}
                            color={verifyAction() === constants.responseSubmitted
                                ? constants.green : constants.red}
                            labelTxt={verifyAction()}
                        />
                        <div className='answerSummaryBtnContainer'>
                            {(!isPast(parseISO(data.submissionEndDate)) && data.bidStatus
                            !== responseStatus.bidSubmitted)
                                && data.projectStatus !== responseStatus.cancelled
                                && <>
                                    <div className='guidanceInfo'>
                                        <span className='title'>{constants.editLotsTitle}</span>
                                    </div>
                                </>}
                            <div className='answerSummaryAdditionalBtnContainer'>
                                {(!isPast(parseISO(data.submissionEndDate)) && data.bidStatus
                                !== responseStatus.bidSubmitted)
                                    && data.projectStatus !== responseStatus.cancelled
                                    && <><Button
                                        id='primary-action-btn'
                                        type='button'
                                        size='small'
                                        variant='primary'
                                        additionalVariant='additionalexportbtn'
                                        label={constants.editLotsLabel}
                                        handleClick={() => setSelectLotsModal(true)} />
                                    </>}
                                {ExportButton()}
                            </div>

                        </div>
                    </div>
                </div>
                {!isPast(parseISO(data.submissionEndDate))
                    && generateRefreshGuidance()
                }
                <Accordion
                    id='lotAccordion'
                    data={data.responses.sort((a, b) => a.lotOrderNo - b.lotOrderNo)
                        .map((lots, index) => ({
                            sectionId: `lot-${index}`,
                            header: <div className='answerSummaryQuestionTitle'>
                                <p className='title'>{`Lot ${lots.lotOrderNo} : ${lots.lotTitle}`}</p>
                            </div>,
                            content: <>
                                {lots.responses.map((question, idx) => <div id='accordionContentBody' key={`lotquestion${idx}`}>
                                    <div className='answerSummaryQuestionTitle'>
                                        <p className='title'>{question.sharedQuestion && <img src={shareIcon} alt={'shared'} id='share-icon'></img>}{question.templateName}</p>
                                    </div>
                                    <div className='answerSummaryBtnGroup'>
                                        <StatusLabel
                                            id={`questionStatus-${idx}`}
                                            color={getResponseStatusColor(
                                                question.responseStatus,
                                                question.requiresUpdate,
                                                question.awaitingSupplierUpdate,
                                            )}
                                            labelTxt={getResponseStatusLabel(
                                                question.responseStatus,
                                                question.requiresUpdate,
                                                question.awaitingSupplierUpdate,
                                            )}
                                        />
                                        <Button
                                            id={`primary-action-btn-${idx}`}
                                            type='button'
                                            size='small'
                                            variant={getViewEditLabel(question) === constants.view ? 'view-link' : 'edit-link'}
                                            disabled={questionnaireAwaitingRefresh.length > 0
                                        && !(question.responseStatus === responseStatus.notStarted
                                        || isPast(parseISO(data.submissionEndDate))
                                        || data.bidStatus === responseStatus.bidSubmitted
                                        || data.projectStatus === responseStatus.cancelled)}
                                            label={getViewEditLabel(question)}
                                            handleClick={
                                                question.responseStatus
                                                === responseStatus.notStarted
                                                || isPast(parseISO(data.submissionEndDate))
                                                || data.bidStatus === responseStatus.bidSubmitted
                                                || data.projectStatus === responseStatus.cancelled
                                                    ? () => history.push(`/questionnaires/answer/view/${question.responseID}`, {
                                                        deadlinePassed: isPast(
                                                            parseISO(data.submissionEndDate),
                                                        ) || data.bidStatus
                                                        === responseStatus.bidSubmitted
                                                        || data.projectStatus
                                                        === responseStatus.cancelled,
                                                    })
                                                    : () => history.push(`/questionnaires/answer/${question.responseID}`)
                                            }
                                        />
                                    </div>
                                </div>)}
                            </>,
                        }))}
                    open={true} />
            </div>}
        {selectLotsModal
            && <AnswerSummaryModal
                closeModal={() => {
                    setSelectLotsModal(false);
                }}
                openModal={selectLotsModal}
                handleSubmit={handleLotSubmit}
                selectedLotsData={selectedLots}
            />}
    </div>;
};

export default AnswerSummary;
