import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DocumentBuilder from '../../features/DocumentBuilder';
import gatewayAPIs from '../../services/gateway.service';
import helperFunctions from '../../utils/helperFunctions';
import Toast from '../../components/Alerts/Toast/Toast';
import { ProjectDataContext } from '../../context/ProjectDataContext';

const ViewWorkflow = () => {
    const { workflowID, type } = useParams();
    // const [type, setType] = useState('');
    const [initialState, setInitialState] = useState({});
    const { setProjectData } = useContext(ProjectDataContext);

    useEffect(() => {
        const fetchData = async () => {
            const responseData = await gatewayAPIs.getWorkflow(workflowID);
            if (responseData.status === 200) {
                const preparedData = helperFunctions
                    .prepareDocumentBuilderState('workflow', responseData.data, workflowID);
                setInitialState(preparedData);
                setProjectData({ title: responseData.data.workflowName });
                // setType(preparedData.details.type);
            } else {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to retrieve information.',
                });
            }
        };
        // TODO: if (workflowId && authContext.user.isAuthenticated) {
        if (workflowID) {
            fetchData();
        }
    }, [workflowID]);

    return Object.keys(initialState).length > 0 ? <DocumentBuilder
        documentMode={'View'}
        context='Workflow'
        type={type}
        initialDocumentState={initialState}
        sectionLabel='Stage'
        sectionItemLabel='Event'
        allowSectionReordering={false}
        allowSectionItemsReordering={false}
    /> : <></>;
};

export default ViewWorkflow;
