import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import NotificationIcon from '../../../../styles/icons/blue/bell.svg';
import NewNotificationIcon from '../../../../styles/icons/white/notification.svg';
import { ThemeContext } from '../../../../context/ThemeContext';
import GeneralConstants from '../../../../utils/generalConstants';

const NotificationToggleButton = ({ handleToggleBtnClick, numberOfNotifications }) => {
    const { toggle } = useContext(ThemeContext);
    return (
        <div id='notificationsButtonContainer'>
            <button id={'notificationToggleButton'}
                onClick={handleToggleBtnClick}>
                {toggle
                    ? <>
                        <a className='title' id='notificationToggleBtnLabel'>{GeneralConstants.NOTIFICATIONS}</a>
                        <img src={NewNotificationIcon} alt='notification-bell-icon' id='bellIcon' />
                    </>
                    : <>
                        <img src={NotificationIcon} alt='notification-bell-icon' id='bellIcon' />
                        <a className='title' id='notificationToggleBtnLabel'>{GeneralConstants.NOTIFICATIONS}</a>
                    </>
                }

            </button>
            <div id='notificationsCounter'>{numberOfNotifications}</div>
        </div>
    );
};

NotificationToggleButton.propTypes = {
    numberOfNotifications: PropTypes.number.isRequired,
    handleToggleBtnClick: PropTypes.func.isRequired,
};

export default NotificationToggleButton;
