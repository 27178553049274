import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Proptypes from 'prop-types';
import { breadcrumbPaths } from '../../config/constants';

import homeIcon from '../../styles/icons/blue/home.svg';
import arrowIcon from '../../styles/images/arrow_icon.png';

const BreadcrumbItem = forwardRef(({ name, path, isLast }, ref) => (
    <div className='breadcrumbItem'>
        {isLast ? (
            <p className='breadcrumbCurrentPage breadcrumbEllipsis' ref={ref}>
                {name}
            </p>
        ) : (
            <>
                {name === breadcrumbPaths.HOME.name
                    ? <Link to={path}>
                        <img
                            src={homeIcon}
                            className='breadcrumbImg'
                            alt='Home'
                        />
                    </Link>
                    : <Link
                        className='breadcrumbLink breadcrumbEllipsis'
                        ref={ref}
                        to={path}
                    >
                        {name}
                    </Link>
                }
                <img
                    src={arrowIcon}
                    className='breadcrumbArrow'
                    alt='Arrow'
                />
            </>
        )}
    </div>
));

BreadcrumbItem.propTypes = {
    name: Proptypes.string.isRequired,
    path: Proptypes.string.isRequired,
    isLast: Proptypes.bool.isRequired,
    ref: Proptypes.oneOfType([
        Proptypes.func,
        Proptypes.shape({ current: Proptypes.instanceOf(Element) }),
        Proptypes.undefined,
    ]),
};

BreadcrumbItem.displayName = 'BreadcrumbItem';

export default BreadcrumbItem;
