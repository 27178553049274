import React, {
    useState, useEffect, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import { useGetDashboardData } from '../../hooks/useGetDashboardData';
import Button from '../../components/Button';
import DashboardCard from '../../components/DashboardCard';
import addIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';
import userManagementAPIs from '../../services/user-management.service';
import Upload from '../../features/Upload';
import WorknotesModal from '../../features/WorknotesModal';
import UserRoles from '../../features/Header/constants';
import rbac from '../../rbac';
import Toast from '../../components/Alerts/Toast/Toast';
import { cleanupDeletedDocument, mergeUploadedDocuments, persistUploadedDocuments } from '../../utils/uploadHelper';
import { ThemeContext } from '../../context/ThemeContext';
import { DASHBOARD_OPTIONS, DASHBOARD_KEYS } from './config';
import {
    taskEventContent,
    myProjectContent,
    submitProcrumentRequest,
    myRequestContent,
    callOffContent,
    myContractContent,
} from './helpers';


const LocalAuthorityDashboard = () => {
    const authContext = useContext(AuthContext);
    const [showWorknotes, setShowWorkNote] = useState(false);
    const [workNoteMode, setWorkNoteMode] = useState('');
    const [workNotes, setWorkNotes] = useState([]);
    const [documents, setDocuments] = useState([]);
    const { accountType } = authContext.user;
    const history = useHistory();
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addIcon;

    const { data, fetchDashboard, getCardContent } = useGetDashboardData(DASHBOARD_OPTIONS,
        DASHBOARD_KEYS, authContext.user);


    const fetchUser = () => {
        setWorkNotes(authContext?.user?.organisationDetails?.worknotes);
        setDocuments(authContext?.user?.additionalDocuments);
    };

    const viewWorkNotes = () => {
        setWorkNoteMode('view');
        setShowWorkNote(true);
    };

    const addWorkNotes = () => {
        setWorkNoteMode('add');
        setShowWorkNote(true);
    };

    const handleAddWorkNotes = async (workNoteInput) => {
        const newListWorknotes = workNoteInput;
        const payLoad = {
            worknotes: newListWorknotes,
        };
        try {
            const response = await userManagementAPIs.updateAccountWorknotes(payLoad);
            if (response.status === 200) {
                Toast.fire({
                    icon: 'success',
                    titleText: 'Worknote added successfully.',
                });

                authContext.updateAuthContext({
                    ...authContext.user,
                    organisationDetails: {
                        ...authContext.user.organisationDetails,
                        worknotes: newListWorknotes,
                    },
                });
            }
        } catch (err) {
            Toast.fire({
                icon: 'error',
                titleText: 'Unable to add worknote.',
            });
        }
    };

    const uploadDocuments = async (newDocs) => {
        const mergedDocs = mergeUploadedDocuments(documents, newDocs,
            {}, authContext.user);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'upload',
            () => userManagementAPIs.updateAccountDocuments(payload),
            fetchUser, // Success
            fetchUser, // Fail
        );

        authContext.updateAuthContext({
            ...authContext.user,
            additionalDocuments: mergedDocs,
        });
    };

    const deleteDocument = async (doc) => {
        const mergedDocs = cleanupDeletedDocument(documents, doc);

        const payload = {
            additionalDocuments: mergedDocs,
        };

        await persistUploadedDocuments(
            'delete',
            () => userManagementAPIs.updateAccountDocuments(payload),
            fetchUser, // Success
            fetchUser, // Fail
        );

        authContext.updateAuthContext({
            ...authContext.user,
            additionalDocuments: mergedDocs,
        });
    };

    useEffect(() => {
        if (authContext.authenticated) {
            fetchUser();
            fetchDashboard();
        }
    }, [authContext.authenticated, fetchDashboard, authContext.user]);

    return <>
        <div className={`dashboardContainer ${toggle ? 'new-dashboardContainer' : ''}`}>
            <DashboardCard
                id='taskEventCard'
                size='small'
                header={true}
                headerTitle='My Tasks and Events'
                actionBtn={false}
                actionBtnText='View All'
                content={getCardContent({
                    method: DASHBOARD_KEYS.TASKS_AND_EVENTS,
                    contentFunction: taskEventContent,
                    content: data,
                })}
                handlerActionBtn={() => { }}
                footer={data?.[DASHBOARD_KEYS.TASKS_AND_EVENTS]?.length > 0}
                footerBtnText='View All'
                footerUrl='/calendar'
                footerBtnHandler={() => history.push('/calendar')}
                customStyle = 'customStyle'
            />
            <DashboardCard
                id='myProjectsCard'
                size='medium'
                header={true}
                headerTitle='My Projects'
                icon
                iconSrc={addIconSrc}
                actionBtn={rbac.can(rbac.action.createProject,
                    [accountType.toLowerCase() + authContext.user.role])}
                actionBtnText='New Project'
                variant='secondary'
                additionalVariant='skinless'
                content={getCardContent({
                    method: DASHBOARD_KEYS.PROJECTS,
                    contentFunction: myProjectContent,
                    content: data,
                    history,
                    toggle,
                })}
                handlerActionBtn={() => history.push('/project/create')}
                footer={true}
                footerBtnText='View All'
                footerUrl={'/projects'}
                footerBtnHandler={() => history.push('/projects')}
                customStyle = 'customStyle'
            />
        </div>
        {accountType.toLowerCase() === UserRoles.LOCAL_AUTHORITY_ROLE && <div className={`dashboardContainer ${toggle ? 'new-dashboardContainer' : ''}`}>
            <DashboardCard
                id='submitProcurementRequests'
                size='small'
                header={true}
                headerTitle='Submitted Procurement Requests'
                caption='Procurement requests
                submitted by members of your organisation, pending your review.'
                actionBtn={false}
                content={getCardContent({
                    method: DASHBOARD_KEYS.PROC_REQUESTS_APPROVAL,
                    contentFunction: submitProcrumentRequest,
                    content: data,
                    history,
                })}
                handlerActionBtn={() => { }}
                footer={true}
                footerBtnText='View All'
                footerUrl={'/procurement-requests'}
                footerBtnHandler={() => history.push('/procurement-requests')}
                customStyle='customStyle'
            />
            <DashboardCard
                id='myProcurementRequestsCard'
                size='medium'
                header={true}
                headerTitle='My Procurement Requests'
                icon
                iconSrc={addIconSrc}
                actionBtn={true}
                actionBtnText='New Request'
                variant='secondary'
                additionalVariant='skinless addProject'
                content={getCardContent({
                    method: DASHBOARD_KEYS.PROC_REQUESTS,
                    contentFunction: myRequestContent,
                    content: data,
                    history,
                })}
                handlerActionBtn={() => history.push('/pre-procurement-request')}
                footer={true}
                footerBtnText='View All'
                footerUrl={'/procurement-requests'}
                footerBtnHandler={() => history.push('/procurement-requests')}
                customStyle='customStyle'
            />
        </div>}
        <div className={`dashboardContainer ${toggle ? 'new-dashboardContainer' : ''}`}>
            {/* <DashboardCard
                id='Stage1Approval'
                size='small'
                header={true}
                headerTitle='Stage 1 Approval'
                caption='Approval requests raised
                by the project manager for stage 1 of the tendering evaluation completed.'
                actionBtn={false}
                content={stage1ApprovalContent()}
                handlerActionBtn={() => { }}
                footer={false}
                footerBtnText='View All'
                footerBtnHandler={() => alert('View All button clicked')}
            /> */}
            <DashboardCard
                id='actions'
                size='small'
                header={true}
                headerTitle='Actions'
                caption={'Documents and Worknotes uploaded here are visible to everyone in your organisation'}
                content={<div id='dashboardActionContainer'>
                    <div id='dashboardActionWorknotes'>
                        <p className='title sectionLabel' id='worknotes'> Worknotes</p>
                        <p className='caption-small'> {`${workNotes ? workNotes?.length : 0} worknotes added`}</p>
                        <div className='buttonContainer'>
                            <Button id='actionBtnS'
                                size='small'
                                variant='secondary'
                                label='View'
                                handleClick={viewWorkNotes} />
                            <Button id='actionBtn'
                                size='small'
                                variant='primary'
                                label='Add'
                                handleClick={addWorkNotes} />
                        </div>
                    </div>
                    <Upload
                        title = {'Documents'}
                        allowMultiple = {true}
                        uploadIdentifier = {'Document'}
                        uploadCallback={uploadDocuments}
                        deleteCallback={deleteDocument}
                        questionnaire={false}
                        uploadedDocuments={documents}
                    />
                </div>}
                customStyle='customStyle'
            />
            <DashboardCard
                id='callOffs'
                size='medium'
                header={true}
                headerTitle='My Call-Offs'
                actionBtn={false}
                content={getCardContent({
                    method: DASHBOARD_KEYS.CALL_OFFS,
                    contentFunction: callOffContent,
                    content: data,
                    history,
                    toggle,
                })}
                handlerActionBtn={() => { }}
                footer={true}
                footerBtnText='View All'
                footerUrl={'/callOffs'}
                footerBtnHandler={() => history.push('/callOffs')}
                customStyle='customStyle'
            />
        </div>

        <div className={`dashboardContainer  ${toggle ? 'new-dashboardContainer' : ''}`}>
            <DashboardCard
                id='myContractsCard'
                size='medium'
                header={true}
                headerTitle='My Contracts'
                icon
                iconSrc={addIconSrc}
                actionBtn={false}
                content={getCardContent({
                    method: DASHBOARD_KEYS.CONTRACTS,
                    contentFunction: myContractContent,
                    content: data,
                    history,
                    toggle,
                })}
                handlerActionBtn={() => {}}
                footer={true}
                footerBtnText='View All'
                footerUrl={'/contracts'}
                footerBtnHandler={() => history.push('/contracts')}
                customStyle = {'customStyle  my-contracts'}
            />
        </div>

        {showWorknotes
            && <WorknotesModal
                closeModal={() => setShowWorkNote(false)}
                mode={workNoteMode}
                worknotesInput={workNotes}
                handleCallback={handleAddWorkNotes}
            />}

    </>;
};

export default LocalAuthorityDashboard;
