import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Form elements
import Button from '../../components/Button';
import LabelledTextarea from '../../components/LabelledTextArea';
import { ThemeContext } from '../../context/ThemeContext';
// Components
import {
    DatePickerComponent,
    LabelComponent,
    RadioButtonComponent,
    InputComponent,
    SelectComponent,
    SelectSearchComponent,
} from './components';

// Icons
import addEllipseIcon from '../../styles/icons/blue/add-ellipse.svg';
import addEllipseIconNew from '../../styles/icons/blue/add-ellipse-2.svg';

// Helpers
import { hasRequiredReferenceType } from './index.helpers';

const ContractsFinderNoticeForm = ({
    project,
    contract,
    suppliers,
    noticeEvent,
    extra,
    handleChange,
    setShowCodesModal,
    generateCodeTag,
}) => {
    const { toggle } = useContext(ThemeContext);
    const addIconSrc = toggle ? addEllipseIconNew : addEllipseIcon;
    return (
        <>
            <div>
                <h3 className='title-large subHeading'>
                Summary Information
                </h3>

                <LabelComponent
                    label='Procurement Reference'
                    value={project?.projectReference}
                />

                <InputComponent
                    id='project.title'
                    label='Notice Title'
                    type='text'
                    value={project?.title}
                    required={'Enter Title'}
                    onChange={handleChange}
                />

                <DatePickerComponent
                    id='contract.submissionDeadlineEndDate'
                    label='Closing Date'
                    value={contract?.submissionDeadlineEndDate}
                    onChange={handleChange}
                    minDateFlag={false}
                />

                <DatePickerComponent
                    id='contract.approachToMarketDate'
                    label='Approach to Market Date'
                    value={contract?.approachToMarketDate}
                    onChange={handleChange}
                    noticeType={noticeEvent?.noticeType}
                    permittedNoticeTypes={['futureOpportunity']}
                />

                <DatePickerComponent
                    id='contract.contractStart'
                    label='Contract Start Date'
                    value={contract?.contractStart}
                    onChange={handleChange}
                    noticeType={noticeEvent?.noticeType}
                    permittedNoticeTypes={['award', 'futureOpportunity', 'liveOpportunity']}
                />

                <DatePickerComponent
                    id='contract.contractEnd'
                    label='Contract End Date'
                    value={contract?.contractEnd}
                    onChange={handleChange}
                    noticeType={noticeEvent?.noticeType}
                    permittedNoticeTypes={['award', 'futureOpportunity', 'liveOpportunity']}
                />

                <RadioButtonComponent
                    id='extra.published'
                    label='Has a notice related to this procurement already been published?'
                    value={extra?.published}
                    required={true}
                    onChange={handleChange}
                    noticeType={noticeEvent?.noticeType}
                    permittedNoticeTypes={['award', 'futureOpportunity', 'liveOpportunity']}
                />

                <InputComponent
                    id='extra.valueLow'
                    label='Lowest/Actual value (£)'
                    type='number'
                    value={extra?.valueLow}
                    required={true}
                    placeholder='Value (low)'
                    onChange={handleChange}
                    noticeType={noticeEvent?.noticeType}
                    permittedNoticeTypes={['award', 'futureOpportunity', 'liveOpportunity']}
                />

                <InputComponent
                    id='extra.valueHigh'
                    label='Highest value (£)'
                    type='number'
                    value={extra?.valueHigh}
                    required={true}
                    placeholder='Value (high)'
                    onChange={handleChange}
                    noticeType={noticeEvent?.noticeType}
                    permittedNoticeTypes={['award', 'futureOpportunity', 'liveOpportunity']}
                />

                <RadioButtonComponent
                    id='extra.isSuitableForSme'
                    label='Is this suitable for small and medium sized enterprises?'
                    value={extra?.isSuitableForSme}
                    required={true}
                    onChange={handleChange}
                />

                <RadioButtonComponent
                    id='extra.isSuitableForVco'
                    label='Is this suitable for voluntary, community, and social enterprises?'
                    value={extra?.isSuitableForVco}
                    required={true}
                    onChange={handleChange}
                />

                <SelectComponent
                    id='extra.contractType'
                    label='Contract Type'
                    value={extra?.contractType}
                    required={true}
                    onChange={handleChange}
                    noticeType={noticeEvent?.noticeType}
                    permittedNoticeTypes={['award', 'futureOpportunity', 'liveOpportunity']}
                    options={{
                        Works: 'Works',
                        Services: 'Services',
                        Products: 'Products',
                        NotApplicable: 'Not Applicable',
                        NotSpecified: 'Not Specified',
                    }}
                />

                <SelectComponent
                    id='extra.procedureType'
                    label='Procedure Type'
                    value={extra?.procedureType}
                    onChange={handleChange}
                    required={true}
                    options={{
                        OpenOJEU: 'Open (OJEU)',
                        OpenAcceleratedOJEU: 'Open - accelerated (OJEU)',
                        RestrictedOJEU: 'Restricted (OJEU)',
                        RestrictedAcceleratedOJEU: 'Restricted - accelerated (OJEU)',
                        CompetitiveProcedureWithNegotiationOJEU: 'Competitive procedure with negotiation (OJEU)',
                        CompetitiveProcedureWithNegotiationAcceleratedOJEU: 'Competitive procedure with negotiation - accelerated (OJEU)',
                        CompetitiveDialogueOJEU: 'Competitive dialogue (OJEU)',
                        DynamicPurchasingSystem: 'Dynamic Purchasing System',
                        NegotiatedProcedureWithoutPriorPublicationOJEU: 'Negotiated procedure without prior publication (OJEU)',
                        InnovationPartnershipOJEU: 'Innovation partnership (OJEU)',
                        OpenNonOJEU: 'Open (non-OJEU)',
                        CompetitiveQuotationNonOJEU: 'Competitive quotation (non-OJEU)',
                        CallOffFromFrameworkAgreement: 'Call off from a Framework Agreement',
                        CallOffFromDynamicPurchasingSystem: 'Call off from a Dynamic Purchasing System',
                        CataloguePurchase: 'Catalogue purchase',
                        SingleTenderActionNonOJEU: 'Single tender action (non-OJEU)',
                        Other: 'Other (procedure type)',
                    }}
                />
            </div>

            {['futureOpportunity'].includes(noticeEvent?.noticeType)
        && <>
            <div className='vpnDivider'></div>

            <div>
                <h3 className='title-large subHeading'>
                    Value Information
                </h3>

                <InputComponent
                    id='extra.spendProfile'
                    label='Spend Profile by Financial Year'
                    type='number'
                    required={true}
                    value={extra?.spendProfile}
                    placeholder='Spend profile'
                    onChange={handleChange}
                />

                <InputComponent
                    id='extra.budget'
                    label='Budget'
                    type='number'
                    required={true}
                    value={extra?.budget}
                    placeholder='Budget'
                    onChange={handleChange}
                />
            </div>
        </>}

            <div className='vpnDivider'></div>

            <div>
                <h3 className='title-large subHeading'>
                Description
                </h3>

                <LabelledTextarea
                    id='project.description'
                    label='Description'
                    type='textarea'
                    value={project?.description}
                    placeholder={'Enter Description'}
                    required={true}
                    onChange={handleChange}
                />
            </div>

            <div className='vpnDivider'></div>

            <div>
                <h3 className='title-large subHeading'>
                Contact Details
                </h3>

                <InputComponent
                    id='extra.contactName'
                    label='Contact Name'
                    type='text'
                    required={true}
                    value={extra?.contactName}
                    placeholder='Contact Name'
                    onChange={handleChange}
                />

                <InputComponent
                    id='extra.contactEmail'
                    label='Contact Email'
                    type='text'
                    required={true}
                    value={extra?.contactEmail}
                    placeholder='hello@nepo.org'
                    onChange={handleChange}
                />

                <InputComponent
                    id='extra.contactAddress1'
                    label='Contact Address'
                    type='text'
                    required={true}
                    value={extra?.contactAddress1}
                    placeholder='First line of the address'
                    onChange={handleChange}
                />

                <InputComponent
                    id='extra.contactAddress2'
                    // label='Contact Address'
                    type='text'
                    required={false}
                    value={extra?.contactAddress2}
                    placeholder='Second line of the address'
                    onChange={handleChange}
                />

                <InputComponent
                    id='extra.contactCity'
                    label='Town/City'
                    type='text'
                    required={true}
                    value={extra?.contactCity}
                    placeholder='Town/City'
                    onChange={handleChange}
                />

                <InputComponent
                    id='extra.contactPostcode'
                    label='Postcode'
                    type='text'
                    required={true}
                    value={extra?.contactPostcode}
                    placeholder='A valid UK postcode, e.g. NE8 3DF'
                    pattern='([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})'
                    title='Enter a valid postcode'
                    onChange={handleChange}
                />

                <SelectComponent
                    id='extra.contactCountry'
                    label='Country'
                    value={extra?.contactCountry}
                    required={true}
                    onChange={handleChange}
                    options={{
                        England: 'England',
                        'Northern Ireland': 'Northern Ireland',
                        Scotland: 'Scotland',
                        Wales: 'Wales',
                    }}
                />

                <InputComponent
                    id='extra.contactTelephone'
                    label='Telephone'
                    type='text'
                    required={false}
                    value={extra?.contactTelephone}
                    placeholder='e.g. +44 (0)191 12345678'
                    pattern='(^\+?[0-9 -\(\)]{1,20}$)'
                    title='Please enter a valid UK phone number'
                    onChange={handleChange}
                />

                <InputComponent
                    id='extra.contactWebsite'
                    label='Website'
                    type='text'
                    required={false}
                    value={extra?.contactWebsite}
                    placeholder='e.g. https://nepo.org'
                    pattern='^https?://\S{1,}'
                    title='Please enter a valid website address, beginning with http:// or https://'
                    onChange={handleChange}
                />
            </div>

            <div className='vpnDivider'></div>

            <div>
                <h3 className='title-large subHeading'>
                Location and Industry
                </h3>

                <SelectSearchComponent
                    id='extra.region'
                    label='Region'
                    value={extra?.region}
                    onChange={(e) => handleChange(e, 'extra.region')}
                    required={true}
                    options={[
                        'Any region',
                        'United Kingdom',
                        'England',
                        'Isle of Man',
                        'Channel Islands',
                        'North East',
                        'North West',
                        'Yorkshire and the Humber',
                        'East Midlands',
                        'West Midlands',
                        'East of England',
                        'London',
                        'South East',
                        'South West',
                        'Scotland',
                        'Wales',
                        'Northern Ireland',
                    ]}
                />
            </div>

            <div className='vpnDivider'></div>

            <div key='codesAndCategories'>
                <h3 className='title-large subHeading'>
                Codes and Categories
                </h3>

                <div className='vpnItem'>
                    <div className='vpnItemEdit'>
                        <h4 className='title'>
                        Select Codes and Categories
                        </h4>

                        <Button
                            id='addCodeButton'
                            size='small'
                            variant='secondary skinless'
                            label='Add'
                            icon={true}
                            iconSrc={addIconSrc}
                            disabled={false}
                            handleClick={(e) => {
                                e.preventDefault();
                                setShowCodesModal(true);
                            }}
                        />
                    </div>

                    {generateCodeTag(extra?.cpvInfo || [], true)}
                </div>
            </div>

            {['award'].includes(noticeEvent?.noticeType)
            && <>
                <div className='vpnDivider'></div>

                <div>
                    <h3 className='title-large subHeading'>
                        Award Details
                    </h3>
                </div>

                {suppliers?.awardedSuppliers?.length > 0
                    && <>
                        <div className='vpnDivider'></div>
                        <div>
                            <h3 className='title-large subHeading'>Suppliers</h3>
                            {suppliers?.awardedSuppliers?.map((supplier, idx) => <>
                                <h3 className='title-large'>
                                    Supplier #{idx + 1}
                                </h3>

                                <LabelComponent
                                    label='Supplier Name'
                                    value={supplier?.account?.companyName}
                                />

                                <LabelComponent
                                    label='Supplier Address'
                                    value={[
                                        // eslint-disable-next-line max-len
                                        supplier?.account?.address?.addressLine1?.trim(),
                                        // eslint-disable-next-line max-len
                                        supplier?.account?.address?.addressLine2?.trim(),
                                        supplier?.account?.address?.city?.trim(),
                                        supplier?.account?.address?.county?.trim(),
                                        supplier?.account?.address?.postcode?.trim(),
                                        supplier?.account?.address?.country?.trim(),
                                    ].filter(Boolean).join(', ')}
                                />

                                <InputComponent
                                    id={`suppliers.awardedSuppliers[${idx}].contact`}
                                    label='Supplier contact name'
                                    value={supplier.contact}
                                    type='text'
                                    required={true}
                                    placeholder='Name of the primary contact for this award'
                                    onChange={handleChange}
                                />

                                <InputComponent
                                    id={`suppliers.awardedSuppliers[${idx}].awardedValue`}
                                    label='Awarded Value (£)'
                                    type='text'
                                    required={true}
                                    value={supplier.awardedValue}
                                    placeholder='Awarded value for this supplier'
                                    onChange={handleChange}
                                />

                                <DatePickerComponent
                                    id={`suppliers.awardedSuppliers[${idx}].awardedDate`}
                                    label='Date contract was awarded'
                                    value={supplier.awardedDate}
                                    onChange={handleChange}
                                    minDateFlag={false}
                                />

                                <DatePickerComponent
                                    id={`suppliers.awardedSuppliers[${idx}].startDate`}
                                    label='Contract Start Date'
                                    value={supplier.startDate}
                                    onChange={handleChange}
                                />

                                <DatePickerComponent
                                    id={`suppliers.awardedSuppliers[${idx}].endDate`}
                                    label='Contract End Date'
                                    value={supplier.endDate}
                                    onChange={handleChange}
                                />

                                <SelectComponent
                                    id={`suppliers.awardedSuppliers[${idx}].referenceType`}
                                    label='Reference Type'
                                    value={supplier.referenceType}
                                    required={true}
                                    onChange={handleChange}
                                    options={{
                                        COMPANIES_HOUSE: 'Companies House number',
                                        CHARITY_COMMISSION: 'Charity Commission reference',
                                        SCOTTISH_CHARITY: 'Scottish Charity Register',
                                        NI_CHARITY_COMMISSION: 'Charity commission for Northern Ireland',
                                        NONE_SOLE_TRADER: 'No reference - sole trader',
                                        NONE_PARTNERSHIP: 'No reference - partnership',
                                        NONE_OTHER: 'No reference - other',
                                    }}
                                />

                                <InputComponent
                                    id={`suppliers.awardedSuppliers[${idx}].referenceNo`}
                                    label='Reference Number'
                                    type='text'
                                    required={!hasRequiredReferenceType(supplier)}
                                    value={supplier.referenceNo}
                                    placeholder='Must follow the selected reference type'
                                    onChange={handleChange}
                                />

                                <RadioButtonComponent
                                    id={`suppliers.awardedSuppliers[${idx}].awardedToSME`}
                                    label='Is the supplier a small or medium-sized enterprise (SME)?'
                                    value={supplier.awardedToSME}
                                    required={true}
                                    onChange={handleChange}
                                />

                                <RadioButtonComponent
                                    id={`suppliers.awardedSuppliers[${idx}].awardedToVCSO`}
                                    label='Is the supplier a voluntary, community or social enterprise (VCSE)?'
                                    value={supplier.awardedToVCSO}
                                    required={true}
                                    onChange={handleChange}
                                />

                                <div className='vpnDivider'></div>
                            </>)}
                        </div>
                    </>
                }
            </>
            }
        </>
    );
};

ContractsFinderNoticeForm.propTypes = {
    project: PropTypes.object.required,
    contract: PropTypes.object.required,
    suppliers: PropTypes.array.required,
    noticeEvent: PropTypes.object.required,
    extra: PropTypes.object.required,
    handleChange: PropTypes.func.required,
    setShowCodesModal: PropTypes.func.required,
    generateCodeTag: PropTypes.func.required,
};

export default ContractsFinderNoticeForm;
