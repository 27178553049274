import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SolutionAccessRequestsCard from './SolutionAccessRequestsCard';
import SolutionUsersCard from './SolutionUsersCard';
import projectManagementAPIs from '../../../services/project-management.service';
import Constants from '../constants';

const SolutionAdminPanel = (props) => {
    const [accessRequestedRequests, setAccessRequestedRequests] = useState([]);
    const [approvedRequests, setApprovedRequests] = useState([]);

    async function fetchSolutionAccessRequests() {
        const response = await projectManagementAPIs.fetchSolutionAccessRequests(
            props.solutionID,
        );

        const allRequests = response.data;

        const accessRequested = allRequests
            .filter((req) => req.requestStatus
                === Constants.SOLUTION_ACCESS_REQUEST_STATUSES.ACCESS_REQUESTED);
        const approved = allRequests
            .filter((req) => req.requestStatus
                 === Constants.SOLUTION_ACCESS_REQUEST_STATUSES.APPROVED);

        setAccessRequestedRequests(accessRequested);
        setApprovedRequests(approved);
    }

    async function reRenderAdminPanel() {
        await fetchSolutionAccessRequests();
    }

    useEffect(() => {
        fetchSolutionAccessRequests();
    }, []);

    return <>
        <div id='solutionAdminPanel'>
            <SolutionAccessRequestsCard
                solutionAccessRequests={accessRequestedRequests}
                contractReference={props.contractReference}
                reRenderParentCallback={reRenderAdminPanel}
            />
            <SolutionUsersCard solutionAccessRequests={approvedRequests} />
        </div>
    </>;
};

SolutionAdminPanel.propTypes = {
    solutionID: PropTypes.string,
    contractReference: PropTypes.string,
};

export default SolutionAdminPanel;
