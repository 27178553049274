import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { set } from 'lodash';
import TimePicker from 'react-time-picker';
import { useHistory } from 'react-router-dom';
import LabelledTextArea from '../../components/LabelledTextArea';
import LabelledCheckBox from '../../components/LabelledCheckBox';
import Button from '../../components/Button';
import Toast from '../../components/Alerts/Toast/Toast';
import clearIcon from '../../styles/icons/blue/clear.svg';
import projectManagementAPIs from '../../services/project-management.service';
import {
    DatePickerComponent,
    RadioButtonComponent,
    InputComponent,
    SelectSearchComponent,
    ErrorMessagesFor,
} from './components';
import FTSNameAndAddresses from './FTSNameAndAddresses';
import FTSContracts from './FTSContracts';
import Confirm from '../../components/Alerts/Confirm/Confirm';

const FTSF01NoticeForm = ({
    project,
    noticeEvent,
}) => {
    const [formFields, setFormFields] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);
    const history = useHistory();

    const handleErrors = (errors) => {
        setErrorMessages(errors?.filter((v, i, a) => a.indexOf(v) === i));
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    const handleChanges = (e, fieldName) => {
        let name;
        let value;

        if (e?.target) {
            if (e?.target.type === 'checkbox') {
                name = e.target.name;
                value = e.target.checked ? e.target.value : '';
            } else {
                name = e.target.name;
                value = e.target.value;
            }
        } else {
            name = fieldName;
            value = e;
        }
        const nodeTree = name.split('.');

        setFormFields((prevState) => (
            { ...set((prevState || {}), nodeTree.join('.'), value) }
        ));
    };

    const confirmAction = async (e, formData) => {
        const { projectID } = project;
        const { eventID, noticeType } = noticeEvent;
        let responseBody = {};
        setErrorMessages([]);
        e.preventDefault();
        const validationErrorMessages = [];
        let IsValid = true;
        formData.forEach((value, property) => {
            if (value !== '' && !property.includes('notMapped') && property !== 'day'
                && property !== 'month' && property !== 'year' && property !== 'hour24'
                && property !== 'minute' && property !== 'time') {
                if (property === 'procedure.languages' || property.includes('cpvSupplementaryCodes')) {
                    // eslint-disable-next-line no-param-reassign
                    value = value.split(',');
                }
                if (value === 'false') {
                    // eslint-disable-next-line no-param-reassign
                    value = (value === 'true');
                }
                if (value === 'true') {
                    // eslint-disable-next-line no-param-reassign
                    value = (value === 'true');
                }

                if (property.includes('weighting')) {
                    // eslint-disable-next-line no-param-reassign
                    value = Number(value);
                }
                if (property === 'contractingBody.addressContractingBody.country') {
                    if (value !== 'UK') {
                        validationErrorMessages.push('Country in the address field is incorrect, this must be United Kingdom.');
                        IsValid = false;
                    }
                }
                if (property.includes('datePublicationNotice')) {
                    if (new Date(value) <= new Date()) {
                        validationErrorMessages.push('Estimated date of publication of contract notice must be in the future.');
                        IsValid = false;
                    }
                }
                if (property.includes('lotMaxOneTenderer')) {
                    const maxOneTender = parseInt(value, 10);
                    if (maxOneTender < 1 || maxOneTender > 1000) {
                        validationErrorMessages.push('Maximum number of lots to be awarded to one tenderer must be between 1 & 1000.');
                        IsValid = false;
                    } else if (formFields?.notMapped?.lotsQuantity > maxOneTender) {
                        validationErrorMessages.push('Number of lots exceeds maximun number of lots that can be awarded to one tenderer.');
                        IsValid = false;
                    }
                }
                if (property.includes('cpvCode')) {
                    const prefix = parseInt(value.substring(0, 2), 10);
                    if (formFields?.notMapped?.contractType === 'WORKS') {
                        if (prefix !== 45) {
                            validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of WORKS must have a CPV code that begins with 45.');
                            IsValid = false;
                        }
                    } else if (formFields?.notMapped?.contractType === 'SUPPLIES') {
                        if (prefix > 44) {
                            if (prefix !== 48) {
                                validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of SUPPLIES must have a CPV code that begins with 0 to 44, or 48.');
                                IsValid = false;
                            }
                        }
                    } else if (formFields?.notMapped?.contractType === 'SERVICES') {
                        if (prefix < 49) {
                            validationErrorMessages.push('Main CPV code does not match contract Type. A contract Type of SERVICES must have a CPV code that begins with a number higher than 49.');
                            IsValid = false;
                        }
                    }
                }
                const nodeTree = property.split('.');
                responseBody = ({ ...set((responseBody || {}), nodeTree.join('.'), value) });
            }
        });
        const payload = {
            type: noticeType,
            formSection: responseBody,
        };
        if (IsValid && projectID && eventID) {
            try {
                const response = await projectManagementAPIs.publishFTSNotice(projectID,
                    eventID, payload);
                if (response.status === 200) {
                    Toast.fire({
                        icon: 'success',
                        titleText: 'Created notice successfully.',
                    });
                    history.push(`/projects/overview/${project.projectID}`);
                } else {
                    handleErrors(response?.data?.Errors?.body || response?.data?.Errors);
                }
            } catch (error) {
                Toast.fire({
                    icon: 'error',
                    titleText: 'Unable to create notice.',
                });
            }
        } else {
            handleErrors(validationErrorMessages);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        Confirm(async () => {
            await confirmAction(e, formData);
        }, {
            subTitle: 'Are you sure you want to submit?',
        });
    };

    return (<form id='form_01' onSubmit={handleFormSubmit}>
        <div className='vpnDivider'></div>
        <ErrorMessagesFor
            errorMessages={errorMessages}
            origin={'FTS'}
        />
        <div className='formElementsDivider'></div>
        <h3 className='title-large subHeading'>F01 Form: {noticeEvent?.noticeType}</h3>
        <div className='formElementsDivider'></div>

        <div className='vpnItem'>
            <h3 className='title-large subHeading'>Preliminary questions</h3>
        </div>
        <RadioButtonComponent
            id='notice.type'
            label='Notice Type'
            value={formFields?.notice?.type}
            required={true}
            onChange={handleChanges}
            renderAsRow={false}
            options={{
                PRI_ONLY: 'This notice is for prior information only',
                PRI_REDUCING_TIME_LIMITS: 'This notice aims at reducing time limits for receipt of tenders',
                PRI_CALL_COMPETITION: 'This notice is a call for competition',
            }}
        />
        <RadioButtonComponent
            id='notMapped.contractType'
            label='Contract Type'
            value={formFields?.notMapped?.contractType}
            required={true}
            onChange={handleChanges}
            renderAsRow={false}
            options={{
                WORKS: 'Works',
                SUPPLIES: 'Supplies',
                SERVICES: 'Services',
            }}
        />
        {formFields?.notice?.type === 'PRI_CALL_COMPETITION'
        && <RadioButtonComponent
            id='procedure.pt'
            label='Procedure Type'
            value={formFields?.procedure?.pt}
            onChange={handleChanges}
            required={true}
            renderAsRow={false}
            options={{
                RESTRICTED: 'Restricted procedure',
                COMPETITIVE_NEGOTIATION: 'Competitive procedure with negotiation',
            }}
        />}
        {formFields?.notice?.type === 'PRI_ONLY'
        && <InputComponent
            id={'notMapped.numberOfAwardedContracts'}
            label='Number of awarded contracts and/or non-awards'
            value={formFields?.notMapped?.numberOfAwardedContracts}
            type='number'
            required={true}
            onChange={handleChanges}
        />}
        <LabelledCheckBox
            id={'notMapped.jointProcurement'}
            label={'Joint procurement'}
            breakColumn={true}
            options={[{
                id: 'notMapped.jointProcurement',
                value: true,
                label: 'The contract involves joint procurement ',
                checked: formFields?.notMapped?.jointProcurement === 'true',
                name: 'notMapped.jointProcurement',
            }]}
            onChange={(e) => handleChanges(e)}
            renderAsRow={true}
            required={false}
        />
        {formFields?.notMapped?.jointProcurement === 'true'
        && <InputComponent
            id={'notMapped.numberOfContractingAuthorities'}
            label='Number of contracting authorities responsible for the procedure'
            value={formFields?.notMapped?.numberOfContractingAuthorities}
            type='number'
            minNumber={1}
            onChange={handleChanges}
            required={false}
        />}
        <div className='formElementsDivider'></div>
        <h4 className='title-large subHeading'>Section I. Contracting authority</h4>
        <h4 className='title-large subHeading'>I.1. Name and addresses</h4>
        <FTSNameAndAddresses
            prefix={'contractingBody.addressContractingBody'}
            handleChange={handleChanges}
            formFields={formFields}
            buyerAddressFlag={true}
        />
        <h3 className='title-large subHeading'>I.2. Joint procurement</h3>
        {formFields?.notMapped?.jointProcurement === 'true'
        && <LabelledTextArea
            id='contractingBody.procurementLaw'
            breakColumn={true}
            label='In the case of joint procurement involving different countries, state applicable national procurement law'
            value={formFields?.contractingBody?.procurementLaw || ''}
            maxCharCount={3000}
            onChange={(e) => handleChanges(e)}
            required={false}
        />}
        <LabelledCheckBox id={'contractingBody.centralPurchasing'}
            breakColumn={true}
            options={[{
                id: 'contractingBody.centralPurchasing',
                value: true,
                label: 'The contract is awarded by central purchasing body.',
                checked: formFields?.contractingBody?.centralPurchasing,
                name: 'contractingBody.centralPurchasing',
            }]}
            onChange={(e) => handleChanges(e)}
            renderAsRow={true}
            required={true}
        />
        <h3 className='title-large subHeading'>I.3. Communication</h3>
        <RadioButtonComponent
            id='contractingBody.urlDocument.type'
            label='How are the procurement documents being made available?'
            value={formFields?.contractingBody?.urlDocument?.type}
            required={true}
            onChange={handleChanges}
            renderAsRow={false}
            options={{
                FULL: 'The procurement documents are available for unrestricted and full direct access, free of charge, at',
                RESTRICTED: 'Access to the procurement documents is restricted. Further information can be obtained at',
                NONE: 'None of the above',
            }}
        />
        {formFields?.contractingBody?.urlDocument?.type === 'FULL'
        && <InputComponent
            id={'contractingBody.urlDocument.url'}
            label='URL'
            value={formFields?.contractingBody?.urlDocument?.url || ''}
            type='text'
            pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}'}
            title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
            required={true}
            onChange={handleChanges}
        />}
        <RadioButtonComponent
            id='notMapped.contractingBody.addressContractingBody.additionalInfo'
            label='Additional information can be obtained from'
            value={formFields?.notMapped?.contractingBody?.addressContractingBody?.additionalInfo}
            required={true}
            onChange={handleChanges}
            renderAsRow={false}
            options={{
                MENTIONED_ADDRESS: 'The above-mentioned address',
                ANOTHER_ADDRESS: 'Another address',
            }}
        />
        {formFields?.notMapped?.contractingBody?.addressContractingBody?.additionalInfo === 'ANOTHER_ADDRESS'
            && <FTSNameAndAddresses
                prefix= {'contractingBody.addressFurtherInfo'}
                handleChange={handleChanges}
                formFields={formFields}
            />}
        {formFields?.notice?.type !== 'PRI_ONLY' && <>
            {formFields?.notMapped?.contractingBody?.addressContractingBody?.additionalInfo === 'MENTIONED_ADDRESS'
        && <LabelledCheckBox id={'notMapped.tender'}
            breakColumn={true}
            options={[{
                id: 'notMapped.tender',
                value: true,
                label: 'Tenders or requests to participate must be submitted',
                checked: formFields?.notMapped?.tender === 'true',
                name: 'notMapped.tender',
            }]}
            onChange={(e) => handleChanges(e)}
            renderAsRow={true}
            required={true}
        />}
            {formFields?.notMapped?.tender === 'true'
        && <InputComponent
            id='contractingBody.tendersRequest.urlParticipation'
            label='URL'
            type='text'
            value={formFields?.contractingBody?.tendersRequest?.urlParticipation || ''}
            onChange={(e) => handleChanges(e)}
            required={true}
            pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}'}
            title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
        />}
            <RadioButtonComponent
                id='notMapped.postTo'
                label='And/or by post to'
                value={formFields?.notMapped?.postTo}
                required={formFields?.notMapped?.tender !== 'true' || true}
                onChange={handleChanges}
                renderAsRow={false}
                options={{
                    ABOVE_ADDRESS: 'The above-mentioned address',
                    ANOTHER_ADDRESS: 'Another address',
                    NONE: 'None of the Above',
                }}
            />
            {formFields?.notMapped?.postTo === 'ANOTHER_ADDRESS'
            && <FTSNameAndAddresses
                prefix= {'contractingBody.tendersRequest.addressParticipation'}
                handleChange={handleChanges}
                formFields={formFields}
            />
            }
            <input
                type='hidden'
                name='contractingBody.tendersRequest.addressParticipationType'
                id='contractingBody.tendersRequest.addressParticipationType'
                value={formFields?.notMapped?.postTo || ''}
            />
            <div className='formElementsDivider'></div>
        </>}
        <LabelledCheckBox id={'notMapped.electronic.communication'}
            breakColumn={true}
            options={[{
                id: 'notMapped.electronic.communication',
                value: true,
                label: 'Electronic communication requires the use of tools and devices that are not generally available.',
                checked: formFields?.notMapped?.electronic?.communication === 'true',
                name: 'notMapped.electronic.communication',
            }]}
            onChange={(e) => handleChanges(e)}
            renderAsRow={true}
            required={false}
        />
        {formFields?.notMapped?.electronic?.communication === 'true'
        && <InputComponent
            id={'contractingBody.urlTool'}
            label='URL'
            value={formFields?.contractingBody?.urlTool || ''}
            type='text'
            pattern={'(https|http|ftp|ftps|sftp)?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}'}
            title='Web address must begin with www., http://, https://, ftp://, ftps:// or sftp://'
            required={false}
            onChange={handleChanges}
        />}
        <h3 className='title-large subHeading'>I.4. Type of the contracting authority</h3>
        <RadioButtonComponent
            id='contractingBody.caType'
            label=''
            value={formFields?.contractingBody?.caType}
            required={true}
            onChange={handleChanges}
            renderAsRow={false}
            options={{
                MINISTRY: 'Ministry or any other national or federal authority, including their regional or local subdivisions',
                NATIONAL_AGENCY: 'National or federal agency/office',
                REGIONAL_AUTHORITY: 'Regional or local authority',
                REGIONAL_AGENCY: 'Regional or local agency/office',
                BODY_PUBLIC: 'Body governed by public law',
                OTHER: 'Other type',
            }}
        />
        {formFields?.contractingBody?.caType === 'OTHER'
        && <LabelledTextArea
            id={'contractingBody.caTypeOther'}
            label='Please enter details here'
            value={formFields?.contractingBody?.caTypeOther || ''}
            type='textarea'
            required={false}
            onChange={handleChanges}
        />}
        <h3 className='title-large subHeading'>I.5. Main activity</h3>
        <RadioButtonComponent
            id='contractingBody.caActivity'
            label=''
            value={formFields?.contractingBody?.caActivity}
            required={true}
            onChange={handleChanges}
            renderAsRow={false}
            options={{
                GENERAL_PUBLIC_SERVICES: 'General public services',
                DEFENCE: 'Defence',
                PUBLIC_ORDER_AND_SAFETY: 'Public order and safety',
                ENVIRONMENT: 'Environment',
                ECONOMIC_AND_FINANCIAL_AFFAIRS: 'Economic and financial affairs',
                HEALTH: 'Health',
                HOUSING_AND_COMMUNITY_AMENITIES: 'Housing and community amenities',
                SOCIAL_PROTECTION: 'Social protection',
                RECREATION_CULTURE_AND_RELIGION: 'Recreation, culture and religion',
                EDUCATION: 'Education',
                OTHER: 'Other activity',
            }}
        />
        {formFields?.contractingBody?.caActivity === 'OTHER'
        && <LabelledTextArea
            id={'contractingBody.caActivityOther'}
            label='Please enter details here'
            value={formFields?.contractingBody?.caActivityOther || ''}
            type='textarea'
            required={false}
            onChange={handleChanges}
        />}
        {formFields?.notMapped?.jointProcurement === 'true'
        // eslint-disable-next-line no-undef
        && _.times(formFields?.notMapped?.numberOfContractingAuthorities, (index) => (
            <>
                <h3 className='title-large subHeading'>Section I.1. Additional contracting authority ({index + 1})</h3>
                <FTSNameAndAddresses
                    prefix={`contractingBody.addressContractingBodyAdditional.${index}`}
                    handleChange={handleChanges}
                    formFields={formFields}
                    buyerAddressFlag={true}
                />
            </>))
        }
        {// eslint-disable-next-line no-undef
            _.times(formFields?.notMapped?.numberOfAwardedContracts || 1, (index) => (
                <>
                    <h3 className='title-large subHeading'>Section II. Object ({index + 1})</h3>
                    <FTSContracts
                        prefix={`objectContracts.${index}`}
                        noticeEvent={noticeEvent}
                        handleChange={handleChanges}
                        formFields={formFields}
                    />
                </>))
        }
        {/* SECTION 3 - Known as lefti in BE */}
        {formFields?.notice?.type === 'PRI_REDUCING_TIME_LIMITS'
        && <>
            <h3 className='title-large heading'>Section III. Legal, economic, financial and technical information</h3>
            <h3 className='title-large subHeading'>III.1. Conditions for participation</h3>
            <h3 className='title-large subHeading'>III.1.1. Suitability to pursue the professional activity, including requirements relating to enrolment on professional or trade registers</h3>
            <LabelledTextArea
                id='lefti.suitability'
                breakColumn={true}
                label='List and brief description of conditions'
                value={formFields?.lefti?.suitability || ''}
                maxCharCount={10000}
                onChange={(e) => handleChanges(e)}
                required={false}
            />
            <h3 className='title-large subHeading'>III.1.2. Economic and financial standing</h3>
            <LabelledCheckBox
                id={'lefti.economicCriteriaDoc'}
                breakColumn={true}
                options={[{
                    id: 'lefti.economicCriteriaDoc',
                    value: true,
                    label: 'Selection criteria as stated in the procurement documents ',
                    checked: formFields?.lefti?.economicCriteriaDoc === 'true',
                    name: 'lefti.economicCriteriaDoc',
                }]}
                onChange={(e) => handleChanges(e)}
                renderAsRow={true}
                required={false}
            />
            <LabelledTextArea
                id='lefti.economicFinancialInfo'
                breakColumn={true}
                label='List and brief description of selection criteria'
                value={formFields?.lefti?.economicFinancialInfo || ''}
                maxCharCount={3000}
                onChange={(e) => handleChanges(e)}
                required={false}
            />
            <LabelledTextArea
                id='lefti.economicFinancialMinLevel'
                breakColumn={true}
                label='Minimum level(s) of standards possibly required'
                value={formFields?.lefti?.economicFinancialMinLevel || ''}
                maxCharCount={3000}
                onChange={(e) => handleChanges(e)}
                required={false}
            />
            {formFields?.lefti?.economicCriteriaDoc
            && <>
                <h3 className='title-large subHeading'>III.1.3. Technical and professional ability</h3>
                <LabelledCheckBox
                    id={'lefti.technicalCriteriaDoc'}
                    breakColumn={true}
                    options={[{
                        id: 'lefti.technicalCriteriaDoc',
                        value: true,
                        label: 'Selection criteria as stated in the procurement documents',
                        checked: formFields?.lefti?.technicalCriteriaDoc === 'true',
                        name: 'lefti.technicalCriteriaDoc',
                    }]}
                    onChange={(e) => handleChanges(e)}
                    renderAsRow={true}
                    required={false}
                />
                <LabelledTextArea
                    id='lefti.technicalProfessionalInfo'
                    breakColumn={true}
                    label='List and brief description of selection criteria'
                    value={formFields?.lefti?.technicalProfessionalInfo || ''}
                    maxCharCount={3000}
                    onChange={(e) => handleChanges(e)}
                    required={false}
                />
                <LabelledTextArea
                    id='lefti.technicalProfessionalMinLevel'
                    breakColumn={true}
                    label='Minimum level(s) of standards possibly required'
                    value={formFields?.lefti?.technicalProfessionalMinLevel || ''}
                    maxCharCount={3000}
                    onChange={(e) => handleChanges(e)}
                    required={false}
                />
            </>}
            <h3 className='title-large subHeading'>III.1.5. Information about reserved contracts</h3>
            <LabelledCheckBox
                id={'lefti.restrictedShelteredWorkshop'}
                breakColumn={true}
                options={[{
                    id: 'lefti.restrictedShelteredWorkshop',
                    value: true,
                    label: 'The contract is reserved to sheltered workshops and economic operators aiming at the social and professional integration of disabled or disadvantaged persons',
                    checked: formFields?.lefti?.restrictedShelteredWorkshop === 'true',
                    name: 'lefti.restrictedShelteredWorkshop',
                }]}
                onChange={(e) => handleChanges(e)}
                renderAsRow={true}
                required={false}
            />
            <LabelledCheckBox
                id={'lefti.restrictedShelteredProgram'}
                breakColumn={true}
                options={[{
                    id: 'lefti.restrictedShelteredProgram',
                    value: true,
                    label: 'The execution of the contract is restricted to the framework of sheltered employment programme',
                    checked: formFields?.lefti?.restrictedShelteredProgram === 'true',
                    name: 'lefti.restrictedShelteredProgram',
                }]}
                onChange={(e) => handleChanges(e)}
                renderAsRow={true}
                required={false}
            />
            <h3 className='title-large subHeading'>III.2. Conditions related to the contract</h3>
            <h3 className='title-large subHeading'>III.2.1. Information about a particular profession</h3>
            <LabelledCheckBox
                id={'lefti.particularProfession'}
                breakColumn={true}
                options={[{
                    id: 'lefti.particularProfession',
                    value: true,
                    label: 'Execution of the service is reserved to a particular profession',
                    checked: formFields?.lefti?.particularProfession,
                    name: 'lefti.particularProfession',
                }]}
                onChange={(e) => handleChanges(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.lefti?.particularProfession
            && <LabelledTextArea
                id='lefti.referenceToLaw'
                breakColumn={true}
                label='Reference to the relevant law, regulation or administrative provision'
                value={formFields?.lefti?.referenceToLaw || ''}
                maxCharCount={3000}
                onChange={(e) => handleChanges(e)}
                required={false}
            />}
            <h3 className='title-large subHeading'>III.2.2. Contract performance conditions</h3>
            <LabelledTextArea
                id='lefti.performanceConditions'
                breakColumn={true}
                label='Contract performance conditions '
                value={formFields?.lefti?.performanceConditions || ''}
                maxCharCount={3000}
                onChange={(e) => handleChanges(e)}
                required={false}
            />
            <h3 className='title-large subHeading'>III.2.3. Information about staff responsible for the performance of the contract</h3>
            <LabelledCheckBox
                id={'lefti.performanceStaffQualifications'}
                breakColumn={true}
                options={[{
                    id: 'lefti.performanceStaffQualifications',
                    value: true,
                    label: 'Obligation to indicate the names and professional qualifications of the staff assigned to performing the contract',
                    checked: formFields?.lefti?.performanceStaffQualifications,
                    name: 'lefti.performanceStaffQualifications',
                }]}
                onChange={(e) => handleChanges(e)}
                renderAsRow={true}
                required={false}
            />
        </>}
        <h3 className='title-large subHeading'>Section IV. Procedure</h3>
        <h3 className='title-large subHeading'>IV.1. Description</h3>
        {formFields?.notice?.type === 'PRI_CALL_COMPETITION'
        && <>
            <h3 className='title-large subHeading'>IV.1.3. Information about a framework agreement or a dynamic purchasing system</h3>
            <LabelledCheckBox
                id={'procedure.dpsPurchasers'}
                breakColumn={true}
                options={[{
                    id: 'procedure.dpsPurchasers',
                    value: true,
                    label: 'The procurement involves the establishment of a framework agreement',
                    checked: formFields?.procedure?.dpsPurchasers,
                    name: 'procedure.dpsPurchasers',
                }]}
                onChange={(e) => handleChanges(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.procedure?.dpsPurchasers
            && <>
                <h3 className='title-large subHeading'>How many operators will be included in the framework agreement?</h3>
                <RadioButtonComponent
                    id='procedure.framework.nbParticipants'
                    label=''
                    value={formFields?.procedure?.framework?.nbParticipants}
                    required={true}
                    onChange={handleChanges}
                    renderAsRow={false}
                    options={{
                        single: 'Framework agreement with a single operator',
                        multiple: 'Framework agreement with several operators',
                    }}
                />
                <LabelledTextArea
                    id='notMapped.procedure.frameworkAgreement.justification'
                    breakColumn={true}
                    label='In the case of framework agreements, provide justification for any duration exceeding 4 years (optional)'
                    value={formFields?.notMapped?.procedure?.frameworkAgreement?.justification || ''}
                    maxCharCount={3000}
                    onChange={(e) => handleChanges(e)}
                />
            </>}
            <h3 className='title-large subHeading'>IV.1.6. Information about electronic auction</h3>
            <LabelledCheckBox id={'procedure.eauction'}
                breakColumn={true}
                options={[{
                    id: 'procedure.eauction',
                    value: true,
                    label: 'Information about electronic auction',
                    checked: formFields?.procedure?.eauction,
                    name: 'procedure.eauction',
                }]}
                onChange={(e) => handleChanges(e)}
                renderAsRow={true}
                required={false}
            />
            {formFields?.procedure?.eauction
            && <LabelledTextArea
                id='procedure.infoAddEauction'
                breakColumn={true}
                label='Additional information about electronic auction'
                value={formFields?.procedure?.infoAddEauction || ''}
                maxCharCount={3000}
                onChange={(e) => handleChanges(e)}
            />}
        </>}
        <h3 className='title-large subHeading'>IV.1.8. Information about the Government Procurement Agreement (GPA)</h3>
        <RadioButtonComponent
            id='procedure.contractCoveredGPA'
            label='The procurement is covered by the Government Procurement Agreement'
            value={formFields?.procedure?.contractCoveredGPA}
            required={true}
            onChange={handleChanges}
            renderAsRow={false}
            options={{
                true: 'Yes',
                false: 'No',
            }}
        />
        <h3 className='title-large subHeading'>IV.2. Administrative information</h3>
        <h3 className='title-large subHeading'>IV.2.1. Previous publication concerning this procedure</h3>
        <InputComponent
            id={'procedure.noticeNumberOJ'}
            label='Search for your notice'
            value={formFields?.procedure?.noticeNumberOJ}
            type='text'
            onChange={handleChanges}
            required={false}
        />
        {formFields?.notice?.type === 'PRI_CALL_COMPETITION'
        && <>
            <h3 className='title-large subHeading'>IV.2.2. Time limit for receipt of expressions of interest</h3>
            {/* This is seperated into two fields on the backend -
            DateReceiptTenders && TimeReceiptTenders. We will need to
            split this before sending payload */}
            <DatePickerComponent
                id='procedure.receiptTenders.dateReceiptTenders'
                label='Time limit for receipt of expressions of interest'
                value={formFields?.procedure?.receiptTenders?.dateReceiptTenders}
                onChange={handleChanges}
                minDateFlag={false}
                required={true}
            />
            <h3 className='title-large subHeading'>Local Time</h3>
            <TimePicker
                clockIcon={null}
                className='customDateInput timeReceipt'
                id='procedure.receiptTenders.timeReceiptTenders'
                clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                hourPlaceholder='HH'
                minutePlaceholder='mm'
                format={'HH:mm'}
                locale='en-gb'
                minTime={'00:00'}
                disableClock={true}
                value={formFields?.procedure?.receiptTenders?.timeReceiptTenders}
                onChange={(time) => setFormFields({
                    ...formFields,
                    procedure: {
                        ...formFields.procedure,
                        receiptTenders: {
                            ...formFields.procedure.receiptTenders,
                            timeReceiptTenders: time,
                        },
                    },
                })}
            />
            <input
                type='hidden'
                name='procedure.receiptTenders.timeReceiptTenders'
                id='procedure.receiptTenders.timeReceiptTenders'
                value={formFields?.procedure?.receiptTenders?.timeReceiptTenders || ''}
            />
            <h3 className='title-large subHeading'>IV.2.4. Languages in which tenders or requests to participate may be submitted</h3>

            <SelectSearchComponent
                id='notMapped.procedure.languages'
                label='Language'
                value={formFields?.notMapped?.procedure?.languages}
                onChange={(e) => handleChanges(e, 'notMapped.procedure.languages')}
                required={true}
                options={[
                    { EN: 'English' },
                    { DE: 'German' },
                ]}
                optionInputType='obj'
            />
            <input
                type='hidden'
                name='procedure.languages'
                id='procedure.languages'
                value={formFields?.notMapped?.procedure?.languages}
            />
            <h3 className='title-large subHeading'>IV.2.5. Scheduled date for start of award procedures</h3>
            <DatePickerComponent
                id='procedure.dateAwardScheduled'
                label='Scheduled date for start of award procedures'
                value={formFields?.procedure?.dateAwardScheduled}
                onChange={handleChanges}
                minDateFlag={false}
                required={false}
            />
        </>}
        <h3 className='title-large subHeading'>Section VI. Complementary information</h3>
        <h3 className='title-large subHeading'>VI.2. Information about electronic workflows</h3>
        {formFields?.notice?.type === 'PRI_REDUCING_TIME_LIMITS'
            && <>
                <LabelledCheckBox
                    id={'complementaryInfo.eordering'}
                    breakColumn={true}
                    options={[{
                        id: 'complementaryInfo.eordering',
                        value: true,
                        label: 'Electronic ordering will be used',
                        checked: formFields?.complementaryInfo?.eordering === 'true',
                        name: 'complementaryInfo.eordering',
                    }]}
                    onChange={(e) => handleChanges(e)}
                    renderAsRow={true}
                    required={false}
                />
                <LabelledCheckBox
                    id={'complementaryInfo.einvoicing'}
                    breakColumn={true}
                    options={[{
                        id: 'complementaryInfo.einvoicing',
                        value: true,
                        label: 'Electronic invoicing will be accepted',
                        checked: formFields?.complementaryInfo?.einvoicing === 'true',
                        name: 'complementaryInfo.einvoicing',
                    }]}
                    onChange={(e) => handleChanges(e)}
                    renderAsRow={true}
                    required={false}
                />
                <LabelledCheckBox
                    id={'complementaryInf.epayment'}
                    breakColumn={true}
                    options={[{
                        id: 'complementaryInfo.epayment',
                        value: true,
                        label: 'Electronic payment will be used',
                        checked: formFields?.complementaryInfo?.epayment === 'true',
                        name: 'complementaryInfo.epayment',
                    }]}
                    onChange={(e) => handleChanges(e)}
                    renderAsRow={true}
                    required={false}
                />
            </>}
        <h3 className='title-large subHeading'>VI.3. Additional information</h3>
        <LabelledTextArea
            id='complementaryInfo.infoAdd'
            breakColumn={true}
            label='Additional information'
            value={formFields?.complementaryInfo?.infoAdd || ''}
            maxCharCount={10000}
            onChange={(e) => handleChanges(e)}
            required={false}
        />
        {formFields?.notice?.type === 'PRI_REDUCING_TIME_LIMITS' || formFields?.notice?.type === 'PRI_CALL_COMPETITION'
            ? <>
                <h3 className='title-large subHeading'>VI.4. Procedures for review</h3>
                <h3 className='title-large subHeading'>VI.4.1. Review body</h3>
                <FTSNameAndAddresses
                    prefix={'complementaryInfo.addressReviewBody'}
                    handleChange={handleChanges}
                    formFields={formFields}
                    registerFlag={false}
                    contactPointFlag={false}
                    buyerAddressFlag={false}
                    nationalIDFlag={false}
                    internetAddressNameUpdateFlag={true}
                    nutsCodeFlag={false}
                />
                <h3 className='title-large subHeading'>VI.4.2. Body responsible for mediation procedures</h3>
                <LabelledCheckBox
                    id={'notMapped.complementaryInfo.mediationProcedure'}
                    breakColumn={true}
                    options={[{
                        id: 'notMapped.complementaryInfo.mediationProcedure',
                        value: true,
                        label: 'There is a body responsible for mediation procedures',
                        checked: formFields?.notMapped?.complementaryInfo?.mediationProcedure === 'true',
                        name: 'notMapped.complementaryInfo.mediationProcedure',
                    }]}
                    onChange={(e) => handleChanges(e)}
                    renderAsRow={true}
                    required={false}
                />
                {formFields?.notMapped?.complementaryInfo?.mediationProcedure === 'true'
                && <FTSNameAndAddresses
                    prefix={'complementaryInfo.addressMediationBody'}
                    handleChange={handleChanges}
                    formFields={formFields}
                    registerFlag={false}
                    contactPointFlag={false}
                    buyerAddressFlag={false}
                    nationalIDFlag={false}
                    internetAddressNameUpdateFlag={true}
                    nutsCodeFlag={false}
                />}
                <h3 className='title-large subHeading'>V1.4.3 Review Procedure</h3>
                <LabelledTextArea
                    id='complementaryInfo.reviewProcedure'
                    breakColumn={true}
                    label='Precise information on deadline(s) for review procedures'
                    value={formFields?.complementaryInfo?.reviewProcedure || ''}
                    maxCharCount={3000}
                    onChange={(e) => handleChanges(e)}
                    required={false}
                />
                <h3 className='title-large subHeading'>VI.4.4. Service from which information about the review procedure may be obtained</h3>
                <LabelledCheckBox
                    id={'notMapped.complementaryInfo.reviewProcedureCheckbox'}
                    breakColumn={true}
                    options={[{
                        id: 'notMapped.complementaryInfo.reviewProcedureCheckbox',
                        value: true,
                        label: 'There is a service from which information about the review procedure may be obtained',
                        checked: formFields?.notMapped?.complementaryInfo?.reviewProcedureCheckbox === 'true',
                        name: 'notMapped.complementaryInfo.reviewProcedureCheckbox',
                    }]}
                    onChange={(e) => handleChanges(e)}
                    renderAsRow={true}
                    required={false}
                />
                {formFields?.notMapped?.complementaryInfo?.reviewProcedureCheckbox
                && <>
                    <FTSNameAndAddresses
                        prefix={'complementaryInfo.addressReviewInfo'}
                        handleChange={handleChanges}
                        formFields={formFields}
                        registerFlag={false}
                        contactPointFlag={false}
                        buyerAddressFlag={false}
                        nationalIDFlag={false}
                        internetAddressNameUpdateFlag={true}
                        nutsCodeFlag={false}
                    />
                </>}
            </>
            : <></>}
        <div className='vpnItemButtonGroup'>
            <Button
                id='cancel'
                label='Cancel'
                variant='secondary'
                handleClick={() => history.goBack() }
                disabled={false}
            />
            <div className='buttonDivider'></div>
            <Button
                id='submit'
                type={'submit'}
                value='Create'
                label='Create'
                breakColumn={false}
            />
        </div>
    </form>);
};

FTSF01NoticeForm.propTypes = {
    project: PropTypes.object,
    noticeEvent: PropTypes.object,
};

export default FTSF01NoticeForm;
