import React from 'react';

const getHashlink = (sectionId) => {
    if (!sectionId) return '';
    return `#${sectionId}`;
};

const getContentMap = (params, context) => {
    const contextInMap = (params.context || context).toLowerCase();
    const generator = params.contentMap[contextInMap];
    if (!generator) return undefined;
    return generator(params);
};


const getAccordionTitleHtml = (header, link, handleClick, params) => {
    if (!header) return <></>;
    const accordionHeader = (
        <>
            {params.data?.referenceValue
                && <span className='headerReferenceValue'>{params.data.referenceValue}</span>}
            {header.prevImg && header.prevImg.showOnlyIf && header.prevImg.showOnlyIf.value
            && <> {header.prevImg.value}</>}
            <div className='title-subtitle'>
                <label>{header.title}</label>
                {header.subtitle && <div>{header.subtitle}</div>}
            </div>
            {header.columns?.map((column, index) => {
                if (column.showOnlyIf && !column.showOnlyIf.value) {
                    return null;
                }
                return (
                    <div key={`col-${index}`}>
                        {column.label && <p className='label'>{column.label}: </p>}
                        <p className='value'>{column.value}</p>
                    </div>
                );
            })}
            {header.extra && <>{header.extra}</>}
            {header.pills
            && <div className='Mui-pills'>{header.pills.map((pill, index) => {
                if (pill.showOnlyIf && !pill.showOnlyIf.value) {
                    return null;
                }

                return (
                    <p key={`pill-${index}`} className={pill.className}>
                        {pill.value}
                    </p>
                );
            })}</div>
            }
        </>
    );
    const card = link || handleClick ? (
        <a href={link || getHashlink(params.sectionId)} onClick={handleClick} className='Mui-acc-link'>
            {accordionHeader}
        </a>
    ) : accordionHeader;
    return card;
};

const getRowMap = (row, index) => row.map((column, colIndex) => {
    if (column.showOnlyIf && !column.showOnlyIf.value) {
        return null;
    }

    return (
        <div key={`col-${index}-${colIndex}`}>
            {column.label && <p className='label'>{column.label}: </p>}
            {column.value}
        </div>
    );
});

const getAccordionContentHtml = (content, params) => {
    if (!content) return <></>;
    const accordionContent = (
        <>
            {content.tags
            && <div className='Mui-acc-tags'> {content.tags.map((tag, index) => (
                <p key={`tag-${index}`} className={tag.className}>
                    {tag.value}
                </p>
            ))}</div>}
            {content.rows?.map((row, index) => (
                <div key={`row-${index}`} className='Mui-acc-row'>
                    {getRowMap(row, index)}
                </div>
            ))}
            {content.grid
                && <div className='Mui-acc-grid'>
                    {content.grid.left && <div className='grid-col'>{getRowMap(content.grid.left, 1)}</div>}
                    {content.grid.right && <div className='grid-col'>{getRowMap(content.grid.right, 2)}</div>}
                </div>
            }
        </>
    );
    const card = content.link || content.handleClick ? (
        <a href={content.link ? content.link : getHashlink(params.sectionId)} onClick={content.handleClick} className='Mui-acc-link'>
            {accordionContent}
        </a>
    ) : accordionContent;

    return <>{card } {content.extra && <div key={`row-${content.rows?.length}`} className='Mui-extra'>{content.extra}</div>}</>;
};

const getAccordionTitle = (params, context) => {
    const content = getContentMap(params, context);
    const { link, handleClick, header } = content;
    return getAccordionTitleHtml(header, link, handleClick, params);
};
const getAccordionContent = (params, context) => {
    const content = getContentMap(params, context);
    return getAccordionContentHtml(content, params);
};


export { getAccordionTitle, getAccordionContent };
