const Constants = {
    sortOptions: [
        null,
        { label: 'Created on (newest)', value: 'createdOn-descending-dateTime' },
        { label: 'Estimated value (highest first)', value: 'estimatedValue-descending-number' },
        { label: 'Estimated value (lowest first)', value: 'estimatedValue-ascending-number' },
    ],
    tabOptions: [
        { label: 'My Projects', value: 'createdBy', url: 'createdBy' },
        { label: 'My Department', value: 'department', url: 'department' },
        { label: 'My Organisation', value: 'all', url: 'organisation' },
        { label: 'All Organisations', value: 'nepo', url: 'nepo' },
    ],
    UNABLE_TO_RETRIEVE_PROJECTS: 'Unable to retrieve projects',
    SEARCH_FOR_A_PROJECT: 'Search for a project',
    ADD: 'Add',
};

export default Constants;
