import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'react-date-picker';

import clearIcon from '../../../styles/icons/blue/clear.svg';

const DatePickerComponent = ({
    id,
    label,
    value,
    noticeType,
    permittedNoticeTypes = [],
    onChange,
    minDateFlag = true,
    ...props
}) => (
    (!noticeType || (noticeType && permittedNoticeTypes.includes(noticeType)))
    && <div className='vpnItem'>
        <div className='noticeDatePicker'>
            <label className='form-label title datePickerLabel'>
                {label}
            </label>

            <DatePicker
                calendarIcon={null}
                className='customDateInput nmiDate-imput'
                clearIcon={<img className='clearIcon' alt='clear' src={clearIcon}></img>}
                dayPlaceholder='DD'
                format={'dd-MM-y'}
                minDate={minDateFlag ? new Date() : ''}
                monthPlaceholder='MM'
                name={id}
                value={value ? new Date(value) : undefined}
                yearPlaceholder='YYYY'
                onChange={(e) => onChange(e, id)}
                { ... props }
            />
        </div>
    </div>
);

DatePickerComponent.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
    noticeType: PropTypes.string,
    permittedNoticeTypes: PropTypes.array,
    minDateFlag: PropTypes.bool,
};

export default DatePickerComponent;
