import instance from '../config/axios.instance';
import endpoints from '../config/endpoints';

const { documentManagement } = endpoints;

const documentManagementAPIs = {
    getAllByAccount: async (accountId) => {
        try {
            const endpoint = documentManagement.getAllByAccount;
            const response = await instance.get(`${endpoint}/${accountId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listTemplates: async (tabSelected) => {
        try {
            const endpoint = documentManagement.template;
            const response = await instance.get(`${endpoint}/browse/all/${tabSelected}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createTemplate: async (payLoad, createNewVersion) => {
        try {
            const endpoint = createNewVersion
                ? `${documentManagement.template}/create/false`
                : `${documentManagement.template}/create`;
            const response = await instance.post(endpoint, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateTemplate: async (payLoad, templateID) => {
        try {
            const endpoint = documentManagement.template;
            const response = await instance.put(`${endpoint}/${templateID}`, payLoad);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getTemplate: async (templateId) => {
        try {
            const endpoint = documentManagement.template;
            const response = await instance.get(`${endpoint}/get/${templateId}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    disableTemplate: async (templateID, payload) => {
        try {
            const endpoint = documentManagement.template;
            const response = await instance.put(`${endpoint}/disable/${templateID}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getByDocumentID: async (documentID) => {
        try {
            const endpoint = documentManagement.getByDocumentID;
            const response = await instance.get(`${endpoint}/${documentID}`, { responseType: 'blob' });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getSignedUrl: async (documentID, attachedToId, attachedToType) => {
        try {
            const endpoint = documentManagement.getSignedUrl;
            const response = (attachedToId && attachedToType)
                ? await instance.get(`${endpoint}/${documentID}/${attachedToId}/${attachedToType}`)
                : await instance.get(`${endpoint}/${documentID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllByDocumentID: async (documentIDs, attachedToId, attachedToType) => {
        try {
            const documents = documentIDs.join(',');
            const endpoint = documentManagement.getAllByDocumentID;
            const response = (attachedToId && attachedToType)
                ? await instance.get(`${endpoint}/${documents}/${attachedToId}/${attachedToType}`, { responseType: 'blob' })
                : await instance.get(`${endpoint}/${documents}`, { responseType: 'blob' });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getByDocumentIDGateway: async (documentID) => {
        try {
            const endpoint = documentManagement.getByDocumentIDGateway;
            const response = await instance.get(`${endpoint}/${documentID}`, { responseType: 'blob' });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getAllByDocumentIDInSubfolders: async (folders) => {
        try {
            const firstIdMatch = window.location.pathname.match(/\/([a-f0-9\-]{36})\//);
            const projectID = firstIdMatch ? firstIdMatch[1] : null;

            const endpoint = documentManagement.getAllByDocumentIDInSubfolders;
            const response = await instance.post(`${endpoint}`, { folders, projectID }, { responseType: 'blob' });
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getDocumentByType: async (type) => {
        try {
            const endpoint = documentManagement.getDocumentByType;
            const response = await instance.get(`${endpoint}/${type}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    deleteDocument: async (ID) => {
        try {
            const endpoint = documentManagement.deleteDocument;
            const response = await instance.delete(`${endpoint}/${ID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getSection: async (ID) => {
        try {
            const endpoint = documentManagement.getSection;
            const response = await instance.get(`${endpoint}/${ID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    createSection: async (payload) => {
        try {
            const endpoint = documentManagement.createSection;
            const response = await instance.post(`${endpoint}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    updateSection: async (id, payload) => {
        try {
            const endpoint = documentManagement.getSection;
            const response = await instance.put(`${endpoint}/${id}`, payload);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    listSections: async () => {
        try {
            const endpoint = documentManagement.listSections;
            const response = await instance.get(`${endpoint}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getDefaultTemplate: async () => {
        try {
            const endpoint = documentManagement.template;
            const response = await instance.get(`${endpoint}/get/default`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },

    getSuppliersDefaultTemplate: async (supplierID) => {
        try {
            const endpoint = documentManagement.template;
            const response = await instance.get(`${endpoint}/get/default/suppliersq/${supplierID}`);
            return response;
        } catch (e) {
            return e.response || e;
        }
    },
};

export default documentManagementAPIs;
