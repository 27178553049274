import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parseISO, isPast } from 'date-fns';

import projectManagementAPIs from '../../services/project-management.service';
import Toast from '../../components/Alerts/Toast/Toast';
import {
    getInitialPage,
    handleLotSubmit,
    getAwaitingRefreshResponses,
    getResponsesMenu,
    getPreviousResponsesMenu,
    getPreviousLotMenu,
    getLotStatusPill,
    getPreviousSubmissionsMenu,
} from './helpers';
import TreeViewer from '../../components/TreeViewer/TreeViewer';
import ExportEditBanner from './components/ExportEditBanner';
import AnswerSummaryModal from '../../components/AnswerSummaryModal';
import GenerateBanner from './components/GenerateBanner';
import GenerateRefreshGuidance from './components/GenerateRefreshGuidance';
import dpsConstants from './dpsConstants';
import preparePreviousSubmissionsTree from '../../services/previous-submissions.service';
import { prepareCurrentSubmissionsTree } from '../../services/current-submissions.service';
import useGetOpportunity from '../../hooks/useGetOpportunity';


const AnswerSummaryDPS = () => {
    const [data, setData] = useState('');
    const [selectLotsModal, setSelectLotsModal] = useState(false);
    const [selectedLots, setSelectedLots] = useState([]);
    const [questionnaireAwaitingRefresh, setQuestionnaireAwaitingRefresh] = useState([]);
    const [currentResponses, setCurrentResponses] = useState([]);
    const [oldResponses, setOldResponses] = useState([]);
    const [withAward, setWithAward] = useState(false);
    const { projectId, eventId } = useParams();
    const { getOpportunity } = useGetOpportunity();

    const getResponsesHistory = async () => {
        try {
            const response = await projectManagementAPIs.getResponsesHistory(projectId, eventId);
            if (response.status === 200) {
                setOldResponses(Object.keys(response.data).length
                    ? preparePreviousSubmissionsTree(response.data,
                        getPreviousResponsesMenu, getPreviousLotMenu,
                        projectId, eventId, getPreviousSubmissionsMenu)
                    : undefined);
            } else {
                Toast.fire({
                    icon: dpsConstants.errorIcon,
                    titleText: dpsConstants.retrieveInfoError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: dpsConstants.errorIcon,
                titleText: dpsConstants.retrieveInfoError,
            });
        }
    };

    const getAnswerSummaryDPSResponse = async () => {
        try {
            const response = await projectManagementAPIs.getDPSAnswerSummary(projectId, eventId);
            if (response.status === 200) {
                const { projectsOrLots, publishLevel, lotSelected } = response.data;
                const quesAwaitingRefresh = getAwaitingRefreshResponses(projectsOrLots);

                if (publishLevel === dpsConstants.lotPublishLevel
                    && !response.data.withAward && lotSelected) {
                    setSelectedLots(Object.entries(projectsOrLots).map(
                        ([key]) => key,
                    ));
                }

                setData(response.data);
                setWithAward(response.data.withAward);
                setQuestionnaireAwaitingRefresh(getAwaitingRefreshResponses(projectsOrLots));
                setCurrentResponses(JSON.stringify(response.data) !== '{}' ? prepareCurrentSubmissionsTree(response.data, getResponsesMenu, quesAwaitingRefresh) : undefined);
            } else {
                Toast.fire({
                    icon: dpsConstants.errorIcon,
                    titleText: dpsConstants.retrieveInfoError,
                });
            }
        } catch (err) {
            Toast.fire({
                icon: dpsConstants.errorIcon,
                titleText: dpsConstants.retrieveInfoError,
            });
        }
    };

    useEffect(() => {
        getResponsesHistory();
        getAnswerSummaryDPSResponse();
        getOpportunity(projectId);
    }, []);

    return (
        <div id='answerSummaryContainer'>
            <div id='answerSummaryBanner'>
                {<GenerateBanner
                    submissionsData={data}
                    questionnaireAwaitingRefresh={questionnaireAwaitingRefresh}
                    projectId={projectId}
                    eventId={eventId}
                    getAnswerSummaryDPSResponse={getAnswerSummaryDPSResponse}
                    selectedLots={selectedLots}
                />}
            </div>

            {!selectedLots.length
                ? getInitialPage(currentResponses, setSelectLotsModal)
                : (
                    <>
                        {data.publishLevel === dpsConstants.lotPublishLevel
                    && <div className='answerSummaryContentContainer' >
                        <ExportEditBanner
                            submissionsData={data}
                            questionnaireAwaitingRefresh={questionnaireAwaitingRefresh}
                            projectId={projectId}
                            setSelectLotsModal={setSelectLotsModal}
                        />
                        {!isPast(parseISO(data.submissionEndDate))
                        && <GenerateRefreshGuidance
                            submissionsData={data}
                            questionnaireAwaitingRefresh={questionnaireAwaitingRefresh}
                            projectId={projectId}
                            eventId={eventId}
                            getAnswerSummaryDPSResponse={getAnswerSummaryDPSResponse}
                        />}
                    </div>
                        }

                        {currentResponses
                    && <TreeViewer classAdded='current-responses-tree' data={currentResponses} hasSearch={false}/>
                        }

                        {oldResponses
                            && <TreeViewer classAdded='previous-responses-tree' data={oldResponses} hasSearch={false}/>
                        }
                    </>
                )
            }

            {selectLotsModal
            && <AnswerSummaryModal
                closeModal={() => {
                    setSelectLotsModal(false);
                }}
                openModal={selectLotsModal}
                handleSubmit={(lotList) => handleLotSubmit(
                    lotList, selectedLots, withAward, projectId,
                    eventId, getAnswerSummaryDPSResponse, setSelectLotsModal, getResponsesHistory,
                )}
                selectedLotsData={selectedLots}
                intentStatuses={data.mapIntentStatuses}
                getLotStatus={(lotID) => data.projectsOrLots && getLotStatusPill(
                    lotID, data.mapIntentStatuses,
                )}
            />}
        </div>
    );
};

export default AnswerSummaryDPS;
