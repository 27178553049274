import React, {
    useState, useContext,
} from 'react';

import coverImage from '../../styles/images/ICT-Services_Transparent BKGRD.gif';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Constants from './constants';
import SupplierRegistration from '../../features/SupplierRegistration';
import HomepageBanner from '../../components/HomepageBanner';
import { ThemeContext } from '../../context/ThemeContext';

const generateUserCards = () => {
    const { userCardsData } = Constants;
    const elements = [];
    userCardsData.forEach((data, idx) => {
        elements.push(<Card
            key={`hpCard-${idx}`}
            id={data.id}
            size={'large'}
            header={false}
            footer={false}
            image={true}
            imageSrc={data.image}
            content={<>
                <h2 id={`${data.id}-title`}>{data.title}</h2>
                <p className='body' id={`${data.id}-description`}>{data.description}</p>
            </>}
        />);
    });
    return elements;
};

const Homepage = () => {
    const [showRegModal, setShowRegModal] = useState(false);
    const toggleRegModal = () => {
        setShowRegModal(!showRegModal);
    };
    const { toggle } = useContext(ThemeContext);
    document.title = 'Home - Open | Developed by Nepo';

    return <><main id='hpMainContent' className={`${toggle ? 'new-hp-main-content' : ''}`}>
        <HomepageBanner/>
        <section id='hpWelcomeContainer'>
            <div id='welcomeMsgContainer'>
                <p className='welcome-open'>{Constants.WELCOME_TO_OPEN}</p>
                <h1>{Constants.H1_TITLE_1} <span className='eprocurement-needs'>{Constants.H1_TITLE_2}</span> <strong>{Constants.H1_TITLE_3}</strong></h1>


                <Button id='getStartedBtn'
                    label='Get Started'
                    handleClick={toggleRegModal} />
            </div>
            <div id='welcomeImgContainer'>
                <img src={coverImage} alt='main-cover'></img>
            </div>
        </section>
        <section id='hpInfoContainer'>
            <div id='hpInfoCards'>
                {generateUserCards()}
            </div>
        </section>
    </main>
    {showRegModal && <SupplierRegistration showRegModal={showRegModal}
        closeModal={toggleRegModal} />}
    </>;
};

export default Homepage;
