import {
    addMonths, isAfter, isBefore, isValid,
} from 'date-fns';

const calculateEndDate = (startDate, duration) => addMonths(startDate, parseInt(duration, 10));

const isValidReviewDate = (reviewDate, awardDate, endDate) => {
    if (!reviewDate || (!awardDate && !endDate)) return true;

    const isAfterAward = !isValid(awardDate) || isAfter(reviewDate, awardDate);
    const isBeforeEnd = !isValid(endDate) || isBefore(reviewDate, endDate);

    return isAfterAward && isBeforeEnd;
};

export { calculateEndDate, isValidReviewDate };
