import React from 'react';
import PropTypes from 'prop-types';
import StatusLabel from '../../../components/StatusLabel';
import helperFunctions from '../../../utils/helperFunctions';

const HeaderContent = ({
    id,
    itemName,
    showStatusLabel,
    statusLabel,
    actionLabel,
    statusColor,
    score,
    weightage,
    readOnly,
}) => <>
    <p className='headerTitle title user-icon' >{itemName}</p>
    <div className='sbAccordionSectionHeaderAddon'>
        {actionLabel
        && !readOnly
        && <div className='statuslabel label'>
            <StatusLabel
                id={id}
                color={actionLabel === 'Action Needed' ? 'red' : 'amber'}
                labelTxt={actionLabel}
            />
        </div>}
        {showStatusLabel
        && <div className='statuslabel label' id='sbStatusLabel'>
            <StatusLabel
                id={id}
                color={statusColor}
                labelTxt={helperFunctions.camel2title(statusLabel)}
            />
        </div>}
        {score >= 0 ? <p className='label'>{`Total Score: ${score}/${weightage}`}</p> : <></>}
    </div>
</>;

HeaderContent.propTypes = {
    id: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    showStatusLabel: PropTypes.bool,
    statusLabel: PropTypes.string,
    actionLabel: PropTypes.string,
    statusColor: PropTypes.string,
    score: PropTypes.number,
    weightage: PropTypes.number,
    readOnly: PropTypes.bool,
};

export default HeaderContent;
