const dpsConstants = {
    LOT: 'lot',
    SUBMISSION: 'submission',
    RESPONSE: 'response',
    SUPPLIER: 'supplier',
    LOT_ID: 'lotID',
    ACCOUNT_ID: 'accountID',
    SUBMITTED_AT: 'Submitted at',
    CURRENT_TITLE: 'Current',
    LOT_TITLE: 'Lot',
    SUBMISSION_TITLE: 'Submission',

    labelDisqualifySupplierId: 'disqualifyLabel',
    labelIncompleteEvaluationsId: 'incompleteEvaluations',
    labelResponseScoreId: 'score',
    labelResponsePassFailResultId: 'passFailResult',
    labelResponseStatusId: 'responseStatus',
    labelIntentStatusId: 'intent-status',
    labelSubmissionStatusId: 'submissionStatus',
    labelActionId: 'action',
    labelSubmissionScoreId: 'totalScore',
    labelSubmissionPassFailResultId: 'totalPassFailResult',
    buttonViewEvaluateQuestionnaireId: 'view',
    buttonDisqualifySupplierId: 'disqualifySupplier',
    buttonEvaluateQuestionnaireId: 'evaluate',
    buttonResumeQuestionnaireId: 'resume',
    buttonEditScoreQuestionnaireId: 'editScore',
    buttonSubmitForApprovalId: 'submitForApproval',
    buttonStageRejectId: 'rejectAll',
    buttonStageApproveId: 'approveAll',
    buttonViewReasonId: 'viewReason-Btn',

    EVALUATION_COMPLETE: 'Evaluation Complete',
    DISQUALIFY_SUPPLIER: 'Disqualify Supplier',
    EVALUATE: 'Evaluate',
    RESUME: 'Resume',
    EDIT_SCORE: 'Edit Score',
    SUBMIT_FOR_APPROVAL: 'Submit for Approval',
    REJECT: 'Reject',
    APPROVE: 'Approve',
    VIEW_REASON: 'View Reason',
    DISQUALIFIED: 'Disqualified',
    INCOMPLETE_EVALUATIONS: 'Incomplete Evaluations',
    ACTION_NEEDED: 'Action Needed',
    EVALUATION_PENDING: 'Evaluation Pending',
    APPROVAL_PENDING: 'Approval Pending',
    STAGE_1_APPROVAL_PENDING: 'Stage 1 Approval Pending',
    STAGE_2_APPROVAL_PENDING: 'Stage 2 Approval Pending',

    ACTION_REJECTED: 'rejected',
    ACTION_S1_APPROVED: 's1approved',
    ACTION_S2_APPROVED: 's2approved',

    UNABLE_TO_START_EVALUATION: 'Unable to start evaluation',
    COMPANY_NAME: 'companyName',
    USER: 'User',
    USER_ICON: 'userIcon',

};

export default dpsConstants;
