/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import propTypes from 'prop-types';
import Button from '../../components/Button';
import ConfirmModal from '../ConfirmModal';
import ScreenOverlay from '../../components/ScreenOverlay';
import UploadModal from '../UploadModal';
import UploadContractModal from '../UploadContractModal';
import AuthContext from '../../context/AuthContext';

import {
    handleDocumentSubmit,
    handleRemoveDocument,
    handleDownload,
    handleMultipleDownload,
    fileShouldBeDisplayedToUser,
} from '../../utils/uploadHelper';

import helperFunctions from '../../utils/helperFunctions';

/* Pass generic uploadIdentifier,considering it is used as part of Modal Heading, alerts etc. */
const Upload = ({
    title,
    className,
    allowMultiple,
    uploadIdentifier = 'Document',
    questionnaire,
    uploadCallback,
    sectionNumber,
    questionNumber,
    deleteCallback,
    uploadedDocuments,
    readOnly,
    audienceSelectionType = null,
    data = {},
    showExpiryDate = true,
}) => {
    const authContext = useContext(AuthContext);
    const [documents, setDocuments] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [upload, setUpload] = useState(false);
    const [viewDocuments, setViewDocuments] = useState(false);
    const [accessGroups, setAccessGroups] = useState([]);

    const mapUploadedDocuments = () => {
        if (Array.isArray(uploadedDocuments)) {
            return uploadedDocuments
                ? uploadedDocuments.map((doc) => ({
                    ...doc,
                    ...doc.document,
                    ...{
                        documentName: doc.document?.documentName || doc.documentName || doc.title,
                        documentDescription: doc.document?.description || doc.description,
                        expiryDate: doc.document?.expiryDate || doc.expiryDate || '',
                        scanned: doc.document?.scanned || 'Unknown',
                    },
                // Keep the file if it's uploaded by current user or not failed
                })).filter((doc) => fileShouldBeDisplayedToUser(doc, authContext.user))
                : [];
        }

        if (audienceSelectionType === 'contract') {
            const mappedDocs = uploadedDocuments ? Object.keys(uploadedDocuments).map((key) => {
                const docs = Object.values(uploadedDocuments[key]).flat();

                // If we want to group the documents by attached resource e.g. supplier/lot then
                // we will want to remove this or do some more mangling of the array to group the
                // documents
                const filteredDocs = docs.filter((value, index, self) => (
                    index === self.findIndex((t) => (
                        t.documentID === value.documentID
                    ))
                ));
                return filteredDocs.map((doc) => ({
                    ...doc,
                    ...doc.document,
                    ...{
                        documentName: doc.document?.documentName || doc.documentName || doc.title,
                        documentDescription: doc.document?.description || doc.description,
                        expiryDate: doc.document?.expiryDate || doc.expiryDate || '',
                        scanned: doc.document?.scanned || 'Unknown',
                        resourceType: key,
                    },
                }));
            }).flat().filter((doc) => (doc.scanned !== 'Failed')) : [];

            return mappedDocs;
        }

        return [];
    };

    useEffect(() => {
        setDocuments(mapUploadedDocuments);

        // FIXME: Probably want to pass in accessGroups from the component
        setAccessGroups([authContext.user.id]);
    }, [uploadedDocuments, authContext.user.id]);

    const body = (
        <div>
            <p className='caption'>{`Are you sure you want to ${uploadIdentifier} request?`}</p>
        </div>
    );

    const handleConfirm = () => {
        setConfirm(false);
    };

    const handleUpload = (event) => {
        event.preventDefault();
        setUpload(true);
    };

    const handleView = (event) => {
        event.preventDefault();
        setViewDocuments(true);
    };

    const pluralizeText = (count, singular) => (
        `${count || 0} ${helperFunctions.pluralizeText(count || 0, singular)} added`
    );

    return (
        <div className={`uploadContainer ${className || ''}`}>
            <div className='upFormInputContainer'>
                <div className='formLabel'>
                    <p className='title'>{title}</p>
                </div>
                <div className='formCaption'>
                    <p className='caption-small'>
                        {pluralizeText(documents.length, 'attachment')}
                    </p>
                </div>
                <div className='upDocumentButtonContainer remove-on-print'>
                    <Button
                        id={`viewDocument-${uploadIdentifier}`}
                        label = 'View'
                        size='small'
                        variant='secondary'
                        handleClick={(e) => handleView(e)}
                    />
                    {!readOnly && <Button
                        id={`uploadDocument-${uploadIdentifier}`}
                        label = 'Upload'
                        size='small'
                        // disabled={!allowMultiple && !!documents.length}
                        handleClick={(e) => handleUpload(e)}
                    />}
                </div>
                <div className='flex-break'></div>
            </div>

            {confirm && <>
                <ConfirmModal
                    body={body}
                    closeModal={() => setConfirm(false)}
                    submitForm={handleConfirm}
                />
                <ScreenOverlay/>
            </>}
            {upload && <>
                {audienceSelectionType === 'contract'
                    ? <><UploadContractModal
                        contractDetails={data}
                        mode='add'
                        documents={documents}
                        questionnaire={questionnaire || false}
                        handleSubmit={
                            (e, document, contract) => handleDocumentSubmit(
                                e, document, authContext, {
                                    accessGroups,
                                    uploadCallback,
                                    successCallback: () => setUpload(false),
                                    questionnaire: (questionnaire || false),
                                    sectionNumber,
                                    questionNumber,
                                    contract,
                                },
                            )
                        }
                        handleRemoveDocument={
                            (document) => handleRemoveDocument(documents, document, {
                                deleteCallback,
                                questionnaire: (questionnaire || false),
                                sectionNumber,
                                questionNumber,
                            })
                        }
                        handleDownload={handleDownload}
                        handleMultipleDownload={handleMultipleDownload}
                        uploadIdentifier={uploadIdentifier}
                        closeModal={() => setUpload(false)}/>
                    <ScreenOverlay/></>
                    : <><UploadModal
                        mode='add'
                        documents={documents}
                        questionnaire={questionnaire || false}
                        handleSubmit={
                            (e, document) => handleDocumentSubmit(e, document, authContext, {
                                accessGroups,
                                uploadCallback,
                                successCallback: () => setUpload(false),
                                questionnaire: (questionnaire || false),
                                sectionNumber,
                                questionNumber,
                            })
                        }
                        handleRemoveDocument={
                            (document) => handleRemoveDocument(documents, document, {
                                deleteCallback,
                                questionnaire: (questionnaire || false),
                                sectionNumber,
                                questionNumber,
                            })
                        }
                        handleDownload={handleDownload}
                        handleMultipleDownloaded={handleMultipleDownload}
                        uploadIdentifier={uploadIdentifier}
                        showExpiryDate={showExpiryDate}
                        allowMultiple={allowMultiple}
                        closeModal={() => setUpload(false)}/>
                    <ScreenOverlay/></>
                }

            </>}
            {viewDocuments && <>
                <UploadModal
                    mode='view'
                    documents={documents}
                    setDocuments={setDocuments}
                    handleSubmit={
                        (e, document) => handleDocumentSubmit(e, document, authContext, setUpload, {
                            accessGroups: [authContext.user.id],
                            uploadCallback,
                            questionnaire: (questionnaire || false),
                            sectionNumber,
                            questionNumber,
                        })
                    }
                    handleRemoveDocument={
                        (document) => handleRemoveDocument(documents, document, setDocuments, {
                            deleteCallback,
                            questionnaire: (questionnaire || false),
                            sectionNumber,
                            questionNumber,
                        })
                    }
                    handleDownload={handleDownload}
                    allowMultiple={allowMultiple}
                    handleMultipleDownload={handleMultipleDownload}
                    uploadIdentifier={uploadIdentifier}
                    readOnly={readOnly}
                    showExpiryDate={showExpiryDate}
                    closeModal={() => setViewDocuments(false)}/>
                <ScreenOverlay/>
            </>}
        </div>
    );
};

Upload.propTypes = {
    title: propTypes.string.isRequired,
    allowMultiple: propTypes.bool,
    className: propTypes.string,
    uploadIdentifier: propTypes.string,
    questionnaire: propTypes.bool,
    uploadCallback: propTypes.func,
    sectionNumber: propTypes.number,
    questionNumber: propTypes.number,
    deleteCallback: propTypes.func,
    uploadedDocuments: propTypes.array,
    readOnly: propTypes.bool,
    audienceSelectionType: propTypes.string,
    data: propTypes.object,
    showExpiryDate: propTypes.bool,
};

export default Upload;
